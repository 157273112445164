<template>
  <div class="good_box">
    <div class="good_content edition_center">
      <Breadcrumb :data="breaData" />
      <!--商品数据-->
      <div class="goods-view display_flex">
        <div class="imgbigbox">
          <div class="po_relative magnifier">
            <div class="magnifier-container" ref="choose">
                <div class="images-cover" ref="content" @mousemove="handleMove" @mouseout="handleOut">
                    <img :src="activeImg? activeImg:goodData.files? goodData.files[0]?goodData.files[0].path:'':''" />
                    <div class="shadow" ref="shadow"></div>
                </div>
               <div class="magnifier-assembly display_flex">
              <!--按钮组-->
              <div class="magnifier-btn magnifier-btn-left">
                <!-- <ArrowLeftBold
                  style="width: 22px; height: 22px; vertical-align: middle"
                /> -->
              </div>
              <!--缩略图-->
              <div class="magnifier-line flex">
                <ul class="display_flex proimg-container flex_wrap">
                  <li class="hand" @click="choiceType(index)" v-for="(imte, index) in goodData.files" :key="index" :class="isType==index? 'active':''">
                    <div class="small-img">
                      <img
                        :src="imte.path"
                        :alt="imte.name"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div class="magnifier-btn magnifier-btn-right">
                <!-- <ArrowRightBold
                  style="width: 22px; height: 22px; vertical-align: middle"
                /> -->
              </div>
            </div>
            </div>
            <div class="larger" ref="larger">
                <img :src="activeImg? activeImg:goodData.files? goodData.files[0]?goodData.files[0].path:'':''" ref="big" />
            </div>
        </div>
        </div>
        <!-- 商品消息 -->
        <div class="goods-mian flex">
          <div class="good_head">
            <div class="goods-tit">
              <h1 class="choose_h1 text_hid_link">{{goodData.title}}</h1>
              <h3 class="choose_h3 text_hid_link">
                {{goodData.cnTitle}}
              </h3>
              <p
                class="goodssku choose_sku"
                style="display: inline-block; width: 170px"
              >
                SKU:{{skuId? skuId:goodData.skus? goodData.skus[0].sku_id:''}}
              </p>
              <span style="margin-right: 20px">SPU：BPMCZARLNO</span>
              <span>上架时间：{{goodData.createTime}}</span>
            </div>
          </div>
          <div class="limited-Buy">
            <ul class="goods-summary priceDis">
              <li class="display_flex">
                <div class="name">价格：<span class="text_line">¥{{regFenToYuan(goodData.marketPrice)}}</span></div>
                <!-- <div class="field flex">
                  <p class="currPrice"></p>
                </div> -->
              </li>
              <li class="display_flex">
                <div class="name">折扣价：<span class="red_text">¥{{price? regFenToYuan(price): regFenToYuan(goodData.salePrice)}}</span></div>
                <!-- <div class="field flex">
                  <p class="currPrice">
                    普通会员会员，您的会员等级尚无折扣，升级可享受更多优惠
                  </p>
                </div> -->
              </li>
            </ul>
          </div>
          <div class="good_ul">
            <ul class="goods-summary">
              <li class="display_flex">
                <div class="name">规格：</div>
                <div class="field flex display_flex min_height">
                  <div class="color_imte hand" @click="clickColorImg(i)" :class="colorImg === i? 'active':''" v-for="(imte, i) in goodData.skus" :key="i">
                    <img :src="imte.images[0]" alt="">
                    <span v-for="(skuImte, skuIndex) in imte.attributes" :key="skuIndex">{{skuImte.vTextMulti.langAndValueMap.CN_zh.value}}{{skuIndex!=imte.attributes.length-1? '+':''}}</span>
                    <i v-if="colorImg === i"></i>
                  </div>
                </div>
              </li>
              <li class="display_flex">
                <div class="name">仓库类型：</div>
                <div class="field flex">
                  <p class="currPrice">{{wareHouse.title}}</p>
                </div>
              </li>
              <li class="display_flex">
                <div class="name">发货地址：</div>
                <div class="field flex">
                  <p class="currPrice">{{wareHouse.country}}{{wareHouse.state}}{{wareHouse.city}}{{wareHouse.district}}{{wareHouse.address}}</p>
                </div>
              </li>
              <li class="display_flex">
                <div class="name">发货区域：</div>
                <div class="field flex">
                  <div class="currPrice warehouse">{{wareHouse.country}}</div>
                </div>
              </li>
              <!-- <li class="display_flex">
                <div class="name">发货物流：</div>
                <div class="field flex">
                  <div class="currPrice">
                    <el-select v-model="value" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="text">参考时效：5-7个工作日</div>
                </div>
              </li> -->
              <li class="display_flex">
                <div class="name mt_5px">选择数量：</div>
                <div class="field flex">
                  <div class="currPrice mt_5px">
                    <el-input-number
                      v-model="num"
                      :min="1"
                      label="描述文字"
                    ></el-input-number>
                  </div>
                  <div class="text">库存数量： 158</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="goods-button">
            <button @click="openCart(1)" class="dowmdata dwnfile ga-dw">
              <img src="../../assets/img/dowmload.png" alt="" />
              <span>立即购买</span>
            </button>
            <button @click="openCart(0)" class="dowmdata addtocart dwnfile ga-dw">
              <img src="../../assets/img/gouwuche.png" alt="" />
              <span>加入购物车</span>
            </button>
            <button @click="addCollectionGood(goodData.id)" class="dowmdata dwnfile ga-dw">
              <img v-if="isFav" style="width: 22px;" src="../../assets/img/collect2.png" alt="" />
              <img v-else style="width: 22px;" src="../../assets/img/collect1.png" alt="" />
            </button>
            <button class="confirmTrans">
              <i class="sinform"></i>
              <span>设置到货/缺货通知</span>
            </button>
          </div>
        </div>
      </div>
      <!-- 区域消息 -->
      <div class="product_announcements hide">
        <div class="title display_flex">
          <h3><img src="../../assets/img/note.png" />注意</h3>
          <div class="closed flex">
            <img src="../../assets/img/closed_guide.png" alt="" />
          </div>
        </div>
        <div class="logisticsWarningContent">
          <p class="p">
            <strong class="color_red">巴巴里狮平台部分商品含有相关成分元素，请各位商需提前了解产品材质情况，并针对其做好相关的风险把控，以免造成不必要的损失。</strong>
          </p>
          <p class="p">
            <strong>*美国加州65法案进一步规定了对于仅包含致癌物质，仅包含致生殖毒性物质，同时包含致癌物质和致生殖毒性物质，亦或是包含某一物质即为致癌物质又为致生殖毒性物质的产品的警示标语要求。</strong
            >
          </p>
          <p class="p">
            <strong>*新法案提供的警示标语修订并不是强制实施的，其只是避免昂贵诉讼的一种有效的方法。只要企业在保证其使用的另外的警示标语是“清晰和合理”并符合加州65法案要求的，那也是可以被接受的。</strong
            >
          </p>
        </div>
      </div>
      <!-- 选品推荐 -->
      <div class="choose_product">
        <p class="bb_title"><span>相关商品推荐</span></p>
        <ul>
          <li @click="openGoodDetail(imte.id)" v-for="(imte, index) in relation" :key="index">
            <!-- <div class="special-discount">-6%</div> -->
            <router-link :to="{ path: '/home' }" target="_blank">
              <img
                :src="imte.logo"
                alt=""
              />
              <p>
                <!-- <span class="new_goodsbrand">Leadzm</span> -->
                {{imte.title}}
              </p>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- 商品详情 -->
      <div class="choose_product">
        <p class="bb_title mb_20px"><span>商品详情</span></p>
        <div style="text-align: center;" v-html="goodData.body"></div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "../../views/mixin.js";
import Breadcrumb from "../../components/Breadcrumb/index.vue";
export default {
  mixins: [mixin],
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breaData: [], // 面包屑
      options: [],
      value: "",
      num: 1,
      activeName: "second",
      isType: 0,
      activeImg: "",
      colorImg: '',
      price: "",
      skuId: "",
      relation: [], // 相关商品
      wareHouse: '', // 仓库信息
      goodData: {},
    };
  },
  created() {
    // this.getSiteId()
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
			this.$requestJSON({
      	  url: 'shop/goods/view/' + this.$route.query.id,
      	  method: 'POST',
          data: {
            token: window.localStorage.getItem(this.domain + this.$store.state.siteId +'Token')? window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'):'',
            siteId: this.$store.state.siteId
          }
      	}).then(res => {
      	//   this.loading = false
      	   if(res.code === 0) {
            //  console.log(JSON.stringify(res.data.skus))
             this.goodData = res.data
             this.isFav = res.isFav
             this.wareHouse = res.wareHouse
             this.relation = res.relation
      	   } else {
      	     this.$message.error (res.msg)
      	   }
      	})
		},
    // 获取元素到文档区域的坐标 
    getPosition: function(element){ 
        // if(goodData.files.length == 0) return
        var dc = document, 
        rec = element.getBoundingClientRect(), 
        x = rec.left, // 获取元素相对浏览器视窗window的左、上坐标 
        y = rec.top; 
        // 与html或body元素的滚动距离相加就是元素相对于文档区域document的坐标位置 
        x += dc.documentElement.scrollLeft || dc.body.scrollLeft; 
        y += dc.documentElement.scrollTop || dc.body.scrollTop; 
        return { 
            left: x, 
            top: y 
        }; 
    },
    handleMove(evt) {
        // if(goodData.files.length == 0) return
        var larger = this.$refs.larger;
        var shadow = this.$refs.shadow;
        var big = this.$refs.big;
        var pos = this.getPosition(this.$refs.choose);
        var shadowRect = shadow.getBoundingClientRect();
        var bigRect = big.getBoundingClientRect();
        var contentRect = this.$refs.content.getBoundingClientRect();
        var maxX = contentRect.width - shadowRect.width;
        var maxY = contentRect.height - shadowRect.height;
 
        var X = evt.pageX - pos.left - shadowRect.width / 2;
        var Y = evt.pageY - pos.top - shadowRect.height / 2;
 
        if (X <= 0) {
            X = 0;
        }
        if (X >= maxX) {
            X = maxX;
        }
        if (Y <= 0) {
            Y = 0;
        }
        if (Y >= maxY) {
            Y = maxY;
        }
        // 防止遮罩层粘滞，跟随鼠标一起滑出大图位置
        var bigX = X * bigRect.width / contentRect.width;
        var bigY = Y * bigRect.height / contentRect.height;
        //  bigX / bigW = X / contentW,主图和遮罩层之间存在两倍关系，放大图和原图之间也有两倍关系
        shadow.style.left = X + "px";
        shadow.style.top = Y + "px";
        // console.log(X, Y, bigX, bigY);
 
        big.style.left = -bigX + "px";
        big.style.top = -bigY + "px";
 
        larger.style.display = "block";
        shadow.style.display = "block";
    },
    handleOut(evt) {
        var larger = this.$refs.larger;
        var shadow = this.$refs.shadow;
        var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
        larger.style.display = "none";
        if (userAgent.indexOf("Firefox") > -1) {
          setTimeout(()=>{
            shadow.style.top = "-1000px";
          },100)
        } else {
          shadow.style.top = "-1000px";
        }
    },
    // 缩略图
		choiceType(i) {
			this.isType = i
      this.activeImg = this.goodData.files[i].path
		},
    // 缩略图
    // 选择颜色
    clickColorImg(i) {
      this.colorImg = i
      this.activeImg = this.goodData.skus[i].images[0]
      this.price = this.goodData.skus[i].price
      this.skuId = this.goodData.skus[i].sku_id
    },
    // 购物车/立即购买
    openCart(i) {
      if(!window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')) {
        this.$message.error ('请登录！')
        this.$router.push({path:'/login', query: {siteId: this.$route.query.siteId? this.$route.query.siteId:this.$store.state.siteId}})
        return
      }
      if(this.goodData.skus) {
        if(!this.colorImg && this.colorImg !== 0) {
          this.$message.error ('请选择规格！')
          return
        }
      }
      let data = {
        siteId: this.$store.state.siteId,
        token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
        goodsId: this.goodData.id,
        skuId: this.skuId? this.skuId:0,
        type: 1,
        wareId: 1,
        price: this.price? this.regFenToYuan(this.price):this.regFenToYuan(this.goodData.salePrice),
        num: this.num,
        isbuyNow: i, // 0:加入购物车 1：立即购买
        skuName: this.goodData.skus? this.goodData.skus[this.colorImg].attributes[0].vTextMulti.langAndValueMap.CN_zh.value:0,
        remark: '' // 备注
      }
      this.$requestJSON({
      	  url: 'shop/cart/add',
      	  method: 'POST',
          data: data
      	}).then(res => {
      	//   this.loading = false
      	   if(res.code === 0) {
      	    //  console.log(res.data)
             if (i === 1) {
               this.$router.push({path:'/orderList',query:{id: res.data.cartId, siteId: this.$route.query.siteId? this.$route.query.siteId:this.$store.state.siteId}})
             } else {
               this.$message.success (res.msg)
             }
      	   } else {
      	     this.$message.error (res.msg)
      	   }
      	})
    },
  },
};
</script>
<style lang="scss" scoped>
#listshow {
    width: 400px;
    height: 100px;
    margin-top: 20px;
}
 
#listshow li {
    width: 98px;
    height: 100px;
    float: left;
}
 
#listshow li img {
    width: 98px;
    height: 100px;
}
 
#listshow .selected {
    border-color: brown;
}
 
.larger {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 1px;
    left: 500px;
    float: left;
    overflow: hidden;
    display: none;
    z-index: 100;
}
 
.larger img {
    width: 800px;
    height: 800px;
    position: absolute;
    left: 0;
    top: 0;
}
 
.shadow {
    width: 200px;
    height: 200px;
    background-color: rgba(145, 200, 200, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
}

.goods-view {
  background: #fff;
  .magnifier {
    width: 500px;
    .magnifier-container {
      width: 100%;
      min-height: 500px;
      border: 1px solid #f1f1f1;
      .images-cover {
        img {
          width: 100%;
        }
      }
    }
    .magnifier-assembly {
      width: 100%;
      overflow: hidden;
      margin-top: 5px;
      .magnifier-btn {
        width: 50px;
        text-align: center;
        line-height: 70px;
      }
      .magnifier-line {
        .proimg-container {
          width: 100%;
          height: 70px;
          overflow: hidden;
          margin-bottom: 20px;
          li {
            width: calc(20% - 10px);
            padding-right: 10px;
            img {
              width: 100%;
              height: auto;
            }
            .small-img {
              padding: 1px;
              border: 1px solid #fff;
              text-align: center;
            }
            .small-img:hover {
              border-color: #ff6600;
            }
          }
          .active {
              // border: 1px solid #;
              .small-img{
               border-color: #ff6600;
              }
             
            }
          li:last-child {
            padding-right: 0px !important;
          }
        }
      }
    }
  }
  .goods-mian {
    padding: 0 20px 20px 20px;
    .good_head {
      .goods-tit {
        border-bottom: 4px solid #cb261c;
        width: 100%;
        h1 {
          padding: 20px 0 5px 0;
        }
        h3 {
          line-height: 24px;
          color: #999 !important;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: normal;
        }
        .goodssku {
          color: #cb261c;
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 14px;
        }
        span {
          color: #666;
        }
      }
    }
    .limited-Buy {
      background: #fcfcfc;
      .priceDis {
        background: url("../../assets/img/good_bg.png") no-repeat;
        background-size: cover;
        padding: 15px 30px;
      }
    }
    .goods-summary {
      .name {
        line-height: 40px;
        width: 120px;
      }
      .field {
        line-height: 40px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .color_imte {
          margin-left: 5px;
          margin-top: 5px;
          position: relative;
          img {
            width: 50px;
            height: auto;
          }
          i {
            background-image: url('../../assets/img/xuanze.png');
            position: absolute;
            bottom: 0;
            right: 0;
            width: 12px;
            height: 12px;
            overflow: hidden;
            text-indent: -99em;
            display: block;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
        .active {
          border: 2px solid #FF0036;
        }
        .warehouse {
          border: 1px solid #ff6600;
          margin-right: 10px;
          line-height: 28px;
          border-radius: 3px;
          // border: 1px solid #eee;
          background: #fff;
          padding: 0 10px;
          cursor: pointer;
          color: #ff6600;
        }
        .text {
          padding-left: 10px;
        }
      }
    }
    .good_ul {
      padding-left: 30px;
      .min_height {
        max-height: 300px;
        overflow: auto;
      }
    }
    .goods-button {
      .addtocart {
        background-color: #ff5400;
        min-width: 150px;
        color: #fff !important;
        span {
          color: #fff !important;
        }
      }
      .dowmdata {
        border: 1px solid #ff5400;
        color: #ff5400;
        line-height: 40px;
        text-align: center;
        padding: 0 15px;
        height: 40px;
        border-radius: 3px;
        margin: 20px 20px 0 0;
        img {
          width: 30px;
          margin-top: -3px;
          vertical-align: middle;
        }
      }
      .confirmTrans {
        .sinform {
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          background: url("../../assets/img/ling.png") no-repeat center;
          background-size: 20px;
          margin: -3px 3px 0 0;
          // line-height: 30px;
          // padding: 0 10px;
          // font-size: 12px;
        }
        span {
          text-decoration: underline;
          color: #cb261c;
        }
      }
    }
  }
}
.product_announcements {
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  padding: 20px;
  margin: 20px 0;
  h3 {
    font-size: 16px;
    color: #ff5400;
    margin-bottom: 10px;
    line-height: 20px;
    font-weight: bold;
    img {
      margin-right: 5px;
      margin-top: -1px;
    }
  }
  .closed {
    text-align: right;
    img {
      margin-top: 2px;
    }
  }
}
.choose_product {
  background: #fff;
  margin: 20px 0;
  .bb_title:before {
    content: "";
    position: absolute;
    width: 100%;
    border-top: 1px dashed #d2d2d2;
    left: 0px;
    top: 30px;
    z-index: 1;
  }
  p {
    position: relative;
    padding: 15px 0 0 0;
    text-align: center;
    span {
      position: relative;
      text-align: center;
      font-size: 16px;
      color: #333;
      display: inline-block;
      padding: 0 20px;
      line-height: 30px;
      z-index: 9;
      background-color: #fff;
      font-weight: bold;
    }
  }
  ul {
    display: flex;
    padding: 20px 30px;
    li {
      width: 14%;
      position: relative;
      text-align: center;
      margin-right: 3%;
      .special-discount {
        position: absolute;
        top: 5px;
        right: 0px;
        height: 30px;
        background-color: #ff6128;
        color: #fff;
        padding: 0 5px;
        line-height: 30px;
        font-size: 12px;
      }
      img {
        width: 100%;
      }
      .new_goodsbrand {
        display: inline-block;
        padding: 0 7px;
        background-color: #cb261c;
        color: #fff;
        line-height: 22px;
        border-radius: 3px;
        margin-right: 5px;
        font-size: 14px;
        font-weight: normal;
      }
      p {
        overflow: hidden;
        color: #666;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-align: center;
        line-height: 24px;
        height: 48px;
        margin: 5px 0;
      }
    }
  }
}
.details_box {
  background: #fff;
  .volume {
    padding: 10px;
    ul {
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      li {
        width: 25%;
        height: 40px;
        line-height: 40px;
        font-weight: 700;
      }
    }
  }
  .choose_detail_img {
    // margin: 0 auto;
    img {
      display: block;
      margin: 0 auto;
    }
  }
}
.historyDiv {
    background-color: #fff;
    margin-bottom: 20px;
    margin-top: 20px;
    .titl {
        width: 100%;
        border-bottom: 1px solid #CB261C;
        line-height: 40px;
        p {
            width: 120px;
            background-color: #CB261C;
            color: #fff;
            text-align: center;
        }
    }
    .swiper-wrapper {
      .swiper-slide {
        width: 240px;
        .commodity {
          padding: 15px;
          text-align: center;
          img {
            height: 150px;
            width: 150px;
          }
          .commodity_p_length {
            line-height: 30px;
            // height: 60px;
            overflow: hidden;
          }
          span {
            color: red;
            height: 24px;
            i {
              display: inline-block;
              padding: 0 10px;
              line-height: 26px;
              background-color: #5e46e6;
              color: #fff;
              border-radius: 3px;
              margin-right: 10px;
            }
          }
        }
      }
    }
}
</style>
