<template>
    <div class="product-manage">
        <div class="managetop">
            <div class="page-tit display_flex">
                <h6 class="flex">我的账单</h6>
                <p class="p flex"><el-button @click="rechargeVisible=true" type="primary" size="mini">充值</el-button></p>
            </div>
            <div class="tab_box">
                <div class="table_box">
                    <el-table :data="billData" style="width: 100%">
                      <el-table-column prop="bank" label="汇款银行"></el-table-column>
                      <el-table-column prop="payTime" label="汇款时间"></el-table-column>
                      <el-table-column prop="orderNo" label="订单号/流水号"></el-table-column>
                      <el-table-column prop="money" label="汇款金额"></el-table-column>
                      <el-table-column prop="remark" label="备注"></el-table-column>
                      <el-table-column prop="logo" label="上传凭证">
                          <template #default="scope">
                            <div class="img_box">
                                <img :src="scope.row.logo" alt="">
                            </div>
                          </template>
                      </el-table-column>
                    </el-table>
                    <div class="paga">
                        <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
                    </div>
                </div>
            </div>
        </div>
        <Recharge :rechargeVisible="rechargeVisible" @cloeNo="cloeNo" />
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
import Recharge from "../../../components/Recharge/index.vue";
export default {
    mixins: [mixin],
    data() {
        return {
             billData: [],
        }
    },
    components: {
      Recharge
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$requestJSON({
      	      url: 'shop/userrecharge/list',
      	      method: 'POST',
              data: {
                page: this.currentPage,
                limit: this.pageSize,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                   this.billData = res.data
                   this.total = res.data.total
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        }
    }
}
</script>
<style lang="scss" scoped>
.img_box {
    img {
        width: 100px;
    }
}
  /deep/.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  /deep/.el-button+.el-button {
      margin-left: 0px;
  }
  .el-select-dropdown__item {
      height: 50px;
  }
  .select_img {
     img {
         width: 60px;
     }
 }
.product-manage {
    margin-bottom: 20px;
    .managetop {
        background-color: #fff;
        padding: 10px 20px;
        .page-tit {
            padding: 10px 0 0;
            height: 50px;
            h6 {
                font-weight: bold;
                color: #333;
                line-height: 40px;
                font-size: 16px;
            }
            .p {
                text-align: right;
            }
        }
        .tab_box {
            margin: 10px 0;
            .table_box {
                .good_box {
                    img {
                        width: 100px;
                    }
                }
                .good_title {
                    padding-left: 8px;
                    .title {
                        color: #666;
                        font-size: 15px;
                    }
                    .good_date {
                        padding-top: 15px;
                        color: #999;
                    }
                }
            }
        }
    }
    .settle-bar {
        background-color: #fff;
        line-height: 40px;
        box-shadow: 0 0 5px #eee;
    }
}
</style>
