<template>
<div class="goods-arranged display_flex flex_wrap">
    <div class="null_box" v-if="goodListData.length==0"><el-empty description="无数据"></el-empty></div>
    <div v-for="(imte, index) in goodListData" :key="index" class="commodity goods-style proitemIndx" @click="openGoodDetail(imte.id)">
                <a class="ProImg ga-search-click chooseTitle_a" target="_blank">
                    <div class="goods-style-img">
                        <img class="chooseImg" :src="imte.logo" :alt="imte.title" />
                            <!-- <div class="replenishment">补货中</div> -->
                    </div>
                </a>
                <a target="_blank" class="chooseTitle_a" ><h3>{{imte.title}}</h3></a>
                <p class="text_hid_link">{{imte.cnTitle}}</p>
                <!-- <div class="Warehouse" data-spu="BWOBOJKUBX">
                            <button type="button" class="Warehouse_btn on"> 中国</button>
                </div> -->
                <div class="productPriceshow price">¥ {{regFenToYuan(imte.salePrice)}}</div>
                <!-- <div class="special special-discount">最高-2%</div> -->
                <!-- <div class="inventory"><Coin style="width: 16px; height: 16px;vertical-align: middle;color: #CB261C;" /> 库存：{{imte.stock}}</div> -->
                <!-- <div class="handle_box display_flex">
                    <div class="handle">
                    <input type="checkbox" name="chooseOne" lay-filter="commonChooseOneFilter" lay-skin="primary" class="commonChooseOne" autocomplete="off" value="7390">
                </div>
                <div class="handle-op flex"> -->
                    <!-- <a class="ga-dw btnDownload mr_5px">
                        <img src="../../assets/img/xiazai.png">
                    </a>
                    <a class="ga-dw btnDownload" @click.stop="addCollectionGood(imte.id)">
                        <img src="../../assets/img/aixin.png">
                    </a> -->
                    <!-- <button type="button" data-pid="7390" pro-id="7390" class="ga-wish pure"><div class="layui-icon collcot-icon"></div></button> -->
                <!-- </div> -->
                <!-- </div> -->
    </div>
    <div class="templet-page">
     	<Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
    </div>
</div>
</template>
<script>
import mixin from "../../views/mixin.js";
import Pagination from "../../components/Pagination/index.vue"
export default {
  mixins: [mixin],
  components: {
	Pagination
  },
  data() {
    return {
        pageSize: 50,
		goodListData: []
	};
  },
   watch:{
       '$store.state.search':function(val){
           if(val) {
               this.currentPage = 1
                this.getData()
           }
        },
       '$store.state.brandId':function(){
            this.currentPage = 1
            this.getData()
        },
        '$store.state.newTime':function(){
            this.currentPage = 1
            this.getData()
        },
        '$store.state.searchType':function(){
            this.currentPage = 1
            this.getData()
        },
        // '$store.state.searchStock':{
	    // 	handler(newVal){
	    // 		console.log(newVal);
        //         this.currentPage = 1
        //         this.getData()
	    // 	},
	    // 	deep: true
	    // },
        // '$store.state.searchPrice':{
	    // 	handler(newVal){
	    // 		console.log(newVal);
        //         this.currentPage = 1
        //         this.getData()
	    // 	},
	    // 	deep: true
	    // }
    },
  created() {
    //   this.getSiteId()
      this.getData()
  },
  methods: {
      getData() {
          let data = {
              siteId: this.$store.state.siteId,
              page: this.currentPage,
              limit: this.pageSize,
              goodsType: this.$route.query.type? this.$route.query.type:'',
              categoryId: this.$route.query.categoryId? this.$route.query.categoryId:'',
              title: this.$route.query.title? this.$route.query.title:''
          }
          if(this.$store.state.brandId) {
              data.brandId = this.$store.state.brandId
          }
          if(this.$store.state.newTime) {
              data.newTime = this.$store.state.newTime
          }
          if(this.$store.state.searchType) {
              data.orderBy = this.$store.state.searchType
          }
          if(this.$store.state.searchStock) {
              data.maxStock = this.$store.state.searchStock.maxStock
              data.minStock = this.$store.state.searchStock.minStock
          }
          if(this.$store.state.searchPrice) {
              data.maxPrice = this.$store.state.searchPrice.maxPrice
              data.minPrice = this.$store.state.searchPrice.minPrice
          }
         this.$requestJSON({
      	  url: 'shop/goods/list/' + this.$store.state.siteId,
      	  method: 'POST',
            data: data
      	}).then(res => {
      	//   this.loading = false
      	   if(res.code === 0) {
             this.goodListData = res.data
             this.total = res.total
             this.$store.state.search = false
             window.scrollTo(0,0)
      	   } else {
      	     this.$message.error (res.msg)
      	   }
      	}) 
      },
      turnPages(data) {
          this.currentPage = data.page? data.page:this.currentPage
          this.pageSize = data.size? data.size:this.pageSize
          this.getData()
      },
  }
};
</script>
<style lang="scss" scoped>
.goods-arranged {
    width: 100%;
    min-height: 200px;
    .null_box {
          background: #fff;
          width: 100%;
          margin-bottom: 15px;
      }
}
.goods-style:nth-child(5n) {
    margin-right: 0px !important;
}
.commodity:hover h3 {
    color: #CB261C;
}
.commodity {
    padding: 15px;
    border-bottom: 1px solid #eee;
}
.goods-style {
    position: relative;
    .special-discount {
        position: absolute;
        top: 5px;
        right: 0px;
        height: 30px;
        background-color: #FF6128;
        color: #fff;
        padding: 0 5px;
        line-height: 30px;
        font-size: 12px;
    }
}
.goods-style {
    width: calc(20% - 42px);
    background-color: #fff;
    margin-right: 15px;
    margin-bottom: 15px;
    .goods-style-img {
        position: relative;
        text-align: center;
        img {
            width: 100%;
            height: 200px;
        }
        .replenishment {
            position: absolute;
            bottom: 0px;
            right: 0px;
            padding: 0 15px;
            background: #999;
            color: #fff;
            line-height: 30px;
        }
    }
    h3 {
       text-align: left; 
       line-height: 30px;
        height: 60px;
        overflow: hidden;
        color: #333;
    }
    p {
       height: 20px;
       color: #999;
        margin-bottom: 5px;
    }
    .Warehouse_btn {
        padding: 0 7px;
        line-height: 28px;
        height: 28px;
        border: 1px solid #ccc;
        font-size: 14px;
        border-radius: 3px;
        display: inline-block;
        margin-right: 5px;
    }
    .Warehouse_btn.on {
        border-color: #CB261C;
        color: #CB261C;
    }
    .Warehouse_btn:last-child {
        margin-right: 0px;
    }
    .price {
        height: 44px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: #FF6A00;
        margin-top: 5px;
        height: 30px;
    }
    .inventory {
        text-align: left;
        margin: 3px 0;
        color: #666;
        height: 24px;
    }
    .handle_box {
        .handle {
            padding: 0px;
            display: inline-block;
            width: 28px;
            padding-top: 5px;
        }
        .handle-op {
            text-align: right;
        }
        .collcot-icon1 {
            background: url(../../assets/img/xin.png) no-repeat center center;
            background-size: 16px;
            width: 20px;
            height: 100%;
        }
        .collcot-icon {
            background: url(../../assets/img/aixin.png) no-repeat center center;
            background-size: 16px;
            width: 20px;
            height: 100%;
        }
    }
}
.templet-page {
    width: 100%;
}
</style>
