<template>
    <div class="evaluate_box bg_fff">
        <div class="evaluate_good">
            <div class="title_box">
                <div class="title">评价</div>
            </div>
            <div class="good display_flex">
                <div class="good_l">
                    <img src="https://img.alicdn.com/bao/uploaded/i2/2210644470342/O1CN01LTv33Y1EOg7AxJf1U_!!2210644470342.jpg_130x130.jpg" alt="">
                    <div class="name">PVC三通接头Y型斜三通三</div>
                </div>
                <div class="good_r flex">
                    <div class="list display_flex">
                        <div class="name">描述相符</div>
                        <el-rate
                          v-model="value"
                          show-score
                          text-color="#ff9900"
                          score-template="{value}"
                        >
                        </el-rate>
                    </div>
                     <div class="list display_flex">
                        <div class="name">卖家服务</div>
                        <el-rate
                          v-model="value"
                          show-score
                          text-color="#ff9900"
                          score-template="{value}"
                        >
                        </el-rate>
                    </div>
                    <div class="list display_flex">
                        <div class="name">物流服务</div>
                        <el-rate
                          v-model="value"
                          show-score
                          text-color="#ff9900"
                          score-template="{value}"
                        >
                        </el-rate>
                    </div>
                    <div class="list display_flex">
                        <div class="name">评价</div>
                        <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="textarea"></el-input>
                    </div>
                    <el-upload
                      action="https://jsonplaceholder.typicode.com/posts/"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                    >
                      <div class="plus_icon">+</div>
                    </el-upload>
                    <div class="tac pt_30">
                        <el-button type="primary" @click="submitForm">提交</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        value: 3.7,
        textarea: ''
      }
    },
    methods: {
        submitForm() {}
    }
  }
</script>
<style lang="scss" scoped>
.plus_icon {
    line-height: 130px;
    font-size: 60px;
    color: #999;
}
.evaluate_box {
    padding: 20px;
    .evaluate_good {
        width: 90%;
        margin: 0 auto;
        border: 1px solid #ccc;
        .title_box {
            background: #f2f2f2;
            .title {
                font-size: 16px;
                font-weight: bold;
                color: #333;
                line-height: 40px;
                // padding-top: 10px;
                width: 100px;
                text-align: center;
            }
        }
        .good {
            padding: 30px 0 50px;
            .good_l {
                width: 250px;
                text-align: center;
                img {
                    width: 132px;
                }
                .name {
                    padding-top: 10px;
                }
            }
            .good_r {
                padding-right: 50px;
                .list {
                    padding-bottom: 10px;
                    .name {
                        width: 80px;
                    }
                }
            }
        }
    }
}
</style>
