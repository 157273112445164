<template>
    <el-dialog
      title="填写快递单号"
      v-model="courierNumVisible"
      width="400px"
      destroy-on-close
      center
    >
      <div class="good_box display_flex">
          <img class="img_table" :src="goodData.orderDetail.logo" alt="">
          <div>
              <div class="title_table text_two_link">{{goodData.orderDetail.title}}</div>
              <div class="price"><span class="color_red">￥{{goodData.orderDetail.price}}</span> ×{{goodData.orderDetail.num}}</div>
          </div>
      </div>
      <div class="num_box">
          <el-form
            :model="numForm"
            :rules="numRules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="快递单号" prop="deliverNo">
              <el-input v-model="numForm.deliverNo"></el-input>
            </el-form-item>
          </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
</template>
<script>
import mixin from "../../views/mixin.js";
  export default {
    mixins: [mixin],
    data() {
      return {
        courierNumVisible: false,
        goodData: {},
        numForm: {
            deliverNo: '' // 快递单号
        },
        numRules: {
            deliverNo: [{ required: true, message: '请输入快递单号', trigger: 'blur' },],
        }
      }
    },
    methods: {
        // 打开弹窗
        getVisible(row) {
            this.courierNumVisible = true
            this.goodData = row
        },
        // 关闭弹窗
        resetForm() {
          this.$refs['ruleForm'].resetFields()
          this.courierNumVisible = false
        },
        submitForm() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            this.getUpdate()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      // 提交接口
      getUpdate() {
          this.$requestJSON({
      	      url: 'shop/orderback/update',
      	      method: 'POST',
              data: {
                  id: this.goodData.id,
                  deliverNo: this.numForm.deliverNo,
                  siteId: this.$store.state.siteId,
                  token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.$message.success ('提交成功！')
                   this.resetForm()
                   this.$emit("getData")
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
      }
    }
  }
</script>
<style lang="scss" scoped>
.good_box {
    .img_table {
        width: 100px;
    }
    .title_table {
        padding-top: 10px;
        line-height: 25px;
        padding-left: 5px;
        height: 60px;
    }
    .price {
        padding-left: 5px;
    }
}
.num_box {
    margin-top: 20px;
}
</style>
