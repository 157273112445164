<template>
    <div class="shopping_cart_list" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="content edition_center display_flex">
            <div class="cart_list bg_fff">
                <div class="page-tit display_flex">
                    <div class="flex display_flex">
                        <h6>购物车</h6>
                        <!-- ，最多可加入100项商品 -->
                        <div class="text">当前已有<span>{{cartData.length}}</span>项商品</div>
                    </div>
                    <div class="addgoods display_flex flex">
                        <div class="name">添加商品：</div>
                        <!-- <input type="text" class="cart_search_key" placeholder="输入商品名或SKU查询商品" /> -->
                        <el-input style="width: 230px;" size="mini" v-model="input" placeholder="输入商品名或SKU查询商品"></el-input>
                        <!-- <el-button type="button" class="seachg">搜索</el-button> -->
                        <el-button size="mini" type="primary" class="seachg">搜索</el-button>
                    </div>
                </div>
                <div class="table_box">
                    <div class="table_head bg_f2f display_flex">
                        <div style="width: 55px;" class=""></div>
                        <div style="width: 43%;" class="">商品</div>
                        <div style="width: 10%;" class="">价格</div>
                        <div style="width: 16%;" class="">数量</div>
                        <div style="width: 12%;" class="">小计</div>
                        <div style="" class="">操作</div>
                    </div>
                    <div class="table">
                        <div class="table_th bg_f2f display_flex">
                            <div style="width: 20px;">
                                <el-checkbox
                                  :indeterminate="isIndeterminate"
                                  v-model="checkAll"
                                  @change="handleCheckAllChange"
                                ></el-checkbox>
                            </div>
                          <div class="body_th">全选</div>
                        </div>
                        <!-- <el-checkbox-group
                          v-model="checkedCities"
                          @change="handleCheckedCheck"> -->
                          <div class="null_box" v-if="cartData.length==0"><el-empty description="您的购物车是空的， 赶紧去逛逛吧"><el-button type="primary" @click="openHome">去逛逛</el-button></el-empty></div>
                          <div v-else v-for="(imte, i) in cartData" :key="i" class="table_body">
                              <div class="good_list display_flex hand">
                                  <div style="width: 55px;" class="checkbox_li">
                                      <el-checkbox @change="handleCheckedCheck(imte)" v-model="imte.check"></el-checkbox>
                                  </div>
                                  <div style="width: 43%;" class="list_th" @click="openGoodDetail(imte.goodsId)">
                                      <div class="good display_flex">
                                          <div class="checkbox_li"><img :src="imte.logo" alt=""></div>
                                          <div class="text_box">
                                              <router-link to="/" class="text_hid_link">{{imte.title}}</router-link>
                                              <p>规格：{{imte.skuName}}</p>
                                          </div>
                                      </div>
                                      <!-- <div class="good display_flex flex_wrap">
                                          <div><img src="https://img.saleyee.cn/Resources/GoodsImages/2020/202010/202010301626418836_1fadc99b-bf8d-434f-8193-f859473940ee.Jpeg" alt=""></div>
                                          <div class="text_box">
                                              <router-link>【WH】可遥控/3档速电动越野车红色</router-link>
                                              <p>SKU：84117204</p>
                                          </div>
                                      </div> -->
                                  </div>
                                  <div @click="openGoodDetail(imte.goodsId)" style="width: 10%;" class="list_th checkbox_li text_center">￥ {{regFenToYuan(imte.price)}}</div>
                                  <div style="width: 16%;" class="list_th checkbox_li text_center"><el-input-number @change="getByNum(imte)" v-model="imte.num" :step="1" step-strictly></el-input-number></div>
                                  <div @click="openGoodDetail(imte.goodsId)" style="width: 12%;" class="list_th color_red checkbox_li text_center">￥ {{regFenToYuan(imte.price * imte.num)}}</div>
                                  <div style="" class="list_th text_center operation">
                                      <div><el-button @click.stop="deleteGood(imte)" size="mini" type="primary">删除</el-button></div>
                                      <div><el-button @click="moveFavorites(imte)" type="text"> 移入收藏夹</el-button></div>
                                  </div>
                              </div>
                          </div>
                        <!-- </el-checkbox-group> -->
                    </div>
                    <div class="paga" v-if="cartData.length>0">
                        <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
                    </div>
                </div>
            </div>
            <div class="settlement bg_fff">
                <div class="settle-posi">
                    <div class="all-management">
                        <p>
                            <span>已选<span class="selectCount">{{checkedCities.length}}</span>项</span>
                        </p>
                        <span class="delgoods">删除选中的商品</span>
                        <p>
                            <span class="clearCart">清空购物车</span>
                        </p>
                    </div>
                    <div class="warehouse-settle">
                        <p><b style="display:block;">总价：</b><span><i class="i_currencycode"></i><span class="s1">{{totalPrice==0? '0.00':totalPrice}}</span></span></p>
                        <el-button size="mini" type="primary" @click="openBuy">去结算</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from "../../views/mixin.js";
export default {
    mixins: [mixin],
    data() {
        return {
            checkAll: false,
            checkedCities: [],
            cities: [],
            isIndeterminate: false,
            cartData: [],
            totalPrice: 0 // 付款总价
        }
    },
    components: {},
    created() {
        this.getData()
    },
    methods: {
     // 获取数据
     getData() {
	 	this.$requestJSON({
     	  url: 'shop/cart/list',
     	  method: 'POST',
           data: {
               page: this.currentPage,
               limit: this.pageSize,
               siteId: this.$store.state.siteId,
               token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
           }
     	}).then(res => {
     	//   this.loading = false
     	   if(res.code === 0) {
     	     console.log(res.data)
              this.cartData = res.data
              this.total = res.total
     	   } else {
     	     this.$message.error (res.msg)
     	   }
     	})
	 },
     // 全选
      handleCheckAllChange(val) {
        this.cartData.map(itme => {
            itme.check = val
        })
        this.checkedCities = val? this.cartData:[]
      },
    //   监听勾选
      handleCheckedCheck(value) {
          if (value.check) {
              this.checkedCities.push(value)
          } else {
            for(let i = 0; i<this.checkedCities.length; i++) {
                if(this.checkedCities[i].id == value.id) {
                    this.checkedCities.splice(i, 1)
                    break;
                }
            }
          }
          // 判断是否是全选
          if(this.checkedCities.length == this.cartData.length) {
              this.checkAll = true
          } else {
              this.checkAll = false
          }
        //  计算总金额
          this.getCheckedTotal()
      },
      // 修改商品购物车数量
      getByNum(row) {
          this.$requestJSON({
      	  url: 'shop/cart/change',
      	  method: 'POST',
            data: {
                id: row.id,
                num: row.num,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
            }
      	}).then(res => {
      	//   this.loading = false
      	   if(res.code === 0) {
             for(let i = 0; i<this.checkedCities.length; i++) {
                if(this.checkedCities[i].id == row.id) {
                    this.checkedCities[i].num = row.num
                    break;
                }
             }
            this.getCheckedTotal()
      	   } else {
      	     this.$message.error (res.msg)
      	   }
      	})
      },
    //   勾选总价
    getCheckedTotal() {
        this.totalPrice = 0
        this.checkedCities.map((imte,i)=>{
            this.totalPrice += this.regFenToYuan(imte.price) * imte.num
        })
        this.totalPrice = this.totalPrice.toFixed(2)
    },
    //   删除
    deleteGood(row) {
        this.$requestJSON({
      	  url: 'shop/cart/del',
      	  method: 'POST',
            data: {
                id: row.id,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
            }
      	}).then(res => {
      	//   this.loading = false
      	   if(res.code === 0) {
            //    this.$message.success ('删除成功')
               this.getData()
            //  this.goodData = res.data
      	   } else {
      	     this.$message.error (res.msg)
      	   }
      	})
    },
    // 移入收藏夹
    moveFavorites(row) {
        // this.deleteGood(row)
        this.isFav = 0
        this.addCollectionGood(row.goodsId,true, row.id)
    },
    // 付款
      openBuy() {
        //   if(this.checkedCities.length>1) {
        //       this.$message.error ('请选择单个商品下单')
        //       return
        //   }
          let arr = ''
        for(let i = 0; i<this.checkedCities.length; i++) {
            if(this.checkedCities.length-1 == i) {
                arr += this.checkedCities[i].id
            } else {
                arr += this.checkedCities[i].id + ','
            }
            
        }
        
        this.$router.push({path:'/orderList',query:{id: arr, siteId: this.$store.state.siteId}})
      },
    },
}
</script>
<style lang="scss" scoped>
.content {
    .cart_list {
        padding: 10px 20px;
        min-height: 650px;
        width: calc(100% - 260px);
        .page-tit {
            padding: 10px 0 0;
            height: 50px;
            h6 {
                font-size: 16px;
                color: #333;
            }
            .text {
                margin-top: 2px;
                margin-left: 10px;
                color: #666;
                font-size: 14px;
            }
            .addgoods {
                height: 27px;
                .name {
                    line-height: 27px;
                    // width: 110px;
                }
                .seachg {
                    margin-left: 10px;
                }
            }
        }
    }
    .settlement {
        width: 240px;
        height: 280px;
        margin-left: 20px;
        padding: 15px 10px;
        .all-management {
           border-bottom: 1px solid #e6e6e6;
           padding-bottom: 10px;
           margin-bottom: 10px;
           .selectCount {
               color: #FF5722;
           }
           p {
               line-height: 24px;
               margin-bottom: 10px;
           }
           .delgoods {
               display: inline-block;
               line-height: 30px;
               cursor: pointer;
           }
           .clearCart {
               display: inline-block;
               line-height: 30px;
               cursor: pointer;
           }
        }
        .warehouse-settle {
            padding: 10px 0;
            p {
                font-size: 14px;
                color: #666;
                line-height: 30px;
                margin-bottom: 10px;
            }
            .s1 {
                font-size: 16px;
                color: #c81522;
                font-weight: bold;
                display: inline-block;
                margin-left: 10px;
            }
            .el-button--primary {
                width: 100%;
                text-align: center;
            }
        }
    }
    .table_box {
        .table_head {
            margin: 10px 0;
            height: 38px;
            line-height: 38px;
            text-align: center;
        }
        .table {
            .table_th {
                border: 1px solid #e6e6e6;
                padding: 0 10px;
                .body_th {
                    // padding-left: 10px;
                    line-height: 40px;
                }
            }
            .table_body {
                border: 1px solid #e6e6e6;
                border-top: none;
                height: 90px;
                // line-height: 90px;
                .checkbox_li {
                   line-height: 90px;
                }
                .text_center {
                    text-align: center;
                }
                .operation {
                    padding-top: 20px;
                    .el-button--mini {
                        min-height: 23px;
                        padding: 2px;
                    }
                }
                .good_list {
                    padding: 0 10px;
                    /deep/.el-button--text {
                        color: #666 !important;
                    }
                .list_th {
                    .good {
                        width: 100%;
                        img {
                            width: 70px;
                        }
                        .text_box {
                            padding-left: 10px;
                            padding-top: 20px;
                            .color_red {
                                columns: #CB261C;
                            }
                            a {
                                color: #CB261C !important;
                            }
                            p {
                                color: #666;
                                tab-size: 12px;
                                padding-top: 10px;
                            }
                        }
                    }
                }
            }
            }
        }
    }
}
</style>
