<template>
    <div class="address_box">
        <div class="title">收货地址</div>
        <div class="add_btn">
            <el-button type="primary" @click="addVisible">添加地址</el-button>
        </div>
        <div class="table_box">
            <el-table
              ref="multipleTable"
              :data="addressData"
              tooltip-effect="dark"
              border
              style="width: 100%;min-height: 450px;"
            >
              <!-- @selection-change="handleSelectionChange" -->
              <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
              <el-table-column prop="name" label="收件人" width="120"></el-table-column>
              <el-table-column prop="phone" label="电话" width="150"></el-table-column>
              <el-table-column prop="email" label="邮箱" width="150"></el-table-column>
              <el-table-column prop="postcode" label="邮编" width="100"></el-table-column>
              <el-table-column label="地址">
                  <template #default="scope">
                      {{scope.row.continent}}{{scope.row.country}}{{scope.row.province}}{{scope.row.city}}{{scope.row.address}}
                  </template>
              </el-table-column>
              <el-table-column label="操作" width="60">
                <template #default="scope">
                  <div @click.stop="editAddress(scope.row)" class="list_s red_text hand">编辑</div>
                  <div @click.stop="delAddress(scope.row)" class="list_s red_text hand">删除</div>
                </template>
              </el-table-column>
            </el-table>
        </div>
        <div class="paga">
            <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
        </div>
        <AddressFrom ref="address" @getAddressData="getData" />
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
import Breadcrumb from "../../../components/Breadcrumb/index.vue";
import AddressFrom from "../../../components/AddressFrom/index.vue";
export default {
    mixins: [mixin],
    components: {
      Breadcrumb,
      AddressFrom
    },
    data() {
        return {
            addressData: []
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$requestJSON({
      	      url: 'shop/address/list',
      	      method: 'POST',
              data: {
                siteId: this.$store.state.siteId,
                page: this.currentPage,
                limit: this.pageSize,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.addressData = res.data
                   this.total = res.total
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
         // 添加地址
        addVisible() {
            this.$refs.address.addVisible()
        },
        // 编辑
        editAddress(row) {
            this.$refs.address.editAddress(row)
        },
        // 删除
        delAddress(row) {
            this.$confirm('确定删除吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
               this.$refs.address.delAddress(row)
            })
            .catch(() => {
              // this.$message({
              //   type: 'info',
              //   message: '已取消删除',
              // })
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.address_box {
    background: #fff;
    padding: 20px;
    padding-bottom: 0px;
    .title {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
    }
    .add_btn {
        margin: 10px 0;
    }
    .table_box {
        min-height: 150px;
    }
    /deep/.el-table th.el-table__cell {
        background: #f2f2f2;
    }
}
</style>