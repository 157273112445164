<template>
    <div class="product-manage">
        <Breadcrumb :data="breaData" />
        <div class="detail_box display_flex">
            <div class="box_l tac w_300">
                <div class="no">订单号：{{orderData.no}}</div>
                <div class="type color_a00">{{orderData.statusName}}</div>
            </div>
            <div class="box_r flex pt_30">
                <el-steps :active="orderData.status" align-center>
                  <el-step title="提交申请"></el-step>
                  <el-step title="卖家发货"></el-step>
                  <el-step title="确认收货"></el-step>
                  <el-step title="评价"></el-step>
                </el-steps>
            </div>
        </div>
        <!-- 付款信息 -->
        <div class="detail_box display_flex">
            <div class="box_l br_ccc flex">
                <div class="title">付款信息</div>
                <div class="lis">付款状态：{{orderData.statusName}}</div>
                <div class="lis">付款时间：{{orderData.payTime}}</div>
                <div class="lis">备注：{{orderData.remark}}</div>
            </div>
            <div class="box_l pl_20 flex">
                <div class="lis">收件人：{{orderData.addressName}}</div>
                <div class="lis">收货地址：{{orderData.addressInfo}}</div>
                <div class="lis">收件人电话：{{orderData.addressPhone}}</div>
            </div>
        </div>
        <!-- 商品 -->
        <div class="detail_box">
             <div class="table_box">
                    <div class="table_head bg_f2f display_flex">
                        <div style="width: 55%;" class="">商品</div>
                        <div style="width: 15%;" class="">价格</div>
                        <div style="width: 15%;" class="">数量</div>
                        <div style="width: 15%;" class="">总额</div>
                    </div>
                    <div class="complaint-goods">
                        <div class="table" v-for="(imte, index) in orderData.orderDetails" :key="index">
                             <!-- @click="openGoodDetail(imte.id)" -->
                            <div class="table_body display_flex hand">
                                <div style="width: 55%;" class="b_r_6e6">
                                    <div class="list_th">
                                    <div class="good display_flex">
                                        <div class="checkbox_li"><img :src="imte.logo" alt=""></div>
                                        <div class="text_box">
                                            <a class="text_hid_link">{{imte.title}}</a>
                                            <p>规格：{{imte.skuName}}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6">
                                    <div class="list" style="color: #FF5722;">￥ {{regFenToYuan(imte.price)}}</div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6">
                                    <div class="list" style="color: #999;">{{imte.num}}</div>
                                </div>
                                <div style="width: 15%;" class="">
                                    <div class="list" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.price*imte.num)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
import Breadcrumb from "../../../components/Breadcrumb/index.vue";
export default {
    mixins: [mixin],
    components: {
    Breadcrumb,
  },
    data() {
        return {
            breaData: [],
            orderData: {}
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$requestJSON({
      	      url: 'shop/order/detail',
      	      method: 'POST',
              data: {
                orderId: this.$route.query.orderId,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.orderData = res.data
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
    }
}
</script>
<style lang="scss" scoped>
.detail_box {
    margin: 10px;
    background: #fff;
    padding: 20px;
    .w_300 {
        width: 300px;
    }
    .box_l {
        .lis {
            height: 40px;
            line-height: 40px;
        }
        .type {
            padding-top: 30px;
            font-size: 24px;
            padding-bottom: 30px;
        }
    }
    .table_box {
                .table_head {
                    margin: 10px 0;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    color: #666;
                }
                .table {
                    border: #e6e6e6 1px solid;
                     margin: 10px 0;
                     .table_title {
                         background-color: #f2f2f2;
                         padding: 0px 15px;
                         line-height: 35px;
                         color: #666;
                         font-size: 14px;
                         border-bottom: #e6e6e6 1px solid;
                         .icon {
                             padding-left: 10px;
                         }
                     }
                     .b_r_6e6 {
                         border-right: #e6e6e6 1px solid;
                     }
                     .list_th {
                         padding: 9px 15px;
                        .good {
                            width: 100%;
                            img {
                                width: 100px;
                            }
                            .text_box {
                                padding-left: 10px;
                                padding-top: 20px;
                                .color_red {
                                    columns: #CB261C;
                                }
                                a {
                                    color: #CB261C !important;
                                }
                                p {
                                    color: #666;
                                    tab-size: 12px;
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                    .list {
                        text-align: center;
                        line-height: 130px;
                    }
                    .list_s {
                      text-align: center;
                    }
                }
            }
}
</style>
