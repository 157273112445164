<template>
    <div class="afterSale bg_fff">
        <div class="title bb_ccc">售后列表</div>
        <div class="table_box">
            <el-table :data="afterData" border style="width: 100%;min-height: 450px;">
              <el-table-column label="商品名称">
                  <template #default="scope">
                    <div class="display_flex">
                        <img class="img_table" :src="scope.row.orderDetail.logo">
                        <div>
                            <div class="title_table text_two_link">{{scope.row.orderDetail.title}}</div>
                            <div class="price"><span class="color_red">￥{{regFenToYuan(scope.row.orderDetail.price)}}</span> ×{{scope.row.orderDetail.num}}</div>
                        </div>
                    </div>
                  </template>
              </el-table-column>
              <el-table-column label="售后" width="180">
                  <template #default="scope">
                    {{scope.row.type==1? '换货':scope.row.type==2? '退货':scope.row.type==3? '退款':''}}
                  </template>
              </el-table-column>
              <el-table-column label="状态" width="180">
                  <template #default="scope">
                    {{scope.row.statusName}}
                  </template>
              </el-table-column>
              <el-table-column label="操作" width="220">
                <template #default="scope">
                  <div v-if="scope.row.type==2&&scope.row.status==1" class="list color_red hand" @click="courierNum(scope.row)">填写快递单号</div>
                  <div v-if="scope.row.status==0" class="list color_red hand" @click="getCancel(scope.row)">撤销申请</div>
                  <div class="list color_red hand" @click="getDetail(scope.row)">查看详情</div>
                  <div class="list color_red hand" @click="getDelete(scope.row)">删除</div>
                </template>
            </el-table-column>
            </el-table>
        </div>
        <div class="paga">
            <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
        </div>
        <CourierNum ref="courierNum" @getData="getData" />
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
import CourierNum from "../../../components/User/getCourierNum.vue";
export default {
    mixins: [mixin],
    components: {
      CourierNum
    },
    data() {
        return {
            afterData: []
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$requestJSON({
      	      url: 'shop/orderback/list',
      	      method: 'POST',
              data: {
                page: this.currentPage,
                limit: this.pageSize,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   console.log(res.data)
                  this.afterData = res.data
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 填写订单号
        courierNum(row) {
            console.log(row)
            this.$refs.courierNum.getVisible(row)
        },
        // 详情
        getDetail(row) {
            // 页面跳转
            this.$router.push({path:'/afterSalesInformation',query:{siteId: this.$store.state.siteId, id: row.id}})
        },
        // 撤销申请
        getCancel(row) {
            this.$confirm(`是否撤销"${row.orderDetail.title}"申请?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            //   closeOnClickModal: false
            })
              .then(() => {
                this.submitCancel(row)
              })
              .catch(() => {
                // this.$message({
                //   type: 'info',
                //   message: '已取消删除',
                // })
              })
        },
        submitCancel(row) {
            this.$requestJSON({
      	      url: 'shop/orderback/cancel',
      	      method: 'POST',
              data: {
                orderId: row.orderId,
                id: row.id,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.$message({
                      type: 'success',
                      message: '撤销申请成功!',
                    })
                    this.getData()
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        getDelete(row) {
          this.$confirm(`是否删除"${row.orderDetail.title}"?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          //   closeOnClickModal: false
          })
            .then(() => {
              this.submitDelete(row)
            })
            .catch(() => {
              // this.$message({
              //   type: 'info',
              //   message: '已取消删除',
              // })
            })
        },
        submitDelete(row) {
          this.$requestJSON({
      	      url: 'shop/orderback/del',
      	      method: 'POST',
              data: {
                id: row.id,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.$message({
                      type: 'success',
                      message: '删除成功!',
                    })
                    this.getData()
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        }
        
    }
}
</script>
<style lang="scss" scoped>
.afterSale {
    padding: 20px;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        line-height: 40px;
        // padding-top: 10px;
    }
    .table_box {
        margin: 20px 0;
        .img_table {
            width: 100px;
        }
        .title_table {
            padding-top: 10px;
            line-height: 25px;
            padding-left: 5px;
            height: 60px;
        }
        .price {
            padding-left: 5px;
        }
    }
}
</style>
