<template>
        <el-dialog
          title="添加收货地址"
          v-model="addressVisible"
          width="60%"
          top="50px"
        >
        <!-- :before-close="handleClose" -->
          <div class="addressFrom">
              <el-form ref="form" :model="addressFrom" label-width="120px">
                <el-form-item label="收件人" prop="name">
                  <el-input v-model="addressFrom.name"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                  <el-input v-model="addressFrom.phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="addressFrom.email"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="addressData">
                  <el-cascader v-model="addressFrom.addressData" :options="options" :props="optionProps"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                  <el-input v-model="addressFrom.address"></el-input>
                </el-form-item>
                <el-form-item label="邮编" prop="postcode">
                  <el-input v-model="addressFrom.postcode"></el-input>
                </el-form-item>
                <el-form-item label="是否默认地址">
                  <el-switch v-model="addressFrom.isDefault"></el-switch>
                </el-form-item>
              </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="addressVisible = false">取 消</el-button>
              <el-button type="primary" @click="addAddress">确 定</el-button>
            </span>
          </template>
        </el-dialog>
</template>
<script>
import options from "../../utils/addressJson";
import mixin from "../../views/mixin.js";
export default {
  mixins: [mixin],
    data() {
        return {
            addressVisible: false,
            options: options, // 地区级联
            editId: '', // 修改id
            addressFrom: {
                name: '', // 收件人姓名
                phone: '', // 电话
                email: '', // 邮箱
                address: '', // 地址
                country: '', // 国家
                province: '', // 省/州
                city: '', // 城市
                street: '', // 区/县
                postcode: '', // 邮编
                isDefault: 0, // 1默认
                addressData: ''
            },
            optionProps: {
                value: 'name',
                label: 'name',
                children: 'childrens'
            }
        }
    },
    methods: {
        // 添加地址
        addVisible() {
            this.addressVisible = true
            this.editId = ''
        },
        // 新增地址/修改地址
        addAddress() {
            if(this.editId) {
              this.addressFrom.id = this.editId
            }
            this.addressFrom.continent = this.addressFrom.addressData[0]
            this.addressFrom.country = this.addressFrom.addressData[1]
            this.addressFrom.province = this.addressFrom.addressData[2]
            this.addressFrom.city = this.addressFrom.addressData[3]
            this.addressFrom.isDefault = this.addressFrom.isDefault? 1:0
            this.addressFrom.token = window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
            this.addressFrom.siteId = this.$store.state.siteId
            this.$requestJSON({
      	      url: 'shop/address/save',
      	      method: 'POST',
              data: this.addressFrom
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                //    this.getAddressData()
                 this.$message.success ('编辑成功！')
                   this.$emit('getAddressData')
                   this.addressVisible = false
                   this.addressFrom = {
                       name: '', // 收件人姓名
                       phone: '', // 电话
                       email: '', // 邮箱
                       address: '', // 地址
                       country: '', // 国家
                       province: '', // 省/州
                       city: '', // 城市
                       street: '', // 区/县
                       postcode: '', // 邮编
                       isDefault: 0, // 1默认
                       addressData: ''
                   }
      	       } else {
      	         this.$message.error ('操作失败')
      	       }
      	    })
        },
        // 编辑
        editAddress(row) {
            this.editId = row.id
            this.$requestJSON({
      	      url: 'shop/address/detail',
      	      method: 'POST',
              data: {
                  id: row.id,
                  siteId: this.siteId,
                  token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                this.addressFrom = res.data
                this.addressFrom.addressData = []
                this.addressFrom.isDefault = this.addressFrom.isDefault? true:false
                this.addressFrom.addressData.push(this.addressFrom.continent,this.addressFrom.country,this.addressFrom.province,this.addressFrom.city)
                   this.addressVisible = true
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 删除
        delAddress(row) {
            this.$requestJSON({
      	      url: 'shop/address/del',
      	      method: 'POST',
              data: {
                  id: row.id,
                  siteId: this.siteId,
                  token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                //    this.getAddressData()
                  this.$emit('getAddressData')
                   this.$message.success (res.msg)
                   this.addressVisible = false
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.el-cascader {
    width: 100% !important;
}
</style>