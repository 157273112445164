<template>
    <div class="product-manage">
        <Breadcrumb :data="breaData" />
        <div class="detail_box display_flex">
            <div class="box_l tac w_300">
                <div class="no">售后状态</div>
                <div class="type color_a00">{{orderback.statusName}}</div>
            </div>
            <div class="box_r flex pt_30">
                <el-steps :active="orderback.status + 1" align-center>
                  <el-step title="提交申请"></el-step>
                  <el-step title="审核"></el-step>
                  <el-step title="完成"></el-step>
                </el-steps>
            </div>
        </div>
        <!-- 付款信息 -->
        <div class="detail_box display_flex">
            <div class="box_l br_ccc flex">
                <div class="title">售后信息</div>
                <div class="lis">售后状态：{{orderback.statusName}}</div>
                <div class="lis">申请时间：{{orderback.createTime}}</div>
                <div class="lis">商家同意申请时间：{{orderback.updateTime}}</div>
                <div class="lis">原因：{{orderback.remark}}</div>
                <div class="lis display_flex">
                    <div>图片：</div>
                    <div class="lis_img" v-for="(imte, i) in orderback.pics" :key="i">
                        <img :src="imte" alt=""></div>
                    </div>
            </div>
            <div class="box_l pl_20 flex">
                <div class="lis">收件人：{{order.addressName}}</div>
                <div class="lis">收货地址：{{order.addressInfo}}</div>
                <div class="lis">收件人电话：{{order.addressPhone}}</div>
            </div>
        </div>
        <!-- 商品 -->
        <div class="detail_box">
             <div class="table_box">
                    <div class="table_head bg_f2f display_flex">
                        <div style="width: 55%;" class="">商品</div>
                        <div style="width: 15%;" class="">价格</div>
                        <div style="width: 15%;" class="">数量</div>
                        <div style="width: 15%;" class="">总额</div>
                    </div>
                    <div class="complaint-goods">
                         <!-- v-for="(imte, index) in orderback.orderDetails" :key="index" -->
                        <div class="table">
                             <!-- @click="openGoodDetail(imte.id)" -->
                            <div class="table_body display_flex hand" v-for="(imte, index) in orderback.orderDetails" :key="index">
                                <div style="width: 55%;" class="b_r_6e6">
                                    <div class="list_th">
                                    <div class="good display_flex">
                                        <div class="checkbox_li"><img :src="imte.logo" alt=""></div>
                                        <div class="text_box">
                                            <a class="text_hid_link">{{imte.title}}</a>
                                            <p>规格：{{imte.skuName}}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6">
                                    <div class="list" style="color: #FF5722;">￥ {{regFenToYuan(imte.price)}}</div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6">
                                    <div class="list" style="color: #999;">{{imte.num}}</div>
                                </div>
                                <div style="width: 15%;" class="">
                                    <div class="list" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.price*imte.num)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
import Breadcrumb from "../../../components/Breadcrumb/index.vue";
export default {
    mixins: [mixin],
    components: {
    Breadcrumb,
  },
    data() {
        return {
            breaData: [],
            orderback: {},
            order: {}
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$requestJSON({
      	      url: 'shop/orderback/detail',
      	      method: 'POST',
              data: {
                id: this.$route.query.id,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   console.log(res.data)
                   this.orderback = res.data.orderback
                   this.order = res.data.order
                   this.orderback.pics = this.orderback.pics.split('|')
                   this.orderback.orderDetails = []
                   this.orderback.orderDetails.push(this.orderback.orderDetail)
      	       }
      	    })
        },
    }
}
</script>
<style lang="scss" scoped>
.detail_box {
    margin: 10px;
    background: #fff;
    padding: 20px;
    .w_300 {
        width: 300px;
    }
    .box_l {
        .lis {
            height: 40px;
            line-height: 40px;
            .lis_img {
                padding-right: 10px;
            }
        }
        .type {
            padding-top: 30px;
            font-size: 24px;
            padding-bottom: 30px;
        }
    }
    .table_box {
                .table_head {
                    margin: 10px 0;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    color: #666;
                }
                .table {
                    border: #e6e6e6 1px solid;
                     margin: 10px 0;
                     .table_title {
                         background-color: #f2f2f2;
                         padding: 0px 15px;
                         line-height: 35px;
                         color: #666;
                         font-size: 14px;
                         border-bottom: #e6e6e6 1px solid;
                         .icon {
                             padding-left: 10px;
                         }
                     }
                     .b_r_6e6 {
                         border-right: #e6e6e6 1px solid;
                     }
                     .list_th {
                         padding: 9px 15px;
                        .good {
                            width: 100%;
                            img {
                                width: 100px;
                            }
                            .text_box {
                                padding-left: 10px;
                                padding-top: 20px;
                                .color_red {
                                    columns: #CB261C;
                                }
                                a {
                                    color: #CB261C !important;
                                }
                                p {
                                    color: #666;
                                    tab-size: 12px;
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                    .list {
                        text-align: center;
                        line-height: 130px;
                    }
                    .list_s {
                      text-align: center;
                    }
                }
            }
}
</style>
