<template>
    <div class="password_box">
        <div class="title">支付密码</div>
        <div class="password_form">
            <el-form
              :model="payForm"
              :rules="payRules"
              ref="ruleForm"
              label-width="140px"
              class="demo-ruleForm"
            >
              <el-form-item label="登录密码" prop="loginpassword">
                <el-input v-model="payForm.loginpassword" type="password"></el-input>
              </el-form-item>
              <el-form-item label="新的支付密码" prop="password">
                <el-input v-model="payForm.password" type="password"></el-input>
              </el-form-item>
              <el-form-item label="确认新的支付密码" prop="repassword">
                <el-input v-model="payForm.repassword" type="password"></el-input>
              </el-form-item>
            </el-form>
            <div class="btn_box">
                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
	import mixin from '../../../views/mixin.js'
    import md5 from 'js-md5'
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				payForm: {
                    loginpassword: '', // 登录密码
                    password: '', // 支付密码
                    repassword: '', // 确认支付密码
                },
                payRules: {
                    loginpassword: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
                    password: [{ required: true, message: '请输入支付密码', trigger: 'blur' }],
                    repassword: [{ required: true, message: '请输入确认支付密码', trigger: 'blur' }],
                }
			};
		},
		created() {
			// this.getSiteId()
            this.payForm.token = window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
            this.payForm.siteId = this.$store.state.siteId
		},
        // mounted() {
        //     this.payForm.token = window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
        //     this.payForm.siteId = this.$store.state.siteId
        // },
		methods: {
            // 提交
            submitForm(formName) {
              this.$refs[formName].validate((valid) => {
                if (valid) {
                    // this.payForm.loginpassword = md5(this.payForm.loginpassword)
                    // this.payForm.password = md5(this.payForm.password)
                    // this.payForm.repassword = md5(this.payForm.repassword)
                  this.getData()
                } else {
                  console.log('请输入!')
                }
              })
            },
            // 清空form
            resetForm(formName) {
              this.$refs[formName].resetFields()
            },
            // 修改密码接口
			getData() {
				this.$requestJSON({
        		  url: 'shop/user/payword',
        		  method: 'POST',
				  data: {
                      loginpassword: md5(this.payForm.loginpassword),
                      password: md5(this.payForm.password),
                      repassword: md5(this.payForm.repassword),
                      token: this.payForm.token,
                      siteId: this.payForm.siteId
                  }
        		}).then(res => {
        		//   this.loading = false
        		   if(res.code === 0) {
                     this.$message.success ('修改成功！')
                     this.$router.push({path: '/accountSecurity', query:{siteId: this.$route.query.siteId? this.$route.query.siteId:this.$store.state.siteId? this.$store.state.siteId:this.siteId}})
        		   } else {
        		     this.$message.error (res.msg)
        		   }
        		})
			},
		}
	};
</script>
<style lang="scss" scoped>
.password_box {
    background: #fff;
    padding: 20px;
    padding-bottom: 40px;
    width: 100%;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        line-height: 40px;
        // padding-top: 10px;
    }
    .password_form {
        width: 60%;
        margin: 30px auto;
        .btn_box {
            // padding-left: 140px;
            margin-left: 140px;
            width: calc(100% - 140px);
            padding-top: 30px;
            /deep/.el-button--primary {
                width: 100%;
            }
        }
    }
}
</style>
