<template>
  <div class="condition">
    <!-- 已选筛选条件 -->
    <div class="term display_flex">
      <span>已选条件：</span>
      <div class="filtro_search flex">
        <a v-for="(imte, index) in selectedData" :key="index"><b>{{imte.title}}</b><em>{{imte.name}}</em><Close @click="closeSelectedLIst(index)" style="width: 16px; height: 1em;padding: 0 0px 0 5px; vertical-align: text-top;font-size: 16px;color: #e4393c;" /></a>
      </div>
    </div>
    <!-- 品牌筛选 -->
    <div v-if="!brandId" class="term display_flex" :class="isCheckBrand? 'morechoose':''">
      <span>商品品牌：</span>
      <div v-if="!isCheckBrand" class="check_flex_right">
        <a
          @click="onBrand(0)"
          :class="brandId == 0? 'layui-btn':''"
          class="layui-btn-xs"
          >全部</a
        >
        <a v-for="(imte, index) in brandData" :key="index" @click="onBrand(imte)" :class="brandId == imte.id? 'layui-btn':''" class="layui-btn-xs">{{imte.name}}</a
        >
      </div>
      <button v-if="brandData&&!isCheckBrand" @click="isCheckBrand=true" class="multi_choice">
        <Plus
          text-anchor="middle"
          style="width: 16px; height: 1em; margin-right: 8px; vertical-align: text-top;"
        />多选
      </button>
       <!-- 品牌多选 -->
    <div v-if="isCheckBrand" class="term check_flex_right">
       <el-checkbox-group v-model="checkBrandList">
         <el-checkbox v-for="(imte, index) in brandData" :key="index" :label="imte">{{imte.name}}</el-checkbox>
       </el-checkbox-group>
       <div class="foot_box">
         <div class="display_flex foot_btn">
           <button @click="onCheckBrand" class="layui-btn layui-btn-xs btn-search">确定</button>
           <button @click="onCheckClose" class="layui-btn-fff layui-btn-xs btn-search">取消</button>
         </div>
       </div>
    </div>
    </div>
    <!-- 仓库筛选 -->
    <!-- <div class="term display_flex">
      <span>区域：</span>
      <div class="check_flex_right">
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val=""
          class="layui-btn layui-btn-xs"
          >全部</a
        >
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val="123"
          class="layui-btn-xs"
          >US</a
        >
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val="124"
          class="layui-btn-xs"
          >UK</a
        >
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val="125"
          class="layui-btn-xs"
          >CN</a
        >
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val="126"
          class="layui-btn-xs"
          >DE</a
        >
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val="127"
          class="layui-btn-xs"
          >FR</a
        >
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val="128"
          class="layui-btn-xs"
          >HK</a
        >
        <a
          data-filter-field="WarehouseIds_String"
          data-filter-val="129"
          class="layui-btn-xs"
          >CZ</a
        >
      </div>
      <button class="multi_choice">
        <Plus
          style="
            width: 16px;
            height: 1em;
            margin-right: 8px;
            vertical-align: text-top;
          "
        />多选
      </button>
    </div> -->
    <!-- 上新时间 -->
    <div class="term display_flex">
      <span>上新时间：</span>
      <div class="check_flex_right">
        <a
          @click="onNewTime(0)"
          :style="newTime===0? 'background-color: #cb261c; color: #fff':''"
          class="layui-btn-xs"
          >全部</a>
        <a
         @click="onNewTime(7)"
         :style="newTime===7? 'background-color: #cb261c; color: #fff':''"
          class="layui-btn-xs"
          >近7天</a>
        <a
          @click="onNewTime(15)"
          :style="newTime===15? 'background-color: #cb261c; color: #fff':''"
          class="layui-btn-xs"
          >近15天</a>
        <a
          @click="onNewTime(30)"
          :style="newTime===30? 'background-color: #cb261c; color: #fff':''"
          class="layui-btn-xs"
          >近30天</a>
        <a
          @click="onNewTime(60)"
          :style="newTime===60? 'background-color: #cb261c; color: #fff':''"
          class="layui-btn-xs"
          >近60天</a>
      </div>
    </div>

    <!-- 仓库类型 -->
    <!-- <div class="term display_flex">
      <span>仓库类型：</span>
      <div class="check_flex_right">
        <a
          data-filter-field="IsAllowUsePlatformWarehouse"
          data-filter-val=""
          class="layui-btn layui-btn-xs"
          >全部</a
        >
        <a
          data-filter-field="IsAllowUsePlatformWarehouse"
          data-filter-val="0"
          class="layui-btn-xs"
          >认证仓</a
        >
        <a
          data-filter-field="IsAllowUsePlatformWarehouse"
          data-filter-val="1"
          class="layui-btn-xs"
          >平台仓</a
        >
        <a
          data-filter-field="IsAllowUsePlatformWarehouse"
          data-filter-val="2"
          class="layui-btn-xs"
          >自建仓</a
        >
      </div>
    </div> -->
  </div>
  <!-- 全选 -->
  <!-- <div class="check-all display_flex">
    <div class="op-tabLeft flex">
      <ul>
        <li>
          <input
            type="checkbox"
            lay-skin="primary"
            lay-filter="commonChooseAllFilter"
            class="commonChooseAll"
            autocomplete="off"
          />
          <span class="ml_8px">全选</span>
        </li>
        <li><p class="alreadGet"></p></li>
      </ul>
    </div>
    <div class="op-getRight flex">
      <ul class="display_flex">
        <li style="display: none"><button class="copybtn"></button></li>
        <li><a class="down-Data">下载数据包</a></li>
        <li><a class="collect">加入收藏夹</a></li>
        <li><a class="down-Sku">下载SPU</a></li>
        <li><a class="copy-Sku">复制SPU</a></li>
      </ul>
    </div>
    <div class="clear"></div>
  </div> -->
  <!-- 排序 -->
  <div class="sort">
    <div class="filter-pro display_flex">
      <ul class="trig-ss display_flex">
        <!-- <li class="ty n">
          <a
            class="order_click"
            data-filter-field="orderby"
            data-filter-val="0"
            >最新</a
          >
        </li> -->
        <li @click="onType('saleUp')" :class="type=='saleUp'? 'n':''" class="ty">
          <a
            class="order_click"
            data-filter-field="orderby"
            data-filter-val="4"
          >
           <span>销量</span>
			     <span class="filter-price">
              <Bottom style="width: 16px;height: 14px;vertical-align: middle;" />
            </span>
          </a>
        </li>
        <li @click="onType('priceUp')" :class="type=='priceUp'? 'n':''" class="ty">
          <a
            class="order_click"
            data-filter-field="orderby"
            data-filter-val="3"
          >
            <span>价格</span>
            <span class="filter-price">
              <CaretTop v-if="type=='priceUp'" style="width: 16px;height: 14px;vertical-align: middle;" />
              <DCaret v-else style="width: 16px;height: 14px;vertical-align: middle;" />
            </span>
          </a>
        </li>
        <li @click="onType('click')" :class="type=='click'? 'n':''" class="ty">
          <a
            class="order_click"
            data-filter-field="orderby"
            data-filter-val="5"
          >
            访问数 
			      <span class="filter-price">
              <CaretTop v-if="type=='click'" style="width: 16px;height: 14px;vertical-align: middle;" />
              <DCaret v-else style="width: 16px;height: 14px;vertical-align: middle;" />
            </span>
          </a>
        </li>
        <li @click="onType('stockUp')" :class="type=='stockUp'? 'n':''" class="ty">
          <a
            class="order_click"
            data-filter-field="orderby"
            data-filter-val="8"
          >
            库存
            <span class="filter-price">
              <Bottom style="width: 16px; height: 14px;vertical-align: middle;" />
            </span>
          </a>
        </li>
        <!-- <li class="seehas">
		   <input
            type="checkbox"
            lay-skin="primary"
            lay-filter="commonChooseAllFilter"
            class="commonChooseAll"
            autocomplete="off"
          />
          <span class="ml_8px">仅看有货 </span>
        </li> -->
        <li class="price ml_16">
          库存：
          <input v-model="minStock" type="text" class="txtprice" onkeyup="value=value.replace(/[^\d]/g,'')"/>
          -
          <input v-model="maxStock" type="text" class="txtprice" onkeyup="value=value.replace(/[^\d]/g,'')" />
        </li>
        <li class="price ml_16">
          价格：
          <input @input="checkInput('min')" v-model="minPrice" type="text" class="txtprice" />
          -
          <input @input="checkInput('max')" v-model="maxPrice" type="text" class="txtprice" />
          <button @click="onStock" class="btn-search">确定</button>
        </li>
      </ul>
      <!-- <div class="pro-pages display_flex">
		<div class="next">
			<ArrowUp
        	  style="
        	    width: 16px;
        	    height: 1em;
        	    margin-top: 4px;
        	  "
        	/>
		</div>
		<div class="next">
			<ArrowDown
        	  style="
        	    width: 16px;
        	    height: 1em;
        	    margin-top: 4px;
        	  "
        	/>
		</div>
        <div class="page_top">1/12</div>
      </div> -->
    </div>
  </div>
  
</template>

<script>
import mixin from "../../views/mixin.js";
export default {
  mixins: [mixin],
  data() {
    return {
	  	checked1: false,
      selectedData: [], // 已选条件
      isCheckBrand: false,
      brandData: [],
      checkBrandList: [],
      brandId: 0,
      newTime: 0,
      type: '',
      minStock: '',
      maxStock: '',
      minPrice: '',
      maxPrice: ''
	  };
  },
  created() {
    // this.getSiteId()
    this.getBrandData()
  },
  methods: {
    // getData() {
    //   this.getBrandData()
    // },
    // 获取品牌数据
    getBrandData() {
      this.$requestJSON({
      	  url: 'shop/brand/list/' + this.$store.state.siteId,
      	  method: 'POST'
      	}).then(res => {
      	//   this.loading = false
      	   if(res.code === 0) {
             this.brandData = res.data
      	   } else {
      	     this.$message.error (res.msg)
      	   }
      	})
    },
    // 品牌
    onBrand(imte) {
      if(imte != 0) {
        this.brandId = imte.id
        this.selectedData.push({name: imte.name, title: '商品品牌：'})
      } else {
        this.brandId = imte
      }
      this.$store.state.brandId = this.brandId
    },
    // 品牌多选
    onCheckBrand() {
      let id = []
      let name = []
      this.checkBrandList.map(imte => {
        id.push(imte.id)
        name.push(imte.name)
      })
      this.brandId = id.join(',')
      this.$store.state.brandId = this.brandId
      this.selectedData.push({name: name.join(','), title: '商品品牌：'})
    },
    // 品牌多选取消
    onCheckClose() {
      this.checkBrandList = [] // 清空多选
      this.isCheckBrand = false // 关闭多选
    },
    // 删除品牌搜索条件
    closeSelectedLIst(index) {
      this.brandId = 0
      this.$store.state.brandId = this.brandId
      this.checkBrandList = [] // 清空多选
      this.isCheckBrand = false // 关闭多选
      this.selectedData.splice(index, 1)
    },
    // 最新
    onNewTime(i) {
      this.newTime = i
      this.$store.state.newTime = this.newTime
    },
    // 类型
    onType(type) {
      this.type = type
      this.$store.state.searchType = this.type
    },
    // 库存量
    onStock() {
      this.$store.state.searchStock = {
        minStock: this.minStock < this.maxStock? this.minStock:this.maxStock,
        maxStock: this.maxStock > this.minStock? this.maxStock:this.minStock
      }
      this.$store.state.searchPrice = {
        minPrice: this.minPrice < this.maxPrice? this.minPrice:this.maxPrice,
        maxPrice: this.maxPrice > this.minPrice? this.maxPrice:this.minPrice
      }
      this.$store.state.search = true
    },
    checkInput(i) {
      // console.log(this.num)
      if(i=='min') {
        this.minPrice = this.dealInputVal(this.minPrice)
      } else {
        this.maxPrice = this.dealInputVal(this.maxPrice)
      }
    },
    dealInputVal(value) {
      value = value.replace(/^0*(0\.|[1-9])/, "$1");
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字而不是字符
      value = value.replace(/\.{1,}/g, "."); //只保留第一个.清除多余的
      value = value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      value = value.replace(/^(-)*(\d*)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      value =
        value.indexOf(".") > 0
          ? value.split(".")[0].substring(0, 10) + "." + value.split(".")[1]
          : value.substring(0, 10);
          if(value == ""){
            this.disabled = true;
          }else if(value == Number(0)){
            this.disabled = true;
          }else if(value == "0."){
             this.disabled = true;
          }else{
            this.disabled = false;
          }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
input, textarea, button, select {
    outline: none;
    resize: none;
    border: 1px solid #d5d5d5;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    font-size: 14px;
}
input, textarea {
    padding: 3px 5px;
    border-radius: 2px;
    color: #666;
    background: #fff;
}
.price input {
    padding-left: 5px;
    width: 40px;
}

.condition {
  background-color: #fff;
  padding: 5px 15px;
  margin-bottom: 10px;
  .filtro_search {
    margin-bottom: 8px;
    a {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        background: #f3f3f3;
        padding: 0 7px;
        margin-top: 6px !important;
        margin-right: 15px !important;
    }
    a b {
        font-weight: 500;
        line-height: 24px;
        color: #666;
        display: inline-block;
        vertical-align: middle;
    }
    a em {
        color: #e4393c;
        line-height: 24px;
        max-width: 150px;
        min-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        font-style: normal;
    }
  }
  /deep/.el-checkbox {
    height: 32px;
  }
  .morechoose {
    border: 2px solid #cb261c !important;
    background: #fff7f6;
  }
  .foot_box {
    width: 100%;
    padding-bottom: 8px;
    .foot_btn {
      width: 80px;
      margin: 0 auto;
    }
    .layui-btn {
      background-color: #cb261c;
      border: 0 none;
    }
    .layui-btn-fff {
      background-color: #fff;
      border: 1px solid #ccc;
      color: #666;
      margin-left: 8px;
    }
  }
  .layui-btn {
    background-color: #cb261c;
  }
  .layui-btn-xs {
    height: 22px;
    line-height: 22px;
    padding: 0 5px;
    font-size: 12px;
  }
  .layui-btn {
    color: #fff !important;
    white-space: nowrap;
    text-align: center;
    border-radius: 2px;
  }
  .term {
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(238, 238, 238);
    span {
      font-weight: 700;
      width: 90px;
      display: inline-block;
      line-height: 36px;
    }
    button {
      // margin: 0 15px;
      background: none;
      line-height: 22px;
      display: block;
    }
    .multi_choice {
      margin-top: 6px !important;
      height: 24px;
      line-height: 22px;
      padding: 0 7px;
      margin-right: 0px !important;
      border: 1px solid #ccc;
      color: #666;
      transition: all 0.3s;
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .multi_choice:hover {
      color: #cb261c;
      border-color: #cb261c;
      transition: all 0.3s;
    }
    .check_flex_right {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      max-height: 68px;
      overflow: hidden;
      a {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
        display: inline-block;
        margin: 0 15px;
        color: #333;
      }
    }
  }
}
.check-all {
  background-color: #fff;
  line-height: 30px;
  padding: 10px 0;
  position: relative;
  top: 0px;
  margin: 0px auto 10px auto;
  z-index: 9;
  box-shadow: 0 0 5px #eee;
  width: 100%;
  left: 0px;
  input {
    vertical-align: middle;
    margin-top: -3px;
    height: 14px;
  }
  .op-tabLeft {
    margin-left: 20px;
  }
  .op-getRight {
    li {
      margin-left: 25px;
      .down-Data {
        background: url(../../assets/img/xiazai.png) no-repeat left center;
        background-size: 16px;
      }
      .collect {
        background: url(../../assets/img/aixin.png) no-repeat left center;
        background-size: 16px;
      }
      .down-Sku {
        background: url(../../assets/img/xiazai2.png) no-repeat left center;
        background-size: 16px;
      }
      .copy-Sku {
        background: url(../../assets/img/fuzhi.png) no-repeat left center;
        background-size: 16px;
      }
      a {
        color: #666;
        display: inline-block;
        padding-left: 20px;
        vertical-align: top;
      }
    }
  }
}
.sort {
    background-color: #fff;
    margin-bottom: 10px;
	.filter-pro {
	    border: 1px solid #ddd;
	    overflow: hidden;
		height: 36px;
	    margin-bottom: 16px;
		.trig-ss {
			// display: flex;
			// flex-wrap: wrap;
			.ty {
				// width: 50px;
			}
			.seehas {
				padding: 0 8px !important;
				display: flex;
				input {
					margin-top: 11px;
				}
			}
			li {
				line-height: 36px;
				overflow: hidden;
				// flex: 1;
				// width: 100px;
				a {
					line-height: 36px;
					border-right: 1px solid #ddd;
					color: #333;
					padding: 0 10px;
					display: block;
					text-align: center;
				}
				button {
					height: 24px;
    				// vertical-align: top;
    				width: 50px;
    				background: #CB261C;
    				color: #fff;
    				line-height: 24px;
					border: 0 none;
					margin-left: 5px;
				}
			}
			.n {
				a {
				color: #e4393c !important;
				background: #eee;
			}
			}
		}
		.pro-pages {
			text-align: right;
		    padding-top: 6px;
			height: 28px;
			margin-left: auto;
			.next {
				border: 1px solid #ddd;
				vertical-align: top;
    			height: 22px;
    			width: 22px;
				margin-right: 5px;
				line-height: 22px;
				text-align: center;
				color: #999;
				margin-left: auto
			}
			div {
				color: #999;
				// margin-left: auto
			}
			.page_top {
				padding-right: 5px;
				line-height: 24px;
			}
		}
	}
}
</style>
