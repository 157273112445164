<template>
    <div class="message_details_box">
        <div class="title_box">
            <div class="title">产品信息修改通知</div>
            <div class="date flex">2022/1/18 16:02:51</div>
        </div>
        <div class="content">
            <p class="hi">尊敬的用户：</p>
            <p class="text">请下载附件查阅参考，并及时调整自己店铺的挂单价格，以防止后面出单发现成本变化造成亏损。请下载附件查阅参考，并及时调整自己店铺的挂单价格，以防止后面出单发现成本变化造成亏损。请下载附件查阅参考，并及时调整自己店铺的挂单价格，以防止后面出单发现成本变化造成亏损。请下载附件查阅参考，并及时调整自己店铺的挂单价格，以防止后面出单发现成本变化造成亏损。</p>
        </div>
        <div class="btn_box">
            <el-button type="primary" @click="openMessage">返回</el-button>
        </div>
    </div>
</template>
<script>
	import mixin from '../../../views/mixin.js'
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				checked1: false,
                messageData: []
			};
		},
		created() {
			// this.getSiteId()
		},
		methods: {
            // 获取数据
			getData() {
				this.$requestJSON({
        		  url: 'shop/' + this.siteId,
        		  method: 'POST',
				  data: {
					  token: window.localStorage.getItem(this.domain + this.siteId+'Token')? window.localStorage.getItem(this.domain + this.siteId+'Token'):''
				  }
        		}).then(res => {
        		//   this.loading = false
        		   if(res.code === 0) {
        		     console.log(res.data)
                     this.messageData = res.data
                     this.total = res.total
        		   } else {
        		     this.$message.error (res.msg)
        		   }
        		})
			},
            // 返回
            openMessage() {
                this.$router.push({path:'/message',query:{siteId: this.$route.query.siteId? this.$route.query.siteId:this.$store.state.siteId? this.$store.state.siteId:this.siteId}})
            }
		}
	};
</script>
<style lang="scss" scoped>
.message_details_box {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    width: 100%;
    .title_box {
        border-bottom: 1px solid #ccc;
        height: 80px;
        text-align: center;
        .title {
            height: 40px;
            line-height: 40px;
            font-weight: 600;
            font-size: 18px;
        }
    }
    .content {
        min-height: 200px;
        padding-top: 30px;
        .hi {
            padding-bottom: 10px;
        }
        .text {
           line-height: 30px;
        }
    }
    .btn_box {
        text-align: center;
    }
}
</style>
