<template>
  <div
    ref="myChart"
    id="myChart"
    :style="{ width: '800px', height: '400px' }"
  ></div>
</template>
<script>
export default {
  props: {
    chartData: Object
  },
  mounted() {
    this.$nextTick(()=>{
      console.log(this.chartData, 'chartData')
      setTimeout(() => {
        this.drawLine()
      },100)
    })
  },
  methods: {
    drawLine() {
      const dom = this.$refs['myChart'];
      const myChart = this.$echarts.init(dom); // 初始化echarts实例
      const option = {
        xAxis: {
          type: 'category',
          data: this.chartData.xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.chartData.yAxis,
            type: 'line',
            smooth: true
          }
        ]
      };
      // 设置实例参数
      myChart.setOption(option);
    }
  }
};
</script>
