<template>
   <div class="message_details_box">
        <div class="title_box">
            <div class="title">{{viewData.title}}</div>
            <div class="date flex">{{viewData.createTime}}</div>
        </div>
        <div class="content">
            <!-- <p class="hi">尊敬的用户：</p> -->
            <p class="text">{{viewData.content}}</p>
        </div>
        <!-- <div class="btn_box">
            <el-button type="primary" @click="openMessage">返回</el-button>
        </div> -->
    </div>
</template>
<script>
import mixin from "../../views/mixin.js";
export default {
    mixins: [mixin],
    data() {
        return {
            viewData: {}
        }
    },
    created() {
      this.getSiteId()
    },
    methods: {
        getData() {
            this.$requestJSON({
        		  url: 'shop/article/view?id=' + this.$route.query.id,
        		  method: 'POST',
				  data: {
					  token: window.localStorage.getItem(this.domain + this.siteId+'Token')? window.localStorage.getItem(this.domain + this.siteId+'Token'):'',
                      siteId: this.siteId
				  }
        		}).then(res => {
        		//   this.loading = false
        		   if(res.code === 0) {
        		     console.log(res.data)
                     this.viewData = res.data
        		   } else {
        		     this.$message.error (res.msg)
        		   }
        		})
			},
        }
    }
</script>
<style lang="scss" scoped>
.message_details_box {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    width: 100%;
    .title_box {
        border-bottom: 1px solid #ccc;
        height: 80px;
        text-align: center;
        .title {
            height: 40px;
            line-height: 40px;
            font-weight: 600;
            font-size: 18px;
        }
    }
    .content {
        min-height: 230px;
        padding-top: 30px;
        .hi {
            padding-bottom: 10px;
        }
        .text {
           line-height: 30px;
        }
    }
    .btn_box {
        text-align: center;
    }
}
</style>