<template>
    <div class="message_box">
        <div class="title_box display_flex">
            <div class="title color_red flex">消息中心</div>
            <div class="btn_box">
                <el-button type="primary">标记已读</el-button>
            </div>
        </div>
        <div class="content">
            <div class="list">
                <div class="list_title_box display_flex">
                    <div class="checkbox">
                        <el-checkbox v-model="checked1"></el-checkbox>
                    </div>
                    <div class="title">产品信息修改通知</div>
                    <div class="date flex">2022/1/18 16:02:51</div>
                </div>
                <div class="list_text_box">
                    <div class="text text_hid_link">尊敬的赛盈用户您好：     以下商品的图片已更改，原来的产品图片会被投诉。请您及时核实更换最新图片。网站上产品数据包已经更新，请放心下载使用！ 感谢您对赛盈平台的信任和支持。 SKU                            标题 99433464          FCH 拆装 双门 双隔层 密度板 餐边柜 80*36*80cm 棕色 简</div>
                    <div class="text_btn color_red hand" @click="openDetails">查看详情></div>
                </div>
            </div>
            <div class="list">
                <div class="list_title_box display_flex">
                    <div class="checkbox">
                        <el-checkbox v-model="checked1"></el-checkbox>
                    </div>
                    <div class="title">产品信息修改通知</div>
                    <div class="date flex">2022/1/18 16:02:51</div>
                </div>
                <div class="list_text_box">
                    <div class="text text_hid_link">尊敬的赛盈用户您好：     以下商品的图片已更改，原来的产品图片会被投诉。请您及时核实更换最新图片。网站上产品数据包已经更新，请放心下载使用！ 感谢您对赛盈平台的信任和支持。 SKU                            标题 99433464          FCH 拆装 双门 双隔层 密度板 餐边柜 80*36*80cm 棕色 简</div>
                    <div class="text_btn color_red hand" @click="openDetails">查看详情></div>
                </div>
            </div>
            <div class="list">
                <div class="list_title_box display_flex">
                    <div class="checkbox">
                        <el-checkbox v-model="checked1"></el-checkbox>
                    </div>
                    <div class="title">产品信息修改通知</div>
                    <div class="date flex">2022/1/18 16:02:51</div>
                </div>
                <div class="list_text_box">
                    <div class="text text_hid_link">尊敬的赛盈用户您好：     以下商品的图片已更改，原来的产品图片会被投诉。请您及时核实更换最新图片。网站上产品数据包已经更新，请放心下载使用！ 感谢您对赛盈平台的信任和支持。 SKU                            标题 99433464          FCH 拆装 双门 双隔层 密度板 餐边柜 80*36*80cm 棕色 简</div>
                    <div class="text_btn color_red hand" @click="openDetails">查看详情></div>
                </div>
            </div>
            <div class="list">
                <div class="list_title_box display_flex">
                    <div class="checkbox">
                        <el-checkbox v-model="checked1"></el-checkbox>
                    </div>
                    <div class="title">产品信息修改通知</div>
                    <div class="date flex">2022/1/18 16:02:51</div>
                </div>
                <div class="list_text_box">
                    <div class="text text_hid_link">尊敬的赛盈用户您好：     以下商品的图片已更改，原来的产品图片会被投诉。请您及时核实更换最新图片。网站上产品数据包已经更新，请放心下载使用！ 感谢您对赛盈平台的信任和支持。 SKU                            标题 99433464          FCH 拆装 双门 双隔层 密度板 餐边柜 80*36*80cm 棕色 简</div>
                    <div class="text_btn color_red hand" @click="openDetails">查看详情></div>
                </div>
            </div>
            <div class="list">
                <div class="list_title_box display_flex">
                    <div class="checkbox">
                        <el-checkbox v-model="checked1"></el-checkbox>
                    </div>
                    <div class="title">产品信息修改通知</div>
                    <div class="date flex">2022/1/18 16:02:51</div>
                </div>
                <div class="list_text_box">
                    <div class="text text_hid_link">尊敬的赛盈用户您好：     以下商品的图片已更改，原来的产品图片会被投诉。请您及时核实更换最新图片。网站上产品数据包已经更新，请放心下载使用！ 感谢您对赛盈平台的信任和支持。 SKU                            标题 99433464          FCH 拆装 双门 双隔层 密度板 餐边柜 80*36*80cm 棕色 简</div>
                    <div class="text_btn color_red hand" @click="openDetails">查看详情></div>
                </div>
            </div>
            <div class="list">
                <div class="list_title_box display_flex">
                    <div class="checkbox">
                        <el-checkbox v-model="checked1"></el-checkbox>
                    </div>
                    <div class="title">产品信息修改通知</div>
                    <div class="date flex">2022/1/18 16:02:51</div>
                </div>
                <div class="list_text_box">
                    <div class="text text_hid_link">尊敬的赛盈用户您好：     以下商品的图片已更改，原来的产品图片会被投诉。请您及时核实更换最新图片。网站上产品数据包已经更新，请放心下载使用！ 感谢您对赛盈平台的信任和支持。 SKU                            标题 99433464          FCH 拆装 双门 双隔层 密度板 餐边柜 80*36*80cm 棕色 简</div>
                    <div class="text_btn color_red hand" @click="openDetails">查看详情></div>
                </div>
            </div>
            <div class="paga">
                <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
            </div>
        </div>
    </div>
</template>
<script>
	import mixin from '../../../views/mixin.js'
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				checked1: false,
                messageData: []
			};
		},
		created() {
			// this.getSiteId()
		},
		methods: {
            // 获取数据
			getData() {
				this.$requestJSON({
        		  url: 'shop/' + this.siteId,
        		  method: 'POST',
				  data: {
					  token: window.localStorage.getItem(this.domain + this.siteId+'Token')? window.localStorage.getItem(this.domain + this.siteId+'Token'):''
				  }
        		}).then(res => {
        		//   this.loading = false
        		   if(res.code === 0) {
        		     console.log(res.data)
                     this.messageData = res.data
                     this.total = res.total
        		   } else {
        		     this.$message.error (res.msg)
        		   }
        		})
			},
            // 详情页面跳转
            openDetails() {
                this.$router.push({path:'/message_details',query:{siteId: this.$route.query.siteId? this.$route.query.siteId:this.$store.state.siteId? this.$store.state.siteId:this.siteId}})
            }
		}
	};
</script>
<style lang="scss" scoped>
.message_box {
    background: #fff;
    margin-bottom: 20px;
    width: 100%;
    .title_box {
        padding: 0 20px;
        margin-top: 20px;
    }
    .title {
        height: 40px;
        line-height: 40px;
        font-weight: 600;
        font-size: 18px;
    }
    .content {
        min-height: 300px;
        padding: 20px;
        .list {
            border-bottom: 1px solid #f7f7f7;
            .checkbox {
                width: 30px;
            }
            .date {
                height: 40px;
                line-height: 43px;
                padding-left: 50px;
            }
            .list_text_box {
                width: 100%;
                .text {
                    height: 20px;
                    color: #999;
                }
                .text_btn {
                    text-align: right;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>
