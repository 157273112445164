<template>
	<div class="search_list">
		<div class="headtopmargin layui-form edition_center">
			<div class="four_goods" :class="{'po-r':$route.query.select, 'bgf5':$route.query.select==3}">
				<!-- 当前位置 -->
				<Breadcrumb />
				<div class="screen_box display_flex edition_center" v-if="$route.query.select=='0'||!$route.query.select">
					<div class="screen_r flex">
						<ScreenBox />
						<SearchGoodList />
					</div>
				</div>
			</div>
			<iframe v-if="$route.query.select==1" class="iframe-box" width="100%" height="1000px" :src="'https://s.taobao.com/search?q='+$route.query.title" frameborder="0"></iframe>
			<iframe v-if="$route.query.select==2" class="iframe-box" width="100%" height="1000px" :src="'https://list.tmall.com/search_product.htm?q='+$route.query.title" frameborder="0"></iframe>
			<iframe v-if="$route.query.select==3" class="iframe-box" width="100%" height="1000px" :src="'https://s.1688.com/selloffer/offer_search.htm?keywords='+$route.query.title" frameborder="0"></iframe>
			<!-- <iframe v-if="$route.query.select==4" class="iframe-box" width="100%" height="1000px" :src="'https://www.aliexpress.com/wholesale?catId=0&initiative_id=SB_20220309233250&SearchText='+$route.query.title" frameborder="0"></iframe> -->
		</div>
	</div>
</template>

<script>
	import mixin from '../../views/mixin.js'
	import Breadcrumb from "../../components/Breadcrumb/index.vue"
	import ScreenBox from "../../components/Home/screen_box.vue"
	import SearchGoodList from "../../components/Home/search_good_list.vue"
	import GoodList from "../../components/Home/good_list.vue"
	export default {
		mixins: [mixin],
		components: {
			Breadcrumb,
			ScreenBox,
			SearchGoodList,
			GoodList,
		},
		data() {
			return {
				
			}
		},
		created() {
			// this.getSiteId()
		},
		methods: {
			getData() {},
			turnPages() {},
		}
	}
</script>

<style lang="scss" scoped>
.iframe-box {
	// display: none;
	// position: relative;
	// top: -100px;
	margin-top: -118px;
}
.four_goods {
	// position: relative;
	background: #fff;
	z-index: 1000;
}
.bgf5 {
	background-color: #F5F5F5 !important;
}
	.screen_box {
		.screen_l{
			width: 260px;
			margin: 0 10px 10px 0;
			min-height: 300px;
			.category_item {
			    margin-bottom: 10px;
				a {
					line-height: 30px;
					display: block;
				}
				.first_category {
				    font-size: 16px;
				    font-weight: bold;
				    color: #333333;
				}
				.categoty_highlight {
				    color: #CB261C !important;
				}
				.second_category {
				    font-size: 14px;
				    font-weight: bold;
				    color: #333333;
					margin-left: 20px;
				}
				.second_category:hover {
					color: #CB261C !important;
				}
			}
			.list_box {
				background-color: #fff;
				padding: 20px;
			}
			.category_goods_item {
				h3 {
					font-size: 16px;
					color: #111;
					line-height: 1.5em;
					font-weight: bold;
					margin-bottom: 5px;
				}
				.user_tabtd {
				    display: flex;
				    margin-bottom: 5px;
				    padding-bottom: 5px;
				    border-bottom: 1px dotted #eee;
					.tab_pic {
						img {
							width: 70px;
						    margin-right: 10px;
						}
					}
					.tab_name {
					    width: calc(100% - 70px);
					    text-align: left;
					    display: flex;
					    flex-direction: column;
					    justify-content: center;
						.tab_longname:hover {
							color: #CB261C !important;
						}
						.tab_longname {
							height: 24px;
							width: 100%;
							overflow: hidden;
							word-break: break-all;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: unset;
							white-space: normal;
							line-height: 20px;
							color: #333;
						}
						span.tab_name_sku {
						    color: #666;
						    display: block;
						    line-height: 20px;
						}
						.commodity_price, .commodity_price b {
						    display: inline-block;
						    font-weight: inherit;
						    color: #ff6128;
						    line-height: 20px;
						}
					}
				}
			}
		}
	}
</style>
