<template>
    <div class="account_box">
        <div class="box">
            <div class="title bb_ccc">账户安全</div>
            <div class="list_box bb_ccc display_flex">
                <div class="icon icon_01"></div>
                <div class="flex pl_20">
                    <div class="title">支付密码</div>
                    <div class="text">用于保护您的资产安全，建议定期更换</div>
                </div>
                <div class="btn_box hand mt_15px" @click="openImte('/payPassword')">修改</div>
            </div>
            <div class="list_box bb_ccc display_flex">
                <div class="icon icon_02"></div>
                <div class="flex pl_20">
                    <div class="title">登录密码</div>
                    <div class="text">用于保护账号信息和登录安全</div>
                </div>
                <div class="btn_box hand mt_15px" @click="openImte('/loginPassword')">修改</div>
            </div>
            <div class="list_box bb_ccc display_flex">
                <div class="icon icon_01"></div>
                <div class="flex pl_20">
                    <div class="title">个人资料</div>
                    <div class="text">用于保护您的资产安全，建议定期更换</div>
                </div>
                <div class="btn_box hand mt_15px" @click="openImte('/payPassword')">修改</div>
            </div>
        </div>
    </div>
</template>
<script>
	import mixin from '../../../views/mixin.js'
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				checked1: false,
                messageData: []
			};
		},
		created() {
			// this.getSiteId()
		},
		methods: {
            // 获取数据
			// getData() {},
            // 修改密码
            openImte(path) {
                this.$router.push({path: path, query:{siteId: this.$route.query.siteId? this.$route.query.siteId:this.$store.state.siteId? this.$store.state.siteId:this.siteId}})
            }
		}
	};
</script>
<style lang="scss" scoped>
.account_box {
    background: #fff;
    // padding-bottom: 40px;
    width: 100%;
    .box {
        padding: 20px;
        padding-bottom: 180px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        line-height: 40px;
        // padding-top: 10px;
    }
    .list_box {
        margin: 0 50px;
        padding: 30px 0;
        .icon {
            background: url(../../../assets/img/user_num.png) no-repeat;
            width: 55px;
            height: 72px;
        }
        .icon_01 {
            background-position: left -358px;
        }
        .icon_02 {
            background-position: right -238px;
        }
        .text {
            color: #999;
        }
        .btn_box {
            width: 80px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            border: 1px solid #CB261C;
            border-radius: 4px;
            color: #CB261C;
        }
        .btn_box:hover {
            background: #CB261C;
            color: #fff;
        }
    }
}

</style>
