<template>
    <div class="afterSale_detail bg_fff">
        <div class="good_list">
            <el-table :data="afterOrder.orderDetails" border style="width: 100%">
              <el-table-column label="商品名称">
                  <template #default="scope">
                    <div class="display_flex">
                        <img class="img_table" :src="scope.row.logo">
                        <div class="title_table text_two_link">{{scope.row.title}}</div>
                    </div>
                  </template>
              </el-table-column>
              <el-table-column prop="num" label="购买数量" width="180"> </el-table-column>
              <el-table-column label="价格" width="180">
                  <template #default="scope">
                    ￥{{regFenToYuan(scope.row.price)}}
                  </template>
              </el-table-column>
            </el-table>
        </div>
        <div class="sale_box">
            <div class="sale_title">售后信息</div>
            <div class="sale_form">
                <el-form
                  :model="saleForm"
                  :rules="saleRules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="售后类型" prop="type">
                    <div class="display_flex">
                        <div class="color_imte hand" @click="clickType(imte.id)" :class="saleForm.type === imte.id? 'active':''" v-for="imte in typeData" :key="imte.id">
                          <div>{{imte.type}}</div>
                          <i v-if="saleForm.type === imte.id"></i>
                        </div>
                    </div>
                  </el-form-item>
                  <div v-if="showType">
                    <el-form-item v-if="saleForm.type==1" label="收件人" prop="deliverName">
                    <el-input v-model="saleForm.deliverName" :disabled="saleForm.type==1"></el-input>
                    </el-form-item>
                    <el-form-item v-if="saleForm.type==1" label="收件人电话" prop="deliverPhone">
                    <el-input v-model="saleForm.deliverPhone" :disabled="saleForm.type==1"></el-input>
                    </el-form-item>
                    <el-form-item v-if="saleForm.type==1" label="收货地址" prop="addressData">
                      <!-- saleForm.type==1 -->
                      <el-select v-model="saleForm.addressData" @change="changeReturnExchange">
                        <el-option
                          v-for="(item, index) in addressList"
                          :key="index"
                          :label="item.continent+','+item.country+','+item.province+','+item.city+','+item.address"
                          :value="index"
                        >
                          <span>{{ item.continent }},{{item.country}},{{item.province}},{{item.city}},{{item.address}}</span>
                        </el-option>
                      </el-select>
                      <!-- saleForm.type==2 -->
                      <!-- <el-cascader v-if="saleForm.type==2" v-model="saleForm.addressData" :options="options" :props="optionProps"></el-cascader> -->
                    </el-form-item>
                    <!-- <el-form-item v-if="saleForm.type==2" label="详细地址" prop="address">
                    <el-input type="textarea" v-model="saleForm.address"></el-input>
                    </el-form-item> -->
                    <el-form-item label="原因" prop="remark">
                    <el-input type="textarea" v-model="saleForm.remark"></el-input>
                    </el-form-item>
                     <el-form-item label="图片" prop="pics">
                    <el-upload
                      class="upload-demo"
                      action="https://www.babalish.com/api/shop/qiniu/upload"
                      :on-success="handleSuccess"
                      multiple
                      :limit="10"
                      list-type="picture-card"
                      :on-exceed="handleExceed"
                      :file-list="fileList"
                      :before-remove="beforeRemove"
                      :on-remove="handleRemove"
                      :on-change="HFhandleChangeImg"
                    >
                      <div class="plus_icon">+</div>
                      <!-- <el-button size="small" type="primary">点击上传</el-button>
                      <template #tip>
                        <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
                      </template> -->
                    </el-upload>
                    </el-form-item>
                  </div>
                  <div v-if="saleForm.type!=3">
                    <div class="sale_title">仓库信息</div>
                    <div class="warehouse_box display_flex">
                      <div class="list flex display_flex">
                        <div class="name">姓名：</div>
                        <div class="text">{{saleForm.warehouse.name}}</div>
                      </div>
                      <div class="list flex display_flex">
                        <div class="name">联系电话：</div>
                        <div class="text">{{saleForm.warehouse.phone}}</div>
                      </div>
                    </div>
                    <div class="warehouse_box list flex display_flex">
                      <div class="name">仓库地址：</div>
                      <div class="text">{{saleForm.warehouse.country}}{{saleForm.warehouse.state}}{{saleForm.warehouse.city}}{{saleForm.warehouse.district}}{{saleForm.warehouse.address}}</div>
                    </div>
                  </div>
                  <el-form-item>
                     <div class="tac pt_30"><el-button type="primary" @click="submitForm('ruleForm')">提交</el-button></div>
                  </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
import options from "../../../utils/addressJson";
export default {
    mixins: [mixin],
    data() {
        return {
            afterOrder: '',
            options: options, // 地区级联
            addressList: [], // 我的地址
            fileList: [], // 上传
            showType: true,
            typeData: [{
                type: '换货',
                id: 1
            },{
                type: '退货',
                id: 2
            },{
                type: '退款',
                id: 3
            }],
            // 售后信息
            saleForm: {
                type: 1,
                remark: '',
                addressId: '', // 地址id
                deliverAddress: '',
                // address: '', // 地址
                // country: '', // 国家
                // province: '', // 省/州
                // city: '', // 城市
                // street: '', // 区/县
                deliverNo: '',
                addressData: '', // 总地址
                deliverName: '', // 收件人姓名
                deliverPhone: '', // 电话
                pics: '', // 图片
                warehouse: '' // 仓库地址
            },
            // 规则
            saleRules: {
                type: [{ required: true, message: '请选择售后类型', trigger: 'change' },],
                deliverName: [{ required: true, message: '请输入收件人', trigger: 'blur' },],
                deliverPhone: [{ required: true, message: '请输入收件人电话', trigger: 'blur' },],
                addressData: [{ required: true, message: '请选择收件地址', trigger: 'change' },],
                address: [{ required: true, message: '请输入详细地址', trigger: 'blur' },],
                remark: [{ required: true, message: '请输入原因', trigger: 'blur' },],
                // pics: [{ required: true, message: '请选择图片', trigger: 'blur' },],
            },
            optionProps: {
                value: 'name',
                label: 'name',
                children: 'childrens'
            }
        }
    },
    created() {
        this.getSiteId()
        // this.getAddressList()
    },
    methods: {
        // 获取数据
        getData() {
            this.$requestJSON({
      	      url: 'shop/orderback/detail',
      	      method: 'POST',
              data: {
                id: this.$route.query.id? this.$route.query.id:'',
                orderId: this.$route.query.orderId? this.$route.query.orderId:'',
                goodId: this.$route.query.goodId? this.$route.query.goodId:'',
                siteId: this.siteId,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   console.log(res.data)
                  this.afterOrder = res.data.order
                  this.saleForm.warehouse = res.data.warehouse
                  this.getAddressList()
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 选择类型
        clickType(id) {
            this.showType = false
            this.saleForm.type = id
            this.saleForm.deliverName = ''
            this.saleForm.deliverPhone = ''
            this.saleForm.addressId = ''
            this.saleForm.deliverAddress = ''
            this.saleForm.addressData = ''
            this.saleForm.pics = ''
            if (id==1) {
                this.saleRules = {
                    type: [{ required: true, message: '请选择售后类型', trigger: 'change' },],
                    deliverName: [{ required: true, message: '请输入收件人', trigger: 'blur' },],
                    deliverPhone: [{ required: true, message: '请输入收件人电话', trigger: 'blur' },],
                    addressData: [{ required: true, message: '请选择收件地址', trigger: 'change' },],
                    address: [{ required: true, message: '请输入详细地址', trigger: 'blur' },],
                    remark: [{ required: true, message: '请输入原因', trigger: 'blur' },],
                    // pics: [{ required: true, message: '请选择图片', trigger: 'blur' },],
                }
            } else if (id==3 || id==2) {
                this.saleRules = {
                    type: [{ required: true, message: '请选择售后类型', trigger: 'change' },],
                    remark: [{ required: true, message: '请输入原因', trigger: 'blur' },],
                    // pics: [{ required: true, message: '请选择图片', trigger: 'blur' },],
                }
            }
            // this.showType = true
            setTimeout(()=>{
                this.showType = true
            }, 100)
        },
        // 地址列表
        getAddressList() {
            this.$requestJSON({
      	      url: 'shop/address/list',
      	      method: 'POST',
              data: {
                siteId: this.siteId,
                page: this.currentPage,
                limit: 200,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.addressList = res.data
                   console.log(res.data, '地址列表')
                //    this.total = res.total
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 退换
        changeReturnExchange(i) {
            console.log(this.addressList[i])
            this.saleForm.deliverName = this.addressList[i].name
            this.saleForm.deliverPhone = this.addressList[i].phone
            this.saleForm.addressId = this.addressList[i].id
            // this.saleForm.deliverAddress = this.addressList[i].continent+this.addressList[i].country+this.addressList[i].province+this.addressList[i].city+this.addressList[i].address
        },
         handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
            files.length + fileList.length
          } 个文件`
        )
      },
      // 删除图片前
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`)
      },
      // 删除图片
       handleRemove(file, fileList) {
        this.saleForm.pics = ''
        fileList.map((imte, index)=>{
            if(fileList.length != index+1) {
                this.saleForm.pics += imte.response.src + '|'
            } else {
                this.saleForm.pics += imte.response.src
            }
        })
      },
        // 上传
        handleSuccess(file, fileList) {
          if(this.saleForm.pics == '') {
            this.saleForm.pics = file.data.url
          } else {
            this.saleForm.pics += '|' + file.data.url
          }
        },
        HFhandleChangeImg(file, fileList) {
            // this.fileList = fileList; // 这一步，是 展示最后一次选择的csv文件
        },
        // 清空
        resetForm() {
          this.$refs['ruleForm'].resetFields()
        },
        // 验证规则
        submitForm(formName) {
          console.log(this.saleForm.pics, '图片')
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saleForm.siteId = this.$store.state.siteId
            this.saleForm.token = window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
             this.saleForm.orderId = this.afterOrder.id
             this.saleForm.goodsId = this.$route.query.goodId
             this.saleForm.warehouseId = this.saleForm.warehouse.id
            //  this.saleForm.deliverNo = this.afterOrder.deliverNo
            //  if(this.saleForm.type != 3) {
            //     if(this.saleForm.type == 2) {
            //         this.saleForm.deliverAddress = this.saleForm.addressData[0] + this.saleForm.addressData[1] + this.saleForm.addressData[2] + this.saleForm.addressData[3] + this.saleForm.address
            //     }
            //  }
            this.getSaleSale()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      // 提交接口
      getSaleSale() {
          this.$requestJSON({
      	      url: 'shop/orderback/save',
      	      method: 'POST',
              data: this.saleForm
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.$message.success ('提交成功！')
                   this.resetForm()
                   this.$router.push({path:'/afterSale',query:{siteId: this.$route.query.siteId? this.$route.query.siteId:this.$store.state.siteId? this.$store.state.siteId:this.siteId}})
                //    this.total = res.total
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
      }
    }
}
</script>
<style lang="scss" scoped>
/deep/.el-table__header-wrapper {
    background: #ccc !important;
}
/deep/.el-select {
    width: 100% !important;
}
/deep/.el-cascader {
    width: 100% !important;
}
.plus_icon {
    line-height: 130px;
    font-size: 60px;
    color: #999;
}
.afterSale_detail {
    padding: 20px;
    padding-bottom: 40px;
    // width: 100%;
    .img_table {
        width: 100px;
    }
    .title_table {
        padding-top: 10px;
        line-height: 25px;
        padding-left: 5px;
        height: 60px;
    }
    .sale_box {
        border: 1px solid #ccc;
        margin-top: 20px;
        padding: 20px;
        .warehouse_box {
          padding-top: 20px;
          margin-left: 30px;
        }
        .sale_form {
            padding-top: 20px;
            padding-bottom: 30px;
        }
         .color_imte {
          margin-left: 5px;
          margin-top: 5px;
          width: 60px;
          text-align: center;
          height: 30px;
          line-height: 30px;
          position: relative;
          border: 1px solid #f5f5f5;
          i {
            background-image: url('../../../assets/img/xuanze.png');
            position: absolute;
            bottom: 0;
            right: 0;
            width: 12px;
            height: 12px;
            overflow: hidden;
            text-indent: -99em;
            display: block;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
        .active {
          border: 2px solid #FF0036;
        }
    }
}
</style>
