<template>
    <div class="product-manage">
        <div class="managetop">
            <div class="page-tit">
                <h6>收藏夹</h6>
            </div>
            <div class="tab_box">
                <div class="table_box">
                    <el-table :data="collectionData" style="width: 100%">
                      <el-table-column prop="date" label="商品">
                          <template #default="scope">
                            <div class="display_flex hand" @click="openGoodDetail(scope.row.goods.id)">
                                <div class="good_box">
                                    <div class="good_img"><img :src="scope.row.goods? scope.row.goods.logo:''" alt=""></div>
                                </div>
                                <div class="good_title">
                                    <div class="title text_two_link">{{scope.row.goods? scope.row.goods.title:''}}</div>
                                    <div class="good_date">{{scope.row.createTime}}</div>
                                </div>
                            </div>
                          </template>
                      </el-table-column>
                      <el-table-column label="价格" width="120">
                          <template #default="scope">
                            <div class="hand" @click="openGoodDetail(scope.row.goods.id)">
                                <div class="text_line">￥{{scope.row.goods? regFenToYuan(scope.row.goods.salePrice):''}}</div>
                                <div class="red_text">￥{{scope.row.goods? regFenToYuan(scope.row.goods.marketPrice):''}}</div>
                            </div>
                          </template>
                      </el-table-column>
                      <el-table-column label="规格" width="200">
                          <template #default="scope">
                            <div class="hand" @click="openGoodDetail(scope.row.goods.id)">
                                 <el-select v-model="scope.row.skus" placeholder="请选择">
                                   <el-option
                                     v-for="(item, index) in scope.row.goods? scope.row.goods.skus:[]"
                                     :key="index"
                                     :label="item.attributes[0].vTextMulti.langAndValueMap.CN_zh.value"
                                     :value="index"
                                   >
                                   <div class="select_img"><img :src="item.images[0]" alt="">{{ item.attributes[0].vTextMulti.langAndValueMap.CN_zh.value }}</div>
                                   </el-option>
                                 </el-select>
                            </div>
                          </template>
                      </el-table-column>
                      <el-table-column fixed="right" label="操作" width="90">
                        <template #default="scope">
                          <el-button @click.stop="dleCollectionGood(scope.row.goods.id, true)" type="text" size="small">取消收藏</el-button>
                          <el-button @click.stop="handleBuy(scope.row)" type="text" size="small">立即购买</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="paga">
                        <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="settle-bar">
            <div class=""></div>
        </div> -->
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
export default {
    mixins: [mixin],
    data() {
        return {
             collectionData: [],
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$requestJSON({
      	      url: 'shop/favorite/list',
      	      method: 'POST',
              data: {
                page: this.currentPage,
                limit: this.pageSize,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                   this.collectionData = res.data
                   this.total = res.data.total
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 取消收藏
        dleCollectionGood(id, istGetData) {
            this.isFav = 1
            this.addCollectionGood(id, istGetData)
        },
        // 立即购买
        handleBuy(val) {
            console.log(val)
            if(val.skus != 0 && !val.skus) {
              this.$message.error ('请选择规格！')
              return
            }
            let data = {
              token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
              siteId: this.$store.state.siteId,
              goodsId: val.goods.id,
              skuId: val.goods.skus[val.skus].sku_id,
              type: 1,
              wareId: 1,
              price: val.goods.salePrice,
              num: val.goods.num? val.goods.num:1,
              isbuyNow: 1, // 0:加入购物车 1：立即购买
              skuName: val.goods.skus[val.skus].attributes[0].vTextMulti.langAndValueMap.CN_zh.value,
              remark: '' // 备注
            }
            this.$requestJSON({
              url: 'shop/cart/add',
              method: 'POST',
              data: data
            }).then(res => {
            //   this.loading = false
               if(res.code === 0) {
                //  console.log(res.data)
               this.$router.push({path:'/orderList',query:{id: res.data.cartId,siteId: this.$store.state.siteId}})
               } else {
                 this.$message.error (res.msg)
               }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
  /deep/.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  /deep/.el-button+.el-button {
      margin-left: 0px;
  }
  .el-select-dropdown__item {
      height: 50px;
  }
  .select_img {
     img {
         width: 60px;
     }
 }
.product-manage {
    margin-bottom: 20px;
    .managetop {
        background-color: #fff;
        padding: 10px 20px;
        .page-tit {
            padding: 10px 0 0;
            height: 50px;
            h6 {
                font-weight: bold;
                color: #333;
                line-height: 40px;
                font-size: 16px;
            }
        }
        .tab_box {
            margin: 10px 0;
            .table_box {
                .good_box {
                    img {
                        width: 100px;
                    }
                }
                .good_title {
                    padding-left: 8px;
                    .title {
                        color: #666;
                        font-size: 15px;
                    }
                    .good_date {
                        padding-top: 15px;
                        color: #999;
                    }
                }
            }
        }
    }
    .settle-bar {
        background-color: #fff;
        line-height: 40px;
        box-shadow: 0 0 5px #eee;
    }
}
</style>
