<template>
    <div class="home">
        <Head :hide-banner="hideBanner" :hotkeys="hotkeys" :siteInfo="siteInfo" />
		<TypeTab />
        <!-- 分类+轮播图 -->
		<div class="edition_center type_box display_flex">
			<TypeList :typeListData='typeListData' />
			<div class="banner flex">
				<el-carousel v-if="banner" :interval="2000" arrow="always">
					<el-carousel-item v-for="item in bannerData" :key="item" style="background-size: cover;background-position:center center;background-repeat: no-repeat;" :style="'background-image:url(' + item.logo + ')'">
						<!-- <img :src="item.logo"> -->
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- 平台爆款 -->
		<!-- <div class="floorMain edition_center" ref="platform" id="platform">
			<div class="gtitle">
				<h2>平台爆款</h2>
			</div>
			<div class="swiper-container">
				<div class="swiper-wrapper platformbtn display_flex">
					<div isload="true" data-floorid="12" data-plat="Amazon" class="swiper-slide active">
						<img
							src="https://resource.saleyee.cn/UploadFiles/Images/2021/202108/e827b18b-7406-44bd-af87-023264fe1e3f.jpg">
					</div>
					<div isload="true" data-floorid="12" data-plat="eBay" class="swiper-slide ">
						<img
							src="https://resource.saleyee.cn/UploadFiles/Images/2021/202108/7f979b21-ecbd-48e9-99bb-f59fb2cc97b5.jpg">
					</div>
					<div isload="true" data-floorid="12" data-plat="Walmart" class="swiper-slide ">
						<img
							src="https://resource.saleyee.cn/UploadFiles/Images/2021/202108/624010d8-62d6-4896-b9df-fad86d3388b6.jpg">
					</div>
					<div isload="true" data-floorid="12" data-plat="Wish" class="swiper-slide ">
						<img
							src="https://resource.saleyee.cn/UploadFiles/Images/2021/202108/7a1a3585-4fa2-4a03-96b6-881bac28cd38.jpg">
					</div>
					<div isload="true" data-floorid="12" data-plat="Shopify" class="swiper-slide ">
						<img
							src="https://resource.saleyee.cn/UploadFiles/Images/2021/202109/e2d3cc56-1db5-40fa-9d20-c7fd9ab83138.jpg">
					</div>
					<div style=" width:100%; border-left: 1px solid #eee; display: flex;justify-content: center;align-items: center;padding: 0 25px;">
						更多平台，后续开放...
					</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="platformgoods edition_center">
			<div data-pro-floorid="12" data-pro-plat="Amazon" class="on goods">
				<div class="ulWrap cnellipsis floor-pro-div">
					<ul class="hot-img-css display_flex">
						<li>
							<div class="proitemIndx">
								<div class="pic">
									<a title="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】"
										target="_blank">
										<img src="https://img.saleyee.cn//Resources/GoodsImages/2021/202101/9d025a39-12e8-44ea-88bf-0f9a300757be.Jpeg"
											alt="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】" />
									</a>
								</div>
								<div class="title clamp">
									<a target="_blank">
										PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】
									</a>
									<span class="price">
									</span>
								</div>
							</div>
						</li>
						<li>
							<div class="proitemIndx">
								<div class="pic">
									<a title="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】"
										target="_blank">
										<img src="https://img.saleyee.cn//Resources/GoodsImages/2021/202101/9d025a39-12e8-44ea-88bf-0f9a300757be.Jpeg"
											alt="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】" />
									</a>
								</div>
								<div class="title clamp">
									<a target="_blank">
										PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】
									</a>
									<span class="price">
									</span>
								</div>
							</div>
						</li>
						<li>
							<div class="proitemIndx">
								<div class="pic">
									<a title="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】"
										target="_blank">
										<img src="https://img.saleyee.cn//Resources/GoodsImages/2021/202101/9d025a39-12e8-44ea-88bf-0f9a300757be.Jpeg"
											alt="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】" />
									</a>
								</div>
								<div class="title clamp">
									<a target="_blank">
										PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】
									</a>
									<span class="price">
									</span>
								</div>
							</div>
						</li>
						<li>
							<div class="proitemIndx">
								<div class="pic">
									<a title="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】"
										target="_blank">
										<img src="https://img.saleyee.cn//Resources/GoodsImages/2021/202101/9d025a39-12e8-44ea-88bf-0f9a300757be.Jpeg"
											alt="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】" />
									</a>
								</div>
								<div class="title clamp">
									<a target="_blank">
										PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】
									</a>
									<span class="price">
									</span>
								</div>
							</div>
						</li>
						<li>
							<div class="proitemIndx">
								<div class="pic">
									<a title="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】"
										target="_blank">
										<img src="https://img.saleyee.cn//Resources/GoodsImages/2021/202101/9d025a39-12e8-44ea-88bf-0f9a300757be.Jpeg"
											alt="PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】" />
									</a>
								</div>
								<div class="title clamp">
									<a target="_blank">
										PVC透明地板保护垫椅子垫 带钉 凸形 【90x120x0.2cm】
									</a>
									<span class="price">
									</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="moreplatform">
					<a ga-cate="platform boutique" class="ga-custom-send" ga-lab="Amazon">查看更多</a>
				</div>
			</div>
		</div> -->
		<!-- 商品 -->
		<div v-for="(imte, index) in categoryGoods" :key="index" :id="imte.icon" :ref="imte.icon">
			<GoodList :title="imte.name" :data="imte.goods" :goodImg="imte.bigLogo"></GoodList>
		</div>
		<!-- 视频推荐 -->
		<!-- <div class="floorMain edition_center dobblegoods a-anchors" id="video" ref="video">
			<div class="gtitle">
				<h2>视频推荐</h2>
			</div>
			<div class="video_recommended">
				<ul class="display_flex">
					<li>
						<a class="video_box">
							<img
								src="https://resource.saleyee.cn/UploadFiles/Images/2021/202109/d8484a3a-b568-45b8-8765-76958a6d4baa.jpg">
							<span class="video_time">1:58</span>
						</a>
						<a>
							<p class="video_introduction">宠物用品市场到底有多火？</p>
						</a>
						<p class="video_release_time">
							<img src="../../assets/img/time.png">
							<em>2021-09-15</em>
						</p>
					</li>
					<li>
						<a class="video_box">
							<img
								src="https://resource.saleyee.cn/UploadFiles/Images/2021/202109/d8484a3a-b568-45b8-8765-76958a6d4baa.jpg">
							<span class="video_time">1:58</span>
						</a>
						<a>
							<p class="video_introduction">宠物用品市场到底有多火？</p>
						</a>
						<p class="video_release_time">
							<img src="../../assets/img/time.png">
							<em>2021-09-15</em>
						</p>
					</li>
					<li>
						<a class="video_box">
							<img
								src="https://resource.saleyee.cn/UploadFiles/Images/2021/202109/d8484a3a-b568-45b8-8765-76958a6d4baa.jpg">
							<span class="video_time">1:58</span>
						</a>
						<a>
							<p class="video_introduction">宠物用品市场到底有多火？</p>
						</a>
						<p class="video_release_time">
							<img src="../../assets/img/time.png">
							<em>2021-09-15</em>
						</p>
					</li>
				</ul>
				<div class="moreplatform">
					<a ga-cate="platform boutique" class="ga-custom-send" ga-lab="Amazon">查看更多</a>
				</div>
			</div>
		</div> -->
		<!-- 欧美海外仓现货一件代发 -->
		<div class="foot_recommend">
			<div class="content">
				<h2 class="foot_recommend_h5">欧美海外仓现货一件代发</h2>
				<p class="foot_recommend_p">主打中大件海外仓精品，为您提供专业化的跨境电商货源服务</p>
				<ul class="foot_recommend_ul">
					<li>
						<img src="../../assets/img/foot_recommend1.png" alt="">
						<h5>海量优质货源</h5>
						<p>甄选30000+海外仓现货SKU，1000+精品每月上新，支持非普货类及大件类产品直发</p>
					</li>
					<li>
						<img src="../../assets/img/foot_recommend2.png" alt="">
						<h5>解放资金压力</h5>
						<p>免费入驻，无需备货、无库存风险，先出单后付款、零成本搭建跨境供应链体系</p>
					</li>
					<li>
						<img src="../../assets/img/foot_recommend3.png" alt="">
						<h5>零投入即享海外仓服务</h5>
						<p>支持美英德法等7大海外仓一件代发，24小时出物流单号、2-8天极速妥投</p>
					</li>
					<li>
						<img src="../../assets/img/foot_recommend4.png" alt="">
						<h5>售后无忧</h5>
						<p>专业客服团队快速响应，海外仓退换货便捷，售后服务期长达90天</p>
					</li>
				</ul>
			</div>
		</div>
		<Foot :siteInfo="siteInfo" :footList="footList" :flinksList="flinksList" />
        <Sidebar :category-goods="categoryGoods" ref="sidebar" v-if="!hideBanner" />
    </div>
</template>
<script>
	import mixin from '../../views/mixin.js'
	import Head from "../../components/Home/head.vue"
	import TypeTab from "../../components/Home/type_tab.vue"
	import TypeList from "../../components/Home/type_list.vue"
	import GoodList from "../../components/Home/good_list.vue"
	import Sidebar from "../../components/Home/sidebar.vue"
	import Foot from "../../components/Home/foot.vue"
	export default {
		mixins: [mixin],
		components: {
			Head,
			TypeTab,
			TypeList,
			GoodList,
			Sidebar,
			Foot
		},
		data() {
			return {
				scroll: '',
				typeListData: [], // 分类
				bannerData: [],  // 轮播图
				hotkeys: [], // 搜索
				categoryGoods: [],
				banner: false,
				flinksList: []
			};
		},
		created() {
			this.getSiteId()
			// this.getData()
			// console.log(document, 'document')
			// (function() { var link = document.querySelector("link[rel*='icon']") || document.createElement('link'); link.type = 'image/x-icon'; link.rel = 'shortcut icon'; link.href = 'http://www.stackoverflow.com/favicon.ico'; document.getElementsByTagName('head')[0].appendChild(link); })();
		},
		mounted() {
			// setTimeout(()=>{
			// 	this.getData()
			// }, 500)
		    //   window.addEventListener('scroll', this.monitorScroll)
		},
		methods: {
			// 监听滚动条
			monitorScroll() {
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
				if(this.scroll>90) {
					this.hideBanner = false
				} else {
					this.hideBanner = true
				}
				// if(this.$refs.platform && this.$refs.platform.offsetTop < this.scroll + 155) {
				// 	this.$refs.sidebar.index = 0
				// }
				for(let i = 0; i<this.categoryGoods.length; i++) {
					if(this.$refs[this.categoryGoods[i].icon] && this.$refs[this.categoryGoods[i].icon].offsetTop < this.scroll + 155) {
						this.$refs.sidebar.index = i
					}
				}
				// if(this.$refs.video && this.$refs.video.offsetTop < this.scroll + 155) {
				// 	this.$refs.sidebar.index = this.categoryGoods.length
				// }
			},
			getData() {
				this.$requestJSON({
        		  url: 'shop/' + this.$store.state.siteId,
        		  method: 'POST',
				  data: {
					  token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')? window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'):''
				  }
        		}).then(res => {
        		//   this.loading = false
        		   if(res.code === 0) {
					 this.typeListData = res.data.categoryTree
        		    this.bannerData = res.data.pptList
					 this.hotkeys = res.data.hotkeys
					 this.categoryGoods = res.data.categoryGoods
					 this.banner = true
					 this.$store.state.flinks = res.data.flinks
					 this.flinksList = res.data.flinks
        		    //  this.total = Number(res.data.total)
        		   } else {
        		     this.$message.error (res.msg)
        		   }
        		})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.padd_top_136 {
		padding-top: 136px;
	}
	/deep/ .el-carousel {
		height: 476px !important;
	}

	/deep/ .el-carousel__container {
		height: 476px !important;
		background-repeat: no-repeat;
		width: 100%;
		background-size:cover; 
		img {
			width: 100%;
			height: auto;
		}
	}

	.moreplatform {
		padding: 20px 0;
		text-align: center;
		background-color: #fff;

		a {
			display: inline-block;
			width: 114px;
			height: 36px;
			background: #FFFFFF;
			border: 1px solid #CB261C;
			border-radius: 18px;
			line-height: 36px;
			color: #CB261C;
		}

		a:hover,
		a:focus {
			color: #fff;
			background: #CB261C;
		}
	}

	.home {
		.type_box {
			li {
				position: relative;
			}

			.banner {
				height: 466px;
				padding: 10px;
				padding-bottom: 0px;
				// padding-right: 0px;
			}
		}

		.platformgoods {
			background: #fff;
			text-align: center;
			li {
				width: calc(20% - 8.5px);
			}

			.goods li .pic img {
				width: 188px;
				margin: 35px 0 25px 0;
			}

			.clamp a {
				overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				color: #111;
				text-align: center;
				line-height: 24px;
				height: 48px;
				font-size: 14px;
			}

			.goods li .title a:hover,
			.sprice a:hover {
				color: #CB261C !important;
			}

			.goods li .title {
				padding: 10px 10px 30px 10px;
			}
		}

		.floorMain {
			.gtitle {
				width: 100%;
				text-align: center;
				margin-top: 20px;

				h2 {
					line-height: 88px;
					height: 88px;
					font-size: 28px;
					font-weight: bold;
					color: #333333;
				}
			}

			.gtitle h2:before {
				content: "";
				display: inline-block;
				margin-right: 27px;
				width: 24px;
				height: 24px;
				background-size: contain;
				background-image: url(../../assets/img/l_icon.png);
				background-repeat: no-repeat;
			}

			.gtitle h2:after {
				content: "";
				display: inline-block;
				margin-left: 27px;
				transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				width: 24px;
				height: 24px;
				background-size: contain;
				background-image: url(../../assets/img/338019c9a5c3e54b4a0a84ec83f87ce0.png);
				background-repeat: no-repeat;
			}

			.swiper-container {
				width: 100%;
				height: 100%;
				margin: 0 auto;
				position: relative;
				overflow: hidden;
				list-style: none;
				padding: 0;
				z-index: 1;
				display: flex;
				background: #F9FAFC;
			}

			.swiper-slide {
				border-left: 1px solid #eee;
				height: 104px;
				width: 160px;
				text-align: center;
				font-size: 18px;
				/* background: #fff; */
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 4px solid #F9FAFC;
			}

			.active {
				border-top-color: #CB261C !important;
				background-color: #fff;
			}
		}

		.dobblegoods {
			.video_recommended {
				margin-bottom: 35px;
				width: 100%;

				a {
					color: #333;
					text-decoration: none;
				}

				ul {
					background-color: #fff;
					padding: 20px;
				}

				li {
					width: calc(33% - 10px);
					margin-right: 20px;

					.video_box {
						position: relative;
						display: inline-block;

						img {
							max-width: 100%;
						}

						.video_time {
							position: absolute;
							right: 15px;
							bottom: 15px;
							display: inline-block;
							background-color: rgba(0, 0, 0, 0.8);
							color: #fff;
							font-size: 16px;
							padding: 0 10px;
							border-radius: 5px;
							height: 26px;
							line-height: 26px;
							z-index: 2;
						}
					}

					.video_introduction {
						font-size: 16px;
						color: #333333;
						font-weight: bold;
						line-height: 24px;
						margin-top: 10px;
						display: -webkit-box;
						text-overflow: ellipsis;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}

					.video_release_time {
						display: flex;
						margin: 5px 0;
						align-items: center;

						img {
							height: 12px;
						}

						em {
							color: #999;
							font-size: 14px;
							line-height: 20px;
							display: inline-block;
							margin-left: 3px;
						}
					}
				}

				li:nth-last-child(1) {
					margin-right: 0px;
				}
			}
		}

		.foot_recommend {
			background-color: #fff;
			padding: 50px 0;
			width: 100%;
			margin-top: 40px;
			.content {
				min-width: 1200px;
				max-width: 1600px;
				margin: 0 auto;
				position: relative;
			}

			.foot_recommend_h5 {
				font-size: 28px;
				font-weight: bold;
				line-height: 40px;
				color: #333333;
				text-align: center;
			}

			.foot_recommend_p {
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				color: #707070;
				text-align: center;
				margin-top: 15px;
			}

			.foot_recommend_ul {
				display: flex;
				margin-top: 50px;

				li {
					border-right: 1px solid #f1f1f1;
					text-align: center;
					width: 25%;
					padding: 0 50px;

					img {
						width: 38px;
						height: auto;
					}

					h5 {
						font-size: 20px;
						font-weight: bold;
						line-height: 28px;
						color: #333333;
						text-align: center;
						margin-top: 15px;
					}

					p {
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						color: #333333;
						text-align: center;
						margin: 15px 0;
					}
				}
			}
		}

		
	}
</style>
