<template>
    <div class="buy">
        <div class="edition_center">
            <Breadcrumb />
            <!-- 收货信息 -->
            <div class="shipping-address box bg_fff">
                <div class="page-tit">
                    <h6>收货信息</h6>
                </div>
                <div class="add_address">
                    <el-button @click="addVisible"><Plus style="width: 16px; height: 1em; margin-right: 8px; vertical-align: text-top;" />新增收货地址</el-button>
                </div>
                <div class="alladdress">
                    <ul>
                        <li @click="getAddressId(imte.id)" :class="addressId === imte.id? 'active':''" class="chosseaddress display_flex" v-for="(imte, i) in addressData" :key="i">
                            <p class="flex">{{imte.name}},{{imte.phone}},{{imte.country}}{{imte.province}},{{imte.city}},{{imte.address}},{{imte.postcode}}</p>
                            <div class="red_text li_btn display_flex">
                                <div @click.stop="delAddress(imte)" class="span">删除</div>
                                <div @click.stop="editAddress(imte)" class="span">编辑</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 商品信息 -->
            <div class="good_inp box bg_fff">
                <div class="page-tit">
                    <h6>商品信息</h6>
                </div>
                <div class="table_head bg_f2f display_flex">
                    <div style="width: 40%;" class="">商品</div>
                    <div style="width: 15%;" class="">原价</div>
                    <div style="width: 15%;" class="">售价</div>
                    <div style="width: 15%;" class="">数量</div>
                    <div style="width: 15%;" class="">金额</div>
                </div>
                <div class="merchandise bg_f2f">
                    <p>CZ</p>
                    <div class="mode-distribution">
                        <span>发货物流：Standard Shipping</span>
                        <span style="margin-left:10px">1-3个工作日</span>
                        <!-- <span style="margin-left:10px">
                            <b style="color:red;">*</b>
                            销售平台：
                            <el-select size="mini" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in optionsList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </span> -->
                        <span> eBay / Groupon平台请务必选择销售平台；选择销售平台后，系统会自动为您选择适合的发货物流</span>
                    </div>
                </div>
                <div v-for="(imte, i) in buyData" :key="i" class="table_body">
                    <div class="good_list display_flex">
                        <div style="width: 40%;" class="list_th">
                            <div class="good display_flex">
                                <div class="checkbox_li"><img :src="imte.logo" alt=""></div>
                                <div class="text_box">
                                    <router-link class="text_hid_link" to="/">{{imte.title}}</router-link>
                                    <p>规格：{{imte.skuName}}</p>
                                </div>
                            </div>
                        </div>
                        <div style="width: 15%;" class="list_th checkbox_li text_center">{{regFenToYuan(imte.price)}}</div>
                        <div style="width: 15%;" class="list_th checkbox_li text_center">{{regFenToYuan(imte.price)}}</div>
                        <div style="width: 15%;" class="list_th checkbox_li text_center">{{imte.num}}</div>
                        <div style="width: 15%;" class="list_th checkbox_li text_center">{{regFenToYuan(imte.num * imte.price)}}</div>
                    </div>
                </div>
            </div>
             <!-- VAT税号 -->
             <!-- <div class="VAT box bg_fff display_flex">
                 <div class="VAT_left flex">
                     <span style="margin-left:10px">
                     <b style="color:red;">*</b>
                     销售平台：<el-button size="mini" type="primary">添加</el-button>
                    </span>
                    <span class="confirmorder-notvat-tip" style="margin-left:5px;padding-top: 12px;">该收货国家未登记VAT税号或个人税号不能下欧盟国家订单</span>
                 </div>
                 <div class="customeruse display_flex flex">
                     <p>自定义单号：</p>
                     <el-input style="width: 300px; margin-top: 13px;" v-model="input"></el-input>
                 </div>
             </div> -->
             <!-- 结算 -->
             <div class="settle-accounts box bg_fff">
                 
                 <p>商品总计：<span class="or_profee">{{all}}</span></p>
                 <div class="hint">应付总额=商品总计-优惠金额-订金抵扣+运费+税费+增值服务费</div>
                 <div class="buy_btn"><el-button type="primary" @click="openPayment">提交订单</el-button></div>
             </div>
        </div>
        <AddressFrom ref="address" @getAddressData="getAddressData" />
    </div>
</template>
<script>
import mixin from "../../views/mixin.js";
import Breadcrumb from "../../components/Breadcrumb/index.vue";
import AddressFrom from "../../components/AddressFrom/index.vue";
export default {
    mixins: [mixin],
    data() {
        return {
            input: '',
            optionsList: [],
            value: '',
            buyData: [],
            all: 0, 
            addressData: [],
            addressId: '', // 地址id
        }
    },
    components: {
      Breadcrumb,
      AddressFrom
    },
    created() {
        // if(!window.localStorage.getItem('Token')) {
        //   this.$message.error ('请登录！')
        //   this.$router.push({path:'/login'})
        //   return
        // }
        this.getSiteId()
    },
    methods: {
        getData() {
            this.getGoodData()
            this.getAddressData()
        },
        // 获取购买商品
        getGoodData() {
            this.$requestJSON({
      	      url: 'shop/cart/detail',
      	      method: 'POST',
              data: {
                  siteId: this.siteId,
                  token: window.localStorage.getItem(this.domain + this.siteId+'Token'),
                  id: this.$route.query.id
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	        //  if (!(res.data instanceof Array)) {
                //        this.all = this.regFenToYuan(res.data.num * res.data.price)
                //        this.buyData.push(res.data)
                //    } else {
                //        this.buyData = res.data.carts
                //        this.all = this.regFenToYuan(res.data.totalPrice)
                //    }
                this.buyData = res.data.carts
                this.all = this.regFenToYuan(res.data.totalPrice)
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 获取地址列表
        getAddressData() {
            this.$requestJSON({
      	      url: 'shop/address/list',
      	      method: 'POST',
              data: {
                siteId: this.siteId,
                page: this.currentPage,
                limit: this.pageSize,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                   this.addressData = res.data
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 添加地址
        addVisible() {
            this.$refs.address.addVisible()
        },
        // 编辑
        editAddress(row) {
            this.$refs.address.editAddress(row)
        },
        // 删除
        delAddress(row) {
            this.$confirm('确定删除吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
               this.$refs.address.delAddress(row)
            })
            .catch(() => {
              // this.$message({
              //   type: 'info',
              //   message: '已取消删除',
              // })
            })
        },
        // 选择地址
        getAddressId(id) {
            this.addressId = id
        },
        // 付款页面
        openPayment() {
            // ,query:{id: res.data.id}
            if(!this.addressId) {
               this.$message.error ('请选择收货地址')
               return
            }
            this.$router.push({path:'/payment', query:{addressId: this.addressId, id: this.$route.query.id, siteId: this.siteId}})
        }
    }
}
</script>
<style lang="scss" scoped>
.page-tit {
    padding: 10px 0 0;
    height: 40px;
    line-height: 40px;
    h6 {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        display: inline-block;
    }
}
.box {
    width: 100%;
    padding: 0px 20px 15px 20px;
    margin-bottom: 10px;
}
.shipping-address {
    .add_address {
        margin: 10px 0;
    }
    .alladdress {
        height: 200px;
        overflow: auto;
        width: 100%;
        border: 1px solid #eee;
        margin-bottom: 10px;
         li {
            height: 30px;
            line-height: 30px;
            border-bottom: 1px dashed #eee;
            padding: 5px 0;
            transition: all .4s;
            background-color: #fff;
            cursor: pointer;
            p {
                padding: 0 5px;
            }
            .li_btn {
                margin-right: 10px;
                .span {
                    width: 40px;
                    text-align: center;
                }
            }
        }
        .active {
            background-color: #CB261C !important;
            color: #fff !important;
            .span {
                color: #fff;
            }
        }
    }
}
.good_inp {
    padding-bottom: 30px;
    .table_head {
        margin: 10px 0;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #666;
    }
    .merchandise {
        border: 1px solid #e6e6e6;
        padding: 5px 10px;
        p {
            display: inline-block;
            font-weight: bold;
            margin-right: 30px;
            color: #ff6600;
        }
        .mode-distribution {
            line-height: 24px;
            font-size: 14px;
        }
    }
    .table_body {
                border: 1px solid #e6e6e6;
                border-top: none;
                height: 90px;
                // line-height: 90px;
                .checkbox_li {
                   line-height: 90px;
                }
                .text_center {
                    text-align: center;
                }
                .operation {
                    padding-top: 20px;
                    .el-button--mini {
                        min-height: 23px;
                        padding: 2px;
                    }
                }
                .good_list {
                    padding: 0 10px;
                    /deep/.el-button--text {
                        color: #666 !important;
                    }
                .list_th {
                    .good {
                        width: 100%;
                        img {
                            width: 70px;
                        }
                        .text_box {
                            padding-left: 10px;
                            padding-top: 20px;
                            .color_red {
                                columns: #CB261C;
                            }
                            a {
                                color: #CB261C !important;
                            }
                            p {
                                color: #666;
                                tab-size: 12px;
                                padding-top: 10px;
                            }
                        }
                    }
                }
            }
            }
}
.VAT {
    line-height: 65px;
        .el-button--mini {
            min-height: 23px;
            padding: 2px;
        }
    }
    .settle-accounts {
        width: 100%;
        text-align: right;
        p {
            line-height: 30px;
            color: #666;
            span {
                display: inline-block;
                width: 150px;
                color: #878787;
                vertical-align: top;
                font-weight: bold;
            }
        }
        .hint {
            padding: 10px 0;
            color: #ff8800;
        }
        .buy_btn {
            width: 20%;
            text-align: center;
            margin-left: auto;
        }
    }
</style>
