<template>
    <div class="guide_box">
        <div class="edition_center">
            <div class="title color_red">会员权益</div>
            <div class="content"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>
<style lang="scss" scoped>
.guide_box {
    background: #fff;
    .title {
        width: 100%;
        text-align: center;
        padding: 20px;
        font-size: 18px;
        font-weight: 600;
        padding-top: 40px;
    }
    .content {
        min-height: 300px;
    }
}
</style>