<template>
    <div class="product-manage">
        <div class="managetop">
            <div class="page-tit">
                <h6>我的订单</h6>
            </div>
            <div class="reminder display_flex">
                <div class="reminder-icon"><Warning style="width: 22px;padding-top: 3px;color: #a77200;" /></div>
                <div class="reminder-text">
                    <p>
                        1.疫情期间无法保证物流时效，物流信息更新也会有所延迟，敬请见谅；<br />
                        2.订单均为系统自动化推送并发货，若需要拦截订单，请务必在下单后及时申请拦截，实际拦截结果以系统告知为准 ； <br />
                        3.状态为配货中的订单实际还未发出，跟踪号有可能变更，对上传跟踪号后不能进行修改的平台，建议在状态为待收货时再将跟踪号导出上传；<br />
                        4.若订单有售后问题，请在平台开启售后并以售后页面的讨论结果为最终操作依据，请关注此页面的留言。平台客服工作时间：9:00-18:00（中国时间 周一 到周五）。周六安排客服值班，具体值班时间以实际为准；<br />
                    </p>
                </div>
            </div>
            <div class="tab_box">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="所有订单" name="all"></el-tab-pane>
                  <el-tab-pane label="待付款 " name="pending"></el-tab-pane>
                  <el-tab-pane label="配货中 " name="distribution"></el-tab-pane>
                  <el-tab-pane label="待收货 " name="received"></el-tab-pane>
                  <el-tab-pane label="已完成" name="complete"></el-tab-pane>
                  <el-tab-pane label="已关闭" name="close"></el-tab-pane>
                </el-tabs>
                <div class="managetopmargin display_flex flex_wrap">
                    <div class="li flex_50">
                        <el-input style="width: calc(70% + 31px)" placeholder="默认搜索最近30天的单据，可在“更多条件”中调整时间范围" v-model="input3" class="input-with-select">
                          <template #prepend>
                            <el-select style="width: 100px;" v-model="select" placeholder="请选择">
                              <el-option label="订单号" value="1"></el-option>
                              <el-option label="收件人" value="2"></el-option>
                            </el-select>
                          </template>
                          <!-- <template #append>
                            <el-button icon="el-icon-search"></el-button>
                          </template> -->
                        </el-input>
                    </div>
                    <div class="li flex_50 display_flex">
                        <div class="name">下单时间：</div>
                        <div class="flex">
                            <el-date-picker
                             style="width: 70%;"
                              v-model="value1"
                              type="datetimerange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <!-- <div  v-if="more" class="li flex_50 display_flex">
                        <div class="name">跟踪号状态：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">币别：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">创建方式：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">导出跟踪号：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">跟踪号状态：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                     <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">区域：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">VAT税务类型：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">VAT税务类型：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">VAT税号：</div>
                        <div class="flex">
                            <el-input style="width: 70%;" v-model="value" placeholder="请输入内容"></el-input>
                        </div>
                    </div>
                    <div v-if="more" class="li flex_50 display_flex">
                        <div class="name">开票订单：</div>
                        <div class="flex">
                            <el-select style="width: 70%;" v-model="value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                        </div>
                    </div> -->
                    <div class="li flex_50 text_right">
                        <el-button type="primary">搜索</el-button>
                    </div>
                    <!-- <div class="li flex_50">
                        <p @click="more = !more" class="cg_search display_flex">
                            更{{more? '少':'多'}}筛选条件
                            <CaretTop v-if="more" style="width:20px;" />
                            <CaretBottom v-if="!more" style="width:20px;" />
                        </p>
                    </div> -->
                </div>
                <div class="table_box">
                    <div class="table_head bg_f2f display_flex">
                        <div style="width: 35%;" class="">商品</div>
                        <div style="width: 15%;" class="">价格</div>
                        <div style="width: 10%;" class="">数量</div>
                        <div style="width: 10%;" class="">售后</div>
                        <div style="width: 10%;" class="">总额</div>
                        <div style="width: 10%;" class="">状态</div>
                        <div style="width: 10%;" class="">操作</div>
                    </div>
                    <div class="complaint-goods">
                      <div class="null_box" v-if="orderData.length==0"><el-empty description="您的订单是空的， 赶紧去逛逛吧"><el-button type="primary" @click="openHome">去逛逛</el-button></el-empty></div>
                        <div class="table" v-for="(imte, index) in orderData" :key="index">
                            <div class="table_title display_flex">
                                <div class="time flex">
                                    <!-- <el-checkbox v-model="checked1"></el-checkbox> -->
                                    订单号：{{imte.no}} 下单时间：{{imte.createTime}}
                                </div>
                                <div class="name display_flex">
                                    <!-- <div>巴巴里狮平台</div> -->
                                    <div class="icon" @click="getDel(imte.id)"><Delete style="width: 22px;margin-top: 4px;" /></div>
                                </div>
                            </div>
                             <!-- @click="openGoodDetail(imte.id)" -->
                            <div class="table_body display_flex hand" v-for="(good, goodId) in imte.orderDetails" :key="goodId">
                                <div style="width: 35%;" class="b_r_6e6" :class="goodId!=imte.orderDetails.length-1? 'bb_6e6':''">
                                    <div class="list_th">
                                    <div class="good display_flex">
                                        <div class="checkbox_li"><img :src="good.logo" alt=""></div>
                                        <div class="text_box">
                                            <a class="text_hid_link">{{good.title}}</a>
                                            <p>规格：{{good.skuName}}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6" :class="goodId!=imte.orderDetails.length-1? 'bb_6e6':''">
                                    <div class="list" style="color: #FF5722;">￥ {{regFenToYuan(good.price)}}</div>
                                </div>
                                <div style="width: 10%;" class="b_r_6e6" :class="goodId!=imte.orderDetails.length-1? 'bb_6e6':''">
                                    <div class="list" style="color: #999;">{{good.num}}</div>
                                </div>
                                <div style="width: 10%;" class="b_r_6e6">
                                    <div @click.stop="getAfterSale(imte.id, good.goodsId)" class="list red_text hand" v-if="imte.status == 3">售后服务</div>
                                </div>
                                <div style="width: 10%;" class="b_r_6e6">
                                    <div v-if="imte.orderDetails.length == 1" class="list" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.totalPrice)}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==0&&goodId==imte.orderDetails.length/2-1" class="list pat_bb" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.totalPrice)}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==1&&goodId==parseInt(imte.orderDetails.length/2)" class="list" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.totalPrice)}}</div>
                                </div>
                                <div style="width: 10%;" class="b_r_6e6">
                                    <div v-if="imte.orderDetails.length == 1" class="list" style="color: #FF5722;">{{imte.status == 0? '待付款':imte.status == 1? '配货中':imte.status == 2? '待收货':imte.status == 3? '已完成':imte.status == 4? '已关闭':''}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==0&&goodId==imte.orderDetails.length/2-1" class="list pat_bb" style="color: #FF5722;">{{imte.status == 0? '待付款':imte.status == 1? '配货中':imte.status == 2? '待收货':imte.status == 3? '已完成':imte.status == 4? '已关闭':''}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==1&&goodId==parseInt(imte.orderDetails.length/2)" class="list" style="color: #FF5722;">{{imte.status == 0? '待付款':imte.status == 1? '配货中':imte.status == 2? '待收货':imte.status == 3? '已完成':imte.status == 4? '已关闭':''}}</div>
                                </div>
                                <div style="width: 10%;">
                                    <div v-if="imte.orderDetails.length == 1" style="padding-top: 40px;">
                                      <div @click.stop="getLook(imte.id)" class="list_s red_text hand">查看订单</div>
                                      <div @click.stop="getEvaluate(imte.id)" class="list_s red_text hand">评价</div>
                                      <div @click.stop="getPay(imte.id)" class="list_s red_text hand" v-if="imte.status == 0">付款</div>
                                      <div @click.stop="getLogistics(imte.deliverNo)" class="list_s red_text hand" v-if="imte.scmStatus == 'WAIT_BUYER_CONFIRM_GOODS'">物流轨迹</div>
                                      <div @click.stop="getCancelOrder(imte.id)" class="list_s red_text hand" v-if="imte.status == 1">取消订单</div>
                                    </div>
                                    <div v-else-if="imte.orderDetails.length%2==0&&goodId==imte.orderDetails.length/2" style="margin-top: -30%;">
                                      <div @click.stop="getLook(imte.id)" class="list_s red_text hand">查看订单</div>
                                      <div @click.stop="getPay(imte.id)" class="list_s red_text hand" v-if="imte.status == 0">付款</div>
                                      <div @click.stop="getLogistics(imte.deliverNo)" class="list_s red_text hand" v-if="imte.scmStatus == 'WAIT_BUYER_CONFIRM_GOODS'">物流轨迹</div>
                                      <div @click.stop="getCancelOrder(imte.id)" class="list_s red_text hand" v-if="imte.status == 1">取消订单</div>
                                    </div>
                                    <div style="padding-top: 30%;" v-else-if="imte.orderDetails.length%2==1&&goodId==parseInt(imte.orderDetails.length/2)">
                                      <div @click.stop="getLook(imte.id)" class="list_s red_text hand">查看订单</div>
                                      <div @click.stop="getPay(imte.id)" class="list_s red_text hand" v-if="imte.status == 0">付款</div>
                                      <div @click.stop="getLogistics(imte.deliverNo)" class="list_s red_text hand" v-if="imte.scmStatus == 'WAIT_BUYER_CONFIRM_GOODS'">物流轨迹</div>
                                      <div @click.stop="getCancelOrder(imte.id)" class="list_s red_text hand" v-if="imte.status == 1">取消订单</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paga" v-if="orderData.length>0">
                        <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
          title="物流轨迹"
          v-model="logisticsVisible"
          width="40%"
          top="30px"
        >
          <div class="body_box">
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.time"
                >
                  <div class="">{{activity.status}}</div>
                </el-timeline-item>
              </el-timeline>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <!-- <el-button @click="logisticsVisible = false">取 消</el-button> -->
              <el-button type="primary" @click="logisticsVisible = false">确 定</el-button>
            </span>
          </template>
        </el-dialog>
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
export default {
    mixins: [mixin],
    data() {
        return {
            logisticsVisible: false, // 物流轨迹弹窗
            activities: [ // 物流数据
              {
                content: '活动按期开始',
                timestamp: '2018-04-15',
              },
              {
                content: '通过审核',
                timestamp: '2018-04-13',
              },
              {
                content: '创建成功',
                timestamp: '2018-04-11',
              },
            ],
            more: false,
            activeName: 'all',
            input3: '',
            select: '1',
             options: [
               {
                 value: '选项1',
                 label: '黄金糕',
               },
               {
                 value: '选项2',
                 label: '双皮奶',
               },
               {
                 value: '选项3',
                 label: '蚵仔煎',
               },
               {
                 value: '选项4',
                 label: '龙须面',
               },
               {
                 value: '选项5',
                 label: '北京烤鸭',
               },
             ],
             value: '',
             orderType: '',
             orderData: []
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$requestJSON({
      	      url: 'shop/order/list',
      	      method: 'POST',
              data: {
                page: this.currentPage,
                limit: this.pageSize,
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
                status: this.orderType
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.orderData = res.data
                   this.total = res.total
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        handleClick(val) {
            this.currentPage = 1
            switch(this.activeName) {
                case 'all':
                  this.orderType = ""
                    this.getData()
                    break
                case 'pending':
                    this.orderType = 0
                    this.getData()
                    break
                case 'distribution':
                    this.orderType = 1
                    this.getData()
                    break
                case 'received':
                    this.orderType = 2
                    this.getData()
                    break
                case 'complete':
                    this.orderType = 3
                    this.getData()
                    break
                case 'complete':
                    this.orderType = 4
                    this.getData()
                    break
            }
        },
        // 删除
        getDel(id) {
           this.$confirm('确定删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
             this.$requestJSON({
      	      url: 'shop/order/del',
      	      method: 'POST',
              data: {
                siteId: this.$store.state.siteId,
                id: id,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         this.$message.success ('删除成功')
                 this.getData()
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除',
            // })
          })
        },
        // 查看订单
        getLook(id) {
          this.$router.push({path:'/OrderDetail',query:{orderId:id,siteId:this.$store.state.siteId, orderId: id}})
        },
        // 评价
        getEvaluate(id) {
          this.$router.push({path:'/getEvaluate',query:{id:id,siteId:this.$store.state.siteId, orderId: id}})
        },
        // 付款
        getPay(id) {
          this.$router.push({path:'/payment',query:{siteId:this.$store.state.siteId, orderId: id}})
        },
        // 物流轨迹
        getLogistics(id) {
            this.$requestJSON({
      	      url: 'shop/kuaidi/'+id,
      	      method: 'POST',
              data: {
                siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                   this.logisticsVisible = true
                   this.activities = res.data.result.list
                //    this.orderData = res.data
                //    this.total = res.data.total
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 售后服务
        getAfterSale(id, goodId) {
          this.$router.push({path:'/afterSaleDetail',query:{siteId:this.$store.state.siteId, orderId: id, goodId: goodId}})
        },
        // 取消订单
        getCancelOrder(id) {
          this.$confirm('确定要取消此订单吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
             this.$requestJSON({
      	      url: 'shop/order/cancel',
      	      method: 'POST',
              data: {
                siteId: this.$store.state.siteId,
                id: id,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         this.$message.success ('取消订单成功')
                 this.getData()
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除',
            // })
          })
        }
    }
}
</script>
<style lang="scss" scoped>
  /deep/.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .body_box {
      height: 480px !important;
      overflow-y: auto;
  }
.product-manage {
    margin-bottom: 20px;
    .managetop {
        background-color: #fff;
        padding: 10px 20px;
        .page-tit {
            padding: 10px 0 0;
            height: 50px;
            h6 {
                font-weight: bold;
                color: #333;
                line-height: 40px;
                font-size: 16px;
            }
        }
        .reminder {
            background: #fcf8e3;
            border: 1px solid #faebcc;
            padding: 10px;
            margin: 10px 0;
            border-radius: 3px;
            .reminder-icon {
                width: 50px;
                text-align: center;
            }
            .reminder-text p {
                line-height: 24px;
                color: #a77200;
                text-align: left!important;
            }
        }
        .tab_box {
            margin: 10px 0;
            .managetopmargin {
                margin-bottom: 30px;
                .li {
                    margin-bottom: 10px;
                    .name {
                        padding-top: 9px;
                        width: 100px;
                    }
                    .cg_search {
                        color: #666;
                        font-size: 14px;
                        margin-left: 40px;
                        margin-top: 10px;
                        cursor: pointer;
                    }
                }
            }
            .table_box {
                .table_head {
                    margin: 10px 0;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    color: #666;
                }
                .table {
                    border: #e6e6e6 1px solid;
                     margin: 10px 0;
                     .table_title {
                         background-color: #f2f2f2;
                         padding: 0px 15px;
                         line-height: 35px;
                         color: #666;
                         font-size: 14px;
                         border-bottom: #e6e6e6 1px solid;
                         .icon {
                             padding-left: 10px;
                         }
                     }
                     .bb_6e6 {
                       border-bottom: #e6e6e6 1px solid;
                     }
                     .b_r_6e6 {
                         border-right: #e6e6e6 1px solid;
                     }
                     .list_th {
                         padding: 9px 15px;
                        .good {
                            width: 100%;
                            img {
                                width: 100px;
                            }
                            .text_box {
                                padding-left: 10px;
                                padding-top: 20px;
                                .color_red {
                                    columns: #CB261C;
                                }
                                a {
                                    color: #CB261C !important;
                                }
                                p {
                                    color: #666;
                                    tab-size: 12px;
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                    .list {
                        text-align: center;
                        line-height: 130px;
                    }
                    .pat_bb {
                       line-height: 0px !important;
                       padding-top: 130px;
                     }
                    .list_s {
                      text-align: center;
                    }
                }
            }
        }
    }
    .settle-bar {
        background-color: #fff;
        line-height: 40px;
        box-shadow: 0 0 5px #eee;
    }
}
</style>
