<template>
    <div class="buy">
        <div class="edition_center">
            <Breadcrumb />
            <div class="OrderInfo layui-clear">
                <h2>收银台</h2>
                <div class="OrderInfo_sum display_flex">
                    <div class="flex2">
                        <h3>请您在2小时内完成支付。</h3>
                        <p>等待付款的订单号：<span style="color: #FE7800;">{{no}}</span></p>
                    </div>
                    <div class="OrderInfo_sumright flex">
                        <div><span>订单金额：</span><span class="red_text">￥{{regFenToYuan(totalPrice)}}</span></div>
                        <div><span>待付金额：</span><span class="red_text">￥{{regFenToYuan(totalPrice)}}</span></div>
                    </div>
                </div>
            </div>
            <div class="payment_style">
                <div class="pay_title">支付方式</div>
                <div class="imte_box display_flex">
                    <el-checkbox v-model="payBalance" @click.stop="getPayMode"></el-checkbox>
                    <img class="pay_imte_img" src="../../assets/img/yue-icon.png">
                    <div class="pay">可用余额:</div>
                    <div class="pay color_a00 weight_bold">￥{{money}}</div>
                    <div class="pay red_text hand" @click="rechargeVisible=true">充值</div>
                </div>
                 <div class="imte_box bb display_flex">
                    <el-checkbox v-model="payWeixin" @click.stop="getPayWeixinMode"></el-checkbox>
                    <img class="pay_imte_img" src="../../assets/img/weixin-icon.png">
                    <div class="pay">微信支付</div>
                    <!-- <div class="pay color_a00 weight_bold">￥{{money}}</div>
                    <div class="red_text">充值</div> -->
                </div>
                <el-button class="mt_30px" @click="bePayment" :loading="paymentBtn" type="primary">确认支付</el-button>
            </div>
        </div>
        <Recharge :rechargeVisible="rechargeVisible" @cloeNo="cloeNo" />
        <CreatQrCode ref="QrCode" />
        <el-dialog
          title="输入密码"
          v-model="passwordVisible"
          width="30%"
          :before-close="handleClose"
        >
          <div class="form">
              <el-form ref="passwordForm" :model="passwordForm" :rules="passwordRules" label-width="80px">
                <el-form-item label="密码" prop="password">
                  <el-input v-model="passwordForm.password" type="password"></el-input>
                </el-form-item>
              </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="clanFrom">取 消</el-button>
              <el-button v-loading.fullscreen.lock="loading" type="primary" @click="submitPassword('passwordForm')">确 定</el-button>
            </span>
          </template>
        </el-dialog>
    </div>
</template>
<script>
import mixin from "../../views/mixin.js";
import Breadcrumb from "../../components/Breadcrumb/index.vue";
import CreatQrCode from "../../components/QCode/CreatQrCode.vue";
import options from "../../utils/addressJson";
import Recharge from "../../components/Recharge/index.vue";
import md5 from 'js-md5'
export default {
    mixins: [mixin],
    data() {
        return {
            passwordVisible: false,
            optionsList: [],
            value: '',
            data: [],
            addressData: [],
            addressVisible: false,
            options: options,
            editId: '',
            rechargeVisible: false, // 充值弹窗
            // 支付方式
            payBalance: false, // 1
            payWeixin: false, // 2
            // 余额支付
            passwordForm: {
                password: ''
            },
            passwordRules: {
                password: [
                  { required: true, message: '请输入密码', trigger: 'blur' }
                ],
            },
            addressFrom: {
                name: '', // 收件人姓名
                phone: '', // 电话
                email: '', // 邮箱
                address: '', // 地址
                country: '', // 国家
                province: '', // 省/州
                city: '', // 城市
                street: '', // 区/县
                postcode: '', // 邮编
                isDefault: '', // 1默认
                addressData: ''
            },
            optionProps: {
                value: 'name',
                label: 'name',
                children: 'childrens'
            },
            money: '',
            totalPrice: '',
            no: '',
            id: '',
            paymentBtn: false
        }
    },
    components: {
      Breadcrumb,
      CreatQrCode,
      Recharge
    },
    created() {
        // if(!window.localStorage.getItem('Token')) {
        //   this.$message.error ('请登录！')
        //   this.$router.push({path:'/login'})
        //   return
        // }
        this.getSiteId()
    },
    methods: {
        getData() {
          if(this.$route.query.orderId) {
             this.getOrder(this.$route.query.orderId)
          } else {
            this.getOrderData()
          }
            this.getWallet()
        },
         // 获取数据
        getOrderData() {
            this.$requestJSON({
      	      // url: 'shop/order/saveBuynow',
      	      url: 'shop/order/saveBuy',
      	      method: 'POST',
              data: {
                cartIds: this.$route.query.id,
                addressId: this.$route.query.addressId,
                siteId: this.siteId,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                //    this.addressData = res.data
                this.getOrder(res.data.orderId)
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 余额充值
        cloeNo() {
            this.rechargeVisible = false
            this.getWallet()
        },
        // 获取余额
        getWallet() {
            this.$requestJSON({
      	      url: 'shop/user/money',
      	      method: 'POST',
              data: {
                siteId: this.siteId,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                this.money = res.data.money
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 获取订单商品
        getOrder(id) {
            this.$requestJSON({
      	      url: 'shop/order/detail',
      	      method: 'POST',
              data: {
                orderId: id,
                siteId: this.siteId,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                   this.totalPrice = res.data.totalPrice
                   this.no = res.data.no
                   this.id = res.data.id
                //    this.addressData = res.data
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 支付方式
        getPayMode(val) {
            if(val) {
                this.payWeixin = false
            }
        },
        getPayWeixinMode(val) {
            if(val) {
                this.payBalance = false
            }
        },
        // 确认支付
        bePayment() {
            if(!this.payBalance&&!this.payWeixin) return
            // this.paymentBtn = true
            if(this.payBalance) { // 余额
                // this.bePaymentBalance()
                this.passwordVisible = true
            } else if (this.payWeixin) {
                console.log('微信支付')
                this.bePaymentWeixin()
            }
        },
        // 取消
        clanFrom() {
          this.$refs['passwordForm'].resetFields()
          this.passwordVisible = false
        },
        // 余额支付
        submitPassword(form) {
            this.$refs[form].validate((valid) => {
              if (valid) {
                // alert('submit!')
                this.loading = true
                this.bePaymentBalance()
              } else {
                console.log('error submit!!')
                return false
              }
            })
        },
        // 余额支付
        bePaymentBalance () {
            this.$requestJSON({
      	      url: 'shop/order/savePay',
      	      method: 'POST',
              data: {
                orderId: this.id,
                payType: '1',
                password: md5(this.passwordForm.password),
                siteId: this.siteId,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
              this.loading = false
      	      this.paymentBtn = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                //    this.addressData = res.data
                 this.$refs['passwordForm'].resetFields()
                this.passwordVisible = false
                // this.$message.success ('下单成功')
                this.$router.push({path:'/myOrderList', query:{siteId: this.siteId}})
      	       } else {
                 this.loading = false
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        // 微信支付
        bePaymentWeixin() {
            this.$requestJSON({
      	      url: 'shop/wxpay/order',
      	      method: 'POST',
              data: {
                orderNo: this.no,
                siteId: this.siteId,
                token: window.localStorage.getItem(this.domain + this.siteId+'Token')
              }
      	    }).then(res => {
      	      this.paymentBtn = false
      	       if(res.code === 0) {
      	         console.log(res.data)
                //    this.urlCode = res.data.urlCode
                   this.$refs.QrCode.codeRow(res.data.urlCode, res.data.order.no, this.regFenToYuan(res.data.order.totalPrice))
                //    this.addressData = res.data
                // this.$message.success ('下单成功')
                // this.$router.push({path:'/myOrderList'})
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        }
    }
}
</script>
<style lang="scss" scoped>
.OrderInfo {
    background-color: #fff;
    padding: 20px 30px;
    width: 100%;
    margin-bottom: 10px;
    border-top: 5px solid #CB261C;
    h2 {
        color: #CB261C;
        font-size: 26px;
        font-weight: bold;
        line-height: 2em;
        margin: 10px 0 30px 0;
    }
    .OrderInfo_sum {
        // justify-content: space-between;
        // align-items: flex-start;
        h3 {
            font-size: 14px;
            color: #666;
            line-height: 2em;
            margin-bottom: 5px;
        }
        .OrderInfo_sumright {
            text-align: right;
        }
        .red_text {
            font-size: 22px;
            color: #eb0000;
            font-weight: bold;
        }
    }
}
.payment_style {
    background-color: #fff;
    padding: 20px 30px;
    width: 100%;
    margin-bottom: 20px;
    .pay_title {
        padding: 8px 0px;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        color: #333;
        display: inline-block;
    }
    .bb {
        border-bottom: 1px solid #f1f1f1;
    }
    .imte_box {
        border-top: 1px solid #f1f1f1;
        padding: 10px 0;
        line-height: 24px;
        .pay_imte_img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
        /deep/ .el-checkbox {
            height: 24px;
            margin: 0 10px;
        }
        .pay {
            // margin: 29px 0;
            width: 90px;
        }
    }
}
</style>
