export const formatTime = function (valueTime) {

  if (valueTime) {
    let newData = Date.parse(new Date())
    let diffTime = Math.abs(newData - valueTime)
    if (diffTime > 7 * 24 * 3600 * 1000) {
      let date = new Date(valueTime)
      // let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      let h = date.getHours()
      h = h < 10 ? ('0' + h) : h
      let minute = date.getMinutes()
      let second = date.getSeconds()
      minute = minute < 10 ? ('1' + minute) : minute
      second = second < 10 ? ('0' + second) : second
      // return m + '-' + d + ' ' + h + ':' + minute + ':' + second
      return false

    } else if (diffTime < 7 * 24 * 3600 * 1000 && diffTime > 24 * 3600 * 1000) {
      // //注释("一周之内")

      // let time = newData - diffTime
      let dayNum = Math.floor(diffTime / (24 * 60 * 60 * 1000))
      // return dayNum + "天前"
      return false

    } else if (diffTime < 24 * 3600 * 1000 && diffTime > 3600 * 1000) {
      // //注释("一天之内")
      // let time = newData - diffTime
      let dayNum = Math.floor(diffTime / (60 * 60 * 1000))
      if(dayNum > 2) {
        return false
      } else {
        return true
      }
      // return dayNum + "小时前"

    } else if (diffTime < 3600 * 1000 && diffTime > 60) {
      // //注释("一小时之内")
      // let time = newData - diffTime
      let dayNum = Math.floor(diffTime / (60 * 1000))
      // return dayNum + "分钟前"
      return true

    } else {
      // return diffTime + '秒前'
      return true
    }
  }
}
export const diffTime = function (startDate, endDate) {

  startDate = new Date(startDate)
  endDate = new Date(endDate)
  let diff = endDate.getTime() - startDate.getTime()//时间差的毫秒数 
  if (diff < 0) {
    return '0秒前'
  }
  //计算出相差天数 
  let days = Math.floor(diff / (24 * 3600 * 1000))

  //计算出小时数 
  let leave1 = diff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数 
  let hours = Math.floor(leave1 / (3600 * 1000))

  //计算相差分钟数 
  let leave2 = leave1 % (3600 * 1000)        //计算小时数后剩余的毫秒数 
  let minutes = Math.floor(leave2 / (60 * 1000))

  //计算相差秒数 

  let leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数 
  let seconds = Math.round(leave3 / 1000)
  let returnStr = seconds + "秒前"

  if (minutes > 0) {
    returnStr = minutes + "分" + returnStr
  }

  if (hours > 0) {
    returnStr = hours + "时" + returnStr
  }

  if (days > 0) {
    returnStr = days + "天" + returnStr
  }

  return returnStr

}
export const timestampToTime = function (timestamp) {
  const date = new Date(timestamp * 1000)//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  // const Y = date.getFullYear() + '-'
  // const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  // const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
  // const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  return h + m
}
export const timestampToTimeAll = function (time) {
  const date = new Date(time)//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
  // const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  // const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  return Y + M + D
}
// 获取近一周时间
export const lastWeekTime = function () {
  var end = new Date();
  var year = end.getFullYear();
  var month = end.getMonth() + 1;//0-11表示1-12月
  var day = end.getDate();
  var dateObj = {};
  dateObj.end = year + '-' + month + '-' + day;
  if (day - 7 <= 0) {   //如果在当月7日之前
      var startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();    //1周前所在月的总天数
      if (month - 1 <= 0) { //如果在当年的1月份
        dateObj.start = (year - 1) + '-' + 12 + '-' + (31 - (7 - day));
      } else {
        dateObj.start = year + '-' + (month - 1) + '-' + (startMonthDay - (7 - day));
      }
  } else {
      dateObj.start = year + '-' + month + '-' + (day - 7);
  }
  console.log(JSON.stringify(dateObj))
  return dateObj
}
// 获取近一月时间
export const lastMonthTime = function () {
  var end = new Date();
  var year = end.getFullYear();
  var month = end.getMonth() + 1;//0-11表示1-12月
  var day = end.getDate();
  var dateObj = {};
  dateObj.end = year + '-' + month + '-' + day;
  var endMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
  if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
      dateObj.start = (year - 1) + '-' + 12 + '-' + day;
  }else{
      var startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
      if(startMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
          if(day < endMonthDay){        //当前天日期小于当前月总天数
              dateObj.start = year + '-' + (month - 1) + '-' + (startMonthDay - (endMonthDay - day));
          }else{
              dateObj.start = year + '-' + (month - 1) + '-' + startMonthDay;
          }
      }else{
          dateObj.start = year + '-' + (month - 1) + '-' + day;
      }
  }
  console.log(JSON.stringify(dateObj))
  return dateObj
}
