<template>
    <div class="Qr_code_box">
        <el-dialog
          title="微信支付"
          v-model="dialogVisible"
          :close-on-click-modal="false"
          :before-close="handleClose"
          width="400px">
          <div class="list display_flex">
            <div class="name flex">订单金额:</div>
            <div class="text flex price">￥{{totalPrice}}</div>
          </div>
          <div class="list display_flex">
            <div class="name">订单号:</div>
            <div class="text flex price">{{no}}</div>
          </div>
          <div class="code_box" id="qrcode" ref="qrCodeUrl"></div>
          <div v-if="isOutQRCode" :class="isOutQRCode? 'mask':''" @click="getRetrieve">
            <div class="code_text">
              <div>{{buying? '正在付款...':'二维码过期，点击重新获取'}}</div>
              <div v-if="!buying" class="ta_c"><i class="el-icon-refresh-right"></i></div>
            </div>
          </div>
          <!-- <template #footer>
            <div class="dialog_footer">
              <div><el-button @click="copyUrl($event)">复制</el-button></div>
              <div><el-button type="primary" @click="downloadQrcode">下载</el-button></div>
            </div>
          </template> -->
        </el-dialog>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import mixin from "../../views/mixin.js";
export default {
  mixins: [mixin],
    data() {
        return {
          dialogVisible: false, // 弹窗
          url: '', // 二维码路径
          isOutQRCode: false, // 遮罩层
          payStateTime: '', // 支付状态定时器
          outQRCode: '', // 二维码过期定时器
          buying: false, // 是否正在下单
          totalPrice: 0.00,
          no: ''
        }
    },
    mounted() {
    //   this.codeRow()
    },
    methods: {
    codeRow(url, id, totalPrice) {
      this.totalPrice = totalPrice
      this.no = id
      this.dialogVisible = true
      this.url = url
      setTimeout(()=>{
        this.creatQrCode(id) //二维码生成
      },100)
    },
    //二维码生成
    creatQrCode(id) {
      document.getElementById("qrcode").innerHTML = ""
      let qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      })
      this.payStateTime = setInterval(() => {
        this.getPayState(id)
      }, 2000)
      this.outQRCode = setTimeout(() => {
        this.isOutQRCode = true
        clearInterval(this.payStateTime)
        clearTimeout(this.outQRCode)
      }, 1000 * 60 * 60 * 2)
    },
    // 重新获取二维码
    getRetrieve() {
      // if (this.buying) return
      // this.$emit('onNextStep')
    },
    // 支付状态
    getPayState(id) {
      this.$requestJSON({
         url: 'shop/order/payStatus?' + Date.parse(new Date()),
         method: 'POST',
         data: {
           orderNo: id,
           token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
           siteId: this.$store.state.siteId
         }
       }).then(res => {
          if(res.code == 0) {
            if(res.data.payStatus == 'SUCCESS') {
              this.buying = true
              this.isOutQRCode = true
              clearInterval(this.payStateTime)
              clearTimeout(this.outQRCode)
              this.dialogVisible = false
              this.$router.push({path:'/myOrderList', query:{siteId: this.$store.state.siteId}})
            } else if (res.data == '订单异常'){
              this.buying = true
              this.isOutQRCode = true
            } else {
              // this.$message.error ('支付失败!')
            }
          }
       })
    },
    // 取消支付
    handleClose() {
      this.$confirm('确定取消支付吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            clearInterval(this.payStateTime)
            clearTimeout(this.outQRCode)
            this.dialogVisible = false
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除',
            // })
          })
    }
  }
}
</script>
<style lang="scss" scoped>
.mask {
  width: 200px;
  height: 200px;
  position:absolute;
  left:49px;
  top:83px;
  background:rgba(0, 0, 0, 1);
  filter: alpha(opacity=60);  /*设置透明度为60%*/
  opacity:0.9;  /*非IE浏览器下设置透明度为60%*/
  z-Index:99;
  display: flex;
  align-items: center;   // 垂直居中，针对的是mycontainer类下面的子元素，不包含“孙子”元素
  justify-content: center; // 水平居中，针对的是mycontainer类下面的子元素，不包含“孙子”元素
  .code_text {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    z-Index:1000;
  }
  .ta_c {
    text-align: center;
    i {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      // text-align: center;
      margin: 0 auto;
    } 
  }
}
.code_box {
   width: 200px !important;
   margin: 0 auto;
}
.dialog_footer {
  display: flex !important;
  div {
    flex: 1;
    text-align: center;
  }
}
.list {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 20px;
  .name {
    color: #333;
  }
  .text {
    text-align: right;
  }
  .price {
    color: #c81522;
  }
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
</style>
