/*省市区三级联动*/
let options = [{
  "id": "1",
  "pid": "0",
  "path": ",1,",
  "level": "1",
  "name": "亚洲",
  "name_en": "Asia",
  "name_pinyin": "yazhou",
  "code": null,
  "childrens": [{
    "id": "7",
    "pid": "1",
    "path": ",1,7,",
    "level": "2",
    "name": "中国",
    "name_en": "China",
    "name_pinyin": "zhongguo",
    "code": "CN",
    "childrens": [{
      "id": "247",
      "pid": "7",
      "path": ",1,7,247,",
      "level": "3",
      "name": "北京",
      "name_en": "Beijing",
      "name_pinyin": "beijing",
      "code": "11",
      "childrens": [{
        "id": "3021",
        "pid": "247",
        "path": ",1,7,247,3021,",
        "level": "4",
        "name": "东城",
        "name_en": "Dongcheng",
        "name_pinyin": "dongcheng",
        "code": "1",
        "childrens": []
      }, {
        "id": "3022",
        "pid": "247",
        "path": ",1,7,247,3022,",
        "level": "4",
        "name": "西城",
        "name_en": "Xicheng",
        "name_pinyin": "xicheng",
        "code": "2",
        "childrens": []
      }, {
        "id": "3023",
        "pid": "247",
        "path": ",1,7,247,3023,",
        "level": "4",
        "name": "朝阳",
        "name_en": "Chaoyang",
        "name_pinyin": "chaoyang",
        "code": "5",
        "childrens": []
      }, {
        "id": "3024",
        "pid": "247",
        "path": ",1,7,247,3024,",
        "level": "4",
        "name": "丰台",
        "name_en": "Fengtai",
        "name_pinyin": "fengtai",
        "code": "6",
        "childrens": []
      }, {
        "id": "3025",
        "pid": "247",
        "path": ",1,7,247,3025,",
        "level": "4",
        "name": "石景山",
        "name_en": "Shijingshan",
        "name_pinyin": "shijingshan",
        "code": "7",
        "childrens": []
      }, {
        "id": "3026",
        "pid": "247",
        "path": ",1,7,247,3026,",
        "level": "4",
        "name": "海淀",
        "name_en": "Haidian",
        "name_pinyin": "haidian",
        "code": "8",
        "childrens": []
      }, {
        "id": "3027",
        "pid": "247",
        "path": ",1,7,247,3027,",
        "level": "4",
        "name": "门头沟",
        "name_en": "Mentougou",
        "name_pinyin": "mentougou",
        "code": "9",
        "childrens": []
      }, {
        "id": "3028",
        "pid": "247",
        "path": ",1,7,247,3028,",
        "level": "4",
        "name": "房山",
        "name_en": "Fangshan",
        "name_pinyin": "fangshan",
        "code": "11",
        "childrens": []
      }, {
        "id": "3029",
        "pid": "247",
        "path": ",1,7,247,3029,",
        "level": "4",
        "name": "通州",
        "name_en": "Tongzhou",
        "name_pinyin": "tongzhou",
        "code": "12",
        "childrens": []
      }, {
        "id": "3030",
        "pid": "247",
        "path": ",1,7,247,3030,",
        "level": "4",
        "name": "顺义",
        "name_en": "Shunyi",
        "name_pinyin": "shunyi",
        "code": "13",
        "childrens": []
      }, {
        "id": "3031",
        "pid": "247",
        "path": ",1,7,247,3031,",
        "level": "4",
        "name": "昌平",
        "name_en": "Changping",
        "name_pinyin": "changping",
        "code": "21",
        "childrens": []
      }, {
        "id": "3032",
        "pid": "247",
        "path": ",1,7,247,3032,",
        "level": "4",
        "name": "大兴",
        "name_en": "Daxing",
        "name_pinyin": "daxing",
        "code": "24",
        "childrens": []
      }, {
        "id": "3033",
        "pid": "247",
        "path": ",1,7,247,3033,",
        "level": "4",
        "name": "平谷",
        "name_en": "Pinggu",
        "name_pinyin": "pinggu",
        "code": "26",
        "childrens": []
      }, {
        "id": "3034",
        "pid": "247",
        "path": ",1,7,247,3034,",
        "level": "4",
        "name": "怀柔",
        "name_en": "Huairou",
        "name_pinyin": "huairou",
        "code": "27",
        "childrens": []
      }, {
        "id": "3035",
        "pid": "247",
        "path": ",1,7,247,3035,",
        "level": "4",
        "name": "密云",
        "name_en": "Miyun",
        "name_pinyin": "miyun",
        "code": "28",
        "childrens": []
      }, {
        "id": "3036",
        "pid": "247",
        "path": ",1,7,247,3036,",
        "level": "4",
        "name": "延庆",
        "name_en": "Yanqing",
        "name_pinyin": "yanqing",
        "code": "29",
        "childrens": []
      }]
    }, {
      "id": "248",
      "pid": "7",
      "path": ",1,7,248,",
      "level": "3",
      "name": "天津",
      "name_en": "Tianjin",
      "name_pinyin": "tianjin",
      "code": "12",
      "childrens": [{
        "id": "3037",
        "pid": "248",
        "path": ",1,7,248,3037,",
        "level": "4",
        "name": "和平",
        "name_en": "Heping",
        "name_pinyin": "heping",
        "code": "1",
        "childrens": []
      }, {
        "id": "3038",
        "pid": "248",
        "path": ",1,7,248,3038,",
        "level": "4",
        "name": "河东",
        "name_en": "Hedong",
        "name_pinyin": "hedong",
        "code": "2",
        "childrens": []
      }, {
        "id": "3039",
        "pid": "248",
        "path": ",1,7,248,3039,",
        "level": "4",
        "name": "河西",
        "name_en": "Hexi",
        "name_pinyin": "hexi",
        "code": "3",
        "childrens": []
      }, {
        "id": "3040",
        "pid": "248",
        "path": ",1,7,248,3040,",
        "level": "4",
        "name": "南开",
        "name_en": "Nankai",
        "name_pinyin": "nankai",
        "code": "4",
        "childrens": []
      }, {
        "id": "3041",
        "pid": "248",
        "path": ",1,7,248,3041,",
        "level": "4",
        "name": "河北",
        "name_en": "Hebei",
        "name_pinyin": "hebei",
        "code": "5",
        "childrens": []
      }, {
        "id": "3042",
        "pid": "248",
        "path": ",1,7,248,3042,",
        "level": "4",
        "name": "红桥",
        "name_en": "Hongqiao",
        "name_pinyin": "hongqiao",
        "code": "6",
        "childrens": []
      }, {
        "id": "3043",
        "pid": "248",
        "path": ",1,7,248,3043,",
        "level": "4",
        "name": "滨海新区",
        "name_en": "Binghaixinqu",
        "name_pinyin": "binhaixinqu",
        "code": "26",
        "childrens": []
      }, {
        "id": "3044",
        "pid": "248",
        "path": ",1,7,248,3044,",
        "level": "4",
        "name": "东丽",
        "name_en": "Dongli",
        "name_pinyin": "dongli",
        "code": "10",
        "childrens": []
      }, {
        "id": "3045",
        "pid": "248",
        "path": ",1,7,248,3045,",
        "level": "4",
        "name": "西青",
        "name_en": "Xiqing",
        "name_pinyin": "xiqing",
        "code": "11",
        "childrens": []
      }, {
        "id": "3046",
        "pid": "248",
        "path": ",1,7,248,3046,",
        "level": "4",
        "name": "津南",
        "name_en": "Jinnan",
        "name_pinyin": "jinnan",
        "code": "12",
        "childrens": []
      }, {
        "id": "3047",
        "pid": "248",
        "path": ",1,7,248,3047,",
        "level": "4",
        "name": "北辰",
        "name_en": "Beichen",
        "name_pinyin": "beichen",
        "code": "13",
        "childrens": []
      }, {
        "id": "3048",
        "pid": "248",
        "path": ",1,7,248,3048,",
        "level": "4",
        "name": "宁河",
        "name_en": "Ninghe",
        "name_pinyin": "ninghe",
        "code": "21",
        "childrens": []
      }, {
        "id": "3049",
        "pid": "248",
        "path": ",1,7,248,3049,",
        "level": "4",
        "name": "武清",
        "name_en": "Wuqing",
        "name_pinyin": "wuqing",
        "code": "22",
        "childrens": []
      }, {
        "id": "3050",
        "pid": "248",
        "path": ",1,7,248,3050,",
        "level": "4",
        "name": "静海",
        "name_en": "Jinghai",
        "name_pinyin": "jinghai",
        "code": "23",
        "childrens": []
      }, {
        "id": "3051",
        "pid": "248",
        "path": ",1,7,248,3051,",
        "level": "4",
        "name": "宝坻",
        "name_en": "Baodi",
        "name_pinyin": "bao",
        "code": "24",
        "childrens": []
      }, {
        "id": "3052",
        "pid": "248",
        "path": ",1,7,248,3052,",
        "level": "4",
        "name": "蓟县",
        "name_en": "Jixian",
        "name_pinyin": "jixian",
        "code": "25",
        "childrens": []
      }]
    }, {
      "id": "249",
      "pid": "7",
      "path": ",1,7,249,",
      "level": "3",
      "name": "河北",
      "name_en": "Hebei",
      "name_pinyin": "hebei",
      "code": "13",
      "childrens": [{
        "id": "3053",
        "pid": "249",
        "path": ",1,7,249,3053,",
        "level": "4",
        "name": "石家庄",
        "name_en": "Shijiazhuang",
        "name_pinyin": "shijiazhuang",
        "code": "1",
        "childrens": []
      }, {
        "id": "3054",
        "pid": "249",
        "path": ",1,7,249,3054,",
        "level": "4",
        "name": "唐山",
        "name_en": "Tangshan",
        "name_pinyin": "tangshan",
        "code": "2",
        "childrens": []
      }, {
        "id": "3055",
        "pid": "249",
        "path": ",1,7,249,3055,",
        "level": "4",
        "name": "秦皇岛",
        "name_en": "Qinhuangdao",
        "name_pinyin": "qinhuangdao",
        "code": "3",
        "childrens": []
      }, {
        "id": "3056",
        "pid": "249",
        "path": ",1,7,249,3056,",
        "level": "4",
        "name": "邯郸",
        "name_en": "Handan",
        "name_pinyin": "handan",
        "code": "4",
        "childrens": []
      }, {
        "id": "3057",
        "pid": "249",
        "path": ",1,7,249,3057,",
        "level": "4",
        "name": "邢台",
        "name_en": "Xingtai",
        "name_pinyin": "xingtai",
        "code": "5",
        "childrens": []
      }, {
        "id": "3058",
        "pid": "249",
        "path": ",1,7,249,3058,",
        "level": "4",
        "name": "保定",
        "name_en": "Baoding",
        "name_pinyin": "baoding",
        "code": "6",
        "childrens": []
      }, {
        "id": "3059",
        "pid": "249",
        "path": ",1,7,249,3059,",
        "level": "4",
        "name": "张家口",
        "name_en": "Zhangjiakou",
        "name_pinyin": "zhangjiakou",
        "code": "7",
        "childrens": []
      }, {
        "id": "3060",
        "pid": "249",
        "path": ",1,7,249,3060,",
        "level": "4",
        "name": "承德",
        "name_en": "Chengde",
        "name_pinyin": "chengde",
        "code": "8",
        "childrens": []
      }, {
        "id": "3061",
        "pid": "249",
        "path": ",1,7,249,3061,",
        "level": "4",
        "name": "沧州",
        "name_en": "Cangzhou",
        "name_pinyin": "cangzhou",
        "code": "9",
        "childrens": []
      }, {
        "id": "3062",
        "pid": "249",
        "path": ",1,7,249,3062,",
        "level": "4",
        "name": "廊坊",
        "name_en": "Langfang",
        "name_pinyin": "langfang",
        "code": "10",
        "childrens": []
      }, {
        "id": "3063",
        "pid": "249",
        "path": ",1,7,249,3063,",
        "level": "4",
        "name": "衡水",
        "name_en": "Hengshui",
        "name_pinyin": "hengshui",
        "code": "11",
        "childrens": []
      }]
    }, {
      "id": "250",
      "pid": "7",
      "path": ",1,7,250,",
      "level": "3",
      "name": "山西",
      "name_en": "Shanxi",
      "name_pinyin": "shanxi",
      "code": "14",
      "childrens": [{
        "id": "3064",
        "pid": "250",
        "path": ",1,7,250,3064,",
        "level": "4",
        "name": "太原",
        "name_en": "Taiyuan",
        "name_pinyin": "taiyuan",
        "code": "1",
        "childrens": []
      }, {
        "id": "3065",
        "pid": "250",
        "path": ",1,7,250,3065,",
        "level": "4",
        "name": "大同",
        "name_en": "Datong",
        "name_pinyin": "datong",
        "code": "2",
        "childrens": []
      }, {
        "id": "3066",
        "pid": "250",
        "path": ",1,7,250,3066,",
        "level": "4",
        "name": "阳泉",
        "name_en": "Yangquan",
        "name_pinyin": "yangquan",
        "code": "3",
        "childrens": []
      }, {
        "id": "3067",
        "pid": "250",
        "path": ",1,7,250,3067,",
        "level": "4",
        "name": "长治",
        "name_en": "Changzhi",
        "name_pinyin": "changzhi",
        "code": "4",
        "childrens": []
      }, {
        "id": "3068",
        "pid": "250",
        "path": ",1,7,250,3068,",
        "level": "4",
        "name": "晋城",
        "name_en": "Jincheng",
        "name_pinyin": "jincheng",
        "code": "5",
        "childrens": []
      }, {
        "id": "3069",
        "pid": "250",
        "path": ",1,7,250,3069,",
        "level": "4",
        "name": "朔州",
        "name_en": "Shuozhou",
        "name_pinyin": "shuozhou",
        "code": "6",
        "childrens": []
      }, {
        "id": "3070",
        "pid": "250",
        "path": ",1,7,250,3070,",
        "level": "4",
        "name": "晋中",
        "name_en": "Jinzhong",
        "name_pinyin": "jinzhong",
        "code": "7",
        "childrens": []
      }, {
        "id": "3071",
        "pid": "250",
        "path": ",1,7,250,3071,",
        "level": "4",
        "name": "运城",
        "name_en": "Yuncheng",
        "name_pinyin": "yuncheng",
        "code": "8",
        "childrens": []
      }, {
        "id": "3072",
        "pid": "250",
        "path": ",1,7,250,3072,",
        "level": "4",
        "name": "忻州",
        "name_en": "Xinzhou",
        "name_pinyin": "xinzhou",
        "code": "9",
        "childrens": []
      }, {
        "id": "3073",
        "pid": "250",
        "path": ",1,7,250,3073,",
        "level": "4",
        "name": "临汾",
        "name_en": "Linfen",
        "name_pinyin": "linfen",
        "code": "10",
        "childrens": []
      }, {
        "id": "3074",
        "pid": "250",
        "path": ",1,7,250,3074,",
        "level": "4",
        "name": "吕梁",
        "name_en": "luliang",
        "name_pinyin": "lvliang",
        "code": "11",
        "childrens": []
      }]
    }, {
      "id": "251",
      "pid": "7",
      "path": ",1,7,251,",
      "level": "3",
      "name": "内蒙古",
      "name_en": "Inner Mongolia",
      "name_pinyin": "neimenggu",
      "code": "15",
      "childrens": [{
        "id": "3075",
        "pid": "251",
        "path": ",1,7,251,3075,",
        "level": "4",
        "name": "呼和浩特",
        "name_en": "Hohhot",
        "name_pinyin": "huhehaote",
        "code": "1",
        "childrens": []
      }, {
        "id": "3076",
        "pid": "251",
        "path": ",1,7,251,3076,",
        "level": "4",
        "name": "包头",
        "name_en": "Baotou",
        "name_pinyin": "baotou",
        "code": "2",
        "childrens": []
      }, {
        "id": "3077",
        "pid": "251",
        "path": ",1,7,251,3077,",
        "level": "4",
        "name": "乌海",
        "name_en": "Wuhai",
        "name_pinyin": "wuhai",
        "code": "3",
        "childrens": []
      }, {
        "id": "3078",
        "pid": "251",
        "path": ",1,7,251,3078,",
        "level": "4",
        "name": "赤峰",
        "name_en": "Chifeng",
        "name_pinyin": "chifeng",
        "code": "4",
        "childrens": []
      }, {
        "id": "3079",
        "pid": "251",
        "path": ",1,7,251,3079,",
        "level": "4",
        "name": "通辽",
        "name_en": "Tongliao",
        "name_pinyin": "tongliao",
        "code": "5",
        "childrens": []
      }, {
        "id": "3080",
        "pid": "251",
        "path": ",1,7,251,3080,",
        "level": "4",
        "name": "鄂尔多斯",
        "name_en": "Ordos",
        "name_pinyin": "eerduosi",
        "code": "6",
        "childrens": []
      }, {
        "id": "3081",
        "pid": "251",
        "path": ",1,7,251,3081,",
        "level": "4",
        "name": "呼伦贝尔",
        "name_en": "Hulun Buir",
        "name_pinyin": "hulunbeier",
        "code": "7",
        "childrens": []
      }, {
        "id": "3082",
        "pid": "251",
        "path": ",1,7,251,3082,",
        "level": "4",
        "name": "巴彦淖尔",
        "name_en": "Bayannur",
        "name_pinyin": "bayannaoer",
        "code": "8",
        "childrens": []
      }, {
        "id": "3083",
        "pid": "251",
        "path": ",1,7,251,3083,",
        "level": "4",
        "name": "乌兰察布",
        "name_en": "Ulan Qab",
        "name_pinyin": "wulanchabu",
        "code": "9",
        "childrens": []
      }, {
        "id": "3084",
        "pid": "251",
        "path": ",1,7,251,3084,",
        "level": "4",
        "name": "兴安",
        "name_en": "Xing'an",
        "name_pinyin": "xingan",
        "code": "22",
        "childrens": []
      }, {
        "id": "3085",
        "pid": "251",
        "path": ",1,7,251,3085,",
        "level": "4",
        "name": "锡林郭勒",
        "name_en": "Xilin Gol",
        "name_pinyin": "xilinguole",
        "code": "25",
        "childrens": []
      }, {
        "id": "3086",
        "pid": "251",
        "path": ",1,7,251,3086,",
        "level": "4",
        "name": "阿拉善",
        "name_en": "Alxa",
        "name_pinyin": "alashan",
        "code": "29",
        "childrens": []
      }]
    }, {
      "id": "252",
      "pid": "7",
      "path": ",1,7,252,",
      "level": "3",
      "name": "辽宁",
      "name_en": "Liaoning",
      "name_pinyin": "liaoning",
      "code": "21",
      "childrens": [{
        "id": "3087",
        "pid": "252",
        "path": ",1,7,252,3087,",
        "level": "4",
        "name": "沈阳",
        "name_en": "Shenyang",
        "name_pinyin": "shenyang",
        "code": "1",
        "childrens": []
      }, {
        "id": "3088",
        "pid": "252",
        "path": ",1,7,252,3088,",
        "level": "4",
        "name": "大连",
        "name_en": "Dalian",
        "name_pinyin": "dalian",
        "code": "2",
        "childrens": []
      }, {
        "id": "3089",
        "pid": "252",
        "path": ",1,7,252,3089,",
        "level": "4",
        "name": "鞍山",
        "name_en": "Anshan",
        "name_pinyin": "anshan",
        "code": "3",
        "childrens": []
      }, {
        "id": "3090",
        "pid": "252",
        "path": ",1,7,252,3090,",
        "level": "4",
        "name": "抚顺",
        "name_en": "Fushun",
        "name_pinyin": "fushun",
        "code": "4",
        "childrens": []
      }, {
        "id": "3091",
        "pid": "252",
        "path": ",1,7,252,3091,",
        "level": "4",
        "name": "本溪",
        "name_en": "Benxi",
        "name_pinyin": "benxi",
        "code": "5",
        "childrens": []
      }, {
        "id": "3092",
        "pid": "252",
        "path": ",1,7,252,3092,",
        "level": "4",
        "name": "丹东",
        "name_en": "Dandong",
        "name_pinyin": "dandong",
        "code": "6",
        "childrens": []
      }, {
        "id": "3093",
        "pid": "252",
        "path": ",1,7,252,3093,",
        "level": "4",
        "name": "锦州",
        "name_en": "Jinzhou",
        "name_pinyin": "jinzhou",
        "code": "7",
        "childrens": []
      }, {
        "id": "3094",
        "pid": "252",
        "path": ",1,7,252,3094,",
        "level": "4",
        "name": "营口",
        "name_en": "Yingkou",
        "name_pinyin": "yingkou",
        "code": "8",
        "childrens": []
      }, {
        "id": "3095",
        "pid": "252",
        "path": ",1,7,252,3095,",
        "level": "4",
        "name": "阜新",
        "name_en": "Fuxin",
        "name_pinyin": "fuxin",
        "code": "9",
        "childrens": []
      }, {
        "id": "3096",
        "pid": "252",
        "path": ",1,7,252,3096,",
        "level": "4",
        "name": "辽阳",
        "name_en": "Liaoyang",
        "name_pinyin": "liaoyang",
        "code": "10",
        "childrens": []
      }, {
        "id": "3097",
        "pid": "252",
        "path": ",1,7,252,3097,",
        "level": "4",
        "name": "盘锦",
        "name_en": "Panjin",
        "name_pinyin": "panjin",
        "code": "11",
        "childrens": []
      }, {
        "id": "3098",
        "pid": "252",
        "path": ",1,7,252,3098,",
        "level": "4",
        "name": "铁岭",
        "name_en": "Tieling",
        "name_pinyin": "tieling",
        "code": "12",
        "childrens": []
      }, {
        "id": "3099",
        "pid": "252",
        "path": ",1,7,252,3099,",
        "level": "4",
        "name": "朝阳",
        "name_en": "Chaoyang",
        "name_pinyin": "chaoyang",
        "code": "13",
        "childrens": []
      }, {
        "id": "3100",
        "pid": "252",
        "path": ",1,7,252,3100,",
        "level": "4",
        "name": "葫芦岛",
        "name_en": "Huludao",
        "name_pinyin": "huludao",
        "code": "14",
        "childrens": []
      }]
    }, {
      "id": "253",
      "pid": "7",
      "path": ",1,7,253,",
      "level": "3",
      "name": "吉林",
      "name_en": "Jilin",
      "name_pinyin": "jilin",
      "code": "22",
      "childrens": [{
        "id": "3101",
        "pid": "253",
        "path": ",1,7,253,3101,",
        "level": "4",
        "name": "长春",
        "name_en": "Changchun",
        "name_pinyin": "changchun",
        "code": "1",
        "childrens": []
      }, {
        "id": "3102",
        "pid": "253",
        "path": ",1,7,253,3102,",
        "level": "4",
        "name": "吉林",
        "name_en": "Jilin",
        "name_pinyin": "jilin",
        "code": "2",
        "childrens": []
      }, {
        "id": "3103",
        "pid": "253",
        "path": ",1,7,253,3103,",
        "level": "4",
        "name": "四平",
        "name_en": "Siping",
        "name_pinyin": "siping",
        "code": "3",
        "childrens": []
      }, {
        "id": "3104",
        "pid": "253",
        "path": ",1,7,253,3104,",
        "level": "4",
        "name": "辽源",
        "name_en": "Liaoyuan",
        "name_pinyin": "liaoyuan",
        "code": "4",
        "childrens": []
      }, {
        "id": "3105",
        "pid": "253",
        "path": ",1,7,253,3105,",
        "level": "4",
        "name": "通化",
        "name_en": "Tonghua",
        "name_pinyin": "tonghua",
        "code": "5",
        "childrens": []
      }, {
        "id": "3106",
        "pid": "253",
        "path": ",1,7,253,3106,",
        "level": "4",
        "name": "白山",
        "name_en": "Baishan",
        "name_pinyin": "baishan",
        "code": "6",
        "childrens": []
      }, {
        "id": "3107",
        "pid": "253",
        "path": ",1,7,253,3107,",
        "level": "4",
        "name": "松原",
        "name_en": "Songyuan",
        "name_pinyin": "songyuan",
        "code": "7",
        "childrens": []
      }, {
        "id": "3108",
        "pid": "253",
        "path": ",1,7,253,3108,",
        "level": "4",
        "name": "白城",
        "name_en": "Baicheng",
        "name_pinyin": "baicheng",
        "code": "8",
        "childrens": []
      }, {
        "id": "3109",
        "pid": "253",
        "path": ",1,7,253,3109,",
        "level": "4",
        "name": "延边",
        "name_en": "Yanbian Korean Autonomous Prefecture",
        "name_pinyin": "yanbian",
        "code": "24",
        "childrens": []
      }]
    }, {
      "id": "254",
      "pid": "7",
      "path": ",1,7,254,",
      "level": "3",
      "name": "黑龙江",
      "name_en": "Heilongjiang",
      "name_pinyin": "heilongjiang",
      "code": "23",
      "childrens": [{
        "id": "3110",
        "pid": "254",
        "path": ",1,7,254,3110,",
        "level": "4",
        "name": "哈尔滨",
        "name_en": "Harbin",
        "name_pinyin": "haerbin",
        "code": "1",
        "childrens": []
      }, {
        "id": "3111",
        "pid": "254",
        "path": ",1,7,254,3111,",
        "level": "4",
        "name": "齐齐哈尔",
        "name_en": "Qiqihar",
        "name_pinyin": "qiqihaer",
        "code": "2",
        "childrens": []
      }, {
        "id": "3112",
        "pid": "254",
        "path": ",1,7,254,3112,",
        "level": "4",
        "name": "鸡西",
        "name_en": "Jixi",
        "name_pinyin": "jixi",
        "code": "3",
        "childrens": []
      }, {
        "id": "3113",
        "pid": "254",
        "path": ",1,7,254,3113,",
        "level": "4",
        "name": "鹤岗",
        "name_en": "Hegang",
        "name_pinyin": "hegang",
        "code": "4",
        "childrens": []
      }, {
        "id": "3114",
        "pid": "254",
        "path": ",1,7,254,3114,",
        "level": "4",
        "name": "双鸭山",
        "name_en": "Shuangyashan",
        "name_pinyin": "shuangyashan",
        "code": "5",
        "childrens": []
      }, {
        "id": "3115",
        "pid": "254",
        "path": ",1,7,254,3115,",
        "level": "4",
        "name": "大庆",
        "name_en": "Daqing",
        "name_pinyin": "daqing",
        "code": "6",
        "childrens": []
      }, {
        "id": "3116",
        "pid": "254",
        "path": ",1,7,254,3116,",
        "level": "4",
        "name": "伊春",
        "name_en": "Yichun",
        "name_pinyin": "yichun",
        "code": "7",
        "childrens": []
      }, {
        "id": "3117",
        "pid": "254",
        "path": ",1,7,254,3117,",
        "level": "4",
        "name": "佳木斯",
        "name_en": "Jiamusi",
        "name_pinyin": "jiamusi",
        "code": "8",
        "childrens": []
      }, {
        "id": "3118",
        "pid": "254",
        "path": ",1,7,254,3118,",
        "level": "4",
        "name": "七台河",
        "name_en": "Qitaihe",
        "name_pinyin": "qitaihe",
        "code": "9",
        "childrens": []
      }, {
        "id": "3119",
        "pid": "254",
        "path": ",1,7,254,3119,",
        "level": "4",
        "name": "牡丹江",
        "name_en": "Mudanjiang",
        "name_pinyin": "mudanjiang",
        "code": "10",
        "childrens": []
      }, {
        "id": "3120",
        "pid": "254",
        "path": ",1,7,254,3120,",
        "level": "4",
        "name": "黑河",
        "name_en": "Heihe",
        "name_pinyin": "heihe",
        "code": "11",
        "childrens": []
      }, {
        "id": "3121",
        "pid": "254",
        "path": ",1,7,254,3121,",
        "level": "4",
        "name": "绥化",
        "name_en": "Suihua",
        "name_pinyin": "suihua",
        "code": "12",
        "childrens": []
      }, {
        "id": "3122",
        "pid": "254",
        "path": ",1,7,254,3122,",
        "level": "4",
        "name": "大兴安岭",
        "name_en": "Da Hinggan Ling",
        "name_pinyin": "daxinganling",
        "code": "27",
        "childrens": []
      }]
    }, {
      "id": "255",
      "pid": "7",
      "path": ",1,7,255,",
      "level": "3",
      "name": "上海",
      "name_en": "Shanghai",
      "name_pinyin": "shanghai",
      "code": "31",
      "childrens": [{
        "id": "3123",
        "pid": "255",
        "path": ",1,7,255,3123,",
        "level": "4",
        "name": "黄浦",
        "name_en": "Huangpu",
        "name_pinyin": "huangpu",
        "code": "1",
        "childrens": []
      }, {
        "id": "3124",
        "pid": "255",
        "path": ",1,7,255,3124,",
        "level": "4",
        "name": "卢湾",
        "name_en": "Luwan",
        "name_pinyin": "luwan",
        "code": "3",
        "childrens": []
      }, {
        "id": "3125",
        "pid": "255",
        "path": ",1,7,255,3125,",
        "level": "4",
        "name": "徐汇",
        "name_en": "Xuhui",
        "name_pinyin": "xuhui",
        "code": "4",
        "childrens": []
      }, {
        "id": "3126",
        "pid": "255",
        "path": ",1,7,255,3126,",
        "level": "4",
        "name": "长宁",
        "name_en": "Changning",
        "name_pinyin": "changning",
        "code": "5",
        "childrens": []
      }, {
        "id": "3127",
        "pid": "255",
        "path": ",1,7,255,3127,",
        "level": "4",
        "name": "静安",
        "name_en": "Jing'an",
        "name_pinyin": "jingan",
        "code": "6",
        "childrens": []
      }, {
        "id": "3128",
        "pid": "255",
        "path": ",1,7,255,3128,",
        "level": "4",
        "name": "普陀",
        "name_en": "Putuo",
        "name_pinyin": "putuo",
        "code": "7",
        "childrens": []
      }, {
        "id": "3129",
        "pid": "255",
        "path": ",1,7,255,3129,",
        "level": "4",
        "name": "闸北",
        "name_en": "Zhabei",
        "name_pinyin": "zhabei",
        "code": "8",
        "childrens": []
      }, {
        "id": "3130",
        "pid": "255",
        "path": ",1,7,255,3130,",
        "level": "4",
        "name": "虹口",
        "name_en": "Hongkou",
        "name_pinyin": "hongkou",
        "code": "9",
        "childrens": []
      }, {
        "id": "3131",
        "pid": "255",
        "path": ",1,7,255,3131,",
        "level": "4",
        "name": "杨浦",
        "name_en": "Yangpu",
        "name_pinyin": "yangpu",
        "code": "11",
        "childrens": []
      }, {
        "id": "3132",
        "pid": "255",
        "path": ",1,7,255,3132,",
        "level": "4",
        "name": "闵行",
        "name_en": "Minhang",
        "name_pinyin": "xing",
        "code": "12",
        "childrens": []
      }, {
        "id": "3133",
        "pid": "255",
        "path": ",1,7,255,3133,",
        "level": "4",
        "name": "宝山",
        "name_en": "Baoshan",
        "name_pinyin": "baoshan",
        "code": "13",
        "childrens": []
      }, {
        "id": "3134",
        "pid": "255",
        "path": ",1,7,255,3134,",
        "level": "4",
        "name": "嘉定",
        "name_en": "Jiading",
        "name_pinyin": "jiading",
        "code": "14",
        "childrens": []
      }, {
        "id": "3135",
        "pid": "255",
        "path": ",1,7,255,3135,",
        "level": "4",
        "name": "浦东新区",
        "name_en": "Pudong New Area",
        "name_pinyin": "pudongxinqu",
        "code": "15",
        "childrens": []
      }, {
        "id": "3136",
        "pid": "255",
        "path": ",1,7,255,3136,",
        "level": "4",
        "name": "金山",
        "name_en": "Jinshan",
        "name_pinyin": "jinshan",
        "code": "16",
        "childrens": []
      }, {
        "id": "3137",
        "pid": "255",
        "path": ",1,7,255,3137,",
        "level": "4",
        "name": "松江",
        "name_en": "Songjiang",
        "name_pinyin": "songjiang",
        "code": "17",
        "childrens": []
      }, {
        "id": "3138",
        "pid": "255",
        "path": ",1,7,255,3138,",
        "level": "4",
        "name": "奉贤",
        "name_en": "Fengxian",
        "name_pinyin": "fengxian",
        "code": "26",
        "childrens": []
      }, {
        "id": "3139",
        "pid": "255",
        "path": ",1,7,255,3139,",
        "level": "4",
        "name": "青浦",
        "name_en": "Qingpu",
        "name_pinyin": "qingpu",
        "code": "29",
        "childrens": []
      }, {
        "id": "3140",
        "pid": "255",
        "path": ",1,7,255,3140,",
        "level": "4",
        "name": "崇明",
        "name_en": "Chongming",
        "name_pinyin": "chongming",
        "code": "30",
        "childrens": []
      }]
    }, {
      "id": "256",
      "pid": "7",
      "path": ",1,7,256,",
      "level": "3",
      "name": "江苏",
      "name_en": "Jiangsu",
      "name_pinyin": "jiangsu",
      "code": "32",
      "childrens": [{
        "id": "3141",
        "pid": "256",
        "path": ",1,7,256,3141,",
        "level": "4",
        "name": "南京",
        "name_en": "Nanjing",
        "name_pinyin": "nanjing",
        "code": "1",
        "childrens": []
      }, {
        "id": "3142",
        "pid": "256",
        "path": ",1,7,256,3142,",
        "level": "4",
        "name": "无锡",
        "name_en": "Wuxi",
        "name_pinyin": "wuxi",
        "code": "2",
        "childrens": []
      }, {
        "id": "3143",
        "pid": "256",
        "path": ",1,7,256,3143,",
        "level": "4",
        "name": "徐州",
        "name_en": "Xuzhou",
        "name_pinyin": "xuzhou",
        "code": "3",
        "childrens": []
      }, {
        "id": "3144",
        "pid": "256",
        "path": ",1,7,256,3144,",
        "level": "4",
        "name": "常州",
        "name_en": "Changzhou",
        "name_pinyin": "changzhou",
        "code": "4",
        "childrens": []
      }, {
        "id": "3145",
        "pid": "256",
        "path": ",1,7,256,3145,",
        "level": "4",
        "name": "苏州",
        "name_en": "Suzhou",
        "name_pinyin": "suzhou",
        "code": "5",
        "childrens": []
      }, {
        "id": "3146",
        "pid": "256",
        "path": ",1,7,256,3146,",
        "level": "4",
        "name": "南通",
        "name_en": "Nantong",
        "name_pinyin": "nantong",
        "code": "6",
        "childrens": []
      }, {
        "id": "3147",
        "pid": "256",
        "path": ",1,7,256,3147,",
        "level": "4",
        "name": "连云港",
        "name_en": "Lianyungang",
        "name_pinyin": "lianyungang",
        "code": "7",
        "childrens": []
      }, {
        "id": "3148",
        "pid": "256",
        "path": ",1,7,256,3148,",
        "level": "4",
        "name": "淮安",
        "name_en": "Huai'an",
        "name_pinyin": "huaian",
        "code": "8",
        "childrens": []
      }, {
        "id": "3149",
        "pid": "256",
        "path": ",1,7,256,3149,",
        "level": "4",
        "name": "盐城",
        "name_en": "Yancheng",
        "name_pinyin": "yancheng",
        "code": "9",
        "childrens": []
      }, {
        "id": "3150",
        "pid": "256",
        "path": ",1,7,256,3150,",
        "level": "4",
        "name": "扬州",
        "name_en": "Yangzhou",
        "name_pinyin": "yangzhou",
        "code": "10",
        "childrens": []
      }, {
        "id": "3151",
        "pid": "256",
        "path": ",1,7,256,3151,",
        "level": "4",
        "name": "镇江",
        "name_en": "Zhenjiang",
        "name_pinyin": "zhenjiang",
        "code": "11",
        "childrens": []
      }, {
        "id": "3152",
        "pid": "256",
        "path": ",1,7,256,3152,",
        "level": "4",
        "name": "泰州",
        "name_en": "Taizhou",
        "name_pinyin": "taizhou",
        "code": "12",
        "childrens": []
      }, {
        "id": "3153",
        "pid": "256",
        "path": ",1,7,256,3153,",
        "level": "4",
        "name": "宿迁",
        "name_en": "Suqian",
        "name_pinyin": "suqian",
        "code": "13",
        "childrens": []
      }]
    }, {
      "id": "257",
      "pid": "7",
      "path": ",1,7,257,",
      "level": "3",
      "name": "浙江",
      "name_en": "Zhejiang",
      "name_pinyin": "zhejiang",
      "code": "33",
      "childrens": [{
        "id": "3154",
        "pid": "257",
        "path": ",1,7,257,3154,",
        "level": "4",
        "name": "杭州",
        "name_en": "Hangzhou",
        "name_pinyin": "hangzhou",
        "code": "1",
        "childrens": []
      }, {
        "id": "3155",
        "pid": "257",
        "path": ",1,7,257,3155,",
        "level": "4",
        "name": "宁波",
        "name_en": "Ningbo",
        "name_pinyin": "ningbo",
        "code": "2",
        "childrens": []
      }, {
        "id": "3156",
        "pid": "257",
        "path": ",1,7,257,3156,",
        "level": "4",
        "name": "温州",
        "name_en": "Wenzhou",
        "name_pinyin": "wenzhou",
        "code": "3",
        "childrens": []
      }, {
        "id": "3157",
        "pid": "257",
        "path": ",1,7,257,3157,",
        "level": "4",
        "name": "嘉兴",
        "name_en": "Jiaxing",
        "name_pinyin": "jiaxing",
        "code": "4",
        "childrens": []
      }, {
        "id": "3158",
        "pid": "257",
        "path": ",1,7,257,3158,",
        "level": "4",
        "name": "湖州",
        "name_en": "Huzhou",
        "name_pinyin": "huzhou",
        "code": "5",
        "childrens": []
      }, {
        "id": "3159",
        "pid": "257",
        "path": ",1,7,257,3159,",
        "level": "4",
        "name": "绍兴",
        "name_en": "Shaoxing",
        "name_pinyin": "shaoxing",
        "code": "6",
        "childrens": []
      }, {
        "id": "3160",
        "pid": "257",
        "path": ",1,7,257,3160,",
        "level": "4",
        "name": "金华",
        "name_en": "Jinhua",
        "name_pinyin": "jinhua",
        "code": "7",
        "childrens": []
      }, {
        "id": "3161",
        "pid": "257",
        "path": ",1,7,257,3161,",
        "level": "4",
        "name": "衢州",
        "name_en": "Quzhou",
        "name_pinyin": "zhou",
        "code": "8",
        "childrens": []
      }, {
        "id": "3162",
        "pid": "257",
        "path": ",1,7,257,3162,",
        "level": "4",
        "name": "舟山",
        "name_en": "Zhoushan",
        "name_pinyin": "zhoushan",
        "code": "9",
        "childrens": []
      }, {
        "id": "3163",
        "pid": "257",
        "path": ",1,7,257,3163,",
        "level": "4",
        "name": "台州",
        "name_en": "Taizhou",
        "name_pinyin": "taizhou",
        "code": "10",
        "childrens": []
      }, {
        "id": "3164",
        "pid": "257",
        "path": ",1,7,257,3164,",
        "level": "4",
        "name": "丽水",
        "name_en": "Lishui",
        "name_pinyin": "lishui",
        "code": "11",
        "childrens": []
      }]
    }, {
      "id": "258",
      "pid": "7",
      "path": ",1,7,258,",
      "level": "3",
      "name": "安徽",
      "name_en": "Anhui",
      "name_pinyin": "anhui",
      "code": "34",
      "childrens": [{
        "id": "3165",
        "pid": "258",
        "path": ",1,7,258,3165,",
        "level": "4",
        "name": "合肥",
        "name_en": "Hefei",
        "name_pinyin": "hefei",
        "code": "1",
        "childrens": []
      }, {
        "id": "3166",
        "pid": "258",
        "path": ",1,7,258,3166,",
        "level": "4",
        "name": "芜湖",
        "name_en": "Wuhu",
        "name_pinyin": "wuhu",
        "code": "2",
        "childrens": []
      }, {
        "id": "3167",
        "pid": "258",
        "path": ",1,7,258,3167,",
        "level": "4",
        "name": "蚌埠",
        "name_en": "Bengbu",
        "name_pinyin": "bangbu",
        "code": "3",
        "childrens": []
      }, {
        "id": "3168",
        "pid": "258",
        "path": ",1,7,258,3168,",
        "level": "4",
        "name": "淮南",
        "name_en": "Huainan",
        "name_pinyin": "huainan",
        "code": "4",
        "childrens": []
      }, {
        "id": "3169",
        "pid": "258",
        "path": ",1,7,258,3169,",
        "level": "4",
        "name": "马鞍山",
        "name_en": "Ma'anshan",
        "name_pinyin": "maanshan",
        "code": "5",
        "childrens": []
      }, {
        "id": "3170",
        "pid": "258",
        "path": ",1,7,258,3170,",
        "level": "4",
        "name": "淮北",
        "name_en": "Huaibei",
        "name_pinyin": "huaibei",
        "code": "6",
        "childrens": []
      }, {
        "id": "3171",
        "pid": "258",
        "path": ",1,7,258,3171,",
        "level": "4",
        "name": "铜陵",
        "name_en": "Tongling",
        "name_pinyin": "tongling",
        "code": "7",
        "childrens": []
      }, {
        "id": "3172",
        "pid": "258",
        "path": ",1,7,258,3172,",
        "level": "4",
        "name": "安庆",
        "name_en": "Anqing",
        "name_pinyin": "anqing",
        "code": "8",
        "childrens": []
      }, {
        "id": "3173",
        "pid": "258",
        "path": ",1,7,258,3173,",
        "level": "4",
        "name": "黄山",
        "name_en": "Huangshan",
        "name_pinyin": "huangshan",
        "code": "10",
        "childrens": []
      }, {
        "id": "3174",
        "pid": "258",
        "path": ",1,7,258,3174,",
        "level": "4",
        "name": "滁州",
        "name_en": "Chuzhou",
        "name_pinyin": "chuzhou",
        "code": "11",
        "childrens": []
      }, {
        "id": "3175",
        "pid": "258",
        "path": ",1,7,258,3175,",
        "level": "4",
        "name": "阜阳",
        "name_en": "Fuyang",
        "name_pinyin": "fuyang",
        "code": "12",
        "childrens": []
      }, {
        "id": "3176",
        "pid": "258",
        "path": ",1,7,258,3176,",
        "level": "4",
        "name": "宿州",
        "name_en": "Suzhou",
        "name_pinyin": "suzhou",
        "code": "13",
        "childrens": []
      }, {
        "id": "3177",
        "pid": "258",
        "path": ",1,7,258,3177,",
        "level": "4",
        "name": "六安",
        "name_en": "Lu'an",
        "name_pinyin": "liuan",
        "code": "15",
        "childrens": []
      }, {
        "id": "3178",
        "pid": "258",
        "path": ",1,7,258,3178,",
        "level": "4",
        "name": "亳州",
        "name_en": "Bozhou",
        "name_pinyin": "zhou",
        "code": "16",
        "childrens": []
      }, {
        "id": "3179",
        "pid": "258",
        "path": ",1,7,258,3179,",
        "level": "4",
        "name": "池州",
        "name_en": "Chizhou",
        "name_pinyin": "chizhou",
        "code": "17",
        "childrens": []
      }, {
        "id": "3180",
        "pid": "258",
        "path": ",1,7,258,3180,",
        "level": "4",
        "name": "宣城",
        "name_en": "Xuancheng",
        "name_pinyin": "xuancheng",
        "code": "18",
        "childrens": []
      }]
    }, {
      "id": "259",
      "pid": "7",
      "path": ",1,7,259,",
      "level": "3",
      "name": "福建",
      "name_en": "Fujian",
      "name_pinyin": "fujian",
      "code": "35",
      "childrens": [{
        "id": "3181",
        "pid": "259",
        "path": ",1,7,259,3181,",
        "level": "4",
        "name": "福州",
        "name_en": "Fuzhou",
        "name_pinyin": "fuzhou",
        "code": "1",
        "childrens": []
      }, {
        "id": "3182",
        "pid": "259",
        "path": ",1,7,259,3182,",
        "level": "4",
        "name": "厦门",
        "name_en": "Xiamen",
        "name_pinyin": "xiamen",
        "code": "2",
        "childrens": []
      }, {
        "id": "3183",
        "pid": "259",
        "path": ",1,7,259,3183,",
        "level": "4",
        "name": "莆田",
        "name_en": "Putian",
        "name_pinyin": "putian",
        "code": "3",
        "childrens": []
      }, {
        "id": "3184",
        "pid": "259",
        "path": ",1,7,259,3184,",
        "level": "4",
        "name": "三明",
        "name_en": "Sanming",
        "name_pinyin": "sanming",
        "code": "4",
        "childrens": []
      }, {
        "id": "3185",
        "pid": "259",
        "path": ",1,7,259,3185,",
        "level": "4",
        "name": "泉州",
        "name_en": "Quanzhou",
        "name_pinyin": "quanzhou",
        "code": "5",
        "childrens": []
      }, {
        "id": "3186",
        "pid": "259",
        "path": ",1,7,259,3186,",
        "level": "4",
        "name": "漳州",
        "name_en": "Zhangzhou",
        "name_pinyin": "zhangzhou",
        "code": "6",
        "childrens": []
      }, {
        "id": "3187",
        "pid": "259",
        "path": ",1,7,259,3187,",
        "level": "4",
        "name": "南平",
        "name_en": "Nanping",
        "name_pinyin": "nanping",
        "code": "7",
        "childrens": []
      }, {
        "id": "3188",
        "pid": "259",
        "path": ",1,7,259,3188,",
        "level": "4",
        "name": "龙岩",
        "name_en": "Longyan",
        "name_pinyin": "longyan",
        "code": "8",
        "childrens": []
      }, {
        "id": "3189",
        "pid": "259",
        "path": ",1,7,259,3189,",
        "level": "4",
        "name": "宁德",
        "name_en": "Ningde",
        "name_pinyin": "ningde",
        "code": "9",
        "childrens": []
      }]
    }, {
      "id": "260",
      "pid": "7",
      "path": ",1,7,260,",
      "level": "3",
      "name": "江西",
      "name_en": "Jiangxi",
      "name_pinyin": "jiangxi",
      "code": "36",
      "childrens": [{
        "id": "3190",
        "pid": "260",
        "path": ",1,7,260,3190,",
        "level": "4",
        "name": "南昌",
        "name_en": "Nanchang",
        "name_pinyin": "nanchang",
        "code": "1",
        "childrens": []
      }, {
        "id": "3191",
        "pid": "260",
        "path": ",1,7,260,3191,",
        "level": "4",
        "name": "景德镇",
        "name_en": "Jingdezhen",
        "name_pinyin": "jingdezhen",
        "code": "2",
        "childrens": []
      }, {
        "id": "3192",
        "pid": "260",
        "path": ",1,7,260,3192,",
        "level": "4",
        "name": "萍乡",
        "name_en": "Pingxiang",
        "name_pinyin": "pingxiang",
        "code": "3",
        "childrens": []
      }, {
        "id": "3193",
        "pid": "260",
        "path": ",1,7,260,3193,",
        "level": "4",
        "name": "九江",
        "name_en": "Jiujiang",
        "name_pinyin": "jiujiang",
        "code": "4",
        "childrens": []
      }, {
        "id": "3194",
        "pid": "260",
        "path": ",1,7,260,3194,",
        "level": "4",
        "name": "新余",
        "name_en": "Xinyu",
        "name_pinyin": "xinyu",
        "code": "5",
        "childrens": []
      }, {
        "id": "3195",
        "pid": "260",
        "path": ",1,7,260,3195,",
        "level": "4",
        "name": "鹰潭",
        "name_en": "Yingtan",
        "name_pinyin": "yingtan",
        "code": "6",
        "childrens": []
      }, {
        "id": "3196",
        "pid": "260",
        "path": ",1,7,260,3196,",
        "level": "4",
        "name": "赣州",
        "name_en": "Ganzhou",
        "name_pinyin": "ganzhou",
        "code": "7",
        "childrens": []
      }, {
        "id": "3197",
        "pid": "260",
        "path": ",1,7,260,3197,",
        "level": "4",
        "name": "吉安",
        "name_en": "Ji'an",
        "name_pinyin": "jian",
        "code": "8",
        "childrens": []
      }, {
        "id": "3198",
        "pid": "260",
        "path": ",1,7,260,3198,",
        "level": "4",
        "name": "宜春",
        "name_en": "Yichun",
        "name_pinyin": "yichun",
        "code": "9",
        "childrens": []
      }, {
        "id": "3199",
        "pid": "260",
        "path": ",1,7,260,3199,",
        "level": "4",
        "name": "抚州",
        "name_en": "Fuzhou",
        "name_pinyin": "fuzhou",
        "code": "10",
        "childrens": []
      }, {
        "id": "3200",
        "pid": "260",
        "path": ",1,7,260,3200,",
        "level": "4",
        "name": "上饶",
        "name_en": "Shangrao",
        "name_pinyin": "shangrao",
        "code": "11",
        "childrens": []
      }]
    }, {
      "id": "261",
      "pid": "7",
      "path": ",1,7,261,",
      "level": "3",
      "name": "山东",
      "name_en": "Shandong",
      "name_pinyin": "shandong",
      "code": "37",
      "childrens": [{
        "id": "3201",
        "pid": "261",
        "path": ",1,7,261,3201,",
        "level": "4",
        "name": "济南",
        "name_en": "Jinan",
        "name_pinyin": "jinan",
        "code": "1",
        "childrens": []
      }, {
        "id": "3202",
        "pid": "261",
        "path": ",1,7,261,3202,",
        "level": "4",
        "name": "青岛",
        "name_en": "Qingdao",
        "name_pinyin": "qingdao",
        "code": "2",
        "childrens": []
      }, {
        "id": "3203",
        "pid": "261",
        "path": ",1,7,261,3203,",
        "level": "4",
        "name": "淄博",
        "name_en": "Zibo",
        "name_pinyin": "zibo",
        "code": "3",
        "childrens": []
      }, {
        "id": "3204",
        "pid": "261",
        "path": ",1,7,261,3204,",
        "level": "4",
        "name": "枣庄",
        "name_en": "Zaozhuang",
        "name_pinyin": "zaozhuang",
        "code": "4",
        "childrens": []
      }, {
        "id": "3205",
        "pid": "261",
        "path": ",1,7,261,3205,",
        "level": "4",
        "name": "东营",
        "name_en": "Dongying",
        "name_pinyin": "dongying",
        "code": "5",
        "childrens": []
      }, {
        "id": "3206",
        "pid": "261",
        "path": ",1,7,261,3206,",
        "level": "4",
        "name": "烟台",
        "name_en": "Yantai",
        "name_pinyin": "yantai",
        "code": "6",
        "childrens": []
      }, {
        "id": "3207",
        "pid": "261",
        "path": ",1,7,261,3207,",
        "level": "4",
        "name": "潍坊",
        "name_en": "Weifang",
        "name_pinyin": "weifang",
        "code": "7",
        "childrens": []
      }, {
        "id": "3208",
        "pid": "261",
        "path": ",1,7,261,3208,",
        "level": "4",
        "name": "济宁",
        "name_en": "Jining",
        "name_pinyin": "jining",
        "code": "8",
        "childrens": []
      }, {
        "id": "3209",
        "pid": "261",
        "path": ",1,7,261,3209,",
        "level": "4",
        "name": "泰安",
        "name_en": "Tai'an",
        "name_pinyin": "taian",
        "code": "9",
        "childrens": []
      }, {
        "id": "3210",
        "pid": "261",
        "path": ",1,7,261,3210,",
        "level": "4",
        "name": "威海",
        "name_en": "Weihai",
        "name_pinyin": "weihai",
        "code": "10",
        "childrens": []
      }, {
        "id": "3211",
        "pid": "261",
        "path": ",1,7,261,3211,",
        "level": "4",
        "name": "日照",
        "name_en": "Rizhao",
        "name_pinyin": "rizhao",
        "code": "11",
        "childrens": []
      }, {
        "id": "3212",
        "pid": "261",
        "path": ",1,7,261,3212,",
        "level": "4",
        "name": "莱芜",
        "name_en": "Laiwu",
        "name_pinyin": "laiwu",
        "code": "12",
        "childrens": []
      }, {
        "id": "3213",
        "pid": "261",
        "path": ",1,7,261,3213,",
        "level": "4",
        "name": "临沂",
        "name_en": "Linyi",
        "name_pinyin": "linyi",
        "code": "13",
        "childrens": []
      }, {
        "id": "3214",
        "pid": "261",
        "path": ",1,7,261,3214,",
        "level": "4",
        "name": "德州",
        "name_en": "Dezhou",
        "name_pinyin": "dezhou",
        "code": "14",
        "childrens": []
      }, {
        "id": "3215",
        "pid": "261",
        "path": ",1,7,261,3215,",
        "level": "4",
        "name": "聊城",
        "name_en": "Liaocheng",
        "name_pinyin": "liaocheng",
        "code": "15",
        "childrens": []
      }, {
        "id": "3216",
        "pid": "261",
        "path": ",1,7,261,3216,",
        "level": "4",
        "name": "滨州",
        "name_en": "Binzhou",
        "name_pinyin": "binzhou",
        "code": "16",
        "childrens": []
      }, {
        "id": "3217",
        "pid": "261",
        "path": ",1,7,261,3217,",
        "level": "4",
        "name": "菏泽",
        "name_en": "Heze",
        "name_pinyin": "heze",
        "code": "17",
        "childrens": []
      }]
    }, {
      "id": "262",
      "pid": "7",
      "path": ",1,7,262,",
      "level": "3",
      "name": "河南",
      "name_en": "Henan",
      "name_pinyin": "henan",
      "code": "41",
      "childrens": [{
        "id": "3218",
        "pid": "262",
        "path": ",1,7,262,3218,",
        "level": "4",
        "name": "郑州",
        "name_en": "Zhengzhou",
        "name_pinyin": "zhengzhou",
        "code": "1",
        "childrens": []
      }, {
        "id": "3219",
        "pid": "262",
        "path": ",1,7,262,3219,",
        "level": "4",
        "name": "开封",
        "name_en": "Kaifeng",
        "name_pinyin": "kaifeng",
        "code": "2",
        "childrens": []
      }, {
        "id": "3220",
        "pid": "262",
        "path": ",1,7,262,3220,",
        "level": "4",
        "name": "洛阳",
        "name_en": "Luoyang",
        "name_pinyin": "luoyang",
        "code": "3",
        "childrens": []
      }, {
        "id": "3221",
        "pid": "262",
        "path": ",1,7,262,3221,",
        "level": "4",
        "name": "平顶山",
        "name_en": "Pingdingshan",
        "name_pinyin": "pingdingshan",
        "code": "4",
        "childrens": []
      }, {
        "id": "3222",
        "pid": "262",
        "path": ",1,7,262,3222,",
        "level": "4",
        "name": "安阳",
        "name_en": "Anyang",
        "name_pinyin": "anyang",
        "code": "5",
        "childrens": []
      }, {
        "id": "3223",
        "pid": "262",
        "path": ",1,7,262,3223,",
        "level": "4",
        "name": "鹤壁",
        "name_en": "Hebi",
        "name_pinyin": "hebi",
        "code": "6",
        "childrens": []
      }, {
        "id": "3224",
        "pid": "262",
        "path": ",1,7,262,3224,",
        "level": "4",
        "name": "新乡",
        "name_en": "Xinxiang",
        "name_pinyin": "xinxiang",
        "code": "7",
        "childrens": []
      }, {
        "id": "3225",
        "pid": "262",
        "path": ",1,7,262,3225,",
        "level": "4",
        "name": "焦作",
        "name_en": "Jiaozuo",
        "name_pinyin": "jiaozuo",
        "code": "8",
        "childrens": []
      }, {
        "id": "3226",
        "pid": "262",
        "path": ",1,7,262,3226,",
        "level": "4",
        "name": "濮阳",
        "name_en": "Puyang",
        "name_pinyin": "yang",
        "code": "9",
        "childrens": []
      }, {
        "id": "3227",
        "pid": "262",
        "path": ",1,7,262,3227,",
        "level": "4",
        "name": "许昌",
        "name_en": "Xuchang",
        "name_pinyin": "xuchang",
        "code": "10",
        "childrens": []
      }, {
        "id": "3228",
        "pid": "262",
        "path": ",1,7,262,3228,",
        "level": "4",
        "name": "漯河",
        "name_en": "Luohe",
        "name_pinyin": "he",
        "code": "11",
        "childrens": []
      }, {
        "id": "3229",
        "pid": "262",
        "path": ",1,7,262,3229,",
        "level": "4",
        "name": "三门峡",
        "name_en": "Sanmenxia",
        "name_pinyin": "sanmenxia",
        "code": "12",
        "childrens": []
      }, {
        "id": "3230",
        "pid": "262",
        "path": ",1,7,262,3230,",
        "level": "4",
        "name": "南阳",
        "name_en": "Nanyang",
        "name_pinyin": "nanyang",
        "code": "13",
        "childrens": []
      }, {
        "id": "3231",
        "pid": "262",
        "path": ",1,7,262,3231,",
        "level": "4",
        "name": "商丘",
        "name_en": "Shangqiu",
        "name_pinyin": "shangqiu",
        "code": "14",
        "childrens": []
      }, {
        "id": "3232",
        "pid": "262",
        "path": ",1,7,262,3232,",
        "level": "4",
        "name": "信阳",
        "name_en": "Xinyang",
        "name_pinyin": "xinyang",
        "code": "15",
        "childrens": []
      }, {
        "id": "3233",
        "pid": "262",
        "path": ",1,7,262,3233,",
        "level": "4",
        "name": "周口",
        "name_en": "Zhoukou",
        "name_pinyin": "zhoukou",
        "code": "16",
        "childrens": []
      }, {
        "id": "3234",
        "pid": "262",
        "path": ",1,7,262,3234,",
        "level": "4",
        "name": "驻马店",
        "name_en": "Zhumadian",
        "name_pinyin": "zhumadian",
        "code": "17",
        "childrens": []
      }, {
        "id": "3235",
        "pid": "262",
        "path": ",1,7,262,3235,",
        "level": "4",
        "name": "济源",
        "name_en": "Jiyuan",
        "name_pinyin": "jiyuan",
        "code": "18",
        "childrens": []
      }]
    }, {
      "id": "263",
      "pid": "7",
      "path": ",1,7,263,",
      "level": "3",
      "name": "湖北",
      "name_en": "Hubei",
      "name_pinyin": "hubei",
      "code": "42",
      "childrens": [{
        "id": "3236",
        "pid": "263",
        "path": ",1,7,263,3236,",
        "level": "4",
        "name": "武汉",
        "name_en": "Wuhan",
        "name_pinyin": "wuhan",
        "code": "1",
        "childrens": []
      }, {
        "id": "3237",
        "pid": "263",
        "path": ",1,7,263,3237,",
        "level": "4",
        "name": "黄石",
        "name_en": "Huangshi",
        "name_pinyin": "huangshi",
        "code": "2",
        "childrens": []
      }, {
        "id": "3238",
        "pid": "263",
        "path": ",1,7,263,3238,",
        "level": "4",
        "name": "十堰",
        "name_en": "Shiyan",
        "name_pinyin": "shiyan",
        "code": "3",
        "childrens": []
      }, {
        "id": "3239",
        "pid": "263",
        "path": ",1,7,263,3239,",
        "level": "4",
        "name": "宜昌",
        "name_en": "Yichang",
        "name_pinyin": "yichang",
        "code": "5",
        "childrens": []
      }, {
        "id": "3240",
        "pid": "263",
        "path": ",1,7,263,3240,",
        "level": "4",
        "name": "襄阳",
        "name_en": "Xiangyang",
        "name_pinyin": "xiangyang",
        "code": "6",
        "childrens": []
      }, {
        "id": "3241",
        "pid": "263",
        "path": ",1,7,263,3241,",
        "level": "4",
        "name": "鄂州",
        "name_en": "Ezhou",
        "name_pinyin": "ezhou",
        "code": "7",
        "childrens": []
      }, {
        "id": "3242",
        "pid": "263",
        "path": ",1,7,263,3242,",
        "level": "4",
        "name": "荆门",
        "name_en": "Jingmen",
        "name_pinyin": "jingmen",
        "code": "8",
        "childrens": []
      }, {
        "id": "3243",
        "pid": "263",
        "path": ",1,7,263,3243,",
        "level": "4",
        "name": "孝感",
        "name_en": "Xiaogan",
        "name_pinyin": "xiaogan",
        "code": "9",
        "childrens": []
      }, {
        "id": "3244",
        "pid": "263",
        "path": ",1,7,263,3244,",
        "level": "4",
        "name": "荆州",
        "name_en": "Jingzhou",
        "name_pinyin": "jingzhou",
        "code": "10",
        "childrens": []
      }, {
        "id": "3245",
        "pid": "263",
        "path": ",1,7,263,3245,",
        "level": "4",
        "name": "黄冈",
        "name_en": "Huanggang",
        "name_pinyin": "huanggang",
        "code": "11",
        "childrens": []
      }, {
        "id": "3246",
        "pid": "263",
        "path": ",1,7,263,3246,",
        "level": "4",
        "name": "咸宁",
        "name_en": "Xianning",
        "name_pinyin": "xianning",
        "code": "12",
        "childrens": []
      }, {
        "id": "3247",
        "pid": "263",
        "path": ",1,7,263,3247,",
        "level": "4",
        "name": "随州",
        "name_en": "Suizhou",
        "name_pinyin": "suizhou",
        "code": "13",
        "childrens": []
      }, {
        "id": "3248",
        "pid": "263",
        "path": ",1,7,263,3248,",
        "level": "4",
        "name": "恩施",
        "name_en": "Enshi Tujia-Miao Autonomous Prefecture",
        "name_pinyin": "enshi",
        "code": "28",
        "childrens": []
      }, {
        "id": "3249",
        "pid": "263",
        "path": ",1,7,263,3249,",
        "level": "4",
        "name": "仙桃",
        "name_en": "Xiantao",
        "name_pinyin": "xiantao",
        "code": "94",
        "childrens": []
      }, {
        "id": "3250",
        "pid": "263",
        "path": ",1,7,263,3250,",
        "level": "4",
        "name": "潜江",
        "name_en": "Qianjiang",
        "name_pinyin": "qianjiang",
        "code": "95",
        "childrens": []
      }, {
        "id": "3251",
        "pid": "263",
        "path": ",1,7,263,3251,",
        "level": "4",
        "name": "天门",
        "name_en": "Tianmen",
        "name_pinyin": "tianmen",
        "code": "96",
        "childrens": []
      }, {
        "id": "3252",
        "pid": "263",
        "path": ",1,7,263,3252,",
        "level": "4",
        "name": "神农架",
        "name_en": "Shennongjia",
        "name_pinyin": "shennongjia",
        "code": "A21",
        "childrens": []
      }]
    }, {
      "id": "264",
      "pid": "7",
      "path": ",1,7,264,",
      "level": "3",
      "name": "湖南",
      "name_en": "Hunan",
      "name_pinyin": "hunan",
      "code": "43",
      "childrens": [{
        "id": "3253",
        "pid": "264",
        "path": ",1,7,264,3253,",
        "level": "4",
        "name": "长沙",
        "name_en": "Changsha",
        "name_pinyin": "changsha",
        "code": "1",
        "childrens": []
      }, {
        "id": "3254",
        "pid": "264",
        "path": ",1,7,264,3254,",
        "level": "4",
        "name": "株洲",
        "name_en": "Zhuzhou",
        "name_pinyin": "zhuzhou",
        "code": "2",
        "childrens": []
      }, {
        "id": "3255",
        "pid": "264",
        "path": ",1,7,264,3255,",
        "level": "4",
        "name": "湘潭",
        "name_en": "Xiangtan",
        "name_pinyin": "xiangtan",
        "code": "3",
        "childrens": []
      }, {
        "id": "3256",
        "pid": "264",
        "path": ",1,7,264,3256,",
        "level": "4",
        "name": "衡阳",
        "name_en": "Hengyang",
        "name_pinyin": "hengyang",
        "code": "4",
        "childrens": []
      }, {
        "id": "3257",
        "pid": "264",
        "path": ",1,7,264,3257,",
        "level": "4",
        "name": "邵阳",
        "name_en": "Shaoyang",
        "name_pinyin": "shaoyang",
        "code": "5",
        "childrens": []
      }, {
        "id": "3258",
        "pid": "264",
        "path": ",1,7,264,3258,",
        "level": "4",
        "name": "岳阳",
        "name_en": "Yueyang",
        "name_pinyin": "yueyang",
        "code": "6",
        "childrens": []
      }, {
        "id": "3259",
        "pid": "264",
        "path": ",1,7,264,3259,",
        "level": "4",
        "name": "常德",
        "name_en": "Changde",
        "name_pinyin": "changde",
        "code": "7",
        "childrens": []
      }, {
        "id": "3260",
        "pid": "264",
        "path": ",1,7,264,3260,",
        "level": "4",
        "name": "张家界",
        "name_en": "Zhangjiajie",
        "name_pinyin": "zhangjiajie",
        "code": "8",
        "childrens": []
      }, {
        "id": "3261",
        "pid": "264",
        "path": ",1,7,264,3261,",
        "level": "4",
        "name": "益阳",
        "name_en": "Yiyang",
        "name_pinyin": "yiyang",
        "code": "9",
        "childrens": []
      }, {
        "id": "3262",
        "pid": "264",
        "path": ",1,7,264,3262,",
        "level": "4",
        "name": "郴州",
        "name_en": "Chenzhou",
        "name_pinyin": "chenzhou",
        "code": "10",
        "childrens": []
      }, {
        "id": "3263",
        "pid": "264",
        "path": ",1,7,264,3263,",
        "level": "4",
        "name": "永州",
        "name_en": "Yongzhou",
        "name_pinyin": "yongzhou",
        "code": "11",
        "childrens": []
      }, {
        "id": "3264",
        "pid": "264",
        "path": ",1,7,264,3264,",
        "level": "4",
        "name": "怀化",
        "name_en": "Huaihua",
        "name_pinyin": "huaihua",
        "code": "12",
        "childrens": []
      }, {
        "id": "3265",
        "pid": "264",
        "path": ",1,7,264,3265,",
        "level": "4",
        "name": "娄底",
        "name_en": "Loudi",
        "name_pinyin": "loudi",
        "code": "13",
        "childrens": []
      }, {
        "id": "3266",
        "pid": "264",
        "path": ",1,7,264,3266,",
        "level": "4",
        "name": "湘西",
        "name_en": "Xiangxi Tujia-Miao Autonomous Prefecture",
        "name_pinyin": "xiangxi",
        "code": "31",
        "childrens": []
      }]
    }, {
      "id": "265",
      "pid": "7",
      "path": ",1,7,265,",
      "level": "3",
      "name": "广东",
      "name_en": "Guangdong",
      "name_pinyin": "guangdong",
      "code": "44",
      "childrens": [{
        "id": "3267",
        "pid": "265",
        "path": ",1,7,265,3267,",
        "level": "4",
        "name": "广州",
        "name_en": "Guangzhou",
        "name_pinyin": "guangzhou",
        "code": "1",
        "childrens": []
      }, {
        "id": "3268",
        "pid": "265",
        "path": ",1,7,265,3268,",
        "level": "4",
        "name": "韶关",
        "name_en": "Shaoguan",
        "name_pinyin": "shaoguan",
        "code": "2",
        "childrens": []
      }, {
        "id": "3269",
        "pid": "265",
        "path": ",1,7,265,3269,",
        "level": "4",
        "name": "深圳",
        "name_en": "Shenzhen",
        "name_pinyin": "shen",
        "code": "3",
        "childrens": []
      }, {
        "id": "3270",
        "pid": "265",
        "path": ",1,7,265,3270,",
        "level": "4",
        "name": "珠海",
        "name_en": "Zhuhai",
        "name_pinyin": "zhuhai",
        "code": "4",
        "childrens": []
      }, {
        "id": "3271",
        "pid": "265",
        "path": ",1,7,265,3271,",
        "level": "4",
        "name": "汕头",
        "name_en": "Shantou",
        "name_pinyin": "shantou",
        "code": "5",
        "childrens": []
      }, {
        "id": "3272",
        "pid": "265",
        "path": ",1,7,265,3272,",
        "level": "4",
        "name": "佛山",
        "name_en": "Foshan",
        "name_pinyin": "foshan",
        "code": "6",
        "childrens": []
      }, {
        "id": "3273",
        "pid": "265",
        "path": ",1,7,265,3273,",
        "level": "4",
        "name": "江门",
        "name_en": "Jiangmen",
        "name_pinyin": "jiangmen",
        "code": "7",
        "childrens": []
      }, {
        "id": "3274",
        "pid": "265",
        "path": ",1,7,265,3274,",
        "level": "4",
        "name": "湛江",
        "name_en": "Zhanjiang",
        "name_pinyin": "zhanjiang",
        "code": "8",
        "childrens": []
      }, {
        "id": "3275",
        "pid": "265",
        "path": ",1,7,265,3275,",
        "level": "4",
        "name": "茂名",
        "name_en": "Maoming",
        "name_pinyin": "maoming",
        "code": "9",
        "childrens": []
      }, {
        "id": "3276",
        "pid": "265",
        "path": ",1,7,265,3276,",
        "level": "4",
        "name": "肇庆",
        "name_en": "Zhaoqing",
        "name_pinyin": "zhaoqing",
        "code": "12",
        "childrens": []
      }, {
        "id": "3277",
        "pid": "265",
        "path": ",1,7,265,3277,",
        "level": "4",
        "name": "惠州",
        "name_en": "Huizhou",
        "name_pinyin": "huizhou",
        "code": "13",
        "childrens": []
      }, {
        "id": "3278",
        "pid": "265",
        "path": ",1,7,265,3278,",
        "level": "4",
        "name": "梅州",
        "name_en": "Meizhou",
        "name_pinyin": "meizhou",
        "code": "14",
        "childrens": []
      }, {
        "id": "3279",
        "pid": "265",
        "path": ",1,7,265,3279,",
        "level": "4",
        "name": "汕尾",
        "name_en": "Shanwei",
        "name_pinyin": "shanwei",
        "code": "15",
        "childrens": []
      }, {
        "id": "3280",
        "pid": "265",
        "path": ",1,7,265,3280,",
        "level": "4",
        "name": "河源",
        "name_en": "Heyuan",
        "name_pinyin": "heyuan",
        "code": "16",
        "childrens": []
      }, {
        "id": "3281",
        "pid": "265",
        "path": ",1,7,265,3281,",
        "level": "4",
        "name": "阳江",
        "name_en": "Yangjiang",
        "name_pinyin": "yangjiang",
        "code": "17",
        "childrens": []
      }, {
        "id": "3282",
        "pid": "265",
        "path": ",1,7,265,3282,",
        "level": "4",
        "name": "清远",
        "name_en": "Qingyuan",
        "name_pinyin": "qingyuan",
        "code": "18",
        "childrens": []
      }, {
        "id": "3283",
        "pid": "265",
        "path": ",1,7,265,3283,",
        "level": "4",
        "name": "东莞",
        "name_en": "Dongguan",
        "name_pinyin": "dong",
        "code": "19",
        "childrens": []
      }, {
        "id": "3284",
        "pid": "265",
        "path": ",1,7,265,3284,",
        "level": "4",
        "name": "中山",
        "name_en": "Zhongshan",
        "name_pinyin": "zhongshan",
        "code": "20",
        "childrens": []
      }, {
        "id": "3285",
        "pid": "265",
        "path": ",1,7,265,3285,",
        "level": "4",
        "name": "潮州",
        "name_en": "Chaozhou",
        "name_pinyin": "chaozhou",
        "code": "51",
        "childrens": []
      }, {
        "id": "3286",
        "pid": "265",
        "path": ",1,7,265,3286,",
        "level": "4",
        "name": "揭阳",
        "name_en": "Jieyang",
        "name_pinyin": "jieyang",
        "code": "52",
        "childrens": []
      }, {
        "id": "3287",
        "pid": "265",
        "path": ",1,7,265,3287,",
        "level": "4",
        "name": "云浮",
        "name_en": "Yunfu",
        "name_pinyin": "yunfu",
        "code": "53",
        "childrens": []
      }]
    }, {
      "id": "266",
      "pid": "7",
      "path": ",1,7,266,",
      "level": "3",
      "name": "广西",
      "name_en": "Guangxi",
      "name_pinyin": "guangxi",
      "code": "45",
      "childrens": [{
        "id": "3288",
        "pid": "266",
        "path": ",1,7,266,3288,",
        "level": "4",
        "name": "南宁",
        "name_en": "Nanning",
        "name_pinyin": "nanning",
        "code": "1",
        "childrens": []
      }, {
        "id": "3289",
        "pid": "266",
        "path": ",1,7,266,3289,",
        "level": "4",
        "name": "柳州",
        "name_en": "Liuzhou",
        "name_pinyin": "liuzhou",
        "code": "2",
        "childrens": []
      }, {
        "id": "3290",
        "pid": "266",
        "path": ",1,7,266,3290,",
        "level": "4",
        "name": "桂林",
        "name_en": "Guilin",
        "name_pinyin": "guilin",
        "code": "3",
        "childrens": []
      }, {
        "id": "3291",
        "pid": "266",
        "path": ",1,7,266,3291,",
        "level": "4",
        "name": "梧州",
        "name_en": "Wuzhou",
        "name_pinyin": "wuzhou",
        "code": "4",
        "childrens": []
      }, {
        "id": "3292",
        "pid": "266",
        "path": ",1,7,266,3292,",
        "level": "4",
        "name": "北海",
        "name_en": "Beihai",
        "name_pinyin": "beihai",
        "code": "5",
        "childrens": []
      }, {
        "id": "3293",
        "pid": "266",
        "path": ",1,7,266,3293,",
        "level": "4",
        "name": "防城港",
        "name_en": "Fangchenggang",
        "name_pinyin": "fangchenggang",
        "code": "6",
        "childrens": []
      }, {
        "id": "3294",
        "pid": "266",
        "path": ",1,7,266,3294,",
        "level": "4",
        "name": "钦州",
        "name_en": "Qinzhou",
        "name_pinyin": "qinzhou",
        "code": "7",
        "childrens": []
      }, {
        "id": "3295",
        "pid": "266",
        "path": ",1,7,266,3295,",
        "level": "4",
        "name": "贵港",
        "name_en": "Guigang",
        "name_pinyin": "guigang",
        "code": "8",
        "childrens": []
      }, {
        "id": "3296",
        "pid": "266",
        "path": ",1,7,266,3296,",
        "level": "4",
        "name": "玉林",
        "name_en": "Yulin",
        "name_pinyin": "yulin",
        "code": "9",
        "childrens": []
      }, {
        "id": "3297",
        "pid": "266",
        "path": ",1,7,266,3297,",
        "level": "4",
        "name": "百色",
        "name_en": "Baise",
        "name_pinyin": "baise",
        "code": "10",
        "childrens": []
      }, {
        "id": "3298",
        "pid": "266",
        "path": ",1,7,266,3298,",
        "level": "4",
        "name": "贺州",
        "name_en": "Hezhou",
        "name_pinyin": "hezhou",
        "code": "11",
        "childrens": []
      }, {
        "id": "3299",
        "pid": "266",
        "path": ",1,7,266,3299,",
        "level": "4",
        "name": "河池",
        "name_en": "Hechi",
        "name_pinyin": "hechi",
        "code": "12",
        "childrens": []
      }, {
        "id": "3300",
        "pid": "266",
        "path": ",1,7,266,3300,",
        "level": "4",
        "name": "来宾",
        "name_en": "Laibin",
        "name_pinyin": "laibin",
        "code": "13",
        "childrens": []
      }, {
        "id": "3301",
        "pid": "266",
        "path": ",1,7,266,3301,",
        "level": "4",
        "name": "崇左",
        "name_en": "Chongzuo",
        "name_pinyin": "chongzuo",
        "code": "14",
        "childrens": []
      }]
    }, {
      "id": "267",
      "pid": "7",
      "path": ",1,7,267,",
      "level": "3",
      "name": "海南",
      "name_en": "Hainan",
      "name_pinyin": "hainan",
      "code": "46",
      "childrens": [{
        "id": "3302",
        "pid": "267",
        "path": ",1,7,267,3302,",
        "level": "4",
        "name": "海口",
        "name_en": "Haikou",
        "name_pinyin": "haikou",
        "code": "1",
        "childrens": []
      }, {
        "id": "3303",
        "pid": "267",
        "path": ",1,7,267,3303,",
        "level": "4",
        "name": "三亚",
        "name_en": "Sanya",
        "name_pinyin": "sanya",
        "code": "2",
        "childrens": []
      }, {
        "id": "3304",
        "pid": "267",
        "path": ",1,7,267,3304,",
        "level": "4",
        "name": "三沙",
        "name_en": "Shansha",
        "name_pinyin": "sansha",
        "code": "3",
        "childrens": []
      }, {
        "id": "3305",
        "pid": "267",
        "path": ",1,7,267,3305,",
        "level": "4",
        "name": "五指山",
        "name_en": "Wuzhishan",
        "name_pinyin": "wuzhishan",
        "code": "91",
        "childrens": []
      }, {
        "id": "3306",
        "pid": "267",
        "path": ",1,7,267,3306,",
        "level": "4",
        "name": "琼海",
        "name_en": "Qionghai",
        "name_pinyin": "qionghai",
        "code": "92",
        "childrens": []
      }, {
        "id": "3307",
        "pid": "267",
        "path": ",1,7,267,3307,",
        "level": "4",
        "name": "儋州",
        "name_en": "Danzhou",
        "name_pinyin": "zhou",
        "code": "93",
        "childrens": []
      }, {
        "id": "3308",
        "pid": "267",
        "path": ",1,7,267,3308,",
        "level": "4",
        "name": "文昌",
        "name_en": "Wenchang",
        "name_pinyin": "wenchang",
        "code": "95",
        "childrens": []
      }, {
        "id": "3309",
        "pid": "267",
        "path": ",1,7,267,3309,",
        "level": "4",
        "name": "万宁",
        "name_en": "Wanning",
        "name_pinyin": "wanning",
        "code": "96",
        "childrens": []
      }, {
        "id": "3310",
        "pid": "267",
        "path": ",1,7,267,3310,",
        "level": "4",
        "name": "东方",
        "name_en": "Dongfang",
        "name_pinyin": "dongfang",
        "code": "97",
        "childrens": []
      }, {
        "id": "3311",
        "pid": "267",
        "path": ",1,7,267,3311,",
        "level": "4",
        "name": "定安",
        "name_en": "Ding'an",
        "name_pinyin": "dingan",
        "code": "A25",
        "childrens": []
      }, {
        "id": "3312",
        "pid": "267",
        "path": ",1,7,267,3312,",
        "level": "4",
        "name": "屯昌",
        "name_en": "Tunchang",
        "name_pinyin": "tunchang",
        "code": "A26",
        "childrens": []
      }, {
        "id": "3313",
        "pid": "267",
        "path": ",1,7,267,3313,",
        "level": "4",
        "name": "澄迈",
        "name_en": "Cengmai",
        "name_pinyin": "chengmai",
        "code": "A27",
        "childrens": []
      }, {
        "id": "3314",
        "pid": "267",
        "path": ",1,7,267,3314,",
        "level": "4",
        "name": "临高",
        "name_en": "Lingao",
        "name_pinyin": "lingao",
        "code": "A28",
        "childrens": []
      }, {
        "id": "3315",
        "pid": "267",
        "path": ",1,7,267,3315,",
        "level": "4",
        "name": "白沙",
        "name_en": "Baisha Li Autonomous County",
        "name_pinyin": "baisha",
        "code": "A30",
        "childrens": []
      }, {
        "id": "3316",
        "pid": "267",
        "path": ",1,7,267,3316,",
        "level": "4",
        "name": "昌江",
        "name_en": "Jiang Li Autonomous County",
        "name_pinyin": "changjiang",
        "code": "A31",
        "childrens": []
      }, {
        "id": "3317",
        "pid": "267",
        "path": ",1,7,267,3317,",
        "level": "4",
        "name": "乐东",
        "name_en": "Ledong Li Autonomous County",
        "name_pinyin": "ledong",
        "code": "A33",
        "childrens": []
      }, {
        "id": "3318",
        "pid": "267",
        "path": ",1,7,267,3318,",
        "level": "4",
        "name": "陵水",
        "name_en": "Lingshui Li Autonomous County",
        "name_pinyin": "lingshui",
        "code": "A34",
        "childrens": []
      }, {
        "id": "3319",
        "pid": "267",
        "path": ",1,7,267,3319,",
        "level": "4",
        "name": "保亭",
        "name_en": "Baoting Li-Miao Autonomous County",
        "name_pinyin": "baoting",
        "code": "A35",
        "childrens": []
      }, {
        "id": "3320",
        "pid": "267",
        "path": ",1,7,267,3320,",
        "level": "4",
        "name": "琼中",
        "name_en": "Qiongzhong Li-Miao Autonomous County",
        "name_pinyin": "qiongzhong",
        "code": "A36",
        "childrens": []
      }]
    }, {
      "id": "268",
      "pid": "7",
      "path": ",1,7,268,",
      "level": "3",
      "name": "重庆",
      "name_en": "Chongqing",
      "name_pinyin": "zhongqing",
      "code": "50",
      "childrens": [{
        "id": "3321",
        "pid": "268",
        "path": ",1,7,268,3321,",
        "level": "4",
        "name": "万州",
        "name_en": "Wanzhou",
        "name_pinyin": "wanzhou",
        "code": "1",
        "childrens": []
      }, {
        "id": "3322",
        "pid": "268",
        "path": ",1,7,268,3322,",
        "level": "4",
        "name": "涪陵",
        "name_en": "Fuling",
        "name_pinyin": "fuling",
        "code": "2",
        "childrens": []
      }, {
        "id": "3323",
        "pid": "268",
        "path": ",1,7,268,3323,",
        "level": "4",
        "name": "渝中",
        "name_en": "Yuzhong",
        "name_pinyin": "yuzhong",
        "code": "3",
        "childrens": []
      }, {
        "id": "3324",
        "pid": "268",
        "path": ",1,7,268,3324,",
        "level": "4",
        "name": "大渡口",
        "name_en": "Dadukou",
        "name_pinyin": "dadukou",
        "code": "4",
        "childrens": []
      }, {
        "id": "3325",
        "pid": "268",
        "path": ",1,7,268,3325,",
        "level": "4",
        "name": "江北",
        "name_en": "Jiangbei",
        "name_pinyin": "jiangbei",
        "code": "5",
        "childrens": []
      }, {
        "id": "3326",
        "pid": "268",
        "path": ",1,7,268,3326,",
        "level": "4",
        "name": "沙坪坝",
        "name_en": "Shapingba",
        "name_pinyin": "shapingba",
        "code": "6",
        "childrens": []
      }, {
        "id": "3327",
        "pid": "268",
        "path": ",1,7,268,3327,",
        "level": "4",
        "name": "九龙坡",
        "name_en": "Jiulongpo",
        "name_pinyin": "jiulongpo",
        "code": "7",
        "childrens": []
      }, {
        "id": "3328",
        "pid": "268",
        "path": ",1,7,268,3328,",
        "level": "4",
        "name": "南岸",
        "name_en": "Nan'an",
        "name_pinyin": "nanan",
        "code": "8",
        "childrens": []
      }, {
        "id": "3329",
        "pid": "268",
        "path": ",1,7,268,3329,",
        "level": "4",
        "name": "北碚",
        "name_en": "Beibei",
        "name_pinyin": "bei",
        "code": "9",
        "childrens": []
      }, {
        "id": "3330",
        "pid": "268",
        "path": ",1,7,268,3330,",
        "level": "4",
        "name": "两江新区",
        "name_en": "Kaixian",
        "name_pinyin": "liangjiangxinqu",
        "code": "85",
        "childrens": []
      }, {
        "id": "3331",
        "pid": "268",
        "path": ",1,7,268,3331,",
        "level": "4",
        "name": "万盛",
        "name_en": "Wansheng",
        "name_pinyin": "wansheng",
        "code": "10",
        "childrens": []
      }, {
        "id": "3332",
        "pid": "268",
        "path": ",1,7,268,3332,",
        "level": "4",
        "name": "双桥",
        "name_en": "Shuangqiao",
        "name_pinyin": "shuangqiao",
        "code": "11",
        "childrens": []
      }, {
        "id": "3333",
        "pid": "268",
        "path": ",1,7,268,3333,",
        "level": "4",
        "name": "渝北",
        "name_en": "Yubei",
        "name_pinyin": "yubei",
        "code": "12",
        "childrens": []
      }, {
        "id": "3334",
        "pid": "268",
        "path": ",1,7,268,3334,",
        "level": "4",
        "name": "巴南",
        "name_en": "Ba'nan",
        "name_pinyin": "banan",
        "code": "13",
        "childrens": []
      }, {
        "id": "3335",
        "pid": "268",
        "path": ",1,7,268,3335,",
        "level": "4",
        "name": "长寿",
        "name_en": "Changshou",
        "name_pinyin": "changshou",
        "code": "21",
        "childrens": []
      }, {
        "id": "3336",
        "pid": "268",
        "path": ",1,7,268,3336,",
        "level": "4",
        "name": "綦江",
        "name_en": "Qijiang",
        "name_pinyin": "jiang",
        "code": "22",
        "childrens": []
      }, {
        "id": "3337",
        "pid": "268",
        "path": ",1,7,268,3337,",
        "level": "4",
        "name": "潼南",
        "name_en": "Tongnan",
        "name_pinyin": "nan",
        "code": "23",
        "childrens": []
      }, {
        "id": "3338",
        "pid": "268",
        "path": ",1,7,268,3338,",
        "level": "4",
        "name": "铜梁",
        "name_en": "Tongliang",
        "name_pinyin": "tongliang",
        "code": "24",
        "childrens": []
      }, {
        "id": "3339",
        "pid": "268",
        "path": ",1,7,268,3339,",
        "level": "4",
        "name": "大足",
        "name_en": "Dazu",
        "name_pinyin": "dazu",
        "code": "25",
        "childrens": []
      }, {
        "id": "3340",
        "pid": "268",
        "path": ",1,7,268,3340,",
        "level": "4",
        "name": "荣昌",
        "name_en": "Rongchang",
        "name_pinyin": "rongchang",
        "code": "26",
        "childrens": []
      }, {
        "id": "3341",
        "pid": "268",
        "path": ",1,7,268,3341,",
        "level": "4",
        "name": "璧山",
        "name_en": "Bishan",
        "name_pinyin": "shan",
        "code": "27",
        "childrens": []
      }, {
        "id": "3342",
        "pid": "268",
        "path": ",1,7,268,3342,",
        "level": "4",
        "name": "梁平",
        "name_en": "Liangping",
        "name_pinyin": "liangping",
        "code": "28",
        "childrens": []
      }, {
        "id": "3343",
        "pid": "268",
        "path": ",1,7,268,3343,",
        "level": "4",
        "name": "城口",
        "name_en": "Chengkou",
        "name_pinyin": "chengkou",
        "code": "29",
        "childrens": []
      }, {
        "id": "3344",
        "pid": "268",
        "path": ",1,7,268,3344,",
        "level": "4",
        "name": "丰都",
        "name_en": "Fengdu",
        "name_pinyin": "fengdu",
        "code": "30",
        "childrens": []
      }, {
        "id": "3345",
        "pid": "268",
        "path": ",1,7,268,3345,",
        "level": "4",
        "name": "垫江",
        "name_en": "Dianjiang",
        "name_pinyin": "dianjiang",
        "code": "31",
        "childrens": []
      }, {
        "id": "3346",
        "pid": "268",
        "path": ",1,7,268,3346,",
        "level": "4",
        "name": "武隆",
        "name_en": "Wulong",
        "name_pinyin": "wulong",
        "code": "32",
        "childrens": []
      }, {
        "id": "3347",
        "pid": "268",
        "path": ",1,7,268,3347,",
        "level": "4",
        "name": "忠县",
        "name_en": "Zhongxian",
        "name_pinyin": "zhongxian",
        "code": "33",
        "childrens": []
      }, {
        "id": "3348",
        "pid": "268",
        "path": ",1,7,268,3348,",
        "level": "4",
        "name": "开县",
        "name_en": "Kaixian",
        "name_pinyin": "kaixian",
        "code": "34",
        "childrens": []
      }, {
        "id": "3349",
        "pid": "268",
        "path": ",1,7,268,3349,",
        "level": "4",
        "name": "云阳",
        "name_en": "Yunyang",
        "name_pinyin": "yunyang",
        "code": "35",
        "childrens": []
      }, {
        "id": "3350",
        "pid": "268",
        "path": ",1,7,268,3350,",
        "level": "4",
        "name": "奉节",
        "name_en": "Fengjie",
        "name_pinyin": "fengjie",
        "code": "36",
        "childrens": []
      }, {
        "id": "3351",
        "pid": "268",
        "path": ",1,7,268,3351,",
        "level": "4",
        "name": "巫山",
        "name_en": "Wushan",
        "name_pinyin": "wushan",
        "code": "37",
        "childrens": []
      }, {
        "id": "3352",
        "pid": "268",
        "path": ",1,7,268,3352,",
        "level": "4",
        "name": "巫溪",
        "name_en": "Wuxi",
        "name_pinyin": "wuxi",
        "code": "38",
        "childrens": []
      }, {
        "id": "3353",
        "pid": "268",
        "path": ",1,7,268,3353,",
        "level": "4",
        "name": "黔江",
        "name_en": "Qianjiang",
        "name_pinyin": "qianjiang",
        "code": "39",
        "childrens": []
      }, {
        "id": "3354",
        "pid": "268",
        "path": ",1,7,268,3354,",
        "level": "4",
        "name": "石柱",
        "name_en": "Shizhu Tujia Autonomous Country",
        "name_pinyin": "shizhu",
        "code": "40",
        "childrens": []
      }, {
        "id": "3355",
        "pid": "268",
        "path": ",1,7,268,3355,",
        "level": "4",
        "name": "秀山",
        "name_en": "Xiushan Tujia-Miao Autonomous Country",
        "name_pinyin": "xiushan",
        "code": "41",
        "childrens": []
      }, {
        "id": "3356",
        "pid": "268",
        "path": ",1,7,268,3356,",
        "level": "4",
        "name": "酉阳",
        "name_en": "Youyang Tujia-Miao Autonomous Country",
        "name_pinyin": "youyang",
        "code": "42",
        "childrens": []
      }, {
        "id": "3357",
        "pid": "268",
        "path": ",1,7,268,3357,",
        "level": "4",
        "name": "彭水",
        "name_en": "Pengshui Miao-Tujia Autonomous Country",
        "name_pinyin": "pengshui",
        "code": "43",
        "childrens": []
      }, {
        "id": "3358",
        "pid": "268",
        "path": ",1,7,268,3358,",
        "level": "4",
        "name": "江津",
        "name_en": "Jiangjin",
        "name_pinyin": "jiangjin",
        "code": "81",
        "childrens": []
      }, {
        "id": "3359",
        "pid": "268",
        "path": ",1,7,268,3359,",
        "level": "4",
        "name": "合川",
        "name_en": "Hechuan",
        "name_pinyin": "hechuan",
        "code": "82",
        "childrens": []
      }, {
        "id": "3360",
        "pid": "268",
        "path": ",1,7,268,3360,",
        "level": "4",
        "name": "永川",
        "name_en": "Yongchuan",
        "name_pinyin": "yongchuan",
        "code": "83",
        "childrens": []
      }, {
        "id": "3361",
        "pid": "268",
        "path": ",1,7,268,3361,",
        "level": "4",
        "name": "南川",
        "name_en": "Liangjiangxinqu",
        "name_pinyin": "nanchuan",
        "code": "84",
        "childrens": []
      }]
    }, {
      "id": "269",
      "pid": "7",
      "path": ",1,7,269,",
      "level": "3",
      "name": "四川",
      "name_en": "Sichuan",
      "name_pinyin": "sichuan",
      "code": "51",
      "childrens": [{
        "id": "3362",
        "pid": "269",
        "path": ",1,7,269,3362,",
        "level": "4",
        "name": "成都",
        "name_en": "Chengdu",
        "name_pinyin": "chengdu",
        "code": "1",
        "childrens": []
      }, {
        "id": "3363",
        "pid": "269",
        "path": ",1,7,269,3363,",
        "level": "4",
        "name": "自贡",
        "name_en": "Zigong",
        "name_pinyin": "zigong",
        "code": "3",
        "childrens": []
      }, {
        "id": "3364",
        "pid": "269",
        "path": ",1,7,269,3364,",
        "level": "4",
        "name": "攀枝花",
        "name_en": "Panzhihua",
        "name_pinyin": "panzhihua",
        "code": "4",
        "childrens": []
      }, {
        "id": "3365",
        "pid": "269",
        "path": ",1,7,269,3365,",
        "level": "4",
        "name": "泸州",
        "name_en": "Luzhou",
        "name_pinyin": "zhou",
        "code": "5",
        "childrens": []
      }, {
        "id": "3366",
        "pid": "269",
        "path": ",1,7,269,3366,",
        "level": "4",
        "name": "德阳",
        "name_en": "Deyang",
        "name_pinyin": "deyang",
        "code": "6",
        "childrens": []
      }, {
        "id": "3367",
        "pid": "269",
        "path": ",1,7,269,3367,",
        "level": "4",
        "name": "绵阳",
        "name_en": "Mianyang",
        "name_pinyin": "mianyang",
        "code": "7",
        "childrens": []
      }, {
        "id": "3368",
        "pid": "269",
        "path": ",1,7,269,3368,",
        "level": "4",
        "name": "广元",
        "name_en": "Guangyuan",
        "name_pinyin": "guangyuan",
        "code": "8",
        "childrens": []
      }, {
        "id": "3369",
        "pid": "269",
        "path": ",1,7,269,3369,",
        "level": "4",
        "name": "遂宁",
        "name_en": "Suining",
        "name_pinyin": "suining",
        "code": "9",
        "childrens": []
      }, {
        "id": "3370",
        "pid": "269",
        "path": ",1,7,269,3370,",
        "level": "4",
        "name": "内江",
        "name_en": "Neijiang",
        "name_pinyin": "neijiang",
        "code": "10",
        "childrens": []
      }, {
        "id": "3371",
        "pid": "269",
        "path": ",1,7,269,3371,",
        "level": "4",
        "name": "乐山",
        "name_en": "Leshan",
        "name_pinyin": "leshan",
        "code": "11",
        "childrens": []
      }, {
        "id": "3372",
        "pid": "269",
        "path": ",1,7,269,3372,",
        "level": "4",
        "name": "南充",
        "name_en": "Nanchong",
        "name_pinyin": "nanchong",
        "code": "13",
        "childrens": []
      }, {
        "id": "3373",
        "pid": "269",
        "path": ",1,7,269,3373,",
        "level": "4",
        "name": "眉山",
        "name_en": "Meishan",
        "name_pinyin": "meishan",
        "code": "14",
        "childrens": []
      }, {
        "id": "3374",
        "pid": "269",
        "path": ",1,7,269,3374,",
        "level": "4",
        "name": "宜宾",
        "name_en": "Yibin",
        "name_pinyin": "yibin",
        "code": "15",
        "childrens": []
      }, {
        "id": "3375",
        "pid": "269",
        "path": ",1,7,269,3375,",
        "level": "4",
        "name": "广安",
        "name_en": "Guang'an",
        "name_pinyin": "guangan",
        "code": "16",
        "childrens": []
      }, {
        "id": "3376",
        "pid": "269",
        "path": ",1,7,269,3376,",
        "level": "4",
        "name": "达州",
        "name_en": "Dazhou",
        "name_pinyin": "dazhou",
        "code": "17",
        "childrens": []
      }, {
        "id": "3377",
        "pid": "269",
        "path": ",1,7,269,3377,",
        "level": "4",
        "name": "雅安",
        "name_en": "Ya'an",
        "name_pinyin": "yaan",
        "code": "18",
        "childrens": []
      }, {
        "id": "3378",
        "pid": "269",
        "path": ",1,7,269,3378,",
        "level": "4",
        "name": "巴中",
        "name_en": "Bazhong",
        "name_pinyin": "bazhong",
        "code": "19",
        "childrens": []
      }, {
        "id": "3379",
        "pid": "269",
        "path": ",1,7,269,3379,",
        "level": "4",
        "name": "资阳",
        "name_en": "Ziyang",
        "name_pinyin": "ziyang",
        "code": "20",
        "childrens": []
      }, {
        "id": "3380",
        "pid": "269",
        "path": ",1,7,269,3380,",
        "level": "4",
        "name": "阿坝",
        "name_en": "Aba Tibetan-Qiang Autonomous Prefecture",
        "name_pinyin": "aba",
        "code": "32",
        "childrens": []
      }, {
        "id": "3381",
        "pid": "269",
        "path": ",1,7,269,3381,",
        "level": "4",
        "name": "甘孜",
        "name_en": "Garze Tibetan Autonomous Prefecture",
        "name_pinyin": "ganzi",
        "code": "33",
        "childrens": []
      }, {
        "id": "3382",
        "pid": "269",
        "path": ",1,7,269,3382,",
        "level": "4",
        "name": "凉山",
        "name_en": "Liangshan Yi Autonomous Prefecture",
        "name_pinyin": "liangshan",
        "code": "34",
        "childrens": []
      }]
    }, {
      "id": "270",
      "pid": "7",
      "path": ",1,7,270,",
      "level": "3",
      "name": "贵州",
      "name_en": "Guizhou",
      "name_pinyin": "guizhou",
      "code": "52",
      "childrens": [{
        "id": "3383",
        "pid": "270",
        "path": ",1,7,270,3383,",
        "level": "4",
        "name": "贵阳",
        "name_en": "Guiyang",
        "name_pinyin": "guiyang",
        "code": "1",
        "childrens": []
      }, {
        "id": "3384",
        "pid": "270",
        "path": ",1,7,270,3384,",
        "level": "4",
        "name": "六盘水",
        "name_en": "Liupanshui",
        "name_pinyin": "liupanshui",
        "code": "2",
        "childrens": []
      }, {
        "id": "3385",
        "pid": "270",
        "path": ",1,7,270,3385,",
        "level": "4",
        "name": "遵义",
        "name_en": "Zunyi",
        "name_pinyin": "zunyi",
        "code": "3",
        "childrens": []
      }, {
        "id": "3386",
        "pid": "270",
        "path": ",1,7,270,3386,",
        "level": "4",
        "name": "安顺",
        "name_en": "Anshun",
        "name_pinyin": "anshun",
        "code": "4",
        "childrens": []
      }, {
        "id": "3387",
        "pid": "270",
        "path": ",1,7,270,3387,",
        "level": "4",
        "name": "铜仁",
        "name_en": "Tongren",
        "name_pinyin": "tongren",
        "code": "22",
        "childrens": []
      }, {
        "id": "3388",
        "pid": "270",
        "path": ",1,7,270,3388,",
        "level": "4",
        "name": "黔西南",
        "name_en": "Qianxinan Buyi-Miao Autonomous Prefecture",
        "name_pinyin": "qianxinan",
        "code": "23",
        "childrens": []
      }, {
        "id": "3389",
        "pid": "270",
        "path": ",1,7,270,3389,",
        "level": "4",
        "name": "毕节",
        "name_en": "Bijie",
        "name_pinyin": "bijie",
        "code": "24",
        "childrens": []
      }, {
        "id": "3390",
        "pid": "270",
        "path": ",1,7,270,3390,",
        "level": "4",
        "name": "黔东南",
        "name_en": "Qiandongnan Miao-Dong Autonomous Prefecture",
        "name_pinyin": "qiandongnan",
        "code": "26",
        "childrens": []
      }, {
        "id": "3391",
        "pid": "270",
        "path": ",1,7,270,3391,",
        "level": "4",
        "name": "黔南",
        "name_en": "Qiannan Buyi Autonomous Prefecture",
        "name_pinyin": "qiannan",
        "code": "27",
        "childrens": []
      }]
    }, {
      "id": "271",
      "pid": "7",
      "path": ",1,7,271,",
      "level": "3",
      "name": "云南",
      "name_en": "Yunnan",
      "name_pinyin": "yunnan",
      "code": "53",
      "childrens": [{
        "id": "3392",
        "pid": "271",
        "path": ",1,7,271,3392,",
        "level": "4",
        "name": "昆明",
        "name_en": "Kunming",
        "name_pinyin": "kunming",
        "code": "1",
        "childrens": []
      }, {
        "id": "3393",
        "pid": "271",
        "path": ",1,7,271,3393,",
        "level": "4",
        "name": "曲靖",
        "name_en": "Qujing",
        "name_pinyin": "qujing",
        "code": "3",
        "childrens": []
      }, {
        "id": "3394",
        "pid": "271",
        "path": ",1,7,271,3394,",
        "level": "4",
        "name": "玉溪",
        "name_en": "Yuxi",
        "name_pinyin": "yuxi",
        "code": "4",
        "childrens": []
      }, {
        "id": "3395",
        "pid": "271",
        "path": ",1,7,271,3395,",
        "level": "4",
        "name": "保山",
        "name_en": "Baoshan",
        "name_pinyin": "baoshan",
        "code": "5",
        "childrens": []
      }, {
        "id": "3396",
        "pid": "271",
        "path": ",1,7,271,3396,",
        "level": "4",
        "name": "昭通",
        "name_en": "Zhaotong",
        "name_pinyin": "zhaotong",
        "code": "6",
        "childrens": []
      }, {
        "id": "3397",
        "pid": "271",
        "path": ",1,7,271,3397,",
        "level": "4",
        "name": "丽江",
        "name_en": "Lijiang",
        "name_pinyin": "lijiang",
        "code": "7",
        "childrens": []
      }, {
        "id": "3398",
        "pid": "271",
        "path": ",1,7,271,3398,",
        "level": "4",
        "name": "普洱",
        "name_en": "Pu'er",
        "name_pinyin": "puer",
        "code": "8",
        "childrens": []
      }, {
        "id": "3399",
        "pid": "271",
        "path": ",1,7,271,3399,",
        "level": "4",
        "name": "临沧",
        "name_en": "Lincang",
        "name_pinyin": "lincang",
        "code": "9",
        "childrens": []
      }, {
        "id": "3400",
        "pid": "271",
        "path": ",1,7,271,3400,",
        "level": "4",
        "name": "楚雄",
        "name_en": "Chuxiong Yi Autonomous Prefecture",
        "name_pinyin": "chuxiong",
        "code": "23",
        "childrens": []
      }, {
        "id": "3401",
        "pid": "271",
        "path": ",1,7,271,3401,",
        "level": "4",
        "name": "红河",
        "name_en": "Honghe Hani-Yi Autonomous Prefecture",
        "name_pinyin": "honghe",
        "code": "25",
        "childrens": []
      }, {
        "id": "3402",
        "pid": "271",
        "path": ",1,7,271,3402,",
        "level": "4",
        "name": "文山",
        "name_en": "Wenshan Zhuang-Miao Autonomous Prefecture",
        "name_pinyin": "wenshan",
        "code": "26",
        "childrens": []
      }, {
        "id": "3403",
        "pid": "271",
        "path": ",1,7,271,3403,",
        "level": "4",
        "name": "西双版纳",
        "name_en": "Xishuangbanna Dai Autonomous Prefecture",
        "name_pinyin": "xishuangbanna",
        "code": "28",
        "childrens": []
      }, {
        "id": "3404",
        "pid": "271",
        "path": ",1,7,271,3404,",
        "level": "4",
        "name": "大理",
        "name_en": "Dali Bai Autonomous Prefecture",
        "name_pinyin": "dali",
        "code": "29",
        "childrens": []
      }, {
        "id": "3405",
        "pid": "271",
        "path": ",1,7,271,3405,",
        "level": "4",
        "name": "德宏",
        "name_en": "Dehong Dai-Jingpo Autonomous Prefecture",
        "name_pinyin": "dehong",
        "code": "31",
        "childrens": []
      }, {
        "id": "3406",
        "pid": "271",
        "path": ",1,7,271,3406,",
        "level": "4",
        "name": "怒江",
        "name_en": "Nujiang Lisu Autonomous Prefecture",
        "name_pinyin": "nujiang",
        "code": "33",
        "childrens": []
      }, {
        "id": "3407",
        "pid": "271",
        "path": ",1,7,271,3407,",
        "level": "4",
        "name": "迪庆",
        "name_en": "Diqing Tibetan Autonomous Prefecture",
        "name_pinyin": "diqing",
        "code": "34",
        "childrens": []
      }]
    }, {
      "id": "272",
      "pid": "7",
      "path": ",1,7,272,",
      "level": "3",
      "name": "西藏",
      "name_en": "Tibet",
      "name_pinyin": "xicang",
      "code": "54",
      "childrens": [{
        "id": "3408",
        "pid": "272",
        "path": ",1,7,272,3408,",
        "level": "4",
        "name": "拉萨",
        "name_en": "Lhasa",
        "name_pinyin": "lasa",
        "code": "1",
        "childrens": []
      }, {
        "id": "3409",
        "pid": "272",
        "path": ",1,7,272,3409,",
        "level": "4",
        "name": "昌都",
        "name_en": "Qamdo",
        "name_pinyin": "changdu",
        "code": "21",
        "childrens": []
      }, {
        "id": "3410",
        "pid": "272",
        "path": ",1,7,272,3410,",
        "level": "4",
        "name": "山南",
        "name_en": "Shannan",
        "name_pinyin": "shannan",
        "code": "22",
        "childrens": []
      }, {
        "id": "3411",
        "pid": "272",
        "path": ",1,7,272,3411,",
        "level": "4",
        "name": "日喀则",
        "name_en": "Xigaze",
        "name_pinyin": "rikaze",
        "code": "23",
        "childrens": []
      }, {
        "id": "3412",
        "pid": "272",
        "path": ",1,7,272,3412,",
        "level": "4",
        "name": "那曲",
        "name_en": "Nagqu",
        "name_pinyin": "naqu",
        "code": "24",
        "childrens": []
      }, {
        "id": "3413",
        "pid": "272",
        "path": ",1,7,272,3413,",
        "level": "4",
        "name": "阿里",
        "name_en": "Ngari",
        "name_pinyin": "ali",
        "code": "25",
        "childrens": []
      }, {
        "id": "3414",
        "pid": "272",
        "path": ",1,7,272,3414,",
        "level": "4",
        "name": "林芝",
        "name_en": "Nyingchi",
        "name_pinyin": "linzhi",
        "code": "26",
        "childrens": []
      }]
    }, {
      "id": "273",
      "pid": "7",
      "path": ",1,7,273,",
      "level": "3",
      "name": "陕西",
      "name_en": "Shaanxi",
      "name_pinyin": "shanxi",
      "code": "61",
      "childrens": [{
        "id": "3415",
        "pid": "273",
        "path": ",1,7,273,3415,",
        "level": "4",
        "name": "西安",
        "name_en": "Xi'an",
        "name_pinyin": "xian",
        "code": "1",
        "childrens": []
      }, {
        "id": "3416",
        "pid": "273",
        "path": ",1,7,273,3416,",
        "level": "4",
        "name": "铜川",
        "name_en": "Tongchuan",
        "name_pinyin": "tongchuan",
        "code": "2",
        "childrens": []
      }, {
        "id": "3417",
        "pid": "273",
        "path": ",1,7,273,3417,",
        "level": "4",
        "name": "宝鸡",
        "name_en": "Baoji",
        "name_pinyin": "baoji",
        "code": "3",
        "childrens": []
      }, {
        "id": "3418",
        "pid": "273",
        "path": ",1,7,273,3418,",
        "level": "4",
        "name": "咸阳",
        "name_en": "Xianyang",
        "name_pinyin": "xianyang",
        "code": "4",
        "childrens": []
      }, {
        "id": "3419",
        "pid": "273",
        "path": ",1,7,273,3419,",
        "level": "4",
        "name": "渭南",
        "name_en": "Weinan",
        "name_pinyin": "weinan",
        "code": "5",
        "childrens": []
      }, {
        "id": "3420",
        "pid": "273",
        "path": ",1,7,273,3420,",
        "level": "4",
        "name": "延安",
        "name_en": "Yan'an",
        "name_pinyin": "yanan",
        "code": "6",
        "childrens": []
      }, {
        "id": "3421",
        "pid": "273",
        "path": ",1,7,273,3421,",
        "level": "4",
        "name": "汉中",
        "name_en": "Hanzhong",
        "name_pinyin": "hanzhong",
        "code": "7",
        "childrens": []
      }, {
        "id": "3422",
        "pid": "273",
        "path": ",1,7,273,3422,",
        "level": "4",
        "name": "榆林",
        "name_en": "Yulin",
        "name_pinyin": "yulin",
        "code": "8",
        "childrens": []
      }, {
        "id": "3423",
        "pid": "273",
        "path": ",1,7,273,3423,",
        "level": "4",
        "name": "安康",
        "name_en": "Ankang",
        "name_pinyin": "ankang",
        "code": "9",
        "childrens": []
      }, {
        "id": "3424",
        "pid": "273",
        "path": ",1,7,273,3424,",
        "level": "4",
        "name": "商洛",
        "name_en": "Shangluo",
        "name_pinyin": "shangluo",
        "code": "10",
        "childrens": []
      }]
    }, {
      "id": "274",
      "pid": "7",
      "path": ",1,7,274,",
      "level": "3",
      "name": "甘肃",
      "name_en": "Gansu",
      "name_pinyin": "gansu",
      "code": "62",
      "childrens": [{
        "id": "3425",
        "pid": "274",
        "path": ",1,7,274,3425,",
        "level": "4",
        "name": "兰州市",
        "name_en": "Lanzhou",
        "name_pinyin": "lanzhoushi",
        "code": "1",
        "childrens": []
      }, {
        "id": "3426",
        "pid": "274",
        "path": ",1,7,274,3426,",
        "level": "4",
        "name": "嘉峪关",
        "name_en": "Jinchang",
        "name_pinyin": "jiayuguan",
        "code": "2",
        "childrens": []
      }, {
        "id": "3427",
        "pid": "274",
        "path": ",1,7,274,3427,",
        "level": "4",
        "name": "金昌",
        "name_en": "Baiyin",
        "name_pinyin": "jinchang",
        "code": "3",
        "childrens": []
      }, {
        "id": "3428",
        "pid": "274",
        "path": ",1,7,274,3428,",
        "level": "4",
        "name": "白银",
        "name_en": "Tianshui",
        "name_pinyin": "baiyin",
        "code": "4",
        "childrens": []
      }, {
        "id": "3429",
        "pid": "274",
        "path": ",1,7,274,3429,",
        "level": "4",
        "name": "天水",
        "name_en": "Jiayuguan",
        "name_pinyin": "tianshui",
        "code": "5",
        "childrens": []
      }, {
        "id": "3430",
        "pid": "274",
        "path": ",1,7,274,3430,",
        "level": "4",
        "name": "武威",
        "name_en": "Wuwei",
        "name_pinyin": "wuwei",
        "code": "6",
        "childrens": []
      }, {
        "id": "3431",
        "pid": "274",
        "path": ",1,7,274,3431,",
        "level": "4",
        "name": "张掖",
        "name_en": "Zhangye",
        "name_pinyin": "zhangye",
        "code": "7",
        "childrens": []
      }, {
        "id": "3432",
        "pid": "274",
        "path": ",1,7,274,3432,",
        "level": "4",
        "name": "平凉",
        "name_en": "Pingliang",
        "name_pinyin": "pingliang",
        "code": "8",
        "childrens": []
      }, {
        "id": "3433",
        "pid": "274",
        "path": ",1,7,274,3433,",
        "level": "4",
        "name": "酒泉",
        "name_en": "Jiuquan",
        "name_pinyin": "jiuquan",
        "code": "9",
        "childrens": []
      }, {
        "id": "3434",
        "pid": "274",
        "path": ",1,7,274,3434,",
        "level": "4",
        "name": "庆阳",
        "name_en": "Qingyang",
        "name_pinyin": "qingyang",
        "code": "10",
        "childrens": []
      }, {
        "id": "3435",
        "pid": "274",
        "path": ",1,7,274,3435,",
        "level": "4",
        "name": "定西",
        "name_en": "Dingxi",
        "name_pinyin": "dingxi",
        "code": "11",
        "childrens": []
      }, {
        "id": "3436",
        "pid": "274",
        "path": ",1,7,274,3436,",
        "level": "4",
        "name": "陇南",
        "name_en": "Longnan",
        "name_pinyin": "longnan",
        "code": "12",
        "childrens": []
      }, {
        "id": "3437",
        "pid": "274",
        "path": ",1,7,274,3437,",
        "level": "4",
        "name": "临夏",
        "name_en": "Linxia Hui Autonomous Prefecture",
        "name_pinyin": "linxia",
        "code": "29",
        "childrens": []
      }, {
        "id": "3438",
        "pid": "274",
        "path": ",1,7,274,3438,",
        "level": "4",
        "name": "甘南",
        "name_en": "Gannan Tibetan Autonomous Prefecture",
        "name_pinyin": "gannan",
        "code": "30",
        "childrens": []
      }]
    }, {
      "id": "275",
      "pid": "7",
      "path": ",1,7,275,",
      "level": "3",
      "name": "青海",
      "name_en": "Qinghai",
      "name_pinyin": "qinghai",
      "code": "63",
      "childrens": [{
        "id": "3439",
        "pid": "275",
        "path": ",1,7,275,3439,",
        "level": "4",
        "name": "西宁",
        "name_en": "Xining",
        "name_pinyin": "xining",
        "code": "1",
        "childrens": []
      }, {
        "id": "3440",
        "pid": "275",
        "path": ",1,7,275,3440,",
        "level": "4",
        "name": "海东",
        "name_en": "Haidong",
        "name_pinyin": "haidong",
        "code": "21",
        "childrens": []
      }, {
        "id": "3441",
        "pid": "275",
        "path": ",1,7,275,3441,",
        "level": "4",
        "name": "海北",
        "name_en": "Haibei Tibetan Autonomous Prefecture",
        "name_pinyin": "haibei",
        "code": "22",
        "childrens": []
      }, {
        "id": "3442",
        "pid": "275",
        "path": ",1,7,275,3442,",
        "level": "4",
        "name": "黄南",
        "name_en": "Huangnan Tibetan Autonomous Prefecture",
        "name_pinyin": "huangnan",
        "code": "23",
        "childrens": []
      }, {
        "id": "3443",
        "pid": "275",
        "path": ",1,7,275,3443,",
        "level": "4",
        "name": "海南",
        "name_en": "Hainan Tibetan Autonomous Prefecture",
        "name_pinyin": "hainan",
        "code": "25",
        "childrens": []
      }, {
        "id": "3444",
        "pid": "275",
        "path": ",1,7,275,3444,",
        "level": "4",
        "name": "果洛",
        "name_en": "Guoluo Tibetan Autonomous Prefecture",
        "name_pinyin": "guoluo",
        "code": "26",
        "childrens": []
      }, {
        "id": "3445",
        "pid": "275",
        "path": ",1,7,275,3445,",
        "level": "4",
        "name": "玉树",
        "name_en": "Yushu Tibetan Autonomous Prefecture",
        "name_pinyin": "yushu",
        "code": "27",
        "childrens": []
      }, {
        "id": "3446",
        "pid": "275",
        "path": ",1,7,275,3446,",
        "level": "4",
        "name": "海西",
        "name_en": "Haixi Mongol-Tibetan Autonomous Prefecture",
        "name_pinyin": "haixi",
        "code": "28",
        "childrens": []
      }]
    }, {
      "id": "276",
      "pid": "7",
      "path": ",1,7,276,",
      "level": "3",
      "name": "宁夏",
      "name_en": "Ningxia",
      "name_pinyin": "ningxia",
      "code": "64",
      "childrens": [{
        "id": "3447",
        "pid": "276",
        "path": ",1,7,276,3447,",
        "level": "4",
        "name": "银川",
        "name_en": "Yinchuan",
        "name_pinyin": "yinchuan",
        "code": "1",
        "childrens": []
      }, {
        "id": "3448",
        "pid": "276",
        "path": ",1,7,276,3448,",
        "level": "4",
        "name": "石嘴山",
        "name_en": "Shizuishan",
        "name_pinyin": "shizuishan",
        "code": "2",
        "childrens": []
      }, {
        "id": "3449",
        "pid": "276",
        "path": ",1,7,276,3449,",
        "level": "4",
        "name": "吴忠",
        "name_en": "Wuzhong",
        "name_pinyin": "wuzhong",
        "code": "3",
        "childrens": []
      }, {
        "id": "3450",
        "pid": "276",
        "path": ",1,7,276,3450,",
        "level": "4",
        "name": "固原",
        "name_en": "Guyuan",
        "name_pinyin": "guyuan",
        "code": "4",
        "childrens": []
      }, {
        "id": "3451",
        "pid": "276",
        "path": ",1,7,276,3451,",
        "level": "4",
        "name": "中卫",
        "name_en": "Zhongwei",
        "name_pinyin": "zhongwei",
        "code": "5",
        "childrens": []
      }]
    }, {
      "id": "277",
      "pid": "7",
      "path": ",1,7,277,",
      "level": "3",
      "name": "新疆",
      "name_en": "Xinjiang",
      "name_pinyin": "xinjiang",
      "code": "65",
      "childrens": [{
        "id": "3452",
        "pid": "277",
        "path": ",1,7,277,3452,",
        "level": "4",
        "name": "乌鲁木齐",
        "name_en": "Urumqi",
        "name_pinyin": "wulumuqi",
        "code": "1",
        "childrens": []
      }, {
        "id": "3453",
        "pid": "277",
        "path": ",1,7,277,3453,",
        "level": "4",
        "name": "克拉玛依",
        "name_en": "Karamay",
        "name_pinyin": "kelamayi",
        "code": "2",
        "childrens": []
      }, {
        "id": "3454",
        "pid": "277",
        "path": ",1,7,277,3454,",
        "level": "4",
        "name": "吐鲁番",
        "name_en": "Turpan",
        "name_pinyin": "tulufan",
        "code": "21",
        "childrens": []
      }, {
        "id": "3455",
        "pid": "277",
        "path": ",1,7,277,3455,",
        "level": "4",
        "name": "哈密",
        "name_en": "Hami",
        "name_pinyin": "hami",
        "code": "22",
        "childrens": []
      }, {
        "id": "3456",
        "pid": "277",
        "path": ",1,7,277,3456,",
        "level": "4",
        "name": "昌吉",
        "name_en": "Changji Hui Autonomous Prefecture",
        "name_pinyin": "changji",
        "code": "23",
        "childrens": []
      }, {
        "id": "3457",
        "pid": "277",
        "path": ",1,7,277,3457,",
        "level": "4",
        "name": "博尔塔拉",
        "name_en": "Bortala Mongol Autonomous Prefecture",
        "name_pinyin": "boertala",
        "code": "27",
        "childrens": []
      }, {
        "id": "3458",
        "pid": "277",
        "path": ",1,7,277,3458,",
        "level": "4",
        "name": "巴音郭楞",
        "name_en": "Bayingolin Mongol Autonomous Prefecture",
        "name_pinyin": "bayinguoleng",
        "code": "28",
        "childrens": []
      }, {
        "id": "3459",
        "pid": "277",
        "path": ",1,7,277,3459,",
        "level": "4",
        "name": "阿克苏",
        "name_en": "Aksu",
        "name_pinyin": "akesu",
        "code": "29",
        "childrens": []
      }, {
        "id": "3460",
        "pid": "277",
        "path": ",1,7,277,3460,",
        "level": "4",
        "name": "克孜勒苏",
        "name_en": "Kizilsu Kirgiz Autonomous Prefecture",
        "name_pinyin": "kezilesu",
        "code": "30",
        "childrens": []
      }, {
        "id": "3461",
        "pid": "277",
        "path": ",1,7,277,3461,",
        "level": "4",
        "name": "喀什",
        "name_en": "Kashi",
        "name_pinyin": "kashi",
        "code": "31",
        "childrens": []
      }, {
        "id": "3462",
        "pid": "277",
        "path": ",1,7,277,3462,",
        "level": "4",
        "name": "和田",
        "name_en": "Hotan",
        "name_pinyin": "hetian",
        "code": "32",
        "childrens": []
      }, {
        "id": "3463",
        "pid": "277",
        "path": ",1,7,277,3463,",
        "level": "4",
        "name": "伊犁",
        "name_en": "Ili Kazakh Autonomous Prefecture",
        "name_pinyin": "yili",
        "code": "40",
        "childrens": []
      }, {
        "id": "3464",
        "pid": "277",
        "path": ",1,7,277,3464,",
        "level": "4",
        "name": "塔城",
        "name_en": "Tacheng",
        "name_pinyin": "tacheng",
        "code": "42",
        "childrens": []
      }, {
        "id": "3465",
        "pid": "277",
        "path": ",1,7,277,3465,",
        "level": "4",
        "name": "阿勒泰",
        "name_en": "Altay",
        "name_pinyin": "aletai",
        "code": "43",
        "childrens": []
      }, {
        "id": "3466",
        "pid": "277",
        "path": ",1,7,277,3466,",
        "level": "4",
        "name": "石河子",
        "name_en": "Shihezi",
        "name_pinyin": "shihezi",
        "code": "91",
        "childrens": []
      }, {
        "id": "3467",
        "pid": "277",
        "path": ",1,7,277,3467,",
        "level": "4",
        "name": "阿拉尔",
        "name_en": "Alar",
        "name_pinyin": "alaer",
        "code": "92",
        "childrens": []
      }, {
        "id": "3468",
        "pid": "277",
        "path": ",1,7,277,3468,",
        "level": "4",
        "name": "图木舒克",
        "name_en": "Tumsuk",
        "name_pinyin": "tumushuke",
        "code": "93",
        "childrens": []
      }, {
        "id": "3469",
        "pid": "277",
        "path": ",1,7,277,3469,",
        "level": "4",
        "name": "五家渠",
        "name_en": "Wujiaqu",
        "name_pinyin": "wujiaqu",
        "code": "94",
        "childrens": []
      }, {
        "id": "3470",
        "pid": "277",
        "path": ",1,7,277,3470,",
        "level": "4",
        "name": "北屯",
        "name_en": "Beitun",
        "name_pinyin": "beitun",
        "code": "95",
        "childrens": []
      }]
    }, {
      "id": "278",
      "pid": "7",
      "path": ",1,7,278,",
      "level": "3",
      "name": "台湾",
      "name_en": "Taiwan",
      "name_pinyin": "taiwan",
      "code": "71",
      "childrens": [{
        "id": "3471",
        "pid": "278",
        "path": ",1,7,278,3471,",
        "level": "4",
        "name": "台北市",
        "name_en": "Taipei City",
        "name_pinyin": "taibeishi",
        "code": "1",
        "childrens": []
      }, {
        "id": "3472",
        "pid": "278",
        "path": ",1,7,278,3472,",
        "level": "4",
        "name": "高雄市",
        "name_en": "Kaohsiung City",
        "name_pinyin": "gaoxiongshi",
        "code": "2",
        "childrens": []
      }, {
        "id": "3473",
        "pid": "278",
        "path": ",1,7,278,3473,",
        "level": "4",
        "name": "基隆市",
        "name_en": "Keelung City",
        "name_pinyin": "jilongshi",
        "code": "3",
        "childrens": []
      }, {
        "id": "3474",
        "pid": "278",
        "path": ",1,7,278,3474,",
        "level": "4",
        "name": "台中市",
        "name_en": "Taichung City",
        "name_pinyin": "taizhongshi",
        "code": "4",
        "childrens": []
      }, {
        "id": "3475",
        "pid": "278",
        "path": ",1,7,278,3475,",
        "level": "4",
        "name": "台南市",
        "name_en": "Tainan City",
        "name_pinyin": "tainanshi",
        "code": "5",
        "childrens": []
      }, {
        "id": "3476",
        "pid": "278",
        "path": ",1,7,278,3476,",
        "level": "4",
        "name": "新竹市",
        "name_en": "Hsinchu City",
        "name_pinyin": "xinzhushi",
        "code": "6",
        "childrens": []
      }, {
        "id": "3477",
        "pid": "278",
        "path": ",1,7,278,3477,",
        "level": "4",
        "name": "嘉义市",
        "name_en": "Chiayi City",
        "name_pinyin": "jiayishi",
        "code": "7",
        "childrens": []
      }, {
        "id": "3478",
        "pid": "278",
        "path": ",1,7,278,3478,",
        "level": "4",
        "name": "台北县",
        "name_en": "Taipei County",
        "name_pinyin": "taibeixian",
        "code": "8",
        "childrens": []
      }, {
        "id": "3479",
        "pid": "278",
        "path": ",1,7,278,3479,",
        "level": "4",
        "name": "宜兰县",
        "name_en": "Ilan County",
        "name_pinyin": "yilanxian",
        "code": "9",
        "childrens": []
      }, {
        "id": "3480",
        "pid": "278",
        "path": ",1,7,278,3480,",
        "level": "4",
        "name": "桃园县",
        "name_en": "Taoyuan County",
        "name_pinyin": "taoyuanxian",
        "code": "10",
        "childrens": []
      }, {
        "id": "3481",
        "pid": "278",
        "path": ",1,7,278,3481,",
        "level": "4",
        "name": "新竹县",
        "name_en": "Hsinchu County",
        "name_pinyin": "xinzhuxian",
        "code": "11",
        "childrens": []
      }, {
        "id": "3482",
        "pid": "278",
        "path": ",1,7,278,3482,",
        "level": "4",
        "name": "苗栗县",
        "name_en": "Miaoli County",
        "name_pinyin": "miaolixian",
        "code": "12",
        "childrens": []
      }, {
        "id": "3483",
        "pid": "278",
        "path": ",1,7,278,3483,",
        "level": "4",
        "name": "台中县",
        "name_en": "Taichung County",
        "name_pinyin": "taizhongxian",
        "code": "13",
        "childrens": []
      }, {
        "id": "3484",
        "pid": "278",
        "path": ",1,7,278,3484,",
        "level": "4",
        "name": "彰化县",
        "name_en": "Changhwa County",
        "name_pinyin": "zhanghuaxian",
        "code": "14",
        "childrens": []
      }, {
        "id": "3485",
        "pid": "278",
        "path": ",1,7,278,3485,",
        "level": "4",
        "name": "南投县",
        "name_en": "Nantou County",
        "name_pinyin": "nantouxian",
        "code": "15",
        "childrens": []
      }, {
        "id": "3486",
        "pid": "278",
        "path": ",1,7,278,3486,",
        "level": "4",
        "name": "云林县",
        "name_en": "Yunnlin County",
        "name_pinyin": "yunlinxian",
        "code": "16",
        "childrens": []
      }, {
        "id": "3487",
        "pid": "278",
        "path": ",1,7,278,3487,",
        "level": "4",
        "name": "嘉义县",
        "name_en": "Chiayi County",
        "name_pinyin": "jiayixian",
        "code": "17",
        "childrens": []
      }, {
        "id": "3488",
        "pid": "278",
        "path": ",1,7,278,3488,",
        "level": "4",
        "name": "台南县",
        "name_en": "Tainan County",
        "name_pinyin": "tainanxian",
        "code": "18",
        "childrens": []
      }, {
        "id": "3489",
        "pid": "278",
        "path": ",1,7,278,3489,",
        "level": "4",
        "name": "高雄县",
        "name_en": "Kaohsiung County",
        "name_pinyin": "gaoxiongxian",
        "code": "19",
        "childrens": []
      }, {
        "id": "3490",
        "pid": "278",
        "path": ",1,7,278,3490,",
        "level": "4",
        "name": "屏东县",
        "name_en": "Pingtung County",
        "name_pinyin": "pingdongxian",
        "code": "20",
        "childrens": []
      }, {
        "id": "3491",
        "pid": "278",
        "path": ",1,7,278,3491,",
        "level": "4",
        "name": "台东县",
        "name_en": "Taitung County",
        "name_pinyin": "taidongxian",
        "code": "22",
        "childrens": []
      }, {
        "id": "3492",
        "pid": "278",
        "path": ",1,7,278,3492,",
        "level": "4",
        "name": "花莲县",
        "name_en": "Hualian County",
        "name_pinyin": "hualianxian",
        "code": "23",
        "childrens": []
      }, {
        "id": "3493",
        "pid": "278",
        "path": ",1,7,278,3493,",
        "level": "4",
        "name": "澎湖县",
        "name_en": "Penghu County",
        "name_pinyin": "penghuxian",
        "code": "21",
        "childrens": []
      }]
    }, {
      "id": "279",
      "pid": "7",
      "path": ",1,7,279,",
      "level": "3",
      "name": "香港",
      "name_en": "Hongkong SAR",
      "name_pinyin": "xianggang",
      "code": "81",
      "childrens": [{
        "id": "3494",
        "pid": "279",
        "path": ",1,7,279,3494,",
        "level": "4",
        "name": "中西区",
        "name_en": "NaN",
        "name_pinyin": "zhongxiqu",
        "code": "HCW",
        "childrens": []
      }, {
        "id": "3495",
        "pid": "279",
        "path": ",1,7,279,3495,",
        "level": "4",
        "name": "东区",
        "name_en": "NaN",
        "name_pinyin": "dongqu",
        "code": "HEA",
        "childrens": []
      }, {
        "id": "3496",
        "pid": "279",
        "path": ",1,7,279,3496,",
        "level": "4",
        "name": "九龙城区",
        "name_en": "NaN",
        "name_pinyin": "jiulongchengqu",
        "code": "KKC",
        "childrens": []
      }, {
        "id": "3497",
        "pid": "279",
        "path": ",1,7,279,3497,",
        "level": "4",
        "name": "观塘区",
        "name_en": "NaN",
        "name_pinyin": "guantangqu",
        "code": "KKT",
        "childrens": []
      }, {
        "id": "3498",
        "pid": "279",
        "path": ",1,7,279,3498,",
        "level": "4",
        "name": "南区",
        "name_en": "NaN",
        "name_pinyin": "nanqu",
        "code": "HSO",
        "childrens": []
      }, {
        "id": "3499",
        "pid": "279",
        "path": ",1,7,279,3499,",
        "level": "4",
        "name": "深水埗区",
        "name_en": "NaN",
        "name_pinyin": "shenshui",
        "code": "KSS",
        "childrens": []
      }, {
        "id": "3500",
        "pid": "279",
        "path": ",1,7,279,3500,",
        "level": "4",
        "name": "黄大仙区",
        "name_en": "NaN",
        "name_pinyin": "huangdaxianqu",
        "code": "KWT",
        "childrens": []
      }, {
        "id": "3501",
        "pid": "279",
        "path": ",1,7,279,3501,",
        "level": "4",
        "name": "湾仔区",
        "name_en": "NaN",
        "name_pinyin": "wanziqu",
        "code": "HWC",
        "childrens": []
      }, {
        "id": "3502",
        "pid": "279",
        "path": ",1,7,279,3502,",
        "level": "4",
        "name": "油尖旺区",
        "name_en": "NaN",
        "name_pinyin": "youjianwangqu",
        "code": "KYT",
        "childrens": []
      }, {
        "id": "3503",
        "pid": "279",
        "path": ",1,7,279,3503,",
        "level": "4",
        "name": "离岛区",
        "name_en": "NaN",
        "name_pinyin": "lidaoqu",
        "code": "NIS",
        "childrens": []
      }, {
        "id": "3504",
        "pid": "279",
        "path": ",1,7,279,3504,",
        "level": "4",
        "name": "葵青区",
        "name_en": "NaN",
        "name_pinyin": "kuiqingqu",
        "code": "NKT",
        "childrens": []
      }, {
        "id": "3505",
        "pid": "279",
        "path": ",1,7,279,3505,",
        "level": "4",
        "name": "北区",
        "name_en": "NaN",
        "name_pinyin": "beiqu",
        "code": "NNO",
        "childrens": []
      }, {
        "id": "3506",
        "pid": "279",
        "path": ",1,7,279,3506,",
        "level": "4",
        "name": "西贡区",
        "name_en": "NaN",
        "name_pinyin": "xigongqu",
        "code": "NSK",
        "childrens": []
      }, {
        "id": "3507",
        "pid": "279",
        "path": ",1,7,279,3507,",
        "level": "4",
        "name": "沙田区",
        "name_en": "NaN",
        "name_pinyin": "shatianqu",
        "code": "NST",
        "childrens": []
      }, {
        "id": "3508",
        "pid": "279",
        "path": ",1,7,279,3508,",
        "level": "4",
        "name": "屯门区",
        "name_en": "NaN",
        "name_pinyin": "tunmenqu",
        "code": "NTM",
        "childrens": []
      }, {
        "id": "3509",
        "pid": "279",
        "path": ",1,7,279,3509,",
        "level": "4",
        "name": "大埔区",
        "name_en": "NaN",
        "name_pinyin": "dapuqu",
        "code": "NTP",
        "childrens": []
      }, {
        "id": "3510",
        "pid": "279",
        "path": ",1,7,279,3510,",
        "level": "4",
        "name": "荃湾区",
        "name_en": "NaN",
        "name_pinyin": "wanqu",
        "code": "NTW",
        "childrens": []
      }, {
        "id": "3511",
        "pid": "279",
        "path": ",1,7,279,3511,",
        "level": "4",
        "name": "元朗区",
        "name_en": "NaN",
        "name_pinyin": "yuanlangqu",
        "code": "NYL",
        "childrens": []
      }]
    }, {
      "id": "280",
      "pid": "7",
      "path": ",1,7,280,",
      "level": "3",
      "name": "澳门",
      "name_en": "Macao SAR",
      "name_pinyin": "aomen",
      "code": "82",
      "childrens": [{
        "id": "3512",
        "pid": "280",
        "path": ",1,7,280,3512,",
        "level": "4",
        "name": "花地玛堂区",
        "name_en": "NaN",
        "name_pinyin": "huadimatangqu",
        "code": "OLF",
        "childrens": []
      }, {
        "id": "3513",
        "pid": "280",
        "path": ",1,7,280,3513,",
        "level": "4",
        "name": "圣安多尼堂区",
        "name_en": "NaN",
        "name_pinyin": "shenganduonitangqu",
        "code": "ANT",
        "childrens": []
      }, {
        "id": "3514",
        "pid": "280",
        "path": ",1,7,280,3514,",
        "level": "4",
        "name": "大堂区",
        "name_en": "NaN",
        "name_pinyin": "datangqu",
        "code": "CAT",
        "childrens": []
      }, {
        "id": "3515",
        "pid": "280",
        "path": ",1,7,280,3515,",
        "level": "4",
        "name": "望德堂区",
        "name_en": "NaN",
        "name_pinyin": "wangdetangqu",
        "code": "LAW",
        "childrens": []
      }, {
        "id": "3516",
        "pid": "280",
        "path": ",1,7,280,3516,",
        "level": "4",
        "name": "风顺堂区",
        "name_en": "NaN",
        "name_pinyin": "fengshuntangqu",
        "code": "LAZ",
        "childrens": []
      }, {
        "id": "3517",
        "pid": "280",
        "path": ",1,7,280,3517,",
        "level": "4",
        "name": "氹仔",
        "name_en": "NaN",
        "name_pinyin": "",
        "code": "TPA",
        "childrens": []
      }, {
        "id": "3518",
        "pid": "280",
        "path": ",1,7,280,3518,",
        "level": "4",
        "name": "路环",
        "name_en": "NaN",
        "name_pinyin": "luhuan",
        "code": "CLN",
        "childrens": []
      }]
    }]
  }, {
    "id": "10",
    "pid": "1",
    "path": ",1,10,",
    "level": "2",
    "name": "阿富汗",
    "name_en": "Afghanistan",
    "name_pinyin": "afuhan",
    "code": "AFG",
    "childrens": [{
      "id": "435",
      "pid": "10",
      "path": ",1,10,435,",
      "level": "3",
      "name": "赫拉特",
      "name_en": "Herat",
      "name_pinyin": "helate",
      "code": "HEA",
      "childrens": []
    }, {
      "id": "436",
      "pid": "10",
      "path": ",1,10,436,",
      "level": "3",
      "name": "喀布尔",
      "name_en": "Kabul",
      "name_pinyin": "kabuer",
      "code": "KBL",
      "childrens": []
    }, {
      "id": "437",
      "pid": "10",
      "path": ",1,10,437,",
      "level": "3",
      "name": "坎大哈",
      "name_en": "Kandahar",
      "name_pinyin": "kandaha",
      "code": "KDH",
      "childrens": []
    }, {
      "id": "438",
      "pid": "10",
      "path": ",1,10,438,",
      "level": "3",
      "name": "马扎里沙里夫",
      "name_en": "Mazar-i Sharif",
      "name_pinyin": "mazhalishalifu",
      "code": "MZR",
      "childrens": []
    }]
  }, {
    "id": "12",
    "pid": "1",
    "path": ",1,12,",
    "level": "2",
    "name": "阿拉伯联合酋长国",
    "name_en": "United Arab Emirates",
    "name_pinyin": "alabolianheqiuchangguo",
    "code": "ARE",
    "childrens": [{
      "id": "473",
      "pid": "12",
      "path": ",1,12,473,",
      "level": "3",
      "name": "阿布扎比",
      "name_en": "Abu Dhabi",
      "name_pinyin": "abuzhabi",
      "code": "AZ",
      "childrens": []
    }, {
      "id": "474",
      "pid": "12",
      "path": ",1,12,474,",
      "level": "3",
      "name": "艾因",
      "name_en": "Al l'Ayn",
      "name_pinyin": "aiyin",
      "code": "AL",
      "childrens": []
    }, {
      "id": "475",
      "pid": "12",
      "path": ",1,12,475,",
      "level": "3",
      "name": "迪拜",
      "name_en": "Dubai",
      "name_pinyin": "dibai",
      "code": "DU",
      "childrens": []
    }, {
      "id": "476",
      "pid": "12",
      "path": ",1,12,476,",
      "level": "3",
      "name": "沙迦",
      "name_en": "Ash Shariqah",
      "name_pinyin": "sha",
      "code": "SH",
      "childrens": []
    }]
  }, {
    "id": "14",
    "pid": "1",
    "path": ",1,14,",
    "level": "2",
    "name": "阿曼",
    "name_en": "Oman",
    "name_pinyin": "aman",
    "code": "OMN",
    "childrens": [{
      "id": "477",
      "pid": "14",
      "path": ",1,14,477,",
      "level": "3",
      "name": "巴提奈地区",
      "name_en": "Al-Batinah",
      "name_pinyin": "batinaidiqu",
      "code": "BA",
      "childrens": []
    }, {
      "id": "478",
      "pid": "14",
      "path": ",1,14,478,",
      "level": "3",
      "name": "达希莱地区",
      "name_en": "Az-Zahirah",
      "name_pinyin": "daxilaidiqu",
      "code": "ZA",
      "childrens": []
    }, {
      "id": "479",
      "pid": "14",
      "path": ",1,14,479,",
      "level": "3",
      "name": "东部地区",
      "name_en": "Ash-Sharqiyah",
      "name_pinyin": "dongbudiqu",
      "code": "SH",
      "childrens": []
    }, {
      "id": "480",
      "pid": "14",
      "path": ",1,14,480,",
      "level": "3",
      "name": "马斯喀特省",
      "name_en": "Masqat",
      "name_pinyin": "masikatesheng",
      "code": "MA",
      "childrens": []
    }, {
      "id": "481",
      "pid": "14",
      "path": ",1,14,481,",
      "level": "3",
      "name": "穆桑达姆省",
      "name_en": "Musandam",
      "name_pinyin": "musangdamusheng",
      "code": "MU",
      "childrens": []
    }, {
      "id": "482",
      "pid": "14",
      "path": ",1,14,482,",
      "level": "3",
      "name": "内地地区",
      "name_en": "Ad-Dakhiliyah",
      "name_pinyin": "neididiqu",
      "code": "DA",
      "childrens": []
    }, {
      "id": "483",
      "pid": "14",
      "path": ",1,14,483,",
      "level": "3",
      "name": "中部地区",
      "name_en": "Al-Wusta",
      "name_pinyin": "zhongbudiqu",
      "code": "WU",
      "childrens": []
    }, {
      "id": "484",
      "pid": "14",
      "path": ",1,14,484,",
      "level": "3",
      "name": "佐法尔省",
      "name_en": "Zufar",
      "name_pinyin": "zuofaersheng",
      "code": "ZU",
      "childrens": []
    }]
  }, {
    "id": "15",
    "pid": "1",
    "path": ",1,15,",
    "level": "2",
    "name": "阿塞拜疆",
    "name_en": "Azerbaijan",
    "name_pinyin": "asaibaijiang",
    "code": "AZE",
    "childrens": [{
      "id": "485",
      "pid": "15",
      "path": ",1,15,485,",
      "level": "3",
      "name": "阿布歇隆",
      "name_en": "Abseron",
      "name_pinyin": "abuxielong",
      "code": "ABS",
      "childrens": []
    }, {
      "id": "486",
      "pid": "15",
      "path": ",1,15,486,",
      "level": "3",
      "name": "哈奇马斯",
      "name_en": "Xacmaz",
      "name_pinyin": "haqimasi",
      "code": "XAC",
      "childrens": []
    }, {
      "id": "487",
      "pid": "15",
      "path": ",1,15,487,",
      "level": "3",
      "name": "卡尔巴卡尔",
      "name_en": "Kalbacar",
      "name_pinyin": "kaerbakaer",
      "code": "KAL",
      "childrens": []
    }, {
      "id": "488",
      "pid": "15",
      "path": ",1,15,488,",
      "level": "3",
      "name": "卡扎赫",
      "name_en": "Qazax",
      "name_pinyin": "kazhahe",
      "code": "QAZ",
      "childrens": []
    }, {
      "id": "489",
      "pid": "15",
      "path": ",1,15,489,",
      "level": "3",
      "name": "连科兰",
      "name_en": "Lankaran",
      "name_pinyin": "liankelan",
      "code": "LAN",
      "childrens": []
    }, {
      "id": "490",
      "pid": "15",
      "path": ",1,15,490,",
      "level": "3",
      "name": "密尔-卡拉巴赫",
      "name_en": "Mil-Qarabax",
      "name_pinyin": "mierkalabahe",
      "code": "MQA",
      "childrens": []
    }, {
      "id": "491",
      "pid": "15",
      "path": ",1,15,491,",
      "level": "3",
      "name": "穆甘-萨连",
      "name_en": "Mugan-Salyan",
      "name_pinyin": "mugansalian",
      "code": "MSA",
      "childrens": []
    }, {
      "id": "492",
      "pid": "15",
      "path": ",1,15,492,",
      "level": "3",
      "name": "纳戈尔诺－卡拉巴赫",
      "name_en": "Nagorni-Qarabax",
      "name_pinyin": "nageernuokalabahe",
      "code": "NQA",
      "childrens": []
    }, {
      "id": "493",
      "pid": "15",
      "path": ",1,15,493,",
      "level": "3",
      "name": "纳希切万",
      "name_en": "Naxcivan",
      "name_pinyin": "naxiqiewan",
      "code": "NX",
      "childrens": []
    }, {
      "id": "494",
      "pid": "15",
      "path": ",1,15,494,",
      "level": "3",
      "name": "普利亚拉克斯",
      "name_en": "Priaraks",
      "name_pinyin": "puliyalakesi",
      "code": "PRI",
      "childrens": []
    }, {
      "id": "495",
      "pid": "15",
      "path": ",1,15,495,",
      "level": "3",
      "name": "舍基",
      "name_en": "Saki",
      "name_pinyin": "sheji",
      "code": "SA",
      "childrens": []
    }, {
      "id": "496",
      "pid": "15",
      "path": ",1,15,496,",
      "level": "3",
      "name": "苏姆盖特",
      "name_en": "Sumqayit",
      "name_pinyin": "sumugaite",
      "code": "SMC",
      "childrens": []
    }, {
      "id": "497",
      "pid": "15",
      "path": ",1,15,497,",
      "level": "3",
      "name": "锡尔万",
      "name_en": "Sirvan",
      "name_pinyin": "xierwan",
      "code": "SIR",
      "childrens": []
    }, {
      "id": "498",
      "pid": "15",
      "path": ",1,15,498,",
      "level": "3",
      "name": "占贾",
      "name_en": "Ganca",
      "name_pinyin": "zhanjia",
      "code": "GA",
      "childrens": []
    }]
  }, {
    "id": "31",
    "pid": "1",
    "path": ",1,31,",
    "level": "2",
    "name": "巴基斯坦",
    "name_en": "Pakistan",
    "name_pinyin": "bajisitan",
    "code": "PAK",
    "childrens": [{
      "id": "609",
      "pid": "31",
      "path": ",1,31,609,",
      "level": "3",
      "name": "白沙瓦",
      "name_en": "Peshawar",
      "name_pinyin": "baishawa",
      "code": "PEW",
      "childrens": []
    }, {
      "id": "610",
      "pid": "31",
      "path": ",1,31,610,",
      "level": "3",
      "name": "费萨拉巴德",
      "name_en": "Faisalabad",
      "name_pinyin": "feisalabade",
      "code": "LYP",
      "childrens": []
    }, {
      "id": "611",
      "pid": "31",
      "path": ",1,31,611,",
      "level": "3",
      "name": "故吉软瓦拉",
      "name_en": "Gujranwala",
      "name_pinyin": "gujiruanwala",
      "code": "GUJ",
      "childrens": []
    }, {
      "id": "612",
      "pid": "31",
      "path": ",1,31,612,",
      "level": "3",
      "name": "海德拉巴",
      "name_en": "Hyderabad",
      "name_pinyin": "haidelaba",
      "code": "HDD",
      "childrens": []
    }, {
      "id": "613",
      "pid": "31",
      "path": ",1,31,613,",
      "level": "3",
      "name": "卡拉奇",
      "name_en": "Karachi",
      "name_pinyin": "kalaqi",
      "code": "KCT",
      "childrens": []
    }, {
      "id": "614",
      "pid": "31",
      "path": ",1,31,614,",
      "level": "3",
      "name": "拉合尔",
      "name_en": "Lahore",
      "name_pinyin": "laheer",
      "code": "LHE",
      "childrens": []
    }, {
      "id": "615",
      "pid": "31",
      "path": ",1,31,615,",
      "level": "3",
      "name": "拉瓦尔品第",
      "name_en": "Rawalpindi",
      "name_pinyin": "lawaerpindi",
      "code": "RWP",
      "childrens": []
    }, {
      "id": "616",
      "pid": "31",
      "path": ",1,31,616,",
      "level": "3",
      "name": "木尔坦",
      "name_en": "Multan",
      "name_pinyin": "muertan",
      "code": "MUX",
      "childrens": []
    }, {
      "id": "617",
      "pid": "31",
      "path": ",1,31,617,",
      "level": "3",
      "name": "伊斯兰堡",
      "name_en": "Islamabad",
      "name_pinyin": "yisilanbao",
      "code": "ISB",
      "childrens": []
    }]
  }, {
    "id": "33",
    "pid": "1",
    "path": ",1,33,",
    "level": "2",
    "name": "巴勒斯坦",
    "name_en": "Palestinian Authority",
    "name_pinyin": "balesitan",
    "code": "PSE",
    "childrens": [{
      "id": "636",
      "pid": "33",
      "path": ",1,33,636,",
      "level": "3",
      "name": "加沙地带",
      "name_en": "Gaza Strip",
      "name_pinyin": "jiashadidai",
      "code": "GZ",
      "childrens": []
    }, {
      "id": "637",
      "pid": "33",
      "path": ",1,33,637,",
      "level": "3",
      "name": "西岸",
      "name_en": "West Bank",
      "name_pinyin": "xian",
      "code": "WE",
      "childrens": []
    }]
  }, {
    "id": "34",
    "pid": "1",
    "path": ",1,34,",
    "level": "2",
    "name": "巴林",
    "name_en": "Bahrain",
    "name_pinyin": "balin",
    "code": "BHR",
    "childrens": [{
      "id": "638",
      "pid": "34",
      "path": ",1,34,638,",
      "level": "3",
      "name": "北部",
      "name_en": "Ash-Shamaliyah",
      "name_pinyin": "beibu",
      "code": "5",
      "childrens": []
    }, {
      "id": "639",
      "pid": "34",
      "path": ",1,34,639,",
      "level": "3",
      "name": "哈德",
      "name_en": "Al-Hadd",
      "name_pinyin": "hade",
      "code": "1",
      "childrens": []
    }, {
      "id": "640",
      "pid": "34",
      "path": ",1,34,640,",
      "level": "3",
      "name": "哈马德",
      "name_en": "Hammad",
      "name_pinyin": "hamade",
      "code": "12",
      "childrens": []
    }, {
      "id": "641",
      "pid": "34",
      "path": ",1,34,641,",
      "level": "3",
      "name": "里法",
      "name_en": "Ar-Rifa",
      "name_pinyin": "lifa",
      "code": "9",
      "childrens": []
    }, {
      "id": "642",
      "pid": "34",
      "path": ",1,34,642,",
      "level": "3",
      "name": "麦纳麦",
      "name_en": "Al-Manamah",
      "name_pinyin": "mainamai",
      "code": "3",
      "childrens": []
    }, {
      "id": "643",
      "pid": "34",
      "path": ",1,34,643,",
      "level": "3",
      "name": "穆哈拉格",
      "name_en": "Al-Muharraq",
      "name_pinyin": "muhalage",
      "code": "2",
      "childrens": []
    }, {
      "id": "644",
      "pid": "34",
      "path": ",1,34,644,",
      "level": "3",
      "name": "西部",
      "name_en": "Al-Gharbiyah",
      "name_pinyin": "xibu",
      "code": "10",
      "childrens": []
    }, {
      "id": "645",
      "pid": "34",
      "path": ",1,34,645,",
      "level": "3",
      "name": "伊萨城",
      "name_en": "Isa",
      "name_pinyin": "yisacheng",
      "code": "8",
      "childrens": []
    }, {
      "id": "646",
      "pid": "34",
      "path": ",1,34,646,",
      "level": "3",
      "name": "中部",
      "name_en": "Al-Wusta",
      "name_pinyin": "zhongbu",
      "code": "7",
      "childrens": []
    }]
  }, {
    "id": "50",
    "pid": "1",
    "path": ",1,50,",
    "level": "2",
    "name": "不丹",
    "name_en": "Bhutan",
    "name_pinyin": "budan",
    "code": "BTN",
    "childrens": []
  }, {
    "id": "54",
    "pid": "1",
    "path": ",1,54,",
    "level": "2",
    "name": "朝鲜",
    "name_en": "North Korea",
    "name_pinyin": "chaoxian",
    "code": "PRK",
    "childrens": [{
      "id": "867",
      "pid": "54",
      "path": ",1,54,867,",
      "level": "3",
      "name": "海州",
      "name_en": "Haeju",
      "name_pinyin": "haizhou",
      "code": "HAE",
      "childrens": []
    }, {
      "id": "868",
      "pid": "54",
      "path": ",1,54,868,",
      "level": "3",
      "name": "惠山",
      "name_en": "Hyesan",
      "name_pinyin": "huishan",
      "code": "HYE",
      "childrens": []
    }, {
      "id": "869",
      "pid": "54",
      "path": ",1,54,869,",
      "level": "3",
      "name": "江界",
      "name_en": "Kanggye",
      "name_pinyin": "jiangjie",
      "code": "KAN",
      "childrens": []
    }, {
      "id": "870",
      "pid": "54",
      "path": ",1,54,870,",
      "level": "3",
      "name": "开城",
      "name_en": "Kaesong",
      "name_pinyin": "kaicheng",
      "code": "KSN",
      "childrens": []
    }, {
      "id": "871",
      "pid": "54",
      "path": ",1,54,871,",
      "level": "3",
      "name": "罗先",
      "name_en": "Naseon",
      "name_pinyin": "luoxian",
      "code": "NAS",
      "childrens": []
    }, {
      "id": "872",
      "pid": "54",
      "path": ",1,54,872,",
      "level": "3",
      "name": "南浦",
      "name_en": "Namp'o",
      "name_pinyin": "nanpu",
      "code": "NAM",
      "childrens": []
    }, {
      "id": "873",
      "pid": "54",
      "path": ",1,54,873,",
      "level": "3",
      "name": "平壤",
      "name_en": "Pyongyang",
      "name_pinyin": "pingrang",
      "code": "FNJ",
      "childrens": []
    }, {
      "id": "874",
      "pid": "54",
      "path": ",1,54,874,",
      "level": "3",
      "name": "清津",
      "name_en": "Ch'ongjin",
      "name_pinyin": "qingjin",
      "code": "CHO",
      "childrens": []
    }, {
      "id": "875",
      "pid": "54",
      "path": ",1,54,875,",
      "level": "3",
      "name": "沙里院",
      "name_en": "Sariwon",
      "name_pinyin": "shaliyuan",
      "code": "SAR",
      "childrens": []
    }, {
      "id": "876",
      "pid": "54",
      "path": ",1,54,876,",
      "level": "3",
      "name": "咸兴",
      "name_en": "Hamhung",
      "name_pinyin": "xianxing",
      "code": "HAM",
      "childrens": []
    }, {
      "id": "877",
      "pid": "54",
      "path": ",1,54,877,",
      "level": "3",
      "name": "新义州",
      "name_en": "Sinuiju",
      "name_pinyin": "xinyizhou",
      "code": "SII",
      "childrens": []
    }, {
      "id": "878",
      "pid": "54",
      "path": ",1,54,878,",
      "level": "3",
      "name": "元山",
      "name_en": "Wonsan",
      "name_pinyin": "yuanshan",
      "code": "WON",
      "childrens": []
    }]
  }, {
    "id": "57",
    "pid": "1",
    "path": ",1,57,",
    "level": "2",
    "name": "东帝汶",
    "name_en": "Timor-Leste",
    "name_pinyin": "dongdi",
    "code": "TLS",
    "childrens": [{
      "id": "935",
      "pid": "57",
      "path": ",1,57,935,",
      "level": "3",
      "name": "阿伊莱乌",
      "name_en": "Aileu",
      "name_pinyin": "ayilaiwu",
      "code": "AL",
      "childrens": []
    }, {
      "id": "936",
      "pid": "57",
      "path": ",1,57,936,",
      "level": "3",
      "name": "阿伊纳罗",
      "name_en": "Ainaro",
      "name_pinyin": "ayinaluo",
      "code": "AN",
      "childrens": []
    }, {
      "id": "937",
      "pid": "57",
      "path": ",1,57,937,",
      "level": "3",
      "name": "埃尔梅拉",
      "name_en": "Ermera",
      "name_pinyin": "aiermeila",
      "code": "ER",
      "childrens": []
    }, {
      "id": "938",
      "pid": "57",
      "path": ",1,57,938,",
      "level": "3",
      "name": "安贝诺",
      "name_en": "Ambeno",
      "name_pinyin": "anbeinuo",
      "code": "AM",
      "childrens": []
    }, {
      "id": "939",
      "pid": "57",
      "path": ",1,57,939,",
      "level": "3",
      "name": "包考",
      "name_en": "Baucau",
      "name_pinyin": "baokao",
      "code": "BA",
      "childrens": []
    }, {
      "id": "940",
      "pid": "57",
      "path": ",1,57,940,",
      "level": "3",
      "name": "博博纳罗",
      "name_en": "Bobonaro",
      "name_pinyin": "bobonaluo",
      "code": "BO",
      "childrens": []
    }, {
      "id": "941",
      "pid": "57",
      "path": ",1,57,941,",
      "level": "3",
      "name": "帝力",
      "name_en": "Dili",
      "name_pinyin": "dili",
      "code": "DI",
      "childrens": []
    }, {
      "id": "942",
      "pid": "57",
      "path": ",1,57,942,",
      "level": "3",
      "name": "科瓦利马",
      "name_en": "Kovalima",
      "name_pinyin": "kewalima",
      "code": "KO",
      "childrens": []
    }, {
      "id": "943",
      "pid": "57",
      "path": ",1,57,943,",
      "level": "3",
      "name": "劳滕",
      "name_en": "Lautem",
      "name_pinyin": "lao",
      "code": "LA",
      "childrens": []
    }, {
      "id": "944",
      "pid": "57",
      "path": ",1,57,944,",
      "level": "3",
      "name": "利基卡",
      "name_en": "Liquica",
      "name_pinyin": "lijika",
      "code": "LI",
      "childrens": []
    }, {
      "id": "945",
      "pid": "57",
      "path": ",1,57,945,",
      "level": "3",
      "name": "马纳图托",
      "name_en": "Manatuto",
      "name_pinyin": "manatutuo",
      "code": "MT",
      "childrens": []
    }, {
      "id": "946",
      "pid": "57",
      "path": ",1,57,946,",
      "level": "3",
      "name": "马努法伊",
      "name_en": "Manofahi",
      "name_pinyin": "manufayi",
      "code": "MF",
      "childrens": []
    }, {
      "id": "947",
      "pid": "57",
      "path": ",1,57,947,",
      "level": "3",
      "name": "维克克",
      "name_en": "Viqueque",
      "name_pinyin": "weikeke",
      "code": "VI",
      "childrens": []
    }]
  }, {
    "id": "70",
    "pid": "1",
    "path": ",1,70,",
    "level": "2",
    "name": "菲律宾",
    "name_en": "Philippines",
    "name_pinyin": "feilvbin",
    "code": "PHL",
    "childrens": [{
      "id": "1088",
      "pid": "70",
      "path": ",1,70,1088,",
      "level": "3",
      "name": "达沃",
      "name_en": "Davao",
      "name_pinyin": "dawo",
      "code": "DOR",
      "childrens": []
    }, {
      "id": "1089",
      "pid": "70",
      "path": ",1,70,1089,",
      "level": "3",
      "name": "卡卢坎",
      "name_en": "Caloocan",
      "name_pinyin": "kalukan",
      "code": "CAO",
      "childrens": []
    }, {
      "id": "1090",
      "pid": "70",
      "path": ",1,70,1090,",
      "level": "3",
      "name": "马尼拉",
      "name_en": "Manila",
      "name_pinyin": "manila",
      "code": "MNL",
      "childrens": []
    }, {
      "id": "1091",
      "pid": "70",
      "path": ",1,70,1091,",
      "level": "3",
      "name": "宿务",
      "name_en": "Cebu",
      "name_pinyin": "suwu",
      "code": "CEB",
      "childrens": []
    }]
  }, {
    "id": "87",
    "pid": "1",
    "path": ",1,87,",
    "level": "2",
    "name": "哈萨克斯坦",
    "name_en": "Kazakhstan",
    "name_pinyin": "hasakesitan",
    "code": "KAZ",
    "childrens": [{
      "id": "1199",
      "pid": "87",
      "path": ",1,87,1199,",
      "level": "3",
      "name": "阿尔卡累克",
      "name_en": "Arkalyk",
      "name_pinyin": "aerkaleike",
      "code": "AYK",
      "childrens": []
    }, {
      "id": "1200",
      "pid": "87",
      "path": ",1,87,1200,",
      "level": "3",
      "name": "阿克莫拉",
      "name_en": "Aqmola",
      "name_pinyin": "akemola",
      "code": "AKM",
      "childrens": []
    }, {
      "id": "1201",
      "pid": "87",
      "path": ",1,87,1201,",
      "level": "3",
      "name": "阿克苏",
      "name_en": "Aksu",
      "name_pinyin": "akesu",
      "code": "AKS",
      "childrens": []
    }, {
      "id": "1202",
      "pid": "87",
      "path": ",1,87,1202,",
      "level": "3",
      "name": "阿克托别",
      "name_en": "Aqtobe",
      "name_pinyin": "aketuobie",
      "code": "AKT",
      "childrens": []
    }, {
      "id": "1203",
      "pid": "87",
      "path": ",1,87,1203,",
      "level": "3",
      "name": "阿拉木图",
      "name_en": "Almaty",
      "name_pinyin": "alamutu",
      "code": "ALA",
      "childrens": []
    }, {
      "id": "1204",
      "pid": "87",
      "path": ",1,87,1204,",
      "level": "3",
      "name": "阿雷斯",
      "name_en": "Arys",
      "name_pinyin": "aleisi",
      "code": "ARY",
      "childrens": []
    }, {
      "id": "1205",
      "pid": "87",
      "path": ",1,87,1205,",
      "level": "3",
      "name": "阿斯塔纳市",
      "name_en": "Astana",
      "name_pinyin": "asitanashi",
      "code": "AST",
      "childrens": []
    }, {
      "id": "1206",
      "pid": "87",
      "path": ",1,87,1206,",
      "level": "3",
      "name": "阿特劳",
      "name_en": "Atyrau",
      "name_pinyin": "atelao",
      "code": "ATY",
      "childrens": []
    }, {
      "id": "1207",
      "pid": "87",
      "path": ",1,87,1207,",
      "level": "3",
      "name": "埃基巴斯图兹",
      "name_en": "Ekibastuz",
      "name_pinyin": "aijibasituzi",
      "code": "EKB",
      "childrens": []
    }, {
      "id": "1208",
      "pid": "87",
      "path": ",1,87,1208,",
      "level": "3",
      "name": "巴尔喀什",
      "name_en": "Balkhash",
      "name_pinyin": "baerkashi",
      "code": "BXH",
      "childrens": []
    }, {
      "id": "1209",
      "pid": "87",
      "path": ",1,87,1209,",
      "level": "3",
      "name": "巴甫洛达尔",
      "name_en": "Pavlodar",
      "name_pinyin": "bafuluodaer",
      "code": "PAV",
      "childrens": []
    }, {
      "id": "1210",
      "pid": "87",
      "path": ",1,87,1210,",
      "level": "3",
      "name": "北哈萨克斯坦",
      "name_en": "Soltustik Qazaqstan",
      "name_pinyin": "beihasakesitan",
      "code": "SEV",
      "childrens": []
    }, {
      "id": "1211",
      "pid": "87",
      "path": ",1,87,1211,",
      "level": "3",
      "name": "东哈萨克斯坦",
      "name_en": "Shyghys Qazaqstan",
      "name_pinyin": "donghasakesitan",
      "code": "VOS",
      "childrens": []
    }, {
      "id": "1212",
      "pid": "87",
      "path": ",1,87,1212,",
      "level": "3",
      "name": "济良诺夫斯克",
      "name_en": "Zyryanovsk",
      "name_pinyin": "jiliangnuofusike",
      "code": "ZYR",
      "childrens": []
    }, {
      "id": "1213",
      "pid": "87",
      "path": ",1,87,1213,",
      "level": "3",
      "name": "江布尔",
      "name_en": "Zhambyl",
      "name_pinyin": "jiangbuer",
      "code": "DMB",
      "childrens": []
    }, {
      "id": "1214",
      "pid": "87",
      "path": ",1,87,1214,",
      "level": "3",
      "name": "杰兹卡兹甘",
      "name_en": "Zhezkazgan",
      "name_pinyin": "jiezikazigan",
      "code": "DZH",
      "childrens": []
    }, {
      "id": "1215",
      "pid": "87",
      "path": ",1,87,1215,",
      "level": "3",
      "name": "卡拉干达",
      "name_en": "Qaraghandy",
      "name_pinyin": "kalaganda",
      "code": "KAR",
      "childrens": []
    }, {
      "id": "1216",
      "pid": "87",
      "path": ",1,87,1216,",
      "level": "3",
      "name": "卡拉扎尔",
      "name_en": "Karazhal",
      "name_pinyin": "kalazhaer",
      "code": "KZO",
      "childrens": []
    }, {
      "id": "1217",
      "pid": "87",
      "path": ",1,87,1217,",
      "level": "3",
      "name": "卡普恰盖",
      "name_en": "Kapchagay",
      "name_pinyin": "kapuqiagai",
      "code": "KAP",
      "childrens": []
    }, {
      "id": "1218",
      "pid": "87",
      "path": ",1,87,1218,",
      "level": "3",
      "name": "科斯塔奈",
      "name_en": "Qostanay",
      "name_pinyin": "kesitanai",
      "code": "KST",
      "childrens": []
    }, {
      "id": "1219",
      "pid": "87",
      "path": ",1,87,1219,",
      "level": "3",
      "name": "克孜勒奥尔达",
      "name_en": "Qyzylorda",
      "name_pinyin": "kezileaoerda",
      "code": "KZY",
      "childrens": []
    }, {
      "id": "1220",
      "pid": "87",
      "path": ",1,87,1220,",
      "level": "3",
      "name": "肯套",
      "name_en": "Kentau",
      "name_pinyin": "kentao",
      "code": "KEN",
      "childrens": []
    }, {
      "id": "1221",
      "pid": "87",
      "path": ",1,87,1221,",
      "level": "3",
      "name": "库尔恰托夫",
      "name_en": "Kurchatov",
      "name_pinyin": "kuerqiatuofu",
      "code": "KUR",
      "childrens": []
    }, {
      "id": "1222",
      "pid": "87",
      "path": ",1,87,1222,",
      "level": "3",
      "name": "利萨科夫斯克",
      "name_en": "Lisakovsk",
      "name_pinyin": "lisakefusike",
      "code": "LKK",
      "childrens": []
    }, {
      "id": "1223",
      "pid": "87",
      "path": ",1,87,1223,",
      "level": "3",
      "name": "列宁诺戈尔斯克",
      "name_en": "Leninogorsk",
      "name_pinyin": "lieningnuogeersike",
      "code": "LEN",
      "childrens": []
    }, {
      "id": "1224",
      "pid": "87",
      "path": ",1,87,1224,",
      "level": "3",
      "name": "鲁德内",
      "name_en": "Rudny",
      "name_pinyin": "ludenei",
      "code": "RUD",
      "childrens": []
    }, {
      "id": "1225",
      "pid": "87",
      "path": ",1,87,1225,",
      "level": "3",
      "name": "曼格斯套",
      "name_en": "Mangghystau",
      "name_pinyin": "mangesitao",
      "code": "MAN",
      "childrens": []
    }, {
      "id": "1226",
      "pid": "87",
      "path": ",1,87,1226,",
      "level": "3",
      "name": "南哈萨克斯坦",
      "name_en": "Ongtustik Qazaqstan",
      "name_pinyin": "nanhasakesitan",
      "code": "KGT",
      "childrens": []
    }, {
      "id": "1227",
      "pid": "87",
      "path": ",1,87,1227,",
      "level": "3",
      "name": "萨兰",
      "name_en": "Saran",
      "name_pinyin": "salan",
      "code": "SAR",
      "childrens": []
    }, {
      "id": "1228",
      "pid": "87",
      "path": ",1,87,1228,",
      "level": "3",
      "name": "塞梅伊",
      "name_en": "Semey",
      "name_pinyin": "saimeiyi",
      "code": "SEM",
      "childrens": []
    }, {
      "id": "1229",
      "pid": "87",
      "path": ",1,87,1229,",
      "level": "3",
      "name": "沙赫京斯克",
      "name_en": "Shakhtinsk",
      "name_pinyin": "shahejingsike",
      "code": "SAK",
      "childrens": []
    }, {
      "id": "1230",
      "pid": "87",
      "path": ",1,87,1230,",
      "level": "3",
      "name": "斯捷普诺戈尔斯克",
      "name_en": "Stepnogorsk",
      "name_pinyin": "sijiepunuogeersike",
      "code": "STE",
      "childrens": []
    }, {
      "id": "1231",
      "pid": "87",
      "path": ",1,87,1231,",
      "level": "3",
      "name": "铁克利",
      "name_en": "Tekeli",
      "name_pinyin": "tiekeli",
      "code": "TEK",
      "childrens": []
    }, {
      "id": "1232",
      "pid": "87",
      "path": ",1,87,1232,",
      "level": "3",
      "name": "铁米尔套",
      "name_en": "Temirtau",
      "name_pinyin": "tiemiertao",
      "code": "TEM",
      "childrens": []
    }, {
      "id": "1233",
      "pid": "87",
      "path": ",1,87,1233,",
      "level": "3",
      "name": "突厥斯坦",
      "name_en": "Turkestan",
      "name_pinyin": "tusitan",
      "code": "TUR",
      "childrens": []
    }, {
      "id": "1234",
      "pid": "87",
      "path": ",1,87,1234,",
      "level": "3",
      "name": "西哈萨克斯坦",
      "name_en": "Batys Qazaqstan",
      "name_pinyin": "xihasakesitan",
      "code": "ZAP",
      "childrens": []
    }, {
      "id": "1235",
      "pid": "87",
      "path": ",1,87,1235,",
      "level": "3",
      "name": "扎纳奥津",
      "name_en": "Zhanaozen",
      "name_pinyin": "zhanaaojin",
      "code": "ZHA",
      "childrens": []
    }]
  }, {
    "id": "89",
    "pid": "1",
    "path": ",1,89,",
    "level": "2",
    "name": "韩国",
    "name_en": "Korea",
    "name_pinyin": "hanguo",
    "code": "KOR",
    "childrens": [{
      "id": "289",
      "pid": "89",
      "path": ",1,89,289,",
      "level": "3",
      "name": "大邱",
      "name_en": "Daegu",
      "name_pinyin": "daqiu",
      "code": "27",
      "childrens": [{
        "id": "3552",
        "pid": "289",
        "path": ",1,89,289,3552,",
        "level": "4",
        "name": "达城郡",
        "name_en": "Dalseong-gun",
        "name_pinyin": "dachengjun",
        "code": "DSG",
        "childrens": []
      }, {
        "id": "3553",
        "pid": "289",
        "path": ",1,89,289,3553,",
        "level": "4",
        "name": "大邱",
        "name_en": "Daegu",
        "name_pinyin": "daqiu",
        "code": "TAE",
        "childrens": []
      }, {
        "id": "3554",
        "pid": "289",
        "path": ",1,89,289,3554,",
        "level": "4",
        "name": "寿城区",
        "name_en": "Suseong-gu",
        "name_pinyin": "shouchengqu",
        "code": "SUS",
        "childrens": []
      }]
    }, {
      "id": "290",
      "pid": "89",
      "path": ",1,89,290,",
      "level": "3",
      "name": "大田",
      "name_en": "Daejeon",
      "name_pinyin": "datian",
      "code": "30",
      "childrens": []
    }, {
      "id": "291",
      "pid": "89",
      "path": ",1,89,291,",
      "level": "3",
      "name": "釜山",
      "name_en": "Busan",
      "name_pinyin": "fushan",
      "code": "26",
      "childrens": []
    }, {
      "id": "292",
      "pid": "89",
      "path": ",1,89,292,",
      "level": "3",
      "name": "光州",
      "name_en": "Gwangju",
      "name_pinyin": "guangzhou",
      "code": "29",
      "childrens": []
    }, {
      "id": "293",
      "pid": "89",
      "path": ",1,89,293,",
      "level": "3",
      "name": "济州特别自治道",
      "name_en": "Jeju-do",
      "name_pinyin": "jizhoutebiezizhidao",
      "code": "41",
      "childrens": []
    }, {
      "id": "294",
      "pid": "89",
      "path": ",1,89,294,",
      "level": "3",
      "name": "江原道",
      "name_en": "Gangwon-do",
      "name_pinyin": "jiangyuandao",
      "code": "42",
      "childrens": [{
        "id": "3555",
        "pid": "294",
        "path": ",1,89,294,3555,",
        "level": "4",
        "name": "春川市",
        "name_en": "Chuncheon",
        "name_pinyin": "chunchuanshi",
        "code": "CHC",
        "childrens": []
      }, {
        "id": "3556",
        "pid": "294",
        "path": ",1,89,294,3556,",
        "level": "4",
        "name": "东海市",
        "name_en": "Donghae",
        "name_pinyin": "donghaishi",
        "code": "TGH",
        "childrens": []
      }, {
        "id": "3557",
        "pid": "294",
        "path": ",1,89,294,3557,",
        "level": "4",
        "name": "高城郡",
        "name_en": "Goseong County",
        "name_pinyin": "gaochengjun",
        "code": "GSG",
        "childrens": []
      }, {
        "id": "3558",
        "pid": "294",
        "path": ",1,89,294,3558,",
        "level": "4",
        "name": "横城郡",
        "name_en": "Hoengseong County",
        "name_pinyin": "hengchengjun",
        "code": "HSG",
        "childrens": []
      }, {
        "id": "3559",
        "pid": "294",
        "path": ",1,89,294,3559,",
        "level": "4",
        "name": "洪川郡",
        "name_en": "Hongcheon County",
        "name_pinyin": "hongchuanjun",
        "code": "HCN",
        "childrens": []
      }, {
        "id": "3560",
        "pid": "294",
        "path": ",1,89,294,3560,",
        "level": "4",
        "name": "华川郡",
        "name_en": "Hwacheon County",
        "name_pinyin": "huachuanjun",
        "code": "HCH",
        "childrens": []
      }, {
        "id": "3561",
        "pid": "294",
        "path": ",1,89,294,3561,",
        "level": "4",
        "name": "江陵市",
        "name_en": "Gangneung",
        "name_pinyin": "jianglingshi",
        "code": "KAG",
        "childrens": []
      }, {
        "id": "3562",
        "pid": "294",
        "path": ",1,89,294,3562,",
        "level": "4",
        "name": "旌善郡",
        "name_en": "Jeongseon County",
        "name_pinyin": "shanjun",
        "code": "JSE",
        "childrens": []
      }, {
        "id": "3563",
        "pid": "294",
        "path": ",1,89,294,3563,",
        "level": "4",
        "name": "麟蹄郡",
        "name_en": "Inje County",
        "name_pinyin": "tijun",
        "code": "IJE",
        "childrens": []
      }, {
        "id": "3564",
        "pid": "294",
        "path": ",1,89,294,3564,",
        "level": "4",
        "name": "宁越郡",
        "name_en": "Yeongwol County",
        "name_pinyin": "ningyuejun",
        "code": "YWL",
        "childrens": []
      }, {
        "id": "3565",
        "pid": "294",
        "path": ",1,89,294,3565,",
        "level": "4",
        "name": "平昌郡",
        "name_en": "Pyeongchang County",
        "name_pinyin": "pingchangjun",
        "code": "POG",
        "childrens": []
      }, {
        "id": "3566",
        "pid": "294",
        "path": ",1,89,294,3566,",
        "level": "4",
        "name": "三陟市",
        "name_en": "Samcheok",
        "name_pinyin": "sanshi",
        "code": "SUK",
        "childrens": []
      }, {
        "id": "3567",
        "pid": "294",
        "path": ",1,89,294,3567,",
        "level": "4",
        "name": "束草市",
        "name_en": "Sokcho",
        "name_pinyin": "shucaoshi",
        "code": "SHO",
        "childrens": []
      }, {
        "id": "3568",
        "pid": "294",
        "path": ",1,89,294,3568,",
        "level": "4",
        "name": "太白市",
        "name_en": "Taebaek",
        "name_pinyin": "taibaishi",
        "code": "TBK",
        "childrens": []
      }, {
        "id": "3569",
        "pid": "294",
        "path": ",1,89,294,3569,",
        "level": "4",
        "name": "铁原郡",
        "name_en": "Cheorwon County",
        "name_pinyin": "tieyuanjun",
        "code": "CWN",
        "childrens": []
      }, {
        "id": "3570",
        "pid": "294",
        "path": ",1,89,294,3570,",
        "level": "4",
        "name": "襄阳郡",
        "name_en": "Yangyang County",
        "name_pinyin": "xiangyangjun",
        "code": "YNY",
        "childrens": []
      }, {
        "id": "3571",
        "pid": "294",
        "path": ",1,89,294,3571,",
        "level": "4",
        "name": "杨口郡",
        "name_en": "Yanggu County",
        "name_pinyin": "yangkoujun",
        "code": "YGU",
        "childrens": []
      }, {
        "id": "3572",
        "pid": "294",
        "path": ",1,89,294,3572,",
        "level": "4",
        "name": "原州市",
        "name_en": "Wonju",
        "name_pinyin": "yuanzhoushi",
        "code": "WJU",
        "childrens": []
      }]
    }, {
      "id": "295",
      "pid": "89",
      "path": ",1,89,295,",
      "level": "3",
      "name": "京畿道",
      "name_en": "Gyeonggi-do",
      "name_pinyin": "jingdao",
      "code": "49",
      "childrens": [{
        "id": "3573",
        "pid": "295",
        "path": ",1,89,295,3573,",
        "level": "4",
        "name": "安城市",
        "name_en": "Anseong",
        "name_pinyin": "anchengshi",
        "code": "ASG",
        "childrens": []
      }, {
        "id": "3574",
        "pid": "295",
        "path": ",1,89,295,3574,",
        "level": "4",
        "name": "安山市",
        "name_en": "Ansan",
        "name_pinyin": "anshanshi",
        "code": "ASN",
        "childrens": []
      }, {
        "id": "3575",
        "pid": "295",
        "path": ",1,89,295,3575,",
        "level": "4",
        "name": "安养市",
        "name_en": "Anyang",
        "name_pinyin": "anyangshi",
        "code": "ANY",
        "childrens": []
      }, {
        "id": "3576",
        "pid": "295",
        "path": ",1,89,295,3576,",
        "level": "4",
        "name": "抱川市",
        "name_en": "Pocheon",
        "name_pinyin": "baochuanshi",
        "code": "POC",
        "childrens": []
      }, {
        "id": "3577",
        "pid": "295",
        "path": ",1,89,295,3577,",
        "level": "4",
        "name": "城南市",
        "name_en": "Seongnam",
        "name_pinyin": "chengnanshi",
        "code": "SEO",
        "childrens": []
      }, {
        "id": "3578",
        "pid": "295",
        "path": ",1,89,295,3578,",
        "level": "4",
        "name": "东豆川市",
        "name_en": "Dongducheon",
        "name_pinyin": "dongdouchuanshi",
        "code": "DDC",
        "childrens": []
      }, {
        "id": "3579",
        "pid": "295",
        "path": ",1,89,295,3579,",
        "level": "4",
        "name": "富川市",
        "name_en": "Bucheon",
        "name_pinyin": "fuchuanshi",
        "code": "BCN",
        "childrens": []
      }, {
        "id": "3580",
        "pid": "295",
        "path": ",1,89,295,3580,",
        "level": "4",
        "name": "高阳市",
        "name_en": "Goyang",
        "name_pinyin": "gaoyangshi",
        "code": "GYG",
        "childrens": []
      }, {
        "id": "3581",
        "pid": "295",
        "path": ",1,89,295,3581,",
        "level": "4",
        "name": "光明市",
        "name_en": "Gwangmyeong",
        "name_pinyin": "guangmingshi",
        "code": "GMG",
        "childrens": []
      }, {
        "id": "3582",
        "pid": "295",
        "path": ",1,89,295,3582,",
        "level": "4",
        "name": "广州市",
        "name_en": "Gwangju",
        "name_pinyin": "guangzhoushi",
        "code": "KWU",
        "childrens": []
      }, {
        "id": "3583",
        "pid": "295",
        "path": ",1,89,295,3583,",
        "level": "4",
        "name": "果川市",
        "name_en": "Gwacheon",
        "name_pinyin": "guochuanshi",
        "code": "GCN",
        "childrens": []
      }, {
        "id": "3584",
        "pid": "295",
        "path": ",1,89,295,3584,",
        "level": "4",
        "name": "河南市",
        "name_en": "Hanam",
        "name_pinyin": "henanshi",
        "code": "HNM",
        "childrens": []
      }, {
        "id": "3585",
        "pid": "295",
        "path": ",1,89,295,3585,",
        "level": "4",
        "name": "华城市",
        "name_en": "Hwaseong",
        "name_pinyin": "huachengshi",
        "code": "HCH",
        "childrens": []
      }, {
        "id": "3586",
        "pid": "295",
        "path": ",1,89,295,3586,",
        "level": "4",
        "name": "加平郡",
        "name_en": "Gapyeong County",
        "name_pinyin": "jiapingjun",
        "code": "GPG",
        "childrens": []
      }, {
        "id": "3587",
        "pid": "295",
        "path": ",1,89,295,3587,",
        "level": "4",
        "name": "金浦市",
        "name_en": "Gimpo",
        "name_pinyin": "jinpushi",
        "code": "GMP",
        "childrens": []
      }, {
        "id": "3588",
        "pid": "295",
        "path": ",1,89,295,3588,",
        "level": "4",
        "name": "九里市",
        "name_en": "Guri",
        "name_pinyin": "jiulishi",
        "code": "GRI",
        "childrens": []
      }, {
        "id": "3589",
        "pid": "295",
        "path": ",1,89,295,3589,",
        "level": "4",
        "name": "军浦市",
        "name_en": "Gunpo",
        "name_pinyin": "junpushi",
        "code": "GUN",
        "childrens": []
      }, {
        "id": "3590",
        "pid": "295",
        "path": ",1,89,295,3590,",
        "level": "4",
        "name": "骊州郡",
        "name_en": "Yeoju County",
        "name_pinyin": "zhoujun",
        "code": "YJU",
        "childrens": []
      }, {
        "id": "3591",
        "pid": "295",
        "path": ",1,89,295,3591,",
        "level": "4",
        "name": "利川市",
        "name_en": "Icheon",
        "name_pinyin": "lichuanshi",
        "code": "ICE",
        "childrens": []
      }, {
        "id": "3592",
        "pid": "295",
        "path": ",1,89,295,3592,",
        "level": "4",
        "name": "涟川郡",
        "name_en": "Yeoncheon County",
        "name_pinyin": "lianchuanjun",
        "code": "YCN",
        "childrens": []
      }, {
        "id": "3593",
        "pid": "295",
        "path": ",1,89,295,3593,",
        "level": "4",
        "name": "龙仁市",
        "name_en": "Yongin",
        "name_pinyin": "longrenshi",
        "code": "YNG",
        "childrens": []
      }, {
        "id": "3594",
        "pid": "295",
        "path": ",1,89,295,3594,",
        "level": "4",
        "name": "南杨州市",
        "name_en": "Namyangju",
        "name_pinyin": "nanyangzhoushi",
        "code": "NYU",
        "childrens": []
      }, {
        "id": "3595",
        "pid": "295",
        "path": ",1,89,295,3595,",
        "level": "4",
        "name": "平泽市",
        "name_en": "Pyeongtaek",
        "name_pinyin": "pingzeshi",
        "code": "PTK",
        "childrens": []
      }, {
        "id": "3596",
        "pid": "295",
        "path": ",1,89,295,3596,",
        "level": "4",
        "name": "坡州市",
        "name_en": "Paju",
        "name_pinyin": "pozhoushi",
        "code": "PJU",
        "childrens": []
      }, {
        "id": "3597",
        "pid": "295",
        "path": ",1,89,295,3597,",
        "level": "4",
        "name": "始兴市",
        "name_en": "Siheung",
        "name_pinyin": "shixingshi",
        "code": "SHE",
        "childrens": []
      }, {
        "id": "3598",
        "pid": "295",
        "path": ",1,89,295,3598,",
        "level": "4",
        "name": "水原市",
        "name_en": "Suwon",
        "name_pinyin": "shuiyuanshi",
        "code": "SUO",
        "childrens": []
      }, {
        "id": "3599",
        "pid": "295",
        "path": ",1,89,295,3599,",
        "level": "4",
        "name": "乌山市",
        "name_en": "Osan",
        "name_pinyin": "wushanshi",
        "code": "OSN",
        "childrens": []
      }, {
        "id": "3600",
        "pid": "295",
        "path": ",1,89,295,3600,",
        "level": "4",
        "name": "扬平郡",
        "name_en": "Yangpyeong County",
        "name_pinyin": "yangpingjun",
        "code": "YPG",
        "childrens": []
      }, {
        "id": "3601",
        "pid": "295",
        "path": ",1,89,295,3601,",
        "level": "4",
        "name": "杨州市",
        "name_en": "Yangju",
        "name_pinyin": "yangzhoushi",
        "code": "YYU",
        "childrens": []
      }, {
        "id": "3602",
        "pid": "295",
        "path": ",1,89,295,3602,",
        "level": "4",
        "name": "仪旺市",
        "name_en": "Uiwang",
        "name_pinyin": "yiwangshi",
        "code": "UWN",
        "childrens": []
      }, {
        "id": "3603",
        "pid": "295",
        "path": ",1,89,295,3603,",
        "level": "4",
        "name": "议政府市",
        "name_en": "Uijeongbu",
        "name_pinyin": "yizhengfushi",
        "code": "UIJ",
        "childrens": []
      }]
    }, {
      "id": "296",
      "pid": "89",
      "path": ",1,89,296,",
      "level": "3",
      "name": "庆尚北道",
      "name_en": "Chungcheongbuk-do",
      "name_pinyin": "qingshangbeidao",
      "code": "43",
      "childrens": [{
        "id": "3604",
        "pid": "296",
        "path": ",1,89,296,3604,",
        "level": "4",
        "name": "安东市",
        "name_en": "Andong",
        "name_pinyin": "andongshi",
        "code": "ADG",
        "childrens": []
      }, {
        "id": "3605",
        "pid": "296",
        "path": ",1,89,296,3605,",
        "level": "4",
        "name": "奉化郡",
        "name_en": "Bonghwa County",
        "name_pinyin": "fenghuajun",
        "code": "BHA",
        "childrens": []
      }, {
        "id": "3606",
        "pid": "296",
        "path": ",1,89,296,3606,",
        "level": "4",
        "name": "高灵郡",
        "name_en": "Goryeong County",
        "name_pinyin": "gaolingjun",
        "code": "GRG",
        "childrens": []
      }, {
        "id": "3607",
        "pid": "296",
        "path": ",1,89,296,3607,",
        "level": "4",
        "name": "龟尾市",
        "name_en": "Gumi",
        "name_pinyin": "guiweishi",
        "code": "KUM",
        "childrens": []
      }, {
        "id": "3608",
        "pid": "296",
        "path": ",1,89,296,3608,",
        "level": "4",
        "name": "金泉市",
        "name_en": "Gimcheon",
        "name_pinyin": "jinquanshi",
        "code": "KMC",
        "childrens": []
      }, {
        "id": "3609",
        "pid": "296",
        "path": ",1,89,296,3609,",
        "level": "4",
        "name": "军威郡",
        "name_en": "Gunwi County",
        "name_pinyin": "junweijun",
        "code": "GWI",
        "childrens": []
      }, {
        "id": "3610",
        "pid": "296",
        "path": ",1,89,296,3610,",
        "level": "4",
        "name": "醴泉郡",
        "name_en": "Yecheon County",
        "name_pinyin": "quanjun",
        "code": "YEC",
        "childrens": []
      }, {
        "id": "3611",
        "pid": "296",
        "path": ",1,89,296,3611,",
        "level": "4",
        "name": "浦项市",
        "name_en": "Pohang",
        "name_pinyin": "puxiangshi",
        "code": "KPO",
        "childrens": []
      }, {
        "id": "3612",
        "pid": "296",
        "path": ",1,89,296,3612,",
        "level": "4",
        "name": "漆谷郡",
        "name_en": "Chilgok County",
        "name_pinyin": "qigujun",
        "code": "CGK",
        "childrens": []
      }, {
        "id": "3613",
        "pid": "296",
        "path": ",1,89,296,3613,",
        "level": "4",
        "name": "淸道郡",
        "name_en": "Cheongdo County",
        "name_pinyin": "",
        "code": "CDO",
        "childrens": []
      }, {
        "id": "3614",
        "pid": "296",
        "path": ",1,89,296,3614,",
        "level": "4",
        "name": "靑松郡",
        "name_en": "Cheongsong County",
        "name_pinyin": "",
        "code": "CSG",
        "childrens": []
      }, {
        "id": "3615",
        "pid": "296",
        "path": ",1,89,296,3615,",
        "level": "4",
        "name": "庆山市",
        "name_en": "Gyeongsan",
        "name_pinyin": "qingshanshi",
        "code": "GYS",
        "childrens": []
      }, {
        "id": "3616",
        "pid": "296",
        "path": ",1,89,296,3616,",
        "level": "4",
        "name": "庆州市",
        "name_en": "Gyeongju",
        "name_pinyin": "qingzhoushi",
        "code": "GJU",
        "childrens": []
      }, {
        "id": "3617",
        "pid": "296",
        "path": ",1,89,296,3617,",
        "level": "4",
        "name": "荣州市",
        "name_en": "Yeongju",
        "name_pinyin": "rongzhoushi",
        "code": "YEJ",
        "childrens": []
      }, {
        "id": "3618",
        "pid": "296",
        "path": ",1,89,296,3618,",
        "level": "4",
        "name": "尙州市",
        "name_en": "Sangju",
        "name_pinyin": "",
        "code": "SJU",
        "childrens": []
      }, {
        "id": "3619",
        "pid": "296",
        "path": ",1,89,296,3619,",
        "level": "4",
        "name": "蔚珍郡",
        "name_en": "Uljin County",
        "name_pinyin": "weizhenjun",
        "code": "UJN",
        "childrens": []
      }, {
        "id": "3620",
        "pid": "296",
        "path": ",1,89,296,3620,",
        "level": "4",
        "name": "闻庆市",
        "name_en": "Mungyeong",
        "name_pinyin": "wenqingshi",
        "code": "MGG",
        "childrens": []
      }, {
        "id": "3621",
        "pid": "296",
        "path": ",1,89,296,3621,",
        "level": "4",
        "name": "星州郡",
        "name_en": "Seongju County",
        "name_pinyin": "xingzhoujun",
        "code": "SEJ",
        "childrens": []
      }, {
        "id": "3622",
        "pid": "296",
        "path": ",1,89,296,3622,",
        "level": "4",
        "name": "义城郡",
        "name_en": "Uiseong County",
        "name_pinyin": "yichengjun",
        "code": "USG",
        "childrens": []
      }, {
        "id": "3623",
        "pid": "296",
        "path": ",1,89,296,3623,",
        "level": "4",
        "name": "英阳郡",
        "name_en": "Yeongyang County",
        "name_pinyin": "yingyangjun",
        "code": "YYG",
        "childrens": []
      }, {
        "id": "3624",
        "pid": "296",
        "path": ",1,89,296,3624,",
        "level": "4",
        "name": "盈德郡",
        "name_en": "Yeongdeok County",
        "name_pinyin": "yingdejun",
        "code": "YDK",
        "childrens": []
      }, {
        "id": "3625",
        "pid": "296",
        "path": ",1,89,296,3625,",
        "level": "4",
        "name": "永川市",
        "name_en": "Yeongcheon",
        "name_pinyin": "yongchuanshi",
        "code": "YCH",
        "childrens": []
      }, {
        "id": "3626",
        "pid": "296",
        "path": ",1,89,296,3626,",
        "level": "4",
        "name": "郁陵郡",
        "name_en": "Ulleung County",
        "name_pinyin": "yulingjun",
        "code": "ULG",
        "childrens": []
      }]
    }, {
      "id": "297",
      "pid": "89",
      "path": ",1,89,297,",
      "level": "3",
      "name": "庆尚南道",
      "name_en": "Chungcheongnam-do",
      "name_pinyin": "qingshangnandao",
      "code": "44",
      "childrens": [{
        "id": "3627",
        "pid": "297",
        "path": ",1,89,297,3627,",
        "level": "4",
        "name": "昌宁郡",
        "name_en": "Changnyeong County",
        "name_pinyin": "changningjun",
        "code": "CNG",
        "childrens": []
      }, {
        "id": "3628",
        "pid": "297",
        "path": ",1,89,297,3628,",
        "level": "4",
        "name": "昌原市",
        "name_en": "Changwon",
        "name_pinyin": "changyuanshi",
        "code": "CHW",
        "childrens": []
      }, {
        "id": "3629",
        "pid": "297",
        "path": ",1,89,297,3629,",
        "level": "4",
        "name": "固城郡",
        "name_en": "Goseong County",
        "name_pinyin": "guchengjun",
        "code": "GSO",
        "childrens": []
      }, {
        "id": "3630",
        "pid": "297",
        "path": ",1,89,297,3630,",
        "level": "4",
        "name": "河东郡",
        "name_en": "Hadong County",
        "name_pinyin": "hedongjun",
        "code": "HDG",
        "childrens": []
      }, {
        "id": "3631",
        "pid": "297",
        "path": ",1,89,297,3631,",
        "level": "4",
        "name": "金海市",
        "name_en": "Gimhae",
        "name_pinyin": "jinhaishi",
        "code": "KMH",
        "childrens": []
      }, {
        "id": "3632",
        "pid": "297",
        "path": ",1,89,297,3632,",
        "level": "4",
        "name": "晋州市",
        "name_en": "Jinju",
        "name_pinyin": "jinzhoushi",
        "code": "HIN",
        "childrens": []
      }, {
        "id": "3633",
        "pid": "297",
        "path": ",1,89,297,3633,",
        "level": "4",
        "name": "居昌郡",
        "name_en": "Geochang County",
        "name_pinyin": "juchangjun",
        "code": "GCH",
        "childrens": []
      }, {
        "id": "3634",
        "pid": "297",
        "path": ",1,89,297,3634,",
        "level": "4",
        "name": "巨济市",
        "name_en": "Geoje",
        "name_pinyin": "jujishi",
        "code": "KJE",
        "childrens": []
      }, {
        "id": "3635",
        "pid": "297",
        "path": ",1,89,297,3635,",
        "level": "4",
        "name": "梁山市",
        "name_en": "Yangsan",
        "name_pinyin": "liangshanshi",
        "code": "YSN",
        "childrens": []
      }, {
        "id": "3636",
        "pid": "297",
        "path": ",1,89,297,3636,",
        "level": "4",
        "name": "马山市",
        "name_en": "Masan",
        "name_pinyin": "mashanshi",
        "code": "MAS",
        "childrens": []
      }, {
        "id": "3637",
        "pid": "297",
        "path": ",1,89,297,3637,",
        "level": "4",
        "name": "密阳市",
        "name_en": "Miryang",
        "name_pinyin": "miyangshi",
        "code": "MIR",
        "childrens": []
      }, {
        "id": "3638",
        "pid": "297",
        "path": ",1,89,297,3638,",
        "level": "4",
        "name": "南海郡",
        "name_en": "Namhae County",
        "name_pinyin": "nanhaijun",
        "code": "NHE",
        "childrens": []
      }, {
        "id": "3639",
        "pid": "297",
        "path": ",1,89,297,3639,",
        "level": "4",
        "name": "山淸郡",
        "name_en": "Sancheong County",
        "name_pinyin": "shan",
        "code": "SCH",
        "childrens": []
      }, {
        "id": "3640",
        "pid": "297",
        "path": ",1,89,297,3640,",
        "level": "4",
        "name": "泗川市",
        "name_en": "Sacheon",
        "name_pinyin": "chuanshi",
        "code": "SAH",
        "childrens": []
      }, {
        "id": "3641",
        "pid": "297",
        "path": ",1,89,297,3641,",
        "level": "4",
        "name": "统营市",
        "name_en": "Tongyeong",
        "name_pinyin": "tongyingshi",
        "code": "TYG",
        "childrens": []
      }, {
        "id": "3642",
        "pid": "297",
        "path": ",1,89,297,3642,",
        "level": "4",
        "name": "陜川郡",
        "name_en": "Hapcheon County",
        "name_pinyin": "",
        "code": "HCE",
        "childrens": []
      }, {
        "id": "3643",
        "pid": "297",
        "path": ",1,89,297,3643,",
        "level": "4",
        "name": "咸安郡",
        "name_en": "Haman County",
        "name_pinyin": "xiananjun",
        "code": "HAN",
        "childrens": []
      }, {
        "id": "3644",
        "pid": "297",
        "path": ",1,89,297,3644,",
        "level": "4",
        "name": "咸阳郡",
        "name_en": "Hamyang County",
        "name_pinyin": "xianyangjun",
        "code": "HYG",
        "childrens": []
      }, {
        "id": "3645",
        "pid": "297",
        "path": ",1,89,297,3645,",
        "level": "4",
        "name": "宜宁郡",
        "name_en": "Uiryeong County",
        "name_pinyin": "yiningjun",
        "code": "URG",
        "childrens": []
      }, {
        "id": "3646",
        "pid": "297",
        "path": ",1,89,297,3646,",
        "level": "4",
        "name": "鎭海市",
        "name_en": "Jinhae",
        "name_pinyin": "",
        "code": "CHF",
        "childrens": []
      }]
    }, {
      "id": "298",
      "pid": "89",
      "path": ",1,89,298,",
      "level": "3",
      "name": "全罗北道",
      "name_en": "Gyeongsangbuk-do",
      "name_pinyin": "quanluobeidao",
      "code": "47",
      "childrens": [{
        "id": "3647",
        "pid": "298",
        "path": ",1,89,298,3647,",
        "level": "4",
        "name": "淳昌郡",
        "name_en": "Sunchang County",
        "name_pinyin": "chunchangjun",
        "code": "SCG",
        "childrens": []
      }, {
        "id": "3648",
        "pid": "298",
        "path": ",1,89,298,3648,",
        "level": "4",
        "name": "扶安郡",
        "name_en": "Buan County",
        "name_pinyin": "fuanjun",
        "code": "PUS",
        "childrens": []
      }, {
        "id": "3649",
        "pid": "298",
        "path": ",1,89,298,3649,",
        "level": "4",
        "name": "高敞郡",
        "name_en": "Gochang County",
        "name_pinyin": "gaochangjun",
        "code": "GCG",
        "childrens": []
      }, {
        "id": "3650",
        "pid": "298",
        "path": ",1,89,298,3650,",
        "level": "4",
        "name": "金堤市",
        "name_en": "Gimje",
        "name_pinyin": "jindishi",
        "code": "GJE",
        "childrens": []
      }, {
        "id": "3651",
        "pid": "298",
        "path": ",1,89,298,3651,",
        "level": "4",
        "name": "井邑市",
        "name_en": "Jeongeup",
        "name_pinyin": "jingyishi",
        "code": "JEO",
        "childrens": []
      }, {
        "id": "3652",
        "pid": "298",
        "path": ",1,89,298,3652,",
        "level": "4",
        "name": "茂朱郡",
        "name_en": "Muju County",
        "name_pinyin": "maozhujun",
        "code": "MJU",
        "childrens": []
      }, {
        "id": "3653",
        "pid": "298",
        "path": ",1,89,298,3653,",
        "level": "4",
        "name": "南原市",
        "name_en": "Namwon",
        "name_pinyin": "nanyuanshi",
        "code": "NWN",
        "childrens": []
      }, {
        "id": "3654",
        "pid": "298",
        "path": ",1,89,298,3654,",
        "level": "4",
        "name": "全州市",
        "name_en": "Jeonju",
        "name_pinyin": "quanzhoushi",
        "code": "JNJ",
        "childrens": []
      }, {
        "id": "3655",
        "pid": "298",
        "path": ",1,89,298,3655,",
        "level": "4",
        "name": "群山市",
        "name_en": "Gunsan",
        "name_pinyin": "qunshanshi",
        "code": "KUV",
        "childrens": []
      }, {
        "id": "3656",
        "pid": "298",
        "path": ",1,89,298,3656,",
        "level": "4",
        "name": "任实郡",
        "name_en": "Imsil County",
        "name_pinyin": "renshijun",
        "code": "ISL",
        "childrens": []
      }, {
        "id": "3657",
        "pid": "298",
        "path": ",1,89,298,3657,",
        "level": "4",
        "name": "完州郡",
        "name_en": "Wanju County",
        "name_pinyin": "wanzhoujun",
        "code": "WAJ",
        "childrens": []
      }, {
        "id": "3658",
        "pid": "298",
        "path": ",1,89,298,3658,",
        "level": "4",
        "name": "益山市",
        "name_en": "Iksan",
        "name_pinyin": "yishanshi",
        "code": "IKS",
        "childrens": []
      }, {
        "id": "3659",
        "pid": "298",
        "path": ",1,89,298,3659,",
        "level": "4",
        "name": "长水郡",
        "name_en": "Jangsu County",
        "name_pinyin": "changshuijun",
        "code": "JSU",
        "childrens": []
      }, {
        "id": "3660",
        "pid": "298",
        "path": ",1,89,298,3660,",
        "level": "4",
        "name": "鎭安郡",
        "name_en": "Jinan County",
        "name_pinyin": "",
        "code": "JAN",
        "childrens": []
      }]
    }, {
      "id": "299",
      "pid": "89",
      "path": ",1,89,299,",
      "level": "3",
      "name": "全罗南道",
      "name_en": "Gyeongsangnam-do",
      "name_pinyin": "quanluonandao",
      "code": "48",
      "childrens": [{
        "id": "3661",
        "pid": "299",
        "path": ",1,89,299,3661,",
        "level": "4",
        "name": "宝城郡",
        "name_en": "Boseong County",
        "name_pinyin": "baochengjun",
        "code": "BSG",
        "childrens": []
      }, {
        "id": "3662",
        "pid": "299",
        "path": ",1,89,299,3662,",
        "level": "4",
        "name": "高兴郡",
        "name_en": "Goheung County",
        "name_pinyin": "gaoxingjun",
        "code": "GHG",
        "childrens": []
      }, {
        "id": "3663",
        "pid": "299",
        "path": ",1,89,299,3663,",
        "level": "4",
        "name": "谷城郡",
        "name_en": "Gokseong County",
        "name_pinyin": "guchengjun",
        "code": "GSE",
        "childrens": []
      }, {
        "id": "3664",
        "pid": "299",
        "path": ",1,89,299,3664,",
        "level": "4",
        "name": "莞岛郡",
        "name_en": "Wando County",
        "name_pinyin": "daojun",
        "code": "WND",
        "childrens": []
      }, {
        "id": "3665",
        "pid": "299",
        "path": ",1,89,299,3665,",
        "level": "4",
        "name": "光阳市",
        "name_en": "Gwangyang",
        "name_pinyin": "guangyangshi",
        "code": "KAN",
        "childrens": []
      }, {
        "id": "3666",
        "pid": "299",
        "path": ",1,89,299,3666,",
        "level": "4",
        "name": "海南郡",
        "name_en": "Haenam County",
        "name_pinyin": "hainanjun",
        "code": "HAE",
        "childrens": []
      }, {
        "id": "3667",
        "pid": "299",
        "path": ",1,89,299,3667,",
        "level": "4",
        "name": "和顺郡",
        "name_en": "Hwasun County",
        "name_pinyin": "heshunjun",
        "code": "HSN",
        "childrens": []
      }, {
        "id": "3668",
        "pid": "299",
        "path": ",1,89,299,3668,",
        "level": "4",
        "name": "康津郡",
        "name_en": "Gangjin County",
        "name_pinyin": "kangjinjun",
        "code": "GJN",
        "childrens": []
      }, {
        "id": "3669",
        "pid": "299",
        "path": ",1,89,299,3669,",
        "level": "4",
        "name": "丽水市",
        "name_en": "Yeosu",
        "name_pinyin": "lishuishi",
        "code": "YOS",
        "childrens": []
      }, {
        "id": "3670",
        "pid": "299",
        "path": ",1,89,299,3670,",
        "level": "4",
        "name": "灵光郡",
        "name_en": "Yeonggwang County",
        "name_pinyin": "lingguangjun",
        "code": "YGG",
        "childrens": []
      }, {
        "id": "3671",
        "pid": "299",
        "path": ",1,89,299,3671,",
        "level": "4",
        "name": "灵岩郡",
        "name_en": "Yeongam County",
        "name_pinyin": "lingyanjun",
        "code": "YAM",
        "childrens": []
      }, {
        "id": "3672",
        "pid": "299",
        "path": ",1,89,299,3672,",
        "level": "4",
        "name": "罗州市",
        "name_en": "Naju",
        "name_pinyin": "luozhoushi",
        "code": "NJU",
        "childrens": []
      }, {
        "id": "3673",
        "pid": "299",
        "path": ",1,89,299,3673,",
        "level": "4",
        "name": "木浦市",
        "name_en": "Mokpo",
        "name_pinyin": "mupushi",
        "code": "MOK",
        "childrens": []
      }, {
        "id": "3674",
        "pid": "299",
        "path": ",1,89,299,3674,",
        "level": "4",
        "name": "求礼郡",
        "name_en": "Gurye County",
        "name_pinyin": "qiulijun",
        "code": "GRE",
        "childrens": []
      }, {
        "id": "3675",
        "pid": "299",
        "path": ",1,89,299,3675,",
        "level": "4",
        "name": "顺天市",
        "name_en": "Suncheon",
        "name_pinyin": "shuntianshi",
        "code": "SYS",
        "childrens": []
      }, {
        "id": "3676",
        "pid": "299",
        "path": ",1,89,299,3676,",
        "level": "4",
        "name": "潭阳郡",
        "name_en": "Damyang County",
        "name_pinyin": "tanyangjun",
        "code": "DYA",
        "childrens": []
      }, {
        "id": "3677",
        "pid": "299",
        "path": ",1,89,299,3677,",
        "level": "4",
        "name": "务安郡",
        "name_en": "Muan County",
        "name_pinyin": "wuanjun",
        "code": "MAN",
        "childrens": []
      }, {
        "id": "3678",
        "pid": "299",
        "path": ",1,89,299,3678,",
        "level": "4",
        "name": "咸平郡",
        "name_en": "Hampyeong County",
        "name_pinyin": "xianpingjun",
        "code": "HPG",
        "childrens": []
      }, {
        "id": "3679",
        "pid": "299",
        "path": ",1,89,299,3679,",
        "level": "4",
        "name": "新安郡",
        "name_en": "Sinan County",
        "name_pinyin": "xinanjun",
        "code": "SAN",
        "childrens": []
      }, {
        "id": "3680",
        "pid": "299",
        "path": ",1,89,299,3680,",
        "level": "4",
        "name": "长城郡",
        "name_en": "Jangseong County",
        "name_pinyin": "changchengjun",
        "code": "JSN",
        "childrens": []
      }, {
        "id": "3681",
        "pid": "299",
        "path": ",1,89,299,3681,",
        "level": "4",
        "name": "长兴郡",
        "name_en": "Jangheung County",
        "name_pinyin": "changxingjun",
        "code": "JHG",
        "childrens": []
      }, {
        "id": "3682",
        "pid": "299",
        "path": ",1,89,299,3682,",
        "level": "4",
        "name": "珍岛郡",
        "name_en": "Jindo County",
        "name_pinyin": "zhendaojun",
        "code": "JDO",
        "childrens": []
      }]
    }, {
      "id": "300",
      "pid": "89",
      "path": ",1,89,300,",
      "level": "3",
      "name": "仁川",
      "name_en": "Incheon",
      "name_pinyin": "renchuan",
      "code": "28",
      "childrens": []
    }, {
      "id": "301",
      "pid": "89",
      "path": ",1,89,301,",
      "level": "3",
      "name": "首尔",
      "name_en": "Seoul",
      "name_pinyin": "shouer",
      "code": "11",
      "childrens": []
    }, {
      "id": "302",
      "pid": "89",
      "path": ",1,89,302,",
      "level": "3",
      "name": "蔚山",
      "name_en": "Ulsan",
      "name_pinyin": "weishan",
      "code": "31",
      "childrens": []
    }, {
      "id": "303",
      "pid": "89",
      "path": ",1,89,303,",
      "level": "3",
      "name": "忠清北道",
      "name_en": "Jeollabuk-do",
      "name_pinyin": "zhongqingbeidao",
      "code": "45",
      "childrens": [{
        "id": "3683",
        "pid": "303",
        "path": ",1,89,303,3683,",
        "level": "4",
        "name": "报恩郡",
        "name_en": "Boeun County",
        "name_pinyin": "baoenjun",
        "code": "BEN",
        "childrens": []
      }, {
        "id": "3684",
        "pid": "303",
        "path": ",1,89,303,3684,",
        "level": "4",
        "name": "曾坪郡",
        "name_en": "Jeungpyeong County",
        "name_pinyin": "zengpingjun",
        "code": "JYG",
        "childrens": []
      }, {
        "id": "3685",
        "pid": "303",
        "path": ",1,89,303,3685,",
        "level": "4",
        "name": "丹阳郡",
        "name_en": "Danyang County",
        "name_pinyin": "danyangjun",
        "code": "DYG",
        "childrens": []
      }, {
        "id": "3686",
        "pid": "303",
        "path": ",1,89,303,3686,",
        "level": "4",
        "name": "堤川市",
        "name_en": "Jecheon",
        "name_pinyin": "dichuanshi",
        "code": "JCH",
        "childrens": []
      }, {
        "id": "3687",
        "pid": "303",
        "path": ",1,89,303,3687,",
        "level": "4",
        "name": "槐山郡",
        "name_en": "Goesan County",
        "name_pinyin": "huaishanjun",
        "code": "GSN",
        "childrens": []
      }, {
        "id": "3688",
        "pid": "303",
        "path": ",1,89,303,3688,",
        "level": "4",
        "name": "淸原郡",
        "name_en": "Cheongwon County",
        "name_pinyin": "",
        "code": "CWO",
        "childrens": []
      }, {
        "id": "3689",
        "pid": "303",
        "path": ",1,89,303,3689,",
        "level": "4",
        "name": "淸州市",
        "name_en": "Cheongju",
        "name_pinyin": "",
        "code": "CJJ",
        "childrens": []
      }, {
        "id": "3690",
        "pid": "303",
        "path": ",1,89,303,3690,",
        "level": "4",
        "name": "沃川郡",
        "name_en": "Okcheon County",
        "name_pinyin": "wochuanjun",
        "code": "OCN",
        "childrens": []
      }, {
        "id": "3691",
        "pid": "303",
        "path": ",1,89,303,3691,",
        "level": "4",
        "name": "阴城郡",
        "name_en": "Eumseong County",
        "name_pinyin": "yinchengjun",
        "code": "ESG",
        "childrens": []
      }, {
        "id": "3692",
        "pid": "303",
        "path": ",1,89,303,3692,",
        "level": "4",
        "name": "永同郡",
        "name_en": "Yeongdong County",
        "name_pinyin": "yongtongjun",
        "code": "YDG",
        "childrens": []
      }, {
        "id": "3693",
        "pid": "303",
        "path": ",1,89,303,3693,",
        "level": "4",
        "name": "鎭川郡",
        "name_en": "Jincheon County",
        "name_pinyin": "",
        "code": "JCN",
        "childrens": []
      }, {
        "id": "3694",
        "pid": "303",
        "path": ",1,89,303,3694,",
        "level": "4",
        "name": "忠州市",
        "name_en": "Chungju",
        "name_pinyin": "zhongzhoushi",
        "code": "CHU",
        "childrens": []
      }]
    }, {
      "id": "304",
      "pid": "89",
      "path": ",1,89,304,",
      "level": "3",
      "name": "忠清南道",
      "name_en": "Jeollanam-do",
      "name_pinyin": "zhongqingnandao",
      "code": "46",
      "childrens": [{
        "id": "3695",
        "pid": "304",
        "path": ",1,89,304,3695,",
        "level": "4",
        "name": "保宁市",
        "name_en": "Boryeong",
        "name_pinyin": "baoningshi",
        "code": "BOR",
        "childrens": []
      }, {
        "id": "3696",
        "pid": "304",
        "path": ",1,89,304,3696,",
        "level": "4",
        "name": "扶余郡",
        "name_en": "Buyeo County",
        "name_pinyin": "fuyujun",
        "code": "BYO",
        "childrens": []
      }, {
        "id": "3697",
        "pid": "304",
        "path": ",1,89,304,3697,",
        "level": "4",
        "name": "公州市",
        "name_en": "Gongju",
        "name_pinyin": "gongzhoushi",
        "code": "GOJ",
        "childrens": []
      }, {
        "id": "3698",
        "pid": "304",
        "path": ",1,89,304,3698,",
        "level": "4",
        "name": "洪城郡",
        "name_en": "Hongseong County",
        "name_pinyin": "hongchengjun",
        "code": "HSE",
        "childrens": []
      }, {
        "id": "3699",
        "pid": "304",
        "path": ",1,89,304,3699,",
        "level": "4",
        "name": "鸡龙市",
        "name_en": "Gyeryong",
        "name_pinyin": "jilongshi",
        "code": "GYE",
        "childrens": []
      }, {
        "id": "3700",
        "pid": "304",
        "path": ",1,89,304,3700,",
        "level": "4",
        "name": "锦山郡",
        "name_en": "Geumsan County",
        "name_pinyin": "jinshanjun",
        "code": "GSA",
        "childrens": []
      }, {
        "id": "3701",
        "pid": "304",
        "path": ",1,89,304,3701,",
        "level": "4",
        "name": "礼山郡",
        "name_en": "Yesan County",
        "name_pinyin": "lishanjun",
        "code": "YOS",
        "childrens": []
      }, {
        "id": "3702",
        "pid": "304",
        "path": ",1,89,304,3702,",
        "level": "4",
        "name": "论山市",
        "name_en": "Nonsan",
        "name_pinyin": "lunshanshi",
        "code": "NSN",
        "childrens": []
      }, {
        "id": "3703",
        "pid": "304",
        "path": ",1,89,304,3703,",
        "level": "4",
        "name": "青阳郡",
        "name_en": "Cheongyang County",
        "name_pinyin": "qingyangjun",
        "code": "CYG",
        "childrens": []
      }, {
        "id": "3704",
        "pid": "304",
        "path": ",1,89,304,3704,",
        "level": "4",
        "name": "瑞山市",
        "name_en": "Seosan",
        "name_pinyin": "ruishanshi",
        "code": "SSA",
        "childrens": []
      }, {
        "id": "3705",
        "pid": "304",
        "path": ",1,89,304,3705,",
        "level": "4",
        "name": "舒川郡",
        "name_en": "Seocheon County",
        "name_pinyin": "shuchuanjun",
        "code": "SCE",
        "childrens": []
      }, {
        "id": "3706",
        "pid": "304",
        "path": ",1,89,304,3706,",
        "level": "4",
        "name": "泰安郡",
        "name_en": "Taean County",
        "name_pinyin": "taianjun",
        "code": "TAN",
        "childrens": []
      }, {
        "id": "3707",
        "pid": "304",
        "path": ",1,89,304,3707,",
        "level": "4",
        "name": "唐津郡",
        "name_en": "Dangjin County",
        "name_pinyin": "tangjinjun",
        "code": "TJI",
        "childrens": []
      }, {
        "id": "3708",
        "pid": "304",
        "path": ",1,89,304,3708,",
        "level": "4",
        "name": "天安市",
        "name_en": "Cheonan",
        "name_pinyin": "tiananshi",
        "code": "CHO",
        "childrens": []
      }, {
        "id": "3709",
        "pid": "304",
        "path": ",1,89,304,3709,",
        "level": "4",
        "name": "牙山市",
        "name_en": "Asan",
        "name_pinyin": "yashanshi",
        "code": "ASA",
        "childrens": []
      }, {
        "id": "3710",
        "pid": "304",
        "path": ",1,89,304,3710,",
        "level": "4",
        "name": "燕岐郡",
        "name_en": "Yeongi County",
        "name_pinyin": "yanjun",
        "code": "YGI",
        "childrens": []
      }]
    }]
  }, {
    "id": "96",
    "pid": "1",
    "path": ",1,96,",
    "level": "2",
    "name": "吉尔吉斯斯坦",
    "name_en": "Kyrgyzstan",
    "name_pinyin": "jierjisisitan",
    "code": "KGZ",
    "childrens": [{
      "id": "1290",
      "pid": "96",
      "path": ",1,96,1290,",
      "level": "3",
      "name": "奥什",
      "name_en": "Osh",
      "name_pinyin": "aoshi",
      "code": "O",
      "childrens": []
    }, {
      "id": "1291",
      "pid": "96",
      "path": ",1,96,1291,",
      "level": "3",
      "name": "巴特肯",
      "name_en": "Batken",
      "name_pinyin": "bateken",
      "code": "B",
      "childrens": []
    }, {
      "id": "1292",
      "pid": "96",
      "path": ",1,96,1292,",
      "level": "3",
      "name": "比什凯克市",
      "name_en": "Bishkek",
      "name_pinyin": "bishikaikeshi",
      "code": "GB",
      "childrens": []
    }, {
      "id": "1293",
      "pid": "96",
      "path": ",1,96,1293,",
      "level": "3",
      "name": "楚河",
      "name_en": "Chuy",
      "name_pinyin": "chuhe",
      "code": "C",
      "childrens": []
    }, {
      "id": "1294",
      "pid": "96",
      "path": ",1,96,1294,",
      "level": "3",
      "name": "贾拉拉巴德",
      "name_en": "Jalal-Abad",
      "name_pinyin": "jialalabade",
      "code": "J",
      "childrens": []
    }, {
      "id": "1295",
      "pid": "96",
      "path": ",1,96,1295,",
      "level": "3",
      "name": "卡拉巴尔塔",
      "name_en": "Karabalta",
      "name_pinyin": "kalabaerta",
      "code": "KBA",
      "childrens": []
    }, {
      "id": "1296",
      "pid": "96",
      "path": ",1,96,1296,",
      "level": "3",
      "name": "卡拉库尔",
      "name_en": "Kara-Kol",
      "name_pinyin": "kalakuer",
      "code": "KKO",
      "childrens": []
    }, {
      "id": "1297",
      "pid": "96",
      "path": ",1,96,1297,",
      "level": "3",
      "name": "坎特",
      "name_en": "Kant",
      "name_pinyin": "kante",
      "code": "KAN",
      "childrens": []
    }, {
      "id": "1298",
      "pid": "96",
      "path": ",1,96,1298,",
      "level": "3",
      "name": "科克扬加克",
      "name_en": "Kok-Jangak",
      "name_pinyin": "kekeyangjiake",
      "code": "KJ",
      "childrens": []
    }, {
      "id": "1299",
      "pid": "96",
      "path": ",1,96,1299,",
      "level": "3",
      "name": "迈利赛",
      "name_en": "Mailuu-Suu",
      "name_pinyin": "mailisai",
      "code": "MS",
      "childrens": []
    }, {
      "id": "1300",
      "pid": "96",
      "path": ",1,96,1300,",
      "level": "3",
      "name": "纳伦",
      "name_en": "Naryn",
      "name_pinyin": "nalun",
      "code": "N",
      "childrens": []
    }, {
      "id": "1301",
      "pid": "96",
      "path": ",1,96,1301,",
      "level": "3",
      "name": "苏卢克图",
      "name_en": "Suluktu",
      "name_pinyin": "suluketu",
      "code": "SU",
      "childrens": []
    }, {
      "id": "1302",
      "pid": "96",
      "path": ",1,96,1302,",
      "level": "3",
      "name": "塔拉斯",
      "name_en": "Talas",
      "name_pinyin": "talasi",
      "code": "T",
      "childrens": []
    }, {
      "id": "1303",
      "pid": "96",
      "path": ",1,96,1303,",
      "level": "3",
      "name": "塔什库梅尔",
      "name_en": "Tash-Kumyr",
      "name_pinyin": "tashikumeier",
      "code": "TK",
      "childrens": []
    }, {
      "id": "1304",
      "pid": "96",
      "path": ",1,96,1304,",
      "level": "3",
      "name": "乌兹根",
      "name_en": "Uzgen",
      "name_pinyin": "wuzigen",
      "code": "UG",
      "childrens": []
    }, {
      "id": "1305",
      "pid": "96",
      "path": ",1,96,1305,",
      "level": "3",
      "name": "伊塞克湖",
      "name_en": "Ysyk-Kol",
      "name_pinyin": "yisaikehu",
      "code": "Y",
      "childrens": []
    }]
  }, {
    "id": "102",
    "pid": "1",
    "path": ",1,102,",
    "level": "2",
    "name": "柬埔寨",
    "name_en": "Cambodia",
    "name_pinyin": "jianpuzhai",
    "code": "KHM",
    "childrens": [{
      "id": "1368",
      "pid": "102",
      "path": ",1,102,1368,",
      "level": "3",
      "name": "奥多棉吉",
      "name_en": "Otdar Mean Chey",
      "name_pinyin": "aoduomianji",
      "code": "OC",
      "childrens": []
    }, {
      "id": "1369",
      "pid": "102",
      "path": ",1,102,1369,",
      "level": "3",
      "name": "白马市",
      "name_en": "Krong Keb",
      "name_pinyin": "baimashi",
      "code": "KB",
      "childrens": []
    }, {
      "id": "1370",
      "pid": "102",
      "path": ",1,102,1370,",
      "level": "3",
      "name": "柏威夏",
      "name_en": "Preah Vihear",
      "name_pinyin": "baiweixia",
      "code": "PR",
      "childrens": []
    }, {
      "id": "1371",
      "pid": "102",
      "path": ",1,102,1371,",
      "level": "3",
      "name": "拜林市",
      "name_en": "Krong Pailin",
      "name_pinyin": "bailinshi",
      "code": "PL",
      "childrens": []
    }, {
      "id": "1372",
      "pid": "102",
      "path": ",1,102,1372,",
      "level": "3",
      "name": "班迭棉吉",
      "name_en": "Banteay Mean Chey",
      "name_pinyin": "bandiemianji",
      "code": "BM",
      "childrens": []
    }, {
      "id": "1373",
      "pid": "102",
      "path": ",1,102,1373,",
      "level": "3",
      "name": "磅清扬",
      "name_en": "Kampong Chhnang",
      "name_pinyin": "bangqingyang",
      "code": "KZC",
      "childrens": []
    }, {
      "id": "1374",
      "pid": "102",
      "path": ",1,102,1374,",
      "level": "3",
      "name": "磅士卑",
      "name_en": "Kampong Spoe",
      "name_pinyin": "bangshibei",
      "code": "KO",
      "childrens": []
    }, {
      "id": "1375",
      "pid": "102",
      "path": ",1,102,1375,",
      "level": "3",
      "name": "磅同",
      "name_en": "Kampong Thum",
      "name_pinyin": "bangtong",
      "code": "KZK",
      "childrens": []
    }, {
      "id": "1376",
      "pid": "102",
      "path": ",1,102,1376,",
      "level": "3",
      "name": "磅湛",
      "name_en": "Kampong Cham",
      "name_pinyin": "bangzhan",
      "code": "KM",
      "childrens": []
    }, {
      "id": "1377",
      "pid": "102",
      "path": ",1,102,1377,",
      "level": "3",
      "name": "波罗勉",
      "name_en": "Prey Veng",
      "name_pinyin": "boluomian",
      "code": "PG",
      "childrens": []
    }, {
      "id": "1378",
      "pid": "102",
      "path": ",1,102,1378,",
      "level": "3",
      "name": "茶胶",
      "name_en": "Takev",
      "name_pinyin": "chajiao",
      "code": "TK",
      "childrens": []
    }, {
      "id": "1379",
      "pid": "102",
      "path": ",1,102,1379,",
      "level": "3",
      "name": "柴桢",
      "name_en": "Svay Rieng",
      "name_pinyin": "chai",
      "code": "SVR",
      "childrens": []
    }, {
      "id": "1380",
      "pid": "102",
      "path": ",1,102,1380,",
      "level": "3",
      "name": "干丹",
      "name_en": "Kandal",
      "name_pinyin": "gandan",
      "code": "KL",
      "childrens": []
    }, {
      "id": "1381",
      "pid": "102",
      "path": ",1,102,1381,",
      "level": "3",
      "name": "戈公",
      "name_en": "Kaoh Kong",
      "name_pinyin": "gegong",
      "code": "KKZ",
      "childrens": []
    }, {
      "id": "1382",
      "pid": "102",
      "path": ",1,102,1382,",
      "level": "3",
      "name": "贡布",
      "name_en": "Kampot",
      "name_pinyin": "gongbu",
      "code": "KMT",
      "childrens": []
    }, {
      "id": "1383",
      "pid": "102",
      "path": ",1,102,1383,",
      "level": "3",
      "name": "金边市",
      "name_en": "Phnum Penh",
      "name_pinyin": "jinbianshi",
      "code": "PNH",
      "childrens": []
    }, {
      "id": "1384",
      "pid": "102",
      "path": ",1,102,1384,",
      "level": "3",
      "name": "桔井",
      "name_en": "Kracheh",
      "name_pinyin": "jiejing",
      "code": "KH",
      "childrens": []
    }, {
      "id": "1385",
      "pid": "102",
      "path": ",1,102,1385,",
      "level": "3",
      "name": "腊塔纳基里",
      "name_en": "Rotanak Kiri",
      "name_pinyin": "latanajili",
      "code": "RBE",
      "childrens": []
    }, {
      "id": "1386",
      "pid": "102",
      "path": ",1,102,1386,",
      "level": "3",
      "name": "马德望",
      "name_en": "Bat Dambang",
      "name_pinyin": "madewang",
      "code": "BA",
      "childrens": []
    }, {
      "id": "1387",
      "pid": "102",
      "path": ",1,102,1387,",
      "level": "3",
      "name": "蒙多基里",
      "name_en": "Mondol Kiri",
      "name_pinyin": "mengduojili",
      "code": "MWV",
      "childrens": []
    }, {
      "id": "1388",
      "pid": "102",
      "path": ",1,102,1388,",
      "level": "3",
      "name": "菩萨",
      "name_en": "Pouthĭsat",
      "name_pinyin": "pusa",
      "code": "PO",
      "childrens": []
    }, {
      "id": "1389",
      "pid": "102",
      "path": ",1,102,1389,",
      "level": "3",
      "name": "上丁",
      "name_en": "Stoeng Treng",
      "name_pinyin": "shangding",
      "code": "TNX",
      "childrens": []
    }, {
      "id": "1390",
      "pid": "102",
      "path": ",1,102,1390,",
      "level": "3",
      "name": "西哈努克市",
      "name_en": "Krong Preah",
      "name_pinyin": "xihanukeshi",
      "code": "KA",
      "childrens": []
    }, {
      "id": "1391",
      "pid": "102",
      "path": ",1,102,1391,",
      "level": "3",
      "name": "暹粒",
      "name_en": "Siem Reab",
      "name_pinyin": "li",
      "code": "REP",
      "childrens": []
    }]
  }, {
    "id": "106",
    "pid": "1",
    "path": ",1,106,",
    "level": "2",
    "name": "卡塔尔",
    "name_en": "Qatar",
    "name_pinyin": "kataer",
    "code": "QAT",
    "childrens": [{
      "id": "1425",
      "pid": "106",
      "path": ",1,106,1425,",
      "level": "3",
      "name": "北部",
      "name_en": "Madinat ach Shamal",
      "name_pinyin": "beibu",
      "code": "MS",
      "childrens": []
    }, {
      "id": "1426",
      "pid": "106",
      "path": ",1,106,1426,",
      "level": "3",
      "name": "多哈",
      "name_en": "Ad Dawhah",
      "name_pinyin": "duoha",
      "code": "DW",
      "childrens": []
    }, {
      "id": "1427",
      "pid": "106",
      "path": ",1,106,1427,",
      "level": "3",
      "name": "古韦里耶",
      "name_en": "Al Ghuwariyah",
      "name_pinyin": "guweiliye",
      "code": "GW",
      "childrens": []
    }, {
      "id": "1428",
      "pid": "106",
      "path": ",1,106,1428,",
      "level": "3",
      "name": "豪尔",
      "name_en": "Al Khawr",
      "name_pinyin": "haoer",
      "code": "KR",
      "childrens": []
    }, {
      "id": "1429",
      "pid": "106",
      "path": ",1,106,1429,",
      "level": "3",
      "name": "杰里扬拜特奈",
      "name_en": "Jariyan al Batnah",
      "name_pinyin": "jieliyangbaitenai",
      "code": "JB",
      "childrens": []
    }, {
      "id": "1430",
      "pid": "106",
      "path": ",1,106,1430,",
      "level": "3",
      "name": "赖扬",
      "name_en": "Ar Rayyan",
      "name_pinyin": "laiyang",
      "code": "RN",
      "childrens": []
    }, {
      "id": "1431",
      "pid": "106",
      "path": ",1,106,1431,",
      "level": "3",
      "name": "沃克拉",
      "name_en": "Al Wakrah",
      "name_pinyin": "wokela",
      "code": "WK",
      "childrens": []
    }, {
      "id": "1432",
      "pid": "106",
      "path": ",1,106,1432,",
      "level": "3",
      "name": "乌姆锡拉勒",
      "name_en": "Umm Salal",
      "name_pinyin": "wumuxilale",
      "code": "UL",
      "childrens": []
    }, {
      "id": "1433",
      "pid": "106",
      "path": ",1,106,1433,",
      "level": "3",
      "name": "朱迈利耶",
      "name_en": "Al Jumaliyah",
      "name_pinyin": "zhumailiye",
      "code": "JM",
      "childrens": []
    }]
  }, {
    "id": "111",
    "pid": "1",
    "path": ",1,111,",
    "level": "2",
    "name": "科威特",
    "name_en": "Kuwait",
    "name_pinyin": "keweite",
    "code": "KWT",
    "childrens": []
  }, {
    "id": "117",
    "pid": "1",
    "path": ",1,117,",
    "level": "2",
    "name": "老挝",
    "name_en": "Laos",
    "name_pinyin": "laowo",
    "code": "LAO",
    "childrens": [{
      "id": "1558",
      "pid": "117",
      "path": ",1,117,1558,",
      "level": "3",
      "name": "阿速坡",
      "name_en": "Attapu",
      "name_pinyin": "asupo",
      "code": "AT",
      "childrens": []
    }, {
      "id": "1559",
      "pid": "117",
      "path": ",1,117,1559,",
      "level": "3",
      "name": "波里坎赛",
      "name_en": "Bolikhamxai",
      "name_pinyin": "bolikansai",
      "code": "BL",
      "childrens": []
    }, {
      "id": "1560",
      "pid": "117",
      "path": ",1,117,1560,",
      "level": "3",
      "name": "博乔",
      "name_en": "Bokeo",
      "name_pinyin": "boqiao",
      "code": "BK",
      "childrens": []
    }, {
      "id": "1561",
      "pid": "117",
      "path": ",1,117,1561,",
      "level": "3",
      "name": "川圹",
      "name_en": "Xiangkhoang",
      "name_pinyin": "chuan",
      "code": "XI",
      "childrens": []
    }, {
      "id": "1562",
      "pid": "117",
      "path": ",1,117,1562,",
      "level": "3",
      "name": "丰沙里",
      "name_en": "Phongsali",
      "name_pinyin": "fengshali",
      "code": "PH",
      "childrens": []
    }, {
      "id": "1563",
      "pid": "117",
      "path": ",1,117,1563,",
      "level": "3",
      "name": "甘蒙",
      "name_en": "Khammouan",
      "name_pinyin": "ganmeng",
      "code": "KH",
      "childrens": []
    }, {
      "id": "1564",
      "pid": "117",
      "path": ",1,117,1564,",
      "level": "3",
      "name": "华潘",
      "name_en": "Houaphan",
      "name_pinyin": "huapan",
      "code": "HO",
      "childrens": []
    }, {
      "id": "1565",
      "pid": "117",
      "path": ",1,117,1565,",
      "level": "3",
      "name": "琅勃拉邦",
      "name_en": "Louangphrabang",
      "name_pinyin": "langbolabang",
      "code": "LP",
      "childrens": []
    }, {
      "id": "1566",
      "pid": "117",
      "path": ",1,117,1566,",
      "level": "3",
      "name": "琅南塔",
      "name_en": "Louang Namtha",
      "name_pinyin": "langnanta",
      "code": "LM",
      "childrens": []
    }, {
      "id": "1567",
      "pid": "117",
      "path": ",1,117,1567,",
      "level": "3",
      "name": "赛宋本行政特区",
      "name_en": "Xaisomboun",
      "name_pinyin": "saisongbenxingzhengtequ",
      "code": "XN",
      "childrens": []
    }, {
      "id": "1568",
      "pid": "117",
      "path": ",1,117,1568,",
      "level": "3",
      "name": "色贡",
      "name_en": "Xekong",
      "name_pinyin": "segong",
      "code": "XE",
      "childrens": []
    }, {
      "id": "1569",
      "pid": "117",
      "path": ",1,117,1569,",
      "level": "3",
      "name": "沙拉湾",
      "name_en": "Saravan",
      "name_pinyin": "shalawan",
      "code": "SL",
      "childrens": []
    }, {
      "id": "1570",
      "pid": "117",
      "path": ",1,117,1570,",
      "level": "3",
      "name": "沙湾拿吉",
      "name_en": "Savannakhet",
      "name_pinyin": "shawannaji",
      "code": "SV",
      "childrens": []
    }, {
      "id": "1571",
      "pid": "117",
      "path": ",1,117,1571,",
      "level": "3",
      "name": "沙耶武里",
      "name_en": "Xaignabouri",
      "name_pinyin": "shayewuli",
      "code": "XA",
      "childrens": []
    }, {
      "id": "1572",
      "pid": "117",
      "path": ",1,117,1572,",
      "level": "3",
      "name": "万象",
      "name_en": "Vientiane",
      "name_pinyin": "wanxiang",
      "code": "VI",
      "childrens": []
    }, {
      "id": "1573",
      "pid": "117",
      "path": ",1,117,1573,",
      "level": "3",
      "name": "乌多姆赛",
      "name_en": "Oudomxai",
      "name_pinyin": "wuduomusai",
      "code": "OU",
      "childrens": []
    }, {
      "id": "1574",
      "pid": "117",
      "path": ",1,117,1574,",
      "level": "3",
      "name": "占巴塞",
      "name_en": "Champasak",
      "name_pinyin": "zhanbasai",
      "code": "CH",
      "childrens": []
    }]
  }, {
    "id": "118",
    "pid": "1",
    "path": ",1,118,",
    "level": "2",
    "name": "黎巴嫩",
    "name_en": "Lebanon",
    "name_pinyin": "libanen",
    "code": "LBN",
    "childrens": [{
      "id": "1575",
      "pid": "118",
      "path": ",1,118,1575,",
      "level": "3",
      "name": "北部",
      "name_en": "Ash-Shamal",
      "name_pinyin": "beibu",
      "code": "AS",
      "childrens": []
    }, {
      "id": "1576",
      "pid": "118",
      "path": ",1,118,1576,",
      "level": "3",
      "name": "贝卡",
      "name_en": "Al-Biqa",
      "name_pinyin": "beika",
      "code": "BI",
      "childrens": []
    }, {
      "id": "1577",
      "pid": "118",
      "path": ",1,118,1577,",
      "level": "3",
      "name": "贝鲁特",
      "name_en": "Bayrut",
      "name_pinyin": "beilute",
      "code": "BA",
      "childrens": []
    }, {
      "id": "1578",
      "pid": "118",
      "path": ",1,118,1578,",
      "level": "3",
      "name": "黎巴嫩山",
      "name_en": "Jabal Lubnan",
      "name_pinyin": "libanenshan",
      "code": "JL",
      "childrens": []
    }, {
      "id": "1579",
      "pid": "118",
      "path": ",1,118,1579,",
      "level": "3",
      "name": "奈拜提耶市",
      "name_en": "An-Nabatiyah",
      "name_pinyin": "naibaitiyeshi",
      "code": "NA",
      "childrens": []
    }, {
      "id": "1580",
      "pid": "118",
      "path": ",1,118,1580,",
      "level": "3",
      "name": "南部",
      "name_en": "Al-Janub",
      "name_pinyin": "nanbu",
      "code": "JA",
      "childrens": []
    }]
  }, {
    "id": "128",
    "pid": "1",
    "path": ",1,128,",
    "level": "2",
    "name": "马尔代夫",
    "name_en": "Maldives",
    "name_pinyin": "maerdaifu",
    "code": "MDV",
    "childrens": [{
      "id": "1675",
      "pid": "128",
      "path": ",1,128,1675,",
      "level": "3",
      "name": "阿杜",
      "name_en": "Addu Atoll",
      "name_pinyin": "adu",
      "code": "ADD",
      "childrens": []
    }, {
      "id": "1676",
      "pid": "128",
      "path": ",1,128,1676,",
      "level": "3",
      "name": "北阿里",
      "name_en": "North Ari Atoll",
      "name_pinyin": "beiali",
      "code": "AAD",
      "childrens": []
    }, {
      "id": "1677",
      "pid": "128",
      "path": ",1,128,1677,",
      "level": "3",
      "name": "北蒂拉杜马蒂",
      "name_en": "North Thiladhunmathi",
      "name_pinyin": "beidiladumadi",
      "code": "THD",
      "childrens": []
    }, {
      "id": "1678",
      "pid": "128",
      "path": ",1,128,1678,",
      "level": "3",
      "name": "北马洛斯马杜卢",
      "name_en": "North Maalhosmadhulu",
      "name_pinyin": "beimaluosimadulu",
      "code": "MAD",
      "childrens": []
    }, {
      "id": "1679",
      "pid": "128",
      "path": ",1,128,1679,",
      "level": "3",
      "name": "北米拉杜马杜卢",
      "name_en": "North Miladhunmadhulu",
      "name_pinyin": "beimiladumadulu",
      "code": "MLD",
      "childrens": []
    }, {
      "id": "1680",
      "pid": "128",
      "path": ",1,128,1680,",
      "level": "3",
      "name": "北尼兰杜",
      "name_en": "North Nilandhe Atoll",
      "name_pinyin": "beinilandu",
      "code": "NAD",
      "childrens": []
    }, {
      "id": "1681",
      "pid": "128",
      "path": ",1,128,1681,",
      "level": "3",
      "name": "北苏瓦迪瓦",
      "name_en": "North Huvadhu Atoll",
      "name_pinyin": "beisuwadiwa",
      "code": "HAD",
      "childrens": []
    }, {
      "id": "1682",
      "pid": "128",
      "path": ",1,128,1682,",
      "level": "3",
      "name": "法迪福卢",
      "name_en": "Faadhippolhu",
      "name_pinyin": "fadifulu",
      "code": "FAA",
      "childrens": []
    }, {
      "id": "1683",
      "pid": "128",
      "path": ",1,128,1683,",
      "level": "3",
      "name": "费利杜",
      "name_en": "Felidhu Atoll",
      "name_pinyin": "feilidu",
      "code": "FEA",
      "childrens": []
    }, {
      "id": "1684",
      "pid": "128",
      "path": ",1,128,1684,",
      "level": "3",
      "name": "福阿穆拉库",
      "name_en": "Foammulah",
      "name_pinyin": "fuamulaku",
      "code": "FMU",
      "childrens": []
    }, {
      "id": "1685",
      "pid": "128",
      "path": ",1,128,1685,",
      "level": "3",
      "name": "哈杜马蒂",
      "name_en": "Hadhdhunmathi",
      "name_pinyin": "hadumadi",
      "code": "HDH",
      "childrens": []
    }, {
      "id": "1686",
      "pid": "128",
      "path": ",1,128,1686,",
      "level": "3",
      "name": "科卢马杜卢",
      "name_en": "Kolhumadulu",
      "name_pinyin": "kelumadulu",
      "code": "KLH",
      "childrens": []
    }, {
      "id": "1687",
      "pid": "128",
      "path": ",1,128,1687,",
      "level": "3",
      "name": "马累",
      "name_en": "Male",
      "name_pinyin": "malei",
      "code": "MAL",
      "childrens": []
    }, {
      "id": "1688",
      "pid": "128",
      "path": ",1,128,1688,",
      "level": "3",
      "name": "马累岛",
      "name_en": "Male Atoll",
      "name_pinyin": "maleidao",
      "code": "MAA",
      "childrens": []
    }, {
      "id": "1689",
      "pid": "128",
      "path": ",1,128,1689,",
      "level": "3",
      "name": "穆拉库",
      "name_en": "Mulakatholhu",
      "name_pinyin": "mulaku",
      "code": "MUA",
      "childrens": []
    }, {
      "id": "1690",
      "pid": "128",
      "path": ",1,128,1690,",
      "level": "3",
      "name": "南阿里",
      "name_en": "South Ari Atoll",
      "name_pinyin": "nanali",
      "code": "AAU",
      "childrens": []
    }, {
      "id": "1691",
      "pid": "128",
      "path": ",1,128,1691,",
      "level": "3",
      "name": "南蒂拉杜马蒂",
      "name_en": "South Thiladhunmathi",
      "name_pinyin": "nandiladumadi",
      "code": "THU",
      "childrens": []
    }, {
      "id": "1692",
      "pid": "128",
      "path": ",1,128,1692,",
      "level": "3",
      "name": "南马洛斯马杜卢",
      "name_en": "South Maalhosmadulu",
      "name_pinyin": "nanmaluosimadulu",
      "code": "MAU",
      "childrens": []
    }, {
      "id": "1693",
      "pid": "128",
      "path": ",1,128,1693,",
      "level": "3",
      "name": "南米拉杜马杜卢",
      "name_en": "South Miladhunmadhulu",
      "name_pinyin": "nanmiladumadulu",
      "code": "MLU",
      "childrens": []
    }, {
      "id": "1694",
      "pid": "128",
      "path": ",1,128,1694,",
      "level": "3",
      "name": "南尼兰杜",
      "name_en": "South Nilandhe Atoll",
      "name_pinyin": "nannilandu",
      "code": "NAU",
      "childrens": []
    }, {
      "id": "1695",
      "pid": "128",
      "path": ",1,128,1695,",
      "level": "3",
      "name": "南苏瓦迪瓦",
      "name_en": "South Huvadhu Atoll",
      "name_pinyin": "nansuwadiwa",
      "code": "HAU",
      "childrens": []
    }]
  }, {
    "id": "131",
    "pid": "1",
    "path": ",1,131,",
    "level": "2",
    "name": "马来西亚",
    "name_en": "Malaysia",
    "name_pinyin": "malaixiya",
    "code": "MYS",
    "childrens": [{
      "id": "305",
      "pid": "131",
      "path": ",1,131,305,",
      "level": "3",
      "name": "槟榔屿",
      "name_en": "Pulau Pinang",
      "name_pinyin": "langyu",
      "code": "PH",
      "childrens": [{
        "id": "3711",
        "pid": "305",
        "path": ",1,131,305,3711,",
        "level": "4",
        "name": "北海",
        "name_en": "Butterworth",
        "name_pinyin": "beihai",
        "code": "BWH",
        "childrens": []
      }, {
        "id": "3712",
        "pid": "305",
        "path": ",1,131,305,3712,",
        "level": "4",
        "name": "槟城",
        "name_en": "George Town",
        "name_pinyin": "cheng",
        "code": "PEN",
        "childrens": []
      }, {
        "id": "3713",
        "pid": "305",
        "path": ",1,131,305,3713,",
        "level": "4",
        "name": "大山脚",
        "name_en": "Bukit Mertajam",
        "name_pinyin": "dashanjiao",
        "code": "BMJ",
        "childrens": []
      }, {
        "id": "3714",
        "pid": "305",
        "path": ",1,131,305,3714,",
        "level": "4",
        "name": "高渊",
        "name_en": "Nibong Tebal",
        "name_pinyin": "gaoyuan",
        "code": "NTE",
        "childrens": []
      }]
    }, {
      "id": "306",
      "pid": "131",
      "path": ",1,131,306,",
      "level": "3",
      "name": "玻璃市",
      "name_en": "Perlis",
      "name_pinyin": "bolishi",
      "code": "PS",
      "childrens": [{
        "id": "3715",
        "pid": "306",
        "path": ",1,131,306,3715,",
        "level": "4",
        "name": "加央",
        "name_en": "Kangar",
        "name_pinyin": "jiayang",
        "code": "KGR",
        "childrens": []
      }]
    }, {
      "id": "307",
      "pid": "131",
      "path": ",1,131,307,",
      "level": "3",
      "name": "丁加奴",
      "name_en": "Terengganu",
      "name_pinyin": "dingjianu",
      "code": "TR",
      "childrens": [{
        "id": "3716",
        "pid": "307",
        "path": ",1,131,307,3716,",
        "level": "4",
        "name": "甘马挽",
        "name_en": "Kemaman",
        "name_pinyin": "ganmawan",
        "code": "KEM",
        "childrens": []
      }, {
        "id": "3717",
        "pid": "307",
        "path": ",1,131,307,3717,",
        "level": "4",
        "name": "瓜拉丁加奴",
        "name_en": "Kuala Terengganu",
        "name_pinyin": "gualadingjianu",
        "code": "TGG",
        "childrens": []
      }, {
        "id": "3718",
        "pid": "307",
        "path": ",1,131,307,3718,",
        "level": "4",
        "name": "龙运",
        "name_en": "Dungun",
        "name_pinyin": "longyun",
        "code": "DGN",
        "childrens": []
      }, {
        "id": "3719",
        "pid": "307",
        "path": ",1,131,307,3719,",
        "level": "4",
        "name": "马江",
        "name_en": "Marang",
        "name_pinyin": "majiang",
        "code": "MAR",
        "childrens": []
      }, {
        "id": "3720",
        "pid": "307",
        "path": ",1,131,307,3720,",
        "level": "4",
        "name": "实兆",
        "name_en": "Setiu",
        "name_pinyin": "shizhao",
        "code": "SET",
        "childrens": []
      }, {
        "id": "3721",
        "pid": "307",
        "path": ",1,131,307,3721,",
        "level": "4",
        "name": "乌鲁",
        "name_en": "Hulu",
        "name_pinyin": "wulu",
        "code": "HUL",
        "childrens": []
      }, {
        "id": "3722",
        "pid": "307",
        "path": ",1,131,307,3722,",
        "level": "4",
        "name": "勿述",
        "name_en": "Besut",
        "name_pinyin": "wushu",
        "code": "BES",
        "childrens": []
      }]
    }, {
      "id": "308",
      "pid": "131",
      "path": ",1,131,308,",
      "level": "3",
      "name": "吉打",
      "name_en": "Kedah",
      "name_pinyin": "jida",
      "code": "KD",
      "childrens": [{
        "id": "3723",
        "pid": "308",
        "path": ",1,131,308,3723,",
        "level": "4",
        "name": "巴东得腊",
        "name_en": "Padang Terap",
        "name_pinyin": "badongdela",
        "code": "PGT",
        "childrens": []
      }, {
        "id": "3724",
        "pid": "308",
        "path": ",1,131,308,3724,",
        "level": "4",
        "name": "笨筒",
        "name_en": "Pendang",
        "name_pinyin": "bentong",
        "code": "PEN",
        "childrens": []
      }, {
        "id": "3725",
        "pid": "308",
        "path": ",1,131,308,3725,",
        "level": "4",
        "name": "浮罗交怡",
        "name_en": "Langkawi",
        "name_pinyin": "fuluojiao",
        "code": "LGK",
        "childrens": []
      }, {
        "id": "3726",
        "pid": "308",
        "path": ",1,131,308,3726,",
        "level": "4",
        "name": "哥打士打",
        "name_en": "Kota Setar",
        "name_pinyin": "gedashida",
        "code": "KOR",
        "childrens": []
      }, {
        "id": "3727",
        "pid": "308",
        "path": ",1,131,308,3727,",
        "level": "4",
        "name": "古邦巴素",
        "name_en": "Kubang Pasu",
        "name_pinyin": "gubangbasu",
        "code": "KPA",
        "childrens": []
      }, {
        "id": "3728",
        "pid": "308",
        "path": ",1,131,308,3728,",
        "level": "4",
        "name": "瓜拉姆达",
        "name_en": "Kuala Muda",
        "name_pinyin": "gualamuda",
        "code": "KMU",
        "childrens": []
      }, {
        "id": "3729",
        "pid": "308",
        "path": ",1,131,308,3729,",
        "level": "4",
        "name": "华玲",
        "name_en": "Baling",
        "name_pinyin": "hualing",
        "code": "BLZ",
        "childrens": []
      }, {
        "id": "3730",
        "pid": "308",
        "path": ",1,131,308,3730,",
        "level": "4",
        "name": "居林",
        "name_en": "Kulim",
        "name_pinyin": "julin",
        "code": "KLM",
        "childrens": []
      }, {
        "id": "3731",
        "pid": "308",
        "path": ",1,131,308,3731,",
        "level": "4",
        "name": "万拉峇鲁",
        "name_en": "Bandar Baharu",
        "name_pinyin": "wanla",
        "code": "BMA",
        "childrens": []
      }]
    }, {
      "id": "309",
      "pid": "131",
      "path": ",1,131,309,",
      "level": "3",
      "name": "吉兰丹",
      "name_en": "Kelantan",
      "name_pinyin": "jilandan",
      "code": "KN",
      "childrens": [{
        "id": "3732",
        "pid": "309",
        "path": ",1,131,309,3732,",
        "level": "4",
        "name": "巴西富地",
        "name_en": "Pasir Putih",
        "name_pinyin": "baxifudi",
        "code": "PPU",
        "childrens": []
      }, {
        "id": "3733",
        "pid": "309",
        "path": ",1,131,309,3733,",
        "level": "4",
        "name": "巴西马",
        "name_en": "Pasir Mas",
        "name_pinyin": "baxima",
        "code": "PMA",
        "childrens": []
      }, {
        "id": "3734",
        "pid": "309",
        "path": ",1,131,309,3734,",
        "level": "4",
        "name": "丹那美拉",
        "name_en": "Tanah Merah",
        "name_pinyin": "dannameila",
        "code": "TMR",
        "childrens": []
      }, {
        "id": "3735",
        "pid": "309",
        "path": ",1,131,309,3735,",
        "level": "4",
        "name": "道北",
        "name_en": "Tumpat",
        "name_pinyin": "daobei",
        "code": "TUM",
        "childrens": []
      }, {
        "id": "3736",
        "pid": "309",
        "path": ",1,131,309,3736,",
        "level": "4",
        "name": "登卓",
        "name_en": "Bachok",
        "name_pinyin": "dengzhuo",
        "code": "BAC",
        "childrens": []
      }, {
        "id": "3737",
        "pid": "309",
        "path": ",1,131,309,3737,",
        "level": "4",
        "name": "哥打巴鲁",
        "name_en": "Kota Bharu",
        "name_pinyin": "gedabalu",
        "code": "KBR",
        "childrens": []
      }, {
        "id": "3738",
        "pid": "309",
        "path": ",1,131,309,3738,",
        "level": "4",
        "name": "瓜拉吉赖",
        "name_en": "Kuala Krai",
        "name_pinyin": "gualajilai",
        "code": "KUG",
        "childrens": []
      }, {
        "id": "3739",
        "pid": "309",
        "path": ",1,131,309,3739,",
        "level": "4",
        "name": "话望生",
        "name_en": "Gua Musang",
        "name_pinyin": "huawangsheng",
        "code": "GMU",
        "childrens": []
      }, {
        "id": "3740",
        "pid": "309",
        "path": ",1,131,309,3740,",
        "level": "4",
        "name": "马樟",
        "name_en": "Machang",
        "name_pinyin": "mazhang",
        "code": "MAC",
        "childrens": []
      }, {
        "id": "3741",
        "pid": "309",
        "path": ",1,131,309,3741,",
        "level": "4",
        "name": "日里",
        "name_en": "Jeli",
        "name_pinyin": "rili",
        "code": "JEL",
        "childrens": []
      }]
    }, {
      "id": "310",
      "pid": "131",
      "path": ",1,131,310,",
      "level": "3",
      "name": "吉隆坡",
      "name_en": "Kuala Lumpur",
      "name_pinyin": "jilongpo",
      "code": "KL",
      "childrens": [{
        "id": "3742",
        "pid": "310",
        "path": ",1,131,310,3742,",
        "level": "4",
        "name": "吉隆坡",
        "name_en": "Kuala Lumpur",
        "name_pinyin": "jilongpo",
        "code": "KUL",
        "childrens": []
      }]
    }, {
      "id": "311",
      "pid": "131",
      "path": ",1,131,311,",
      "level": "3",
      "name": "马六甲",
      "name_en": "Malacca",
      "name_pinyin": "maliujia",
      "code": "ML",
      "childrens": [{
        "id": "3743",
        "pid": "311",
        "path": ",1,131,311,3743,",
        "level": "4",
        "name": "马六甲市",
        "name_en": "Melaka",
        "name_pinyin": "maliujiashi",
        "code": "MEL",
        "childrens": []
      }, {
        "id": "3744",
        "pid": "311",
        "path": ",1,131,311,3744,",
        "level": "4",
        "name": "亚罗牙也",
        "name_en": "Alor Gajah",
        "name_pinyin": "yaluoyaye",
        "code": "AOG",
        "childrens": []
      }, {
        "id": "3745",
        "pid": "311",
        "path": ",1,131,311,3745,",
        "level": "4",
        "name": "野新",
        "name_en": "Jasin",
        "name_pinyin": "yexin",
        "code": "JAS",
        "childrens": []
      }]
    }, {
      "id": "312",
      "pid": "131",
      "path": ",1,131,312,",
      "level": "3",
      "name": "纳闽",
      "name_en": "Labuan",
      "name_pinyin": "namin",
      "code": "LB",
      "childrens": [{
        "id": "3746",
        "pid": "312",
        "path": ",1,131,312,3746,",
        "level": "4",
        "name": "纳闽",
        "name_en": "Labuan",
        "name_pinyin": "namin",
        "code": "LBU",
        "childrens": []
      }, {
        "id": "3747",
        "pid": "312",
        "path": ",1,131,312,3747,",
        "level": "4",
        "name": "维多利亚",
        "name_en": "Victoria",
        "name_pinyin": "weiduoliya",
        "code": "VIC",
        "childrens": []
      }]
    }, {
      "id": "313",
      "pid": "131",
      "path": ",1,131,313,",
      "level": "3",
      "name": "彭亨",
      "name_en": "Pahang",
      "name_pinyin": "pengheng",
      "code": "PG",
      "childrens": [{
        "id": "3748",
        "pid": "313",
        "path": ",1,131,313,3748,",
        "level": "4",
        "name": "百乐",
        "name_en": "Bera",
        "name_pinyin": "baile",
        "code": "BER",
        "childrens": []
      }, {
        "id": "3749",
        "pid": "313",
        "path": ",1,131,313,3749,",
        "level": "4",
        "name": "北根",
        "name_en": "Pekan",
        "name_pinyin": "beigen",
        "code": "PEK",
        "childrens": []
      }, {
        "id": "3750",
        "pid": "313",
        "path": ",1,131,313,3750,",
        "level": "4",
        "name": "淡马鲁",
        "name_en": "Temerloh",
        "name_pinyin": "danmalu",
        "code": "TEM",
        "childrens": []
      }, {
        "id": "3751",
        "pid": "313",
        "path": ",1,131,313,3751,",
        "level": "4",
        "name": "而连突",
        "name_en": "Jerantut",
        "name_pinyin": "erliantu",
        "code": "JER",
        "childrens": []
      }, {
        "id": "3752",
        "pid": "313",
        "path": ",1,131,313,3752,",
        "level": "4",
        "name": "关丹",
        "name_en": "Kuantan",
        "name_pinyin": "guandan",
        "code": "KUA",
        "childrens": []
      }, {
        "id": "3753",
        "pid": "313",
        "path": ",1,131,313,3753,",
        "level": "4",
        "name": "金马仑高原",
        "name_en": "Cameron Highlands",
        "name_pinyin": "jinmalungaoyuan",
        "code": "CAH",
        "childrens": []
      }, {
        "id": "3754",
        "pid": "313",
        "path": ",1,131,313,3754,",
        "level": "4",
        "name": "劳勿",
        "name_en": "Raub",
        "name_pinyin": "laowu",
        "code": "RAU",
        "childrens": []
      }, {
        "id": "3755",
        "pid": "313",
        "path": ",1,131,313,3755,",
        "level": "4",
        "name": "立卑",
        "name_en": "Kuala Lipis",
        "name_pinyin": "libei",
        "code": "KUL",
        "childrens": []
      }, {
        "id": "3756",
        "pid": "313",
        "path": ",1,131,313,3756,",
        "level": "4",
        "name": "马兰",
        "name_en": "Maran",
        "name_pinyin": "malan",
        "code": "MAR",
        "childrens": []
      }, {
        "id": "3757",
        "pid": "313",
        "path": ",1,131,313,3757,",
        "level": "4",
        "name": "文冬",
        "name_en": "Bentong",
        "name_pinyin": "wendong",
        "code": "BEN",
        "childrens": []
      }, {
        "id": "3758",
        "pid": "313",
        "path": ",1,131,313,3758,",
        "level": "4",
        "name": "云冰",
        "name_en": "Rompin",
        "name_pinyin": "yunbing",
        "code": "TOM",
        "childrens": []
      }]
    }, {
      "id": "314",
      "pid": "131",
      "path": ",1,131,314,",
      "level": "3",
      "name": "霹雳",
      "name_en": "Perak",
      "name_pinyin": "pi",
      "code": "PK",
      "childrens": [{
        "id": "3759",
        "pid": "314",
        "path": ",1,131,314,3759,",
        "level": "4",
        "name": "安顺",
        "name_en": "Teluk Intan",
        "name_pinyin": "anshun",
        "code": "TAS",
        "childrens": []
      }, {
        "id": "3760",
        "pid": "314",
        "path": ",1,131,314,3760,",
        "level": "4",
        "name": "丹绒马",
        "name_en": "Tanjung Malim",
        "name_pinyin": "danrongma",
        "code": "TAM",
        "childrens": []
      }, {
        "id": "3761",
        "pid": "314",
        "path": ",1,131,314,3761,",
        "level": "4",
        "name": "和丰",
        "name_en": "Sungai Siput",
        "name_pinyin": "hefeng",
        "code": "SSP",
        "childrens": []
      }, {
        "id": "3762",
        "pid": "314",
        "path": ",1,131,314,3762,",
        "level": "4",
        "name": "紅土坎",
        "name_en": "Lumut",
        "name_pinyin": "",
        "code": "LUM",
        "childrens": []
      }, {
        "id": "3763",
        "pid": "314",
        "path": ",1,131,314,3763,",
        "level": "4",
        "name": "华都牙也",
        "name_en": "Batu Gajah",
        "name_pinyin": "huaduyaye",
        "code": "BGA",
        "childrens": []
      }, {
        "id": "3764",
        "pid": "314",
        "path": ",1,131,314,3764,",
        "level": "4",
        "name": "江沙",
        "name_en": "Kuala Kangsar",
        "name_pinyin": "jiangsha",
        "code": "KAR",
        "childrens": []
      }, {
        "id": "3765",
        "pid": "314",
        "path": ",1,131,314,3765,",
        "level": "4",
        "name": "太平",
        "name_en": "Taiping",
        "name_pinyin": "taiping",
        "code": "TPG",
        "childrens": []
      }, {
        "id": "3766",
        "pid": "314",
        "path": ",1,131,314,3766,",
        "level": "4",
        "name": "怡保",
        "name_en": "Ipoh",
        "name_pinyin": "bao",
        "code": "IPH",
        "childrens": []
      }]
    }, {
      "id": "315",
      "pid": "131",
      "path": ",1,131,315,",
      "level": "3",
      "name": "柔佛",
      "name_en": "Johor",
      "name_pinyin": "roufo",
      "code": "JH",
      "childrens": [{
        "id": "3767",
        "pid": "315",
        "path": ",1,131,315,3767,",
        "level": "4",
        "name": "笨珍",
        "name_en": "Pontian",
        "name_pinyin": "benzhen",
        "code": "POW",
        "childrens": []
      }, {
        "id": "3768",
        "pid": "315",
        "path": ",1,131,315,3768,",
        "level": "4",
        "name": "丰盛港",
        "name_en": "Mersing",
        "name_pinyin": "fengshenggang",
        "code": "MEP",
        "childrens": []
      }, {
        "id": "3769",
        "pid": "315",
        "path": ",1,131,315,3769,",
        "level": "4",
        "name": "哥打丁宜",
        "name_en": "Kota Tinggi",
        "name_pinyin": "gedadingyi",
        "code": "KTI",
        "childrens": []
      }, {
        "id": "3770",
        "pid": "315",
        "path": ",1,131,315,3770,",
        "level": "4",
        "name": "居銮",
        "name_en": "Kluang",
        "name_pinyin": "ju",
        "code": "KLA",
        "childrens": []
      }, {
        "id": "3771",
        "pid": "315",
        "path": ",1,131,315,3771,",
        "level": "4",
        "name": "峇株巴辖",
        "name_en": "Batu Pahat",
        "name_pinyin": "",
        "code": "BAT",
        "childrens": []
      }, {
        "id": "3772",
        "pid": "315",
        "path": ",1,131,315,3772,",
        "level": "4",
        "name": "麻坡",
        "name_en": "Muar",
        "name_pinyin": "mapo",
        "code": "MUA",
        "childrens": []
      }, {
        "id": "3773",
        "pid": "315",
        "path": ",1,131,315,3773,",
        "level": "4",
        "name": "昔加末",
        "name_en": "Segamat",
        "name_pinyin": "xijiamo",
        "code": "SGM",
        "childrens": []
      }, {
        "id": "3774",
        "pid": "315",
        "path": ",1,131,315,3774,",
        "level": "4",
        "name": "新山",
        "name_en": "Johor Bahru",
        "name_pinyin": "xinshan",
        "code": "JHB",
        "childrens": []
      }]
    }, {
      "id": "316",
      "pid": "131",
      "path": ",1,131,316,",
      "level": "3",
      "name": "森美兰",
      "name_en": "Negeri Sembilan",
      "name_pinyin": "senmeilan",
      "code": "NS",
      "childrens": [{
        "id": "3775",
        "pid": "316",
        "path": ",1,131,316,3775,",
        "level": "4",
        "name": "波德申",
        "name_en": "Port Dickson",
        "name_pinyin": "bodeshen",
        "code": "PDI",
        "childrens": []
      }, {
        "id": "3776",
        "pid": "316",
        "path": ",1,131,316,3776,",
        "level": "4",
        "name": "淡边",
        "name_en": "Tampin",
        "name_pinyin": "danbian",
        "code": "TAI",
        "childrens": []
      }, {
        "id": "3777",
        "pid": "316",
        "path": ",1,131,316,3777,",
        "level": "4",
        "name": "芙蓉",
        "name_en": "Seremban",
        "name_pinyin": "rong",
        "code": "SRB",
        "childrens": []
      }, {
        "id": "3778",
        "pid": "316",
        "path": ",1,131,316,3778,",
        "level": "4",
        "name": "瓜拉庇劳",
        "name_en": "Kuala Pilah",
        "name_pinyin": "gualabilao",
        "code": "KPI",
        "childrens": []
      }, {
        "id": "3779",
        "pid": "316",
        "path": ",1,131,316,3779,",
        "level": "4",
        "name": "林茂",
        "name_en": "Rembau",
        "name_pinyin": "linmao",
        "code": "REM",
        "childrens": []
      }, {
        "id": "3780",
        "pid": "316",
        "path": ",1,131,316,3780,",
        "level": "4",
        "name": "仁保",
        "name_en": "Jempol",
        "name_pinyin": "renbao",
        "code": "JEP",
        "childrens": []
      }, {
        "id": "3781",
        "pid": "316",
        "path": ",1,131,316,3781,",
        "level": "4",
        "name": "日叻务",
        "name_en": "Jelebu",
        "name_pinyin": "riwu",
        "code": "JEL",
        "childrens": []
      }]
    }, {
      "id": "317",
      "pid": "131",
      "path": ",1,131,317,",
      "level": "3",
      "name": "沙巴",
      "name_en": "Sabah",
      "name_pinyin": "shaba",
      "code": "SB",
      "childrens": [{
        "id": "3782",
        "pid": "317",
        "path": ",1,131,317,3782,",
        "level": "4",
        "name": "吧巴",
        "name_en": "Papar",
        "name_pinyin": "baba",
        "code": "PAP",
        "childrens": []
      }, {
        "id": "3783",
        "pid": "317",
        "path": ",1,131,317,3783,",
        "level": "4",
        "name": "保佛",
        "name_en": "Beaufort",
        "name_pinyin": "baofo",
        "code": "BEF",
        "childrens": []
      }, {
        "id": "3784",
        "pid": "317",
        "path": ",1,131,317,3784,",
        "level": "4",
        "name": "比鲁兰",
        "name_en": "Beluran",
        "name_pinyin": "bilulan",
        "code": "BEL",
        "childrens": []
      }, {
        "id": "3785",
        "pid": "317",
        "path": ",1,131,317,3785,",
        "level": "4",
        "name": "必达士",
        "name_en": "Pitas",
        "name_pinyin": "bidashi",
        "code": "PIT",
        "childrens": []
      }, {
        "id": "3786",
        "pid": "317",
        "path": ",1,131,317,3786,",
        "level": "4",
        "name": "兵南邦",
        "name_en": "Penampang",
        "name_pinyin": "bingnanbang",
        "code": "PMP",
        "childrens": []
      }, {
        "id": "3787",
        "pid": "317",
        "path": ",1,131,317,3787,",
        "level": "4",
        "name": "担布南",
        "name_en": "Tambunan",
        "name_pinyin": "danbunan",
        "code": "TAB",
        "childrens": []
      }, {
        "id": "3788",
        "pid": "317",
        "path": ",1,131,317,3788,",
        "level": "4",
        "name": "丹南",
        "name_en": "Tenom",
        "name_pinyin": "dannan",
        "code": "TEN",
        "childrens": []
      }, {
        "id": "3789",
        "pid": "317",
        "path": ",1,131,317,3789,",
        "level": "4",
        "name": "斗湖",
        "name_en": "Tawau",
        "name_pinyin": "douhu",
        "code": "TAW",
        "childrens": []
      }, {
        "id": "3790",
        "pid": "317",
        "path": ",1,131,317,3790,",
        "level": "4",
        "name": "斗亚兰",
        "name_en": "Tuaran",
        "name_pinyin": "douyalan",
        "code": "TUR",
        "childrens": []
      }, {
        "id": "3791",
        "pid": "317",
        "path": ",1,131,317,3791,",
        "level": "4",
        "name": "哥打基纳巴鲁",
        "name_en": "Kota Kinabalu",
        "name_pinyin": "gedajinabalu",
        "code": "BKI",
        "childrens": []
      }, {
        "id": "3792",
        "pid": "317",
        "path": ",1,131,317,3792,",
        "level": "4",
        "name": "哥打马鲁都",
        "name_en": "Kota Marudu",
        "name_pinyin": "gedamaludu",
        "code": "KMU",
        "childrens": []
      }, {
        "id": "3793",
        "pid": "317",
        "path": ",1,131,317,3793,",
        "level": "4",
        "name": "根地咬",
        "name_en": "Keningau",
        "name_pinyin": "gendiyao",
        "code": "KEG",
        "childrens": []
      }, {
        "id": "3794",
        "pid": "317",
        "path": ",1,131,317,3794,",
        "level": "4",
        "name": "古达",
        "name_en": "Kudat",
        "name_pinyin": "guda",
        "code": "KUD",
        "childrens": []
      }, {
        "id": "3795",
        "pid": "317",
        "path": ",1,131,317,3795,",
        "level": "4",
        "name": "古打毛律",
        "name_en": "Kota Belud",
        "name_pinyin": "gudamaolv",
        "code": "KBD",
        "childrens": []
      }, {
        "id": "3796",
        "pid": "317",
        "path": ",1,131,317,3796,",
        "level": "4",
        "name": "古纳",
        "name_en": "Kunak",
        "name_pinyin": "guna",
        "code": "KUN",
        "childrens": []
      }, {
        "id": "3797",
        "pid": "317",
        "path": ",1,131,317,3797,",
        "level": "4",
        "name": "瓜拉班尤",
        "name_en": "Kuala Penyu",
        "name_pinyin": "gualabanyou",
        "code": "KPU",
        "childrens": []
      }, {
        "id": "3798",
        "pid": "317",
        "path": ",1,131,317,3798,",
        "level": "4",
        "name": "京那巴登岸",
        "name_en": "Kinabatangan",
        "name_pinyin": "jingnabadengan",
        "code": "KBT",
        "childrens": []
      }, {
        "id": "3799",
        "pid": "317",
        "path": ",1,131,317,3799,",
        "level": "4",
        "name": "兰脑",
        "name_en": "Ranau",
        "name_pinyin": "lannao",
        "code": "RNU",
        "childrens": []
      }, {
        "id": "3800",
        "pid": "317",
        "path": ",1,131,317,3800,",
        "level": "4",
        "name": "拿笃",
        "name_en": "Lahad Datu",
        "name_pinyin": "na",
        "code": "LDU",
        "childrens": []
      }, {
        "id": "3801",
        "pid": "317",
        "path": ",1,131,317,3801,",
        "level": "4",
        "name": "纳巴湾",
        "name_en": "Nabawan",
        "name_pinyin": "nabawan",
        "code": "NAB",
        "childrens": []
      }, {
        "id": "3802",
        "pid": "317",
        "path": ",1,131,317,3802,",
        "level": "4",
        "name": "山打根",
        "name_en": "Sandakan",
        "name_pinyin": "shandagen",
        "code": "SDK",
        "childrens": []
      }, {
        "id": "3803",
        "pid": "317",
        "path": ",1,131,317,3803,",
        "level": "4",
        "name": "西比陶",
        "name_en": "Sipitang",
        "name_pinyin": "xibitao",
        "code": "SPT",
        "childrens": []
      }, {
        "id": "3804",
        "pid": "317",
        "path": ",1,131,317,3804,",
        "level": "4",
        "name": "仙本那",
        "name_en": "Semporna",
        "name_pinyin": "xianbenna",
        "code": "SMM",
        "childrens": []
      }]
    }, {
      "id": "318",
      "pid": "131",
      "path": ",1,131,318,",
      "level": "3",
      "name": "沙捞越",
      "name_en": "Sarawak",
      "name_pinyin": "shalaoyue",
      "code": "SR",
      "childrens": [{
        "id": "3805",
        "pid": "318",
        "path": ",1,131,318,3805,",
        "level": "4",
        "name": "古晋",
        "name_en": "Kuching",
        "name_pinyin": "gujin",
        "code": "KCH",
        "childrens": []
      }, {
        "id": "3806",
        "pid": "318",
        "path": ",1,131,318,3806,",
        "level": "4",
        "name": "加帛",
        "name_en": "Kapit",
        "name_pinyin": "jiabo",
        "code": "KPI",
        "childrens": []
      }, {
        "id": "3807",
        "pid": "318",
        "path": ",1,131,318,3807,",
        "level": "4",
        "name": "林梦",
        "name_en": "Limbang",
        "name_pinyin": "linmeng",
        "code": "LMN",
        "childrens": []
      }, {
        "id": "3808",
        "pid": "318",
        "path": ",1,131,318,3808,",
        "level": "4",
        "name": "美里",
        "name_en": "Miri",
        "name_pinyin": "meili",
        "code": "MYY",
        "childrens": []
      }, {
        "id": "3809",
        "pid": "318",
        "path": ",1,131,318,3809,",
        "level": "4",
        "name": "民都鲁",
        "name_en": "Bintulu",
        "name_pinyin": "mindulu",
        "code": "BTU",
        "childrens": []
      }, {
        "id": "3810",
        "pid": "318",
        "path": ",1,131,318,3810,",
        "level": "4",
        "name": "木胶",
        "name_en": "Mukah",
        "name_pinyin": "mujiao",
        "code": "MKM",
        "childrens": []
      }, {
        "id": "3811",
        "pid": "318",
        "path": ",1,131,318,3811,",
        "level": "4",
        "name": "木中",
        "name_en": "Betong",
        "name_pinyin": "muzhong",
        "code": "BTG",
        "childrens": []
      }, {
        "id": "3812",
        "pid": "318",
        "path": ",1,131,318,3812,",
        "level": "4",
        "name": "三马拉汉",
        "name_en": "Samarahan",
        "name_pinyin": "sanmalahan",
        "code": "SMH",
        "childrens": []
      }, {
        "id": "3813",
        "pid": "318",
        "path": ",1,131,318,3813,",
        "level": "4",
        "name": "斯里阿曼",
        "name_en": "Sri Aman",
        "name_pinyin": "siliaman",
        "code": "SAM",
        "childrens": []
      }, {
        "id": "3814",
        "pid": "318",
        "path": ",1,131,318,3814,",
        "level": "4",
        "name": "泗里街",
        "name_en": "Sarikei",
        "name_pinyin": "lijie",
        "code": "SAR",
        "childrens": []
      }, {
        "id": "3815",
        "pid": "318",
        "path": ",1,131,318,3815,",
        "level": "4",
        "name": "泗务",
        "name_en": "Sibu",
        "name_pinyin": "wu",
        "code": "SBW",
        "childrens": []
      }]
    }, {
      "id": "319",
      "pid": "131",
      "path": ",1,131,319,",
      "level": "3",
      "name": "雪兰莪",
      "name_en": "Selangor",
      "name_pinyin": "xuelan",
      "code": "SL",
      "childrens": [{
        "id": "3816",
        "pid": "319",
        "path": ",1,131,319,3816,",
        "level": "4",
        "name": "八打灵",
        "name_en": "Petaling",
        "name_pinyin": "badaling",
        "code": "PJA",
        "childrens": []
      }, {
        "id": "3817",
        "pid": "319",
        "path": ",1,131,319,3817,",
        "level": "4",
        "name": "鹅麦",
        "name_en": "Gombak",
        "name_pinyin": "emai",
        "code": "GOM",
        "childrens": []
      }, {
        "id": "3818",
        "pid": "319",
        "path": ",1,131,319,3818,",
        "level": "4",
        "name": "瓜拉冷岳",
        "name_en": "Kuala Langat",
        "name_pinyin": "gualalengyue",
        "code": "KLG",
        "childrens": []
      }, {
        "id": "3819",
        "pid": "319",
        "path": ",1,131,319,3819,",
        "level": "4",
        "name": "瓜拉雪兰莪",
        "name_en": "Kuala Selangor",
        "name_pinyin": "gualaxuelan",
        "code": "KSL",
        "childrens": []
      }, {
        "id": "3820",
        "pid": "319",
        "path": ",1,131,319,3820,",
        "level": "4",
        "name": "沙白安南",
        "name_en": "Sabak Bernam",
        "name_pinyin": "shabaiannan",
        "code": "SBM",
        "childrens": []
      }, {
        "id": "3821",
        "pid": "319",
        "path": ",1,131,319,3821,",
        "level": "4",
        "name": "乌鲁冷岳",
        "name_en": "Hulu Langat",
        "name_pinyin": "wululengyue",
        "code": "HUL",
        "childrens": []
      }, {
        "id": "3822",
        "pid": "319",
        "path": ",1,131,319,3822,",
        "level": "4",
        "name": "乌鲁雪兰莪",
        "name_en": "Hulu Selangor",
        "name_pinyin": "wuluxuelan",
        "code": "HUS",
        "childrens": []
      }, {
        "id": "3823",
        "pid": "319",
        "path": ",1,131,319,3823,",
        "level": "4",
        "name": "雪邦",
        "name_en": "Sepang",
        "name_pinyin": "xuebang",
        "code": "SEP",
        "childrens": []
      }]
    }]
  }, {
    "id": "143",
    "pid": "1",
    "path": ",1,143,",
    "level": "2",
    "name": "蒙古",
    "name_en": "Mongolia",
    "name_pinyin": "menggu",
    "code": "MNG",
    "childrens": [{
      "id": "1734",
      "pid": "143",
      "path": ",1,143,1734,",
      "level": "3",
      "name": "巴彦洪格尔",
      "name_en": "Bayanhongor",
      "name_pinyin": "bayanhonggeer",
      "code": "69",
      "childrens": []
    }, {
      "id": "1735",
      "pid": "143",
      "path": ",1,143,1735,",
      "level": "3",
      "name": "巴彦乌勒盖",
      "name_en": "Bayan-Ulgiy",
      "name_pinyin": "bayanwulegai",
      "code": "71",
      "childrens": []
    }, {
      "id": "1736",
      "pid": "143",
      "path": ",1,143,1736,",
      "level": "3",
      "name": "布尔干",
      "name_en": "Bulgan",
      "name_pinyin": "buergan",
      "code": "67",
      "childrens": []
    }, {
      "id": "1737",
      "pid": "143",
      "path": ",1,143,1737,",
      "level": "3",
      "name": "达尔汗乌勒",
      "name_en": "Darhan-Uul",
      "name_pinyin": "daerhanwule",
      "code": "37",
      "childrens": []
    }, {
      "id": "1738",
      "pid": "143",
      "path": ",1,143,1738,",
      "level": "3",
      "name": "东方",
      "name_en": "Dornod",
      "name_pinyin": "dongfang",
      "code": "61",
      "childrens": []
    }, {
      "id": "1739",
      "pid": "143",
      "path": ",1,143,1739,",
      "level": "3",
      "name": "东戈壁",
      "name_en": "Dornogovi",
      "name_pinyin": "donggebi",
      "code": "63",
      "childrens": []
    }, {
      "id": "1740",
      "pid": "143",
      "path": ",1,143,1740,",
      "level": "3",
      "name": "鄂尔浑",
      "name_en": "Orhon",
      "name_pinyin": "eerhun",
      "code": "35",
      "childrens": []
    }, {
      "id": "1741",
      "pid": "143",
      "path": ",1,143,1741,",
      "level": "3",
      "name": "戈壁阿尔泰",
      "name_en": "Govi-Altay",
      "name_pinyin": "gebiaertai",
      "code": "65",
      "childrens": []
    }, {
      "id": "1742",
      "pid": "143",
      "path": ",1,143,1742,",
      "level": "3",
      "name": "戈壁苏木贝尔",
      "name_en": "Govisumber",
      "name_pinyin": "gebisumubeier",
      "code": "64",
      "childrens": []
    }, {
      "id": "1743",
      "pid": "143",
      "path": ",1,143,1743,",
      "level": "3",
      "name": "后杭爱",
      "name_en": "Arhangay",
      "name_pinyin": "houhangai",
      "code": "73",
      "childrens": []
    }, {
      "id": "1744",
      "pid": "143",
      "path": ",1,143,1744,",
      "level": "3",
      "name": "科布多",
      "name_en": "Hovd",
      "name_pinyin": "kebuduo",
      "code": "43",
      "childrens": []
    }, {
      "id": "1745",
      "pid": "143",
      "path": ",1,143,1745,",
      "level": "3",
      "name": "肯特",
      "name_en": "Hentiy",
      "name_pinyin": "kente",
      "code": "39",
      "childrens": []
    }, {
      "id": "1746",
      "pid": "143",
      "path": ",1,143,1746,",
      "level": "3",
      "name": "库苏古尔",
      "name_en": "Hovsgol",
      "name_pinyin": "kusuguer",
      "code": "41",
      "childrens": []
    }, {
      "id": "1747",
      "pid": "143",
      "path": ",1,143,1747,",
      "level": "3",
      "name": "南戈壁",
      "name_en": "Umnogovi",
      "name_pinyin": "nangebi",
      "code": "UMN",
      "childrens": []
    }, {
      "id": "1748",
      "pid": "143",
      "path": ",1,143,1748,",
      "level": "3",
      "name": "前杭爱",
      "name_en": "Uvorhangay",
      "name_pinyin": "qianhangai",
      "code": "UVO",
      "childrens": []
    }, {
      "id": "1749",
      "pid": "143",
      "path": ",1,143,1749,",
      "level": "3",
      "name": "色楞格",
      "name_en": "Selenge",
      "name_pinyin": "selengge",
      "code": "49",
      "childrens": []
    }, {
      "id": "1750",
      "pid": "143",
      "path": ",1,143,1750,",
      "level": "3",
      "name": "苏赫巴托尔",
      "name_en": "Suhbaatar",
      "name_pinyin": "suhebatuoer",
      "code": "51",
      "childrens": []
    }, {
      "id": "1751",
      "pid": "143",
      "path": ",1,143,1751,",
      "level": "3",
      "name": "乌布苏",
      "name_en": "Uvs",
      "name_pinyin": "wubusu",
      "code": "46",
      "childrens": []
    }, {
      "id": "1752",
      "pid": "143",
      "path": ",1,143,1752,",
      "level": "3",
      "name": "乌兰巴托市",
      "name_en": "Ulaanbaatar hot",
      "name_pinyin": "wulanbatuoshi",
      "code": "1",
      "childrens": []
    }, {
      "id": "1753",
      "pid": "143",
      "path": ",1,143,1753,",
      "level": "3",
      "name": "扎布汗",
      "name_en": "Dzavhan",
      "name_pinyin": "zhabuhan",
      "code": "57",
      "childrens": []
    }, {
      "id": "1754",
      "pid": "143",
      "path": ",1,143,1754,",
      "level": "3",
      "name": "中戈壁",
      "name_en": "Dundgovi",
      "name_pinyin": "zhonggebi",
      "code": "59",
      "childrens": []
    }, {
      "id": "1755",
      "pid": "143",
      "path": ",1,143,1755,",
      "level": "3",
      "name": "中央",
      "name_en": "Tov",
      "name_pinyin": "zhongyang",
      "code": "47",
      "childrens": []
    }]
  }, {
    "id": "145",
    "pid": "1",
    "path": ",1,145,",
    "level": "2",
    "name": "孟加拉",
    "name_en": "Bangladesh",
    "name_pinyin": "mengjiala",
    "code": "BGD",
    "childrens": [{
      "id": "1756",
      "pid": "145",
      "path": ",1,145,1756,",
      "level": "3",
      "name": "达卡",
      "name_en": "Dhaka",
      "name_pinyin": "daka",
      "code": "DAC",
      "childrens": []
    }, {
      "id": "1757",
      "pid": "145",
      "path": ",1,145,1757,",
      "level": "3",
      "name": "吉大港",
      "name_en": "Chittagong",
      "name_pinyin": "jidagang",
      "code": "CGP",
      "childrens": []
    }, {
      "id": "1758",
      "pid": "145",
      "path": ",1,145,1758,",
      "level": "3",
      "name": "库尔纳",
      "name_en": "Khulna",
      "name_pinyin": "kuerna",
      "code": "KHL",
      "childrens": []
    }]
  }, {
    "id": "148",
    "pid": "1",
    "path": ",1,148,",
    "level": "2",
    "name": "缅甸",
    "name_en": "Myanmar",
    "name_pinyin": "miandian",
    "code": "MMR",
    "childrens": [{
      "id": "1788",
      "pid": "148",
      "path": ",1,148,1788,",
      "level": "3",
      "name": "勃固省",
      "name_en": "Bago",
      "name_pinyin": "bogusheng",
      "code": "BG",
      "childrens": []
    }, {
      "id": "1789",
      "pid": "148",
      "path": ",1,148,1789,",
      "level": "3",
      "name": "掸邦",
      "name_en": "Shan",
      "name_pinyin": "danbang",
      "code": "SH",
      "childrens": []
    }, {
      "id": "1790",
      "pid": "148",
      "path": ",1,148,1790,",
      "level": "3",
      "name": "德林达依省",
      "name_en": "Tanintharyi",
      "name_pinyin": "delindayisheng",
      "code": "TN",
      "childrens": []
    }, {
      "id": "1791",
      "pid": "148",
      "path": ",1,148,1791,",
      "level": "3",
      "name": "克伦邦",
      "name_en": "Kayin",
      "name_pinyin": "kelunbang",
      "code": "KN",
      "childrens": []
    }, {
      "id": "1792",
      "pid": "148",
      "path": ",1,148,1792,",
      "level": "3",
      "name": "克钦邦",
      "name_en": "Kachin",
      "name_pinyin": "keqinbang",
      "code": "KC",
      "childrens": []
    }, {
      "id": "1793",
      "pid": "148",
      "path": ",1,148,1793,",
      "level": "3",
      "name": "克耶邦",
      "name_en": "Kayah",
      "name_pinyin": "keyebang",
      "code": "KH",
      "childrens": []
    }, {
      "id": "1794",
      "pid": "148",
      "path": ",1,148,1794,",
      "level": "3",
      "name": "马圭省",
      "name_en": "Magway",
      "name_pinyin": "maguisheng",
      "code": "MG",
      "childrens": []
    }, {
      "id": "1795",
      "pid": "148",
      "path": ",1,148,1795,",
      "level": "3",
      "name": "曼德勒省",
      "name_en": "Mandalay",
      "name_pinyin": "mandelesheng",
      "code": "MD",
      "childrens": []
    }, {
      "id": "1796",
      "pid": "148",
      "path": ",1,148,1796,",
      "level": "3",
      "name": "孟邦",
      "name_en": "Mon",
      "name_pinyin": "mengbang",
      "code": "MN",
      "childrens": []
    }, {
      "id": "1797",
      "pid": "148",
      "path": ",1,148,1797,",
      "level": "3",
      "name": "钦邦",
      "name_en": "Chin",
      "name_pinyin": "qinbang",
      "code": "CH",
      "childrens": []
    }, {
      "id": "1798",
      "pid": "148",
      "path": ",1,148,1798,",
      "level": "3",
      "name": "若开邦",
      "name_en": "Rakhine",
      "name_pinyin": "ruokaibang",
      "code": "RK",
      "childrens": []
    }, {
      "id": "1799",
      "pid": "148",
      "path": ",1,148,1799,",
      "level": "3",
      "name": "实皆省",
      "name_en": "Sagaing",
      "name_pinyin": "shijiesheng",
      "code": "SG",
      "childrens": []
    }, {
      "id": "1800",
      "pid": "148",
      "path": ",1,148,1800,",
      "level": "3",
      "name": "仰光省",
      "name_en": "Yangon",
      "name_pinyin": "yangguangsheng",
      "code": "YG",
      "childrens": []
    }, {
      "id": "1801",
      "pid": "148",
      "path": ",1,148,1801,",
      "level": "3",
      "name": "伊洛瓦底省",
      "name_en": "Ayeyarwady",
      "name_pinyin": "yiluowadisheng",
      "code": "AY",
      "childrens": []
    }]
  }, {
    "id": "159",
    "pid": "1",
    "path": ",1,159,",
    "level": "2",
    "name": "尼泊尔",
    "name_en": "Nepal",
    "name_pinyin": "niboer",
    "code": "NPL",
    "childrens": [{
      "id": "1934",
      "pid": "159",
      "path": ",1,159,1934,",
      "level": "3",
      "name": "巴格马蒂",
      "name_en": "Bagmati",
      "name_pinyin": "bagemadi",
      "code": "BA",
      "childrens": []
    }, {
      "id": "1935",
      "pid": "159",
      "path": ",1,159,1935,",
      "level": "3",
      "name": "道拉吉里",
      "name_en": "Dhawalagiri",
      "name_pinyin": "daolajili",
      "code": "DH",
      "childrens": []
    }, {
      "id": "1936",
      "pid": "159",
      "path": ",1,159,1936,",
      "level": "3",
      "name": "甘达基",
      "name_en": "Gandaki",
      "name_pinyin": "gandaji",
      "code": "GA",
      "childrens": []
    }, {
      "id": "1937",
      "pid": "159",
      "path": ",1,159,1937,",
      "level": "3",
      "name": "戈西",
      "name_en": "Kosi",
      "name_pinyin": "gexi",
      "code": "KO",
      "childrens": []
    }, {
      "id": "1938",
      "pid": "159",
      "path": ",1,159,1938,",
      "level": "3",
      "name": "格尔纳利",
      "name_en": "Karnali",
      "name_pinyin": "geernali",
      "code": "KA",
      "childrens": []
    }, {
      "id": "1939",
      "pid": "159",
      "path": ",1,159,1939,",
      "level": "3",
      "name": "贾纳克布尔",
      "name_en": "Janakpur",
      "name_pinyin": "jianakebuer",
      "code": "JA",
      "childrens": []
    }, {
      "id": "1940",
      "pid": "159",
      "path": ",1,159,1940,",
      "level": "3",
      "name": "拉布蒂",
      "name_en": "Rapti",
      "name_pinyin": "labudi",
      "code": "RA",
      "childrens": []
    }, {
      "id": "1941",
      "pid": "159",
      "path": ",1,159,1941,",
      "level": "3",
      "name": "蓝毗尼",
      "name_en": "Lumbini",
      "name_pinyin": "lanpini",
      "code": "LU",
      "childrens": []
    }, {
      "id": "1942",
      "pid": "159",
      "path": ",1,159,1942,",
      "level": "3",
      "name": "马哈卡利",
      "name_en": "Mahakali",
      "name_pinyin": "mahakali",
      "code": "MA",
      "childrens": []
    }, {
      "id": "1943",
      "pid": "159",
      "path": ",1,159,1943,",
      "level": "3",
      "name": "梅吉",
      "name_en": "Mechi",
      "name_pinyin": "meiji",
      "code": "ME",
      "childrens": []
    }, {
      "id": "1944",
      "pid": "159",
      "path": ",1,159,1944,",
      "level": "3",
      "name": "纳拉亚尼",
      "name_en": "Narayani",
      "name_pinyin": "nalayani",
      "code": "NA",
      "childrens": []
    }, {
      "id": "1945",
      "pid": "159",
      "path": ",1,159,1945,",
      "level": "3",
      "name": "佩里",
      "name_en": "Bheri",
      "name_pinyin": "peili",
      "code": "BH",
      "childrens": []
    }, {
      "id": "1946",
      "pid": "159",
      "path": ",1,159,1946,",
      "level": "3",
      "name": "萨加玛塔",
      "name_en": "Sogarmatha",
      "name_pinyin": "sajiamata",
      "code": "SA",
      "childrens": []
    }, {
      "id": "1947",
      "pid": "159",
      "path": ",1,159,1947,",
      "level": "3",
      "name": "塞蒂",
      "name_en": "Seti",
      "name_pinyin": "saidi",
      "code": "SE",
      "childrens": []
    }]
  }, {
    "id": "170",
    "pid": "1",
    "path": ",1,170,",
    "level": "2",
    "name": "日本",
    "name_en": "Japan",
    "name_pinyin": "riben",
    "code": "JPN",
    "childrens": [{
      "id": "2026",
      "pid": "170",
      "path": ",1,170,2026,",
      "level": "3",
      "name": "爱媛",
      "name_en": "Ehime",
      "name_pinyin": "ai",
      "code": "38",
      "childrens": []
    }, {
      "id": "2027",
      "pid": "170",
      "path": ",1,170,2027,",
      "level": "3",
      "name": "爱知",
      "name_en": "Aichi",
      "name_pinyin": "aizhi",
      "code": "23",
      "childrens": []
    }, {
      "id": "2028",
      "pid": "170",
      "path": ",1,170,2028,",
      "level": "3",
      "name": "北海道",
      "name_en": "Hokkaido",
      "name_pinyin": "beihaidao",
      "code": "1",
      "childrens": []
    }, {
      "id": "2029",
      "pid": "170",
      "path": ",1,170,2029,",
      "level": "3",
      "name": "兵库",
      "name_en": "Hyogo",
      "name_pinyin": "bingku",
      "code": "28",
      "childrens": []
    }, {
      "id": "2030",
      "pid": "170",
      "path": ",1,170,2030,",
      "level": "3",
      "name": "冲绳",
      "name_en": "Okinawa",
      "name_pinyin": "chongsheng",
      "code": "47",
      "childrens": []
    }, {
      "id": "2031",
      "pid": "170",
      "path": ",1,170,2031,",
      "level": "3",
      "name": "茨城",
      "name_en": "Ibaraki",
      "name_pinyin": "cicheng",
      "code": "8",
      "childrens": []
    }, {
      "id": "2032",
      "pid": "170",
      "path": ",1,170,2032,",
      "level": "3",
      "name": "大阪",
      "name_en": "Osaka",
      "name_pinyin": "da",
      "code": "27",
      "childrens": []
    }, {
      "id": "2033",
      "pid": "170",
      "path": ",1,170,2033,",
      "level": "3",
      "name": "大分",
      "name_en": "Oita",
      "name_pinyin": "dafen",
      "code": "44",
      "childrens": []
    }, {
      "id": "2034",
      "pid": "170",
      "path": ",1,170,2034,",
      "level": "3",
      "name": "岛根",
      "name_en": "Shimane",
      "name_pinyin": "daogen",
      "code": "32",
      "childrens": []
    }, {
      "id": "2035",
      "pid": "170",
      "path": ",1,170,2035,",
      "level": "3",
      "name": "徳岛",
      "name_en": "Tokushima",
      "name_pinyin": "",
      "code": "36",
      "childrens": []
    }, {
      "id": "2036",
      "pid": "170",
      "path": ",1,170,2036,",
      "level": "3",
      "name": "东京",
      "name_en": "Tokyo",
      "name_pinyin": "dongjing",
      "code": "13",
      "childrens": []
    }, {
      "id": "2037",
      "pid": "170",
      "path": ",1,170,2037,",
      "level": "3",
      "name": "福岛",
      "name_en": "Fukushima",
      "name_pinyin": "fudao",
      "code": "7",
      "childrens": []
    }, {
      "id": "2038",
      "pid": "170",
      "path": ",1,170,2038,",
      "level": "3",
      "name": "福冈",
      "name_en": "Fukuoka",
      "name_pinyin": "fugang",
      "code": "40",
      "childrens": []
    }, {
      "id": "2039",
      "pid": "170",
      "path": ",1,170,2039,",
      "level": "3",
      "name": "福井",
      "name_en": "Fukui",
      "name_pinyin": "fujing",
      "code": "18",
      "childrens": []
    }, {
      "id": "2040",
      "pid": "170",
      "path": ",1,170,2040,",
      "level": "3",
      "name": "富山",
      "name_en": "Toyama",
      "name_pinyin": "fushan",
      "code": "16",
      "childrens": []
    }, {
      "id": "2041",
      "pid": "170",
      "path": ",1,170,2041,",
      "level": "3",
      "name": "冈山",
      "name_en": "Okayama",
      "name_pinyin": "gangshan",
      "code": "33",
      "childrens": []
    }, {
      "id": "2042",
      "pid": "170",
      "path": ",1,170,2042,",
      "level": "3",
      "name": "高知",
      "name_en": "Kochi",
      "name_pinyin": "gaozhi",
      "code": "39",
      "childrens": []
    }, {
      "id": "2043",
      "pid": "170",
      "path": ",1,170,2043,",
      "level": "3",
      "name": "宮城",
      "name_en": "Miyagi",
      "name_pinyin": "",
      "code": "4",
      "childrens": []
    }, {
      "id": "2044",
      "pid": "170",
      "path": ",1,170,2044,",
      "level": "3",
      "name": "宫崎",
      "name_en": "Miyazaki",
      "name_pinyin": "gongqi",
      "code": "45",
      "childrens": []
    }, {
      "id": "2045",
      "pid": "170",
      "path": ",1,170,2045,",
      "level": "3",
      "name": "广岛",
      "name_en": "Hiroshima",
      "name_pinyin": "guangdao",
      "code": "34",
      "childrens": []
    }, {
      "id": "2046",
      "pid": "170",
      "path": ",1,170,2046,",
      "level": "3",
      "name": "和歌山",
      "name_en": "Wakayama",
      "name_pinyin": "hegeshan",
      "code": "30",
      "childrens": []
    }, {
      "id": "2047",
      "pid": "170",
      "path": ",1,170,2047,",
      "level": "3",
      "name": "京都",
      "name_en": "Kyoto",
      "name_pinyin": "jingdu",
      "code": "26",
      "childrens": []
    }, {
      "id": "2048",
      "pid": "170",
      "path": ",1,170,2048,",
      "level": "3",
      "name": "静冈",
      "name_en": "Shizuoka",
      "name_pinyin": "jinggang",
      "code": "22",
      "childrens": []
    }, {
      "id": "2049",
      "pid": "170",
      "path": ",1,170,2049,",
      "level": "3",
      "name": "枥木",
      "name_en": "Tochigi",
      "name_pinyin": "mu",
      "code": "9",
      "childrens": []
    }, {
      "id": "2050",
      "pid": "170",
      "path": ",1,170,2050,",
      "level": "3",
      "name": "鹿儿岛",
      "name_en": "Kagoshima",
      "name_pinyin": "luerdao",
      "code": "46",
      "childrens": []
    }, {
      "id": "2051",
      "pid": "170",
      "path": ",1,170,2051,",
      "level": "3",
      "name": "奈良",
      "name_en": "Nara",
      "name_pinyin": "nailiang",
      "code": "29",
      "childrens": []
    }, {
      "id": "2052",
      "pid": "170",
      "path": ",1,170,2052,",
      "level": "3",
      "name": "鸟取",
      "name_en": "Tottori",
      "name_pinyin": "niaoqu",
      "code": "31",
      "childrens": []
    }, {
      "id": "2053",
      "pid": "170",
      "path": ",1,170,2053,",
      "level": "3",
      "name": "岐阜",
      "name_en": "Gifu",
      "name_pinyin": "fu",
      "code": "21",
      "childrens": []
    }, {
      "id": "2054",
      "pid": "170",
      "path": ",1,170,2054,",
      "level": "3",
      "name": "埼玉",
      "name_en": "Saitama",
      "name_pinyin": "",
      "code": "11",
      "childrens": []
    }, {
      "id": "2055",
      "pid": "170",
      "path": ",1,170,2055,",
      "level": "3",
      "name": "千叶",
      "name_en": "Chiba",
      "name_pinyin": "qianye",
      "code": "12",
      "childrens": []
    }, {
      "id": "2056",
      "pid": "170",
      "path": ",1,170,2056,",
      "level": "3",
      "name": "青森",
      "name_en": "Aomori",
      "name_pinyin": "qingsen",
      "code": "2",
      "childrens": []
    }, {
      "id": "2057",
      "pid": "170",
      "path": ",1,170,2057,",
      "level": "3",
      "name": "秋田",
      "name_en": "Akita",
      "name_pinyin": "qiutian",
      "code": "5",
      "childrens": []
    }, {
      "id": "2058",
      "pid": "170",
      "path": ",1,170,2058,",
      "level": "3",
      "name": "群马",
      "name_en": "Gunma",
      "name_pinyin": "qunma",
      "code": "10",
      "childrens": []
    }, {
      "id": "2059",
      "pid": "170",
      "path": ",1,170,2059,",
      "level": "3",
      "name": "三重",
      "name_en": "Mie",
      "name_pinyin": "sanzhong",
      "code": "24",
      "childrens": []
    }, {
      "id": "2060",
      "pid": "170",
      "path": ",1,170,2060,",
      "level": "3",
      "name": "山口",
      "name_en": "Yamaguchi",
      "name_pinyin": "shankou",
      "code": "35",
      "childrens": []
    }, {
      "id": "2061",
      "pid": "170",
      "path": ",1,170,2061,",
      "level": "3",
      "name": "山梨",
      "name_en": "Yamanashi",
      "name_pinyin": "shanli",
      "code": "19",
      "childrens": []
    }, {
      "id": "2062",
      "pid": "170",
      "path": ",1,170,2062,",
      "level": "3",
      "name": "山形",
      "name_en": "Yamagata",
      "name_pinyin": "shanxing",
      "code": "6",
      "childrens": []
    }, {
      "id": "2063",
      "pid": "170",
      "path": ",1,170,2063,",
      "level": "3",
      "name": "神奈川",
      "name_en": "Kanagawa",
      "name_pinyin": "shennaichuan",
      "code": "14",
      "childrens": []
    }, {
      "id": "2064",
      "pid": "170",
      "path": ",1,170,2064,",
      "level": "3",
      "name": "石川",
      "name_en": "Ishikawa",
      "name_pinyin": "shichuan",
      "code": "17",
      "childrens": []
    }, {
      "id": "2065",
      "pid": "170",
      "path": ",1,170,2065,",
      "level": "3",
      "name": "香川",
      "name_en": "Kagawa",
      "name_pinyin": "xiangchuan",
      "code": "37",
      "childrens": []
    }, {
      "id": "2066",
      "pid": "170",
      "path": ",1,170,2066,",
      "level": "3",
      "name": "新潟",
      "name_en": "Niigata",
      "name_pinyin": "xin",
      "code": "15",
      "childrens": []
    }, {
      "id": "2067",
      "pid": "170",
      "path": ",1,170,2067,",
      "level": "3",
      "name": "熊本",
      "name_en": "Kumamoto",
      "name_pinyin": "xiongben",
      "code": "43",
      "childrens": []
    }, {
      "id": "2068",
      "pid": "170",
      "path": ",1,170,2068,",
      "level": "3",
      "name": "岩手",
      "name_en": "Iwate",
      "name_pinyin": "yanshou",
      "code": "3",
      "childrens": []
    }, {
      "id": "2069",
      "pid": "170",
      "path": ",1,170,2069,",
      "level": "3",
      "name": "长崎",
      "name_en": "Nagasaki",
      "name_pinyin": "changqi",
      "code": "42",
      "childrens": []
    }, {
      "id": "2070",
      "pid": "170",
      "path": ",1,170,2070,",
      "level": "3",
      "name": "长野",
      "name_en": "Nagano",
      "name_pinyin": "changye",
      "code": "20",
      "childrens": []
    }, {
      "id": "2071",
      "pid": "170",
      "path": ",1,170,2071,",
      "level": "3",
      "name": "滋贺",
      "name_en": "Shiga",
      "name_pinyin": "zihe",
      "code": "25",
      "childrens": []
    }, {
      "id": "2072",
      "pid": "170",
      "path": ",1,170,2072,",
      "level": "3",
      "name": "佐贺",
      "name_en": "Saga",
      "name_pinyin": "zuohe",
      "code": "41",
      "childrens": []
    }]
  }, {
    "id": "178",
    "pid": "1",
    "path": ",1,178,",
    "level": "2",
    "name": "塞浦路斯",
    "name_en": "Cyprus",
    "name_pinyin": "saipulusi",
    "code": "CYP",
    "childrens": [{
      "id": "2167",
      "pid": "178",
      "path": ",1,178,2167,",
      "level": "3",
      "name": "法马古斯塔",
      "name_en": "Famagusta",
      "name_pinyin": "famagusita",
      "code": "4",
      "childrens": []
    }, {
      "id": "2168",
      "pid": "178",
      "path": ",1,178,2168,",
      "level": "3",
      "name": "凯里尼亚",
      "name_en": "Kyrenia",
      "name_pinyin": "kailiniya",
      "code": "6",
      "childrens": []
    }, {
      "id": "2169",
      "pid": "178",
      "path": ",1,178,2169,",
      "level": "3",
      "name": "拉纳卡",
      "name_en": "Larnaca",
      "name_pinyin": "lanaka",
      "code": "3",
      "childrens": []
    }, {
      "id": "2170",
      "pid": "178",
      "path": ",1,178,2170,",
      "level": "3",
      "name": "利马索尔",
      "name_en": "Limassol",
      "name_pinyin": "limasuoer",
      "code": "2",
      "childrens": []
    }, {
      "id": "2171",
      "pid": "178",
      "path": ",1,178,2171,",
      "level": "3",
      "name": "尼科西亚",
      "name_en": "Nicosia",
      "name_pinyin": "nikexiya",
      "code": "1",
      "childrens": []
    }, {
      "id": "2172",
      "pid": "178",
      "path": ",1,178,2172,",
      "level": "3",
      "name": "帕福斯",
      "name_en": "Pafos",
      "name_pinyin": "pafusi",
      "code": "5",
      "childrens": []
    }]
  }, {
    "id": "180",
    "pid": "1",
    "path": ",1,180,",
    "level": "2",
    "name": "沙特阿拉伯",
    "name_en": "Saudi Arabia",
    "name_pinyin": "shatealabo",
    "code": "SAU",
    "childrens": [{
      "id": "2173",
      "pid": "180",
      "path": ",1,180,2173,",
      "level": "3",
      "name": "阿尔阿尔",
      "name_en": "Arar",
      "name_pinyin": "aeraer",
      "code": "ARA",
      "childrens": []
    }, {
      "id": "2174",
      "pid": "180",
      "path": ",1,180,2174,",
      "level": "3",
      "name": "艾卜哈",
      "name_en": "Abha",
      "name_pinyin": "aiboha",
      "code": "AHB",
      "childrens": []
    }, {
      "id": "2175",
      "pid": "180",
      "path": ",1,180,2175,",
      "level": "3",
      "name": "巴哈",
      "name_en": "Al Bahah",
      "name_pinyin": "baha",
      "code": "BH",
      "childrens": []
    }, {
      "id": "2176",
      "pid": "180",
      "path": ",1,180,2176,",
      "level": "3",
      "name": "布赖代",
      "name_en": "Buraydah",
      "name_pinyin": "bulaidai",
      "code": "BUR",
      "childrens": []
    }, {
      "id": "2177",
      "pid": "180",
      "path": ",1,180,2177,",
      "level": "3",
      "name": "达曼",
      "name_en": "Dammam",
      "name_pinyin": "daman",
      "code": "DAM",
      "childrens": []
    }, {
      "id": "2178",
      "pid": "180",
      "path": ",1,180,2178,",
      "level": "3",
      "name": "哈费尔巴廷",
      "name_en": "Hafar al-Batin",
      "name_pinyin": "hafeierbating",
      "code": "HBT",
      "childrens": []
    }, {
      "id": "2179",
      "pid": "180",
      "path": ",1,180,2179,",
      "level": "3",
      "name": "哈伊勒",
      "name_en": "Hail",
      "name_pinyin": "hayile",
      "code": "HL",
      "childrens": []
    }, {
      "id": "2180",
      "pid": "180",
      "path": ",1,180,2180,",
      "level": "3",
      "name": "海米斯穆谢特",
      "name_en": "Khamis Mushayt",
      "name_pinyin": "haimisimuxiete",
      "code": "KMX",
      "childrens": []
    }, {
      "id": "2181",
      "pid": "180",
      "path": ",1,180,2181,",
      "level": "3",
      "name": "海耶",
      "name_en": "Al-Kharj",
      "name_pinyin": "haiye",
      "code": "AKH",
      "childrens": []
    }, {
      "id": "2182",
      "pid": "180",
      "path": ",1,180,2182,",
      "level": "3",
      "name": "胡富夫",
      "name_en": "Al-Hufuf",
      "name_pinyin": "hufufu",
      "code": "HFF",
      "childrens": []
    }, {
      "id": "2183",
      "pid": "180",
      "path": ",1,180,2183,",
      "level": "3",
      "name": "吉达",
      "name_en": "Jiddah",
      "name_pinyin": "jida",
      "code": "JED",
      "childrens": []
    }, {
      "id": "2184",
      "pid": "180",
      "path": ",1,180,2184,",
      "level": "3",
      "name": "吉赞",
      "name_en": "Jizan",
      "name_pinyin": "jizan",
      "code": "JZ",
      "childrens": []
    }, {
      "id": "2185",
      "pid": "180",
      "path": ",1,180,2185,",
      "level": "3",
      "name": "利雅得",
      "name_en": "Riyad",
      "name_pinyin": "liyade",
      "code": "RD",
      "childrens": []
    }, {
      "id": "2186",
      "pid": "180",
      "path": ",1,180,2186,",
      "level": "3",
      "name": "麦地那",
      "name_en": "Medina",
      "name_pinyin": "maidina",
      "code": "MED",
      "childrens": []
    }, {
      "id": "2187",
      "pid": "180",
      "path": ",1,180,2187,",
      "level": "3",
      "name": "麦加",
      "name_en": "Makkah",
      "name_pinyin": "maijia",
      "code": "ML",
      "childrens": []
    }, {
      "id": "2188",
      "pid": "180",
      "path": ",1,180,2188,",
      "level": "3",
      "name": "姆巴拉兹",
      "name_en": "Al-Mubarraz",
      "name_pinyin": "mubalazi",
      "code": "MBR",
      "childrens": []
    }, {
      "id": "2189",
      "pid": "180",
      "path": ",1,180,2189,",
      "level": "3",
      "name": "纳季兰",
      "name_en": "Najran",
      "name_pinyin": "najilan",
      "code": "NR",
      "childrens": []
    }, {
      "id": "2190",
      "pid": "180",
      "path": ",1,180,2190,",
      "level": "3",
      "name": "塞卡卡",
      "name_en": "Sakaka",
      "name_pinyin": "saikaka",
      "code": "SAK",
      "childrens": []
    }, {
      "id": "2191",
      "pid": "180",
      "path": ",1,180,2191,",
      "level": "3",
      "name": "塔布克",
      "name_en": "Tabuk",
      "name_pinyin": "tabuke",
      "code": "TB",
      "childrens": []
    }, {
      "id": "2192",
      "pid": "180",
      "path": ",1,180,2192,",
      "level": "3",
      "name": "塔伊夫",
      "name_en": "At Tarif",
      "name_pinyin": "tayifu",
      "code": "TAR",
      "childrens": []
    }, {
      "id": "2193",
      "pid": "180",
      "path": ",1,180,2193,",
      "level": "3",
      "name": "延布",
      "name_en": "Yanbu al-Bahr",
      "name_pinyin": "yanbu",
      "code": "YNB",
      "childrens": []
    }, {
      "id": "2194",
      "pid": "180",
      "path": ",1,180,2194,",
      "level": "3",
      "name": "朱拜勒",
      "name_en": "Al-Jubayl",
      "name_pinyin": "zhubaile",
      "code": "JBI",
      "childrens": []
    }]
  }, {
    "id": "189",
    "pid": "1",
    "path": ",1,189,",
    "level": "2",
    "name": "斯里兰卡",
    "name_en": "Sri Lanka",
    "name_pinyin": "sililanka",
    "code": "LKA",
    "childrens": [{
      "id": "2195",
      "pid": "189",
      "path": ",1,189,2195,",
      "level": "3",
      "name": "阿努拉德普勒",
      "name_en": "Anuradhapura",
      "name_pinyin": "anuladepule",
      "code": "ADP",
      "childrens": []
    }, {
      "id": "2196",
      "pid": "189",
      "path": ",1,189,2196,",
      "level": "3",
      "name": "安帕赖",
      "name_en": "Ampara",
      "name_pinyin": "anpalai",
      "code": "AMP",
      "childrens": []
    }, {
      "id": "2197",
      "pid": "189",
      "path": ",1,189,2197,",
      "level": "3",
      "name": "巴杜勒",
      "name_en": "Badulla",
      "name_pinyin": "badule",
      "code": "BAD",
      "childrens": []
    }, {
      "id": "2198",
      "pid": "189",
      "path": ",1,189,2198,",
      "level": "3",
      "name": "拜蒂克洛",
      "name_en": "Batticaloa",
      "name_pinyin": "baidikeluo",
      "code": "BTC",
      "childrens": []
    }, {
      "id": "2199",
      "pid": "189",
      "path": ",1,189,2199,",
      "level": "3",
      "name": "波隆纳鲁沃",
      "name_en": "Polonnaruwa",
      "name_pinyin": "bolongnaluwo",
      "code": "POL",
      "childrens": []
    }, {
      "id": "2200",
      "pid": "189",
      "path": ",1,189,2200,",
      "level": "3",
      "name": "汉班托特",
      "name_en": "Hambantota",
      "name_pinyin": "hanbantuote",
      "code": "HBA",
      "childrens": []
    }, {
      "id": "2201",
      "pid": "189",
      "path": ",1,189,2201,",
      "level": "3",
      "name": "基里诺奇",
      "name_en": "Kilinochchi",
      "name_pinyin": "jilinuoqi",
      "code": "KIL",
      "childrens": []
    }, {
      "id": "2202",
      "pid": "189",
      "path": ",1,189,2202,",
      "level": "3",
      "name": "加勒",
      "name_en": "Galle",
      "name_pinyin": "jiale",
      "code": "GAL",
      "childrens": []
    }, {
      "id": "2203",
      "pid": "189",
      "path": ",1,189,2203,",
      "level": "3",
      "name": "加姆珀哈",
      "name_en": "Gampaha",
      "name_pinyin": "jiamuha",
      "code": "GAM",
      "childrens": []
    }, {
      "id": "2204",
      "pid": "189",
      "path": ",1,189,2204,",
      "level": "3",
      "name": "贾夫纳",
      "name_en": "Jaffna",
      "name_pinyin": "jiafuna",
      "code": "JAF",
      "childrens": []
    }, {
      "id": "2205",
      "pid": "189",
      "path": ",1,189,2205,",
      "level": "3",
      "name": "卡卢特勒",
      "name_en": "Kalutara",
      "name_pinyin": "kalutele",
      "code": "KLT",
      "childrens": []
    }, {
      "id": "2206",
      "pid": "189",
      "path": ",1,189,2206,",
      "level": "3",
      "name": "凯格勒",
      "name_en": "Kegalle",
      "name_pinyin": "kaigele",
      "code": "KEG",
      "childrens": []
    }, {
      "id": "2207",
      "pid": "189",
      "path": ",1,189,2207,",
      "level": "3",
      "name": "康提",
      "name_en": "Kandy",
      "name_pinyin": "kangti",
      "code": "KAN",
      "childrens": []
    }, {
      "id": "2208",
      "pid": "189",
      "path": ",1,189,2208,",
      "level": "3",
      "name": "科伦坡",
      "name_en": "Colombo",
      "name_pinyin": "kelunpo",
      "code": "CMB",
      "childrens": []
    }, {
      "id": "2209",
      "pid": "189",
      "path": ",1,189,2209,",
      "level": "3",
      "name": "库鲁内格勒",
      "name_en": "Kurunegala",
      "name_pinyin": "kuluneigele",
      "code": "KUR",
      "childrens": []
    }, {
      "id": "2210",
      "pid": "189",
      "path": ",1,189,2210,",
      "level": "3",
      "name": "拉特纳普勒",
      "name_en": "Ratnapura",
      "name_pinyin": "latenapule",
      "code": "RAT",
      "childrens": []
    }, {
      "id": "2211",
      "pid": "189",
      "path": ",1,189,2211,",
      "level": "3",
      "name": "马纳尔",
      "name_en": "Mannar",
      "name_pinyin": "manaer",
      "code": "MAN",
      "childrens": []
    }, {
      "id": "2212",
      "pid": "189",
      "path": ",1,189,2212,",
      "level": "3",
      "name": "马特莱",
      "name_en": "Matale",
      "name_pinyin": "matelai",
      "code": "MAT",
      "childrens": []
    }, {
      "id": "2213",
      "pid": "189",
      "path": ",1,189,2213,",
      "level": "3",
      "name": "马特勒",
      "name_en": "Matara",
      "name_pinyin": "matele",
      "code": "MAA",
      "childrens": []
    }, {
      "id": "2214",
      "pid": "189",
      "path": ",1,189,2214,",
      "level": "3",
      "name": "莫讷勒格勒",
      "name_en": "Monaragala",
      "name_pinyin": "molegele",
      "code": "MON",
      "childrens": []
    }, {
      "id": "2215",
      "pid": "189",
      "path": ",1,189,2215,",
      "level": "3",
      "name": "穆莱蒂武",
      "name_en": "Mullathivu",
      "name_pinyin": "mulaidiwu",
      "code": "MUL",
      "childrens": []
    }, {
      "id": "2216",
      "pid": "189",
      "path": ",1,189,2216,",
      "level": "3",
      "name": "努沃勒埃利耶",
      "name_en": "Nuwara Eliya",
      "name_pinyin": "nuwoleailiye",
      "code": "NUE",
      "childrens": []
    }, {
      "id": "2217",
      "pid": "189",
      "path": ",1,189,2217,",
      "level": "3",
      "name": "普塔勒姆",
      "name_en": "Puttalam",
      "name_pinyin": "putalemu",
      "code": "PUT",
      "childrens": []
    }, {
      "id": "2218",
      "pid": "189",
      "path": ",1,189,2218,",
      "level": "3",
      "name": "亭可马里",
      "name_en": "Trincomalee",
      "name_pinyin": "tingkemali",
      "code": "TRR",
      "childrens": []
    }, {
      "id": "2219",
      "pid": "189",
      "path": ",1,189,2219,",
      "level": "3",
      "name": "瓦武尼亚",
      "name_en": "Vavuniya",
      "name_pinyin": "wawuniya",
      "code": "VAV",
      "childrens": []
    }]
  }, {
    "id": "198",
    "pid": "1",
    "path": ",1,198,",
    "level": "2",
    "name": "塔吉克斯坦",
    "name_en": "Tajikistan",
    "name_pinyin": "tajikesitan",
    "code": "TJK",
    "childrens": [{
      "id": "2269",
      "pid": "198",
      "path": ",1,198,2269,",
      "level": "3",
      "name": "杜尚别",
      "name_en": "Dushanbe",
      "name_pinyin": "dushangbie",
      "code": "DYU",
      "childrens": []
    }, {
      "id": "2270",
      "pid": "198",
      "path": ",1,198,2270,",
      "level": "3",
      "name": "霍罗格",
      "name_en": "Khorugh",
      "name_pinyin": "huoluoge",
      "code": "KHO",
      "childrens": []
    }, {
      "id": "2271",
      "pid": "198",
      "path": ",1,198,2271,",
      "level": "3",
      "name": "卡尼巴达姆",
      "name_en": "Kanibadam",
      "name_pinyin": "kanibadamu",
      "code": "KAN",
      "childrens": []
    }, {
      "id": "2272",
      "pid": "198",
      "path": ",1,198,2272,",
      "level": "3",
      "name": "科法尔尼洪",
      "name_en": "Kofarnihon",
      "name_pinyin": "kefaernihong",
      "code": "KOF",
      "childrens": []
    }, {
      "id": "2273",
      "pid": "198",
      "path": ",1,198,2273,",
      "level": "3",
      "name": "苦盏",
      "name_en": "Khujand",
      "name_pinyin": "kuzhan",
      "code": "KHU",
      "childrens": []
    }, {
      "id": "2274",
      "pid": "198",
      "path": ",1,198,2274,",
      "level": "3",
      "name": "库尔干-秋别",
      "name_en": "Kurgan-Tjube",
      "name_pinyin": "kuerganqiubie",
      "code": "KTJ",
      "childrens": []
    }, {
      "id": "2275",
      "pid": "198",
      "path": ",1,198,2275,",
      "level": "3",
      "name": "库洛布",
      "name_en": "Kulob",
      "name_pinyin": "kuluobu",
      "code": "KLB",
      "childrens": []
    }, {
      "id": "2276",
      "pid": "198",
      "path": ",1,198,2276,",
      "level": "3",
      "name": "洛贡",
      "name_en": "Rogun",
      "name_pinyin": "luogong",
      "code": "RGU",
      "childrens": []
    }, {
      "id": "2277",
      "pid": "198",
      "path": ",1,198,2277,",
      "level": "3",
      "name": "努雷克",
      "name_en": "Nurek",
      "name_pinyin": "nuleike",
      "code": "NUR",
      "childrens": []
    }, {
      "id": "2278",
      "pid": "198",
      "path": ",1,198,2278,",
      "level": "3",
      "name": "彭吉肯特",
      "name_en": "Pendzhikent",
      "name_pinyin": "pengjikente",
      "code": "PJK",
      "childrens": []
    }, {
      "id": "2279",
      "pid": "198",
      "path": ",1,198,2279,",
      "level": "3",
      "name": "萨班特",
      "name_en": "Sarband",
      "name_pinyin": "sabante",
      "code": "SBA",
      "childrens": []
    }, {
      "id": "2280",
      "pid": "198",
      "path": ",1,198,2280,",
      "level": "3",
      "name": "塔博沙尔",
      "name_en": "Taboshar",
      "name_pinyin": "taboshaer",
      "code": "TBS",
      "childrens": []
    }, {
      "id": "2281",
      "pid": "198",
      "path": ",1,198,2281,",
      "level": "3",
      "name": "图尔孙扎德",
      "name_en": "Tursunzade",
      "name_pinyin": "tuersunzhade",
      "code": "TSZ",
      "childrens": []
    }, {
      "id": "2282",
      "pid": "198",
      "path": ",1,198,2282,",
      "level": "3",
      "name": "乌拉秋别",
      "name_en": "Ura-Tjube",
      "name_pinyin": "wulaqiubie",
      "code": "UTJ",
      "childrens": []
    }, {
      "id": "2283",
      "pid": "198",
      "path": ",1,198,2283,",
      "level": "3",
      "name": "伊斯法拉",
      "name_en": "Isfara",
      "name_pinyin": "yisifala",
      "code": "ISF",
      "childrens": []
    }]
  }, {
    "id": "199",
    "pid": "1",
    "path": ",1,199,",
    "level": "2",
    "name": "泰国",
    "name_en": "Thailand",
    "name_pinyin": "taiguo",
    "code": "THA",
    "childrens": [{
      "id": "2284",
      "pid": "199",
      "path": ",1,199,2284,",
      "level": "3",
      "name": "安纳乍能",
      "name_en": "Amnat Charoen",
      "name_pinyin": "annazhaneng",
      "code": "37",
      "childrens": []
    }, {
      "id": "2285",
      "pid": "199",
      "path": ",1,199,2285,",
      "level": "3",
      "name": "巴蜀",
      "name_en": "Prachuap Khiri Khan",
      "name_pinyin": "bashu",
      "code": "77",
      "childrens": []
    }, {
      "id": "2286",
      "pid": "199",
      "path": ",1,199,2286,",
      "level": "3",
      "name": "巴吞他尼",
      "name_en": "Pathum Thani",
      "name_pinyin": "batuntani",
      "code": "13",
      "childrens": []
    }, {
      "id": "2287",
      "pid": "199",
      "path": ",1,199,2287,",
      "level": "3",
      "name": "巴真",
      "name_en": "Prachin Buri",
      "name_pinyin": "bazhen",
      "code": "25",
      "childrens": []
    }, {
      "id": "2288",
      "pid": "199",
      "path": ",1,199,2288,",
      "level": "3",
      "name": "北碧",
      "name_en": "Kanchanaburi",
      "name_pinyin": "beibi",
      "code": "71",
      "childrens": []
    }, {
      "id": "2289",
      "pid": "199",
      "path": ",1,199,2289,",
      "level": "3",
      "name": "北标",
      "name_en": "Saraburi",
      "name_pinyin": "beibiao",
      "code": "19",
      "childrens": []
    }, {
      "id": "2290",
      "pid": "199",
      "path": ",1,199,2290,",
      "level": "3",
      "name": "北大年",
      "name_en": "Pattani",
      "name_pinyin": "beidanian",
      "code": "94",
      "childrens": []
    }, {
      "id": "2291",
      "pid": "199",
      "path": ",1,199,2291,",
      "level": "3",
      "name": "北揽",
      "name_en": "Samut Prakan",
      "name_pinyin": "beilan",
      "code": "11",
      "childrens": []
    }, {
      "id": "2292",
      "pid": "199",
      "path": ",1,199,2292,",
      "level": "3",
      "name": "北榄坡",
      "name_en": "Nakhon Sawan",
      "name_pinyin": "beipo",
      "code": "60",
      "childrens": []
    }, {
      "id": "2293",
      "pid": "199",
      "path": ",1,199,2293,",
      "level": "3",
      "name": "北柳",
      "name_en": "Chachoengsao",
      "name_pinyin": "beiliu",
      "code": "24",
      "childrens": []
    }, {
      "id": "2294",
      "pid": "199",
      "path": ",1,199,2294,",
      "level": "3",
      "name": "碧差汶",
      "name_en": "Phetchabun",
      "name_pinyin": "bicha",
      "code": "76",
      "childrens": []
    }, {
      "id": "2295",
      "pid": "199",
      "path": ",1,199,2295,",
      "level": "3",
      "name": "博达伦",
      "name_en": "Phatthalung",
      "name_pinyin": "bodalun",
      "code": "93",
      "childrens": []
    }, {
      "id": "2296",
      "pid": "199",
      "path": ",1,199,2296,",
      "level": "3",
      "name": "猜那",
      "name_en": "Chai Nat",
      "name_pinyin": "caina",
      "code": "18",
      "childrens": []
    }, {
      "id": "2297",
      "pid": "199",
      "path": ",1,199,2297,",
      "level": "3",
      "name": "猜也奔",
      "name_en": "Chaiyaphum",
      "name_pinyin": "caiyeben",
      "code": "36",
      "childrens": []
    }, {
      "id": "2298",
      "pid": "199",
      "path": ",1,199,2298,",
      "level": "3",
      "name": "程逸",
      "name_en": "Uttaradit",
      "name_pinyin": "chengyi",
      "code": "53",
      "childrens": []
    }, {
      "id": "2299",
      "pid": "199",
      "path": ",1,199,2299,",
      "level": "3",
      "name": "春蓬",
      "name_en": "Chumphon",
      "name_pinyin": "chunpeng",
      "code": "86",
      "childrens": []
    }, {
      "id": "2300",
      "pid": "199",
      "path": ",1,199,2300,",
      "level": "3",
      "name": "春武里",
      "name_en": "Chon Buri",
      "name_pinyin": "chunwuli",
      "code": "20",
      "childrens": []
    }, {
      "id": "2301",
      "pid": "199",
      "path": ",1,199,2301,",
      "level": "3",
      "name": "达",
      "name_en": "Tak",
      "name_pinyin": "da",
      "code": "63",
      "childrens": []
    }, {
      "id": "2302",
      "pid": "199",
      "path": ",1,199,2302,",
      "level": "3",
      "name": "达叻",
      "name_en": "Trat",
      "name_pinyin": "da",
      "code": "23",
      "childrens": []
    }, {
      "id": "2303",
      "pid": "199",
      "path": ",1,199,2303,",
      "level": "3",
      "name": "大城",
      "name_en": "Phra Nakhon Si Ayutthaya",
      "name_pinyin": "dacheng",
      "code": "14",
      "childrens": []
    }, {
      "id": "2304",
      "pid": "199",
      "path": ",1,199,2304,",
      "level": "3",
      "name": "董里",
      "name_en": "Trang",
      "name_pinyin": "dongli",
      "code": "92",
      "childrens": []
    }, {
      "id": "2305",
      "pid": "199",
      "path": ",1,199,2305,",
      "level": "3",
      "name": "佛丕",
      "name_en": "Phetchaburi",
      "name_pinyin": "fo",
      "code": "78",
      "childrens": []
    }, {
      "id": "2306",
      "pid": "199",
      "path": ",1,199,2306,",
      "level": "3",
      "name": "佛统",
      "name_en": "Nakhon Pathom",
      "name_pinyin": "fotong",
      "code": "73",
      "childrens": []
    }, {
      "id": "2307",
      "pid": "199",
      "path": ",1,199,2307,",
      "level": "3",
      "name": "甘烹碧",
      "name_en": "Kamphaeng Phet",
      "name_pinyin": "ganpengbi",
      "code": "62",
      "childrens": []
    }, {
      "id": "2308",
      "pid": "199",
      "path": ",1,199,2308,",
      "level": "3",
      "name": "红统",
      "name_en": "Ang Thong",
      "name_pinyin": "hongtong",
      "code": "15",
      "childrens": []
    }, {
      "id": "2309",
      "pid": "199",
      "path": ",1,199,2309,",
      "level": "3",
      "name": "华富里",
      "name_en": "Lop Buri",
      "name_pinyin": "huafuli",
      "code": "16",
      "childrens": []
    }, {
      "id": "2310",
      "pid": "199",
      "path": ",1,199,2310,",
      "level": "3",
      "name": "加拉信",
      "name_en": "Kalasin",
      "name_pinyin": "jialaxin",
      "code": "46",
      "childrens": []
    }, {
      "id": "2311",
      "pid": "199",
      "path": ",1,199,2311,",
      "level": "3",
      "name": "甲米",
      "name_en": "Krabi",
      "name_pinyin": "jiami",
      "code": "81",
      "childrens": []
    }, {
      "id": "2312",
      "pid": "199",
      "path": ",1,199,2312,",
      "level": "3",
      "name": "尖竹汶",
      "name_en": "Chanthaburi",
      "name_pinyin": "jianzhu",
      "code": "22",
      "childrens": []
    }, {
      "id": "2313",
      "pid": "199",
      "path": ",1,199,2313,",
      "level": "3",
      "name": "孔敬",
      "name_en": "Khon Kaen",
      "name_pinyin": "kongjing",
      "code": "40",
      "childrens": []
    }, {
      "id": "2314",
      "pid": "199",
      "path": ",1,199,2314,",
      "level": "3",
      "name": "拉农",
      "name_en": "Rayong",
      "name_pinyin": "lanong",
      "code": "21",
      "childrens": []
    }, {
      "id": "2315",
      "pid": "199",
      "path": ",1,199,2315,",
      "level": "3",
      "name": "廊开",
      "name_en": "Nong Khai",
      "name_pinyin": "langkai",
      "code": "43",
      "childrens": []
    }, {
      "id": "2316",
      "pid": "199",
      "path": ",1,199,2316,",
      "level": "3",
      "name": "廊莫那浦",
      "name_en": "Nong Bua Lamphu",
      "name_pinyin": "langmonapu",
      "code": "39",
      "childrens": []
    }, {
      "id": "2317",
      "pid": "199",
      "path": ",1,199,2317,",
      "level": "3",
      "name": "叻丕",
      "name_en": "Ratchaburi",
      "name_pinyin": "",
      "code": "70",
      "childrens": []
    }, {
      "id": "2318",
      "pid": "199",
      "path": ",1,199,2318,",
      "level": "3",
      "name": "黎",
      "name_en": "Loei",
      "name_pinyin": "li",
      "code": "42",
      "childrens": []
    }, {
      "id": "2319",
      "pid": "199",
      "path": ",1,199,2319,",
      "level": "3",
      "name": "黎逸",
      "name_en": "Roi Et",
      "name_pinyin": "liyi",
      "code": "45",
      "childrens": []
    }, {
      "id": "2320",
      "pid": "199",
      "path": ",1,199,2320,",
      "level": "3",
      "name": "龙仔厝",
      "name_en": "Samut Sakhon",
      "name_pinyin": "longzi",
      "code": "74",
      "childrens": []
    }, {
      "id": "2321",
      "pid": "199",
      "path": ",1,199,2321,",
      "level": "3",
      "name": "罗勇",
      "name_en": "Ranong",
      "name_pinyin": "luoyong",
      "code": "85",
      "childrens": []
    }, {
      "id": "2322",
      "pid": "199",
      "path": ",1,199,2322,",
      "level": "3",
      "name": "洛坤",
      "name_en": "Nakhon Si Thammarat",
      "name_pinyin": "luokun",
      "code": "80",
      "childrens": []
    }, {
      "id": "2323",
      "pid": "199",
      "path": ",1,199,2323,",
      "level": "3",
      "name": "玛哈沙拉堪",
      "name_en": "Maha Sarakham",
      "name_pinyin": "mahashalakan",
      "code": "44",
      "childrens": []
    }, {
      "id": "2324",
      "pid": "199",
      "path": ",1,199,2324,",
      "level": "3",
      "name": "曼谷",
      "name_en": "Bangkok",
      "name_pinyin": "mangu",
      "code": "10",
      "childrens": []
    }, {
      "id": "2325",
      "pid": "199",
      "path": ",1,199,2325,",
      "level": "3",
      "name": "莫达汉",
      "name_en": "Mukdahan",
      "name_pinyin": "modahan",
      "code": "49",
      "childrens": []
    }, {
      "id": "2326",
      "pid": "199",
      "path": ",1,199,2326,",
      "level": "3",
      "name": "那空那育",
      "name_en": "Nakhon Nayok",
      "name_pinyin": "nakongnayu",
      "code": "26",
      "childrens": []
    }, {
      "id": "2327",
      "pid": "199",
      "path": ",1,199,2327,",
      "level": "3",
      "name": "那空帕农",
      "name_en": "Nakhon Phanom",
      "name_pinyin": "nakongpanong",
      "code": "48",
      "childrens": []
    }, {
      "id": "2328",
      "pid": "199",
      "path": ",1,199,2328,",
      "level": "3",
      "name": "难",
      "name_en": "Nan",
      "name_pinyin": "nan",
      "code": "55",
      "childrens": []
    }, {
      "id": "2329",
      "pid": "199",
      "path": ",1,199,2329,",
      "level": "3",
      "name": "南奔",
      "name_en": "Lamphun",
      "name_pinyin": "nanben",
      "code": "51",
      "childrens": []
    }, {
      "id": "2330",
      "pid": "199",
      "path": ",1,199,2330,",
      "level": "3",
      "name": "暖武里",
      "name_en": "Nonthaburi",
      "name_pinyin": "nuanwuli",
      "code": "12",
      "childrens": []
    }, {
      "id": "2331",
      "pid": "199",
      "path": ",1,199,2331,",
      "level": "3",
      "name": "帕",
      "name_en": "Phrae",
      "name_pinyin": "pa",
      "code": "54",
      "childrens": []
    }, {
      "id": "2332",
      "pid": "199",
      "path": ",1,199,2332,",
      "level": "3",
      "name": "帕尧",
      "name_en": "Phayao",
      "name_pinyin": "payao",
      "code": "56",
      "childrens": []
    }, {
      "id": "2333",
      "pid": "199",
      "path": ",1,199,2333,",
      "level": "3",
      "name": "攀牙",
      "name_en": "Phangnga",
      "name_pinyin": "panya",
      "code": "82",
      "childrens": []
    }, {
      "id": "2334",
      "pid": "199",
      "path": ",1,199,2334,",
      "level": "3",
      "name": "彭世洛",
      "name_en": "Phitsanulok",
      "name_pinyin": "pengshiluo",
      "code": "65",
      "childrens": []
    }, {
      "id": "2335",
      "pid": "199",
      "path": ",1,199,2335,",
      "level": "3",
      "name": "披集",
      "name_en": "Phichit",
      "name_pinyin": "piji",
      "code": "66",
      "childrens": []
    }, {
      "id": "2336",
      "pid": "199",
      "path": ",1,199,2336,",
      "level": "3",
      "name": "普吉",
      "name_en": "Phuket",
      "name_pinyin": "puji",
      "code": "83",
      "childrens": []
    }, {
      "id": "2337",
      "pid": "199",
      "path": ",1,199,2337,",
      "level": "3",
      "name": "清莱",
      "name_en": "Chiang Rai",
      "name_pinyin": "qinglai",
      "code": "57",
      "childrens": []
    }, {
      "id": "2338",
      "pid": "199",
      "path": ",1,199,2338,",
      "level": "3",
      "name": "清迈",
      "name_en": "Chiang Mai",
      "name_pinyin": "qingmai",
      "code": "50",
      "childrens": []
    }, {
      "id": "2339",
      "pid": "199",
      "path": ",1,199,2339,",
      "level": "3",
      "name": "色军",
      "name_en": "Sakon Nakhon",
      "name_pinyin": "sejun",
      "code": "47",
      "childrens": []
    }, {
      "id": "2340",
      "pid": "199",
      "path": ",1,199,2340,",
      "level": "3",
      "name": "沙敦",
      "name_en": "Satun",
      "name_pinyin": "shadun",
      "code": "91",
      "childrens": []
    }, {
      "id": "2341",
      "pid": "199",
      "path": ",1,199,2341,",
      "level": "3",
      "name": "沙缴",
      "name_en": "Sa Kaeo",
      "name_pinyin": "shajiao",
      "code": "27",
      "childrens": []
    }, {
      "id": "2342",
      "pid": "199",
      "path": ",1,199,2342,",
      "level": "3",
      "name": "四色菊",
      "name_en": "Si sa ket",
      "name_pinyin": "siseju",
      "code": "33",
      "childrens": []
    }, {
      "id": "2343",
      "pid": "199",
      "path": ",1,199,2343,",
      "level": "3",
      "name": "宋卡",
      "name_en": "Songkhla",
      "name_pinyin": "songka",
      "code": "90",
      "childrens": []
    }, {
      "id": "2344",
      "pid": "199",
      "path": ",1,199,2344,",
      "level": "3",
      "name": "素可泰",
      "name_en": "Sukhothai",
      "name_pinyin": "suketai",
      "code": "64",
      "childrens": []
    }, {
      "id": "2345",
      "pid": "199",
      "path": ",1,199,2345,",
      "level": "3",
      "name": "素叻",
      "name_en": "Surat Thani",
      "name_pinyin": "su",
      "code": "84",
      "childrens": []
    }, {
      "id": "2346",
      "pid": "199",
      "path": ",1,199,2346,",
      "level": "3",
      "name": "素林",
      "name_en": "Surin",
      "name_pinyin": "sulin",
      "code": "32",
      "childrens": []
    }, {
      "id": "2347",
      "pid": "199",
      "path": ",1,199,2347,",
      "level": "3",
      "name": "素攀武里",
      "name_en": "Suphan Buri",
      "name_pinyin": "supanwuli",
      "code": "72",
      "childrens": []
    }, {
      "id": "2348",
      "pid": "199",
      "path": ",1,199,2348,",
      "level": "3",
      "name": "陶公",
      "name_en": "Narathiwat",
      "name_pinyin": "taogong",
      "code": "96",
      "childrens": []
    }, {
      "id": "2349",
      "pid": "199",
      "path": ",1,199,2349,",
      "level": "3",
      "name": "乌隆",
      "name_en": "Udon Thani",
      "name_pinyin": "wulong",
      "code": "41",
      "childrens": []
    }, {
      "id": "2350",
      "pid": "199",
      "path": ",1,199,2350,",
      "level": "3",
      "name": "乌泰他尼",
      "name_en": "Uthai Thani",
      "name_pinyin": "wutaitani",
      "code": "61",
      "childrens": []
    }, {
      "id": "2351",
      "pid": "199",
      "path": ",1,199,2351,",
      "level": "3",
      "name": "乌汶",
      "name_en": "Ubon Ratchathani",
      "name_pinyin": "wu",
      "code": "34",
      "childrens": []
    }, {
      "id": "2352",
      "pid": "199",
      "path": ",1,199,2352,",
      "level": "3",
      "name": "武里南",
      "name_en": "Buri Ram",
      "name_pinyin": "wulinan",
      "code": "31",
      "childrens": []
    }, {
      "id": "2353",
      "pid": "199",
      "path": ",1,199,2353,",
      "level": "3",
      "name": "信武里",
      "name_en": "Sing Buri",
      "name_pinyin": "xinwuli",
      "code": "17",
      "childrens": []
    }, {
      "id": "2354",
      "pid": "199",
      "path": ",1,199,2354,",
      "level": "3",
      "name": "耶梭通",
      "name_en": "Yasothon",
      "name_pinyin": "yesuotong",
      "code": "35",
      "childrens": []
    }, {
      "id": "2355",
      "pid": "199",
      "path": ",1,199,2355,",
      "level": "3",
      "name": "也拉",
      "name_en": "Yala",
      "name_pinyin": "yela",
      "code": "95",
      "childrens": []
    }, {
      "id": "2356",
      "pid": "199",
      "path": ",1,199,2356,",
      "level": "3",
      "name": "夜丰颂",
      "name_en": "Mae Hong Son",
      "name_pinyin": "yefengsong",
      "code": "58",
      "childrens": []
    }, {
      "id": "2357",
      "pid": "199",
      "path": ",1,199,2357,",
      "level": "3",
      "name": "夜功",
      "name_en": "Samut Songkhram",
      "name_pinyin": "yegong",
      "code": "75",
      "childrens": []
    }]
  }, {
    "id": "207",
    "pid": "1",
    "path": ",1,207,",
    "level": "2",
    "name": "土耳其",
    "name_en": "Turkey",
    "name_pinyin": "tuerqi",
    "code": "TUR",
    "childrens": [{
      "id": "2414",
      "pid": "207",
      "path": ",1,207,2414,",
      "level": "3",
      "name": "阿达纳",
      "name_en": "Adana",
      "name_pinyin": "adana",
      "code": "ADA",
      "childrens": []
    }, {
      "id": "2415",
      "pid": "207",
      "path": ",1,207,2415,",
      "level": "3",
      "name": "阿德亚曼",
      "name_en": "Adiyaman",
      "name_pinyin": "adeyaman",
      "code": "ADI",
      "childrens": []
    }, {
      "id": "2416",
      "pid": "207",
      "path": ",1,207,2416,",
      "level": "3",
      "name": "阿尔达罕",
      "name_en": "Ardahan",
      "name_pinyin": "aerdahan",
      "code": "ARD",
      "childrens": []
    }, {
      "id": "2417",
      "pid": "207",
      "path": ",1,207,2417,",
      "level": "3",
      "name": "阿尔特温",
      "name_en": "Artvin",
      "name_pinyin": "aertewen",
      "code": "ART",
      "childrens": []
    }, {
      "id": "2418",
      "pid": "207",
      "path": ",1,207,2418,",
      "level": "3",
      "name": "阿菲永",
      "name_en": "Afyon",
      "name_pinyin": "afeiyong",
      "code": "AFY",
      "childrens": []
    }, {
      "id": "2419",
      "pid": "207",
      "path": ",1,207,2419,",
      "level": "3",
      "name": "阿克萨赖",
      "name_en": "Aksaray",
      "name_pinyin": "akesalai",
      "code": "AKS",
      "childrens": []
    }, {
      "id": "2420",
      "pid": "207",
      "path": ",1,207,2420,",
      "level": "3",
      "name": "阿勒",
      "name_en": "Agri",
      "name_pinyin": "ale",
      "code": "AGR",
      "childrens": []
    }, {
      "id": "2421",
      "pid": "207",
      "path": ",1,207,2421,",
      "level": "3",
      "name": "阿马西亚",
      "name_en": "Amasya",
      "name_pinyin": "amaxiya",
      "code": "AMA",
      "childrens": []
    }, {
      "id": "2422",
      "pid": "207",
      "path": ",1,207,2422,",
      "level": "3",
      "name": "埃迪尔内",
      "name_en": "Edirne",
      "name_pinyin": "aidiernei",
      "code": "EDI",
      "childrens": []
    }, {
      "id": "2423",
      "pid": "207",
      "path": ",1,207,2423,",
      "level": "3",
      "name": "埃尔津詹",
      "name_en": "Erzincan",
      "name_pinyin": "aierjinzhan",
      "code": "EZC",
      "childrens": []
    }, {
      "id": "2424",
      "pid": "207",
      "path": ",1,207,2424,",
      "level": "3",
      "name": "埃尔祖鲁姆",
      "name_en": "Erzurum",
      "name_pinyin": "aierzulumu",
      "code": "EZR",
      "childrens": []
    }, {
      "id": "2425",
      "pid": "207",
      "path": ",1,207,2425,",
      "level": "3",
      "name": "埃拉泽",
      "name_en": "Elazig",
      "name_pinyin": "ailaze",
      "code": "ELA",
      "childrens": []
    }, {
      "id": "2426",
      "pid": "207",
      "path": ",1,207,2426,",
      "level": "3",
      "name": "埃斯基谢希尔",
      "name_en": "Eskisehir",
      "name_pinyin": "aisijixiexier",
      "code": "ESK",
      "childrens": []
    }, {
      "id": "2427",
      "pid": "207",
      "path": ",1,207,2427,",
      "level": "3",
      "name": "艾登",
      "name_en": "Aydin",
      "name_pinyin": "aideng",
      "code": "AYI",
      "childrens": []
    }, {
      "id": "2428",
      "pid": "207",
      "path": ",1,207,2428,",
      "level": "3",
      "name": "安卡拉",
      "name_en": "Ankara",
      "name_pinyin": "ankala",
      "code": "ANK",
      "childrens": []
    }, {
      "id": "2429",
      "pid": "207",
      "path": ",1,207,2429,",
      "level": "3",
      "name": "安塔利亚",
      "name_en": "Antalya",
      "name_pinyin": "antaliya",
      "code": "ANT",
      "childrens": []
    }, {
      "id": "2430",
      "pid": "207",
      "path": ",1,207,2430,",
      "level": "3",
      "name": "奥尔杜",
      "name_en": "Ordu",
      "name_pinyin": "aoerdu",
      "code": "ORD",
      "childrens": []
    }, {
      "id": "2431",
      "pid": "207",
      "path": ",1,207,2431,",
      "level": "3",
      "name": "巴尔腾",
      "name_en": "Bartin",
      "name_pinyin": "baerteng",
      "code": "BAR",
      "childrens": []
    }, {
      "id": "2432",
      "pid": "207",
      "path": ",1,207,2432,",
      "level": "3",
      "name": "巴勒克埃西尔",
      "name_en": "Balikesir",
      "name_pinyin": "balekeaixier",
      "code": "BAL",
      "childrens": []
    }, {
      "id": "2433",
      "pid": "207",
      "path": ",1,207,2433,",
      "level": "3",
      "name": "巴特曼",
      "name_en": "Batman",
      "name_pinyin": "bateman",
      "code": "BAT",
      "childrens": []
    }, {
      "id": "2434",
      "pid": "207",
      "path": ",1,207,2434,",
      "level": "3",
      "name": "巴伊布尔特",
      "name_en": "Bayburt",
      "name_pinyin": "bayibuerte",
      "code": "BAY",
      "childrens": []
    }, {
      "id": "2435",
      "pid": "207",
      "path": ",1,207,2435,",
      "level": "3",
      "name": "比莱吉克",
      "name_en": "Bilecik",
      "name_pinyin": "bilaijike",
      "code": "BIL",
      "childrens": []
    }, {
      "id": "2436",
      "pid": "207",
      "path": ",1,207,2436,",
      "level": "3",
      "name": "比特利斯",
      "name_en": "Bitlis",
      "name_pinyin": "bitelisi",
      "code": "BIT",
      "childrens": []
    }, {
      "id": "2437",
      "pid": "207",
      "path": ",1,207,2437,",
      "level": "3",
      "name": "宾格尔",
      "name_en": "Bingol",
      "name_pinyin": "bingeer",
      "code": "BIN",
      "childrens": []
    }, {
      "id": "2438",
      "pid": "207",
      "path": ",1,207,2438,",
      "level": "3",
      "name": "博卢",
      "name_en": "Bolu",
      "name_pinyin": "bolu",
      "code": "BOL",
      "childrens": []
    }, {
      "id": "2439",
      "pid": "207",
      "path": ",1,207,2439,",
      "level": "3",
      "name": "布尔杜尔",
      "name_en": "Burdur",
      "name_pinyin": "buerduer",
      "code": "BRD",
      "childrens": []
    }, {
      "id": "2440",
      "pid": "207",
      "path": ",1,207,2440,",
      "level": "3",
      "name": "布尔萨",
      "name_en": "Bursa",
      "name_pinyin": "buersa",
      "code": "BRS",
      "childrens": []
    }, {
      "id": "2441",
      "pid": "207",
      "path": ",1,207,2441,",
      "level": "3",
      "name": "昌克勒",
      "name_en": "Cankiri",
      "name_pinyin": "changkele",
      "code": "CKR",
      "childrens": []
    }, {
      "id": "2442",
      "pid": "207",
      "path": ",1,207,2442,",
      "level": "3",
      "name": "代尼兹利",
      "name_en": "Denizli",
      "name_pinyin": "dainizili",
      "code": "DEN",
      "childrens": []
    }, {
      "id": "2443",
      "pid": "207",
      "path": ",1,207,2443,",
      "level": "3",
      "name": "迪亚巴克尔",
      "name_en": "Diyarbakir",
      "name_pinyin": "diyabakeer",
      "code": "DIY",
      "childrens": []
    }, {
      "id": "2444",
      "pid": "207",
      "path": ",1,207,2444,",
      "level": "3",
      "name": "凡",
      "name_en": "Van",
      "name_pinyin": "fan",
      "code": "VAN",
      "childrens": []
    }, {
      "id": "2445",
      "pid": "207",
      "path": ",1,207,2445,",
      "level": "3",
      "name": "哈卡里",
      "name_en": "Hakkari",
      "name_pinyin": "hakali",
      "code": "HKR",
      "childrens": []
    }, {
      "id": "2446",
      "pid": "207",
      "path": ",1,207,2446,",
      "level": "3",
      "name": "哈塔伊",
      "name_en": "Hatay",
      "name_pinyin": "hatayi",
      "code": "HTY",
      "childrens": []
    }, {
      "id": "2447",
      "pid": "207",
      "path": ",1,207,2447,",
      "level": "3",
      "name": "基利斯",
      "name_en": "Kilis",
      "name_pinyin": "jilisi",
      "code": "KLS",
      "childrens": []
    }, {
      "id": "2448",
      "pid": "207",
      "path": ",1,207,2448,",
      "level": "3",
      "name": "吉雷松",
      "name_en": "Giresun",
      "name_pinyin": "jileisong",
      "code": "GIR",
      "childrens": []
    }, {
      "id": "2449",
      "pid": "207",
      "path": ",1,207,2449,",
      "level": "3",
      "name": "加济安泰普",
      "name_en": "Gaziantep",
      "name_pinyin": "jiajiantaipu",
      "code": "GAZ",
      "childrens": []
    }, {
      "id": "2450",
      "pid": "207",
      "path": ",1,207,2450,",
      "level": "3",
      "name": "居米什哈内",
      "name_en": "Gumushane",
      "name_pinyin": "jumishihanei",
      "code": "GMS",
      "childrens": []
    }, {
      "id": "2451",
      "pid": "207",
      "path": ",1,207,2451,",
      "level": "3",
      "name": "卡尔斯",
      "name_en": "Kars",
      "name_pinyin": "kaersi",
      "code": "KRS",
      "childrens": []
    }, {
      "id": "2452",
      "pid": "207",
      "path": ",1,207,2452,",
      "level": "3",
      "name": "卡赫拉曼马拉什",
      "name_en": "Kahraman Maras",
      "name_pinyin": "kahelamanmalashi",
      "code": "KAH",
      "childrens": []
    }, {
      "id": "2453",
      "pid": "207",
      "path": ",1,207,2453,",
      "level": "3",
      "name": "卡拉比克",
      "name_en": "Karabuk",
      "name_pinyin": "kalabike",
      "code": "KRB",
      "childrens": []
    }, {
      "id": "2454",
      "pid": "207",
      "path": ",1,207,2454,",
      "level": "3",
      "name": "卡拉曼",
      "name_en": "Karaman",
      "name_pinyin": "kalaman",
      "code": "KRM",
      "childrens": []
    }, {
      "id": "2455",
      "pid": "207",
      "path": ",1,207,2455,",
      "level": "3",
      "name": "卡斯塔莫努",
      "name_en": "Kastamonu",
      "name_pinyin": "kasitamonu",
      "code": "KAS",
      "childrens": []
    }, {
      "id": "2456",
      "pid": "207",
      "path": ",1,207,2456,",
      "level": "3",
      "name": "开塞利",
      "name_en": "Kayseri",
      "name_pinyin": "kaisaili",
      "code": "KAY",
      "childrens": []
    }, {
      "id": "2457",
      "pid": "207",
      "path": ",1,207,2457,",
      "level": "3",
      "name": "科贾埃利",
      "name_en": "Kocaeli",
      "name_pinyin": "kejiaaili",
      "code": "KOC",
      "childrens": []
    }, {
      "id": "2458",
      "pid": "207",
      "path": ",1,207,2458,",
      "level": "3",
      "name": "柯克拉雷利",
      "name_en": "Kirklareli",
      "name_pinyin": "kekelaleili",
      "code": "KLR",
      "childrens": []
    }, {
      "id": "2459",
      "pid": "207",
      "path": ",1,207,2459,",
      "level": "3",
      "name": "科尼亚",
      "name_en": "Konya",
      "name_pinyin": "keniya",
      "code": "KON",
      "childrens": []
    }, {
      "id": "2460",
      "pid": "207",
      "path": ",1,207,2460,",
      "level": "3",
      "name": "克尔谢希尔",
      "name_en": "Kirsehir",
      "name_pinyin": "keerxiexier",
      "code": "KRH",
      "childrens": []
    }, {
      "id": "2461",
      "pid": "207",
      "path": ",1,207,2461,",
      "level": "3",
      "name": "克勒克卡莱",
      "name_en": "Kirikkale",
      "name_pinyin": "kelekekalai",
      "code": "KRK",
      "childrens": []
    }, {
      "id": "2462",
      "pid": "207",
      "path": ",1,207,2462,",
      "level": "3",
      "name": "拉飞",
      "name_en": "Urfa",
      "name_pinyin": "lafei",
      "code": "URF",
      "childrens": []
    }, {
      "id": "2463",
      "pid": "207",
      "path": ",1,207,2463,",
      "level": "3",
      "name": "里泽",
      "name_en": "Rize",
      "name_pinyin": "lize",
      "code": "RIZ",
      "childrens": []
    }, {
      "id": "2464",
      "pid": "207",
      "path": ",1,207,2464,",
      "level": "3",
      "name": "马尔丁",
      "name_en": "Mardin",
      "name_pinyin": "maerding",
      "code": "MAR",
      "childrens": []
    }, {
      "id": "2465",
      "pid": "207",
      "path": ",1,207,2465,",
      "level": "3",
      "name": "马拉蒂亚",
      "name_en": "Malatya",
      "name_pinyin": "maladiya",
      "code": "MAL",
      "childrens": []
    }, {
      "id": "2466",
      "pid": "207",
      "path": ",1,207,2466,",
      "level": "3",
      "name": "马尼萨",
      "name_en": "Manisa",
      "name_pinyin": "manisa",
      "code": "MAN",
      "childrens": []
    }, {
      "id": "2467",
      "pid": "207",
      "path": ",1,207,2467,",
      "level": "3",
      "name": "穆拉",
      "name_en": "Mugla",
      "name_pinyin": "mula",
      "code": "MUG",
      "childrens": []
    }, {
      "id": "2468",
      "pid": "207",
      "path": ",1,207,2468,",
      "level": "3",
      "name": "穆什",
      "name_en": "Mus",
      "name_pinyin": "mushi",
      "code": "MUS",
      "childrens": []
    }, {
      "id": "2469",
      "pid": "207",
      "path": ",1,207,2469,",
      "level": "3",
      "name": "内夫谢希尔",
      "name_en": "Nevsehir",
      "name_pinyin": "neifuxiexier",
      "code": "NEV",
      "childrens": []
    }, {
      "id": "2470",
      "pid": "207",
      "path": ",1,207,2470,",
      "level": "3",
      "name": "尼代",
      "name_en": "Nigde",
      "name_pinyin": "nidai",
      "code": "NIG",
      "childrens": []
    }, {
      "id": "2471",
      "pid": "207",
      "path": ",1,207,2471,",
      "level": "3",
      "name": "恰纳卡莱",
      "name_en": "Canakkale",
      "name_pinyin": "qianakalai",
      "code": "CKL",
      "childrens": []
    }, {
      "id": "2472",
      "pid": "207",
      "path": ",1,207,2472,",
      "level": "3",
      "name": "乔鲁姆",
      "name_en": "Corum",
      "name_pinyin": "qiaolumu",
      "code": "COR",
      "childrens": []
    }, {
      "id": "2473",
      "pid": "207",
      "path": ",1,207,2473,",
      "level": "3",
      "name": "屈塔希亚",
      "name_en": "Kutahya",
      "name_pinyin": "qutaxiya",
      "code": "KUT",
      "childrens": []
    }, {
      "id": "2474",
      "pid": "207",
      "path": ",1,207,2474,",
      "level": "3",
      "name": "萨卡里亚",
      "name_en": "Sakarya",
      "name_pinyin": "sakaliya",
      "code": "SAK",
      "childrens": []
    }, {
      "id": "2475",
      "pid": "207",
      "path": ",1,207,2475,",
      "level": "3",
      "name": "萨姆松",
      "name_en": "Samsun",
      "name_pinyin": "samusong",
      "code": "SAM",
      "childrens": []
    }, {
      "id": "2476",
      "pid": "207",
      "path": ",1,207,2476,",
      "level": "3",
      "name": "泰基尔达",
      "name_en": "Tekirdag",
      "name_pinyin": "taijierda",
      "code": "TEL",
      "childrens": []
    }, {
      "id": "2477",
      "pid": "207",
      "path": ",1,207,2477,",
      "level": "3",
      "name": "特拉布宗",
      "name_en": "Trabzon",
      "name_pinyin": "telabuzong",
      "code": "TRA",
      "childrens": []
    }, {
      "id": "2478",
      "pid": "207",
      "path": ",1,207,2478,",
      "level": "3",
      "name": "通杰利",
      "name_en": "Tunceli",
      "name_pinyin": "tongjieli",
      "code": "TUN",
      "childrens": []
    }, {
      "id": "2479",
      "pid": "207",
      "path": ",1,207,2479,",
      "level": "3",
      "name": "托卡特",
      "name_en": "Tokat",
      "name_pinyin": "tuokate",
      "code": "TOK",
      "childrens": []
    }, {
      "id": "2480",
      "pid": "207",
      "path": ",1,207,2480,",
      "level": "3",
      "name": "乌萨克",
      "name_en": "Usak",
      "name_pinyin": "wusake",
      "code": "USK",
      "childrens": []
    }, {
      "id": "2481",
      "pid": "207",
      "path": ",1,207,2481,",
      "level": "3",
      "name": "锡尔纳克",
      "name_en": "Sirnak",
      "name_pinyin": "xiernake",
      "code": "SIR",
      "childrens": []
    }, {
      "id": "2482",
      "pid": "207",
      "path": ",1,207,2482,",
      "level": "3",
      "name": "锡尔特",
      "name_en": "Siirt",
      "name_pinyin": "xierte",
      "code": "SII",
      "childrens": []
    }, {
      "id": "2483",
      "pid": "207",
      "path": ",1,207,2483,",
      "level": "3",
      "name": "锡诺普",
      "name_en": "Sinop",
      "name_pinyin": "xinuopu",
      "code": "SIN",
      "childrens": []
    }, {
      "id": "2484",
      "pid": "207",
      "path": ",1,207,2484,",
      "level": "3",
      "name": "锡瓦斯",
      "name_en": "Sivas",
      "name_pinyin": "xiwasi",
      "code": "SIV",
      "childrens": []
    }, {
      "id": "2485",
      "pid": "207",
      "path": ",1,207,2485,",
      "level": "3",
      "name": "伊迪尔",
      "name_en": "Igdir",
      "name_pinyin": "yidier",
      "code": "IGD",
      "childrens": []
    }, {
      "id": "2486",
      "pid": "207",
      "path": ",1,207,2486,",
      "level": "3",
      "name": "伊切尔",
      "name_en": "Icel",
      "name_pinyin": "yiqieer",
      "code": "ICE",
      "childrens": []
    }, {
      "id": "2487",
      "pid": "207",
      "path": ",1,207,2487,",
      "level": "3",
      "name": "伊斯帕尔塔",
      "name_en": "Isparta",
      "name_pinyin": "yisipaerta",
      "code": "ISP",
      "childrens": []
    }, {
      "id": "2488",
      "pid": "207",
      "path": ",1,207,2488,",
      "level": "3",
      "name": "伊斯坦布尔",
      "name_en": "Istanbul",
      "name_pinyin": "yisitanbuer",
      "code": "IST",
      "childrens": []
    }, {
      "id": "2489",
      "pid": "207",
      "path": ",1,207,2489,",
      "level": "3",
      "name": "伊兹密尔",
      "name_en": "Izmir",
      "name_pinyin": "yizimier",
      "code": "IZM",
      "childrens": []
    }, {
      "id": "2490",
      "pid": "207",
      "path": ",1,207,2490,",
      "level": "3",
      "name": "约兹加特",
      "name_en": "Yozgat",
      "name_pinyin": "yuezijiate",
      "code": "YOZ",
      "childrens": []
    }, {
      "id": "2491",
      "pid": "207",
      "path": ",1,207,2491,",
      "level": "3",
      "name": "宗古尔达克",
      "name_en": "Zonguldak",
      "name_pinyin": "zongguerdake",
      "code": "ZON",
      "childrens": []
    }]
  }, {
    "id": "208",
    "pid": "1",
    "path": ",1,208,",
    "level": "2",
    "name": "土库曼斯坦",
    "name_en": "Turkmenistan",
    "name_pinyin": "tukumansitan",
    "code": "TKM",
    "childrens": [{
      "id": "2492",
      "pid": "208",
      "path": ",1,208,2492,",
      "level": "3",
      "name": "阿哈尔",
      "name_en": "Ahal",
      "name_pinyin": "ahaer",
      "code": "A",
      "childrens": []
    }, {
      "id": "2493",
      "pid": "208",
      "path": ",1,208,2493,",
      "level": "3",
      "name": "阿什哈巴德市",
      "name_en": "Ashgabat",
      "name_pinyin": "ashihabadeshi",
      "code": "ASB",
      "childrens": []
    }, {
      "id": "2494",
      "pid": "208",
      "path": ",1,208,2494,",
      "level": "3",
      "name": "巴尔坎",
      "name_en": "Balkan",
      "name_pinyin": "baerkan",
      "code": "B",
      "childrens": []
    }, {
      "id": "2495",
      "pid": "208",
      "path": ",1,208,2495,",
      "level": "3",
      "name": "达沙古兹",
      "name_en": "Dashoguz",
      "name_pinyin": "dashaguzi",
      "code": "D",
      "childrens": []
    }, {
      "id": "2496",
      "pid": "208",
      "path": ",1,208,2496,",
      "level": "3",
      "name": "列巴普",
      "name_en": "Lebap",
      "name_pinyin": "liebapu",
      "code": "L",
      "childrens": []
    }, {
      "id": "2497",
      "pid": "208",
      "path": ",1,208,2497,",
      "level": "3",
      "name": "马雷",
      "name_en": "Mary",
      "name_pinyin": "malei",
      "code": "M",
      "childrens": []
    }, {
      "id": "2498",
      "pid": "208",
      "path": ",1,208,2498,",
      "level": "3",
      "name": "涅比特达格",
      "name_en": "Nebitdag",
      "name_pinyin": "niebitedage",
      "code": "NEB",
      "childrens": []
    }]
  }, {
    "id": "216",
    "pid": "1",
    "path": ",1,216,",
    "level": "2",
    "name": "文莱",
    "name_en": "Brunei",
    "name_pinyin": "wenlai",
    "code": "BRN",
    "childrens": []
  }, {
    "id": "220",
    "pid": "1",
    "path": ",1,220,",
    "level": "2",
    "name": "乌兹别克斯坦",
    "name_en": "Uzbekistan",
    "name_pinyin": "wuzibiekesitan",
    "code": "UZB",
    "childrens": [{
      "id": "2653",
      "pid": "220",
      "path": ",1,220,2653,",
      "level": "3",
      "name": "安集延",
      "name_en": "Andijon",
      "name_pinyin": "anjiyan",
      "code": "AN",
      "childrens": []
    }, {
      "id": "2654",
      "pid": "220",
      "path": ",1,220,2654,",
      "level": "3",
      "name": "布哈拉",
      "name_en": "Buxoro",
      "name_pinyin": "buhala",
      "code": "BU",
      "childrens": []
    }, {
      "id": "2655",
      "pid": "220",
      "path": ",1,220,2655,",
      "level": "3",
      "name": "费尔干纳",
      "name_en": "Fargona",
      "name_pinyin": "feierganna",
      "code": "FA",
      "childrens": []
    }, {
      "id": "2656",
      "pid": "220",
      "path": ",1,220,2656,",
      "level": "3",
      "name": "花拉子模",
      "name_en": "Xorazm",
      "name_pinyin": "hualazimo",
      "code": "XO",
      "childrens": []
    }, {
      "id": "2657",
      "pid": "220",
      "path": ",1,220,2657,",
      "level": "3",
      "name": "吉扎克",
      "name_en": "Jizzax",
      "name_pinyin": "jizhake",
      "code": "JI",
      "childrens": []
    }, {
      "id": "2658",
      "pid": "220",
      "path": ",1,220,2658,",
      "level": "3",
      "name": "卡拉卡尔帕克斯坦共和国",
      "name_en": "Qoraqalpogiston",
      "name_pinyin": "kalakaerpakesitangongheguo",
      "code": "QR",
      "childrens": []
    }, {
      "id": "2659",
      "pid": "220",
      "path": ",1,220,2659,",
      "level": "3",
      "name": "卡什卡达里亚",
      "name_en": "Qasqadaryo",
      "name_pinyin": "kashikadaliya",
      "code": "QA",
      "childrens": []
    }, {
      "id": "2660",
      "pid": "220",
      "path": ",1,220,2660,",
      "level": "3",
      "name": "纳曼干",
      "name_en": "Namangan",
      "name_pinyin": "namangan",
      "code": "NG",
      "childrens": []
    }, {
      "id": "2661",
      "pid": "220",
      "path": ",1,220,2661,",
      "level": "3",
      "name": "纳沃伊",
      "name_en": "Navoiy",
      "name_pinyin": "nawoyi",
      "code": "NW",
      "childrens": []
    }, {
      "id": "2662",
      "pid": "220",
      "path": ",1,220,2662,",
      "level": "3",
      "name": "撒马尔罕",
      "name_en": "Samarqand",
      "name_pinyin": "samaerhan",
      "code": "SA",
      "childrens": []
    }, {
      "id": "2663",
      "pid": "220",
      "path": ",1,220,2663,",
      "level": "3",
      "name": "苏尔汉河",
      "name_en": "Surxondaryo",
      "name_pinyin": "suerhanhe",
      "code": "SU",
      "childrens": []
    }, {
      "id": "2664",
      "pid": "220",
      "path": ",1,220,2664,",
      "level": "3",
      "name": "塔什干",
      "name_en": "Toshkent",
      "name_pinyin": "tashigan",
      "code": "TK",
      "childrens": []
    }, {
      "id": "2665",
      "pid": "220",
      "path": ",1,220,2665,",
      "level": "3",
      "name": "塔什干市",
      "name_en": "Toshkent Shahri",
      "name_pinyin": "tashiganshi",
      "code": "TO",
      "childrens": []
    }, {
      "id": "2666",
      "pid": "220",
      "path": ",1,220,2666,",
      "level": "3",
      "name": "锡尔河",
      "name_en": "Sirdaryo",
      "name_pinyin": "xierhe",
      "code": "SI",
      "childrens": []
    }]
  }, {
    "id": "223",
    "pid": "1",
    "path": ",1,223,",
    "level": "2",
    "name": "新加坡",
    "name_en": "Singapore",
    "name_pinyin": "xinjiapo",
    "code": "SGP",
    "childrens": []
  }, {
    "id": "227",
    "pid": "1",
    "path": ",1,227,",
    "level": "2",
    "name": "叙利亚",
    "name_en": "Syria",
    "name_pinyin": "xuliya",
    "code": "SYR",
    "childrens": [{
      "id": "2771",
      "pid": "227",
      "path": ",1,227,2771,",
      "level": "3",
      "name": "阿勒颇",
      "name_en": "Halab",
      "name_pinyin": "alepo",
      "code": "HL",
      "childrens": []
    }, {
      "id": "2772",
      "pid": "227",
      "path": ",1,227,2772,",
      "level": "3",
      "name": "大马士革",
      "name_en": "Rif Dimashq",
      "name_pinyin": "damashige",
      "code": "RD",
      "childrens": []
    }, {
      "id": "2773",
      "pid": "227",
      "path": ",1,227,2773,",
      "level": "3",
      "name": "大马士革市",
      "name_en": "Madinat Dimashq",
      "name_pinyin": "damashigeshi",
      "code": "DI",
      "childrens": []
    }, {
      "id": "2774",
      "pid": "227",
      "path": ",1,227,2774,",
      "level": "3",
      "name": "代尔祖尔",
      "name_en": "Dayr az Zawr",
      "name_pinyin": "daierzuer",
      "code": "DZ",
      "childrens": []
    }, {
      "id": "2775",
      "pid": "227",
      "path": ",1,227,2775,",
      "level": "3",
      "name": "德拉",
      "name_en": "Dara",
      "name_pinyin": "dela",
      "code": "DA",
      "childrens": []
    }, {
      "id": "2776",
      "pid": "227",
      "path": ",1,227,2776,",
      "level": "3",
      "name": "哈马",
      "name_en": "Hamah",
      "name_pinyin": "hama",
      "code": "HM",
      "childrens": []
    }, {
      "id": "2777",
      "pid": "227",
      "path": ",1,227,2777,",
      "level": "3",
      "name": "哈塞克",
      "name_en": "Al Hasakah",
      "name_pinyin": "hasaike",
      "code": "HA",
      "childrens": []
    }, {
      "id": "2778",
      "pid": "227",
      "path": ",1,227,2778,",
      "level": "3",
      "name": "霍姆斯",
      "name_en": "Hims",
      "name_pinyin": "huomusi",
      "code": "HI",
      "childrens": []
    }, {
      "id": "2779",
      "pid": "227",
      "path": ",1,227,2779,",
      "level": "3",
      "name": "加布",
      "name_en": "Al Ghab",
      "name_pinyin": "jiabu",
      "code": "GH",
      "childrens": []
    }, {
      "id": "2780",
      "pid": "227",
      "path": ",1,227,2780,",
      "level": "3",
      "name": "卡米什利",
      "name_en": "Al-Qamishli",
      "name_pinyin": "kamishili",
      "code": "QA",
      "childrens": []
    }, {
      "id": "2781",
      "pid": "227",
      "path": ",1,227,2781,",
      "level": "3",
      "name": "库奈特拉",
      "name_en": "Al Qunaytirah",
      "name_pinyin": "kunaitela",
      "code": "QU",
      "childrens": []
    }, {
      "id": "2782",
      "pid": "227",
      "path": ",1,227,2782,",
      "level": "3",
      "name": "拉卡",
      "name_en": "Ar Raqqah",
      "name_pinyin": "laka",
      "code": "RQ",
      "childrens": []
    }, {
      "id": "2783",
      "pid": "227",
      "path": ",1,227,2783,",
      "level": "3",
      "name": "拉塔基亚",
      "name_en": "Al Ladhiqiyah",
      "name_pinyin": "latajiya",
      "code": "LA",
      "childrens": []
    }, {
      "id": "2784",
      "pid": "227",
      "path": ",1,227,2784,",
      "level": "3",
      "name": "苏韦达",
      "name_en": "As Suwayda",
      "name_pinyin": "suweida",
      "code": "SU",
      "childrens": []
    }, {
      "id": "2785",
      "pid": "227",
      "path": ",1,227,2785,",
      "level": "3",
      "name": "塔尔图斯",
      "name_en": "Tartus",
      "name_pinyin": "taertusi",
      "code": "TA",
      "childrens": []
    }, {
      "id": "2786",
      "pid": "227",
      "path": ",1,227,2786,",
      "level": "3",
      "name": "伊德利卜",
      "name_en": "Idlib",
      "name_pinyin": "yidelibo",
      "code": "ID",
      "childrens": []
    }]
  }, {
    "id": "229",
    "pid": "1",
    "path": ",1,229,",
    "level": "2",
    "name": "亚美尼亚",
    "name_en": "Armenia",
    "name_pinyin": "yameiniya",
    "code": "ARM",
    "childrens": [{
      "id": "2801",
      "pid": "229",
      "path": ",1,229,2801,",
      "level": "3",
      "name": "阿尔马维尔",
      "name_en": "Armavir",
      "name_pinyin": "aermaweier",
      "code": "ARM",
      "childrens": []
    }, {
      "id": "2802",
      "pid": "229",
      "path": ",1,229,2802,",
      "level": "3",
      "name": "阿拉加措特恩",
      "name_en": "Aragacotn",
      "name_pinyin": "alajiacuoteen",
      "code": "AGT",
      "childrens": []
    }, {
      "id": "2803",
      "pid": "229",
      "path": ",1,229,2803,",
      "level": "3",
      "name": "阿拉拉特",
      "name_en": "Ararat",
      "name_pinyin": "alalate",
      "code": "ARA",
      "childrens": []
    }, {
      "id": "2804",
      "pid": "229",
      "path": ",1,229,2804,",
      "level": "3",
      "name": "埃里温市",
      "name_en": "Yerevan",
      "name_pinyin": "ailiwenshi",
      "code": "EVN",
      "childrens": []
    }, {
      "id": "2805",
      "pid": "229",
      "path": ",1,229,2805,",
      "level": "3",
      "name": "格加尔库尼克",
      "name_en": "Gelarkunik",
      "name_pinyin": "gejiaerkunike",
      "code": "GEG",
      "childrens": []
    }, {
      "id": "2806",
      "pid": "229",
      "path": ",1,229,2806,",
      "level": "3",
      "name": "科泰克",
      "name_en": "Kotayk",
      "name_pinyin": "ketaike",
      "code": "KOT",
      "childrens": []
    }, {
      "id": "2807",
      "pid": "229",
      "path": ",1,229,2807,",
      "level": "3",
      "name": "洛里",
      "name_en": "Lorri",
      "name_pinyin": "luoli",
      "code": "LOR",
      "childrens": []
    }, {
      "id": "2808",
      "pid": "229",
      "path": ",1,229,2808,",
      "level": "3",
      "name": "塔武什",
      "name_en": "Tavus",
      "name_pinyin": "tawushi",
      "code": "TAV",
      "childrens": []
    }, {
      "id": "2809",
      "pid": "229",
      "path": ",1,229,2809,",
      "level": "3",
      "name": "瓦约茨·佐尔",
      "name_en": "VayocJor",
      "name_pinyin": "wayueci",
      "code": "VAY",
      "childrens": []
    }, {
      "id": "2810",
      "pid": "229",
      "path": ",1,229,2810,",
      "level": "3",
      "name": "希拉克",
      "name_en": "Shirak",
      "name_pinyin": "xilake",
      "code": "SHI",
      "childrens": []
    }, {
      "id": "2811",
      "pid": "229",
      "path": ",1,229,2811,",
      "level": "3",
      "name": "休尼克",
      "name_en": "Syunik",
      "name_pinyin": "xiunike",
      "code": "SYU",
      "childrens": []
    }]
  }, {
    "id": "230",
    "pid": "1",
    "path": ",1,230,",
    "level": "2",
    "name": "也门",
    "name_en": "Yemen",
    "name_pinyin": "yemen",
    "code": "YEM",
    "childrens": [{
      "id": "2812",
      "pid": "230",
      "path": ",1,230,2812,",
      "level": "3",
      "name": "阿比扬",
      "name_en": "Abyan",
      "name_pinyin": "abiyang",
      "code": "AB",
      "childrens": []
    }, {
      "id": "2813",
      "pid": "230",
      "path": ",1,230,2813,",
      "level": "3",
      "name": "阿姆兰",
      "name_en": "Amran Sana",
      "name_pinyin": "amulan",
      "code": "AM",
      "childrens": []
    }, {
      "id": "2814",
      "pid": "230",
      "path": ",1,230,2814,",
      "level": "3",
      "name": "贝达",
      "name_en": "Al-Bayda",
      "name_pinyin": "beida",
      "code": "BA",
      "childrens": []
    }, {
      "id": "2815",
      "pid": "230",
      "path": ",1,230,2815,",
      "level": "3",
      "name": "达利",
      "name_en": "Ad-Dali",
      "name_pinyin": "dali",
      "code": "DA",
      "childrens": []
    }, {
      "id": "2816",
      "pid": "230",
      "path": ",1,230,2816,",
      "level": "3",
      "name": "哈德拉毛",
      "name_en": "Hadramawt",
      "name_pinyin": "hadelamao",
      "code": "HD",
      "childrens": []
    }, {
      "id": "2817",
      "pid": "230",
      "path": ",1,230,2817,",
      "level": "3",
      "name": "哈杰",
      "name_en": "Hajjah",
      "name_pinyin": "hajie",
      "code": "HJ",
      "childrens": []
    }, {
      "id": "2818",
      "pid": "230",
      "path": ",1,230,2818,",
      "level": "3",
      "name": "荷台达",
      "name_en": "Al-Hudaydah",
      "name_pinyin": "hetaida",
      "code": "HU",
      "childrens": []
    }, {
      "id": "2819",
      "pid": "230",
      "path": ",1,230,2819,",
      "level": "3",
      "name": "焦夫",
      "name_en": "Al-Jawf",
      "name_pinyin": "jiaofu",
      "code": "JA",
      "childrens": []
    }, {
      "id": "2820",
      "pid": "230",
      "path": ",1,230,2820,",
      "level": "3",
      "name": "拉赫季",
      "name_en": "Lahij",
      "name_pinyin": "laheji",
      "code": "LA",
      "childrens": []
    }, {
      "id": "2821",
      "pid": "230",
      "path": ",1,230,2821,",
      "level": "3",
      "name": "马里卜",
      "name_en": "Marib",
      "name_pinyin": "malibo",
      "code": "MA",
      "childrens": []
    }, {
      "id": "2822",
      "pid": "230",
      "path": ",1,230,2822,",
      "level": "3",
      "name": "迈赫拉",
      "name_en": "Al-Mahrah",
      "name_pinyin": "maihela",
      "code": "MR",
      "childrens": []
    }, {
      "id": "2823",
      "pid": "230",
      "path": ",1,230,2823,",
      "level": "3",
      "name": "迈赫维特",
      "name_en": "Al-Mahwit",
      "name_pinyin": "maiheweite",
      "code": "MW",
      "childrens": []
    }, {
      "id": "2824",
      "pid": "230",
      "path": ",1,230,2824,",
      "level": "3",
      "name": "萨达",
      "name_en": "Sadah",
      "name_pinyin": "sada",
      "code": "SD",
      "childrens": []
    }, {
      "id": "2825",
      "pid": "230",
      "path": ",1,230,2825,",
      "level": "3",
      "name": "萨那",
      "name_en": "Sana",
      "name_pinyin": "sana",
      "code": "SN",
      "childrens": []
    }, {
      "id": "2826",
      "pid": "230",
      "path": ",1,230,2826,",
      "level": "3",
      "name": "赛文",
      "name_en": "Seiyun",
      "name_pinyin": "saiwen",
      "code": "GXF",
      "childrens": []
    }, {
      "id": "2827",
      "pid": "230",
      "path": ",1,230,2827,",
      "level": "3",
      "name": "舍卜沃",
      "name_en": "Shabwah",
      "name_pinyin": "shebowo",
      "code": "SH",
      "childrens": []
    }, {
      "id": "2828",
      "pid": "230",
      "path": ",1,230,2828,",
      "level": "3",
      "name": "塔伊兹",
      "name_en": "Taizz",
      "name_pinyin": "tayizi",
      "code": "TA",
      "childrens": []
    }, {
      "id": "2829",
      "pid": "230",
      "path": ",1,230,2829,",
      "level": "3",
      "name": "希赫尔",
      "name_en": "Ash-Shihr",
      "name_pinyin": "xiheer",
      "code": "ASR",
      "childrens": []
    }, {
      "id": "2830",
      "pid": "230",
      "path": ",1,230,2830,",
      "level": "3",
      "name": "亚丁",
      "name_en": "Adan",
      "name_pinyin": "yading",
      "code": "AD",
      "childrens": []
    }, {
      "id": "2831",
      "pid": "230",
      "path": ",1,230,2831,",
      "level": "3",
      "name": "伊卜",
      "name_en": "Ibb",
      "name_pinyin": "yibo",
      "code": "IB",
      "childrens": []
    }, {
      "id": "2832",
      "pid": "230",
      "path": ",1,230,2832,",
      "level": "3",
      "name": "扎玛尔",
      "name_en": "Dhamar",
      "name_pinyin": "zhamaer",
      "code": "DH",
      "childrens": []
    }]
  }, {
    "id": "231",
    "pid": "1",
    "path": ",1,231,",
    "level": "2",
    "name": "伊拉克",
    "name_en": "Iraq",
    "name_pinyin": "yilake",
    "code": "IRQ",
    "childrens": []
  }, {
    "id": "232",
    "pid": "1",
    "path": ",1,232,",
    "level": "2",
    "name": "伊朗",
    "name_en": "Iran",
    "name_pinyin": "yilang",
    "code": "IRN",
    "childrens": []
  }, {
    "id": "233",
    "pid": "1",
    "path": ",1,233,",
    "level": "2",
    "name": "以色列",
    "name_en": "Israel",
    "name_pinyin": "yiselie",
    "code": "ISR",
    "childrens": [{
      "id": "2833",
      "pid": "233",
      "path": ",1,233,2833,",
      "level": "3",
      "name": "阿什杜德",
      "name_en": "Ashdod",
      "name_pinyin": "ashidude",
      "code": "ASH",
      "childrens": []
    }, {
      "id": "2834",
      "pid": "233",
      "path": ",1,233,2834,",
      "level": "3",
      "name": "贝尔谢巴",
      "name_en": "Beersheba",
      "name_pinyin": "beierxieba",
      "code": "BEV",
      "childrens": []
    }, {
      "id": "2835",
      "pid": "233",
      "path": ",1,233,2835,",
      "level": "3",
      "name": "贝特雁",
      "name_en": "Bat Yam",
      "name_pinyin": "beiteyan",
      "code": "BAT",
      "childrens": []
    }, {
      "id": "2836",
      "pid": "233",
      "path": ",1,233,2836,",
      "level": "3",
      "name": "海法",
      "name_en": "Haifa",
      "name_pinyin": "haifa",
      "code": "HFA",
      "childrens": []
    }, {
      "id": "2837",
      "pid": "233",
      "path": ",1,233,2837,",
      "level": "3",
      "name": "霍隆",
      "name_en": "Holon",
      "name_pinyin": "huolong",
      "code": "HOL",
      "childrens": []
    }, {
      "id": "2838",
      "pid": "233",
      "path": ",1,233,2838,",
      "level": "3",
      "name": "内坦亚",
      "name_en": "Netanya",
      "name_pinyin": "neitanya",
      "code": "NAT",
      "childrens": []
    }, {
      "id": "2839",
      "pid": "233",
      "path": ",1,233,2839,",
      "level": "3",
      "name": "特拉维夫",
      "name_en": "Tel Aviv-Yafo",
      "name_pinyin": "telaweifu",
      "code": "TLV",
      "childrens": []
    }, {
      "id": "2840",
      "pid": "233",
      "path": ",1,233,2840,",
      "level": "3",
      "name": "耶路撒冷",
      "name_en": "Jerusalem",
      "name_pinyin": "yelusaleng",
      "code": "J",
      "childrens": []
    }]
  }, {
    "id": "235",
    "pid": "1",
    "path": ",1,235,",
    "level": "2",
    "name": "印度",
    "name_en": "India",
    "name_pinyin": "yindu",
    "code": "IND",
    "childrens": [{
      "id": "2907",
      "pid": "235",
      "path": ",1,235,2907,",
      "level": "3",
      "name": "艾藻尔",
      "name_en": "Aizawl",
      "name_pinyin": "aizaoer",
      "code": "AJL",
      "childrens": []
    }, {
      "id": "2908",
      "pid": "235",
      "path": ",1,235,2908,",
      "level": "3",
      "name": "班加罗尔",
      "name_en": "Bangalore",
      "name_pinyin": "banjialuoer",
      "code": "BLR",
      "childrens": []
    }, {
      "id": "2909",
      "pid": "235",
      "path": ",1,235,2909,",
      "level": "3",
      "name": "本地治里",
      "name_en": "Pondicherry",
      "name_pinyin": "bendizhili",
      "code": "PNY",
      "childrens": []
    }, {
      "id": "2910",
      "pid": "235",
      "path": ",1,235,2910,",
      "level": "3",
      "name": "博帕尔",
      "name_en": "Bhopal",
      "name_pinyin": "bopaer",
      "code": "BHO",
      "childrens": []
    }, {
      "id": "2911",
      "pid": "235",
      "path": ",1,235,2911,",
      "level": "3",
      "name": "布巴内斯瓦尔",
      "name_en": "Bhubaneswar",
      "name_pinyin": "bubaneisiwaer",
      "code": "BBI",
      "childrens": []
    }, {
      "id": "2912",
      "pid": "235",
      "path": ",1,235,2912,",
      "level": "3",
      "name": "昌迪加尔",
      "name_en": "Chandigarh",
      "name_pinyin": "changdijiaer",
      "code": "IXC",
      "childrens": []
    }, {
      "id": "2913",
      "pid": "235",
      "path": ",1,235,2913,",
      "level": "3",
      "name": "达曼",
      "name_en": "Daman",
      "name_pinyin": "daman",
      "code": "DAM",
      "childrens": []
    }, {
      "id": "2914",
      "pid": "235",
      "path": ",1,235,2914,",
      "level": "3",
      "name": "第乌",
      "name_en": "Diu",
      "name_pinyin": "diwu",
      "code": "DIU",
      "childrens": []
    }, {
      "id": "2915",
      "pid": "235",
      "path": ",1,235,2915,",
      "level": "3",
      "name": "甘托克",
      "name_en": "Gangtok",
      "name_pinyin": "gantuoke",
      "code": "GTO",
      "childrens": []
    }, {
      "id": "2916",
      "pid": "235",
      "path": ",1,235,2916,",
      "level": "3",
      "name": "哥印拜陀",
      "name_en": "Coimbatore",
      "name_pinyin": "geyinbaituo",
      "code": "CJB",
      "childrens": []
    }, {
      "id": "2917",
      "pid": "235",
      "path": ",1,235,2917,",
      "level": "3",
      "name": "加尔各答",
      "name_en": "Calcutta",
      "name_pinyin": "jiaergeda",
      "code": "CCU",
      "childrens": []
    }, {
      "id": "2918",
      "pid": "235",
      "path": ",1,235,2918,",
      "level": "3",
      "name": "加里加尔",
      "name_en": "Karaikal",
      "name_pinyin": "jialijiaer",
      "code": "KRK",
      "childrens": []
    }, {
      "id": "2919",
      "pid": "235",
      "path": ",1,235,2919,",
      "level": "3",
      "name": "贾巴尔普尔",
      "name_en": "Jabalpur",
      "name_pinyin": "jiabaerpuer",
      "code": "JLR",
      "childrens": []
    }, {
      "id": "2920",
      "pid": "235",
      "path": ",1,235,2920,",
      "level": "3",
      "name": "贾朗达尔",
      "name_en": "Jalandhar",
      "name_pinyin": "jialangdaer",
      "code": "JUC",
      "childrens": []
    }, {
      "id": "2921",
      "pid": "235",
      "path": ",1,235,2921,",
      "level": "3",
      "name": "焦特布尔",
      "name_en": "Jodhpur",
      "name_pinyin": "jiaotebuer",
      "code": "JDH",
      "childrens": []
    }, {
      "id": "2922",
      "pid": "235",
      "path": ",1,235,2922,",
      "level": "3",
      "name": "金奈",
      "name_en": "Chennai",
      "name_pinyin": "jinnai",
      "code": "MAA",
      "childrens": []
    }, {
      "id": "2923",
      "pid": "235",
      "path": ",1,235,2923,",
      "level": "3",
      "name": "卡瓦拉蒂",
      "name_en": "Kavaratti",
      "name_pinyin": "kawaladi",
      "code": "KVA",
      "childrens": []
    }, {
      "id": "2924",
      "pid": "235",
      "path": ",1,235,2924,",
      "level": "3",
      "name": "科希马",
      "name_en": "Kohima",
      "name_pinyin": "kexima",
      "code": "KOM",
      "childrens": []
    }, {
      "id": "2925",
      "pid": "235",
      "path": ",1,235,2925,",
      "level": "3",
      "name": "马埃",
      "name_en": "Mahe",
      "name_pinyin": "maai",
      "code": "MAH",
      "childrens": []
    }, {
      "id": "2926",
      "pid": "235",
      "path": ",1,235,2926,",
      "level": "3",
      "name": "马杜赖",
      "name_en": "Madurai",
      "name_pinyin": "madulai",
      "code": "IXM",
      "childrens": []
    }, {
      "id": "2927",
      "pid": "235",
      "path": ",1,235,2927,",
      "level": "3",
      "name": "森伯尔布尔",
      "name_en": "Sambalpur",
      "name_pinyin": "senboerbuer",
      "code": "SLR",
      "childrens": []
    }, {
      "id": "2928",
      "pid": "235",
      "path": ",1,235,2928,",
      "level": "3",
      "name": "特里凡得琅",
      "name_en": "Trivandrum",
      "name_pinyin": "telifandelang",
      "code": "TRV",
      "childrens": []
    }, {
      "id": "2929",
      "pid": "235",
      "path": ",1,235,2929,",
      "level": "3",
      "name": "乌代布尔",
      "name_en": "Udaipur",
      "name_pinyin": "wudaibuer",
      "code": "UDR",
      "childrens": []
    }, {
      "id": "2930",
      "pid": "235",
      "path": ",1,235,2930,",
      "level": "3",
      "name": "西隆",
      "name_en": "Shillong",
      "name_pinyin": "xilong",
      "code": "SHL",
      "childrens": []
    }, {
      "id": "2931",
      "pid": "235",
      "path": ",1,235,2931,",
      "level": "3",
      "name": "锡尔萨瓦",
      "name_en": "Silvassa",
      "name_pinyin": "xiersawa",
      "code": "SIL",
      "childrens": []
    }, {
      "id": "2932",
      "pid": "235",
      "path": ",1,235,2932,",
      "level": "3",
      "name": "新德里",
      "name_en": "New Delhi",
      "name_pinyin": "xindeli",
      "code": "ICD",
      "childrens": []
    }, {
      "id": "2933",
      "pid": "235",
      "path": ",1,235,2933,",
      "level": "3",
      "name": "亚南",
      "name_en": "Yanam",
      "name_pinyin": "yanan",
      "code": "SRV",
      "childrens": []
    }, {
      "id": "2934",
      "pid": "235",
      "path": ",1,235,2934,",
      "level": "3",
      "name": "因帕尔",
      "name_en": "Imphal",
      "name_pinyin": "yinpaer",
      "code": "IMF",
      "childrens": []
    }, {
      "id": "2935",
      "pid": "235",
      "path": ",1,235,2935,",
      "level": "3",
      "name": "印多尔",
      "name_en": "Indore",
      "name_pinyin": "yinduoer",
      "code": "IDR",
      "childrens": []
    }, {
      "id": "2936",
      "pid": "235",
      "path": ",1,235,2936,",
      "level": "3",
      "name": "斋普尔",
      "name_en": "Jaipur",
      "name_pinyin": "zhaipuer",
      "code": "JAI",
      "childrens": []
    }]
  }, {
    "id": "236",
    "pid": "1",
    "path": ",1,236,",
    "level": "2",
    "name": "印度尼西亚",
    "name_en": "Indonesia",
    "name_pinyin": "yindunixiya",
    "code": "IDN",
    "childrens": [{
      "id": "2937",
      "pid": "236",
      "path": ",1,236,2937,",
      "level": "3",
      "name": "巴厘",
      "name_en": "Bali",
      "name_pinyin": "bali",
      "code": "BA",
      "childrens": []
    }, {
      "id": "2938",
      "pid": "236",
      "path": ",1,236,2938,",
      "level": "3",
      "name": "邦加－勿里洞群岛",
      "name_en": "Kepulauan Bangka Belitung",
      "name_pinyin": "bangjiawulidongqundao",
      "code": "BB",
      "childrens": []
    }, {
      "id": "2939",
      "pid": "236",
      "path": ",1,236,2939,",
      "level": "3",
      "name": "北苏拉威西",
      "name_en": "Sulawesi Utara",
      "name_pinyin": "beisulaweixi",
      "code": "SA",
      "childrens": []
    }, {
      "id": "2940",
      "pid": "236",
      "path": ",1,236,2940,",
      "level": "3",
      "name": "北苏门答腊",
      "name_en": "Sumatera Utara",
      "name_pinyin": "beisumendala",
      "code": "SU",
      "childrens": []
    }, {
      "id": "2941",
      "pid": "236",
      "path": ",1,236,2941,",
      "level": "3",
      "name": "大雅加达首都特区",
      "name_en": "Daerah Tingkat I Kalimantan Barat",
      "name_pinyin": "dayajiadashoudutequ",
      "code": "KB",
      "childrens": []
    }, {
      "id": "2942",
      "pid": "236",
      "path": ",1,236,2942,",
      "level": "3",
      "name": "东加里曼丹",
      "name_en": "Kalimantan Timur",
      "name_pinyin": "dongjialimandan",
      "code": "KI",
      "childrens": []
    }, {
      "id": "2943",
      "pid": "236",
      "path": ",1,236,2943,",
      "level": "3",
      "name": "东南苏拉威西",
      "name_en": "Sulawesi Tenggara",
      "name_pinyin": "dongnansulaweixi",
      "code": "SG",
      "childrens": []
    }, {
      "id": "2944",
      "pid": "236",
      "path": ",1,236,2944,",
      "level": "3",
      "name": "东努沙登加拉",
      "name_en": "Nusa Tenggara Timur",
      "name_pinyin": "dongnushadengjiala",
      "code": "NT",
      "childrens": []
    }, {
      "id": "2945",
      "pid": "236",
      "path": ",1,236,2945,",
      "level": "3",
      "name": "东爪哇",
      "name_en": "Java Timur",
      "name_pinyin": "dongzhuawa",
      "code": "JI",
      "childrens": []
    }, {
      "id": "2946",
      "pid": "236",
      "path": ",1,236,2946,",
      "level": "3",
      "name": "廖内",
      "name_en": "Riau",
      "name_pinyin": "liaonei",
      "code": "RI",
      "childrens": []
    }, {
      "id": "2947",
      "pid": "236",
      "path": ",1,236,2947,",
      "level": "3",
      "name": "马鲁古",
      "name_en": "Maluku",
      "name_pinyin": "malugu",
      "code": "MA",
      "childrens": []
    }, {
      "id": "2948",
      "pid": "236",
      "path": ",1,236,2948,",
      "level": "3",
      "name": "明古鲁",
      "name_en": "Bengkulu",
      "name_pinyin": "minggulu",
      "code": "BE",
      "childrens": []
    }, {
      "id": "2949",
      "pid": "236",
      "path": ",1,236,2949,",
      "level": "3",
      "name": "楠榜",
      "name_en": "Lampung",
      "name_pinyin": "bang",
      "code": "LA",
      "childrens": []
    }, {
      "id": "2950",
      "pid": "236",
      "path": ",1,236,2950,",
      "level": "3",
      "name": "南加里曼丹",
      "name_en": "Kalimantan Selatan",
      "name_pinyin": "nanjialimandan",
      "code": "KS",
      "childrens": []
    }, {
      "id": "2951",
      "pid": "236",
      "path": ",1,236,2951,",
      "level": "3",
      "name": "南苏拉威西",
      "name_en": "Sulawesi Selatan",
      "name_pinyin": "nansulaweixi",
      "code": "SN",
      "childrens": []
    }, {
      "id": "2952",
      "pid": "236",
      "path": ",1,236,2952,",
      "level": "3",
      "name": "南苏门答腊",
      "name_en": "Sumatera Selatan",
      "name_pinyin": "nansumendala",
      "code": "SS",
      "childrens": []
    }, {
      "id": "2953",
      "pid": "236",
      "path": ",1,236,2953,",
      "level": "3",
      "name": "日惹特区",
      "name_en": "Daerah Istimewa Yogyakarta",
      "name_pinyin": "riretequ",
      "code": "YO",
      "childrens": []
    }, {
      "id": "2954",
      "pid": "236",
      "path": ",1,236,2954,",
      "level": "3",
      "name": "万丹",
      "name_en": "Banten",
      "name_pinyin": "wandan",
      "code": "BT",
      "childrens": []
    }, {
      "id": "2955",
      "pid": "236",
      "path": ",1,236,2955,",
      "level": "3",
      "name": "西努沙登加拉",
      "name_en": "Nusa Tenggara Barat",
      "name_pinyin": "xinushadengjiala",
      "code": "NB",
      "childrens": []
    }, {
      "id": "2956",
      "pid": "236",
      "path": ",1,236,2956,",
      "level": "3",
      "name": "西苏门答腊",
      "name_en": "Sumatera Barat",
      "name_pinyin": "xisumendala",
      "code": "SR",
      "childrens": []
    }, {
      "id": "2957",
      "pid": "236",
      "path": ",1,236,2957,",
      "level": "3",
      "name": "西爪哇",
      "name_en": "Java Barat",
      "name_pinyin": "xizhuawa",
      "code": "JB",
      "childrens": []
    }, {
      "id": "2958",
      "pid": "236",
      "path": ",1,236,2958,",
      "level": "3",
      "name": "雅加达",
      "name_en": "Jakarta Raya",
      "name_pinyin": "yajiada",
      "code": "JK",
      "childrens": []
    }, {
      "id": "2959",
      "pid": "236",
      "path": ",1,236,2959,",
      "level": "3",
      "name": "亚齐",
      "name_en": "Aceh",
      "name_pinyin": "yaqi",
      "code": "AC",
      "childrens": []
    }, {
      "id": "2960",
      "pid": "236",
      "path": ",1,236,2960,",
      "level": "3",
      "name": "伊里安查亚",
      "name_en": "Irian Jaya",
      "name_pinyin": "yilianchaya",
      "code": "IJ",
      "childrens": []
    }, {
      "id": "2961",
      "pid": "236",
      "path": ",1,236,2961,",
      "level": "3",
      "name": "占碑",
      "name_en": "Jambi",
      "name_pinyin": "zhanbei",
      "code": "JA",
      "childrens": []
    }, {
      "id": "2962",
      "pid": "236",
      "path": ",1,236,2962,",
      "level": "3",
      "name": "中加里曼丹",
      "name_en": "Kalimantan Tengah",
      "name_pinyin": "zhongjialimandan",
      "code": "KT",
      "childrens": []
    }, {
      "id": "2963",
      "pid": "236",
      "path": ",1,236,2963,",
      "level": "3",
      "name": "中苏拉威西",
      "name_en": "Sulawesi Tengah",
      "name_pinyin": "zhongsulaweixi",
      "code": "ST",
      "childrens": []
    }, {
      "id": "2964",
      "pid": "236",
      "path": ",1,236,2964,",
      "level": "3",
      "name": "中爪哇",
      "name_en": "Java Tengah",
      "name_pinyin": "zhongzhuawa",
      "code": "JT",
      "childrens": []
    }]
  }, {
    "id": "239",
    "pid": "1",
    "path": ",1,239,",
    "level": "2",
    "name": "约旦",
    "name_en": "Jordan",
    "name_pinyin": "yuedan",
    "code": "JOR",
    "childrens": [{
      "id": "2965",
      "pid": "239",
      "path": ",1,239,2965,",
      "level": "3",
      "name": "阿吉隆",
      "name_en": "Allun",
      "name_pinyin": "ajilong",
      "code": "AJ",
      "childrens": []
    }, {
      "id": "2966",
      "pid": "239",
      "path": ",1,239,2966,",
      "level": "3",
      "name": "安曼",
      "name_en": "Amman",
      "name_pinyin": "anman",
      "code": "AM",
      "childrens": []
    }, {
      "id": "2967",
      "pid": "239",
      "path": ",1,239,2967,",
      "level": "3",
      "name": "拜勒加",
      "name_en": "Balqa",
      "name_pinyin": "bailejia",
      "code": "BA",
      "childrens": []
    }, {
      "id": "2968",
      "pid": "239",
      "path": ",1,239,2968,",
      "level": "3",
      "name": "杰拉什",
      "name_en": "Jarash",
      "name_pinyin": "jielashi",
      "code": "JA",
      "childrens": []
    }, {
      "id": "2969",
      "pid": "239",
      "path": ",1,239,2969,",
      "level": "3",
      "name": "卡拉克",
      "name_en": "Karak",
      "name_pinyin": "kalake",
      "code": "KA",
      "childrens": []
    }, {
      "id": "2970",
      "pid": "239",
      "path": ",1,239,2970,",
      "level": "3",
      "name": "鲁赛法",
      "name_en": "Rusayfah",
      "name_pinyin": "lusaifa",
      "code": "RU",
      "childrens": []
    }, {
      "id": "2971",
      "pid": "239",
      "path": ",1,239,2971,",
      "level": "3",
      "name": "马安",
      "name_en": "Maan",
      "name_pinyin": "maan",
      "code": "MN",
      "childrens": []
    }, {
      "id": "2972",
      "pid": "239",
      "path": ",1,239,2972,",
      "level": "3",
      "name": "马德巴",
      "name_en": "Madaba",
      "name_pinyin": "madeba",
      "code": "MD",
      "childrens": []
    }, {
      "id": "2973",
      "pid": "239",
      "path": ",1,239,2973,",
      "level": "3",
      "name": "马夫拉克",
      "name_en": "Mafraq",
      "name_pinyin": "mafulake",
      "code": "MF",
      "childrens": []
    }, {
      "id": "2974",
      "pid": "239",
      "path": ",1,239,2974,",
      "level": "3",
      "name": "塔菲拉",
      "name_en": "Tafiela",
      "name_pinyin": "tafeila",
      "code": "TA",
      "childrens": []
    }, {
      "id": "2975",
      "pid": "239",
      "path": ",1,239,2975,",
      "level": "3",
      "name": "亚喀巴",
      "name_en": "Aqaba",
      "name_pinyin": "yakaba",
      "code": "AQ",
      "childrens": []
    }, {
      "id": "2976",
      "pid": "239",
      "path": ",1,239,2976,",
      "level": "3",
      "name": "伊尔比德",
      "name_en": "Irbid",
      "name_pinyin": "yierbide",
      "code": "IR",
      "childrens": []
    }, {
      "id": "2977",
      "pid": "239",
      "path": ",1,239,2977,",
      "level": "3",
      "name": "扎尔卡",
      "name_en": "Zarqa",
      "name_pinyin": "zhaerka",
      "code": "ZA",
      "childrens": []
    }]
  }, {
    "id": "240",
    "pid": "1",
    "path": ",1,240,",
    "level": "2",
    "name": "越南",
    "name_en": "Vietnam",
    "name_pinyin": "yuenan",
    "code": "VNM",
    "childrens": [{
      "id": "2978",
      "pid": "240",
      "path": ",1,240,2978,",
      "level": "3",
      "name": "海防",
      "name_en": "Haiphong",
      "name_pinyin": "haifang",
      "code": "HP",
      "childrens": []
    }, {
      "id": "2979",
      "pid": "240",
      "path": ",1,240,2979,",
      "level": "3",
      "name": "河内",
      "name_en": "Hanoi",
      "name_pinyin": "henei",
      "code": "HI",
      "childrens": []
    }, {
      "id": "2980",
      "pid": "240",
      "path": ",1,240,2980,",
      "level": "3",
      "name": "胡志明市",
      "name_en": "Ho Chi Minh City",
      "name_pinyin": "huzhimingshi",
      "code": "HC",
      "childrens": []
    }]
  }]
}, {
  "id": "2",
  "pid": "0",
  "path": ",2,",
  "level": "1",
  "name": "欧洲",
  "name_en": "Europe",
  "name_pinyin": "ouzhou",
  "code": null,
  "childrens": [{
    "id": "8",
    "pid": "2",
    "path": ",2,8,",
    "level": "2",
    "name": "阿尔巴尼亚",
    "name_en": "Albania",
    "name_pinyin": "aerbaniya",
    "code": "ALB",
    "childrens": [{
      "id": "375",
      "pid": "8",
      "path": ",2,8,375,",
      "level": "3",
      "name": "爱尔巴桑",
      "name_en": "Elbasan",
      "name_pinyin": "aierbasang",
      "code": "EL",
      "childrens": []
    }, {
      "id": "376",
      "pid": "8",
      "path": ",2,8,376,",
      "level": "3",
      "name": "迪勃拉",
      "name_en": "Diber",
      "name_pinyin": "dibola",
      "code": "DI",
      "childrens": []
    }, {
      "id": "377",
      "pid": "8",
      "path": ",2,8,377,",
      "level": "3",
      "name": "地拉那",
      "name_en": "Tirane",
      "name_pinyin": "dilana",
      "code": "TR",
      "childrens": []
    }, {
      "id": "378",
      "pid": "8",
      "path": ",2,8,378,",
      "level": "3",
      "name": "都拉斯",
      "name_en": "Durres",
      "name_pinyin": "dulasi",
      "code": "DR",
      "childrens": []
    }, {
      "id": "379",
      "pid": "8",
      "path": ",2,8,379,",
      "level": "3",
      "name": "发罗拉",
      "name_en": "Vlore",
      "name_pinyin": "faluola",
      "code": "VL",
      "childrens": []
    }, {
      "id": "380",
      "pid": "8",
      "path": ",2,8,380,",
      "level": "3",
      "name": "费里",
      "name_en": "Fier",
      "name_pinyin": "feili",
      "code": "FR",
      "childrens": []
    }, {
      "id": "381",
      "pid": "8",
      "path": ",2,8,381,",
      "level": "3",
      "name": "吉诺卡斯特",
      "name_en": "Gjirokaster",
      "name_pinyin": "jinuokasite",
      "code": "GJ",
      "childrens": []
    }, {
      "id": "382",
      "pid": "8",
      "path": ",2,8,382,",
      "level": "3",
      "name": "科尔察",
      "name_en": "Korce",
      "name_pinyin": "keercha",
      "code": "KO",
      "childrens": []
    }, {
      "id": "383",
      "pid": "8",
      "path": ",2,8,383,",
      "level": "3",
      "name": "库克斯",
      "name_en": "Kukes",
      "name_pinyin": "kukesi",
      "code": "KU",
      "childrens": []
    }, {
      "id": "384",
      "pid": "8",
      "path": ",2,8,384,",
      "level": "3",
      "name": "莱什",
      "name_en": "Lezhe",
      "name_pinyin": "laishi",
      "code": "LE",
      "childrens": []
    }, {
      "id": "385",
      "pid": "8",
      "path": ",2,8,385,",
      "level": "3",
      "name": "培拉特",
      "name_en": "Berat",
      "name_pinyin": "peilate",
      "code": "BR",
      "childrens": []
    }, {
      "id": "386",
      "pid": "8",
      "path": ",2,8,386,",
      "level": "3",
      "name": "斯库台",
      "name_en": "Shkoder",
      "name_pinyin": "sikutai",
      "code": "SH",
      "childrens": []
    }]
  }, {
    "id": "19",
    "pid": "2",
    "path": ",2,19,",
    "level": "2",
    "name": "爱尔兰",
    "name_en": "Ireland",
    "name_pinyin": "aierlan",
    "code": "IRL",
    "childrens": [{
      "id": "515",
      "pid": "19",
      "path": ",2,19,515,",
      "level": "3",
      "name": "奥法利",
      "name_en": "Offaly",
      "name_pinyin": "aofali",
      "code": "OF",
      "childrens": []
    }, {
      "id": "516",
      "pid": "19",
      "path": ",2,19,516,",
      "level": "3",
      "name": "蒂珀雷里",
      "name_en": "Tipperary",
      "name_pinyin": "dileili",
      "code": "TP",
      "childrens": []
    }, {
      "id": "517",
      "pid": "19",
      "path": ",2,19,517,",
      "level": "3",
      "name": "都柏林",
      "name_en": "Dublin",
      "name_pinyin": "dubailin",
      "code": "DB",
      "childrens": []
    }, {
      "id": "518",
      "pid": "19",
      "path": ",2,19,518,",
      "level": "3",
      "name": "多内加尔",
      "name_en": "Donegal",
      "name_pinyin": "duoneijiaer",
      "code": "DG",
      "childrens": []
    }, {
      "id": "519",
      "pid": "19",
      "path": ",2,19,519,",
      "level": "3",
      "name": "戈尔韦",
      "name_en": "Galway",
      "name_pinyin": "geerwei",
      "code": "GW",
      "childrens": []
    }, {
      "id": "520",
      "pid": "19",
      "path": ",2,19,520,",
      "level": "3",
      "name": "基尔代尔",
      "name_en": "Kildare",
      "name_pinyin": "jierdaier",
      "code": "KD",
      "childrens": []
    }, {
      "id": "521",
      "pid": "19",
      "path": ",2,19,521,",
      "level": "3",
      "name": "基尔肯尼",
      "name_en": "Kilkenny",
      "name_pinyin": "jierkenni",
      "code": "KK",
      "childrens": []
    }, {
      "id": "522",
      "pid": "19",
      "path": ",2,19,522,",
      "level": "3",
      "name": "卡范",
      "name_en": "Cavan",
      "name_pinyin": "kafan",
      "code": "CV",
      "childrens": []
    }, {
      "id": "523",
      "pid": "19",
      "path": ",2,19,523,",
      "level": "3",
      "name": "卡洛",
      "name_en": "Carlow",
      "name_pinyin": "kaluo",
      "code": "CW",
      "childrens": []
    }, {
      "id": "524",
      "pid": "19",
      "path": ",2,19,524,",
      "level": "3",
      "name": "凯里",
      "name_en": "Kerry",
      "name_pinyin": "kaili",
      "code": "KR",
      "childrens": []
    }, {
      "id": "525",
      "pid": "19",
      "path": ",2,19,525,",
      "level": "3",
      "name": "科克",
      "name_en": "Cork",
      "name_pinyin": "keke",
      "code": "CK",
      "childrens": []
    }, {
      "id": "526",
      "pid": "19",
      "path": ",2,19,526,",
      "level": "3",
      "name": "克莱尔",
      "name_en": "Clare",
      "name_pinyin": "kelaier",
      "code": "CL",
      "childrens": []
    }, {
      "id": "527",
      "pid": "19",
      "path": ",2,19,527,",
      "level": "3",
      "name": "朗福德",
      "name_en": "Longford",
      "name_pinyin": "langfude",
      "code": "LF",
      "childrens": []
    }, {
      "id": "528",
      "pid": "19",
      "path": ",2,19,528,",
      "level": "3",
      "name": "劳斯",
      "name_en": "Louth",
      "name_pinyin": "laosi",
      "code": "LT",
      "childrens": []
    }, {
      "id": "529",
      "pid": "19",
      "path": ",2,19,529,",
      "level": "3",
      "name": "崂斯",
      "name_en": "Laois",
      "name_pinyin": "si",
      "code": "LA",
      "childrens": []
    }, {
      "id": "530",
      "pid": "19",
      "path": ",2,19,530,",
      "level": "3",
      "name": "利默里克",
      "name_en": "Limerick",
      "name_pinyin": "limolike",
      "code": "LM",
      "childrens": []
    }, {
      "id": "531",
      "pid": "19",
      "path": ",2,19,531,",
      "level": "3",
      "name": "利特里姆",
      "name_en": "Leitrim",
      "name_pinyin": "litelimu",
      "code": "LR",
      "childrens": []
    }, {
      "id": "532",
      "pid": "19",
      "path": ",2,19,532,",
      "level": "3",
      "name": "罗斯康芒",
      "name_en": "Roscommon",
      "name_pinyin": "luosikangmang",
      "code": "RC",
      "childrens": []
    }, {
      "id": "533",
      "pid": "19",
      "path": ",2,19,533,",
      "level": "3",
      "name": "梅奥",
      "name_en": "Mayo",
      "name_pinyin": "meiao",
      "code": "MY",
      "childrens": []
    }, {
      "id": "534",
      "pid": "19",
      "path": ",2,19,534,",
      "level": "3",
      "name": "米斯",
      "name_en": "Meath",
      "name_pinyin": "misi",
      "code": "MT",
      "childrens": []
    }, {
      "id": "535",
      "pid": "19",
      "path": ",2,19,535,",
      "level": "3",
      "name": "莫内根",
      "name_en": "Monaghan",
      "name_pinyin": "moneigen",
      "code": "MG",
      "childrens": []
    }, {
      "id": "536",
      "pid": "19",
      "path": ",2,19,536,",
      "level": "3",
      "name": "斯莱戈",
      "name_en": "Sligo",
      "name_pinyin": "silaige",
      "code": "SL",
      "childrens": []
    }, {
      "id": "537",
      "pid": "19",
      "path": ",2,19,537,",
      "level": "3",
      "name": "威克洛",
      "name_en": "Wicklow",
      "name_pinyin": "weikeluo",
      "code": "WK",
      "childrens": []
    }, {
      "id": "538",
      "pid": "19",
      "path": ",2,19,538,",
      "level": "3",
      "name": "韦克斯福德",
      "name_en": "Wexford",
      "name_pinyin": "weikesifude",
      "code": "WX",
      "childrens": []
    }, {
      "id": "539",
      "pid": "19",
      "path": ",2,19,539,",
      "level": "3",
      "name": "沃特福德",
      "name_en": "Waterford",
      "name_pinyin": "wotefude",
      "code": "WF",
      "childrens": []
    }, {
      "id": "540",
      "pid": "19",
      "path": ",2,19,540,",
      "level": "3",
      "name": "西米斯",
      "name_en": "Westmeath",
      "name_pinyin": "ximisi",
      "code": "WM",
      "childrens": []
    }]
  }, {
    "id": "20",
    "pid": "2",
    "path": ",2,20,",
    "level": "2",
    "name": "爱沙尼亚",
    "name_en": "Estonia",
    "name_pinyin": "aishaniya",
    "code": "EST",
    "childrens": [{
      "id": "541",
      "pid": "20",
      "path": ",2,20,541,",
      "level": "3",
      "name": "贝尔瓦",
      "name_en": "Polva",
      "name_pinyin": "beierwa",
      "code": "65",
      "childrens": []
    }, {
      "id": "542",
      "pid": "20",
      "path": ",2,20,542,",
      "level": "3",
      "name": "哈留",
      "name_en": "Harju",
      "name_pinyin": "haliu",
      "code": "37",
      "childrens": []
    }, {
      "id": "543",
      "pid": "20",
      "path": ",2,20,543,",
      "level": "3",
      "name": "拉普拉",
      "name_en": "Rapla",
      "name_pinyin": "lapula",
      "code": "70",
      "childrens": []
    }, {
      "id": "544",
      "pid": "20",
      "path": ",2,20,544,",
      "level": "3",
      "name": "里亚内",
      "name_en": "Laane",
      "name_pinyin": "liyanei",
      "code": "57",
      "childrens": []
    }, {
      "id": "545",
      "pid": "20",
      "path": ",2,20,545,",
      "level": "3",
      "name": "帕尔努",
      "name_en": "Parnu",
      "name_pinyin": "paernu",
      "code": "67",
      "childrens": []
    }, {
      "id": "546",
      "pid": "20",
      "path": ",2,20,546,",
      "level": "3",
      "name": "萨雷",
      "name_en": "Saare",
      "name_pinyin": "salei",
      "code": "74",
      "childrens": []
    }, {
      "id": "547",
      "pid": "20",
      "path": ",2,20,547,",
      "level": "3",
      "name": "塔尔图",
      "name_en": "Tartu",
      "name_pinyin": "taertu",
      "code": "78",
      "childrens": []
    }, {
      "id": "548",
      "pid": "20",
      "path": ",2,20,548,",
      "level": "3",
      "name": "瓦尔加",
      "name_en": "Valga",
      "name_pinyin": "waerjia",
      "code": "82",
      "childrens": []
    }, {
      "id": "549",
      "pid": "20",
      "path": ",2,20,549,",
      "level": "3",
      "name": "维良地",
      "name_en": "Viljandi",
      "name_pinyin": "weiliangdi",
      "code": "84",
      "childrens": []
    }, {
      "id": "550",
      "pid": "20",
      "path": ",2,20,550,",
      "level": "3",
      "name": "维鲁",
      "name_en": "Laane-Viru",
      "name_pinyin": "weilu",
      "code": "59",
      "childrens": []
    }, {
      "id": "551",
      "pid": "20",
      "path": ",2,20,551,",
      "level": "3",
      "name": "沃鲁",
      "name_en": "Voru",
      "name_pinyin": "wolu",
      "code": "86",
      "childrens": []
    }, {
      "id": "552",
      "pid": "20",
      "path": ",2,20,552,",
      "level": "3",
      "name": "希尤",
      "name_en": "Hiiu",
      "name_pinyin": "xiyou",
      "code": "39",
      "childrens": []
    }, {
      "id": "553",
      "pid": "20",
      "path": ",2,20,553,",
      "level": "3",
      "name": "耶尔韦",
      "name_en": "Jarva",
      "name_pinyin": "yeerwei",
      "code": "51",
      "childrens": []
    }, {
      "id": "554",
      "pid": "20",
      "path": ",2,20,554,",
      "level": "3",
      "name": "耶盖瓦",
      "name_en": "Jogeva",
      "name_pinyin": "yegaiwa",
      "code": "49",
      "childrens": []
    }, {
      "id": "555",
      "pid": "20",
      "path": ",2,20,555,",
      "level": "3",
      "name": "依达－维鲁",
      "name_en": "Ida-Viru",
      "name_pinyin": "yidaweilu",
      "code": "44",
      "childrens": []
    }]
  }, {
    "id": "21",
    "pid": "2",
    "path": ",2,21,",
    "level": "2",
    "name": "安道尔",
    "name_en": "Andorra",
    "name_pinyin": "andaoer",
    "code": "AND",
    "childrens": [{
      "id": "556",
      "pid": "21",
      "path": ",2,21,556,",
      "level": "3",
      "name": "安道尔城",
      "name_en": "Andorra la Vella",
      "name_pinyin": "andaoercheng",
      "code": "7",
      "childrens": []
    }, {
      "id": "557",
      "pid": "21",
      "path": ",2,21,557,",
      "level": "3",
      "name": "奥尔迪诺",
      "name_en": "Ordino",
      "name_pinyin": "aoerdinuo",
      "code": "5",
      "childrens": []
    }, {
      "id": "558",
      "pid": "21",
      "path": ",2,21,558,",
      "level": "3",
      "name": "恩坎普",
      "name_en": "Encamp",
      "name_pinyin": "enkanpu",
      "code": "3",
      "childrens": []
    }, {
      "id": "559",
      "pid": "21",
      "path": ",2,21,559,",
      "level": "3",
      "name": "卡尼略",
      "name_en": "Canillo",
      "name_pinyin": "kanilue",
      "code": "2",
      "childrens": []
    }, {
      "id": "560",
      "pid": "21",
      "path": ",2,21,560,",
      "level": "3",
      "name": "莱塞斯卡尔德－恩戈尔达",
      "name_en": "Escaldes-Engordany",
      "name_pinyin": "laisaisikaerdeengeerda",
      "code": "8",
      "childrens": []
    }, {
      "id": "561",
      "pid": "21",
      "path": ",2,21,561,",
      "level": "3",
      "name": "马萨纳",
      "name_en": "La Massana",
      "name_pinyin": "masana",
      "code": "4",
      "childrens": []
    }, {
      "id": "562",
      "pid": "21",
      "path": ",2,21,562,",
      "level": "3",
      "name": "圣胡利娅－德洛里亚",
      "name_en": "Sant Julia de Laria",
      "name_pinyin": "shenghulideluoliya",
      "code": "6",
      "childrens": []
    }]
  }, {
    "id": "26",
    "pid": "2",
    "path": ",2,26,",
    "level": "2",
    "name": "奥地利",
    "name_en": "Austria",
    "name_pinyin": "aodili",
    "code": "AUT",
    "childrens": [{
      "id": "581",
      "pid": "26",
      "path": ",2,26,581,",
      "level": "3",
      "name": "布尔根兰",
      "name_en": "Burgenland",
      "name_pinyin": "buergenlan",
      "code": "BUR",
      "childrens": []
    }, {
      "id": "582",
      "pid": "26",
      "path": ",2,26,582,",
      "level": "3",
      "name": "蒂罗尔",
      "name_en": "Tyrol",
      "name_pinyin": "diluoer",
      "code": "TYR",
      "childrens": []
    }, {
      "id": "583",
      "pid": "26",
      "path": ",2,26,583,",
      "level": "3",
      "name": "福拉尔贝格",
      "name_en": "Vorarlberg",
      "name_pinyin": "fulaerbeige",
      "code": "VOR",
      "childrens": []
    }, {
      "id": "584",
      "pid": "26",
      "path": ",2,26,584,",
      "level": "3",
      "name": "克恩顿",
      "name_en": "Carinthia",
      "name_pinyin": "keendun",
      "code": "CAT",
      "childrens": []
    }, {
      "id": "585",
      "pid": "26",
      "path": ",2,26,585,",
      "level": "3",
      "name": "萨尔茨堡",
      "name_en": "Salzburg",
      "name_pinyin": "saercibao",
      "code": "SZG",
      "childrens": []
    }, {
      "id": "586",
      "pid": "26",
      "path": ",2,26,586,",
      "level": "3",
      "name": "上奥地利",
      "name_en": "Upper Austria",
      "name_pinyin": "shangaodili",
      "code": "UAU",
      "childrens": []
    }, {
      "id": "587",
      "pid": "26",
      "path": ",2,26,587,",
      "level": "3",
      "name": "施蒂利亚",
      "name_en": "Styria",
      "name_pinyin": "shidiliya",
      "code": "STY",
      "childrens": []
    }, {
      "id": "588",
      "pid": "26",
      "path": ",2,26,588,",
      "level": "3",
      "name": "维也纳",
      "name_en": "Vienna",
      "name_pinyin": "weiyena",
      "code": "VDD",
      "childrens": []
    }, {
      "id": "589",
      "pid": "26",
      "path": ",2,26,589,",
      "level": "3",
      "name": "下奥地利",
      "name_en": "Lower Austria",
      "name_pinyin": "xiaaodili",
      "code": "LAU",
      "childrens": []
    }]
  }, {
    "id": "27",
    "pid": "2",
    "path": ",2,27,",
    "level": "2",
    "name": "奥兰群岛",
    "name_en": "Aland lslands",
    "name_pinyin": "aolanqundao",
    "code": "ALA",
    "childrens": []
  }, {
    "id": "37",
    "pid": "2",
    "path": ",2,37,",
    "level": "2",
    "name": "白俄罗斯",
    "name_en": "White Russia",
    "name_pinyin": "baieluosi",
    "code": "BLR",
    "childrens": [{
      "id": "674",
      "pid": "37",
      "path": ",2,37,674,",
      "level": "3",
      "name": "布列斯特",
      "name_en": "Bresckaja",
      "name_pinyin": "buliesite",
      "code": "BR",
      "childrens": []
    }, {
      "id": "675",
      "pid": "37",
      "path": ",2,37,675,",
      "level": "3",
      "name": "戈梅利",
      "name_en": "Homelskaja",
      "name_pinyin": "gemeili",
      "code": "HO",
      "childrens": []
    }, {
      "id": "676",
      "pid": "37",
      "path": ",2,37,676,",
      "level": "3",
      "name": "格罗德诺",
      "name_en": "Hrodzenskaja",
      "name_pinyin": "geluodenuo",
      "code": "HR",
      "childrens": []
    }, {
      "id": "677",
      "pid": "37",
      "path": ",2,37,677,",
      "level": "3",
      "name": "明斯克市",
      "name_en": "Minsk",
      "name_pinyin": "mingsikeshi",
      "code": "MI",
      "childrens": []
    }, {
      "id": "678",
      "pid": "37",
      "path": ",2,37,678,",
      "level": "3",
      "name": "莫吉廖夫",
      "name_en": "Mahileuskaja",
      "name_pinyin": "mojiliaofu",
      "code": "MA",
      "childrens": []
    }, {
      "id": "679",
      "pid": "37",
      "path": ",2,37,679,",
      "level": "3",
      "name": "维捷布斯克",
      "name_en": "Vicebskaja",
      "name_pinyin": "weijiebusike",
      "code": "VI",
      "childrens": []
    }]
  }, {
    "id": "39",
    "pid": "2",
    "path": ",2,39,",
    "level": "2",
    "name": "保加利亚",
    "name_en": "Bulgaria",
    "name_pinyin": "baojialiya",
    "code": "BGR",
    "childrens": [{
      "id": "680",
      "pid": "39",
      "path": ",2,39,680,",
      "level": "3",
      "name": "布尔加斯",
      "name_en": "Burgas",
      "name_pinyin": "buerjiasi",
      "code": "BOJ",
      "childrens": []
    }, {
      "id": "681",
      "pid": "39",
      "path": ",2,39,681,",
      "level": "3",
      "name": "卡斯科伏",
      "name_en": "Khaskovo",
      "name_pinyin": "kasikefu",
      "code": "KHO",
      "childrens": []
    }, {
      "id": "682",
      "pid": "39",
      "path": ",2,39,682,",
      "level": "3",
      "name": "鲁塞",
      "name_en": "Ruse",
      "name_pinyin": "lusai",
      "code": "ROU",
      "childrens": []
    }, {
      "id": "683",
      "pid": "39",
      "path": ",2,39,683,",
      "level": "3",
      "name": "洛维奇",
      "name_en": "Lovech",
      "name_pinyin": "luoweiqi",
      "code": "LVP",
      "childrens": []
    }, {
      "id": "684",
      "pid": "39",
      "path": ",2,39,684,",
      "level": "3",
      "name": "蒙塔纳",
      "name_en": "Montana",
      "name_pinyin": "mengtana",
      "code": "OZA",
      "childrens": []
    }, {
      "id": "685",
      "pid": "39",
      "path": ",2,39,685,",
      "level": "3",
      "name": "普罗夫迪夫",
      "name_en": "Plovdiv",
      "name_pinyin": "puluofudifu",
      "code": "PDV",
      "childrens": []
    }, {
      "id": "686",
      "pid": "39",
      "path": ",2,39,686,",
      "level": "3",
      "name": "索非亚",
      "name_en": "Sofiya",
      "name_pinyin": "suofeiya",
      "code": "SOF",
      "childrens": []
    }, {
      "id": "687",
      "pid": "39",
      "path": ",2,39,687,",
      "level": "3",
      "name": "索非亚市",
      "name_en": "Grad Sofiya",
      "name_pinyin": "suofeiyashi",
      "code": "GSO",
      "childrens": []
    }, {
      "id": "688",
      "pid": "39",
      "path": ",2,39,688,",
      "level": "3",
      "name": "瓦尔纳",
      "name_en": "Varna",
      "name_pinyin": "waerna",
      "code": "VAR",
      "childrens": []
    }]
  }, {
    "id": "42",
    "pid": "2",
    "path": ",2,42,",
    "level": "2",
    "name": "比利时",
    "name_en": "Belgium",
    "name_pinyin": "bilishi",
    "code": "BEL",
    "childrens": [{
      "id": "702",
      "pid": "42",
      "path": ",2,42,702,",
      "level": "3",
      "name": "埃诺",
      "name_en": "Hainaut",
      "name_pinyin": "ainuo",
      "code": "WHT",
      "childrens": []
    }, {
      "id": "703",
      "pid": "42",
      "path": ",2,42,703,",
      "level": "3",
      "name": "安特卫普",
      "name_en": "Antwerpen",
      "name_pinyin": "anteweipu",
      "code": "VAN",
      "childrens": []
    }, {
      "id": "704",
      "pid": "42",
      "path": ",2,42,704,",
      "level": "3",
      "name": "布拉班特-瓦隆",
      "name_en": "Brabant-Wallone",
      "name_pinyin": "bulabantewalong",
      "code": "WBR",
      "childrens": []
    }, {
      "id": "705",
      "pid": "42",
      "path": ",2,42,705,",
      "level": "3",
      "name": "布鲁塞尔",
      "name_en": "Brussels",
      "name_pinyin": "bulusaier",
      "code": "BRU",
      "childrens": []
    }, {
      "id": "706",
      "pid": "42",
      "path": ",2,42,706,",
      "level": "3",
      "name": "东佛兰德",
      "name_en": "Oost-Vlaanderen",
      "name_pinyin": "dongfolande",
      "code": "VOV",
      "childrens": []
    }, {
      "id": "707",
      "pid": "42",
      "path": ",2,42,707,",
      "level": "3",
      "name": "佛兰芒-布拉班特",
      "name_en": "Vlaams-Brabant",
      "name_pinyin": "folanmangbulabante",
      "code": "VBR",
      "childrens": []
    }, {
      "id": "708",
      "pid": "42",
      "path": ",2,42,708,",
      "level": "3",
      "name": "列日",
      "name_en": "Liege",
      "name_pinyin": "lieri",
      "code": "WLG",
      "childrens": []
    }, {
      "id": "709",
      "pid": "42",
      "path": ",2,42,709,",
      "level": "3",
      "name": "林堡",
      "name_en": "Limburg",
      "name_pinyin": "linbao",
      "code": "VLI",
      "childrens": []
    }, {
      "id": "710",
      "pid": "42",
      "path": ",2,42,710,",
      "level": "3",
      "name": "卢森堡",
      "name_en": "Luxembourg",
      "name_pinyin": "lusenbao",
      "code": "WLX",
      "childrens": []
    }, {
      "id": "711",
      "pid": "42",
      "path": ",2,42,711,",
      "level": "3",
      "name": "那慕尔",
      "name_en": "Namur",
      "name_pinyin": "namuer",
      "code": "WNA",
      "childrens": []
    }, {
      "id": "712",
      "pid": "42",
      "path": ",2,42,712,",
      "level": "3",
      "name": "西佛兰德",
      "name_en": "West-Vlaanderen",
      "name_pinyin": "xifolande",
      "code": "VWV",
      "childrens": []
    }]
  }, {
    "id": "43",
    "pid": "2",
    "path": ",2,43,",
    "level": "2",
    "name": "冰岛",
    "name_en": "Iceland",
    "name_pinyin": "bingdao",
    "code": "ISL",
    "childrens": []
  }, {
    "id": "45",
    "pid": "2",
    "path": ",2,45,",
    "level": "2",
    "name": "波兰",
    "name_en": "Poland",
    "name_pinyin": "bolan",
    "code": "POL",
    "childrens": [{
      "id": "713",
      "pid": "45",
      "path": ",2,45,713,",
      "level": "3",
      "name": "埃尔布隆格",
      "name_en": "Elbląg",
      "name_pinyin": "aierbulongge",
      "code": "ELB",
      "childrens": []
    }, {
      "id": "714",
      "pid": "45",
      "path": ",2,45,714,",
      "level": "3",
      "name": "奥尔什丁",
      "name_en": "Olsztyn",
      "name_pinyin": "aoershiding",
      "code": "OLS",
      "childrens": []
    }, {
      "id": "715",
      "pid": "45",
      "path": ",2,45,715,",
      "level": "3",
      "name": "奥斯特罗文卡",
      "name_en": "Ostrołeka",
      "name_pinyin": "aositeluowenka",
      "code": "OSS",
      "childrens": []
    }, {
      "id": "716",
      "pid": "45",
      "path": ",2,45,716,",
      "level": "3",
      "name": "比得哥什",
      "name_en": "Bydgoszcz",
      "name_pinyin": "bidegeshi",
      "code": "BZG",
      "childrens": []
    }, {
      "id": "717",
      "pid": "45",
      "path": ",2,45,717,",
      "level": "3",
      "name": "彼得库夫",
      "name_en": "Piotrkow",
      "name_pinyin": "bidekufu",
      "code": "PIO",
      "childrens": []
    }, {
      "id": "718",
      "pid": "45",
      "path": ",2,45,718,",
      "level": "3",
      "name": "比托姆",
      "name_en": "Bytom",
      "name_pinyin": "bituomu",
      "code": "BYT",
      "childrens": []
    }, {
      "id": "719",
      "pid": "45",
      "path": ",2,45,719,",
      "level": "3",
      "name": "比亚瓦波德拉斯卡",
      "name_en": "Biała Podlaska",
      "name_pinyin": "biyawabodelasika",
      "code": "BAP",
      "childrens": []
    }, {
      "id": "720",
      "pid": "45",
      "path": ",2,45,720,",
      "level": "3",
      "name": "比亚维斯托克",
      "name_en": "Białystok",
      "name_pinyin": "biyaweisituoke",
      "code": "BIA",
      "childrens": []
    }, {
      "id": "721",
      "pid": "45",
      "path": ",2,45,721,",
      "level": "3",
      "name": "波莱",
      "name_en": "Opole",
      "name_pinyin": "bolai",
      "code": "OPO",
      "childrens": []
    }, {
      "id": "722",
      "pid": "45",
      "path": ",2,45,722,",
      "level": "3",
      "name": "波兹南",
      "name_en": "Poznan",
      "name_pinyin": "bozinan",
      "code": "POZ",
      "childrens": []
    }, {
      "id": "723",
      "pid": "45",
      "path": ",2,45,723,",
      "level": "3",
      "name": "达布罗瓦戈尼察",
      "name_en": "Dabrowa Gornicza",
      "name_pinyin": "dabuluowagenicha",
      "code": "DAB",
      "childrens": []
    }, {
      "id": "724",
      "pid": "45",
      "path": ",2,45,724,",
      "level": "3",
      "name": "大波兰地区戈茹夫",
      "name_en": "Gorzow Wlkp",
      "name_pinyin": "dabolandiqugerufu",
      "code": "GOW",
      "childrens": []
    }, {
      "id": "725",
      "pid": "45",
      "path": ",2,45,725,",
      "level": "3",
      "name": "弗罗茨瓦夫",
      "name_en": "Wroclaw",
      "name_pinyin": "fuluociwafu",
      "code": "WRO",
      "childrens": []
    }, {
      "id": "726",
      "pid": "45",
      "path": ",2,45,726,",
      "level": "3",
      "name": "弗沃茨瓦韦克",
      "name_en": "Wlocławek",
      "name_pinyin": "fuwociwaweike",
      "code": "WLO",
      "childrens": []
    }, {
      "id": "727",
      "pid": "45",
      "path": ",2,45,727,",
      "level": "3",
      "name": "格但斯克",
      "name_en": "Gdansk",
      "name_pinyin": "gedansike",
      "code": "GDN",
      "childrens": []
    }, {
      "id": "728",
      "pid": "45",
      "path": ",2,45,728,",
      "level": "3",
      "name": "格丁尼亚",
      "name_en": "Gdynia",
      "name_pinyin": "gedingniya",
      "code": "GDY",
      "childrens": []
    }, {
      "id": "729",
      "pid": "45",
      "path": ",2,45,729,",
      "level": "3",
      "name": "格利维采",
      "name_en": "Gliwice",
      "name_pinyin": "geliweicai",
      "code": "GWC",
      "childrens": []
    }, {
      "id": "730",
      "pid": "45",
      "path": ",2,45,730,",
      "level": "3",
      "name": "格鲁琼兹",
      "name_en": "Grudziadz",
      "name_pinyin": "geluqiongzi",
      "code": "GRU",
      "childrens": []
    }, {
      "id": "731",
      "pid": "45",
      "path": ",2,45,731,",
      "level": "3",
      "name": "海乌姆",
      "name_en": "Chełm",
      "name_pinyin": "haiwumu",
      "code": "CHO",
      "childrens": []
    }, {
      "id": "732",
      "pid": "45",
      "path": ",2,45,732,",
      "level": "3",
      "name": "华沙",
      "name_en": "Warszawa",
      "name_pinyin": "huasha",
      "code": "WAW",
      "childrens": []
    }, {
      "id": "733",
      "pid": "45",
      "path": ",2,45,733,",
      "level": "3",
      "name": "霍茹夫",
      "name_en": "Chorzow",
      "name_pinyin": "huorufu",
      "code": "CHZ",
      "childrens": []
    }, {
      "id": "734",
      "pid": "45",
      "path": ",2,45,734,",
      "level": "3",
      "name": "卡利什",
      "name_en": "Kalisz",
      "name_pinyin": "kalishi",
      "code": "KAL",
      "childrens": []
    }, {
      "id": "735",
      "pid": "45",
      "path": ",2,45,735,",
      "level": "3",
      "name": "卡托维兹",
      "name_en": "Katowice",
      "name_pinyin": "katuoweizi",
      "code": "KTW",
      "childrens": []
    }, {
      "id": "736",
      "pid": "45",
      "path": ",2,45,736,",
      "level": "3",
      "name": "凯尔采",
      "name_en": "Kielce",
      "name_pinyin": "kaiercai",
      "code": "KLC",
      "childrens": []
    }, {
      "id": "737",
      "pid": "45",
      "path": ",2,45,737,",
      "level": "3",
      "name": "科宁",
      "name_en": "Konin",
      "name_pinyin": "kening",
      "code": "KON",
      "childrens": []
    }, {
      "id": "738",
      "pid": "45",
      "path": ",2,45,738,",
      "level": "3",
      "name": "科沙林",
      "name_en": "Koszalin",
      "name_pinyin": "keshalin",
      "code": "OSZ",
      "childrens": []
    }, {
      "id": "739",
      "pid": "45",
      "path": ",2,45,739,",
      "level": "3",
      "name": "克拉科夫",
      "name_en": "Krakow",
      "name_pinyin": "kelakefu",
      "code": "KRK",
      "childrens": []
    }, {
      "id": "740",
      "pid": "45",
      "path": ",2,45,740,",
      "level": "3",
      "name": "克罗斯诺",
      "name_en": "Krosno",
      "name_pinyin": "keluosinuo",
      "code": "KRO",
      "childrens": []
    }, {
      "id": "741",
      "pid": "45",
      "path": ",2,45,741,",
      "level": "3",
      "name": "拉多姆",
      "name_en": "Radom",
      "name_pinyin": "laduomu",
      "code": "RDM",
      "childrens": []
    }, {
      "id": "742",
      "pid": "45",
      "path": ",2,45,742,",
      "level": "3",
      "name": "莱格尼察",
      "name_en": "Legnica",
      "name_pinyin": "laigenicha",
      "code": "LEG",
      "childrens": []
    }, {
      "id": "743",
      "pid": "45",
      "path": ",2,45,743,",
      "level": "3",
      "name": "莱什诺",
      "name_en": "Leszno",
      "name_pinyin": "laishinuo",
      "code": "LEZ",
      "childrens": []
    }, {
      "id": "744",
      "pid": "45",
      "path": ",2,45,744,",
      "level": "3",
      "name": "卢布林",
      "name_en": "Lublin",
      "name_pinyin": "lubulin",
      "code": "LUL",
      "childrens": []
    }, {
      "id": "745",
      "pid": "45",
      "path": ",2,45,745,",
      "level": "3",
      "name": "鲁达",
      "name_en": "Ruda Sl",
      "name_pinyin": "luda",
      "code": "RDS",
      "childrens": []
    }, {
      "id": "746",
      "pid": "45",
      "path": ",2,45,746,",
      "level": "3",
      "name": "罗兹",
      "name_en": "Lodz",
      "name_pinyin": "luozi",
      "code": "LOD",
      "childrens": []
    }, {
      "id": "747",
      "pid": "45",
      "path": ",2,45,747,",
      "level": "3",
      "name": "绿山城",
      "name_en": "Zielona Gora",
      "name_pinyin": "lvshancheng",
      "code": "IEG",
      "childrens": []
    }, {
      "id": "748",
      "pid": "45",
      "path": ",2,45,748,",
      "level": "3",
      "name": "米什洛维采",
      "name_en": "Mysłowice",
      "name_pinyin": "mishiluoweicai",
      "code": "MYL",
      "childrens": []
    }, {
      "id": "749",
      "pid": "45",
      "path": ",2,45,749,",
      "level": "3",
      "name": "皮瓦",
      "name_en": "Piła",
      "name_pinyin": "piwa",
      "code": "PIL",
      "childrens": []
    }, {
      "id": "750",
      "pid": "45",
      "path": ",2,45,750,",
      "level": "3",
      "name": "普热梅希尔",
      "name_en": "Przemysl",
      "name_pinyin": "puremeixier",
      "code": "PRZ",
      "childrens": []
    }, {
      "id": "751",
      "pid": "45",
      "path": ",2,45,751,",
      "level": "3",
      "name": "普沃茨克",
      "name_en": "Plock",
      "name_pinyin": "puwocike",
      "code": "PLO",
      "childrens": []
    }, {
      "id": "752",
      "pid": "45",
      "path": ",2,45,752,",
      "level": "3",
      "name": "切哈努夫",
      "name_en": "Ciechanow",
      "name_pinyin": "qiehanufu",
      "code": "CIE",
      "childrens": []
    }, {
      "id": "753",
      "pid": "45",
      "path": ",2,45,753,",
      "level": "3",
      "name": "热舒夫",
      "name_en": "Rzeszow",
      "name_pinyin": "reshufu",
      "code": "RZE",
      "childrens": []
    }, {
      "id": "754",
      "pid": "45",
      "path": ",2,45,754,",
      "level": "3",
      "name": "什切青",
      "name_en": "Szczecin",
      "name_pinyin": "shiqieqing",
      "code": "SZZ",
      "childrens": []
    }, {
      "id": "755",
      "pid": "45",
      "path": ",2,45,755,",
      "level": "3",
      "name": "斯凯尔涅维采",
      "name_en": "Skierniewice",
      "name_pinyin": "sikaiernieweicai",
      "code": "SKI",
      "childrens": []
    }, {
      "id": "756",
      "pid": "45",
      "path": ",2,45,756,",
      "level": "3",
      "name": "斯武普斯克",
      "name_en": "Slupsk",
      "name_pinyin": "siwupusike",
      "code": "SLP",
      "childrens": []
    }, {
      "id": "757",
      "pid": "45",
      "path": ",2,45,757,",
      "level": "3",
      "name": "苏瓦乌基",
      "name_en": "Suwałki",
      "name_pinyin": "suwawuji",
      "code": "SWL",
      "childrens": []
    }, {
      "id": "758",
      "pid": "45",
      "path": ",2,45,758,",
      "level": "3",
      "name": "索波特",
      "name_en": "Sopot",
      "name_pinyin": "suobote",
      "code": "SOP",
      "childrens": []
    }, {
      "id": "759",
      "pid": "45",
      "path": ",2,45,759,",
      "level": "3",
      "name": "索斯诺维茨",
      "name_en": "Sosnowiec",
      "name_pinyin": "suosinuoweici",
      "code": "SWC",
      "childrens": []
    }, {
      "id": "760",
      "pid": "45",
      "path": ",2,45,760,",
      "level": "3",
      "name": "塔尔努夫",
      "name_en": "Tarnow",
      "name_pinyin": "taernufu",
      "code": "TAR",
      "childrens": []
    }, {
      "id": "761",
      "pid": "45",
      "path": ",2,45,761,",
      "level": "3",
      "name": "塔尔诺布热格",
      "name_en": "Tarnobrzeg",
      "name_pinyin": "taernuoburege",
      "code": "QEP",
      "childrens": []
    }, {
      "id": "762",
      "pid": "45",
      "path": ",2,45,762,",
      "level": "3",
      "name": "特切",
      "name_en": "Tychy",
      "name_pinyin": "teqie",
      "code": "TYY",
      "childrens": []
    }, {
      "id": "763",
      "pid": "45",
      "path": ",2,45,763,",
      "level": "3",
      "name": "托伦",
      "name_en": "Torun",
      "name_pinyin": "tuolun",
      "code": "TOR",
      "childrens": []
    }, {
      "id": "764",
      "pid": "45",
      "path": ",2,45,764,",
      "level": "3",
      "name": "瓦乌布日赫",
      "name_en": "Walbrzych",
      "name_pinyin": "wawuburihe",
      "code": "WZH",
      "childrens": []
    }, {
      "id": "765",
      "pid": "45",
      "path": ",2,45,765,",
      "level": "3",
      "name": "沃姆扎",
      "name_en": "Lomza",
      "name_pinyin": "womuzha",
      "code": "QOY",
      "childrens": []
    }, {
      "id": "766",
      "pid": "45",
      "path": ",2,45,766,",
      "level": "3",
      "name": "希米亚诺维采",
      "name_en": "Siemianowice Sl",
      "name_pinyin": "ximiyanuoweicai",
      "code": "SOW",
      "childrens": []
    }, {
      "id": "767",
      "pid": "45",
      "path": ",2,45,767,",
      "level": "3",
      "name": "希维诺乌伊希切",
      "name_en": "Swinoujscie",
      "name_pinyin": "xiweinuowuyixiqie",
      "code": "SWI",
      "childrens": []
    }, {
      "id": "768",
      "pid": "45",
      "path": ",2,45,768,",
      "level": "3",
      "name": "希维托赫洛维采",
      "name_en": "Swietochłowice",
      "name_pinyin": "xiweituoheluoweicai",
      "code": "SWT",
      "childrens": []
    }, {
      "id": "769",
      "pid": "45",
      "path": ",2,45,769,",
      "level": "3",
      "name": "谢德尔采",
      "name_en": "Siedlce",
      "name_pinyin": "xiedeercai",
      "code": "SDC",
      "childrens": []
    }, {
      "id": "770",
      "pid": "45",
      "path": ",2,45,770,",
      "level": "3",
      "name": "谢拉兹",
      "name_en": "Sieradz",
      "name_pinyin": "xielazi",
      "code": "SIR",
      "childrens": []
    }, {
      "id": "771",
      "pid": "45",
      "path": ",2,45,771,",
      "level": "3",
      "name": "新松奇",
      "name_en": "Nowy Sacz",
      "name_pinyin": "xinsongqi",
      "code": "NOW",
      "childrens": []
    }, {
      "id": "772",
      "pid": "45",
      "path": ",2,45,772,",
      "level": "3",
      "name": "雅沃兹诺",
      "name_en": "Jaworzno",
      "name_pinyin": "yawozinuo",
      "code": "JAW",
      "childrens": []
    }, {
      "id": "773",
      "pid": "45",
      "path": ",2,45,773,",
      "level": "3",
      "name": "耶莱尼亚古拉",
      "name_en": "Jelenia Gora",
      "name_pinyin": "yelainiyagula",
      "code": "JEG",
      "childrens": []
    }, {
      "id": "774",
      "pid": "45",
      "path": ",2,45,774,",
      "level": "3",
      "name": "扎布热",
      "name_en": "Zabrze",
      "name_pinyin": "zhabure",
      "code": "ZAB",
      "childrens": []
    }, {
      "id": "775",
      "pid": "45",
      "path": ",2,45,775,",
      "level": "3",
      "name": "扎莫希奇",
      "name_en": "Zamosc",
      "name_pinyin": "zhamoxiqi",
      "code": "ZAM",
      "childrens": []
    }]
  }, {
    "id": "47",
    "pid": "2",
    "path": ",2,47,",
    "level": "2",
    "name": "波斯尼亚和黑塞哥维那",
    "name_en": "Bosnia and Herzegovina",
    "name_pinyin": "bosiniyaheheisaigeweina",
    "code": "BIH",
    "childrens": [{
      "id": "788",
      "pid": "47",
      "path": ",2,47,788,",
      "level": "3",
      "name": "波萨维纳",
      "name_en": "Posavski",
      "name_pinyin": "bosaweina",
      "code": "FPO",
      "childrens": []
    }, {
      "id": "789",
      "pid": "47",
      "path": ",2,47,789,",
      "level": "3",
      "name": "波斯尼亚－波德里涅",
      "name_en": "Bosansko-Podrinjski",
      "name_pinyin": "bosiniyabodelinie",
      "code": "FBP",
      "childrens": []
    }, {
      "id": "790",
      "pid": "47",
      "path": ",2,47,790,",
      "level": "3",
      "name": "多米斯拉夫格勒",
      "name_en": "Tomislavgrad",
      "name_pinyin": "duomisilafugele",
      "code": "FTO",
      "childrens": []
    }, {
      "id": "791",
      "pid": "47",
      "path": ",2,47,791,",
      "level": "3",
      "name": "黑塞哥维那－涅雷特瓦",
      "name_en": "Hercegovacko-Neretvanski",
      "name_pinyin": "heisaigeweinanieleitewa",
      "code": "FHN",
      "childrens": []
    }, {
      "id": "792",
      "pid": "47",
      "path": ",2,47,792,",
      "level": "3",
      "name": "萨拉热窝",
      "name_en": "Sarajevo",
      "name_pinyin": "salarewo",
      "code": "FSA",
      "childrens": []
    }, {
      "id": "793",
      "pid": "47",
      "path": ",2,47,793,",
      "level": "3",
      "name": "图兹拉－波德里涅",
      "name_en": "Tuzlanski-Podrinjski",
      "name_pinyin": "tuzilabodelinie",
      "code": "FTU",
      "childrens": []
    }, {
      "id": "794",
      "pid": "47",
      "path": ",2,47,794,",
      "level": "3",
      "name": "乌纳－萨纳",
      "name_en": "Unsko-Sanski",
      "name_pinyin": "wunasana",
      "code": "FUS",
      "childrens": []
    }, {
      "id": "795",
      "pid": "47",
      "path": ",2,47,795,",
      "level": "3",
      "name": "西波斯尼亚",
      "name_en": "Hercegovacko-Bosanski",
      "name_pinyin": "xibosiniya",
      "code": "FHB",
      "childrens": []
    }, {
      "id": "796",
      "pid": "47",
      "path": ",2,47,796,",
      "level": "3",
      "name": "西黑塞哥维那",
      "name_en": "Zapadno-Hercegovaki",
      "name_pinyin": "xiheisaigeweina",
      "code": "FZH",
      "childrens": []
    }, {
      "id": "797",
      "pid": "47",
      "path": ",2,47,797,",
      "level": "3",
      "name": "泽尼察－多博伊",
      "name_en": "Zenicko-Dobojski",
      "name_pinyin": "zenichaduoboyi",
      "code": "FZE",
      "childrens": []
    }, {
      "id": "798",
      "pid": "47",
      "path": ",2,47,798,",
      "level": "3",
      "name": "中波斯尼亚",
      "name_en": "Srednjo-Bosanski",
      "name_pinyin": "zhongbosiniya",
      "code": "FSB",
      "childrens": []
    }]
  }, {
    "id": "53",
    "pid": "2",
    "path": ",2,53,",
    "level": "2",
    "name": "布韦岛",
    "name_en": "Bouvet Island",
    "name_pinyin": "buweidao",
    "code": "BVT",
    "childrens": []
  }, {
    "id": "55",
    "pid": "2",
    "path": ",2,55,",
    "level": "2",
    "name": "丹麦",
    "name_en": "Denmark",
    "name_pinyin": "danmai",
    "code": "DNK",
    "childrens": [{
      "id": "879",
      "pid": "55",
      "path": ",2,55,879,",
      "level": "3",
      "name": "奥胡斯",
      "name_en": "Aarhus",
      "name_pinyin": "aohusi",
      "code": "AR",
      "childrens": []
    }, {
      "id": "880",
      "pid": "55",
      "path": ",2,55,880,",
      "level": "3",
      "name": "北日德兰",
      "name_en": "Nordjylland",
      "name_pinyin": "beiridelan",
      "code": "VSV",
      "childrens": []
    }, {
      "id": "881",
      "pid": "55",
      "path": ",2,55,881,",
      "level": "3",
      "name": "博恩霍尔姆",
      "name_en": "Bornholm",
      "name_pinyin": "boenhuoermu",
      "code": "BO",
      "childrens": []
    }, {
      "id": "882",
      "pid": "55",
      "path": ",2,55,882,",
      "level": "3",
      "name": "菲特烈堡",
      "name_en": "Frederiksborg",
      "name_pinyin": "feiteliebao",
      "code": "FRE",
      "childrens": []
    }, {
      "id": "883",
      "pid": "55",
      "path": ",2,55,883,",
      "level": "3",
      "name": "菲茵",
      "name_en": "Fyn",
      "name_pinyin": "feiyin",
      "code": "FY",
      "childrens": []
    }, {
      "id": "884",
      "pid": "55",
      "path": ",2,55,884,",
      "level": "3",
      "name": "哥本哈根",
      "name_en": "Copenhagen",
      "name_pinyin": "gebenhagen",
      "code": "CPH",
      "childrens": []
    }, {
      "id": "885",
      "pid": "55",
      "path": ",2,55,885,",
      "level": "3",
      "name": "里伯",
      "name_en": "Ribe",
      "name_pinyin": "libo",
      "code": "RIB",
      "childrens": []
    }, {
      "id": "886",
      "pid": "55",
      "path": ",2,55,886,",
      "level": "3",
      "name": "灵克宾",
      "name_en": "Ringkoebing",
      "name_pinyin": "lingkebin",
      "code": "RKG",
      "childrens": []
    }, {
      "id": "887",
      "pid": "55",
      "path": ",2,55,887,",
      "level": "3",
      "name": "罗斯基勒",
      "name_en": "Roskilde",
      "name_pinyin": "luosijile",
      "code": "RKE",
      "childrens": []
    }, {
      "id": "888",
      "pid": "55",
      "path": ",2,55,888,",
      "level": "3",
      "name": "南日德兰",
      "name_en": "Soenderjylland",
      "name_pinyin": "nanridelan",
      "code": "VBI",
      "childrens": []
    }, {
      "id": "889",
      "pid": "55",
      "path": ",2,55,889,",
      "level": "3",
      "name": "斯多斯特姆",
      "name_en": "Storstroem",
      "name_pinyin": "siduositemu",
      "code": "ST",
      "childrens": []
    }, {
      "id": "890",
      "pid": "55",
      "path": ",2,55,890,",
      "level": "3",
      "name": "维堡",
      "name_en": "Viborg",
      "name_pinyin": "weibao",
      "code": "VIB",
      "childrens": []
    }, {
      "id": "891",
      "pid": "55",
      "path": ",2,55,891,",
      "level": "3",
      "name": "维厄勒",
      "name_en": "Vejle",
      "name_pinyin": "weiele",
      "code": "VEJ",
      "childrens": []
    }, {
      "id": "892",
      "pid": "55",
      "path": ",2,55,892,",
      "level": "3",
      "name": "西希兰",
      "name_en": "Vestsjaelland",
      "name_pinyin": "xixilan",
      "code": "VS",
      "childrens": []
    }]
  }, {
    "id": "56",
    "pid": "2",
    "path": ",2,56,",
    "level": "2",
    "name": "德国",
    "name_en": "Germany",
    "name_pinyin": "deguo",
    "code": "DEU",
    "childrens": [{
      "id": "893",
      "pid": "56",
      "path": ",2,56,893,",
      "level": "3",
      "name": "阿恩斯贝格",
      "name_en": "Arnsberg",
      "name_pinyin": "aensibeige",
      "code": "ARN",
      "childrens": []
    }, {
      "id": "894",
      "pid": "56",
      "path": ",2,56,894,",
      "level": "3",
      "name": "爱尔福特",
      "name_en": "Erfurt",
      "name_pinyin": "aierfute",
      "code": "ERF",
      "childrens": []
    }, {
      "id": "895",
      "pid": "56",
      "path": ",2,56,895,",
      "level": "3",
      "name": "安斯巴格",
      "name_en": "Ansbach",
      "name_pinyin": "ansibage",
      "code": "ANS",
      "childrens": []
    }, {
      "id": "896",
      "pid": "56",
      "path": ",2,56,896,",
      "level": "3",
      "name": "奥格斯堡",
      "name_en": "Augsburg",
      "name_pinyin": "aogesibao",
      "code": "AGB",
      "childrens": []
    }, {
      "id": "897",
      "pid": "56",
      "path": ",2,56,897,",
      "level": "3",
      "name": "柏林",
      "name_en": "Berlin",
      "name_pinyin": "bailin",
      "code": "BE",
      "childrens": []
    }, {
      "id": "898",
      "pid": "56",
      "path": ",2,56,898,",
      "level": "3",
      "name": "拜伊罗特",
      "name_en": "Bayreuth",
      "name_pinyin": "baiyiluote",
      "code": "BYU",
      "childrens": []
    }, {
      "id": "899",
      "pid": "56",
      "path": ",2,56,899,",
      "level": "3",
      "name": "比勒费尔德",
      "name_en": "Bielefeld",
      "name_pinyin": "bilefeierde",
      "code": "BFE",
      "childrens": []
    }, {
      "id": "900",
      "pid": "56",
      "path": ",2,56,900,",
      "level": "3",
      "name": "波茨坦",
      "name_en": "Potsdam",
      "name_pinyin": "bocitan",
      "code": "POT",
      "childrens": []
    }, {
      "id": "901",
      "pid": "56",
      "path": ",2,56,901,",
      "level": "3",
      "name": "波鸿",
      "name_en": "Bochum",
      "name_pinyin": "bohong",
      "code": "BOM",
      "childrens": []
    }, {
      "id": "902",
      "pid": "56",
      "path": ",2,56,902,",
      "level": "3",
      "name": "不来梅",
      "name_en": "Bremen",
      "name_pinyin": "bulaimei",
      "code": "HB",
      "childrens": []
    }, {
      "id": "903",
      "pid": "56",
      "path": ",2,56,903,",
      "level": "3",
      "name": "不伦瑞克",
      "name_en": "Brunswick",
      "name_pinyin": "bulunruike",
      "code": "BRW",
      "childrens": []
    }, {
      "id": "904",
      "pid": "56",
      "path": ",2,56,904,",
      "level": "3",
      "name": "达姆施塔特",
      "name_en": "Darmstadt",
      "name_pinyin": "damushitate",
      "code": "DAR",
      "childrens": []
    }, {
      "id": "905",
      "pid": "56",
      "path": ",2,56,905,",
      "level": "3",
      "name": "代特莫尔特",
      "name_en": "Detmold",
      "name_pinyin": "daitemoerte",
      "code": "DET",
      "childrens": []
    }, {
      "id": "906",
      "pid": "56",
      "path": ",2,56,906,",
      "level": "3",
      "name": "德累斯顿",
      "name_en": "Dresden",
      "name_pinyin": "deleisidun",
      "code": "DRS",
      "childrens": []
    }, {
      "id": "907",
      "pid": "56",
      "path": ",2,56,907,",
      "level": "3",
      "name": "德绍",
      "name_en": "Dessau",
      "name_pinyin": "deshao",
      "code": "DES",
      "childrens": []
    }, {
      "id": "908",
      "pid": "56",
      "path": ",2,56,908,",
      "level": "3",
      "name": "杜塞尔多夫",
      "name_en": "Dusseldorf",
      "name_pinyin": "dusaierduofu",
      "code": "DUS",
      "childrens": []
    }, {
      "id": "909",
      "pid": "56",
      "path": ",2,56,909,",
      "level": "3",
      "name": "法兰克福",
      "name_en": "Frankfurt",
      "name_pinyin": "falankefu",
      "code": "FFO",
      "childrens": []
    }, {
      "id": "910",
      "pid": "56",
      "path": ",2,56,910,",
      "level": "3",
      "name": "弗赖堡",
      "name_en": "Freiburg",
      "name_pinyin": "fulaibao",
      "code": "FBG",
      "childrens": []
    }, {
      "id": "911",
      "pid": "56",
      "path": ",2,56,911,",
      "level": "3",
      "name": "哈雷",
      "name_en": "Halle",
      "name_pinyin": "halei",
      "code": "HAE",
      "childrens": []
    }, {
      "id": "912",
      "pid": "56",
      "path": ",2,56,912,",
      "level": "3",
      "name": "汉堡",
      "name_en": "Hamburg",
      "name_pinyin": "hanbao",
      "code": "HH",
      "childrens": []
    }, {
      "id": "913",
      "pid": "56",
      "path": ",2,56,913,",
      "level": "3",
      "name": "汉诺威",
      "name_en": "Hannover",
      "name_pinyin": "hannuowei",
      "code": "HAJ",
      "childrens": []
    }, {
      "id": "914",
      "pid": "56",
      "path": ",2,56,914,",
      "level": "3",
      "name": "基尔",
      "name_en": "Kiel",
      "name_pinyin": "jier",
      "code": "KEL",
      "childrens": []
    }, {
      "id": "915",
      "pid": "56",
      "path": ",2,56,915,",
      "level": "3",
      "name": "吉森",
      "name_en": "GieBen",
      "name_pinyin": "jisen",
      "code": "GBN",
      "childrens": []
    }, {
      "id": "916",
      "pid": "56",
      "path": ",2,56,916,",
      "level": "3",
      "name": "卡尔斯鲁厄",
      "name_en": "Karlsruhe",
      "name_pinyin": "kaersilue",
      "code": "KAE",
      "childrens": []
    }, {
      "id": "917",
      "pid": "56",
      "path": ",2,56,917,",
      "level": "3",
      "name": "卡塞尔",
      "name_en": "Kassel",
      "name_pinyin": "kasaier",
      "code": "KAS",
      "childrens": []
    }, {
      "id": "918",
      "pid": "56",
      "path": ",2,56,918,",
      "level": "3",
      "name": "开姆尼斯",
      "name_en": "Chemnitz",
      "name_pinyin": "kaimunisi",
      "code": "CHE",
      "childrens": []
    }, {
      "id": "919",
      "pid": "56",
      "path": ",2,56,919,",
      "level": "3",
      "name": "科布伦次",
      "name_en": "Koblenz",
      "name_pinyin": "kebulunci",
      "code": "KOB",
      "childrens": []
    }, {
      "id": "920",
      "pid": "56",
      "path": ",2,56,920,",
      "level": "3",
      "name": "科隆",
      "name_en": "Cologne",
      "name_pinyin": "kelong",
      "code": "CGN",
      "childrens": []
    }, {
      "id": "921",
      "pid": "56",
      "path": ",2,56,921,",
      "level": "3",
      "name": "莱比锡",
      "name_en": "Leipzig",
      "name_pinyin": "laibixi",
      "code": "LEJ",
      "childrens": []
    }, {
      "id": "922",
      "pid": "56",
      "path": ",2,56,922,",
      "level": "3",
      "name": "兰茨胡特",
      "name_en": "Landshut",
      "name_pinyin": "lancihute",
      "code": "LDH",
      "childrens": []
    }, {
      "id": "923",
      "pid": "56",
      "path": ",2,56,923,",
      "level": "3",
      "name": "吕讷堡",
      "name_en": "Luneburg",
      "name_pinyin": "lvbao",
      "code": "LBG",
      "childrens": []
    }, {
      "id": "924",
      "pid": "56",
      "path": ",2,56,924,",
      "level": "3",
      "name": "马格德堡",
      "name_en": "Magdeburg",
      "name_pinyin": "magedebao",
      "code": "MAG",
      "childrens": []
    }, {
      "id": "925",
      "pid": "56",
      "path": ",2,56,925,",
      "level": "3",
      "name": "曼海姆",
      "name_en": "Mannheim",
      "name_pinyin": "manhaimu",
      "code": "MHG",
      "childrens": []
    }, {
      "id": "926",
      "pid": "56",
      "path": ",2,56,926,",
      "level": "3",
      "name": "美因兹",
      "name_en": "Mainz",
      "name_pinyin": "meiyinzi",
      "code": "MAI",
      "childrens": []
    }, {
      "id": "927",
      "pid": "56",
      "path": ",2,56,927,",
      "level": "3",
      "name": "明斯特",
      "name_en": "Muenster",
      "name_pinyin": "mingsite",
      "code": "MUN",
      "childrens": []
    }, {
      "id": "928",
      "pid": "56",
      "path": ",2,56,928,",
      "level": "3",
      "name": "慕尼黑",
      "name_en": "Munich",
      "name_pinyin": "munihei",
      "code": "MUC",
      "childrens": []
    }, {
      "id": "929",
      "pid": "56",
      "path": ",2,56,929,",
      "level": "3",
      "name": "纽伦堡",
      "name_en": "Nuremberg",
      "name_pinyin": "niulunbao",
      "code": "NUE",
      "childrens": []
    }, {
      "id": "930",
      "pid": "56",
      "path": ",2,56,930,",
      "level": "3",
      "name": "什未林",
      "name_en": "Schwerin",
      "name_pinyin": "shiweilin",
      "code": "SWH",
      "childrens": []
    }, {
      "id": "931",
      "pid": "56",
      "path": ",2,56,931,",
      "level": "3",
      "name": "斯图加特",
      "name_en": "Stuttgart",
      "name_pinyin": "situjiate",
      "code": "STR",
      "childrens": []
    }, {
      "id": "932",
      "pid": "56",
      "path": ",2,56,932,",
      "level": "3",
      "name": "特里尔",
      "name_en": "Trier",
      "name_pinyin": "telier",
      "code": "TRI",
      "childrens": []
    }, {
      "id": "933",
      "pid": "56",
      "path": ",2,56,933,",
      "level": "3",
      "name": "威斯巴登",
      "name_en": "Wiesbaden",
      "name_pinyin": "weisibadeng",
      "code": "WIB",
      "childrens": []
    }, {
      "id": "934",
      "pid": "56",
      "path": ",2,56,934,",
      "level": "3",
      "name": "维尔茨堡",
      "name_en": "Wuerzburg",
      "name_pinyin": "weiercibao",
      "code": "WUG",
      "childrens": []
    }]
  }, {
    "id": "61",
    "pid": "2",
    "path": ",2,61,",
    "level": "2",
    "name": "俄罗斯",
    "name_en": "Russia",
    "name_pinyin": "eluosi",
    "code": "RUS",
    "childrens": [{
      "id": "953",
      "pid": "61",
      "path": ",2,61,953,",
      "level": "3",
      "name": "阿巴坎",
      "name_en": "Abakan",
      "name_pinyin": "abakan",
      "code": "ABA",
      "childrens": []
    }, {
      "id": "954",
      "pid": "61",
      "path": ",2,61,954,",
      "level": "3",
      "name": "阿尔汉格尔斯克",
      "name_en": "Arkhangelsk",
      "name_pinyin": "aerhangeersike",
      "code": "ARK",
      "childrens": []
    }, {
      "id": "955",
      "pid": "61",
      "path": ",2,61,955,",
      "level": "3",
      "name": "阿金斯科耶",
      "name_en": "Aginskoye",
      "name_pinyin": "ajinsikeye",
      "code": "AGI",
      "childrens": []
    }, {
      "id": "956",
      "pid": "61",
      "path": ",2,61,956,",
      "level": "3",
      "name": "阿纳德尔",
      "name_en": "Anadyr",
      "name_pinyin": "anadeer",
      "code": "DYR",
      "childrens": []
    }, {
      "id": "957",
      "pid": "61",
      "path": ",2,61,957,",
      "level": "3",
      "name": "阿斯特拉罕",
      "name_en": "Astrakhan",
      "name_pinyin": "asitelahan",
      "code": "AST",
      "childrens": []
    }, {
      "id": "958",
      "pid": "61",
      "path": ",2,61,958,",
      "level": "3",
      "name": "埃利斯塔",
      "name_en": "Elista",
      "name_pinyin": "ailisita",
      "code": "ESL",
      "childrens": []
    }, {
      "id": "959",
      "pid": "61",
      "path": ",2,61,959,",
      "level": "3",
      "name": "奥廖尔",
      "name_en": "Orel",
      "name_pinyin": "aoliaoer",
      "code": "ORL",
      "childrens": []
    }, {
      "id": "960",
      "pid": "61",
      "path": ",2,61,960,",
      "level": "3",
      "name": "奥伦堡",
      "name_en": "Orenburg",
      "name_pinyin": "aolunbao",
      "code": "ORE",
      "childrens": []
    }, {
      "id": "961",
      "pid": "61",
      "path": ",2,61,961,",
      "level": "3",
      "name": "巴尔瑙尔",
      "name_en": "Barnaul",
      "name_pinyin": "baerer",
      "code": "BAX",
      "childrens": []
    }, {
      "id": "962",
      "pid": "61",
      "path": ",2,61,962,",
      "level": "3",
      "name": "奔萨",
      "name_en": "Penza",
      "name_pinyin": "bensa",
      "code": "PNZ",
      "childrens": []
    }, {
      "id": "963",
      "pid": "61",
      "path": ",2,61,963,",
      "level": "3",
      "name": "彼得罗巴甫洛夫斯克",
      "name_en": "Petropavlovsk-Kamchatskiy",
      "name_pinyin": "bideluobafuluofusike",
      "code": "PKC",
      "childrens": []
    }, {
      "id": "964",
      "pid": "61",
      "path": ",2,61,964,",
      "level": "3",
      "name": "彼得罗扎沃茨克",
      "name_en": "Petrozavodsk",
      "name_pinyin": "bideluozhawocike",
      "code": "PES",
      "childrens": []
    }, {
      "id": "965",
      "pid": "61",
      "path": ",2,61,965,",
      "level": "3",
      "name": "彼尔姆",
      "name_en": "Perm",
      "name_pinyin": "biermu",
      "code": "PER",
      "childrens": []
    }, {
      "id": "966",
      "pid": "61",
      "path": ",2,61,966,",
      "level": "3",
      "name": "比罗比詹",
      "name_en": "Birobidzan",
      "name_pinyin": "biluobizhan",
      "code": "BBZ",
      "childrens": []
    }, {
      "id": "967",
      "pid": "61",
      "path": ",2,61,967,",
      "level": "3",
      "name": "别尔哥罗德",
      "name_en": "Belgorod",
      "name_pinyin": "bieergeluode",
      "code": "BEL",
      "childrens": []
    }, {
      "id": "968",
      "pid": "61",
      "path": ",2,61,968,",
      "level": "3",
      "name": "伯力",
      "name_en": "Chabarovsk",
      "name_pinyin": "boli",
      "code": "COK",
      "childrens": []
    }, {
      "id": "969",
      "pid": "61",
      "path": ",2,61,969,",
      "level": "3",
      "name": "布拉戈维申斯克",
      "name_en": "Blagoveshchensk",
      "name_pinyin": "bulageweishensike",
      "code": "BQS",
      "childrens": []
    }, {
      "id": "970",
      "pid": "61",
      "path": ",2,61,970,",
      "level": "3",
      "name": "布良斯克",
      "name_en": "Bryansk",
      "name_pinyin": "buliangsike",
      "code": "BRY",
      "childrens": []
    }, {
      "id": "971",
      "pid": "61",
      "path": ",2,61,971,",
      "level": "3",
      "name": "车里雅宾斯克",
      "name_en": "Chelyabinsk",
      "name_pinyin": "cheliyabinsike",
      "code": "CHE",
      "childrens": []
    }, {
      "id": "972",
      "pid": "61",
      "path": ",2,61,972,",
      "level": "3",
      "name": "赤塔",
      "name_en": "Chita",
      "name_pinyin": "chita",
      "code": "CHI",
      "childrens": []
    }, {
      "id": "973",
      "pid": "61",
      "path": ",2,61,973,",
      "level": "3",
      "name": "顿河畔罗斯托夫",
      "name_en": "Rostov-na-Donu",
      "name_pinyin": "dunhepanluosituofu",
      "code": "ROS",
      "childrens": []
    }, {
      "id": "974",
      "pid": "61",
      "path": ",2,61,974,",
      "level": "3",
      "name": "鄂木斯克",
      "name_en": "Omsk",
      "name_pinyin": "emusike",
      "code": "OMS",
      "childrens": []
    }, {
      "id": "975",
      "pid": "61",
      "path": ",2,61,975,",
      "level": "3",
      "name": "伏尔加格勒",
      "name_en": "Volgograd",
      "name_pinyin": "fuerjiagele",
      "code": "VOG",
      "childrens": []
    }, {
      "id": "976",
      "pid": "61",
      "path": ",2,61,976,",
      "level": "3",
      "name": "弗拉基米尔",
      "name_en": "Vladimir",
      "name_pinyin": "fulajimier",
      "code": "VMR",
      "childrens": []
    }, {
      "id": "977",
      "pid": "61",
      "path": ",2,61,977,",
      "level": "3",
      "name": "弗拉季高加索",
      "name_en": "Vladikavkaz",
      "name_pinyin": "fulajigaojiasuo",
      "code": "VLA",
      "childrens": []
    }, {
      "id": "978",
      "pid": "61",
      "path": ",2,61,978,",
      "level": "3",
      "name": "戈尔诺－阿尔泰斯克",
      "name_en": "Gorno-Altajsk",
      "name_pinyin": "geernuoaertaisike",
      "code": "GOA",
      "childrens": []
    }, {
      "id": "979",
      "pid": "61",
      "path": ",2,61,979,",
      "level": "3",
      "name": "格罗兹尼",
      "name_en": "Grozny",
      "name_pinyin": "geluozini",
      "code": "GRV",
      "childrens": []
    }, {
      "id": "980",
      "pid": "61",
      "path": ",2,61,980,",
      "level": "3",
      "name": "海参崴",
      "name_en": "Vladivostok",
      "name_pinyin": "haican",
      "code": "VVO",
      "childrens": []
    }, {
      "id": "981",
      "pid": "61",
      "path": ",2,61,981,",
      "level": "3",
      "name": "汉特－曼西斯克",
      "name_en": "Khanty-Mansiysk",
      "name_pinyin": "hantemanxisike",
      "code": "KHM",
      "childrens": []
    }, {
      "id": "982",
      "pid": "61",
      "path": ",2,61,982,",
      "level": "3",
      "name": "基洛夫",
      "name_en": "Kirov",
      "name_pinyin": "jiluofu",
      "code": "KIR",
      "childrens": []
    }, {
      "id": "983",
      "pid": "61",
      "path": ",2,61,983,",
      "level": "3",
      "name": "加里宁格勒",
      "name_en": "Kaliningrad",
      "name_pinyin": "jialininggele",
      "code": "KGD",
      "childrens": []
    }, {
      "id": "984",
      "pid": "61",
      "path": ",2,61,984,",
      "level": "3",
      "name": "喀山",
      "name_en": "Kazan",
      "name_pinyin": "kashan",
      "code": "KZN",
      "childrens": []
    }, {
      "id": "985",
      "pid": "61",
      "path": ",2,61,985,",
      "level": "3",
      "name": "卡卢加",
      "name_en": "Kaluga",
      "name_pinyin": "kalujia",
      "code": "KLF",
      "childrens": []
    }, {
      "id": "986",
      "pid": "61",
      "path": ",2,61,986,",
      "level": "3",
      "name": "科斯特罗马",
      "name_en": "Kostroma",
      "name_pinyin": "kesiteluoma",
      "code": "KOS",
      "childrens": []
    }, {
      "id": "987",
      "pid": "61",
      "path": ",2,61,987,",
      "level": "3",
      "name": "克拉斯诺达尔",
      "name_en": "Krasnodar",
      "name_pinyin": "kelasinuodaer",
      "code": "KRR",
      "childrens": []
    }, {
      "id": "988",
      "pid": "61",
      "path": ",2,61,988,",
      "level": "3",
      "name": "克拉斯诺亚尔斯克",
      "name_en": "Krasnojarsk",
      "name_pinyin": "kelasinuoyaersike",
      "code": "KYA",
      "childrens": []
    }, {
      "id": "989",
      "pid": "61",
      "path": ",2,61,989,",
      "level": "3",
      "name": "克麦罗沃",
      "name_en": "Kemerovo",
      "name_pinyin": "kemailuowo",
      "code": "KEM",
      "childrens": []
    }, {
      "id": "990",
      "pid": "61",
      "path": ",2,61,990,",
      "level": "3",
      "name": "克孜勒",
      "name_en": "Kyzyl",
      "name_pinyin": "kezile",
      "code": "KYZ",
      "childrens": []
    }, {
      "id": "991",
      "pid": "61",
      "path": ",2,61,991,",
      "level": "3",
      "name": "库德姆卡尔",
      "name_en": "Kudymkar",
      "name_pinyin": "kudemukaer",
      "code": "KUD",
      "childrens": []
    }, {
      "id": "992",
      "pid": "61",
      "path": ",2,61,992,",
      "level": "3",
      "name": "库尔干",
      "name_en": "Kurgan",
      "name_pinyin": "kuergan",
      "code": "KRO",
      "childrens": []
    }, {
      "id": "993",
      "pid": "61",
      "path": ",2,61,993,",
      "level": "3",
      "name": "库尔斯克",
      "name_en": "Kursk",
      "name_pinyin": "kuersike",
      "code": "URS",
      "childrens": []
    }, {
      "id": "994",
      "pid": "61",
      "path": ",2,61,994,",
      "level": "3",
      "name": "利佩茨克",
      "name_en": "Lipeck",
      "name_pinyin": "lipeicike",
      "code": "LIP",
      "childrens": []
    }, {
      "id": "995",
      "pid": "61",
      "path": ",2,61,995,",
      "level": "3",
      "name": "梁赞",
      "name_en": "Ryazan",
      "name_pinyin": "liangzan",
      "code": "RYA",
      "childrens": []
    }, {
      "id": "996",
      "pid": "61",
      "path": ",2,61,996,",
      "level": "3",
      "name": "马哈奇卡拉",
      "name_en": "Makhachkala",
      "name_pinyin": "mahaqikala",
      "code": "MCX",
      "childrens": []
    }, {
      "id": "997",
      "pid": "61",
      "path": ",2,61,997,",
      "level": "3",
      "name": "马加丹",
      "name_en": "Magadan",
      "name_pinyin": "majiadan",
      "code": "MAG",
      "childrens": []
    }, {
      "id": "998",
      "pid": "61",
      "path": ",2,61,998,",
      "level": "3",
      "name": "马加斯",
      "name_en": "Magas",
      "name_pinyin": "majiasi",
      "code": "IN",
      "childrens": []
    }, {
      "id": "999",
      "pid": "61",
      "path": ",2,61,999,",
      "level": "3",
      "name": "迈科普",
      "name_en": "Maykop",
      "name_pinyin": "maikepu",
      "code": "MAY",
      "childrens": []
    }, {
      "id": "1000",
      "pid": "61",
      "path": ",2,61,1000,",
      "level": "3",
      "name": "摩尔曼斯克",
      "name_en": "Murmansk",
      "name_pinyin": "moermansike",
      "code": "MMK",
      "childrens": []
    }, {
      "id": "1001",
      "pid": "61",
      "path": ",2,61,1001,",
      "level": "3",
      "name": "莫斯科",
      "name_en": "Moscow",
      "name_pinyin": "mosike",
      "code": "MOW",
      "childrens": []
    }, {
      "id": "1002",
      "pid": "61",
      "path": ",2,61,1002,",
      "level": "3",
      "name": "纳尔奇克",
      "name_en": "Nalchik",
      "name_pinyin": "naerqike",
      "code": "NAL",
      "childrens": []
    }, {
      "id": "1003",
      "pid": "61",
      "path": ",2,61,1003,",
      "level": "3",
      "name": "纳里扬马尔",
      "name_en": "Naryan-Mar",
      "name_pinyin": "naliyangmaer",
      "code": "NNM",
      "childrens": []
    }, {
      "id": "1004",
      "pid": "61",
      "path": ",2,61,1004,",
      "level": "3",
      "name": "南萨哈林斯克",
      "name_en": "Juzno-Sachalinsk",
      "name_pinyin": "nansahalinsike",
      "code": "JSA",
      "childrens": []
    }, {
      "id": "1005",
      "pid": "61",
      "path": ",2,61,1005,",
      "level": "3",
      "name": "诺夫哥罗德",
      "name_en": "Velikij Novgorod",
      "name_pinyin": "nuofugeluode",
      "code": "VUS",
      "childrens": []
    }, {
      "id": "1006",
      "pid": "61",
      "path": ",2,61,1006,",
      "level": "3",
      "name": "帕拉纳",
      "name_en": "Palana",
      "name_pinyin": "palana",
      "code": "PAL",
      "childrens": []
    }, {
      "id": "1007",
      "pid": "61",
      "path": ",2,61,1007,",
      "level": "3",
      "name": "普斯科夫",
      "name_en": "Pskov",
      "name_pinyin": "pusikefu",
      "code": "PSK",
      "childrens": []
    }, {
      "id": "1008",
      "pid": "61",
      "path": ",2,61,1008,",
      "level": "3",
      "name": "切博克萨雷",
      "name_en": "Cheboksary",
      "name_pinyin": "qiebokesalei",
      "code": "CSY",
      "childrens": []
    }, {
      "id": "1009",
      "pid": "61",
      "path": ",2,61,1009,",
      "level": "3",
      "name": "切尔克斯克",
      "name_en": "Cherkessk",
      "name_pinyin": "qieerkesike",
      "code": "CKS",
      "childrens": []
    }, {
      "id": "1010",
      "pid": "61",
      "path": ",2,61,1010,",
      "level": "3",
      "name": "秋明",
      "name_en": "Tyumen",
      "name_pinyin": "qiuming",
      "code": "TYU",
      "childrens": []
    }, {
      "id": "1011",
      "pid": "61",
      "path": ",2,61,1011,",
      "level": "3",
      "name": "萨拉托夫",
      "name_en": "Saratov",
      "name_pinyin": "salatuofu",
      "code": "SAR",
      "childrens": []
    }, {
      "id": "1012",
      "pid": "61",
      "path": ",2,61,1012,",
      "level": "3",
      "name": "萨兰斯克",
      "name_en": "Saransk",
      "name_pinyin": "salansike",
      "code": "SKX",
      "childrens": []
    }, {
      "id": "1013",
      "pid": "61",
      "path": ",2,61,1013,",
      "level": "3",
      "name": "萨列哈尔德",
      "name_en": "Salekhard",
      "name_pinyin": "saliehaerde",
      "code": "SLY",
      "childrens": []
    }, {
      "id": "1014",
      "pid": "61",
      "path": ",2,61,1014,",
      "level": "3",
      "name": "萨马拉",
      "name_en": "Samara",
      "name_pinyin": "samala",
      "code": "SAM",
      "childrens": []
    }, {
      "id": "1015",
      "pid": "61",
      "path": ",2,61,1015,",
      "level": "3",
      "name": "瑟克特夫卡尔",
      "name_en": "Syktyvkar",
      "name_pinyin": "seketefukaer",
      "code": "SCW",
      "childrens": []
    }, {
      "id": "1016",
      "pid": "61",
      "path": ",2,61,1016,",
      "level": "3",
      "name": "圣彼得堡",
      "name_en": "St. Peterburg",
      "name_pinyin": "shengbidebao",
      "code": "SPE",
      "childrens": []
    }, {
      "id": "1017",
      "pid": "61",
      "path": ",2,61,1017,",
      "level": "3",
      "name": "斯摩棱斯克",
      "name_en": "Smolensk",
      "name_pinyin": "simolengsike",
      "code": "LNX",
      "childrens": []
    }, {
      "id": "1018",
      "pid": "61",
      "path": ",2,61,1018,",
      "level": "3",
      "name": "斯塔夫罗波尔",
      "name_en": "Stavropol",
      "name_pinyin": "sitafuluoboer",
      "code": "STA",
      "childrens": []
    }, {
      "id": "1019",
      "pid": "61",
      "path": ",2,61,1019,",
      "level": "3",
      "name": "坦波夫",
      "name_en": "Tambov",
      "name_pinyin": "tanbofu",
      "code": "TAM",
      "childrens": []
    }, {
      "id": "1020",
      "pid": "61",
      "path": ",2,61,1020,",
      "level": "3",
      "name": "特维尔",
      "name_en": "Tver",
      "name_pinyin": "teweier",
      "code": "TVE",
      "childrens": []
    }, {
      "id": "1021",
      "pid": "61",
      "path": ",2,61,1021,",
      "level": "3",
      "name": "图拉",
      "name_en": "Tula",
      "name_pinyin": "tula",
      "code": "TUL",
      "childrens": []
    }, {
      "id": "1022",
      "pid": "61",
      "path": ",2,61,1022,",
      "level": "3",
      "name": "托木斯克",
      "name_en": "Tomsk",
      "name_pinyin": "tuomusike",
      "code": "TOM",
      "childrens": []
    }, {
      "id": "1023",
      "pid": "61",
      "path": ",2,61,1023,",
      "level": "3",
      "name": "沃罗涅什",
      "name_en": "Voronezh",
      "name_pinyin": "woluonieshi",
      "code": "VOR",
      "childrens": []
    }, {
      "id": "1024",
      "pid": "61",
      "path": ",2,61,1024,",
      "level": "3",
      "name": "沃洛格达",
      "name_en": "Vologda",
      "name_pinyin": "woluogeda",
      "code": "VLG",
      "childrens": []
    }, {
      "id": "1025",
      "pid": "61",
      "path": ",2,61,1025,",
      "level": "3",
      "name": "乌法",
      "name_en": "Ufa",
      "name_pinyin": "wufa",
      "code": "UFA",
      "childrens": []
    }, {
      "id": "1026",
      "pid": "61",
      "path": ",2,61,1026,",
      "level": "3",
      "name": "乌兰乌德",
      "name_en": "Ulan-Ude",
      "name_pinyin": "wulanwude",
      "code": "UUD",
      "childrens": []
    }, {
      "id": "1027",
      "pid": "61",
      "path": ",2,61,1027,",
      "level": "3",
      "name": "乌里扬诺夫斯克",
      "name_en": "Uljanovsk",
      "name_pinyin": "wuliyangnuofusike",
      "code": "ULY",
      "childrens": []
    }, {
      "id": "1028",
      "pid": "61",
      "path": ",2,61,1028,",
      "level": "3",
      "name": "乌斯季奥尔登斯基",
      "name_en": "Ust-Ordynsky",
      "name_pinyin": "wusijiaoerdengsiji",
      "code": "UOB",
      "childrens": []
    }, {
      "id": "1029",
      "pid": "61",
      "path": ",2,61,1029,",
      "level": "3",
      "name": "下诺夫哥罗德",
      "name_en": "Niznij Novgorod",
      "name_pinyin": "xianuofugeluode",
      "code": "GOJ",
      "childrens": []
    }, {
      "id": "1030",
      "pid": "61",
      "path": ",2,61,1030,",
      "level": "3",
      "name": "新西伯利亚",
      "name_en": "Novosibirsk",
      "name_pinyin": "xinxiboliya",
      "code": "NVS",
      "childrens": []
    }, {
      "id": "1031",
      "pid": "61",
      "path": ",2,61,1031,",
      "level": "3",
      "name": "雅库茨克",
      "name_en": "Jakutsk",
      "name_pinyin": "yakucike",
      "code": "JAK",
      "childrens": []
    }, {
      "id": "1032",
      "pid": "61",
      "path": ",2,61,1032,",
      "level": "3",
      "name": "雅罗斯拉夫尔",
      "name_en": "Jaroslavl",
      "name_pinyin": "yaluosilafuer",
      "code": "JAR",
      "childrens": []
    }, {
      "id": "1033",
      "pid": "61",
      "path": ",2,61,1033,",
      "level": "3",
      "name": "叶卡捷林堡",
      "name_en": "Jekaterinburg",
      "name_pinyin": "yekajielinbao",
      "code": "JEK",
      "childrens": []
    }, {
      "id": "1034",
      "pid": "61",
      "path": ",2,61,1034,",
      "level": "3",
      "name": "伊尔库茨克",
      "name_en": "Irkutsk",
      "name_pinyin": "yierkucike",
      "code": "IKT",
      "childrens": []
    }, {
      "id": "1035",
      "pid": "61",
      "path": ",2,61,1035,",
      "level": "3",
      "name": "伊热夫斯克",
      "name_en": "Izhevsk",
      "name_pinyin": "yirefusike",
      "code": "IJK",
      "childrens": []
    }, {
      "id": "1036",
      "pid": "61",
      "path": ",2,61,1036,",
      "level": "3",
      "name": "伊万诺沃",
      "name_en": "Ivanovo",
      "name_pinyin": "yiwannuowo",
      "code": "IVO",
      "childrens": []
    }, {
      "id": "1037",
      "pid": "61",
      "path": ",2,61,1037,",
      "level": "3",
      "name": "约什卡尔奥拉",
      "name_en": "Yoshkar-Ola",
      "name_pinyin": "yueshikaeraola",
      "code": "YOL",
      "childrens": []
    }]
  }, {
    "id": "64",
    "pid": "2",
    "path": ",2,64,",
    "level": "2",
    "name": "法国",
    "name_en": "France",
    "name_pinyin": "faguo",
    "code": "FRA",
    "childrens": [{
      "id": "1065",
      "pid": "64",
      "path": ",2,64,1065,",
      "level": "3",
      "name": "阿尔勒",
      "name_en": "Arles",
      "name_pinyin": "aerle",
      "code": "ARL",
      "childrens": []
    }, {
      "id": "1066",
      "pid": "64",
      "path": ",2,64,1066,",
      "level": "3",
      "name": "阿雅克修",
      "name_en": "Ajaccio",
      "name_pinyin": "ayakexiu",
      "code": "AJA",
      "childrens": []
    }, {
      "id": "1067",
      "pid": "64",
      "path": ",2,64,1067,",
      "level": "3",
      "name": "艾克斯",
      "name_en": "Aix-en-Provence",
      "name_pinyin": "aikesi",
      "code": "QXB",
      "childrens": []
    }, {
      "id": "1068",
      "pid": "64",
      "path": ",2,64,1068,",
      "level": "3",
      "name": "奥尔良",
      "name_en": "Orleans",
      "name_pinyin": "aoerliang",
      "code": "ORR",
      "childrens": []
    }, {
      "id": "1069",
      "pid": "64",
      "path": ",2,64,1069,",
      "level": "3",
      "name": "巴黎",
      "name_en": "Paris",
      "name_pinyin": "bali",
      "code": "PAR",
      "childrens": []
    }, {
      "id": "1070",
      "pid": "64",
      "path": ",2,64,1070,",
      "level": "3",
      "name": "贝桑松",
      "name_en": "Besancon",
      "name_pinyin": "beisangsong",
      "code": "BSN",
      "childrens": []
    }, {
      "id": "1071",
      "pid": "64",
      "path": ",2,64,1071,",
      "level": "3",
      "name": "第戎",
      "name_en": "Dijon",
      "name_pinyin": "dirong",
      "code": "DIJ",
      "childrens": []
    }, {
      "id": "1072",
      "pid": "64",
      "path": ",2,64,1072,",
      "level": "3",
      "name": "弗雷瑞斯",
      "name_en": "Frejus",
      "name_pinyin": "fuleiruisi",
      "code": "FRJ",
      "childrens": []
    }, {
      "id": "1073",
      "pid": "64",
      "path": ",2,64,1073,",
      "level": "3",
      "name": "卡昂",
      "name_en": "Caen",
      "name_pinyin": "kaang",
      "code": "CFR",
      "childrens": []
    }, {
      "id": "1074",
      "pid": "64",
      "path": ",2,64,1074,",
      "level": "3",
      "name": "雷恩",
      "name_en": "Rennes",
      "name_pinyin": "leien",
      "code": "RNS",
      "childrens": []
    }, {
      "id": "1075",
      "pid": "64",
      "path": ",2,64,1075,",
      "level": "3",
      "name": "里昂",
      "name_en": "Lyon",
      "name_pinyin": "liang",
      "code": "LIO",
      "childrens": []
    }, {
      "id": "1076",
      "pid": "64",
      "path": ",2,64,1076,",
      "level": "3",
      "name": "里尔",
      "name_en": "Lille",
      "name_pinyin": "lier",
      "code": "LLE",
      "childrens": []
    }, {
      "id": "1077",
      "pid": "64",
      "path": ",2,64,1077,",
      "level": "3",
      "name": "利摩日",
      "name_en": "Limoges",
      "name_pinyin": "limori",
      "code": "LIG",
      "childrens": []
    }, {
      "id": "1078",
      "pid": "64",
      "path": ",2,64,1078,",
      "level": "3",
      "name": "鲁昂",
      "name_en": "Rouen",
      "name_pinyin": "luang",
      "code": "URO",
      "childrens": []
    }, {
      "id": "1079",
      "pid": "64",
      "path": ",2,64,1079,",
      "level": "3",
      "name": "马赛",
      "name_en": "Marseille",
      "name_pinyin": "masai",
      "code": "MRS",
      "childrens": []
    }, {
      "id": "1080",
      "pid": "64",
      "path": ",2,64,1080,",
      "level": "3",
      "name": "梅斯",
      "name_en": "Metz",
      "name_pinyin": "meisi",
      "code": "MZM",
      "childrens": []
    }, {
      "id": "1081",
      "pid": "64",
      "path": ",2,64,1081,",
      "level": "3",
      "name": "蒙彼利埃",
      "name_en": "Montpellier",
      "name_pinyin": "mengbiliai",
      "code": "MPL",
      "childrens": []
    }, {
      "id": "1082",
      "pid": "64",
      "path": ",2,64,1082,",
      "level": "3",
      "name": "南特",
      "name_en": "Nantes",
      "name_pinyin": "nante",
      "code": "NTE",
      "childrens": []
    }, {
      "id": "1083",
      "pid": "64",
      "path": ",2,64,1083,",
      "level": "3",
      "name": "尼斯",
      "name_en": "Nice",
      "name_pinyin": "nisi",
      "code": "NCE",
      "childrens": []
    }, {
      "id": "1084",
      "pid": "64",
      "path": ",2,64,1084,",
      "level": "3",
      "name": "沙隆",
      "name_en": "Chalons-en-Champagne",
      "name_pinyin": "shalong",
      "code": "CSM",
      "childrens": []
    }, {
      "id": "1085",
      "pid": "64",
      "path": ",2,64,1085,",
      "level": "3",
      "name": "图卢兹",
      "name_en": "Toulouse",
      "name_pinyin": "tuluzi",
      "code": "TLS",
      "childrens": []
    }, {
      "id": "1086",
      "pid": "64",
      "path": ",2,64,1086,",
      "level": "3",
      "name": "瓦朗斯",
      "name_en": "Valence",
      "name_pinyin": "walangsi",
      "code": "VAA",
      "childrens": []
    }, {
      "id": "1087",
      "pid": "64",
      "path": ",2,64,1087,",
      "level": "3",
      "name": "亚眠",
      "name_en": "Amiens",
      "name_pinyin": "yamian",
      "code": "AMI",
      "childrens": []
    }]
  }, {
    "id": "65",
    "pid": "2",
    "path": ",2,65,",
    "level": "2",
    "name": "法罗群岛",
    "name_en": "Faroe Islands",
    "name_pinyin": "faluoqundao",
    "code": "FRO",
    "childrens": []
  }, {
    "id": "68",
    "pid": "2",
    "path": ",2,68,",
    "level": "2",
    "name": "法属南部领地",
    "name_en": "French Southern and Antarctic Lands",
    "name_pinyin": "fashunanbulingdi",
    "code": "ATF",
    "childrens": []
  }, {
    "id": "69",
    "pid": "2",
    "path": ",2,69,",
    "level": "2",
    "name": "梵蒂冈",
    "name_en": "Vatican City",
    "name_pinyin": "digang",
    "code": "VAT",
    "childrens": []
  }, {
    "id": "72",
    "pid": "2",
    "path": ",2,72,",
    "level": "2",
    "name": "芬兰",
    "name_en": "Finland",
    "name_pinyin": "fenlan",
    "code": "FIN",
    "childrens": [{
      "id": "1092",
      "pid": "72",
      "path": ",2,72,1092,",
      "level": "3",
      "name": "埃斯波",
      "name_en": "Espoo",
      "name_pinyin": "aisibo",
      "code": "ESP",
      "childrens": []
    }, {
      "id": "1093",
      "pid": "72",
      "path": ",2,72,1093,",
      "level": "3",
      "name": "奥卢",
      "name_en": "Oulu",
      "name_pinyin": "aolu",
      "code": "OLU",
      "childrens": []
    }, {
      "id": "1094",
      "pid": "72",
      "path": ",2,72,1094,",
      "level": "3",
      "name": "波里",
      "name_en": "Pori",
      "name_pinyin": "boli",
      "code": "POR",
      "childrens": []
    }, {
      "id": "1095",
      "pid": "72",
      "path": ",2,72,1095,",
      "level": "3",
      "name": "博尔沃",
      "name_en": "Porvoo",
      "name_pinyin": "boerwo",
      "code": "PRV",
      "childrens": []
    }, {
      "id": "1096",
      "pid": "72",
      "path": ",2,72,1096,",
      "level": "3",
      "name": "海门林纳",
      "name_en": "Hameenlinna",
      "name_pinyin": "haimenlinna",
      "code": "HMY",
      "childrens": []
    }, {
      "id": "1097",
      "pid": "72",
      "path": ",2,72,1097,",
      "level": "3",
      "name": "赫尔辛基",
      "name_en": "Helsinki",
      "name_pinyin": "heerxinji",
      "code": "HEL",
      "childrens": []
    }, {
      "id": "1098",
      "pid": "72",
      "path": ",2,72,1098,",
      "level": "3",
      "name": "卡亚尼",
      "name_en": "Kajaani",
      "name_pinyin": "kayani",
      "code": "KAJ",
      "childrens": []
    }, {
      "id": "1099",
      "pid": "72",
      "path": ",2,72,1099,",
      "level": "3",
      "name": "科科拉",
      "name_en": "Kokkola",
      "name_pinyin": "kekela",
      "code": "KOK",
      "childrens": []
    }, {
      "id": "1100",
      "pid": "72",
      "path": ",2,72,1100,",
      "level": "3",
      "name": "科特卡",
      "name_en": "Kotka",
      "name_pinyin": "keteka",
      "code": "KTK",
      "childrens": []
    }, {
      "id": "1101",
      "pid": "72",
      "path": ",2,72,1101,",
      "level": "3",
      "name": "库奥皮奥",
      "name_en": "Kuopio",
      "name_pinyin": "kuaopiao",
      "code": "KUO",
      "childrens": []
    }, {
      "id": "1102",
      "pid": "72",
      "path": ",2,72,1102,",
      "level": "3",
      "name": "拉赫蒂",
      "name_en": "Lahti",
      "name_pinyin": "lahedi",
      "code": "LHI",
      "childrens": []
    }, {
      "id": "1103",
      "pid": "72",
      "path": ",2,72,1103,",
      "level": "3",
      "name": "拉彭兰塔",
      "name_en": "Lappeenranta",
      "name_pinyin": "lapenglanta",
      "code": "LPP",
      "childrens": []
    }, {
      "id": "1104",
      "pid": "72",
      "path": ",2,72,1104,",
      "level": "3",
      "name": "罗瓦涅米",
      "name_en": "Rovaniemi",
      "name_pinyin": "luowaniemi",
      "code": "RVN",
      "childrens": []
    }, {
      "id": "1105",
      "pid": "72",
      "path": ",2,72,1105,",
      "level": "3",
      "name": "玛丽港",
      "name_en": "Mariehamn",
      "name_pinyin": "maligang",
      "code": "MHQ",
      "childrens": []
    }, {
      "id": "1106",
      "pid": "72",
      "path": ",2,72,1106,",
      "level": "3",
      "name": "米凯利",
      "name_en": "Mikkeli",
      "name_pinyin": "mikaili",
      "code": "MIK",
      "childrens": []
    }, {
      "id": "1107",
      "pid": "72",
      "path": ",2,72,1107,",
      "level": "3",
      "name": "坦佩雷",
      "name_en": "Tampere",
      "name_pinyin": "tanpeilei",
      "code": "TMP",
      "childrens": []
    }, {
      "id": "1108",
      "pid": "72",
      "path": ",2,72,1108,",
      "level": "3",
      "name": "图尔库",
      "name_en": "Turku",
      "name_pinyin": "tuerku",
      "code": "TKU",
      "childrens": []
    }, {
      "id": "1109",
      "pid": "72",
      "path": ",2,72,1109,",
      "level": "3",
      "name": "瓦萨",
      "name_en": "Vaasa",
      "name_pinyin": "wasa",
      "code": "VAA",
      "childrens": []
    }, {
      "id": "1110",
      "pid": "72",
      "path": ",2,72,1110,",
      "level": "3",
      "name": "万塔",
      "name_en": "Vantaa",
      "name_pinyin": "wanta",
      "code": "VAT",
      "childrens": []
    }, {
      "id": "1111",
      "pid": "72",
      "path": ",2,72,1111,",
      "level": "3",
      "name": "约恩苏",
      "name_en": "Joensuu",
      "name_pinyin": "yueensu",
      "code": "JOE",
      "childrens": []
    }]
  }, {
    "id": "74",
    "pid": "2",
    "path": ",2,74,",
    "level": "2",
    "name": "弗兰克群岛",
    "name_en": "Falkland Islands",
    "name_pinyin": "fulankequndao",
    "code": "FLK",
    "childrens": []
  }, {
    "id": "80",
    "pid": "2",
    "path": ",2,80,",
    "level": "2",
    "name": "格恩西岛",
    "name_en": "Guernsey",
    "name_pinyin": "geenxidao",
    "code": "GGY",
    "childrens": []
  }, {
    "id": "90",
    "pid": "2",
    "path": ",2,90,",
    "level": "2",
    "name": "荷兰",
    "name_en": "Netherlands",
    "name_pinyin": "helan",
    "code": "NLD",
    "childrens": [{
      "id": "1236",
      "pid": "90",
      "path": ",2,90,1236,",
      "level": "3",
      "name": "阿尔梅勒",
      "name_en": "Almere",
      "name_pinyin": "aermeile",
      "code": "AER",
      "childrens": []
    }, {
      "id": "1237",
      "pid": "90",
      "path": ",2,90,1237,",
      "level": "3",
      "name": "阿默斯福特",
      "name_en": "Amersfoort",
      "name_pinyin": "amosifute",
      "code": "AME",
      "childrens": []
    }, {
      "id": "1238",
      "pid": "90",
      "path": ",2,90,1238,",
      "level": "3",
      "name": "阿姆斯特丹",
      "name_en": "Amsterdam",
      "name_pinyin": "amusitedan",
      "code": "AMS",
      "childrens": []
    }, {
      "id": "1239",
      "pid": "90",
      "path": ",2,90,1239,",
      "level": "3",
      "name": "阿纳姆",
      "name_en": "Arnhem",
      "name_pinyin": "anamu",
      "code": "ARN",
      "childrens": []
    }, {
      "id": "1240",
      "pid": "90",
      "path": ",2,90,1240,",
      "level": "3",
      "name": "阿珀尔多伦",
      "name_en": "Apeldoorn",
      "name_pinyin": "aerduolun",
      "code": "APE",
      "childrens": []
    }, {
      "id": "1241",
      "pid": "90",
      "path": ",2,90,1241,",
      "level": "3",
      "name": "阿森",
      "name_en": "Assen",
      "name_pinyin": "asen",
      "code": "ASS",
      "childrens": []
    }, {
      "id": "1242",
      "pid": "90",
      "path": ",2,90,1242,",
      "level": "3",
      "name": "埃德",
      "name_en": "Ede",
      "name_pinyin": "aide",
      "code": "EDE",
      "childrens": []
    }, {
      "id": "1243",
      "pid": "90",
      "path": ",2,90,1243,",
      "level": "3",
      "name": "埃门",
      "name_en": "Emmen",
      "name_pinyin": "aimen",
      "code": "EMM",
      "childrens": []
    }, {
      "id": "1244",
      "pid": "90",
      "path": ",2,90,1244,",
      "level": "3",
      "name": "埃因霍芬",
      "name_en": "Eindhoven",
      "name_pinyin": "aiyinhuofen",
      "code": "EIN",
      "childrens": []
    }, {
      "id": "1245",
      "pid": "90",
      "path": ",2,90,1245,",
      "level": "3",
      "name": "布雷达",
      "name_en": "Breda",
      "name_pinyin": "buleida",
      "code": "BRD",
      "childrens": []
    }, {
      "id": "1246",
      "pid": "90",
      "path": ",2,90,1246,",
      "level": "3",
      "name": "蒂尔堡",
      "name_en": "Tilburg",
      "name_pinyin": "dierbao",
      "code": "TLB",
      "childrens": []
    }, {
      "id": "1247",
      "pid": "90",
      "path": ",2,90,1247,",
      "level": "3",
      "name": "多德雷赫特",
      "name_en": "Dordrecht",
      "name_pinyin": "duodeleihete",
      "code": "DOR",
      "childrens": []
    }, {
      "id": "1248",
      "pid": "90",
      "path": ",2,90,1248,",
      "level": "3",
      "name": "恩斯赫德",
      "name_en": "Enschede",
      "name_pinyin": "ensihede",
      "code": "ENS",
      "childrens": []
    }, {
      "id": "1249",
      "pid": "90",
      "path": ",2,90,1249,",
      "level": "3",
      "name": "格罗宁根",
      "name_en": "Groningen",
      "name_pinyin": "geluoninggen",
      "code": "GRQ",
      "childrens": []
    }, {
      "id": "1250",
      "pid": "90",
      "path": ",2,90,1250,",
      "level": "3",
      "name": "哈勒姆",
      "name_en": "Haarlem",
      "name_pinyin": "halemu",
      "code": "HRA",
      "childrens": []
    }, {
      "id": "1251",
      "pid": "90",
      "path": ",2,90,1251,",
      "level": "3",
      "name": "海牙",
      "name_en": "Hague",
      "name_pinyin": "haiya",
      "code": "HAG",
      "childrens": []
    }, {
      "id": "1252",
      "pid": "90",
      "path": ",2,90,1252,",
      "level": "3",
      "name": "霍夫多尔普",
      "name_en": "Hoofddorp",
      "name_pinyin": "huofuduoerpu",
      "code": "HFD",
      "childrens": []
    }, {
      "id": "1253",
      "pid": "90",
      "path": ",2,90,1253,",
      "level": "3",
      "name": "莱顿",
      "name_en": "Leiden",
      "name_pinyin": "laidun",
      "code": "LID",
      "childrens": []
    }, {
      "id": "1254",
      "pid": "90",
      "path": ",2,90,1254,",
      "level": "3",
      "name": "莱利斯塔德",
      "name_en": "Lelystad",
      "name_pinyin": "lailisitade",
      "code": "LEY",
      "childrens": []
    }, {
      "id": "1255",
      "pid": "90",
      "path": ",2,90,1255,",
      "level": "3",
      "name": "鹿特丹",
      "name_en": "Rotterdam",
      "name_pinyin": "lutedan",
      "code": "RTM",
      "childrens": []
    }, {
      "id": "1256",
      "pid": "90",
      "path": ",2,90,1256,",
      "level": "3",
      "name": "吕伐登",
      "name_en": "Leeuwarden",
      "name_pinyin": "lvfadeng",
      "code": "LWR",
      "childrens": []
    }, {
      "id": "1257",
      "pid": "90",
      "path": ",2,90,1257,",
      "level": "3",
      "name": "马斯特里赫特",
      "name_en": "Maastricht",
      "name_pinyin": "masitelihete",
      "code": "MST",
      "childrens": []
    }, {
      "id": "1258",
      "pid": "90",
      "path": ",2,90,1258,",
      "level": "3",
      "name": "米德尔堡",
      "name_en": "Middelburg",
      "name_pinyin": "mideerbao",
      "code": "MDL",
      "childrens": []
    }, {
      "id": "1259",
      "pid": "90",
      "path": ",2,90,1259,",
      "level": "3",
      "name": "奈梅亨",
      "name_en": "Nijmegen",
      "name_pinyin": "naimeiheng",
      "code": "NIJ",
      "childrens": []
    }, {
      "id": "1260",
      "pid": "90",
      "path": ",2,90,1260,",
      "level": "3",
      "name": "斯海尔托亨博思",
      "name_en": "'s-Hertogenbosch",
      "name_pinyin": "sihaiertuohengbosi",
      "code": "HTB",
      "childrens": []
    }, {
      "id": "1261",
      "pid": "90",
      "path": ",2,90,1261,",
      "level": "3",
      "name": "乌得勒支",
      "name_en": "Utrecht",
      "name_pinyin": "wudelezhi",
      "code": "UTC",
      "childrens": []
    }, {
      "id": "1262",
      "pid": "90",
      "path": ",2,90,1262,",
      "level": "3",
      "name": "兹沃勒",
      "name_en": "Zwolle",
      "name_pinyin": "ziwole",
      "code": "ZWO",
      "childrens": []
    }, {
      "id": "1263",
      "pid": "90",
      "path": ",2,90,1263,",
      "level": "3",
      "name": "佐特尔梅",
      "name_en": "Zoetermeer",
      "name_pinyin": "zuoteermei",
      "code": "ZTM",
      "childrens": []
    }]
  }, {
    "id": "103",
    "pid": "2",
    "path": ",2,103,",
    "level": "2",
    "name": "捷克共和国",
    "name_en": "Czech Republic",
    "name_pinyin": "jiekegongheguo",
    "code": "CZE",
    "childrens": [{
      "id": "1392",
      "pid": "103",
      "path": ",2,103,1392,",
      "level": "3",
      "name": "奥洛穆茨",
      "name_en": "Olomoucky",
      "name_pinyin": "aoluomuci",
      "code": "OL",
      "childrens": []
    }, {
      "id": "1393",
      "pid": "103",
      "path": ",2,103,1393,",
      "level": "3",
      "name": "比尔森",
      "name_en": "Plzensky",
      "name_pinyin": "biersen",
      "code": "PL",
      "childrens": []
    }, {
      "id": "1394",
      "pid": "103",
      "path": ",2,103,1394,",
      "level": "3",
      "name": "布拉格直辖市",
      "name_en": "Prague",
      "name_pinyin": "bulagezhixiashi",
      "code": "PR",
      "childrens": []
    }, {
      "id": "1395",
      "pid": "103",
      "path": ",2,103,1395,",
      "level": "3",
      "name": "赫拉德茨-克拉洛韦",
      "name_en": "Kralovehradecky",
      "name_pinyin": "heladecikelaluowei",
      "code": "KR",
      "childrens": []
    }, {
      "id": "1396",
      "pid": "103",
      "path": ",2,103,1396,",
      "level": "3",
      "name": "卡罗维发利",
      "name_en": "Karlovarsky",
      "name_pinyin": "kaluoweifali",
      "code": "KA",
      "childrens": []
    }, {
      "id": "1397",
      "pid": "103",
      "path": ",2,103,1397,",
      "level": "3",
      "name": "利贝雷克",
      "name_en": "Liberecky",
      "name_pinyin": "libeileike",
      "code": "LI",
      "childrens": []
    }, {
      "id": "1398",
      "pid": "103",
      "path": ",2,103,1398,",
      "level": "3",
      "name": "摩拉维亚-西里西亚",
      "name_en": "Moravskoslezsky",
      "name_pinyin": "molaweiyaxilixiya",
      "code": "MO",
      "childrens": []
    }, {
      "id": "1399",
      "pid": "103",
      "path": ",2,103,1399,",
      "level": "3",
      "name": "南摩拉维亚",
      "name_en": "Jihomoravsky",
      "name_pinyin": "nanmolaweiya",
      "code": "JC",
      "childrens": []
    }, {
      "id": "1400",
      "pid": "103",
      "path": ",2,103,1400,",
      "level": "3",
      "name": "帕尔杜比采",
      "name_en": "Pardubicky",
      "name_pinyin": "paerdubicai",
      "code": "PA",
      "childrens": []
    }, {
      "id": "1401",
      "pid": "103",
      "path": ",2,103,1401,",
      "level": "3",
      "name": "维索基纳",
      "name_en": "Vysocina",
      "name_pinyin": "weisuojina",
      "code": "VY",
      "childrens": []
    }, {
      "id": "1402",
      "pid": "103",
      "path": ",2,103,1402,",
      "level": "3",
      "name": "乌斯季",
      "name_en": "Ustecky",
      "name_pinyin": "wusiji",
      "code": "US",
      "childrens": []
    }, {
      "id": "1403",
      "pid": "103",
      "path": ",2,103,1403,",
      "level": "3",
      "name": "中捷克",
      "name_en": "Stredocesky",
      "name_pinyin": "zhongjieke",
      "code": "ST",
      "childrens": []
    }, {
      "id": "1404",
      "pid": "103",
      "path": ",2,103,1404,",
      "level": "3",
      "name": "兹林",
      "name_en": "Zlinsky",
      "name_pinyin": "zilin",
      "code": "ZL",
      "childrens": []
    }]
  }, {
    "id": "112",
    "pid": "2",
    "path": ",2,112,",
    "level": "2",
    "name": "克罗地亚",
    "name_en": "Croatia",
    "name_pinyin": "keluodiya",
    "code": "HRV",
    "childrens": [{
      "id": "1453",
      "pid": "112",
      "path": ",2,112,1453,",
      "level": "3",
      "name": "奥西耶克-巴拉尼亚",
      "name_en": "Osjecko-Baranjska",
      "name_pinyin": "aoxiyekebalaniya",
      "code": "14",
      "childrens": []
    }, {
      "id": "1454",
      "pid": "112",
      "path": ",2,112,1454,",
      "level": "3",
      "name": "别洛瓦尔-比洛戈拉",
      "name_en": "Bjelovarsko-Bilogorska",
      "name_pinyin": "bieluowaerbiluogela",
      "code": "7",
      "childrens": []
    }, {
      "id": "1455",
      "pid": "112",
      "path": ",2,112,1455,",
      "level": "3",
      "name": "滨海和山区",
      "name_en": "Primorsko-Goranska",
      "name_pinyin": "binhaiheshanqu",
      "code": "8",
      "childrens": []
    }, {
      "id": "1456",
      "pid": "112",
      "path": ",2,112,1456,",
      "level": "3",
      "name": "波热加-斯拉沃尼亚",
      "name_en": "Pozega-Slavonia",
      "name_pinyin": "borejiasilawoniya",
      "code": "11",
      "childrens": []
    }, {
      "id": "1457",
      "pid": "112",
      "path": ",2,112,1457,",
      "level": "3",
      "name": "布罗德-波萨维纳",
      "name_en": "Brodsko-Posavska",
      "name_pinyin": "buluodebosaweina",
      "code": "12",
      "childrens": []
    }, {
      "id": "1458",
      "pid": "112",
      "path": ",2,112,1458,",
      "level": "3",
      "name": "杜布罗夫斯克-内雷特瓦",
      "name_en": "Dubrovacko-Neretvanska",
      "name_pinyin": "dubuluofusikeneileitewa",
      "code": "19",
      "childrens": []
    }, {
      "id": "1459",
      "pid": "112",
      "path": ",2,112,1459,",
      "level": "3",
      "name": "卡尔洛瓦茨",
      "name_en": "Karlovacka",
      "name_pinyin": "kaerluowaci",
      "code": "4",
      "childrens": []
    }, {
      "id": "1460",
      "pid": "112",
      "path": ",2,112,1460,",
      "level": "3",
      "name": "科普里夫尼察-克里热夫齐",
      "name_en": "Koprivnicko-Krizevacka",
      "name_pinyin": "kepulifunichakelirefuqi",
      "code": "6",
      "childrens": []
    }, {
      "id": "1461",
      "pid": "112",
      "path": ",2,112,1461,",
      "level": "3",
      "name": "克拉皮纳-扎戈列",
      "name_en": "Krapinsko-Zagorska",
      "name_pinyin": "kelapinazhagelie",
      "code": "2",
      "childrens": []
    }, {
      "id": "1462",
      "pid": "112",
      "path": ",2,112,1462,",
      "level": "3",
      "name": "利卡-塞尼",
      "name_en": "Licko-Senjska",
      "name_pinyin": "likasaini",
      "code": "9",
      "childrens": []
    }, {
      "id": "1463",
      "pid": "112",
      "path": ",2,112,1463,",
      "level": "3",
      "name": "梅吉穆列",
      "name_en": "Medimurska",
      "name_pinyin": "meijimulie",
      "code": "20",
      "childrens": []
    }, {
      "id": "1464",
      "pid": "112",
      "path": ",2,112,1464,",
      "level": "3",
      "name": "萨格勒布",
      "name_en": "Zagrebacka",
      "name_pinyin": "sagelebu",
      "code": "1",
      "childrens": []
    }, {
      "id": "1465",
      "pid": "112",
      "path": ",2,112,1465,",
      "level": "3",
      "name": "萨格勒布市",
      "name_en": "Grad Zagreb",
      "name_pinyin": "sagelebushi",
      "code": "21",
      "childrens": []
    }, {
      "id": "1466",
      "pid": "112",
      "path": ",2,112,1466,",
      "level": "3",
      "name": "斯普利特-达尔马提亚",
      "name_en": "Splitsko-Dalmatinska",
      "name_pinyin": "sipulitedaermatiya",
      "code": "17",
      "childrens": []
    }, {
      "id": "1467",
      "pid": "112",
      "path": ",2,112,1467,",
      "level": "3",
      "name": "瓦拉日丁",
      "name_en": "Varazdinska",
      "name_pinyin": "walariding",
      "code": "5",
      "childrens": []
    }, {
      "id": "1468",
      "pid": "112",
      "path": ",2,112,1468,",
      "level": "3",
      "name": "维罗维蒂察-波德拉维纳",
      "name_en": "Viroviticko-Podravska",
      "name_pinyin": "weiluoweidichabodelaweina",
      "code": "10",
      "childrens": []
    }, {
      "id": "1469",
      "pid": "112",
      "path": ",2,112,1469,",
      "level": "3",
      "name": "武科瓦尔-斯里耶姆",
      "name_en": "Vukovarsko-Srijemska",
      "name_pinyin": "wukewaersiliyemu",
      "code": "16",
      "childrens": []
    }, {
      "id": "1470",
      "pid": "112",
      "path": ",2,112,1470,",
      "level": "3",
      "name": "希贝尼克-克宁",
      "name_en": "Sibensko-Kninska",
      "name_pinyin": "xibeinikekening",
      "code": "15",
      "childrens": []
    }, {
      "id": "1471",
      "pid": "112",
      "path": ",2,112,1471,",
      "level": "3",
      "name": "锡萨克-莫斯拉维纳",
      "name_en": "Sisacko-Moslavacka",
      "name_pinyin": "xisakemosilaweina",
      "code": "3",
      "childrens": []
    }, {
      "id": "1472",
      "pid": "112",
      "path": ",2,112,1472,",
      "level": "3",
      "name": "伊斯特拉",
      "name_en": "Istarska",
      "name_pinyin": "yisitela",
      "code": "18",
      "childrens": []
    }, {
      "id": "1473",
      "pid": "112",
      "path": ",2,112,1473,",
      "level": "3",
      "name": "扎达尔",
      "name_en": "Zadarska",
      "name_pinyin": "zhadaer",
      "code": "13",
      "childrens": []
    }]
  }, {
    "id": "115",
    "pid": "2",
    "path": ",2,115,",
    "level": "2",
    "name": "拉脱维亚",
    "name_en": "Latvia",
    "name_pinyin": "latuoweiya",
    "code": "LVA",
    "childrens": [{
      "id": "1522",
      "pid": "115",
      "path": ",2,115,1522,",
      "level": "3",
      "name": "阿卢克斯内",
      "name_en": "Aluksnes",
      "name_pinyin": "alukesinei",
      "code": "ALU",
      "childrens": []
    }, {
      "id": "1523",
      "pid": "115",
      "path": ",2,115,1523,",
      "level": "3",
      "name": "爱兹克劳克雷",
      "name_en": "Aizkraukles",
      "name_pinyin": "aizikelaokelei",
      "code": "AIZ",
      "childrens": []
    }, {
      "id": "1524",
      "pid": "115",
      "path": ",2,115,1524,",
      "level": "3",
      "name": "奥格雷",
      "name_en": "Ogres",
      "name_pinyin": "aogelei",
      "code": "OGR",
      "childrens": []
    }, {
      "id": "1525",
      "pid": "115",
      "path": ",2,115,1525,",
      "level": "3",
      "name": "巴尔维",
      "name_en": "Balvu",
      "name_pinyin": "baerwei",
      "code": "BAL",
      "childrens": []
    }, {
      "id": "1526",
      "pid": "115",
      "path": ",2,115,1526,",
      "level": "3",
      "name": "包斯卡",
      "name_en": "Bauskas",
      "name_pinyin": "baosika",
      "code": "BAU",
      "childrens": []
    }, {
      "id": "1527",
      "pid": "115",
      "path": ",2,115,1527,",
      "level": "3",
      "name": "采西斯",
      "name_en": "Cesu",
      "name_pinyin": "caixisi",
      "code": "CES",
      "childrens": []
    }, {
      "id": "1528",
      "pid": "115",
      "path": ",2,115,1528,",
      "level": "3",
      "name": "多贝莱",
      "name_en": "Dobeles",
      "name_pinyin": "duobeilai",
      "code": "DOB",
      "childrens": []
    }, {
      "id": "1529",
      "pid": "115",
      "path": ",2,115,1529,",
      "level": "3",
      "name": "古尔贝内",
      "name_en": "Gulbenes",
      "name_pinyin": "guerbeinei",
      "code": "GUL",
      "childrens": []
    }, {
      "id": "1530",
      "pid": "115",
      "path": ",2,115,1530,",
      "level": "3",
      "name": "杰卡布皮尔斯",
      "name_en": "Jekabpils",
      "name_pinyin": "jiekabupiersi",
      "code": "JEK",
      "childrens": []
    }, {
      "id": "1531",
      "pid": "115",
      "path": ",2,115,1531,",
      "level": "3",
      "name": "克拉斯拉瓦",
      "name_en": "Kraslavas",
      "name_pinyin": "kelasilawa",
      "code": "KRA",
      "childrens": []
    }, {
      "id": "1532",
      "pid": "115",
      "path": ",2,115,1532,",
      "level": "3",
      "name": "库尔迪加",
      "name_en": "Kuldigas",
      "name_pinyin": "kuerdijia",
      "code": "KUL",
      "childrens": []
    }, {
      "id": "1533",
      "pid": "115",
      "path": ",2,115,1533,",
      "level": "3",
      "name": "雷泽克内",
      "name_en": "Rezeknes",
      "name_pinyin": "leizekenei",
      "code": "RZR",
      "childrens": []
    }, {
      "id": "1534",
      "pid": "115",
      "path": ",2,115,1534,",
      "level": "3",
      "name": "里加",
      "name_en": "Rigas",
      "name_pinyin": "lijia",
      "code": "RGA",
      "childrens": []
    }, {
      "id": "1535",
      "pid": "115",
      "path": ",2,115,1535,",
      "level": "3",
      "name": "利耶帕亚",
      "name_en": "Liepajas",
      "name_pinyin": "liyepaya",
      "code": "LPK",
      "childrens": []
    }, {
      "id": "1536",
      "pid": "115",
      "path": ",2,115,1536,",
      "level": "3",
      "name": "林巴济",
      "name_en": "Limbazu",
      "name_pinyin": "linbaji",
      "code": "LIM",
      "childrens": []
    }, {
      "id": "1537",
      "pid": "115",
      "path": ",2,115,1537,",
      "level": "3",
      "name": "卢扎",
      "name_en": "Ludzas",
      "name_pinyin": "luzha",
      "code": "LUD",
      "childrens": []
    }, {
      "id": "1538",
      "pid": "115",
      "path": ",2,115,1538,",
      "level": "3",
      "name": "马多纳",
      "name_en": "Madonas",
      "name_pinyin": "maduona",
      "code": "MAD",
      "childrens": []
    }, {
      "id": "1539",
      "pid": "115",
      "path": ",2,115,1539,",
      "level": "3",
      "name": "普雷利",
      "name_en": "Preilu",
      "name_pinyin": "puleili",
      "code": "PRE",
      "childrens": []
    }, {
      "id": "1540",
      "pid": "115",
      "path": ",2,115,1540,",
      "level": "3",
      "name": "萨尔杜斯",
      "name_en": "Saldus",
      "name_pinyin": "saerdusi",
      "code": "SAL",
      "childrens": []
    }, {
      "id": "1541",
      "pid": "115",
      "path": ",2,115,1541,",
      "level": "3",
      "name": "塔尔西",
      "name_en": "Talsu",
      "name_pinyin": "taerxi",
      "code": "TAL",
      "childrens": []
    }, {
      "id": "1542",
      "pid": "115",
      "path": ",2,115,1542,",
      "level": "3",
      "name": "陶格夫皮尔斯",
      "name_en": "Daugavpils",
      "name_pinyin": "taogefupiersi",
      "code": "DGR",
      "childrens": []
    }, {
      "id": "1543",
      "pid": "115",
      "path": ",2,115,1543,",
      "level": "3",
      "name": "图库马",
      "name_en": "Tukuma",
      "name_pinyin": "tukuma",
      "code": "TUK",
      "childrens": []
    }, {
      "id": "1544",
      "pid": "115",
      "path": ",2,115,1544,",
      "level": "3",
      "name": "瓦尔加",
      "name_en": "Valkas",
      "name_pinyin": "waerjia",
      "code": "VLK",
      "childrens": []
    }, {
      "id": "1545",
      "pid": "115",
      "path": ",2,115,1545,",
      "level": "3",
      "name": "瓦尔米耶拉",
      "name_en": "Valmieras",
      "name_pinyin": "waermiyela",
      "code": "VLM",
      "childrens": []
    }, {
      "id": "1546",
      "pid": "115",
      "path": ",2,115,1546,",
      "level": "3",
      "name": "文茨皮尔斯",
      "name_en": "Ventspils",
      "name_pinyin": "wencipiersi",
      "code": "VSL",
      "childrens": []
    }, {
      "id": "1547",
      "pid": "115",
      "path": ",2,115,1547,",
      "level": "3",
      "name": "叶尔加瓦",
      "name_en": "Jelgavas",
      "name_pinyin": "yeerjiawa",
      "code": "JGR",
      "childrens": []
    }]
  }, {
    "id": "121",
    "pid": "2",
    "path": ",2,121,",
    "level": "2",
    "name": "立陶宛",
    "name_en": "Lithuania",
    "name_pinyin": "litaowan",
    "code": "LTU",
    "childrens": [{
      "id": "1598",
      "pid": "121",
      "path": ",2,121,1598,",
      "level": "3",
      "name": "阿利图斯",
      "name_en": "Alytus",
      "name_pinyin": "alitusi",
      "code": "AL",
      "childrens": []
    }, {
      "id": "1599",
      "pid": "121",
      "path": ",2,121,1599,",
      "level": "3",
      "name": "考纳斯",
      "name_en": "Kaunas",
      "name_pinyin": "kaonasi",
      "code": "KA",
      "childrens": []
    }, {
      "id": "1600",
      "pid": "121",
      "path": ",2,121,1600,",
      "level": "3",
      "name": "克莱佩达",
      "name_en": "Klaipeda",
      "name_pinyin": "kelaipeida",
      "code": "KL",
      "childrens": []
    }, {
      "id": "1601",
      "pid": "121",
      "path": ",2,121,1601,",
      "level": "3",
      "name": "马里扬泊列",
      "name_en": "Marijampole",
      "name_pinyin": "maliyangbolie",
      "code": "MA",
      "childrens": []
    }, {
      "id": "1602",
      "pid": "121",
      "path": ",2,121,1602,",
      "level": "3",
      "name": "帕涅韦日斯",
      "name_en": "Panevezys",
      "name_pinyin": "panieweirisi",
      "code": "PA",
      "childrens": []
    }, {
      "id": "1603",
      "pid": "121",
      "path": ",2,121,1603,",
      "level": "3",
      "name": "陶拉格",
      "name_en": "Taurages",
      "name_pinyin": "taolage",
      "code": "TA",
      "childrens": []
    }, {
      "id": "1604",
      "pid": "121",
      "path": ",2,121,1604,",
      "level": "3",
      "name": "特尔希艾",
      "name_en": "Telsiu",
      "name_pinyin": "teerxiai",
      "code": "TE",
      "childrens": []
    }, {
      "id": "1605",
      "pid": "121",
      "path": ",2,121,1605,",
      "level": "3",
      "name": "维尔纽斯",
      "name_en": "Vilnius",
      "name_pinyin": "weierniusi",
      "code": "VI",
      "childrens": []
    }, {
      "id": "1606",
      "pid": "121",
      "path": ",2,121,1606,",
      "level": "3",
      "name": "乌田纳",
      "name_en": "Utenos",
      "name_pinyin": "wutianna",
      "code": "UT",
      "childrens": []
    }, {
      "id": "1607",
      "pid": "121",
      "path": ",2,121,1607,",
      "level": "3",
      "name": "希奥利艾",
      "name_en": "Siauliai",
      "name_pinyin": "xiaoliai",
      "code": "SI",
      "childrens": []
    }, {
      "id": "1608",
      "pid": "121",
      "path": ",2,121,1608,",
      "level": "3",
      "name": "亚克曼",
      "name_en": "Akmenes",
      "name_pinyin": "yakeman",
      "code": "AKM",
      "childrens": []
    }]
  }, {
    "id": "122",
    "pid": "2",
    "path": ",2,122,",
    "level": "2",
    "name": "列支敦士登",
    "name_en": "Liechtenstein",
    "name_pinyin": "liezhidunshideng",
    "code": "LIE",
    "childrens": []
  }, {
    "id": "123",
    "pid": "2",
    "path": ",2,123,",
    "level": "2",
    "name": "留尼旺岛",
    "name_en": "Reunion",
    "name_pinyin": "liuniwangdao",
    "code": "REU",
    "childrens": []
  }, {
    "id": "124",
    "pid": "2",
    "path": ",2,124,",
    "level": "2",
    "name": "卢森堡",
    "name_en": "Luxembourg",
    "name_pinyin": "lusenbao",
    "code": "LUX",
    "childrens": [{
      "id": "1609",
      "pid": "124",
      "path": ",2,124,1609,",
      "level": "3",
      "name": "迪基希",
      "name_en": "Diekirch",
      "name_pinyin": "dijixi",
      "code": "DD",
      "childrens": []
    }, {
      "id": "1610",
      "pid": "124",
      "path": ",2,124,1610,",
      "level": "3",
      "name": "格雷文马赫",
      "name_en": "Grevenmacher",
      "name_pinyin": "geleiwenmahe",
      "code": "GG",
      "childrens": []
    }, {
      "id": "1611",
      "pid": "124",
      "path": ",2,124,1611,",
      "level": "3",
      "name": "卢森堡",
      "name_en": "Luxembourg",
      "name_pinyin": "lusenbao",
      "code": "LL",
      "childrens": []
    }]
  }, {
    "id": "126",
    "pid": "2",
    "path": ",2,126,",
    "level": "2",
    "name": "罗马尼亚",
    "name_en": "Romania",
    "name_pinyin": "luomaniya",
    "code": "ROU",
    "childrens": [{
      "id": "1628",
      "pid": "126",
      "path": ",2,126,1628,",
      "level": "3",
      "name": "阿尔巴尤利亚",
      "name_en": "Alba Iulia",
      "name_pinyin": "aerbayouliya",
      "code": "AL",
      "childrens": []
    }, {
      "id": "1629",
      "pid": "126",
      "path": ",2,126,1629,",
      "level": "3",
      "name": "阿拉德",
      "name_en": "Arad",
      "name_pinyin": "alade",
      "code": "AR",
      "childrens": []
    }, {
      "id": "1630",
      "pid": "126",
      "path": ",2,126,1630,",
      "level": "3",
      "name": "奥拉迪亚",
      "name_en": "Oradea",
      "name_pinyin": "aoladiya",
      "code": "OR",
      "childrens": []
    }, {
      "id": "1631",
      "pid": "126",
      "path": ",2,126,1631,",
      "level": "3",
      "name": "巴克乌",
      "name_en": "Bacau",
      "name_pinyin": "bakewu",
      "code": "BA",
      "childrens": []
    }, {
      "id": "1632",
      "pid": "126",
      "path": ",2,126,1632,",
      "level": "3",
      "name": "巴亚马雷",
      "name_en": "Baia Mare",
      "name_pinyin": "bayamalei",
      "code": "BM",
      "childrens": []
    }, {
      "id": "1633",
      "pid": "126",
      "path": ",2,126,1633,",
      "level": "3",
      "name": "比斯特里察",
      "name_en": "Bistrita",
      "name_pinyin": "bisitelicha",
      "code": "BN",
      "childrens": []
    }, {
      "id": "1634",
      "pid": "126",
      "path": ",2,126,1634,",
      "level": "3",
      "name": "博托沙尼",
      "name_en": "Botosani",
      "name_pinyin": "botuoshani",
      "code": "BO",
      "childrens": []
    }, {
      "id": "1635",
      "pid": "126",
      "path": ",2,126,1635,",
      "level": "3",
      "name": "布加勒斯特",
      "name_en": "Bucuresti",
      "name_pinyin": "bujialesite",
      "code": "BC",
      "childrens": []
    }, {
      "id": "1636",
      "pid": "126",
      "path": ",2,126,1636,",
      "level": "3",
      "name": "布拉索夫",
      "name_en": "Brasov",
      "name_pinyin": "bulasuofu",
      "code": "BS",
      "childrens": []
    }, {
      "id": "1637",
      "pid": "126",
      "path": ",2,126,1637,",
      "level": "3",
      "name": "布勒伊拉",
      "name_en": "Braila",
      "name_pinyin": "buleyila",
      "code": "BL",
      "childrens": []
    }, {
      "id": "1638",
      "pid": "126",
      "path": ",2,126,1638,",
      "level": "3",
      "name": "布泽乌",
      "name_en": "Buzau",
      "name_pinyin": "buzewu",
      "code": "BZ",
      "childrens": []
    }, {
      "id": "1639",
      "pid": "126",
      "path": ",2,126,1639,",
      "level": "3",
      "name": "德罗贝塔-塞维林堡",
      "name_en": "Drobeta-Turnu Severin",
      "name_pinyin": "deluobeitasaiweilinbao",
      "code": "DT",
      "childrens": []
    }, {
      "id": "1640",
      "pid": "126",
      "path": ",2,126,1640,",
      "level": "3",
      "name": "德瓦",
      "name_en": "Deva",
      "name_pinyin": "dewa",
      "code": "DE",
      "childrens": []
    }, {
      "id": "1641",
      "pid": "126",
      "path": ",2,126,1641,",
      "level": "3",
      "name": "蒂米什瓦拉",
      "name_en": "Timisoara",
      "name_pinyin": "dimishiwala",
      "code": "TI",
      "childrens": []
    }, {
      "id": "1642",
      "pid": "126",
      "path": ",2,126,1642,",
      "level": "3",
      "name": "福克沙尼",
      "name_en": "Focsani",
      "name_pinyin": "fukeshani",
      "code": "FO",
      "childrens": []
    }, {
      "id": "1643",
      "pid": "126",
      "path": ",2,126,1643,",
      "level": "3",
      "name": "加拉茨",
      "name_en": "Galati",
      "name_pinyin": "jialaci",
      "code": "GL",
      "childrens": []
    }, {
      "id": "1644",
      "pid": "126",
      "path": ",2,126,1644,",
      "level": "3",
      "name": "久尔久",
      "name_en": "Giurgiu",
      "name_pinyin": "jiuerjiu",
      "code": "GG",
      "childrens": []
    }, {
      "id": "1645",
      "pid": "126",
      "path": ",2,126,1645,",
      "level": "3",
      "name": "康斯坦察",
      "name_en": "Constanta",
      "name_pinyin": "kangsitancha",
      "code": "CT",
      "childrens": []
    }, {
      "id": "1646",
      "pid": "126",
      "path": ",2,126,1646,",
      "level": "3",
      "name": "克拉约瓦",
      "name_en": "Craiova",
      "name_pinyin": "kelayuewa",
      "code": "DO",
      "childrens": []
    }, {
      "id": "1647",
      "pid": "126",
      "path": ",2,126,1647,",
      "level": "3",
      "name": "克勒拉希",
      "name_en": "Calarasi",
      "name_pinyin": "kelelaxi",
      "code": "CR",
      "childrens": []
    }, {
      "id": "1648",
      "pid": "126",
      "path": ",2,126,1648,",
      "level": "3",
      "name": "克卢日纳波卡",
      "name_en": "Cluj-Napoca",
      "name_pinyin": "kelurinaboka",
      "code": "CN",
      "childrens": []
    }, {
      "id": "1649",
      "pid": "126",
      "path": ",2,126,1649,",
      "level": "3",
      "name": "勒姆尼库沃尔恰",
      "name_en": "XRimnicu Vilcea",
      "name_pinyin": "lemunikuwoerqia",
      "code": "VI",
      "childrens": []
    }, {
      "id": "1650",
      "pid": "126",
      "path": ",2,126,1650,",
      "level": "3",
      "name": "雷希察",
      "name_en": "Resita",
      "name_pinyin": "leixicha",
      "code": "RE",
      "childrens": []
    }, {
      "id": "1651",
      "pid": "126",
      "path": ",2,126,1651,",
      "level": "3",
      "name": "梅尔库里亚丘克",
      "name_en": "Miercurea-Ciuc",
      "name_pinyin": "meierkuliyaqiuke",
      "code": "MC",
      "childrens": []
    }, {
      "id": "1652",
      "pid": "126",
      "path": ",2,126,1652,",
      "level": "3",
      "name": "皮特什蒂",
      "name_en": "Pitesti",
      "name_pinyin": "piteshidi",
      "code": "PI",
      "childrens": []
    }, {
      "id": "1653",
      "pid": "126",
      "path": ",2,126,1653,",
      "level": "3",
      "name": "皮亚特拉尼亚姆茨",
      "name_en": "Piatra Neamt",
      "name_pinyin": "piyatelaniyamuci",
      "code": "PN",
      "childrens": []
    }, {
      "id": "1654",
      "pid": "126",
      "path": ",2,126,1654,",
      "level": "3",
      "name": "普洛耶什蒂",
      "name_en": "Ploiesti",
      "name_pinyin": "puluoyeshidi",
      "code": "PL",
      "childrens": []
    }, {
      "id": "1655",
      "pid": "126",
      "path": ",2,126,1655,",
      "level": "3",
      "name": "萨图·马雷",
      "name_en": "Satu Mare",
      "name_pinyin": "satu",
      "code": "SM",
      "childrens": []
    }, {
      "id": "1656",
      "pid": "126",
      "path": ",2,126,1656,",
      "level": "3",
      "name": "圣格奥尔基",
      "name_en": "Sfantu-Gheorghe",
      "name_pinyin": "shenggeaoerji",
      "code": "SG",
      "childrens": []
    }, {
      "id": "1657",
      "pid": "126",
      "path": ",2,126,1657,",
      "level": "3",
      "name": "斯拉蒂纳",
      "name_en": "Slatina",
      "name_pinyin": "siladina",
      "code": "ST",
      "childrens": []
    }, {
      "id": "1658",
      "pid": "126",
      "path": ",2,126,1658,",
      "level": "3",
      "name": "斯洛博齐亚",
      "name_en": "Slobozia",
      "name_pinyin": "siluoboqiya",
      "code": "SB",
      "childrens": []
    }, {
      "id": "1659",
      "pid": "126",
      "path": ",2,126,1659,",
      "level": "3",
      "name": "苏恰瓦",
      "name_en": "Suceava",
      "name_pinyin": "suqiawa",
      "code": "SU",
      "childrens": []
    }, {
      "id": "1660",
      "pid": "126",
      "path": ",2,126,1660,",
      "level": "3",
      "name": "特尔戈维什泰",
      "name_en": "Targovişte",
      "name_pinyin": "teergeweishitai",
      "code": "TA",
      "childrens": []
    }, {
      "id": "1661",
      "pid": "126",
      "path": ",2,126,1661,",
      "level": "3",
      "name": "特尔古穆列什",
      "name_en": "Tirgu Mures",
      "name_pinyin": "teergumulieshi",
      "code": "TM",
      "childrens": []
    }, {
      "id": "1662",
      "pid": "126",
      "path": ",2,126,1662,",
      "level": "3",
      "name": "特尔古日乌",
      "name_en": "Tirgu-Jiu",
      "name_pinyin": "teerguriwu",
      "code": "TJ",
      "childrens": []
    }, {
      "id": "1663",
      "pid": "126",
      "path": ",2,126,1663,",
      "level": "3",
      "name": "图尔恰",
      "name_en": "Tulcea",
      "name_pinyin": "tuerqia",
      "code": "TU",
      "childrens": []
    }, {
      "id": "1664",
      "pid": "126",
      "path": ",2,126,1664,",
      "level": "3",
      "name": "瓦斯卢伊",
      "name_en": "Vaslui",
      "name_pinyin": "wasiluyi",
      "code": "VA",
      "childrens": []
    }, {
      "id": "1665",
      "pid": "126",
      "path": ",2,126,1665,",
      "level": "3",
      "name": "锡比乌",
      "name_en": "Sibiu",
      "name_pinyin": "xibiwu",
      "code": "SO",
      "childrens": []
    }, {
      "id": "1666",
      "pid": "126",
      "path": ",2,126,1666,",
      "level": "3",
      "name": "雅西",
      "name_en": "Iasi",
      "name_pinyin": "yaxi",
      "code": "IS",
      "childrens": []
    }, {
      "id": "1667",
      "pid": "126",
      "path": ",2,126,1667,",
      "level": "3",
      "name": "亚厉山德里亚",
      "name_en": "Alexandria",
      "name_pinyin": "yalishandeliya",
      "code": "AD",
      "childrens": []
    }, {
      "id": "1668",
      "pid": "126",
      "path": ",2,126,1668,",
      "level": "3",
      "name": "扎勒乌",
      "name_en": "Zalau",
      "name_pinyin": "zhalewu",
      "code": "ZA",
      "childrens": []
    }]
  }, {
    "id": "129",
    "pid": "2",
    "path": ",2,129,",
    "level": "2",
    "name": "马耳他",
    "name_en": "Malta",
    "name_pinyin": "maerta",
    "code": "MLT",
    "childrens": []
  }, {
    "id": "133",
    "pid": "2",
    "path": ",2,133,",
    "level": "2",
    "name": "马其顿",
    "name_en": "Macedonia,Former Yugoslav Republic of",
    "name_pinyin": "maqidun",
    "code": "MKD",
    "childrens": []
  }, {
    "id": "137",
    "pid": "2",
    "path": ",2,137,",
    "level": "2",
    "name": "曼岛",
    "name_en": "Isle of Man",
    "name_pinyin": "mandao",
    "code": "IMN",
    "childrens": []
  }, {
    "id": "149",
    "pid": "2",
    "path": ",2,149,",
    "level": "2",
    "name": "摩尔多瓦",
    "name_en": "Moldova",
    "name_pinyin": "moerduowa",
    "code": "MDA",
    "childrens": []
  }, {
    "id": "151",
    "pid": "2",
    "path": ",2,151,",
    "level": "2",
    "name": "摩纳哥",
    "name_en": "Monaco",
    "name_pinyin": "monage",
    "code": "MCO",
    "childrens": []
  }, {
    "id": "164",
    "pid": "2",
    "path": ",2,164,",
    "level": "2",
    "name": "挪威",
    "name_en": "Norway",
    "name_pinyin": "nuowei",
    "code": "NOR",
    "childrens": [{
      "id": "1978",
      "pid": "164",
      "path": ",2,164,1978,",
      "level": "3",
      "name": "阿克什胡斯",
      "name_en": "Akershus",
      "name_pinyin": "akeshihusi",
      "code": "2",
      "childrens": []
    }, {
      "id": "1979",
      "pid": "164",
      "path": ",2,164,1979,",
      "level": "3",
      "name": "奥普兰",
      "name_en": "Oppland",
      "name_pinyin": "aopulan",
      "code": "5",
      "childrens": []
    }, {
      "id": "1980",
      "pid": "164",
      "path": ",2,164,1980,",
      "level": "3",
      "name": "奥斯陆市",
      "name_en": "Oslo",
      "name_pinyin": "aosilushi",
      "code": "3",
      "childrens": []
    }, {
      "id": "1981",
      "pid": "164",
      "path": ",2,164,1981,",
      "level": "3",
      "name": "北特伦德拉格",
      "name_en": "Nord-Trondelag",
      "name_pinyin": "beitelundelage",
      "code": "17",
      "childrens": []
    }, {
      "id": "1982",
      "pid": "164",
      "path": ",2,164,1982,",
      "level": "3",
      "name": "布斯克吕",
      "name_en": "Buskerud",
      "name_pinyin": "busikelv",
      "code": "6",
      "childrens": []
    }, {
      "id": "1983",
      "pid": "164",
      "path": ",2,164,1983,",
      "level": "3",
      "name": "东阿格德尔",
      "name_en": "Aust-Agder",
      "name_pinyin": "dongagedeer",
      "code": "9",
      "childrens": []
    }, {
      "id": "1984",
      "pid": "164",
      "path": ",2,164,1984,",
      "level": "3",
      "name": "东福尔",
      "name_en": "Ostfold",
      "name_pinyin": "dongfuer",
      "code": "1",
      "childrens": []
    }, {
      "id": "1985",
      "pid": "164",
      "path": ",2,164,1985,",
      "level": "3",
      "name": "芬马克",
      "name_en": "Finnmark",
      "name_pinyin": "fenmake",
      "code": "20",
      "childrens": []
    }, {
      "id": "1986",
      "pid": "164",
      "path": ",2,164,1986,",
      "level": "3",
      "name": "海德马克",
      "name_en": "Hedmark",
      "name_pinyin": "haidemake",
      "code": "4",
      "childrens": []
    }, {
      "id": "1987",
      "pid": "164",
      "path": ",2,164,1987,",
      "level": "3",
      "name": "霍达兰",
      "name_en": "Hordaland",
      "name_pinyin": "huodalan",
      "code": "12",
      "childrens": []
    }, {
      "id": "1988",
      "pid": "164",
      "path": ",2,164,1988,",
      "level": "3",
      "name": "罗加兰",
      "name_en": "Rogaland",
      "name_pinyin": "luojialan",
      "code": "11",
      "childrens": []
    }, {
      "id": "1989",
      "pid": "164",
      "path": ",2,164,1989,",
      "level": "3",
      "name": "默勒－鲁姆斯达尔",
      "name_en": "More og Romsdal",
      "name_pinyin": "molelumusidaer",
      "code": "15",
      "childrens": []
    }, {
      "id": "1990",
      "pid": "164",
      "path": ",2,164,1990,",
      "level": "3",
      "name": "南特伦德拉格",
      "name_en": "Sor-Trondelag",
      "name_pinyin": "nantelundelage",
      "code": "16",
      "childrens": []
    }, {
      "id": "1991",
      "pid": "164",
      "path": ",2,164,1991,",
      "level": "3",
      "name": "诺尔兰",
      "name_en": "Nordland",
      "name_pinyin": "nuoerlan",
      "code": "18",
      "childrens": []
    }, {
      "id": "1992",
      "pid": "164",
      "path": ",2,164,1992,",
      "level": "3",
      "name": "松恩－菲尤拉讷",
      "name_en": "Sogn og Fjordane",
      "name_pinyin": "songenfeiyoula",
      "code": "14",
      "childrens": []
    }, {
      "id": "1993",
      "pid": "164",
      "path": ",2,164,1993,",
      "level": "3",
      "name": "泰勒马克",
      "name_en": "Telemark",
      "name_pinyin": "tailemake",
      "code": "8",
      "childrens": []
    }, {
      "id": "1994",
      "pid": "164",
      "path": ",2,164,1994,",
      "level": "3",
      "name": "特罗姆斯",
      "name_en": "Troms",
      "name_pinyin": "teluomusi",
      "code": "19",
      "childrens": []
    }, {
      "id": "1995",
      "pid": "164",
      "path": ",2,164,1995,",
      "level": "3",
      "name": "西阿格德尔",
      "name_en": "Vest-Agder",
      "name_pinyin": "xiagedeer",
      "code": "10",
      "childrens": []
    }, {
      "id": "1996",
      "pid": "164",
      "path": ",2,164,1996,",
      "level": "3",
      "name": "西福尔",
      "name_en": "Vestfold",
      "name_pinyin": "xifuer",
      "code": "7",
      "childrens": []
    }]
  }, {
    "id": "168",
    "pid": "2",
    "path": ",2,168,",
    "level": "2",
    "name": "葡萄牙",
    "name_en": "Portugal",
    "name_pinyin": "putaoya",
    "code": "PRT",
    "childrens": [{
      "id": "1997",
      "pid": "168",
      "path": ",2,168,1997,",
      "level": "3",
      "name": "滨海阿连特茹",
      "name_en": "Alentejo Litoral",
      "name_pinyin": "binhaialianteru",
      "code": "ALL",
      "childrens": []
    }, {
      "id": "1998",
      "pid": "168",
      "path": ",2,168,1998,",
      "level": "3",
      "name": "滨海皮尼亚尔",
      "name_en": "Pinhal Litoral",
      "name_pinyin": "binhaipiniyaer",
      "code": "PLT",
      "childrens": []
    }, {
      "id": "1999",
      "pid": "168",
      "path": ",2,168,1999,",
      "level": "3",
      "name": "波尔图",
      "name_en": "Porto",
      "name_pinyin": "boertu",
      "code": "VDP",
      "childrens": []
    }, {
      "id": "2000",
      "pid": "168",
      "path": ",2,168,2000,",
      "level": "3",
      "name": "杜罗",
      "name_en": "Douro",
      "name_pinyin": "duluo",
      "code": "MDR",
      "childrens": []
    }, {
      "id": "2001",
      "pid": "168",
      "path": ",2,168,2001,",
      "level": "3",
      "name": "恩特拉杜罗伏日",
      "name_en": "Entre Douro e Vouga",
      "name_pinyin": "enteladuluofuri",
      "code": "EDV",
      "childrens": []
    }, {
      "id": "2002",
      "pid": "168",
      "path": ",2,168,2002,",
      "level": "3",
      "name": "法鲁",
      "name_en": "Faro",
      "name_pinyin": "falu",
      "code": "FAO",
      "childrens": []
    }, {
      "id": "2003",
      "pid": "168",
      "path": ",2,168,2003,",
      "level": "3",
      "name": "丰沙尔",
      "name_en": "Funchal",
      "name_pinyin": "fengshaer",
      "code": "FUN",
      "childrens": []
    }, {
      "id": "2004",
      "pid": "168",
      "path": ",2,168,2004,",
      "level": "3",
      "name": "卡瓦多",
      "name_en": "Cavado",
      "name_pinyin": "kawaduo",
      "code": "CAV",
      "childrens": []
    }, {
      "id": "2005",
      "pid": "168",
      "path": ",2,168,2005,",
      "level": "3",
      "name": "科瓦贝拉",
      "name_en": "Cova da Beira",
      "name_pinyin": "kewabeila",
      "code": "CLB",
      "childrens": []
    }, {
      "id": "2006",
      "pid": "168",
      "path": ",2,168,2006,",
      "level": "3",
      "name": "里斯本",
      "name_en": "Lisboa",
      "name_pinyin": "lisiben",
      "code": "LIS",
      "childrens": []
    }, {
      "id": "2007",
      "pid": "168",
      "path": ",2,168,2007,",
      "level": "3",
      "name": "利巴特茹",
      "name_en": "Leziria do Tejo",
      "name_pinyin": "libateru",
      "code": "LTE",
      "childrens": []
    }, {
      "id": "2008",
      "pid": "168",
      "path": ",2,168,2008,",
      "level": "3",
      "name": "梅地奥特茹",
      "name_en": "Medio Tejo",
      "name_pinyin": "meidiaoteru",
      "code": "MTE",
      "childrens": []
    }, {
      "id": "2009",
      "pid": "168",
      "path": ",2,168,2009,",
      "level": "3",
      "name": "米尼奥-利马",
      "name_en": "Minho-Lima",
      "name_pinyin": "miniaolima",
      "code": "MLI",
      "childrens": []
    }, {
      "id": "2010",
      "pid": "168",
      "path": ",2,168,2010,",
      "level": "3",
      "name": "内贝拉北",
      "name_en": "Beira Interior Norte",
      "name_pinyin": "neibeilabei",
      "code": "BIN",
      "childrens": []
    }, {
      "id": "2011",
      "pid": "168",
      "path": ",2,168,2011,",
      "level": "3",
      "name": "内贝拉南",
      "name_en": "Beira Interior Sul",
      "name_pinyin": "neibeilanan",
      "code": "BIS",
      "childrens": []
    }, {
      "id": "2012",
      "pid": "168",
      "path": ",2,168,2012,",
      "level": "3",
      "name": "内皮尼亚尔北",
      "name_en": "Pinhal Interior Norte",
      "name_pinyin": "neipiniyaerbei",
      "code": "PIN",
      "childrens": []
    }, {
      "id": "2013",
      "pid": "168",
      "path": ",2,168,2013,",
      "level": "3",
      "name": "内皮尼亚尔南",
      "name_en": "Pinhal Interior Sul",
      "name_pinyin": "neipiniyaernan",
      "code": "PIS",
      "childrens": []
    }, {
      "id": "2014",
      "pid": "168",
      "path": ",2,168,2014,",
      "level": "3",
      "name": "蓬塔德尔加达",
      "name_en": "Ponta Delgada",
      "name_pinyin": "pengtadeerjiada",
      "code": "PDL",
      "childrens": []
    }, {
      "id": "2015",
      "pid": "168",
      "path": ",2,168,2015,",
      "level": "3",
      "name": "塞图巴尔半岛",
      "name_en": "Peninsula de Setubal",
      "name_pinyin": "saitubaerbandao",
      "code": "PSE",
      "childrens": []
    }, {
      "id": "2016",
      "pid": "168",
      "path": ",2,168,2016,",
      "level": "3",
      "name": "山后",
      "name_en": "Serra da Estrela",
      "name_pinyin": "shanhou",
      "code": "SES",
      "childrens": []
    }, {
      "id": "2017",
      "pid": "168",
      "path": ",2,168,2017,",
      "level": "3",
      "name": "上阿连特茹",
      "name_en": "Alto Alentejo",
      "name_pinyin": "shangalianteru",
      "code": "AAT",
      "childrens": []
    }, {
      "id": "2018",
      "pid": "168",
      "path": ",2,168,2018,",
      "level": "3",
      "name": "上特拉斯山",
      "name_en": "Alto Tros-os-Montes",
      "name_pinyin": "shangtelasishan",
      "code": "ATM",
      "childrens": []
    }, {
      "id": "2019",
      "pid": "168",
      "path": ",2,168,2019,",
      "level": "3",
      "name": "塔梅加",
      "name_en": "Tamega",
      "name_pinyin": "tameijia",
      "code": "TAM",
      "childrens": []
    }, {
      "id": "2020",
      "pid": "168",
      "path": ",2,168,2020,",
      "level": "3",
      "name": "万福",
      "name_en": "Ave",
      "name_pinyin": "wanfu",
      "code": "AES",
      "childrens": []
    }, {
      "id": "2021",
      "pid": "168",
      "path": ",2,168,2021,",
      "level": "3",
      "name": "西部",
      "name_en": "Oeste",
      "name_pinyin": "xibu",
      "code": "OES",
      "childrens": []
    }, {
      "id": "2022",
      "pid": "168",
      "path": ",2,168,2022,",
      "level": "3",
      "name": "下阿连特茹",
      "name_en": "Baixo Alentejo",
      "name_pinyin": "xiaalianteru",
      "code": "BAL",
      "childrens": []
    }, {
      "id": "2023",
      "pid": "168",
      "path": ",2,168,2023,",
      "level": "3",
      "name": "下伏日",
      "name_en": "Baixo Vouga",
      "name_pinyin": "xiafuri",
      "code": "BVO",
      "childrens": []
    }, {
      "id": "2024",
      "pid": "168",
      "path": ",2,168,2024,",
      "level": "3",
      "name": "下蒙德古",
      "name_en": "Baixo Mondego",
      "name_pinyin": "xiamengdegu",
      "code": "BMO",
      "childrens": []
    }, {
      "id": "2025",
      "pid": "168",
      "path": ",2,168,2025,",
      "level": "3",
      "name": "中阿连特茹",
      "name_en": "Alentejo Central",
      "name_pinyin": "zhongalianteru",
      "code": "ALC",
      "childrens": []
    }]
  }, {
    "id": "171",
    "pid": "2",
    "path": ",2,171,",
    "level": "2",
    "name": "瑞典",
    "name_en": "Sweden",
    "name_pinyin": "ruidian",
    "code": "SWE",
    "childrens": [{
      "id": "2073",
      "pid": "171",
      "path": ",2,171,2073,",
      "level": "3",
      "name": "北博滕",
      "name_en": "Norrbottens",
      "name_pinyin": "beibo",
      "code": "BD",
      "childrens": []
    }, {
      "id": "2074",
      "pid": "171",
      "path": ",2,171,2074,",
      "level": "3",
      "name": "布莱金厄",
      "name_en": "Blekinge",
      "name_pinyin": "bulaijine",
      "code": "K",
      "childrens": []
    }, {
      "id": "2075",
      "pid": "171",
      "path": ",2,171,2075,",
      "level": "3",
      "name": "达拉纳",
      "name_en": "Dalarnas",
      "name_pinyin": "dalana",
      "code": "DLN",
      "childrens": []
    }, {
      "id": "2076",
      "pid": "171",
      "path": ",2,171,2076,",
      "level": "3",
      "name": "东约特兰",
      "name_en": "Ustergotland",
      "name_pinyin": "dongyuetelan",
      "code": "UGL",
      "childrens": []
    }, {
      "id": "2077",
      "pid": "171",
      "path": ",2,171,2077,",
      "level": "3",
      "name": "厄勒布鲁",
      "name_en": "Orebro",
      "name_pinyin": "elebulu",
      "code": "T",
      "childrens": []
    }, {
      "id": "2078",
      "pid": "171",
      "path": ",2,171,2078,",
      "level": "3",
      "name": "哥得兰",
      "name_en": "Gotlands",
      "name_pinyin": "gedelan",
      "code": "I",
      "childrens": []
    }, {
      "id": "2079",
      "pid": "171",
      "path": ",2,171,2079,",
      "level": "3",
      "name": "哈兰",
      "name_en": "Hallands",
      "name_pinyin": "halan",
      "code": "N",
      "childrens": []
    }, {
      "id": "2080",
      "pid": "171",
      "path": ",2,171,2080,",
      "level": "3",
      "name": "卡尔马",
      "name_en": "Kalmar",
      "name_pinyin": "kaerma",
      "code": "H",
      "childrens": []
    }, {
      "id": "2081",
      "pid": "171",
      "path": ",2,171,2081,",
      "level": "3",
      "name": "克鲁努贝里",
      "name_en": "Kronobergs",
      "name_pinyin": "kelunubeili",
      "code": "G",
      "childrens": []
    }, {
      "id": "2082",
      "pid": "171",
      "path": ",2,171,2082,",
      "level": "3",
      "name": "南曼兰",
      "name_en": "Sodermanlands",
      "name_pinyin": "nanmanlan",
      "code": "D",
      "childrens": []
    }, {
      "id": "2083",
      "pid": "171",
      "path": ",2,171,2083,",
      "level": "3",
      "name": "斯德哥尔摩",
      "name_en": "Stockholms",
      "name_pinyin": "sidegeermo",
      "code": "AB",
      "childrens": []
    }, {
      "id": "2084",
      "pid": "171",
      "path": ",2,171,2084,",
      "level": "3",
      "name": "斯科耐",
      "name_en": "Skane",
      "name_pinyin": "sikenai",
      "code": "M",
      "childrens": []
    }, {
      "id": "2085",
      "pid": "171",
      "path": ",2,171,2085,",
      "level": "3",
      "name": "韦姆兰",
      "name_en": "Varmlands",
      "name_pinyin": "weimulan",
      "code": "S",
      "childrens": []
    }, {
      "id": "2086",
      "pid": "171",
      "path": ",2,171,2086,",
      "level": "3",
      "name": "乌普萨拉",
      "name_en": "Uppsala",
      "name_pinyin": "wupusala",
      "code": "C",
      "childrens": []
    }, {
      "id": "2087",
      "pid": "171",
      "path": ",2,171,2087,",
      "level": "3",
      "name": "西博滕",
      "name_en": "Vasterbottens",
      "name_pinyin": "xibo",
      "code": "AC",
      "childrens": []
    }, {
      "id": "2088",
      "pid": "171",
      "path": ",2,171,2088,",
      "level": "3",
      "name": "西曼兰",
      "name_en": "Vastmanlands",
      "name_pinyin": "ximanlan",
      "code": "U",
      "childrens": []
    }, {
      "id": "2089",
      "pid": "171",
      "path": ",2,171,2089,",
      "level": "3",
      "name": "西诺尔兰",
      "name_en": "Vasternorrlands",
      "name_pinyin": "xinuoerlan",
      "code": "Y",
      "childrens": []
    }, {
      "id": "2090",
      "pid": "171",
      "path": ",2,171,2090,",
      "level": "3",
      "name": "西约特兰",
      "name_en": "Vastra Gotalands",
      "name_pinyin": "xiyuetelan",
      "code": "O",
      "childrens": []
    }, {
      "id": "2091",
      "pid": "171",
      "path": ",2,171,2091,",
      "level": "3",
      "name": "延雪平",
      "name_en": "Jonkopings",
      "name_pinyin": "yanxueping",
      "code": "F",
      "childrens": []
    }, {
      "id": "2092",
      "pid": "171",
      "path": ",2,171,2092,",
      "level": "3",
      "name": "耶夫勒堡",
      "name_en": "Gavleborgs",
      "name_pinyin": "yefulebao",
      "code": "X",
      "childrens": []
    }, {
      "id": "2093",
      "pid": "171",
      "path": ",2,171,2093,",
      "level": "3",
      "name": "耶姆特兰",
      "name_en": "Jamtlands",
      "name_pinyin": "yemutelan",
      "code": "Z",
      "childrens": []
    }]
  }, {
    "id": "172",
    "pid": "2",
    "path": ",2,172,",
    "level": "2",
    "name": "瑞士",
    "name_en": "Switzerland",
    "name_pinyin": "ruishi",
    "code": "CHE",
    "childrens": [{
      "id": "2094",
      "pid": "172",
      "path": ",2,172,2094,",
      "level": "3",
      "name": "阿尔高",
      "name_en": "Aargau",
      "name_pinyin": "aergao",
      "code": "AG",
      "childrens": []
    }, {
      "id": "2095",
      "pid": "172",
      "path": ",2,172,2095,",
      "level": "3",
      "name": "巴塞尔城市",
      "name_en": "Basel－Sstadt",
      "name_pinyin": "basaierchengshi",
      "code": "BS",
      "childrens": []
    }, {
      "id": "2096",
      "pid": "172",
      "path": ",2,172,2096,",
      "level": "3",
      "name": "巴塞尔乡村",
      "name_en": "Basel Landschaft",
      "name_pinyin": "basaierxiangcun",
      "code": "BL",
      "childrens": []
    }, {
      "id": "2097",
      "pid": "172",
      "path": ",2,172,2097,",
      "level": "3",
      "name": "伯尔尼",
      "name_en": "Bern",
      "name_pinyin": "boerni",
      "code": "BE",
      "childrens": []
    }, {
      "id": "2098",
      "pid": "172",
      "path": ",2,172,2098,",
      "level": "3",
      "name": "楚格",
      "name_en": "Zug",
      "name_pinyin": "chuge",
      "code": "ZG",
      "childrens": []
    }, {
      "id": "2099",
      "pid": "172",
      "path": ",2,172,2099,",
      "level": "3",
      "name": "弗里堡",
      "name_en": "Freiburg",
      "name_pinyin": "fulibao",
      "code": "FR",
      "childrens": []
    }, {
      "id": "2100",
      "pid": "172",
      "path": ",2,172,2100,",
      "level": "3",
      "name": "格拉鲁斯",
      "name_en": "Glarus",
      "name_pinyin": "gelalusi",
      "code": "GL",
      "childrens": []
    }, {
      "id": "2101",
      "pid": "172",
      "path": ",2,172,2101,",
      "level": "3",
      "name": "格劳宾登",
      "name_en": "Graubünden",
      "name_pinyin": "gelaobindeng",
      "code": "GR",
      "childrens": []
    }, {
      "id": "2102",
      "pid": "172",
      "path": ",2,172,2102,",
      "level": "3",
      "name": "卢塞恩",
      "name_en": "Luzern",
      "name_pinyin": "lusaien",
      "code": "LU",
      "childrens": []
    }, {
      "id": "2103",
      "pid": "172",
      "path": ",2,172,2103,",
      "level": "3",
      "name": "洛桑",
      "name_en": "Lausanne",
      "name_pinyin": "luosang",
      "code": "LA",
      "childrens": []
    }, {
      "id": "2104",
      "pid": "172",
      "path": ",2,172,2104,",
      "level": "3",
      "name": "纳沙泰尔",
      "name_en": "Neuchatel",
      "name_pinyin": "nashataier",
      "code": "NE",
      "childrens": []
    }, {
      "id": "2105",
      "pid": "172",
      "path": ",2,172,2105,",
      "level": "3",
      "name": "内阿彭策尔",
      "name_en": "Appenzell Innerrhodn",
      "name_pinyin": "neiapengceer",
      "code": "AI",
      "childrens": []
    }, {
      "id": "2106",
      "pid": "172",
      "path": ",2,172,2106,",
      "level": "3",
      "name": "日内瓦",
      "name_en": "Geneve",
      "name_pinyin": "rineiwa",
      "code": "GE",
      "childrens": []
    }, {
      "id": "2107",
      "pid": "172",
      "path": ",2,172,2107,",
      "level": "3",
      "name": "汝拉",
      "name_en": "Jura",
      "name_pinyin": "rula",
      "code": "JU",
      "childrens": []
    }, {
      "id": "2108",
      "pid": "172",
      "path": ",2,172,2108,",
      "level": "3",
      "name": "沙夫豪森",
      "name_en": "Schaffhausen",
      "name_pinyin": "shafuhaosen",
      "code": "SH",
      "childrens": []
    }, {
      "id": "2109",
      "pid": "172",
      "path": ",2,172,2109,",
      "level": "3",
      "name": "上瓦尔登",
      "name_en": "Obwalden",
      "name_pinyin": "shangwaerdeng",
      "code": "OW",
      "childrens": []
    }, {
      "id": "2110",
      "pid": "172",
      "path": ",2,172,2110,",
      "level": "3",
      "name": "圣加仑",
      "name_en": "St.Gallen",
      "name_pinyin": "shengjialun",
      "code": "SG",
      "childrens": []
    }, {
      "id": "2111",
      "pid": "172",
      "path": ",2,172,2111,",
      "level": "3",
      "name": "施维茨",
      "name_en": "Schwyz",
      "name_pinyin": "shiweici",
      "code": "SZ",
      "childrens": []
    }, {
      "id": "2112",
      "pid": "172",
      "path": ",2,172,2112,",
      "level": "3",
      "name": "苏黎世",
      "name_en": "Zurich",
      "name_pinyin": "sulishi",
      "code": "ZH",
      "childrens": []
    }, {
      "id": "2113",
      "pid": "172",
      "path": ",2,172,2113,",
      "level": "3",
      "name": "索洛图恩",
      "name_en": "Solothurn",
      "name_pinyin": "suoluotuen",
      "code": "SO",
      "childrens": []
    }, {
      "id": "2114",
      "pid": "172",
      "path": ",2,172,2114,",
      "level": "3",
      "name": "提契诺",
      "name_en": "Ticino",
      "name_pinyin": "tiqinuo",
      "code": "TI",
      "childrens": []
    }, {
      "id": "2115",
      "pid": "172",
      "path": ",2,172,2115,",
      "level": "3",
      "name": "图尔高",
      "name_en": "Thurgau",
      "name_pinyin": "tuergao",
      "code": "TG",
      "childrens": []
    }, {
      "id": "2116",
      "pid": "172",
      "path": ",2,172,2116,",
      "level": "3",
      "name": "瓦莱",
      "name_en": "Wallis",
      "name_pinyin": "walai",
      "code": "VS",
      "childrens": []
    }, {
      "id": "2117",
      "pid": "172",
      "path": ",2,172,2117,",
      "level": "3",
      "name": "外阿彭策尔",
      "name_en": "Appenzell Ausserrhon",
      "name_pinyin": "waiapengceer",
      "code": "AR",
      "childrens": []
    }, {
      "id": "2118",
      "pid": "172",
      "path": ",2,172,2118,",
      "level": "3",
      "name": "沃",
      "name_en": "Vaud",
      "name_pinyin": "wo",
      "code": "VD",
      "childrens": []
    }, {
      "id": "2119",
      "pid": "172",
      "path": ",2,172,2119,",
      "level": "3",
      "name": "乌里",
      "name_en": "Uri",
      "name_pinyin": "wuli",
      "code": "UR",
      "childrens": []
    }, {
      "id": "2120",
      "pid": "172",
      "path": ",2,172,2120,",
      "level": "3",
      "name": "下瓦尔登",
      "name_en": "Nidwalden",
      "name_pinyin": "xiawaerdeng",
      "code": "NW",
      "childrens": []
    }]
  }, {
    "id": "175",
    "pid": "2",
    "path": ",2,175,",
    "level": "2",
    "name": "塞尔维亚,黑山",
    "name_en": "Serbia,Montenegro",
    "name_pinyin": "saierweiyaheishan",
    "code": "SCG",
    "childrens": [{
      "id": "2144",
      "pid": "175",
      "path": ",2,175,2144,",
      "level": "3",
      "name": "贝尔格莱德",
      "name_en": "Beograd",
      "name_pinyin": "beiergelaide",
      "code": "BEG",
      "childrens": []
    }, {
      "id": "2145",
      "pid": "175",
      "path": ",2,175,2145,",
      "level": "3",
      "name": "波德戈里察",
      "name_en": "Podgorica",
      "name_pinyin": "bodegelicha",
      "code": "POD",
      "childrens": []
    }, {
      "id": "2146",
      "pid": "175",
      "path": ",2,175,2146,",
      "level": "3",
      "name": "克拉古涅瓦茨",
      "name_en": "Kragujevac",
      "name_pinyin": "kelaguniewaci",
      "code": "KGV",
      "childrens": []
    }, {
      "id": "2147",
      "pid": "175",
      "path": ",2,175,2147,",
      "level": "3",
      "name": "尼什",
      "name_en": "Nis",
      "name_pinyin": "nishi",
      "code": "INI",
      "childrens": []
    }, {
      "id": "2148",
      "pid": "175",
      "path": ",2,175,2148,",
      "level": "3",
      "name": "诺维萨德",
      "name_en": "Novi Sad",
      "name_pinyin": "nuoweisade",
      "code": "NVS",
      "childrens": []
    }, {
      "id": "2149",
      "pid": "175",
      "path": ",2,175,2149,",
      "level": "3",
      "name": "普里什蒂纳",
      "name_en": "Pristina",
      "name_pinyin": "pulishidina",
      "code": "PRN",
      "childrens": []
    }, {
      "id": "2150",
      "pid": "175",
      "path": ",2,175,2150,",
      "level": "3",
      "name": "苏博蒂察",
      "name_en": "Subotica",
      "name_pinyin": "subodicha",
      "code": "SUB",
      "childrens": []
    }, {
      "id": "2151",
      "pid": "175",
      "path": ",2,175,2151,",
      "level": "3",
      "name": "泽蒙",
      "name_en": "Zemun",
      "name_pinyin": "zemeng",
      "code": "ZEM",
      "childrens": []
    }]
  }, {
    "id": "186",
    "pid": "2",
    "path": ",2,186,",
    "level": "2",
    "name": "圣马力诺",
    "name_en": "San Marino",
    "name_pinyin": "shengmalinuo",
    "code": "SMR",
    "childrens": []
  }, {
    "id": "190",
    "pid": "2",
    "path": ",2,190,",
    "level": "2",
    "name": "斯洛伐克",
    "name_en": "Slovakia",
    "name_pinyin": "siluofake",
    "code": "SVK",
    "childrens": [{
      "id": "2220",
      "pid": "190",
      "path": ",2,190,2220,",
      "level": "3",
      "name": "班斯卡-比斯特里察",
      "name_en": "Banskobystricky",
      "name_pinyin": "bansikabisitelicha",
      "code": "BBY",
      "childrens": []
    }, {
      "id": "2221",
      "pid": "190",
      "path": ",2,190,2221,",
      "level": "3",
      "name": "布拉迪斯拉发",
      "name_en": "Bratislavsky",
      "name_pinyin": "buladisilafa",
      "code": "BTS",
      "childrens": []
    }, {
      "id": "2222",
      "pid": "190",
      "path": ",2,190,2222,",
      "level": "3",
      "name": "科希策",
      "name_en": "Koricky",
      "name_pinyin": "kexice",
      "code": "KOR",
      "childrens": []
    }, {
      "id": "2223",
      "pid": "190",
      "path": ",2,190,2223,",
      "level": "3",
      "name": "尼特拉",
      "name_en": "Nitriansky",
      "name_pinyin": "nitela",
      "code": "NRA",
      "childrens": []
    }, {
      "id": "2224",
      "pid": "190",
      "path": ",2,190,2224,",
      "level": "3",
      "name": "普雷绍夫",
      "name_en": "Prerovsky",
      "name_pinyin": "puleishaofu",
      "code": "POV",
      "childrens": []
    }, {
      "id": "2225",
      "pid": "190",
      "path": ",2,190,2225,",
      "level": "3",
      "name": "日利纳",
      "name_en": "Rilinsky",
      "name_pinyin": "rilina",
      "code": "RIL",
      "childrens": []
    }, {
      "id": "2226",
      "pid": "190",
      "path": ",2,190,2226,",
      "level": "3",
      "name": "特尔纳瓦",
      "name_en": "Trnavsky",
      "name_pinyin": "teernawa",
      "code": "TNA",
      "childrens": []
    }, {
      "id": "2227",
      "pid": "190",
      "path": ",2,190,2227,",
      "level": "3",
      "name": "特伦钦",
      "name_en": "Trenriansky",
      "name_pinyin": "telunqin",
      "code": "TRE",
      "childrens": []
    }]
  }, {
    "id": "191",
    "pid": "2",
    "path": ",2,191,",
    "level": "2",
    "name": "斯洛文尼亚",
    "name_en": "Slovenia",
    "name_pinyin": "siluowenniya",
    "code": "SVN",
    "childrens": [{
      "id": "2228",
      "pid": "191",
      "path": ",2,191,2228,",
      "level": "3",
      "name": "奥巴尔诺-克拉",
      "name_en": "Obalno-kraska",
      "name_pinyin": "aobaernuokela",
      "code": "OKR",
      "childrens": []
    }, {
      "id": "2229",
      "pid": "191",
      "path": ",2,191,2229,",
      "level": "3",
      "name": "奥斯雷德涅斯洛文",
      "name_en": "Osrednjeslovenska",
      "name_pinyin": "aosileideniesiluowen",
      "code": "OSR",
      "childrens": []
    }, {
      "id": "2230",
      "pid": "191",
      "path": ",2,191,2230,",
      "level": "3",
      "name": "波德拉夫",
      "name_en": "Podravska",
      "name_pinyin": "bodelafu",
      "code": "POD",
      "childrens": []
    }, {
      "id": "2231",
      "pid": "191",
      "path": ",2,191,2231,",
      "level": "3",
      "name": "波穆尔",
      "name_en": "Pomurska",
      "name_pinyin": "bomuer",
      "code": "POM",
      "childrens": []
    }, {
      "id": "2232",
      "pid": "191",
      "path": ",2,191,2232,",
      "level": "3",
      "name": "多雷尼",
      "name_en": "Dolenjska",
      "name_pinyin": "duoleini",
      "code": "DLJ",
      "childrens": []
    }, {
      "id": "2233",
      "pid": "191",
      "path": ",2,191,2233,",
      "level": "3",
      "name": "戈雷尼",
      "name_en": "Gorenjska",
      "name_pinyin": "geleini",
      "code": "GSZ",
      "childrens": []
    }, {
      "id": "2234",
      "pid": "191",
      "path": ",2,191,2234,",
      "level": "3",
      "name": "戈里",
      "name_en": "Goriska",
      "name_pinyin": "geli",
      "code": "GSK",
      "childrens": []
    }, {
      "id": "2235",
      "pid": "191",
      "path": ",2,191,2235,",
      "level": "3",
      "name": "科洛",
      "name_en": "Koroska",
      "name_pinyin": "keluo",
      "code": "KOR",
      "childrens": []
    }, {
      "id": "2236",
      "pid": "191",
      "path": ",2,191,2236,",
      "level": "3",
      "name": "诺特拉尼",
      "name_en": "Notranjsko-kraska",
      "name_pinyin": "nuotelani",
      "code": "NKR",
      "childrens": []
    }, {
      "id": "2237",
      "pid": "191",
      "path": ",2,191,2237,",
      "level": "3",
      "name": "萨维尼",
      "name_en": "Savinjska",
      "name_pinyin": "saweini",
      "code": "SAV",
      "childrens": []
    }, {
      "id": "2238",
      "pid": "191",
      "path": ",2,191,2238,",
      "level": "3",
      "name": "斯波德涅波萨夫",
      "name_en": "Spodnjeposavska",
      "name_pinyin": "sibodeniebosafu",
      "code": "SPO",
      "childrens": []
    }, {
      "id": "2239",
      "pid": "191",
      "path": ",2,191,2239,",
      "level": "3",
      "name": "扎萨夫",
      "name_en": "Zasavska",
      "name_pinyin": "zhasafu",
      "code": "ZAS",
      "childrens": []
    }]
  }, {
    "id": "192",
    "pid": "2",
    "path": ",2,192,",
    "level": "2",
    "name": "斯瓦尔巴和扬马廷",
    "name_en": "Svalbard and Jan Mayen",
    "name_pinyin": "siwaerbaheyangmating",
    "code": "SJM",
    "childrens": []
  }, {
    "id": "218",
    "pid": "2",
    "path": ",2,218,",
    "level": "2",
    "name": "乌克兰",
    "name_en": "Ukraine",
    "name_pinyin": "wukelan",
    "code": "UKR",
    "childrens": [{
      "id": "2609",
      "pid": "218",
      "path": ",2,218,2609,",
      "level": "3",
      "name": "敖德萨",
      "name_en": "Odessa",
      "name_pinyin": "aodesa",
      "code": "51",
      "childrens": []
    }, {
      "id": "2610",
      "pid": "218",
      "path": ",2,218,2610,",
      "level": "3",
      "name": "波尔塔瓦",
      "name_en": "Poltava",
      "name_pinyin": "boertawa",
      "code": "53",
      "childrens": []
    }, {
      "id": "2611",
      "pid": "218",
      "path": ",2,218,2611,",
      "level": "3",
      "name": "第聂伯罗波得罗夫斯克",
      "name_en": "Dnipropetrovsk",
      "name_pinyin": "dinieboluobodeluofusike",
      "code": "12",
      "childrens": []
    }, {
      "id": "2612",
      "pid": "218",
      "path": ",2,218,2612,",
      "level": "3",
      "name": "顿涅茨克",
      "name_en": "Donetsk",
      "name_pinyin": "dunniecike",
      "code": "14",
      "childrens": []
    }, {
      "id": "2613",
      "pid": "218",
      "path": ",2,218,2613,",
      "level": "3",
      "name": "哈尔科夫",
      "name_en": "Kharkiv",
      "name_pinyin": "haerkefu",
      "code": "63",
      "childrens": []
    }, {
      "id": "2614",
      "pid": "218",
      "path": ",2,218,2614,",
      "level": "3",
      "name": "赫尔松州",
      "name_en": "Khersonsrka",
      "name_pinyin": "heersongzhou",
      "code": "65",
      "childrens": []
    }, {
      "id": "2615",
      "pid": "218",
      "path": ",2,218,2615,",
      "level": "3",
      "name": "赫梅利尼茨基",
      "name_en": "Khmelnytsky",
      "name_pinyin": "hemeiliniciji",
      "code": "68",
      "childrens": []
    }, {
      "id": "2616",
      "pid": "218",
      "path": ",2,218,2616,",
      "level": "3",
      "name": "基辅",
      "name_en": "Kyiv",
      "name_pinyin": "jifu",
      "code": "30",
      "childrens": []
    }, {
      "id": "2617",
      "pid": "218",
      "path": ",2,218,2617,",
      "level": "3",
      "name": "基洛夫格勒",
      "name_en": "Kirovohrad",
      "name_pinyin": "jiluofugele",
      "code": "35",
      "childrens": []
    }, {
      "id": "2618",
      "pid": "218",
      "path": ",2,218,2618,",
      "level": "3",
      "name": "捷尔诺波尔",
      "name_en": "Ternopil",
      "name_pinyin": "jieernuoboer",
      "code": "61",
      "childrens": []
    }, {
      "id": "2619",
      "pid": "218",
      "path": ",2,218,2619,",
      "level": "3",
      "name": "克里米亚自治共和国",
      "name_en": "Respublika Krym",
      "name_pinyin": "kelimiyazizhigongheguo",
      "code": "43",
      "childrens": []
    }, {
      "id": "2620",
      "pid": "218",
      "path": ",2,218,2620,",
      "level": "3",
      "name": "利沃夫",
      "name_en": "Lviv",
      "name_pinyin": "liwofu",
      "code": "46",
      "childrens": []
    }, {
      "id": "2621",
      "pid": "218",
      "path": ",2,218,2621,",
      "level": "3",
      "name": "卢甘斯克",
      "name_en": "Luhansk",
      "name_pinyin": "lugansike",
      "code": "9",
      "childrens": []
    }, {
      "id": "2622",
      "pid": "218",
      "path": ",2,218,2622,",
      "level": "3",
      "name": "罗夫诺",
      "name_en": "Rivne",
      "name_pinyin": "luofunuo",
      "code": "56",
      "childrens": []
    }, {
      "id": "2623",
      "pid": "218",
      "path": ",2,218,2623,",
      "level": "3",
      "name": "尼古拉耶夫",
      "name_en": "Mykolayiv",
      "name_pinyin": "nigulayefu",
      "code": "48",
      "childrens": []
    }, {
      "id": "2624",
      "pid": "218",
      "path": ",2,218,2624,",
      "level": "3",
      "name": "切尔卡瑟",
      "name_en": "Cherkasy",
      "name_pinyin": "qieerkase",
      "code": "71",
      "childrens": []
    }, {
      "id": "2625",
      "pid": "218",
      "path": ",2,218,2625,",
      "level": "3",
      "name": "切尔尼戈夫",
      "name_en": "Chernihiv",
      "name_pinyin": "qieernigefu",
      "code": "74",
      "childrens": []
    }, {
      "id": "2626",
      "pid": "218",
      "path": ",2,218,2626,",
      "level": "3",
      "name": "切尔诺夫策",
      "name_en": "Chernivtsi",
      "name_pinyin": "qieernuofuce",
      "code": "77",
      "childrens": []
    }, {
      "id": "2627",
      "pid": "218",
      "path": ",2,218,2627,",
      "level": "3",
      "name": "日托米尔",
      "name_en": "Zhytomyr",
      "name_pinyin": "rituomier",
      "code": "18",
      "childrens": []
    }, {
      "id": "2628",
      "pid": "218",
      "path": ",2,218,2628,",
      "level": "3",
      "name": "苏梅",
      "name_en": "Sumy",
      "name_pinyin": "sumei",
      "code": "59",
      "childrens": []
    }, {
      "id": "2629",
      "pid": "218",
      "path": ",2,218,2629,",
      "level": "3",
      "name": "外喀尔巴阡",
      "name_en": "Zakarpatska",
      "name_pinyin": "waikaerba",
      "code": "21",
      "childrens": []
    }, {
      "id": "2630",
      "pid": "218",
      "path": ",2,218,2630,",
      "level": "3",
      "name": "文尼察",
      "name_en": "Vinnytsya",
      "name_pinyin": "wennicha",
      "code": "5",
      "childrens": []
    }, {
      "id": "2631",
      "pid": "218",
      "path": ",2,218,2631,",
      "level": "3",
      "name": "沃伦",
      "name_en": "Volyn",
      "name_pinyin": "wolun",
      "code": "7",
      "childrens": []
    }, {
      "id": "2632",
      "pid": "218",
      "path": ",2,218,2632,",
      "level": "3",
      "name": "伊万－弗兰科夫州",
      "name_en": "Ivano-Frankivsk",
      "name_pinyin": "yiwanfulankefuzhou",
      "code": "26",
      "childrens": []
    }, {
      "id": "2633",
      "pid": "218",
      "path": ",2,218,2633,",
      "level": "3",
      "name": "扎波罗热",
      "name_en": "Zaporizhzhya",
      "name_pinyin": "zhaboluore",
      "code": "23",
      "childrens": []
    }]
  }, {
    "id": "221",
    "pid": "2",
    "path": ",2,221,",
    "level": "2",
    "name": "西班牙",
    "name_en": "Spain",
    "name_pinyin": "xibanya",
    "code": "ESP",
    "childrens": [{
      "id": "2667",
      "pid": "221",
      "path": ",2,221,2667,",
      "level": "3",
      "name": "阿尔梅里亚",
      "name_en": "Almeria",
      "name_pinyin": "aermeiliya",
      "code": "LEI",
      "childrens": []
    }, {
      "id": "2668",
      "pid": "221",
      "path": ",2,221,2668,",
      "level": "3",
      "name": "阿尔瓦塞特",
      "name_en": "Albacete",
      "name_pinyin": "aerwasaite",
      "code": "ALB",
      "childrens": []
    }, {
      "id": "2669",
      "pid": "221",
      "path": ",2,221,2669,",
      "level": "3",
      "name": "阿拉瓦",
      "name_en": "Alava",
      "name_pinyin": "alawa",
      "code": "ALA",
      "childrens": []
    }, {
      "id": "2670",
      "pid": "221",
      "path": ",2,221,2670,",
      "level": "3",
      "name": "阿利坎特",
      "name_en": "Alicante",
      "name_pinyin": "alikante",
      "code": "ALC",
      "childrens": []
    }, {
      "id": "2671",
      "pid": "221",
      "path": ",2,221,2671,",
      "level": "3",
      "name": "阿斯图利亚斯",
      "name_en": "Asturias",
      "name_pinyin": "asituliyasi",
      "code": "AST",
      "childrens": []
    }, {
      "id": "2672",
      "pid": "221",
      "path": ",2,221,2672,",
      "level": "3",
      "name": "阿维拉",
      "name_en": "Avila",
      "name_pinyin": "aweila",
      "code": "AVI",
      "childrens": []
    }, {
      "id": "2673",
      "pid": "221",
      "path": ",2,221,2673,",
      "level": "3",
      "name": "奥伦塞",
      "name_en": "Orense",
      "name_pinyin": "aolunsai",
      "code": "ORE",
      "childrens": []
    }, {
      "id": "2674",
      "pid": "221",
      "path": ",2,221,2674,",
      "level": "3",
      "name": "巴达霍斯",
      "name_en": "Badajoz",
      "name_pinyin": "badahuosi",
      "code": "BJZ",
      "childrens": []
    }, {
      "id": "2675",
      "pid": "221",
      "path": ",2,221,2675,",
      "level": "3",
      "name": "巴利阿里",
      "name_en": "Baleares",
      "name_pinyin": "baliali",
      "code": "BLR",
      "childrens": []
    }, {
      "id": "2676",
      "pid": "221",
      "path": ",2,221,2676,",
      "level": "3",
      "name": "巴利亚多利德",
      "name_en": "Valladolid",
      "name_pinyin": "baliyaduolide",
      "code": "VLL",
      "childrens": []
    }, {
      "id": "2677",
      "pid": "221",
      "path": ",2,221,2677,",
      "level": "3",
      "name": "巴伦西亚",
      "name_en": "Valencia",
      "name_pinyin": "balunxiya",
      "code": "VLC",
      "childrens": []
    }, {
      "id": "2678",
      "pid": "221",
      "path": ",2,221,2678,",
      "level": "3",
      "name": "巴塞罗那",
      "name_en": "Barcelona",
      "name_pinyin": "basailuona",
      "code": "BCN",
      "childrens": []
    }, {
      "id": "2679",
      "pid": "221",
      "path": ",2,221,2679,",
      "level": "3",
      "name": "比斯开",
      "name_en": "Vizcaya",
      "name_pinyin": "bisikai",
      "code": "VSE",
      "childrens": []
    }, {
      "id": "2680",
      "pid": "221",
      "path": ",2,221,2680,",
      "level": "3",
      "name": "布尔戈斯",
      "name_en": "Burgos",
      "name_pinyin": "buergesi",
      "code": "BUR",
      "childrens": []
    }, {
      "id": "2681",
      "pid": "221",
      "path": ",2,221,2681,",
      "level": "3",
      "name": "格拉纳达",
      "name_en": "Granada",
      "name_pinyin": "gelanada",
      "code": "GRX",
      "childrens": []
    }, {
      "id": "2682",
      "pid": "221",
      "path": ",2,221,2682,",
      "level": "3",
      "name": "瓜达拉哈拉",
      "name_en": "Guadalajara",
      "name_pinyin": "guadalahala",
      "code": "GUA",
      "childrens": []
    }, {
      "id": "2683",
      "pid": "221",
      "path": ",2,221,2683,",
      "level": "3",
      "name": "哈恩",
      "name_en": "Jaen",
      "name_pinyin": "haen",
      "code": "JAE",
      "childrens": []
    }, {
      "id": "2684",
      "pid": "221",
      "path": ",2,221,2684,",
      "level": "3",
      "name": "赫罗纳",
      "name_en": "Gerona",
      "name_pinyin": "heluona",
      "code": "GRO",
      "childrens": []
    }, {
      "id": "2685",
      "pid": "221",
      "path": ",2,221,2685,",
      "level": "3",
      "name": "吉普斯夸",
      "name_en": "Guipuzcoa",
      "name_pinyin": "jipusikua",
      "code": "GUI",
      "childrens": []
    }, {
      "id": "2686",
      "pid": "221",
      "path": ",2,221,2686,",
      "level": "3",
      "name": "加的斯",
      "name_en": "Cadiz",
      "name_pinyin": "jiadesi",
      "code": "CAD",
      "childrens": []
    }, {
      "id": "2687",
      "pid": "221",
      "path": ",2,221,2687,",
      "level": "3",
      "name": "卡塞雷斯",
      "name_en": "Caceres",
      "name_pinyin": "kasaileisi",
      "code": "CCS",
      "childrens": []
    }, {
      "id": "2688",
      "pid": "221",
      "path": ",2,221,2688,",
      "level": "3",
      "name": "卡斯蒂利亚",
      "name_en": "Cludad Real",
      "name_pinyin": "kasidiliya",
      "code": "CIR",
      "childrens": []
    }, {
      "id": "2689",
      "pid": "221",
      "path": ",2,221,2689,",
      "level": "3",
      "name": "卡斯特利翁",
      "name_en": "Castellon",
      "name_pinyin": "kasiteliweng",
      "code": "CAS",
      "childrens": []
    }, {
      "id": "2690",
      "pid": "221",
      "path": ",2,221,2690,",
      "level": "3",
      "name": "科尔多瓦",
      "name_en": "Cordoba",
      "name_pinyin": "keerduowa",
      "code": "ODB",
      "childrens": []
    }, {
      "id": "2691",
      "pid": "221",
      "path": ",2,221,2691,",
      "level": "3",
      "name": "昆卡",
      "name_en": "Cuenca",
      "name_pinyin": "kunka",
      "code": "CUE",
      "childrens": []
    }, {
      "id": "2692",
      "pid": "221",
      "path": ",2,221,2692,",
      "level": "3",
      "name": "拉科鲁尼亚",
      "name_en": "La Coruna",
      "name_pinyin": "lakeluniya",
      "code": "LCG",
      "childrens": []
    }, {
      "id": "2693",
      "pid": "221",
      "path": ",2,221,2693,",
      "level": "3",
      "name": "拉里奥哈",
      "name_en": "La Rioja",
      "name_pinyin": "laliaoha",
      "code": "ARL",
      "childrens": []
    }, {
      "id": "2694",
      "pid": "221",
      "path": ",2,221,2694,",
      "level": "3",
      "name": "拉斯帕尔马斯",
      "name_en": "Las Palmas",
      "name_pinyin": "lasipaermasi",
      "code": "LPA",
      "childrens": []
    }, {
      "id": "2695",
      "pid": "221",
      "path": ",2,221,2695,",
      "level": "3",
      "name": "莱昂",
      "name_en": "Leon",
      "name_pinyin": "laiang",
      "code": "LEN",
      "childrens": []
    }, {
      "id": "2696",
      "pid": "221",
      "path": ",2,221,2696,",
      "level": "3",
      "name": "莱里达",
      "name_en": "Lleida",
      "name_pinyin": "lailida",
      "code": "LLE",
      "childrens": []
    }, {
      "id": "2697",
      "pid": "221",
      "path": ",2,221,2697,",
      "level": "3",
      "name": "卢戈",
      "name_en": "Provincia de Lugo",
      "name_pinyin": "luge",
      "code": "LGO",
      "childrens": []
    }, {
      "id": "2698",
      "pid": "221",
      "path": ",2,221,2698,",
      "level": "3",
      "name": "马德里",
      "name_en": "Madrid",
      "name_pinyin": "madeli",
      "code": "MAD",
      "childrens": []
    }, {
      "id": "2699",
      "pid": "221",
      "path": ",2,221,2699,",
      "level": "3",
      "name": "马拉加",
      "name_en": "Malaga",
      "name_pinyin": "malajia",
      "code": "AGP",
      "childrens": []
    }, {
      "id": "2700",
      "pid": "221",
      "path": ",2,221,2700,",
      "level": "3",
      "name": "穆尔西亚",
      "name_en": "Murcia",
      "name_pinyin": "muerxiya",
      "code": "MJV",
      "childrens": []
    }, {
      "id": "2701",
      "pid": "221",
      "path": ",2,221,2701,",
      "level": "3",
      "name": "纳瓦拉",
      "name_en": "Navarra",
      "name_pinyin": "nawala",
      "code": "NVV",
      "childrens": []
    }, {
      "id": "2702",
      "pid": "221",
      "path": ",2,221,2702,",
      "level": "3",
      "name": "帕伦西亚",
      "name_en": "Palencia",
      "name_pinyin": "palunxiya",
      "code": "PAC",
      "childrens": []
    }, {
      "id": "2703",
      "pid": "221",
      "path": ",2,221,2703,",
      "level": "3",
      "name": "蓬特韦德拉",
      "name_en": "Provincia de Pontevedra",
      "name_pinyin": "pengteweidela",
      "code": "PEV",
      "childrens": []
    }, {
      "id": "2704",
      "pid": "221",
      "path": ",2,221,2704,",
      "level": "3",
      "name": "萨拉戈萨",
      "name_en": "Zaragoza",
      "name_pinyin": "salagesa",
      "code": "ZAZ",
      "childrens": []
    }, {
      "id": "2705",
      "pid": "221",
      "path": ",2,221,2705,",
      "level": "3",
      "name": "萨拉曼卡",
      "name_en": "Salamanca",
      "name_pinyin": "salamanka",
      "code": "SLM",
      "childrens": []
    }, {
      "id": "2706",
      "pid": "221",
      "path": ",2,221,2706,",
      "level": "3",
      "name": "萨莫拉",
      "name_en": "Zamora",
      "name_pinyin": "samola",
      "code": "ZMR",
      "childrens": []
    }, {
      "id": "2707",
      "pid": "221",
      "path": ",2,221,2707,",
      "level": "3",
      "name": "塞哥维亚",
      "name_en": "Segovia",
      "name_pinyin": "saigeweiya",
      "code": "SEG",
      "childrens": []
    }, {
      "id": "2708",
      "pid": "221",
      "path": ",2,221,2708,",
      "level": "3",
      "name": "塞维利亚",
      "name_en": "Sevilla",
      "name_pinyin": "saiweiliya",
      "code": "SVQ",
      "childrens": []
    }, {
      "id": "2709",
      "pid": "221",
      "path": ",2,221,2709,",
      "level": "3",
      "name": "桑坦德",
      "name_en": "Santander",
      "name_pinyin": "sangtande",
      "code": "SDR",
      "childrens": []
    }, {
      "id": "2710",
      "pid": "221",
      "path": ",2,221,2710,",
      "level": "3",
      "name": "圣克鲁斯-德特内里费",
      "name_en": "Santa Cruz de Tenerife",
      "name_pinyin": "shengkelusideteneilifei",
      "code": "SCT",
      "childrens": []
    }, {
      "id": "2711",
      "pid": "221",
      "path": ",2,221,2711,",
      "level": "3",
      "name": "索里亚",
      "name_en": "Soria",
      "name_pinyin": "suoliya",
      "code": "SOR",
      "childrens": []
    }, {
      "id": "2712",
      "pid": "221",
      "path": ",2,221,2712,",
      "level": "3",
      "name": "塔拉戈纳",
      "name_en": "Tarragona",
      "name_pinyin": "talagena",
      "code": "TAR",
      "childrens": []
    }, {
      "id": "2713",
      "pid": "221",
      "path": ",2,221,2713,",
      "level": "3",
      "name": "特鲁埃尔",
      "name_en": "Teruel",
      "name_pinyin": "teluaier",
      "code": "TER",
      "childrens": []
    }, {
      "id": "2714",
      "pid": "221",
      "path": ",2,221,2714,",
      "level": "3",
      "name": "托莱多",
      "name_en": "Toledo",
      "name_pinyin": "tuolaiduo",
      "code": "TOL",
      "childrens": []
    }, {
      "id": "2715",
      "pid": "221",
      "path": ",2,221,2715,",
      "level": "3",
      "name": "韦尔瓦",
      "name_en": "Huelva",
      "name_pinyin": "weierwa",
      "code": "HUV",
      "childrens": []
    }, {
      "id": "2716",
      "pid": "221",
      "path": ",2,221,2716,",
      "level": "3",
      "name": "韦斯卡",
      "name_en": "Huesca",
      "name_pinyin": "weisika",
      "code": "HUC",
      "childrens": []
    }]
  }, {
    "id": "222",
    "pid": "2",
    "path": ",2,222,",
    "level": "2",
    "name": "希腊",
    "name_en": "Greece",
    "name_pinyin": "xila",
    "code": "GRC",
    "childrens": [{
      "id": "2717",
      "pid": "222",
      "path": ",2,222,2717,",
      "level": "3",
      "name": "比雷埃夫斯",
      "name_en": "Peiraievs",
      "name_pinyin": "bileiaifusi",
      "code": "PRI",
      "childrens": []
    }, {
      "id": "2718",
      "pid": "222",
      "path": ",2,222,2718,",
      "level": "3",
      "name": "多德卡尼斯",
      "name_en": "Dodecanese",
      "name_pinyin": "duodekanisi",
      "code": "DO",
      "childrens": []
    }, {
      "id": "2719",
      "pid": "222",
      "path": ",2,222,2719,",
      "level": "3",
      "name": "干尼亚",
      "name_en": "Chanion",
      "name_pinyin": "ganniya",
      "code": "CHQ",
      "childrens": []
    }, {
      "id": "2720",
      "pid": "222",
      "path": ",2,222,2720,",
      "level": "3",
      "name": "基克拉迪",
      "name_en": "Cyclades",
      "name_pinyin": "jikeladi",
      "code": "CY",
      "childrens": []
    }, {
      "id": "2721",
      "pid": "222",
      "path": ",2,222,2721,",
      "level": "3",
      "name": "拉西锡",
      "name_en": "Lasithiou",
      "name_pinyin": "laxixi",
      "code": "LST",
      "childrens": []
    }, {
      "id": "2722",
      "pid": "222",
      "path": ",2,222,2722,",
      "level": "3",
      "name": "莱斯博斯",
      "name_en": "Lesbos",
      "name_pinyin": "laisibosi",
      "code": "LES",
      "childrens": []
    }, {
      "id": "2723",
      "pid": "222",
      "path": ",2,222,2723,",
      "level": "3",
      "name": "雷西姆农",
      "name_en": "Rethymnis",
      "name_pinyin": "leiximunong",
      "code": "RET",
      "childrens": []
    }, {
      "id": "2724",
      "pid": "222",
      "path": ",2,222,2724,",
      "level": "3",
      "name": "萨摩斯",
      "name_en": "Samos",
      "name_pinyin": "samosi",
      "code": "SMI",
      "childrens": []
    }, {
      "id": "2725",
      "pid": "222",
      "path": ",2,222,2725,",
      "level": "3",
      "name": "雅典",
      "name_en": "Athens",
      "name_pinyin": "yadian",
      "code": "ATH",
      "childrens": []
    }, {
      "id": "2726",
      "pid": "222",
      "path": ",2,222,2726,",
      "level": "3",
      "name": "伊拉克里翁",
      "name_en": "Irakleiou",
      "name_pinyin": "yilakeliweng",
      "code": "HER",
      "childrens": []
    }]
  }, {
    "id": "226",
    "pid": "2",
    "path": ",2,226,",
    "level": "2",
    "name": "匈牙利",
    "name_en": "Hungary",
    "name_pinyin": "xiongyali",
    "code": "HUN",
    "childrens": [{
      "id": "2751",
      "pid": "226",
      "path": ",2,226,2751,",
      "level": "3",
      "name": "巴兰尼亚",
      "name_en": "Baranya",
      "name_pinyin": "balanniya",
      "code": "BA",
      "childrens": []
    }, {
      "id": "2752",
      "pid": "226",
      "path": ",2,226,2752,",
      "level": "3",
      "name": "巴奇-基什孔",
      "name_en": "Bacs-Kiskun",
      "name_pinyin": "baqijishikong",
      "code": "BK",
      "childrens": []
    }, {
      "id": "2753",
      "pid": "226",
      "path": ",2,226,2753,",
      "level": "3",
      "name": "包尔绍德-奥包乌伊-曾普伦",
      "name_en": "Borsod-Abauj-Zemplen",
      "name_pinyin": "baoershaodeaobaowuyizengpulun",
      "code": "BZ",
      "childrens": []
    }, {
      "id": "2754",
      "pid": "226",
      "path": ",2,226,2754,",
      "level": "3",
      "name": "贝凯什",
      "name_en": "Bekes",
      "name_pinyin": "beikaishi",
      "code": "BE",
      "childrens": []
    }, {
      "id": "2755",
      "pid": "226",
      "path": ",2,226,2755,",
      "level": "3",
      "name": "布达佩斯",
      "name_en": "Budapest",
      "name_pinyin": "budapeisi",
      "code": "BU",
      "childrens": []
    }, {
      "id": "2756",
      "pid": "226",
      "path": ",2,226,2756,",
      "level": "3",
      "name": "费耶尔",
      "name_en": "Fejer",
      "name_pinyin": "feiyeer",
      "code": "FE",
      "childrens": []
    }, {
      "id": "2757",
      "pid": "226",
      "path": ",2,226,2757,",
      "level": "3",
      "name": "豪伊杜-比豪尔",
      "name_en": "Hajdu-Bihar",
      "name_pinyin": "haoyidubihaoer",
      "code": "HB",
      "childrens": []
    }, {
      "id": "2758",
      "pid": "226",
      "path": ",2,226,2758,",
      "level": "3",
      "name": "赫维什",
      "name_en": "Heves",
      "name_pinyin": "heweishi",
      "code": "HE",
      "childrens": []
    }, {
      "id": "2759",
      "pid": "226",
      "path": ",2,226,2759,",
      "level": "3",
      "name": "加兹-纳杰孔-索尔诺克",
      "name_en": "Jasz-Nagykun-Szolnok",
      "name_pinyin": "jiazinajiekongsuoernuoke",
      "code": "JN",
      "childrens": []
    }, {
      "id": "2760",
      "pid": "226",
      "path": ",2,226,2760,",
      "level": "3",
      "name": "杰尔-莫松-肖普朗",
      "name_en": "Gyor-Moson-Sopron",
      "name_pinyin": "jieermosongxiaopulang",
      "code": "GS",
      "childrens": []
    }, {
      "id": "2761",
      "pid": "226",
      "path": ",2,226,2761,",
      "level": "3",
      "name": "科马罗姆",
      "name_en": "Komarom-Esztergom",
      "name_pinyin": "kemaluomu",
      "code": "KE",
      "childrens": []
    }, {
      "id": "2762",
      "pid": "226",
      "path": ",2,226,2762,",
      "level": "3",
      "name": "诺格拉德",
      "name_en": "Nograd",
      "name_pinyin": "nuogelade",
      "code": "NO",
      "childrens": []
    }, {
      "id": "2763",
      "pid": "226",
      "path": ",2,226,2763,",
      "level": "3",
      "name": "佩斯",
      "name_en": "Pest",
      "name_pinyin": "peisi",
      "code": "PE",
      "childrens": []
    }, {
      "id": "2764",
      "pid": "226",
      "path": ",2,226,2764,",
      "level": "3",
      "name": "琼格拉德",
      "name_en": "Csongrad",
      "name_pinyin": "qionggelade",
      "code": "CS",
      "childrens": []
    }, {
      "id": "2765",
      "pid": "226",
      "path": ",2,226,2765,",
      "level": "3",
      "name": "绍莫吉",
      "name_en": "Somogy",
      "name_pinyin": "shaomoji",
      "code": "SO",
      "childrens": []
    }, {
      "id": "2766",
      "pid": "226",
      "path": ",2,226,2766,",
      "level": "3",
      "name": "索博尔奇-索特马尔-贝拉格",
      "name_en": "Szabolcs-Szatmar-Bereg",
      "name_pinyin": "suoboerqisuotemaerbeilage",
      "code": "SZ",
      "childrens": []
    }, {
      "id": "2767",
      "pid": "226",
      "path": ",2,226,2767,",
      "level": "3",
      "name": "托尔瑙",
      "name_en": "Tolna",
      "name_pinyin": "tuoer",
      "code": "TO",
      "childrens": []
    }, {
      "id": "2768",
      "pid": "226",
      "path": ",2,226,2768,",
      "level": "3",
      "name": "维斯普雷姆",
      "name_en": "Veszprem",
      "name_pinyin": "weisipuleimu",
      "code": "VE",
      "childrens": []
    }, {
      "id": "2769",
      "pid": "226",
      "path": ",2,226,2769,",
      "level": "3",
      "name": "沃什",
      "name_en": "Vas",
      "name_pinyin": "woshi",
      "code": "VA",
      "childrens": []
    }, {
      "id": "2770",
      "pid": "226",
      "path": ",2,226,2770,",
      "level": "3",
      "name": "佐洛",
      "name_en": "Zala",
      "name_pinyin": "zuoluo",
      "code": "ZA",
      "childrens": []
    }]
  }, {
    "id": "234",
    "pid": "2",
    "path": ",2,234,",
    "level": "2",
    "name": "意大利",
    "name_en": "Italy",
    "name_pinyin": "yidali",
    "code": "ITA",
    "childrens": [{
      "id": "2841",
      "pid": "234",
      "path": ",2,234,2841,",
      "level": "3",
      "name": "阿斯蒂",
      "name_en": "Asti",
      "name_pinyin": "asidi",
      "code": "AST",
      "childrens": []
    }, {
      "id": "2842",
      "pid": "234",
      "path": ",2,234,2842,",
      "level": "3",
      "name": "阿斯科利皮切诺",
      "name_en": "Ascoli Piceno",
      "name_pinyin": "asikelipiqienuo",
      "code": "ASP",
      "childrens": []
    }, {
      "id": "2843",
      "pid": "234",
      "path": ",2,234,2843,",
      "level": "3",
      "name": "安科纳",
      "name_en": "Ancona",
      "name_pinyin": "ankena",
      "code": "AOI",
      "childrens": []
    }, {
      "id": "2844",
      "pid": "234",
      "path": ",2,234,2844,",
      "level": "3",
      "name": "奥尔比亚",
      "name_en": "Olbia-Tempio",
      "name_pinyin": "aoerbiya",
      "code": "OLB",
      "childrens": []
    }, {
      "id": "2845",
      "pid": "234",
      "path": ",2,234,2845,",
      "level": "3",
      "name": "奥里斯塔诺",
      "name_en": "Oristano",
      "name_pinyin": "aolisitanuo",
      "code": "QOS",
      "childrens": []
    }, {
      "id": "2846",
      "pid": "234",
      "path": ",2,234,2846,",
      "level": "3",
      "name": "奥斯塔",
      "name_en": "Aosta",
      "name_pinyin": "aosita",
      "code": "AOT",
      "childrens": []
    }, {
      "id": "2847",
      "pid": "234",
      "path": ",2,234,2847,",
      "level": "3",
      "name": "巴勒莫",
      "name_en": "Palermo",
      "name_pinyin": "balemo",
      "code": "PMO",
      "childrens": []
    }, {
      "id": "2848",
      "pid": "234",
      "path": ",2,234,2848,",
      "level": "3",
      "name": "巴里",
      "name_en": "Bari",
      "name_pinyin": "bali",
      "code": "BRI",
      "childrens": []
    }, {
      "id": "2849",
      "pid": "234",
      "path": ",2,234,2849,",
      "level": "3",
      "name": "贝加莫",
      "name_en": "Bergamo",
      "name_pinyin": "beijiamo",
      "code": "BGO",
      "childrens": []
    }, {
      "id": "2850",
      "pid": "234",
      "path": ",2,234,2850,",
      "level": "3",
      "name": "贝内文托",
      "name_en": "Benevento",
      "name_pinyin": "beineiwentuo",
      "code": "BEN",
      "childrens": []
    }, {
      "id": "2851",
      "pid": "234",
      "path": ",2,234,2851,",
      "level": "3",
      "name": "比萨",
      "name_en": "Pisa",
      "name_pinyin": "bisa",
      "code": "PSA",
      "childrens": []
    }, {
      "id": "2852",
      "pid": "234",
      "path": ",2,234,2852,",
      "level": "3",
      "name": "波代诺内",
      "name_en": "Pordenone",
      "name_pinyin": "bodainuonei",
      "code": "PRD",
      "childrens": []
    }, {
      "id": "2853",
      "pid": "234",
      "path": ",2,234,2853,",
      "level": "3",
      "name": "波坦察",
      "name_en": "Potenza",
      "name_pinyin": "botancha",
      "code": "QPO",
      "childrens": []
    }, {
      "id": "2854",
      "pid": "234",
      "path": ",2,234,2854,",
      "level": "3",
      "name": "博洛尼亚",
      "name_en": "Bologna",
      "name_pinyin": "boluoniya",
      "code": "BLQ",
      "childrens": []
    }, {
      "id": "2855",
      "pid": "234",
      "path": ",2,234,2855,",
      "level": "3",
      "name": "布拉",
      "name_en": "Biella",
      "name_pinyin": "bula",
      "code": "BIE",
      "childrens": []
    }, {
      "id": "2856",
      "pid": "234",
      "path": ",2,234,2856,",
      "level": "3",
      "name": "布雷西亚",
      "name_en": "Brescia",
      "name_pinyin": "buleixiya",
      "code": "BRC",
      "childrens": []
    }, {
      "id": "2857",
      "pid": "234",
      "path": ",2,234,2857,",
      "level": "3",
      "name": "布林迪西",
      "name_en": "Brindisi",
      "name_pinyin": "bulindixi",
      "code": "BDS",
      "childrens": []
    }, {
      "id": "2858",
      "pid": "234",
      "path": ",2,234,2858,",
      "level": "3",
      "name": "的里雅斯特",
      "name_en": "Trieste",
      "name_pinyin": "deliyasite",
      "code": "TRS",
      "childrens": []
    }, {
      "id": "2859",
      "pid": "234",
      "path": ",2,234,2859,",
      "level": "3",
      "name": "都灵",
      "name_en": "Turin",
      "name_pinyin": "duling",
      "code": "TRN",
      "childrens": []
    }, {
      "id": "2860",
      "pid": "234",
      "path": ",2,234,2860,",
      "level": "3",
      "name": "费拉拉",
      "name_en": "Ferrara",
      "name_pinyin": "feilala",
      "code": "FRR",
      "childrens": []
    }, {
      "id": "2861",
      "pid": "234",
      "path": ",2,234,2861,",
      "level": "3",
      "name": "佛罗伦萨",
      "name_en": "Firenze",
      "name_pinyin": "foluolunsa",
      "code": "FLR",
      "childrens": []
    }, {
      "id": "2862",
      "pid": "234",
      "path": ",2,234,2862,",
      "level": "3",
      "name": "福贾",
      "name_en": "Foggia",
      "name_pinyin": "fujia",
      "code": "FOG",
      "childrens": []
    }, {
      "id": "2863",
      "pid": "234",
      "path": ",2,234,2863,",
      "level": "3",
      "name": "卡利亚里",
      "name_en": "Cagliari",
      "name_pinyin": "kaliyali",
      "code": "CAG",
      "childrens": []
    }, {
      "id": "2864",
      "pid": "234",
      "path": ",2,234,2864,",
      "level": "3",
      "name": "卡塞塔",
      "name_en": "Caserta",
      "name_pinyin": "kasaita",
      "code": "CST",
      "childrens": []
    }, {
      "id": "2865",
      "pid": "234",
      "path": ",2,234,2865,",
      "level": "3",
      "name": "卡塔尼亚",
      "name_en": "Catania",
      "name_pinyin": "kataniya",
      "code": "CTA",
      "childrens": []
    }, {
      "id": "2866",
      "pid": "234",
      "path": ",2,234,2866,",
      "level": "3",
      "name": "卡坦扎罗",
      "name_en": "Catanzaro",
      "name_pinyin": "katanzhaluo",
      "code": "QCZ",
      "childrens": []
    }, {
      "id": "2867",
      "pid": "234",
      "path": ",2,234,2867,",
      "level": "3",
      "name": "坎波巴索",
      "name_en": "Campobasso",
      "name_pinyin": "kanbobasuo",
      "code": "COB",
      "childrens": []
    }, {
      "id": "2868",
      "pid": "234",
      "path": ",2,234,2868,",
      "level": "3",
      "name": "科摩",
      "name_en": "Como",
      "name_pinyin": "kemo",
      "code": "CIY",
      "childrens": []
    }, {
      "id": "2869",
      "pid": "234",
      "path": ",2,234,2869,",
      "level": "3",
      "name": "科森扎",
      "name_en": "Cosenza",
      "name_pinyin": "kesenzha",
      "code": "QCS",
      "childrens": []
    }, {
      "id": "2870",
      "pid": "234",
      "path": ",2,234,2870,",
      "level": "3",
      "name": "克罗托内",
      "name_en": "Crotone",
      "name_pinyin": "keluotuonei",
      "code": "CRV",
      "childrens": []
    }, {
      "id": "2871",
      "pid": "234",
      "path": ",2,234,2871,",
      "level": "3",
      "name": "库内奥",
      "name_en": "Cuneo",
      "name_pinyin": "kuneiao",
      "code": "CUN",
      "childrens": []
    }, {
      "id": "2872",
      "pid": "234",
      "path": ",2,234,2872,",
      "level": "3",
      "name": "拉奎拉",
      "name_en": "L'Aquila",
      "name_pinyin": "lakuila",
      "code": "LAQ",
      "childrens": []
    }, {
      "id": "2873",
      "pid": "234",
      "path": ",2,234,2873,",
      "level": "3",
      "name": "拉斯佩齐亚",
      "name_en": "La Spezia",
      "name_pinyin": "lasipeiqiya",
      "code": "SPE",
      "childrens": []
    }, {
      "id": "2874",
      "pid": "234",
      "path": ",2,234,2874,",
      "level": "3",
      "name": "莱科",
      "name_en": "Lecco",
      "name_pinyin": "laike",
      "code": "LCO",
      "childrens": []
    }, {
      "id": "2875",
      "pid": "234",
      "path": ",2,234,2875,",
      "level": "3",
      "name": "莱切",
      "name_en": "Lecce",
      "name_pinyin": "laiqie",
      "code": "LCC",
      "childrens": []
    }, {
      "id": "2876",
      "pid": "234",
      "path": ",2,234,2876,",
      "level": "3",
      "name": "雷焦艾米利亚",
      "name_en": "Reggio Emilia",
      "name_pinyin": "leijiaoaimiliya",
      "code": "RNE",
      "childrens": []
    }, {
      "id": "2877",
      "pid": "234",
      "path": ",2,234,2877,",
      "level": "3",
      "name": "雷焦卡拉布里亚",
      "name_en": "Reggio Calabria",
      "name_pinyin": "leijiaokalabuliya",
      "code": "REG",
      "childrens": []
    }, {
      "id": "2878",
      "pid": "234",
      "path": ",2,234,2878,",
      "level": "3",
      "name": "里窝那",
      "name_en": "Livorno",
      "name_pinyin": "liwona",
      "code": "LIV",
      "childrens": []
    }, {
      "id": "2879",
      "pid": "234",
      "path": ",2,234,2879,",
      "level": "3",
      "name": "罗马",
      "name_en": "Roma",
      "name_pinyin": "luoma",
      "code": "ROM",
      "childrens": []
    }, {
      "id": "2880",
      "pid": "234",
      "path": ",2,234,2880,",
      "level": "3",
      "name": "马萨",
      "name_en": "Massa-Carrara",
      "name_pinyin": "masa",
      "code": "MCR",
      "childrens": []
    }, {
      "id": "2881",
      "pid": "234",
      "path": ",2,234,2881,",
      "level": "3",
      "name": "马泰拉",
      "name_en": "Matera",
      "name_pinyin": "mataila",
      "code": "MTR",
      "childrens": []
    }, {
      "id": "2882",
      "pid": "234",
      "path": ",2,234,2882,",
      "level": "3",
      "name": "蒙扎",
      "name_en": "Monza e Brianza",
      "name_pinyin": "mengzha",
      "code": "MZA",
      "childrens": []
    }, {
      "id": "2883",
      "pid": "234",
      "path": ",2,234,2883,",
      "level": "3",
      "name": "米兰",
      "name_en": "Milano",
      "name_pinyin": "milan",
      "code": "MIL",
      "childrens": []
    }, {
      "id": "2884",
      "pid": "234",
      "path": ",2,234,2884,",
      "level": "3",
      "name": "摩德纳",
      "name_en": "Modena",
      "name_pinyin": "modena",
      "code": "MOD",
      "childrens": []
    }, {
      "id": "2885",
      "pid": "234",
      "path": ",2,234,2885,",
      "level": "3",
      "name": "墨西拿",
      "name_en": "Messina",
      "name_pinyin": "moxina",
      "code": "MSN",
      "childrens": []
    }, {
      "id": "2886",
      "pid": "234",
      "path": ",2,234,2886,",
      "level": "3",
      "name": "那不勒斯",
      "name_en": "Naples",
      "name_pinyin": "nabulesi",
      "code": "NAP",
      "childrens": []
    }, {
      "id": "2887",
      "pid": "234",
      "path": ",2,234,2887,",
      "level": "3",
      "name": "努奥罗",
      "name_en": "Nuoro",
      "name_pinyin": "nuaoluo",
      "code": "QNU",
      "childrens": []
    }, {
      "id": "2888",
      "pid": "234",
      "path": ",2,234,2888,",
      "level": "3",
      "name": "诺瓦拉",
      "name_en": "Novara",
      "name_pinyin": "nuowala",
      "code": "NVR",
      "childrens": []
    }, {
      "id": "2889",
      "pid": "234",
      "path": ",2,234,2889,",
      "level": "3",
      "name": "帕尔马",
      "name_en": "Parma",
      "name_pinyin": "paerma",
      "code": "PMF",
      "childrens": []
    }, {
      "id": "2890",
      "pid": "234",
      "path": ",2,234,2890,",
      "level": "3",
      "name": "帕维亚",
      "name_en": "Pavia",
      "name_pinyin": "paweiya",
      "code": "PAV",
      "childrens": []
    }, {
      "id": "2891",
      "pid": "234",
      "path": ",2,234,2891,",
      "level": "3",
      "name": "佩鲁贾",
      "name_en": "Perugia",
      "name_pinyin": "peilujia",
      "code": "PEG",
      "childrens": []
    }, {
      "id": "2892",
      "pid": "234",
      "path": ",2,234,2892,",
      "level": "3",
      "name": "热那亚",
      "name_en": "Genova",
      "name_pinyin": "renaya",
      "code": "CAX",
      "childrens": []
    }, {
      "id": "2893",
      "pid": "234",
      "path": ",2,234,2893,",
      "level": "3",
      "name": "萨莱诺",
      "name_en": "Salerno",
      "name_pinyin": "salainuo",
      "code": "SAL",
      "childrens": []
    }, {
      "id": "2894",
      "pid": "234",
      "path": ",2,234,2894,",
      "level": "3",
      "name": "萨萨里",
      "name_en": "Sassari",
      "name_pinyin": "sasali",
      "code": "QSS",
      "childrens": []
    }, {
      "id": "2895",
      "pid": "234",
      "path": ",2,234,2895,",
      "level": "3",
      "name": "萨沃纳",
      "name_en": "Savona",
      "name_pinyin": "sawona",
      "code": "SVN",
      "childrens": []
    }, {
      "id": "2896",
      "pid": "234",
      "path": ",2,234,2896,",
      "level": "3",
      "name": "塔兰托",
      "name_en": "Taranto",
      "name_pinyin": "talantuo",
      "code": "TAR",
      "childrens": []
    }, {
      "id": "2897",
      "pid": "234",
      "path": ",2,234,2897,",
      "level": "3",
      "name": "特拉帕尼",
      "name_en": "Trapani",
      "name_pinyin": "telapani",
      "code": "TPS",
      "childrens": []
    }, {
      "id": "2898",
      "pid": "234",
      "path": ",2,234,2898,",
      "level": "3",
      "name": "特伦托",
      "name_en": "Trento",
      "name_pinyin": "teluntuo",
      "code": "TRT",
      "childrens": []
    }, {
      "id": "2899",
      "pid": "234",
      "path": ",2,234,2899,",
      "level": "3",
      "name": "威尼斯",
      "name_en": "Venice",
      "name_pinyin": "weinisi",
      "code": "VCE",
      "childrens": []
    }, {
      "id": "2900",
      "pid": "234",
      "path": ",2,234,2900,",
      "level": "3",
      "name": "韦尔切利",
      "name_en": "Vercelli",
      "name_pinyin": "weierqieli",
      "code": "VRL",
      "childrens": []
    }, {
      "id": "2901",
      "pid": "234",
      "path": ",2,234,2901,",
      "level": "3",
      "name": "维泰博",
      "name_en": "Viterbo",
      "name_pinyin": "weitaibo",
      "code": "VIT",
      "childrens": []
    }, {
      "id": "2902",
      "pid": "234",
      "path": ",2,234,2902,",
      "level": "3",
      "name": "乌迪内",
      "name_en": "Udine",
      "name_pinyin": "wudinei",
      "code": "UDN",
      "childrens": []
    }, {
      "id": "2903",
      "pid": "234",
      "path": ",2,234,2903,",
      "level": "3",
      "name": "锡拉库扎",
      "name_en": "Syracuse",
      "name_pinyin": "xilakuzha",
      "code": "SYR",
      "childrens": []
    }, {
      "id": "2904",
      "pid": "234",
      "path": ",2,234,2904,",
      "level": "3",
      "name": "锡耶纳",
      "name_en": "Siena",
      "name_pinyin": "xiyena",
      "code": "SNA",
      "childrens": []
    }, {
      "id": "2905",
      "pid": "234",
      "path": ",2,234,2905,",
      "level": "3",
      "name": "亚历山德里亚",
      "name_en": "Alessandria",
      "name_pinyin": "yalishandeliya",
      "code": "ALE",
      "childrens": []
    }, {
      "id": "2906",
      "pid": "234",
      "path": ",2,234,2906,",
      "level": "3",
      "name": "伊塞尔尼亚",
      "name_en": "Isernia",
      "name_pinyin": "yisaierniya",
      "code": "ISE",
      "childrens": []
    }]
  }, {
    "id": "237",
    "pid": "2",
    "path": ",2,237,",
    "level": "2",
    "name": "英国",
    "name_en": "United Kingdom",
    "name_pinyin": "yingguo",
    "code": "GBR",
    "childrens": [{
      "id": "371",
      "pid": "237",
      "path": ",2,237,371,",
      "level": "3",
      "name": "北爱尔兰",
      "name_en": "Northern Ireland",
      "name_pinyin": "beiaierlan",
      "code": "NIR",
      "childrens": [{
        "id": "4107",
        "pid": "371",
        "path": ",2,237,371,4107,",
        "level": "4",
        "name": "贝尔法斯特",
        "name_en": "Belfast",
        "name_pinyin": "beierfasite",
        "code": "BFS",
        "childrens": []
      }, {
        "id": "4108",
        "pid": "371",
        "path": ",2,237,371,4108,",
        "level": "4",
        "name": "德里",
        "name_en": "Derry",
        "name_pinyin": "deli",
        "code": "DRY",
        "childrens": []
      }, {
        "id": "4109",
        "pid": "371",
        "path": ",2,237,371,4109,",
        "level": "4",
        "name": "利斯本",
        "name_en": "Lisburn",
        "name_pinyin": "lisiben",
        "code": "LSB",
        "childrens": []
      }, {
        "id": "4110",
        "pid": "371",
        "path": ",2,237,371,4110,",
        "level": "4",
        "name": "纽里",
        "name_en": "Newry",
        "name_pinyin": "niuli",
        "code": "NYM",
        "childrens": []
      }]
    }, {
      "id": "372",
      "pid": "237",
      "path": ",2,237,372,",
      "level": "3",
      "name": "苏格兰",
      "name_en": "Scotland",
      "name_pinyin": "sugelan",
      "code": "SCT",
      "childrens": [{
        "id": "4111",
        "pid": "372",
        "path": ",2,237,372,4111,",
        "level": "4",
        "name": "阿伯丁",
        "name_en": "Aberdeen",
        "name_pinyin": "aboding",
        "code": "ABD",
        "childrens": []
      }, {
        "id": "4112",
        "pid": "372",
        "path": ",2,237,372,4112,",
        "level": "4",
        "name": "爱丁堡",
        "name_en": "Edinburgh",
        "name_pinyin": "aidingbao",
        "code": "EDH",
        "childrens": []
      }, {
        "id": "4113",
        "pid": "372",
        "path": ",2,237,372,4113,",
        "level": "4",
        "name": "丹迪",
        "name_en": "Dundee",
        "name_pinyin": "dandi",
        "code": "DND",
        "childrens": []
      }, {
        "id": "4114",
        "pid": "372",
        "path": ",2,237,372,4114,",
        "level": "4",
        "name": "格拉斯哥",
        "name_en": "Glasgow",
        "name_pinyin": "gelasige",
        "code": "GLG",
        "childrens": []
      }, {
        "id": "4115",
        "pid": "372",
        "path": ",2,237,372,4115,",
        "level": "4",
        "name": "斯特灵",
        "name_en": "Stirling",
        "name_pinyin": "siteling",
        "code": "STG",
        "childrens": []
      }, {
        "id": "4116",
        "pid": "372",
        "path": ",2,237,372,4116,",
        "level": "4",
        "name": "因弗内斯",
        "name_en": "Inverness",
        "name_pinyin": "yinfuneisi",
        "code": "INV",
        "childrens": []
      }]
    }, {
      "id": "373",
      "pid": "237",
      "path": ",2,237,373,",
      "level": "3",
      "name": "威尔士",
      "name_en": "Wales",
      "name_pinyin": "weiershi",
      "code": "WLS",
      "childrens": [{
        "id": "4117",
        "pid": "373",
        "path": ",2,237,373,4117,",
        "level": "4",
        "name": "班戈",
        "name_en": "Bangor",
        "name_pinyin": "bange",
        "code": "BAN",
        "childrens": []
      }, {
        "id": "4118",
        "pid": "373",
        "path": ",2,237,373,4118,",
        "level": "4",
        "name": "卡迪夫",
        "name_en": "Cardiff",
        "name_pinyin": "kadifu",
        "code": "CDF",
        "childrens": []
      }, {
        "id": "4119",
        "pid": "373",
        "path": ",2,237,373,4119,",
        "level": "4",
        "name": "纽波特",
        "name_en": "Newport",
        "name_pinyin": "niubote",
        "code": "NWP",
        "childrens": []
      }, {
        "id": "4120",
        "pid": "373",
        "path": ",2,237,373,4120,",
        "level": "4",
        "name": "斯旺西",
        "name_en": "Swansea",
        "name_pinyin": "siwangxi",
        "code": "SWA",
        "childrens": []
      }]
    }, {
      "id": "374",
      "pid": "237",
      "path": ",2,237,374,",
      "level": "3",
      "name": "英格兰",
      "name_en": "England",
      "name_pinyin": "yinggelan",
      "code": "ENG",
      "childrens": [{
        "id": "4121",
        "pid": "374",
        "path": ",2,237,374,4121,",
        "level": "4",
        "name": "埃克塞特",
        "name_en": "Exeter",
        "name_pinyin": "aikesaite",
        "code": "EXE",
        "childrens": []
      }, {
        "id": "4122",
        "pid": "374",
        "path": ",2,237,374,4122,",
        "level": "4",
        "name": "巴斯",
        "name_en": "Bath",
        "name_pinyin": "basi",
        "code": "BAS",
        "childrens": []
      }, {
        "id": "4123",
        "pid": "374",
        "path": ",2,237,374,4123,",
        "level": "4",
        "name": "彼得伯勒",
        "name_en": "Peterborough",
        "name_pinyin": "bidebole",
        "code": "PTE",
        "childrens": []
      }, {
        "id": "4124",
        "pid": "374",
        "path": ",2,237,374,4124,",
        "level": "4",
        "name": "伯明翰",
        "name_en": "Birmingham",
        "name_pinyin": "bominghan",
        "code": "BIR",
        "childrens": []
      }, {
        "id": "4125",
        "pid": "374",
        "path": ",2,237,374,4125,",
        "level": "4",
        "name": "布拉德福德",
        "name_en": "Bradford",
        "name_pinyin": "buladefude",
        "code": "BRD",
        "childrens": []
      }, {
        "id": "4126",
        "pid": "374",
        "path": ",2,237,374,4126,",
        "level": "4",
        "name": "布莱顿与赫福",
        "name_en": "Brighton & Hove",
        "name_pinyin": "bulaidunyuhefu",
        "code": "BNH",
        "childrens": []
      }, {
        "id": "4127",
        "pid": "374",
        "path": ",2,237,374,4127,",
        "level": "4",
        "name": "布里斯托尔",
        "name_en": "Bristol",
        "name_pinyin": "bulisituoer",
        "code": "BST",
        "childrens": []
      }, {
        "id": "4128",
        "pid": "374",
        "path": ",2,237,374,4128,",
        "level": "4",
        "name": "德比",
        "name_en": "Derby",
        "name_pinyin": "debi",
        "code": "DER",
        "childrens": []
      }, {
        "id": "4129",
        "pid": "374",
        "path": ",2,237,374,4129,",
        "level": "4",
        "name": "德罕",
        "name_en": "Durham",
        "name_pinyin": "dehan",
        "code": "DUR",
        "childrens": []
      }, {
        "id": "4130",
        "pid": "374",
        "path": ",2,237,374,4130,",
        "level": "4",
        "name": "格洛斯特",
        "name_en": "Gloucester",
        "name_pinyin": "geluosite",
        "code": "GLO",
        "childrens": []
      }, {
        "id": "4131",
        "pid": "374",
        "path": ",2,237,374,4131,",
        "level": "4",
        "name": "赫尔河畔京斯敦",
        "name_en": "Kingston upon Hull",
        "name_pinyin": "heerhepanjingsidun",
        "code": "KUH",
        "childrens": []
      }, {
        "id": "4132",
        "pid": "374",
        "path": ",2,237,374,4132,",
        "level": "4",
        "name": "赫里福德",
        "name_en": "Hereford",
        "name_pinyin": "helifude",
        "code": "HAF",
        "childrens": []
      }, {
        "id": "4133",
        "pid": "374",
        "path": ",2,237,374,4133,",
        "level": "4",
        "name": "剑桥",
        "name_en": "Cambridge",
        "name_pinyin": "jianqiao",
        "code": "CAM",
        "childrens": []
      }, {
        "id": "4134",
        "pid": "374",
        "path": ",2,237,374,4134,",
        "level": "4",
        "name": "卡莱尔",
        "name_en": "Carlisle",
        "name_pinyin": "kalaier",
        "code": "CAX",
        "childrens": []
      }, {
        "id": "4135",
        "pid": "374",
        "path": ",2,237,374,4135,",
        "level": "4",
        "name": "坎特伯雷",
        "name_en": "Canterbury",
        "name_pinyin": "kantebolei",
        "code": "CNG",
        "childrens": []
      }, {
        "id": "4136",
        "pid": "374",
        "path": ",2,237,374,4136,",
        "level": "4",
        "name": "考文垂",
        "name_en": "Coventry",
        "name_pinyin": "kaowenchui",
        "code": "COV",
        "childrens": []
      }, {
        "id": "4137",
        "pid": "374",
        "path": ",2,237,374,4137,",
        "level": "4",
        "name": "兰开斯特",
        "name_en": "Lancaster",
        "name_pinyin": "lankaisite",
        "code": "LAN",
        "childrens": []
      }, {
        "id": "4138",
        "pid": "374",
        "path": ",2,237,374,4138,",
        "level": "4",
        "name": "里彭",
        "name_en": "Ripon",
        "name_pinyin": "lipeng",
        "code": "RIP",
        "childrens": []
      }, {
        "id": "4139",
        "pid": "374",
        "path": ",2,237,374,4139,",
        "level": "4",
        "name": "利奇菲尔德",
        "name_en": "Lichfield",
        "name_pinyin": "liqifeierde",
        "code": "LHF",
        "childrens": []
      }, {
        "id": "4140",
        "pid": "374",
        "path": ",2,237,374,4140,",
        "level": "4",
        "name": "利物浦",
        "name_en": "Liverpool",
        "name_pinyin": "liwupu",
        "code": "LIV",
        "childrens": []
      }, {
        "id": "4141",
        "pid": "374",
        "path": ",2,237,374,4141,",
        "level": "4",
        "name": "利茲",
        "name_en": "Leeds",
        "name_pinyin": "li",
        "code": "LDS",
        "childrens": []
      }, {
        "id": "4142",
        "pid": "374",
        "path": ",2,237,374,4142,",
        "level": "4",
        "name": "列斯特",
        "name_en": "Leicester",
        "name_pinyin": "liesite",
        "code": "LCE",
        "childrens": []
      }, {
        "id": "4143",
        "pid": "374",
        "path": ",2,237,374,4143,",
        "level": "4",
        "name": "林肯",
        "name_en": "Lincoln",
        "name_pinyin": "linken",
        "code": "LCN",
        "childrens": []
      }, {
        "id": "4144",
        "pid": "374",
        "path": ",2,237,374,4144,",
        "level": "4",
        "name": "伦敦",
        "name_en": "London",
        "name_pinyin": "lundun",
        "code": "LND",
        "childrens": []
      }, {
        "id": "4145",
        "pid": "374",
        "path": ",2,237,374,4145,",
        "level": "4",
        "name": "曼彻斯特",
        "name_en": "Manchester",
        "name_pinyin": "manchesite",
        "code": "MAN",
        "childrens": []
      }, {
        "id": "4146",
        "pid": "374",
        "path": ",2,237,374,4146,",
        "level": "4",
        "name": "南安普敦",
        "name_en": "Southampton",
        "name_pinyin": "nananpudun",
        "code": "STH",
        "childrens": []
      }, {
        "id": "4147",
        "pid": "374",
        "path": ",2,237,374,4147,",
        "level": "4",
        "name": "牛津",
        "name_en": "Oxford",
        "name_pinyin": "niujin",
        "code": "OXF",
        "childrens": []
      }, {
        "id": "4148",
        "pid": "374",
        "path": ",2,237,374,4148,",
        "level": "4",
        "name": "纽卡斯尔",
        "name_en": "Newcastle",
        "name_pinyin": "niukasier",
        "code": "NCL",
        "childrens": []
      }, {
        "id": "4149",
        "pid": "374",
        "path": ",2,237,374,4149,",
        "level": "4",
        "name": "诺丁汉",
        "name_en": "Nottingham",
        "name_pinyin": "nuodinghan",
        "code": "NGM",
        "childrens": []
      }, {
        "id": "4150",
        "pid": "374",
        "path": ",2,237,374,4150,",
        "level": "4",
        "name": "诺里奇",
        "name_en": "Norwich",
        "name_pinyin": "nuoliqi",
        "code": "NRW",
        "childrens": []
      }, {
        "id": "4151",
        "pid": "374",
        "path": ",2,237,374,4151,",
        "level": "4",
        "name": "朴茨茅斯",
        "name_en": "Portsmouth",
        "name_pinyin": "pucimaosi",
        "code": "POR",
        "childrens": []
      }, {
        "id": "4152",
        "pid": "374",
        "path": ",2,237,374,4152,",
        "level": "4",
        "name": "普雷斯顿",
        "name_en": "Preston",
        "name_pinyin": "puleisidun",
        "code": "PRE",
        "childrens": []
      }, {
        "id": "4153",
        "pid": "374",
        "path": ",2,237,374,4153,",
        "level": "4",
        "name": "普利茅斯",
        "name_en": "Plymouth",
        "name_pinyin": "pulimaosi",
        "code": "PLY",
        "childrens": []
      }, {
        "id": "4154",
        "pid": "374",
        "path": ",2,237,374,4154,",
        "level": "4",
        "name": "奇切斯特",
        "name_en": "Chichester",
        "name_pinyin": "qiqiesite",
        "code": "CST",
        "childrens": []
      }, {
        "id": "4155",
        "pid": "374",
        "path": ",2,237,374,4155,",
        "level": "4",
        "name": "切斯特",
        "name_en": "Chester",
        "name_pinyin": "qiesite",
        "code": "CEG",
        "childrens": []
      }, {
        "id": "4156",
        "pid": "374",
        "path": ",2,237,374,4156,",
        "level": "4",
        "name": "桑德兰",
        "name_en": "Sunderland",
        "name_pinyin": "sangdelan",
        "code": "SUN",
        "childrens": []
      }, {
        "id": "4157",
        "pid": "374",
        "path": ",2,237,374,4157,",
        "level": "4",
        "name": "圣阿本斯",
        "name_en": "Saint Albans",
        "name_pinyin": "shengabensi",
        "code": "TBL",
        "childrens": []
      }, {
        "id": "4158",
        "pid": "374",
        "path": ",2,237,374,4158,",
        "level": "4",
        "name": "索尔斯堡",
        "name_en": "Salisbury",
        "name_pinyin": "suoersibao",
        "code": "SLS",
        "childrens": []
      }, {
        "id": "4159",
        "pid": "374",
        "path": ",2,237,374,4159,",
        "level": "4",
        "name": "索福特",
        "name_en": "Salford",
        "name_pinyin": "suofute",
        "code": "SLF",
        "childrens": []
      }, {
        "id": "4160",
        "pid": "374",
        "path": ",2,237,374,4160,",
        "level": "4",
        "name": "特鲁罗",
        "name_en": "Truro",
        "name_pinyin": "teluluo",
        "code": "TRU",
        "childrens": []
      }, {
        "id": "4161",
        "pid": "374",
        "path": ",2,237,374,4161,",
        "level": "4",
        "name": "特伦特河畔斯多克",
        "name_en": "Stoke-on-Trent",
        "name_pinyin": "teluntehepansiduoke",
        "code": "SOT",
        "childrens": []
      }, {
        "id": "4162",
        "pid": "374",
        "path": ",2,237,374,4162,",
        "level": "4",
        "name": "威尔斯",
        "name_en": "Wells",
        "name_pinyin": "weiersi",
        "code": "WLS",
        "childrens": []
      }, {
        "id": "4163",
        "pid": "374",
        "path": ",2,237,374,4163,",
        "level": "4",
        "name": "韦克菲尔德",
        "name_en": "Wakefield",
        "name_pinyin": "weikefeierde",
        "code": "WKF",
        "childrens": []
      }, {
        "id": "4164",
        "pid": "374",
        "path": ",2,237,374,4164,",
        "level": "4",
        "name": "温彻斯特",
        "name_en": "Winchester",
        "name_pinyin": "wenchesite",
        "code": "WNE",
        "childrens": []
      }, {
        "id": "4165",
        "pid": "374",
        "path": ",2,237,374,4165,",
        "level": "4",
        "name": "伍尔弗汉普顿",
        "name_en": "Wolverhampton",
        "name_pinyin": "wuerfuhanpudun",
        "code": "WOV",
        "childrens": []
      }, {
        "id": "4166",
        "pid": "374",
        "path": ",2,237,374,4166,",
        "level": "4",
        "name": "伍斯特",
        "name_en": "Worcester",
        "name_pinyin": "wusite",
        "code": "WOR",
        "childrens": []
      }, {
        "id": "4167",
        "pid": "374",
        "path": ",2,237,374,4167,",
        "level": "4",
        "name": "谢菲尔德",
        "name_en": "Sheffield",
        "name_pinyin": "xiefeierde",
        "code": "SHE",
        "childrens": []
      }, {
        "id": "4168",
        "pid": "374",
        "path": ",2,237,374,4168,",
        "level": "4",
        "name": "伊利",
        "name_en": "Ely",
        "name_pinyin": "yili",
        "code": "ELY",
        "childrens": []
      }, {
        "id": "4169",
        "pid": "374",
        "path": ",2,237,374,4169,",
        "level": "4",
        "name": "约克",
        "name_en": "York",
        "name_pinyin": "yueke",
        "code": "YOR",
        "childrens": []
      }, {
        "id": "4170",
        "pid": "374",
        "path": ",2,237,374,4170,",
        "level": "4",
        "name": "哈哈哈",
        "name_en": "yeyeye",
        "name_pinyin": "heheh",
        "code": "YEY",
        "childrens": []
      }]
    }]
  }, {
    "id": "242",
    "pid": "2",
    "path": ",2,242,",
    "level": "2",
    "name": "泽西岛",
    "name_en": "Jersey",
    "name_pinyin": "zexidao",
    "code": "JEY",
    "childrens": []
  }]
}, {
  "id": "3",
  "pid": "0",
  "path": ",3,",
  "level": "1",
  "name": "非洲",
  "name_en": "Africa",
  "name_pinyin": "feizhou",
  "code": null,
  "childrens": [{
    "id": "9",
    "pid": "3",
    "path": ",3,9,",
    "level": "2",
    "name": "阿尔及利亚",
    "name_en": "Algeria",
    "name_pinyin": "aerjiliya",
    "code": "DZA",
    "childrens": [{
      "id": "387",
      "pid": "9",
      "path": ",3,9,387,",
      "level": "3",
      "name": "阿德拉尔",
      "name_en": "Adrar",
      "name_pinyin": "adelaer",
      "code": "ADR",
      "childrens": []
    }, {
      "id": "388",
      "pid": "9",
      "path": ",3,9,388,",
      "level": "3",
      "name": "阿尔及尔",
      "name_en": "Alger",
      "name_pinyin": "aerjier",
      "code": "ALG",
      "childrens": []
    }, {
      "id": "389",
      "pid": "9",
      "path": ",3,9,389,",
      "level": "3",
      "name": "艾因·德夫拉",
      "name_en": "Ain Defla",
      "name_pinyin": "aiyin",
      "code": "ADE",
      "childrens": []
    }, {
      "id": "390",
      "pid": "9",
      "path": ",3,9,390,",
      "level": "3",
      "name": "艾因·蒂姆尚特",
      "name_en": "Ain Temouchent",
      "name_pinyin": "aiyin",
      "code": "ATE",
      "childrens": []
    }, {
      "id": "391",
      "pid": "9",
      "path": ",3,9,391,",
      "level": "3",
      "name": "安纳巴",
      "name_en": "Annaba",
      "name_pinyin": "annaba",
      "code": "AAE",
      "childrens": []
    }, {
      "id": "392",
      "pid": "9",
      "path": ",3,9,392,",
      "level": "3",
      "name": "奥兰",
      "name_en": "Oran",
      "name_pinyin": "aolan",
      "code": "ORA",
      "childrens": []
    }, {
      "id": "393",
      "pid": "9",
      "path": ",3,9,393,",
      "level": "3",
      "name": "巴特纳",
      "name_en": "Batna",
      "name_pinyin": "batena",
      "code": "BAT",
      "childrens": []
    }, {
      "id": "394",
      "pid": "9",
      "path": ",3,9,394,",
      "level": "3",
      "name": "贝贾亚",
      "name_en": "Bejaia",
      "name_pinyin": "beijiaya",
      "code": "BJA",
      "childrens": []
    }, {
      "id": "395",
      "pid": "9",
      "path": ",3,9,395,",
      "level": "3",
      "name": "贝沙尔",
      "name_en": "Bechar",
      "name_pinyin": "beishaer",
      "code": "BEC",
      "childrens": []
    }, {
      "id": "396",
      "pid": "9",
      "path": ",3,9,396,",
      "level": "3",
      "name": "贝伊德",
      "name_en": "El Bayadh",
      "name_pinyin": "beiyide",
      "code": "EBA",
      "childrens": []
    }, {
      "id": "397",
      "pid": "9",
      "path": ",3,9,397,",
      "level": "3",
      "name": "比斯克拉",
      "name_en": "Biskra",
      "name_pinyin": "bisikela",
      "code": "BIS",
      "childrens": []
    }, {
      "id": "398",
      "pid": "9",
      "path": ",3,9,398,",
      "level": "3",
      "name": "布尔吉·布阿雷里吉",
      "name_en": "Bordj Bou Arreridj",
      "name_pinyin": "buerji",
      "code": "BOR",
      "childrens": []
    }, {
      "id": "399",
      "pid": "9",
      "path": ",3,9,399,",
      "level": "3",
      "name": "布利达",
      "name_en": "Blida",
      "name_pinyin": "bulida",
      "code": "BLI",
      "childrens": []
    }, {
      "id": "400",
      "pid": "9",
      "path": ",3,9,400,",
      "level": "3",
      "name": "布迈德斯",
      "name_en": "Boumerdes",
      "name_pinyin": "bumaidesi",
      "code": "BOU",
      "childrens": []
    }, {
      "id": "401",
      "pid": "9",
      "path": ",3,9,401,",
      "level": "3",
      "name": "布依拉",
      "name_en": "Bouira",
      "name_pinyin": "buyila",
      "code": "BOA",
      "childrens": []
    }, {
      "id": "402",
      "pid": "9",
      "path": ",3,9,402,",
      "level": "3",
      "name": "蒂巴扎",
      "name_en": "Tipaza",
      "name_pinyin": "dibazha",
      "code": "TIP",
      "childrens": []
    }, {
      "id": "403",
      "pid": "9",
      "path": ",3,9,403,",
      "level": "3",
      "name": "蒂斯姆西勒特",
      "name_en": "Tissemsilt",
      "name_pinyin": "disimuxilete",
      "code": "TIS",
      "childrens": []
    }, {
      "id": "404",
      "pid": "9",
      "path": ",3,9,404,",
      "level": "3",
      "name": "盖尔达耶",
      "name_en": "Ghardaia",
      "name_pinyin": "gaierdaye",
      "code": "GHA",
      "childrens": []
    }, {
      "id": "405",
      "pid": "9",
      "path": ",3,9,405,",
      "level": "3",
      "name": "盖尔马",
      "name_en": "Guelma",
      "name_pinyin": "gaierma",
      "code": "GUE",
      "childrens": []
    }, {
      "id": "406",
      "pid": "9",
      "path": ",3,9,406,",
      "level": "3",
      "name": "罕西拉",
      "name_en": "Khenchela",
      "name_pinyin": "hanxila",
      "code": "KHE",
      "childrens": []
    }, {
      "id": "407",
      "pid": "9",
      "path": ",3,9,407,",
      "level": "3",
      "name": "赫利赞",
      "name_en": "Relizane",
      "name_pinyin": "helizan",
      "code": "REL",
      "childrens": []
    }, {
      "id": "408",
      "pid": "9",
      "path": ",3,9,408,",
      "level": "3",
      "name": "吉杰尔",
      "name_en": "Jijel",
      "name_pinyin": "jijieer",
      "code": "JIJ",
      "childrens": []
    }, {
      "id": "409",
      "pid": "9",
      "path": ",3,9,409,",
      "level": "3",
      "name": "杰勒法",
      "name_en": "Djelfa",
      "name_pinyin": "jielefa",
      "code": "DJE",
      "childrens": []
    }, {
      "id": "410",
      "pid": "9",
      "path": ",3,9,410,",
      "level": "3",
      "name": "君士坦丁",
      "name_en": "Constantine",
      "name_pinyin": "junshitanding",
      "code": "CZL",
      "childrens": []
    }, {
      "id": "411",
      "pid": "9",
      "path": ",3,9,411,",
      "level": "3",
      "name": "拉格瓦特",
      "name_en": "Laghouat",
      "name_pinyin": "lagewate",
      "code": "LAG",
      "childrens": []
    }, {
      "id": "412",
      "pid": "9",
      "path": ",3,9,412,",
      "level": "3",
      "name": "马斯卡拉",
      "name_en": "Mascara",
      "name_pinyin": "masikala",
      "code": "MUA",
      "childrens": []
    }, {
      "id": "413",
      "pid": "9",
      "path": ",3,9,413,",
      "level": "3",
      "name": "麦迪亚",
      "name_en": "Medea",
      "name_pinyin": "maidiya",
      "code": "MED",
      "childrens": []
    }, {
      "id": "414",
      "pid": "9",
      "path": ",3,9,414,",
      "level": "3",
      "name": "密拉",
      "name_en": "Mila",
      "name_pinyin": "mila",
      "code": "MIL",
      "childrens": []
    }, {
      "id": "415",
      "pid": "9",
      "path": ",3,9,415,",
      "level": "3",
      "name": "莫斯塔加纳姆",
      "name_en": "Mostaganem",
      "name_pinyin": "mositajianamu",
      "code": "MOS",
      "childrens": []
    }, {
      "id": "416",
      "pid": "9",
      "path": ",3,9,416,",
      "level": "3",
      "name": "姆西拉",
      "name_en": "Msila",
      "name_pinyin": "muxila",
      "code": "MSI",
      "childrens": []
    }, {
      "id": "417",
      "pid": "9",
      "path": ",3,9,417,",
      "level": "3",
      "name": "纳阿马",
      "name_en": "Naama",
      "name_pinyin": "naama",
      "code": "NAA",
      "childrens": []
    }, {
      "id": "418",
      "pid": "9",
      "path": ",3,9,418,",
      "level": "3",
      "name": "塞蒂夫",
      "name_en": "Setif",
      "name_pinyin": "saidifu",
      "code": "SET",
      "childrens": []
    }, {
      "id": "419",
      "pid": "9",
      "path": ",3,9,419,",
      "level": "3",
      "name": "赛伊达",
      "name_en": "Saida",
      "name_pinyin": "saiyida",
      "code": "SAI",
      "childrens": []
    }, {
      "id": "420",
      "pid": "9",
      "path": ",3,9,420,",
      "level": "3",
      "name": "斯基克达",
      "name_en": "Skikda",
      "name_pinyin": "sijikeda",
      "code": "SKI",
      "childrens": []
    }, {
      "id": "421",
      "pid": "9",
      "path": ",3,9,421,",
      "level": "3",
      "name": "苏克·阿赫拉斯",
      "name_en": "Souk Ahras",
      "name_pinyin": "suke",
      "code": "SAH",
      "childrens": []
    }, {
      "id": "422",
      "pid": "9",
      "path": ",3,9,422,",
      "level": "3",
      "name": "塔里夫",
      "name_en": "El Tarf",
      "name_pinyin": "talifu",
      "code": "ETA",
      "childrens": []
    }, {
      "id": "423",
      "pid": "9",
      "path": ",3,9,423,",
      "level": "3",
      "name": "塔曼拉塞特",
      "name_en": "Tamanghasset",
      "name_pinyin": "tamanlasaite",
      "code": "TAM",
      "childrens": []
    }, {
      "id": "424",
      "pid": "9",
      "path": ",3,9,424,",
      "level": "3",
      "name": "特贝萨",
      "name_en": "Tebessa",
      "name_pinyin": "tebeisa",
      "code": "TEB",
      "childrens": []
    }, {
      "id": "425",
      "pid": "9",
      "path": ",3,9,425,",
      "level": "3",
      "name": "特莱姆森",
      "name_en": "Tlemcen",
      "name_pinyin": "telaimusen",
      "code": "TLE",
      "childrens": []
    }, {
      "id": "426",
      "pid": "9",
      "path": ",3,9,426,",
      "level": "3",
      "name": "提济乌祖",
      "name_en": "Tizi Ouzou",
      "name_pinyin": "tijiwuzu",
      "code": "IOU",
      "childrens": []
    }, {
      "id": "427",
      "pid": "9",
      "path": ",3,9,427,",
      "level": "3",
      "name": "提亚雷特",
      "name_en": "Tiaret",
      "name_pinyin": "tiyaleite",
      "code": "TIA",
      "childrens": []
    }, {
      "id": "428",
      "pid": "9",
      "path": ",3,9,428,",
      "level": "3",
      "name": "廷杜夫",
      "name_en": "Tindouf",
      "name_pinyin": "tingdufu",
      "code": "TIN",
      "childrens": []
    }, {
      "id": "429",
      "pid": "9",
      "path": ",3,9,429,",
      "level": "3",
      "name": "瓦德",
      "name_en": "El Oued",
      "name_pinyin": "wade",
      "code": "EOU",
      "childrens": []
    }, {
      "id": "430",
      "pid": "9",
      "path": ",3,9,430,",
      "level": "3",
      "name": "瓦尔格拉",
      "name_en": "Ouargla",
      "name_pinyin": "waergela",
      "code": "OUA",
      "childrens": []
    }, {
      "id": "431",
      "pid": "9",
      "path": ",3,9,431,",
      "level": "3",
      "name": "乌姆布阿基",
      "name_en": "Oum el Bouaghi",
      "name_pinyin": "wumubuaji",
      "code": "OEB",
      "childrens": []
    }, {
      "id": "432",
      "pid": "9",
      "path": ",3,9,432,",
      "level": "3",
      "name": "西迪贝勒阿贝斯",
      "name_en": "Sidi Bel Abbes",
      "name_pinyin": "xidibeileabeisi",
      "code": "SBA",
      "childrens": []
    }, {
      "id": "433",
      "pid": "9",
      "path": ",3,9,433,",
      "level": "3",
      "name": "谢里夫",
      "name_en": "Chlef",
      "name_pinyin": "xielifu",
      "code": "CHL",
      "childrens": []
    }, {
      "id": "434",
      "pid": "9",
      "path": ",3,9,434,",
      "level": "3",
      "name": "伊利齐",
      "name_en": "Illizi",
      "name_pinyin": "yiliqi",
      "code": "ILL",
      "childrens": []
    }]
  }, {
    "id": "16",
    "pid": "3",
    "path": ",3,16,",
    "level": "2",
    "name": "阿森松岛",
    "name_en": "Ascension Island",
    "name_pinyin": "asensongdao",
    "code": "ASC",
    "childrens": []
  }, {
    "id": "17",
    "pid": "3",
    "path": ",3,17,",
    "level": "2",
    "name": "埃及",
    "name_en": "Egypt",
    "name_pinyin": "aiji",
    "code": "EGY",
    "childrens": [{
      "id": "499",
      "pid": "17",
      "path": ",3,17,499,",
      "level": "3",
      "name": "阿斯旺",
      "name_en": "Aswan",
      "name_pinyin": "asiwang",
      "code": "ASW",
      "childrens": []
    }, {
      "id": "500",
      "pid": "17",
      "path": ",3,17,500,",
      "level": "3",
      "name": "古尔代盖",
      "name_en": "Al Ghurdaqah",
      "name_pinyin": "guerdaigai",
      "code": "GBY",
      "childrens": []
    }, {
      "id": "501",
      "pid": "17",
      "path": ",3,17,501,",
      "level": "3",
      "name": "开罗",
      "name_en": "Cairo",
      "name_pinyin": "kailuo",
      "code": "CAI",
      "childrens": []
    }, {
      "id": "502",
      "pid": "17",
      "path": ",3,17,502,",
      "level": "3",
      "name": "苏布拉开马",
      "name_en": "Shubra al Khaymah",
      "name_pinyin": "subulakaima",
      "code": "SKH",
      "childrens": []
    }, {
      "id": "503",
      "pid": "17",
      "path": ",3,17,503,",
      "level": "3",
      "name": "亚历山大",
      "name_en": "Alexandria",
      "name_pinyin": "yalishanda",
      "code": "ALY",
      "childrens": []
    }]
  }, {
    "id": "18",
    "pid": "3",
    "path": ",3,18,",
    "level": "2",
    "name": "埃塞俄比亚",
    "name_en": "Ethiopia",
    "name_pinyin": "aisaiebiya",
    "code": "ETH",
    "childrens": [{
      "id": "504",
      "pid": "18",
      "path": ",3,18,504,",
      "level": "3",
      "name": "阿法尔",
      "name_en": "Afar",
      "name_pinyin": "afaer",
      "code": "AF",
      "childrens": []
    }, {
      "id": "505",
      "pid": "18",
      "path": ",3,18,505,",
      "level": "3",
      "name": "阿姆哈拉",
      "name_en": "Amara",
      "name_pinyin": "amuhala",
      "code": "AH",
      "childrens": []
    }, {
      "id": "506",
      "pid": "18",
      "path": ",3,18,506,",
      "level": "3",
      "name": "奥罗米亚",
      "name_en": "Oromiya",
      "name_pinyin": "aoluomiya",
      "code": "OR",
      "childrens": []
    }, {
      "id": "507",
      "pid": "18",
      "path": ",3,18,507,",
      "level": "3",
      "name": "宾香古尔",
      "name_en": "Binshangul Gumuz",
      "name_pinyin": "binxiangguer",
      "code": "BG",
      "childrens": []
    }, {
      "id": "508",
      "pid": "18",
      "path": ",3,18,508,",
      "level": "3",
      "name": "德雷达瓦",
      "name_en": "Dire Dawa",
      "name_pinyin": "deleidawa",
      "code": "DD",
      "childrens": []
    }, {
      "id": "509",
      "pid": "18",
      "path": ",3,18,509,",
      "level": "3",
      "name": "甘贝拉各族",
      "name_en": "Gambela Hizboch",
      "name_pinyin": "ganbeilagezu",
      "code": "GB",
      "childrens": []
    }, {
      "id": "510",
      "pid": "18",
      "path": ",3,18,510,",
      "level": "3",
      "name": "哈勒里民族",
      "name_en": "Hareri  Hizb",
      "name_pinyin": "haleliminzu",
      "code": "HR",
      "childrens": []
    }, {
      "id": "511",
      "pid": "18",
      "path": ",3,18,511,",
      "level": "3",
      "name": "南方各族",
      "name_en": "YeDebub Biheroch",
      "name_pinyin": "nanfanggezu",
      "code": "SN",
      "childrens": []
    }, {
      "id": "512",
      "pid": "18",
      "path": ",3,18,512,",
      "level": "3",
      "name": "索马里",
      "name_en": "Sumale",
      "name_pinyin": "suomali",
      "code": "SM",
      "childrens": []
    }, {
      "id": "513",
      "pid": "18",
      "path": ",3,18,513,",
      "level": "3",
      "name": "提格雷",
      "name_en": "Tigray",
      "name_pinyin": "tigelei",
      "code": "TG",
      "childrens": []
    }, {
      "id": "514",
      "pid": "18",
      "path": ",3,18,514,",
      "level": "3",
      "name": "亚的斯亚贝巴",
      "name_en": "Adis abeba",
      "name_pinyin": "yadesiyabeiba",
      "code": "AA",
      "childrens": []
    }]
  }, {
    "id": "22",
    "pid": "3",
    "path": ",3,22,",
    "level": "2",
    "name": "安哥拉",
    "name_en": "Angola",
    "name_pinyin": "angela",
    "code": "AGO",
    "childrens": [{
      "id": "563",
      "pid": "22",
      "path": ",3,22,563,",
      "level": "3",
      "name": "北宽扎",
      "name_en": "Cuanza Norte",
      "name_pinyin": "beikuanzha",
      "code": "CNO",
      "childrens": []
    }, {
      "id": "564",
      "pid": "22",
      "path": ",3,22,564,",
      "level": "3",
      "name": "北隆达",
      "name_en": "Lunda Norte",
      "name_pinyin": "beilongda",
      "code": "LNO",
      "childrens": []
    }, {
      "id": "565",
      "pid": "22",
      "path": ",3,22,565,",
      "level": "3",
      "name": "本戈",
      "name_en": "Bengo",
      "name_pinyin": "benge",
      "code": "BGO",
      "childrens": []
    }, {
      "id": "566",
      "pid": "22",
      "path": ",3,22,566,",
      "level": "3",
      "name": "本格拉",
      "name_en": "Benguela",
      "name_pinyin": "bengela",
      "code": "BGU",
      "childrens": []
    }, {
      "id": "567",
      "pid": "22",
      "path": ",3,22,567,",
      "level": "3",
      "name": "比耶",
      "name_en": "Bie",
      "name_pinyin": "biye",
      "code": "BIE",
      "childrens": []
    }, {
      "id": "568",
      "pid": "22",
      "path": ",3,22,568,",
      "level": "3",
      "name": "卡宾达",
      "name_en": "Cabinda",
      "name_pinyin": "kabinda",
      "code": "CAB",
      "childrens": []
    }, {
      "id": "569",
      "pid": "22",
      "path": ",3,22,569,",
      "level": "3",
      "name": "库内内",
      "name_en": "Cunene",
      "name_pinyin": "kuneinei",
      "code": "CNN",
      "childrens": []
    }, {
      "id": "570",
      "pid": "22",
      "path": ",3,22,570,",
      "level": "3",
      "name": "宽多库邦戈",
      "name_en": "Cuando Cubango",
      "name_pinyin": "kuanduokubangge",
      "code": "CCU",
      "childrens": []
    }, {
      "id": "571",
      "pid": "22",
      "path": ",3,22,571,",
      "level": "3",
      "name": "罗安达",
      "name_en": "Luanda",
      "name_pinyin": "luoanda",
      "code": "LUA",
      "childrens": []
    }, {
      "id": "572",
      "pid": "22",
      "path": ",3,22,572,",
      "level": "3",
      "name": "马兰热",
      "name_en": "Malanje",
      "name_pinyin": "malanre",
      "code": "MAL",
      "childrens": []
    }, {
      "id": "573",
      "pid": "22",
      "path": ",3,22,573,",
      "level": "3",
      "name": "莫希科",
      "name_en": "Moxico",
      "name_pinyin": "moxike",
      "code": "MOX",
      "childrens": []
    }, {
      "id": "574",
      "pid": "22",
      "path": ",3,22,574,",
      "level": "3",
      "name": "纳米贝",
      "name_en": "Namibe",
      "name_pinyin": "namibei",
      "code": "NAM",
      "childrens": []
    }, {
      "id": "575",
      "pid": "22",
      "path": ",3,22,575,",
      "level": "3",
      "name": "南宽扎",
      "name_en": "Cuanza Sul",
      "name_pinyin": "nankuanzha",
      "code": "CUS",
      "childrens": []
    }, {
      "id": "576",
      "pid": "22",
      "path": ",3,22,576,",
      "level": "3",
      "name": "南隆达",
      "name_en": "Lunda Sul",
      "name_pinyin": "nanlongda",
      "code": "LSU",
      "childrens": []
    }, {
      "id": "577",
      "pid": "22",
      "path": ",3,22,577,",
      "level": "3",
      "name": "万博",
      "name_en": "Huambo",
      "name_pinyin": "wanbo",
      "code": "HUA",
      "childrens": []
    }, {
      "id": "578",
      "pid": "22",
      "path": ",3,22,578,",
      "level": "3",
      "name": "威拉",
      "name_en": "Huila",
      "name_pinyin": "weila",
      "code": "HUI",
      "childrens": []
    }, {
      "id": "579",
      "pid": "22",
      "path": ",3,22,579,",
      "level": "3",
      "name": "威热",
      "name_en": "Uige",
      "name_pinyin": "weire",
      "code": "UIG",
      "childrens": []
    }, {
      "id": "580",
      "pid": "22",
      "path": ",3,22,580,",
      "level": "3",
      "name": "扎伊尔",
      "name_en": "Zaire",
      "name_pinyin": "zhayier",
      "code": "ZAI",
      "childrens": []
    }]
  }, {
    "id": "41",
    "pid": "3",
    "path": ",3,41,",
    "level": "2",
    "name": "贝宁",
    "name_en": "Benin",
    "name_pinyin": "beining",
    "code": "BEN",
    "childrens": [{
      "id": "689",
      "pid": "41",
      "path": ",3,41,689,",
      "level": "3",
      "name": "阿黎博里",
      "name_en": "Alibori",
      "name_pinyin": "aliboli",
      "code": "AL",
      "childrens": []
    }, {
      "id": "690",
      "pid": "41",
      "path": ",3,41,690,",
      "level": "3",
      "name": "阿塔科拉",
      "name_en": "Atakora",
      "name_pinyin": "atakela",
      "code": "AK",
      "childrens": []
    }, {
      "id": "691",
      "pid": "41",
      "path": ",3,41,691,",
      "level": "3",
      "name": "滨海",
      "name_en": "Littoral",
      "name_pinyin": "binhai",
      "code": "LI",
      "childrens": []
    }, {
      "id": "692",
      "pid": "41",
      "path": ",3,41,692,",
      "level": "3",
      "name": "波希康市",
      "name_en": "Bohicon",
      "name_pinyin": "boxikangshi",
      "code": "BOH",
      "childrens": []
    }, {
      "id": "693",
      "pid": "41",
      "path": ",3,41,693,",
      "level": "3",
      "name": "博尔古",
      "name_en": "Borgou",
      "name_pinyin": "boergu",
      "code": "BO",
      "childrens": []
    }, {
      "id": "694",
      "pid": "41",
      "path": ",3,41,694,",
      "level": "3",
      "name": "大西洋",
      "name_en": "Atlantique",
      "name_pinyin": "daxiyang",
      "code": "AQ",
      "childrens": []
    }, {
      "id": "695",
      "pid": "41",
      "path": ",3,41,695,",
      "level": "3",
      "name": "高原",
      "name_en": "Plateau",
      "name_pinyin": "gaoyuan",
      "code": "PL",
      "childrens": []
    }, {
      "id": "696",
      "pid": "41",
      "path": ",3,41,696,",
      "level": "3",
      "name": "库福",
      "name_en": "Kouffo",
      "name_pinyin": "kufu",
      "code": "KO",
      "childrens": []
    }, {
      "id": "697",
      "pid": "41",
      "path": ",3,41,697,",
      "level": "3",
      "name": "莫诺",
      "name_en": "Mono",
      "name_pinyin": "monuo",
      "code": "MO",
      "childrens": []
    }, {
      "id": "698",
      "pid": "41",
      "path": ",3,41,698,",
      "level": "3",
      "name": "丘陵",
      "name_en": "Collines",
      "name_pinyin": "qiuling",
      "code": "CO",
      "childrens": []
    }, {
      "id": "699",
      "pid": "41",
      "path": ",3,41,699,",
      "level": "3",
      "name": "韦梅",
      "name_en": "Oueme",
      "name_pinyin": "weimei",
      "code": "OU",
      "childrens": []
    }, {
      "id": "700",
      "pid": "41",
      "path": ",3,41,700,",
      "level": "3",
      "name": "峡谷",
      "name_en": "Donga",
      "name_pinyin": "xiagu",
      "code": "DO",
      "childrens": []
    }, {
      "id": "701",
      "pid": "41",
      "path": ",3,41,701,",
      "level": "3",
      "name": "祖",
      "name_en": "Zou",
      "name_pinyin": "zu",
      "code": "ZO",
      "childrens": []
    }]
  }, {
    "id": "48",
    "pid": "3",
    "path": ",3,48,",
    "level": "2",
    "name": "博茨瓦纳",
    "name_en": "Botswana",
    "name_pinyin": "bociwana",
    "code": "BWA",
    "childrens": []
  }, {
    "id": "51",
    "pid": "3",
    "path": ",3,51,",
    "level": "2",
    "name": "布基纳法索",
    "name_en": "Burkina Faso",
    "name_pinyin": "bujinafasuo",
    "code": "BFA",
    "childrens": [{
      "id": "805",
      "pid": "51",
      "path": ",3,51,805,",
      "level": "3",
      "name": "巴雷",
      "name_en": "Bale",
      "name_pinyin": "balei",
      "code": "BAL",
      "childrens": []
    }, {
      "id": "806",
      "pid": "51",
      "path": ",3,51,806,",
      "level": "3",
      "name": "巴姆",
      "name_en": "Bam",
      "name_pinyin": "bamu",
      "code": "BAM",
      "childrens": []
    }, {
      "id": "807",
      "pid": "51",
      "path": ",3,51,807,",
      "level": "3",
      "name": "巴瓦",
      "name_en": "Banwa",
      "name_pinyin": "bawa",
      "code": "BAN",
      "childrens": []
    }, {
      "id": "808",
      "pid": "51",
      "path": ",3,51,808,",
      "level": "3",
      "name": "巴泽加",
      "name_en": "Bazega",
      "name_pinyin": "bazejia",
      "code": "BAZ",
      "childrens": []
    }, {
      "id": "809",
      "pid": "51",
      "path": ",3,51,809,",
      "level": "3",
      "name": "波尼",
      "name_en": "Poni",
      "name_pinyin": "boni",
      "code": "PON",
      "childrens": []
    }, {
      "id": "810",
      "pid": "51",
      "path": ",3,51,810,",
      "level": "3",
      "name": "布尔古",
      "name_en": "Boulgou",
      "name_pinyin": "buergu",
      "code": "BLG",
      "childrens": []
    }, {
      "id": "811",
      "pid": "51",
      "path": ",3,51,811,",
      "level": "3",
      "name": "布尔基恩德",
      "name_en": "Boulkiemde",
      "name_pinyin": "buerjiende",
      "code": "BOK",
      "childrens": []
    }, {
      "id": "812",
      "pid": "51",
      "path": ",3,51,812,",
      "level": "3",
      "name": "布古里巴",
      "name_en": "Bougouriba",
      "name_pinyin": "buguliba",
      "code": "BOR",
      "childrens": []
    }, {
      "id": "813",
      "pid": "51",
      "path": ",3,51,813,",
      "level": "3",
      "name": "冈祖尔古",
      "name_en": "Ganzourgou",
      "name_pinyin": "gangzuergu",
      "code": "GAN",
      "childrens": []
    }, {
      "id": "814",
      "pid": "51",
      "path": ",3,51,814,",
      "level": "3",
      "name": "古尔马",
      "name_en": "Gourma",
      "name_pinyin": "guerma",
      "code": "GOU",
      "childrens": []
    }, {
      "id": "815",
      "pid": "51",
      "path": ",3,51,815,",
      "level": "3",
      "name": "济罗",
      "name_en": "Ziro",
      "name_pinyin": "jiluo",
      "code": "ZIR",
      "childrens": []
    }, {
      "id": "816",
      "pid": "51",
      "path": ",3,51,816,",
      "level": "3",
      "name": "卡焦戈",
      "name_en": "Kadiogo",
      "name_pinyin": "kajiaoge",
      "code": "KAD",
      "childrens": []
    }, {
      "id": "817",
      "pid": "51",
      "path": ",3,51,817,",
      "level": "3",
      "name": "凯内杜古",
      "name_en": "Kenedougou",
      "name_pinyin": "kaineidugu",
      "code": "KEN",
      "childrens": []
    }, {
      "id": "818",
      "pid": "51",
      "path": ",3,51,818,",
      "level": "3",
      "name": "科蒙加里",
      "name_en": "Komondjari",
      "name_pinyin": "kemengjiali",
      "code": "KOO",
      "childrens": []
    }, {
      "id": "819",
      "pid": "51",
      "path": ",3,51,819,",
      "level": "3",
      "name": "科莫埃",
      "name_en": "Comoe",
      "name_pinyin": "kemoai",
      "code": "COM",
      "childrens": []
    }, {
      "id": "820",
      "pid": "51",
      "path": ",3,51,820,",
      "level": "3",
      "name": "孔皮恩加",
      "name_en": "Kompienga",
      "name_pinyin": "kongpienjia",
      "code": "KOP",
      "childrens": []
    }, {
      "id": "821",
      "pid": "51",
      "path": ",3,51,821,",
      "level": "3",
      "name": "孔西",
      "name_en": "Kossi",
      "name_pinyin": "kongxi",
      "code": "KOS",
      "childrens": []
    }, {
      "id": "822",
      "pid": "51",
      "path": ",3,51,822,",
      "level": "3",
      "name": "库尔佩罗戈",
      "name_en": "Koulpelogo",
      "name_pinyin": "kuerpeiluoge",
      "code": "KOL",
      "childrens": []
    }, {
      "id": "823",
      "pid": "51",
      "path": ",3,51,823,",
      "level": "3",
      "name": "库尔维奥戈",
      "name_en": "Kourweogo",
      "name_pinyin": "kuerweiaoge",
      "code": "KOW",
      "childrens": []
    }, {
      "id": "824",
      "pid": "51",
      "path": ",3,51,824,",
      "level": "3",
      "name": "库里滕加",
      "name_en": "Kouritenga",
      "name_pinyin": "kulijia",
      "code": "KOT",
      "childrens": []
    }, {
      "id": "825",
      "pid": "51",
      "path": ",3,51,825,",
      "level": "3",
      "name": "雷拉巴",
      "name_en": "Leraba",
      "name_pinyin": "leilaba",
      "code": "LER",
      "childrens": []
    }, {
      "id": "826",
      "pid": "51",
      "path": ",3,51,826,",
      "level": "3",
      "name": "罗卢姆",
      "name_en": "Loroum",
      "name_pinyin": "luolumu",
      "code": "LOR",
      "childrens": []
    }, {
      "id": "827",
      "pid": "51",
      "path": ",3,51,827,",
      "level": "3",
      "name": "穆翁",
      "name_en": "Mouhoun",
      "name_pinyin": "muweng",
      "code": "MOU",
      "childrens": []
    }, {
      "id": "828",
      "pid": "51",
      "path": ",3,51,828,",
      "level": "3",
      "name": "纳门滕加",
      "name_en": "Namentenga",
      "name_pinyin": "namenjia",
      "code": "NAM",
      "childrens": []
    }, {
      "id": "829",
      "pid": "51",
      "path": ",3,51,829,",
      "level": "3",
      "name": "纳乌里",
      "name_en": "Nahouri",
      "name_pinyin": "nawuli",
      "code": "NAH",
      "childrens": []
    }, {
      "id": "830",
      "pid": "51",
      "path": ",3,51,830,",
      "level": "3",
      "name": "纳亚拉",
      "name_en": "Nayala",
      "name_pinyin": "nayala",
      "code": "NAY",
      "childrens": []
    }, {
      "id": "831",
      "pid": "51",
      "path": ",3,51,831,",
      "level": "3",
      "name": "尼亚尼亚",
      "name_en": "Gnagna",
      "name_pinyin": "niyaniya",
      "code": "GNA",
      "childrens": []
    }, {
      "id": "832",
      "pid": "51",
      "path": ",3,51,832,",
      "level": "3",
      "name": "努姆比埃尔",
      "name_en": "Noumbiel",
      "name_pinyin": "numubiaier",
      "code": "NOU",
      "childrens": []
    }, {
      "id": "833",
      "pid": "51",
      "path": ",3,51,833,",
      "level": "3",
      "name": "帕索雷",
      "name_en": "Passore",
      "name_pinyin": "pasuolei",
      "code": "PAS",
      "childrens": []
    }, {
      "id": "834",
      "pid": "51",
      "path": ",3,51,834,",
      "level": "3",
      "name": "塞诺",
      "name_en": "Seno",
      "name_pinyin": "sainuo",
      "code": "SEN",
      "childrens": []
    }, {
      "id": "835",
      "pid": "51",
      "path": ",3,51,835,",
      "level": "3",
      "name": "桑吉",
      "name_en": "Sanguie",
      "name_pinyin": "sangji",
      "code": "SAG",
      "childrens": []
    }, {
      "id": "836",
      "pid": "51",
      "path": ",3,51,836,",
      "level": "3",
      "name": "桑马滕加",
      "name_en": "Sanmatenga",
      "name_pinyin": "sangmajia",
      "code": "SAM",
      "childrens": []
    }, {
      "id": "837",
      "pid": "51",
      "path": ",3,51,837,",
      "level": "3",
      "name": "苏鲁",
      "name_en": "Sourou",
      "name_pinyin": "sulu",
      "code": "SOR",
      "childrens": []
    }, {
      "id": "838",
      "pid": "51",
      "path": ",3,51,838,",
      "level": "3",
      "name": "苏姆",
      "name_en": "Soum",
      "name_pinyin": "sumu",
      "code": "SOM",
      "childrens": []
    }, {
      "id": "839",
      "pid": "51",
      "path": ",3,51,839,",
      "level": "3",
      "name": "塔波阿",
      "name_en": "Tapoa",
      "name_pinyin": "taboa",
      "code": "TAP",
      "childrens": []
    }, {
      "id": "840",
      "pid": "51",
      "path": ",3,51,840,",
      "level": "3",
      "name": "图伊",
      "name_en": "Tuy",
      "name_pinyin": "tuyi",
      "code": "TUY",
      "childrens": []
    }, {
      "id": "841",
      "pid": "51",
      "path": ",3,51,841,",
      "level": "3",
      "name": "乌埃",
      "name_en": "Houet",
      "name_pinyin": "wuai",
      "code": "HOU",
      "childrens": []
    }, {
      "id": "842",
      "pid": "51",
      "path": ",3,51,842,",
      "level": "3",
      "name": "乌布里滕加",
      "name_en": "Oubritenga",
      "name_pinyin": "wubulijia",
      "code": "OUB",
      "childrens": []
    }, {
      "id": "843",
      "pid": "51",
      "path": ",3,51,843,",
      "level": "3",
      "name": "乌达兰",
      "name_en": "Oudalan",
      "name_pinyin": "wudalan",
      "code": "OUD",
      "childrens": []
    }, {
      "id": "844",
      "pid": "51",
      "path": ",3,51,844,",
      "level": "3",
      "name": "锡西里",
      "name_en": "Sissili",
      "name_pinyin": "xixili",
      "code": "SIS",
      "childrens": []
    }, {
      "id": "845",
      "pid": "51",
      "path": ",3,51,845,",
      "level": "3",
      "name": "亚加",
      "name_en": "Yagha",
      "name_pinyin": "yajia",
      "code": "YAG",
      "childrens": []
    }, {
      "id": "846",
      "pid": "51",
      "path": ",3,51,846,",
      "level": "3",
      "name": "亚滕加",
      "name_en": "Yatenga",
      "name_pinyin": "yajia",
      "code": "YAT",
      "childrens": []
    }, {
      "id": "847",
      "pid": "51",
      "path": ",3,51,847,",
      "level": "3",
      "name": "伊奥巴",
      "name_en": "Ioba",
      "name_pinyin": "yiaoba",
      "code": "IOA",
      "childrens": []
    }, {
      "id": "848",
      "pid": "51",
      "path": ",3,51,848,",
      "level": "3",
      "name": "宗德韦奥戈",
      "name_en": "Zoundweogo",
      "name_pinyin": "zongdeweiaoge",
      "code": "ZOW",
      "childrens": []
    }, {
      "id": "849",
      "pid": "51",
      "path": ",3,51,849,",
      "level": "3",
      "name": "宗多马",
      "name_en": "Zondoma",
      "name_pinyin": "zongduoma",
      "code": "ZOD",
      "childrens": []
    }]
  }, {
    "id": "52",
    "pid": "3",
    "path": ",3,52,",
    "level": "2",
    "name": "布隆迪",
    "name_en": "Burundi",
    "name_pinyin": "bulongdi",
    "code": "BDI",
    "childrens": [{
      "id": "850",
      "pid": "52",
      "path": ",3,52,850,",
      "level": "3",
      "name": "布班扎",
      "name_en": "Bubanza",
      "name_pinyin": "bubanzha",
      "code": "BB",
      "childrens": []
    }, {
      "id": "851",
      "pid": "52",
      "path": ",3,52,851,",
      "level": "3",
      "name": "布鲁里",
      "name_en": "Bururi",
      "name_pinyin": "bululi",
      "code": "BR",
      "childrens": []
    }, {
      "id": "852",
      "pid": "52",
      "path": ",3,52,852,",
      "level": "3",
      "name": "布琼布拉城市",
      "name_en": "Bujumbura Mairie",
      "name_pinyin": "buqiongbulachengshi",
      "code": "BM",
      "childrens": []
    }, {
      "id": "853",
      "pid": "52",
      "path": ",3,52,853,",
      "level": "3",
      "name": "布琼布拉乡村",
      "name_en": "Bujumbura Rural",
      "name_pinyin": "buqiongbulaxiangcun",
      "code": "BU",
      "childrens": []
    }, {
      "id": "854",
      "pid": "52",
      "path": ",3,52,854,",
      "level": "3",
      "name": "恩戈齐",
      "name_en": "Ngozi",
      "name_pinyin": "engeqi",
      "code": "NG",
      "childrens": []
    }, {
      "id": "855",
      "pid": "52",
      "path": ",3,52,855,",
      "level": "3",
      "name": "基龙多",
      "name_en": "Kirundo",
      "name_pinyin": "jilongduo",
      "code": "KI",
      "childrens": []
    }, {
      "id": "856",
      "pid": "52",
      "path": ",3,52,856,",
      "level": "3",
      "name": "基特加",
      "name_en": "Gitega",
      "name_pinyin": "jitejia",
      "code": "GI",
      "childrens": []
    }, {
      "id": "857",
      "pid": "52",
      "path": ",3,52,857,",
      "level": "3",
      "name": "卡鲁济",
      "name_en": "Karuzi",
      "name_pinyin": "kaluji",
      "code": "KR",
      "childrens": []
    }, {
      "id": "858",
      "pid": "52",
      "path": ",3,52,858,",
      "level": "3",
      "name": "卡扬扎",
      "name_en": "Kayanza",
      "name_pinyin": "kayangzha",
      "code": "KY",
      "childrens": []
    }, {
      "id": "859",
      "pid": "52",
      "path": ",3,52,859,",
      "level": "3",
      "name": "坎库佐",
      "name_en": "Cankuzo",
      "name_pinyin": "kankuzuo",
      "code": "CA",
      "childrens": []
    }, {
      "id": "860",
      "pid": "52",
      "path": ",3,52,860,",
      "level": "3",
      "name": "鲁塔纳",
      "name_en": "Rutana",
      "name_pinyin": "lutana",
      "code": "RT",
      "childrens": []
    }, {
      "id": "861",
      "pid": "52",
      "path": ",3,52,861,",
      "level": "3",
      "name": "鲁伊吉",
      "name_en": "Ruyigi",
      "name_pinyin": "luyiji",
      "code": "RY",
      "childrens": []
    }, {
      "id": "862",
      "pid": "52",
      "path": ",3,52,862,",
      "level": "3",
      "name": "马坎巴",
      "name_en": "Makamba",
      "name_pinyin": "makanba",
      "code": "MA",
      "childrens": []
    }, {
      "id": "863",
      "pid": "52",
      "path": ",3,52,863,",
      "level": "3",
      "name": "穆拉姆维亚",
      "name_en": "Muramvya",
      "name_pinyin": "mulamuweiya",
      "code": "MU",
      "childrens": []
    }, {
      "id": "864",
      "pid": "52",
      "path": ",3,52,864,",
      "level": "3",
      "name": "穆瓦洛",
      "name_en": "Mwaro",
      "name_pinyin": "muwaluo",
      "code": "MW",
      "childrens": []
    }, {
      "id": "865",
      "pid": "52",
      "path": ",3,52,865,",
      "level": "3",
      "name": "穆因加",
      "name_en": "Muyinga",
      "name_pinyin": "muyinjia",
      "code": "MY",
      "childrens": []
    }, {
      "id": "866",
      "pid": "52",
      "path": ",3,52,866,",
      "level": "3",
      "name": "锡比托凯",
      "name_en": "Cibitoke",
      "name_pinyin": "xibituokai",
      "code": "CI",
      "childrens": []
    }]
  }, {
    "id": "58",
    "pid": "3",
    "path": ",3,58,",
    "level": "2",
    "name": "多哥",
    "name_en": "Togo",
    "name_pinyin": "duoge",
    "code": "TGO",
    "childrens": [{
      "id": "948",
      "pid": "58",
      "path": ",3,58,948,",
      "level": "3",
      "name": "滨海区",
      "name_en": "Maritime",
      "name_pinyin": "binhaiqu",
      "code": "M",
      "childrens": []
    }, {
      "id": "949",
      "pid": "58",
      "path": ",3,58,949,",
      "level": "3",
      "name": "草原区",
      "name_en": "Savanes",
      "name_pinyin": "caoyuanqu",
      "code": "S",
      "childrens": []
    }, {
      "id": "950",
      "pid": "58",
      "path": ",3,58,950,",
      "level": "3",
      "name": "高原区",
      "name_en": "Plateaux",
      "name_pinyin": "gaoyuanqu",
      "code": "P",
      "childrens": []
    }, {
      "id": "951",
      "pid": "58",
      "path": ",3,58,951,",
      "level": "3",
      "name": "卡拉区",
      "name_en": "Kara",
      "name_pinyin": "kalaqu",
      "code": "K",
      "childrens": []
    }, {
      "id": "952",
      "pid": "58",
      "path": ",3,58,952,",
      "level": "3",
      "name": "中部区",
      "name_en": "Centre",
      "name_pinyin": "zhongbuqu",
      "code": "C",
      "childrens": []
    }]
  }, {
    "id": "63",
    "pid": "3",
    "path": ",3,63,",
    "level": "2",
    "name": "厄立特里亚",
    "name_en": "Eritrea",
    "name_pinyin": "eliteliya",
    "code": "ERI",
    "childrens": [{
      "id": "1059",
      "pid": "63",
      "path": ",3,63,1059,",
      "level": "3",
      "name": "安塞巴",
      "name_en": "Anseba",
      "name_pinyin": "ansaiba",
      "code": "KE",
      "childrens": []
    }, {
      "id": "1060",
      "pid": "63",
      "path": ",3,63,1060,",
      "level": "3",
      "name": "北红海",
      "name_en": "Semenawi Keyih Bahri",
      "name_pinyin": "beihonghai",
      "code": "SK",
      "childrens": []
    }, {
      "id": "1061",
      "pid": "63",
      "path": ",3,63,1061,",
      "level": "3",
      "name": "加什·巴尔卡",
      "name_en": "Gash Barka",
      "name_pinyin": "jiashi",
      "code": "BR",
      "childrens": []
    }, {
      "id": "1062",
      "pid": "63",
      "path": ",3,63,1062,",
      "level": "3",
      "name": "南部",
      "name_en": "Debub",
      "name_pinyin": "nanbu",
      "code": "DE",
      "childrens": []
    }, {
      "id": "1063",
      "pid": "63",
      "path": ",3,63,1063,",
      "level": "3",
      "name": "南红海",
      "name_en": "Debubawi Keyih Bahri",
      "name_pinyin": "nanhonghai",
      "code": "DK",
      "childrens": []
    }, {
      "id": "1064",
      "pid": "63",
      "path": ",3,63,1064,",
      "level": "3",
      "name": "中部",
      "name_en": "Maekel",
      "name_pinyin": "zhongbu",
      "code": "MA",
      "childrens": []
    }]
  }, {
    "id": "73",
    "pid": "3",
    "path": ",3,73,",
    "level": "2",
    "name": "佛得角",
    "name_en": "Cape Verde",
    "name_pinyin": "fodejiao",
    "code": "CPV",
    "childrens": [{
      "id": "1112",
      "pid": "73",
      "path": ",3,73,1112,",
      "level": "3",
      "name": "保尔",
      "name_en": "Paul",
      "name_pinyin": "baoer",
      "code": "PA",
      "childrens": []
    }, {
      "id": "1113",
      "pid": "73",
      "path": ",3,73,1113,",
      "level": "3",
      "name": "波多诺伏",
      "name_en": "Porto Novo",
      "name_pinyin": "boduonuofu",
      "code": "PN",
      "childrens": []
    }, {
      "id": "1114",
      "pid": "73",
      "path": ",3,73,1114,",
      "level": "3",
      "name": "博阿维斯塔岛",
      "name_en": "Boa Vista",
      "name_pinyin": "boaweisitadao",
      "code": "BV",
      "childrens": []
    }, {
      "id": "1115",
      "pid": "73",
      "path": ",3,73,1115,",
      "level": "3",
      "name": "布拉瓦岛",
      "name_en": "Brava",
      "name_pinyin": "bulawadao",
      "code": "BR",
      "childrens": []
    }, {
      "id": "1116",
      "pid": "73",
      "path": ",3,73,1116,",
      "level": "3",
      "name": "大里贝拉",
      "name_en": "Ribeira Grande",
      "name_pinyin": "dalibeila",
      "code": "RG",
      "childrens": []
    }, {
      "id": "1117",
      "pid": "73",
      "path": ",3,73,1117,",
      "level": "3",
      "name": "福古岛",
      "name_en": "Fogo",
      "name_pinyin": "fugudao",
      "code": "FO",
      "childrens": []
    }, {
      "id": "1118",
      "pid": "73",
      "path": ",3,73,1118,",
      "level": "3",
      "name": "马尤岛",
      "name_en": "Maio",
      "name_pinyin": "mayoudao",
      "code": "MA",
      "childrens": []
    }, {
      "id": "1119",
      "pid": "73",
      "path": ",3,73,1119,",
      "level": "3",
      "name": "莫斯特罗",
      "name_en": "Mosteiros",
      "name_pinyin": "mositeluo",
      "code": "MO",
      "childrens": []
    }, {
      "id": "1120",
      "pid": "73",
      "path": ",3,73,1120,",
      "level": "3",
      "name": "普拉亚",
      "name_en": "Praia",
      "name_pinyin": "pulaya",
      "code": "PR",
      "childrens": []
    }, {
      "id": "1121",
      "pid": "73",
      "path": ",3,73,1121,",
      "level": "3",
      "name": "萨尔岛",
      "name_en": "Sal",
      "name_pinyin": "saerdao",
      "code": "SL",
      "childrens": []
    }, {
      "id": "1122",
      "pid": "73",
      "path": ",3,73,1122,",
      "level": "3",
      "name": "圣安唐岛",
      "name_en": "Santo Antao",
      "name_pinyin": "shengantangdao",
      "code": "SA",
      "childrens": []
    }, {
      "id": "1123",
      "pid": "73",
      "path": ",3,73,1123,",
      "level": "3",
      "name": "圣地亚哥岛",
      "name_en": "Santiago",
      "name_pinyin": "shengdiyagedao",
      "code": "IA",
      "childrens": []
    }, {
      "id": "1124",
      "pid": "73",
      "path": ",3,73,1124,",
      "level": "3",
      "name": "圣多明戈",
      "name_en": "Sao Domingos",
      "name_pinyin": "shengduomingge",
      "code": "SD",
      "childrens": []
    }, {
      "id": "1125",
      "pid": "73",
      "path": ",3,73,1125,",
      "level": "3",
      "name": "圣菲利普",
      "name_en": "Sao Filipe",
      "name_pinyin": "shengfeilipu",
      "code": "SF",
      "childrens": []
    }, {
      "id": "1126",
      "pid": "73",
      "path": ",3,73,1126,",
      "level": "3",
      "name": "圣卡塔琳娜",
      "name_en": "Santa Catarina",
      "name_pinyin": "shengkatalinna",
      "code": "CA",
      "childrens": []
    }, {
      "id": "1127",
      "pid": "73",
      "path": ",3,73,1127,",
      "level": "3",
      "name": "圣克鲁斯",
      "name_en": "Santa Cruz",
      "name_pinyin": "shengkelusi",
      "code": "CR",
      "childrens": []
    }, {
      "id": "1128",
      "pid": "73",
      "path": ",3,73,1128,",
      "level": "3",
      "name": "圣米戈尔",
      "name_en": "Sao Miguel",
      "name_pinyin": "shengmigeer",
      "code": "SM",
      "childrens": []
    }, {
      "id": "1129",
      "pid": "73",
      "path": ",3,73,1129,",
      "level": "3",
      "name": "圣尼古拉岛",
      "name_en": "Sao Nicolau",
      "name_pinyin": "shengniguladao",
      "code": "SN",
      "childrens": []
    }, {
      "id": "1130",
      "pid": "73",
      "path": ",3,73,1130,",
      "level": "3",
      "name": "圣维森特岛",
      "name_en": "Sao Vicente",
      "name_pinyin": "shengweisentedao",
      "code": "SV",
      "childrens": []
    }, {
      "id": "1131",
      "pid": "73",
      "path": ",3,73,1131,",
      "level": "3",
      "name": "塔拉法尔",
      "name_en": "Tarrafal",
      "name_pinyin": "talafaer",
      "code": "TA",
      "childrens": []
    }]
  }, {
    "id": "75",
    "pid": "3",
    "path": ",3,75,",
    "level": "2",
    "name": "冈比亚",
    "name_en": "Gambia",
    "name_pinyin": "gangbiya",
    "code": "GMB",
    "childrens": []
  }, {
    "id": "76",
    "pid": "3",
    "path": ",3,76,",
    "level": "2",
    "name": "刚果",
    "name_en": "Congo",
    "name_pinyin": "gangguo",
    "code": "COG",
    "childrens": []
  }, {
    "id": "77",
    "pid": "3",
    "path": ",3,77,",
    "level": "2",
    "name": "刚果民主共和国",
    "name_en": "Congo(DRC)",
    "name_pinyin": "gangguominzhugongheguo",
    "code": "COD",
    "childrens": []
  }, {
    "id": "95",
    "pid": "3",
    "path": ",3,95,",
    "level": "2",
    "name": "吉布提",
    "name_en": "Djibouti",
    "name_pinyin": "jibuti",
    "code": "DJI",
    "childrens": [{
      "id": "1286",
      "pid": "95",
      "path": ",3,95,1286,",
      "level": "3",
      "name": "阿里萨比赫区",
      "name_en": "Ali Sabih",
      "name_pinyin": "alisabihequ",
      "code": "S",
      "childrens": []
    }, {
      "id": "1287",
      "pid": "95",
      "path": ",3,95,1287,",
      "level": "3",
      "name": "奥博克区",
      "name_en": "Obock",
      "name_pinyin": "aobokequ",
      "code": "O",
      "childrens": []
    }, {
      "id": "1288",
      "pid": "95",
      "path": ",3,95,1288,",
      "level": "3",
      "name": "迪基勒区",
      "name_en": "Dikhil",
      "name_pinyin": "dijilequ",
      "code": "K",
      "childrens": []
    }, {
      "id": "1289",
      "pid": "95",
      "path": ",3,95,1289,",
      "level": "3",
      "name": "塔朱拉区",
      "name_en": "Tadjoura",
      "name_pinyin": "tazhulaqu",
      "code": "T",
      "childrens": []
    }]
  }, {
    "id": "97",
    "pid": "3",
    "path": ",3,97,",
    "level": "2",
    "name": "几内亚",
    "name_en": "Guinea",
    "name_pinyin": "jineiya",
    "code": "GIN",
    "childrens": [{
      "id": "1306",
      "pid": "97",
      "path": ",3,97,1306,",
      "level": "3",
      "name": "博凯",
      "name_en": "Boke",
      "name_pinyin": "bokai",
      "code": "BOK",
      "childrens": []
    }, {
      "id": "1307",
      "pid": "97",
      "path": ",3,97,1307,",
      "level": "3",
      "name": "恩泽雷科雷",
      "name_en": "Nzerekore",
      "name_pinyin": "enzeleikelei",
      "code": "NZR",
      "childrens": []
    }, {
      "id": "1308",
      "pid": "97",
      "path": ",3,97,1308,",
      "level": "3",
      "name": "法拉纳",
      "name_en": "Faranah",
      "name_pinyin": "falana",
      "code": "FRN",
      "childrens": []
    }, {
      "id": "1309",
      "pid": "97",
      "path": ",3,97,1309,",
      "level": "3",
      "name": "金迪亚",
      "name_en": "Kindia",
      "name_pinyin": "jindiya",
      "code": "KND",
      "childrens": []
    }, {
      "id": "1310",
      "pid": "97",
      "path": ",3,97,1310,",
      "level": "3",
      "name": "康康",
      "name_en": "Kankan",
      "name_pinyin": "kangkang",
      "code": "KNK",
      "childrens": []
    }, {
      "id": "1311",
      "pid": "97",
      "path": ",3,97,1311,",
      "level": "3",
      "name": "科纳克里",
      "name_en": "Conakry",
      "name_pinyin": "kenakeli",
      "code": "CNK",
      "childrens": []
    }, {
      "id": "1312",
      "pid": "97",
      "path": ",3,97,1312,",
      "level": "3",
      "name": "拉贝",
      "name_en": "Labe",
      "name_pinyin": "labei",
      "code": "LAB",
      "childrens": []
    }, {
      "id": "1313",
      "pid": "97",
      "path": ",3,97,1313,",
      "level": "3",
      "name": "玛木",
      "name_en": "Mamou",
      "name_pinyin": "mamu",
      "code": "MAM",
      "childrens": []
    }]
  }, {
    "id": "98",
    "pid": "3",
    "path": ",3,98,",
    "level": "2",
    "name": "几内亚比绍",
    "name_en": "Guinea-Bissau",
    "name_pinyin": "jineiyabishao",
    "code": "GNB",
    "childrens": []
  }, {
    "id": "100",
    "pid": "3",
    "path": ",3,100,",
    "level": "2",
    "name": "加纳",
    "name_en": "Ghana",
    "name_pinyin": "jiana",
    "code": "GHA",
    "childrens": [{
      "id": "1348",
      "pid": "100",
      "path": ",3,100,1348,",
      "level": "3",
      "name": "阿散蒂",
      "name_en": "Ashanti",
      "name_pinyin": "asandi",
      "code": "AS",
      "childrens": []
    }, {
      "id": "1349",
      "pid": "100",
      "path": ",3,100,1349,",
      "level": "3",
      "name": "奥布阿西",
      "name_en": "Obuasi",
      "name_pinyin": "aobuaxi",
      "code": "OBU",
      "childrens": []
    }, {
      "id": "1350",
      "pid": "100",
      "path": ",3,100,1350,",
      "level": "3",
      "name": "北部",
      "name_en": "Northern",
      "name_pinyin": "beibu",
      "code": "NO",
      "childrens": []
    }, {
      "id": "1351",
      "pid": "100",
      "path": ",3,100,1351,",
      "level": "3",
      "name": "布朗阿哈福",
      "name_en": "Brong Ahafo",
      "name_pinyin": "bulangahafu",
      "code": "BA",
      "childrens": []
    }, {
      "id": "1352",
      "pid": "100",
      "path": ",3,100,1352,",
      "level": "3",
      "name": "大阿克拉",
      "name_en": "Greater Accra",
      "name_pinyin": "daakela",
      "code": "GA",
      "childrens": []
    }, {
      "id": "1353",
      "pid": "100",
      "path": ",3,100,1353,",
      "level": "3",
      "name": "东部",
      "name_en": "Eastern",
      "name_pinyin": "dongbu",
      "code": "EA",
      "childrens": []
    }, {
      "id": "1354",
      "pid": "100",
      "path": ",3,100,1354,",
      "level": "3",
      "name": "上东部",
      "name_en": "Upper East",
      "name_pinyin": "shangdongbu",
      "code": "UE",
      "childrens": []
    }, {
      "id": "1355",
      "pid": "100",
      "path": ",3,100,1355,",
      "level": "3",
      "name": "上西部",
      "name_en": "Upper West",
      "name_pinyin": "shangxibu",
      "code": "UW",
      "childrens": []
    }, {
      "id": "1356",
      "pid": "100",
      "path": ",3,100,1356,",
      "level": "3",
      "name": "沃尔特",
      "name_en": "Volta",
      "name_pinyin": "woerte",
      "code": "VO",
      "childrens": []
    }, {
      "id": "1357",
      "pid": "100",
      "path": ",3,100,1357,",
      "level": "3",
      "name": "西部",
      "name_en": "Western",
      "name_pinyin": "xibu",
      "code": "WE",
      "childrens": []
    }, {
      "id": "1358",
      "pid": "100",
      "path": ",3,100,1358,",
      "level": "3",
      "name": "中部",
      "name_en": "Central",
      "name_pinyin": "zhongbu",
      "code": "CE",
      "childrens": []
    }]
  }, {
    "id": "101",
    "pid": "3",
    "path": ",3,101,",
    "level": "2",
    "name": "加蓬",
    "name_en": "Gabon",
    "name_pinyin": "jiapeng",
    "code": "GAB",
    "childrens": [{
      "id": "1359",
      "pid": "101",
      "path": ",3,101,1359,",
      "level": "3",
      "name": "奥果韦-洛洛",
      "name_en": "Ogooue-Lolo",
      "name_pinyin": "aoguoweiluoluo",
      "code": "OL",
      "childrens": []
    }, {
      "id": "1360",
      "pid": "101",
      "path": ",3,101,1360,",
      "level": "3",
      "name": "奥果韦-伊温多",
      "name_en": "Ogooue-Ivindo",
      "name_pinyin": "aoguoweiyiwenduo",
      "code": "OI",
      "childrens": []
    }, {
      "id": "1361",
      "pid": "101",
      "path": ",3,101,1361,",
      "level": "3",
      "name": "滨海奥果韦",
      "name_en": "Ogooue-Maritime",
      "name_pinyin": "binhaiaoguowei",
      "code": "OM",
      "childrens": []
    }, {
      "id": "1362",
      "pid": "101",
      "path": ",3,101,1362,",
      "level": "3",
      "name": "恩古涅",
      "name_en": "Ngounie",
      "name_pinyin": "engunie",
      "code": "NG",
      "childrens": []
    }, {
      "id": "1363",
      "pid": "101",
      "path": ",3,101,1363,",
      "level": "3",
      "name": "河口",
      "name_en": "Estuaire",
      "name_pinyin": "hekou",
      "code": "ES",
      "childrens": []
    }, {
      "id": "1364",
      "pid": "101",
      "path": ",3,101,1364,",
      "level": "3",
      "name": "尼扬加",
      "name_en": "Nyanga",
      "name_pinyin": "niyangjia",
      "code": "NY",
      "childrens": []
    }, {
      "id": "1365",
      "pid": "101",
      "path": ",3,101,1365,",
      "level": "3",
      "name": "上奥果韦",
      "name_en": "Haut-Ogooue",
      "name_pinyin": "shangaoguowei",
      "code": "HO",
      "childrens": []
    }, {
      "id": "1366",
      "pid": "101",
      "path": ",3,101,1366,",
      "level": "3",
      "name": "沃勒-恩特姆",
      "name_en": "Woleu-Ntem",
      "name_pinyin": "woleentemu",
      "code": "WN",
      "childrens": []
    }, {
      "id": "1367",
      "pid": "101",
      "path": ",3,101,1367,",
      "level": "3",
      "name": "中奥果韦",
      "name_en": "Moyen-Ogooue",
      "name_pinyin": "zhongaoguowei",
      "code": "MO",
      "childrens": []
    }]
  }, {
    "id": "104",
    "pid": "3",
    "path": ",3,104,",
    "level": "2",
    "name": "津巴布韦",
    "name_en": "Zimbabwe",
    "name_pinyin": "jinbabuwei",
    "code": "ZWE",
    "childrens": [{
      "id": "1405",
      "pid": "104",
      "path": ",3,104,1405,",
      "level": "3",
      "name": "北马塔贝莱兰",
      "name_en": "Matabeleland North",
      "name_pinyin": "beimatabeilailan",
      "code": "MN",
      "childrens": []
    }, {
      "id": "1406",
      "pid": "104",
      "path": ",3,104,1406,",
      "level": "3",
      "name": "布拉瓦约",
      "name_en": "Bulawayo",
      "name_pinyin": "bulawayue",
      "code": "BU",
      "childrens": []
    }, {
      "id": "1407",
      "pid": "104",
      "path": ",3,104,1407,",
      "level": "3",
      "name": "东马绍纳兰",
      "name_en": "Mashonaland East",
      "name_pinyin": "dongmashaonalan",
      "code": "ME",
      "childrens": []
    }, {
      "id": "1408",
      "pid": "104",
      "path": ",3,104,1408,",
      "level": "3",
      "name": "哈拉雷",
      "name_en": "Harare",
      "name_pinyin": "halalei",
      "code": "HA",
      "childrens": []
    }, {
      "id": "1409",
      "pid": "104",
      "path": ",3,104,1409,",
      "level": "3",
      "name": "马尼卡兰",
      "name_en": "Manicaland",
      "name_pinyin": "manikalan",
      "code": "ML",
      "childrens": []
    }, {
      "id": "1410",
      "pid": "104",
      "path": ",3,104,1410,",
      "level": "3",
      "name": "马斯温戈",
      "name_en": "Masvingo",
      "name_pinyin": "masiwenge",
      "code": "MV",
      "childrens": []
    }, {
      "id": "1411",
      "pid": "104",
      "path": ",3,104,1411,",
      "level": "3",
      "name": "南马塔贝莱兰",
      "name_en": "Matabeleland South",
      "name_pinyin": "nanmatabeilailan",
      "code": "MS",
      "childrens": []
    }, {
      "id": "1412",
      "pid": "104",
      "path": ",3,104,1412,",
      "level": "3",
      "name": "西马绍纳兰",
      "name_en": "Mashonaland West",
      "name_pinyin": "ximashaonalan",
      "code": "MW",
      "childrens": []
    }, {
      "id": "1413",
      "pid": "104",
      "path": ",3,104,1413,",
      "level": "3",
      "name": "中部",
      "name_en": "Midlands",
      "name_pinyin": "zhongbu",
      "code": "MD",
      "childrens": []
    }, {
      "id": "1414",
      "pid": "104",
      "path": ",3,104,1414,",
      "level": "3",
      "name": "中马绍纳兰",
      "name_en": "Mashonaland Central",
      "name_pinyin": "zhongmashaonalan",
      "code": "MC",
      "childrens": []
    }]
  }, {
    "id": "105",
    "pid": "3",
    "path": ",3,105,",
    "level": "2",
    "name": "喀麦隆",
    "name_en": "Cameroon",
    "name_pinyin": "kamailong",
    "code": "CMR",
    "childrens": [{
      "id": "1415",
      "pid": "105",
      "path": ",3,105,1415,",
      "level": "3",
      "name": "阿达马瓦",
      "name_en": "Adamaoua",
      "name_pinyin": "adamawa",
      "code": "ADA",
      "childrens": []
    }, {
      "id": "1416",
      "pid": "105",
      "path": ",3,105,1416,",
      "level": "3",
      "name": "北部",
      "name_en": "Nord",
      "name_pinyin": "beibu",
      "code": "NOR",
      "childrens": []
    }, {
      "id": "1417",
      "pid": "105",
      "path": ",3,105,1417,",
      "level": "3",
      "name": "北端",
      "name_en": "Extreme-Nord",
      "name_pinyin": "beiduan",
      "code": "EXN",
      "childrens": []
    }, {
      "id": "1418",
      "pid": "105",
      "path": ",3,105,1418,",
      "level": "3",
      "name": "滨海",
      "name_en": "Littoral",
      "name_pinyin": "binhai",
      "code": "LIT",
      "childrens": []
    }, {
      "id": "1419",
      "pid": "105",
      "path": ",3,105,1419,",
      "level": "3",
      "name": "东部",
      "name_en": "Est",
      "name_pinyin": "dongbu",
      "code": "EST",
      "childrens": []
    }, {
      "id": "1420",
      "pid": "105",
      "path": ",3,105,1420,",
      "level": "3",
      "name": "南部",
      "name_en": "Sud",
      "name_pinyin": "nanbu",
      "code": "SUD",
      "childrens": []
    }, {
      "id": "1421",
      "pid": "105",
      "path": ",3,105,1421,",
      "level": "3",
      "name": "西北",
      "name_en": "Nord-Oueste",
      "name_pinyin": "xibei",
      "code": "NOT",
      "childrens": []
    }, {
      "id": "1422",
      "pid": "105",
      "path": ",3,105,1422,",
      "level": "3",
      "name": "西部",
      "name_en": "Ouest",
      "name_pinyin": "xibu",
      "code": "OUE",
      "childrens": []
    }, {
      "id": "1423",
      "pid": "105",
      "path": ",3,105,1423,",
      "level": "3",
      "name": "西南",
      "name_en": "Sud-Oueste",
      "name_pinyin": "xinan",
      "code": "SOU",
      "childrens": []
    }, {
      "id": "1424",
      "pid": "105",
      "path": ",3,105,1424,",
      "level": "3",
      "name": "中央",
      "name_en": "Centre",
      "name_pinyin": "zhongyang",
      "code": "CEN",
      "childrens": []
    }]
  }, {
    "id": "109",
    "pid": "3",
    "path": ",3,109,",
    "level": "2",
    "name": "科摩罗",
    "name_en": "Comoros",
    "name_pinyin": "kemoluo",
    "code": "COM",
    "childrens": []
  }, {
    "id": "110",
    "pid": "3",
    "path": ",3,110,",
    "level": "2",
    "name": "科特迪瓦",
    "name_en": "Cote d'Ivoire",
    "name_pinyin": "ketediwa",
    "code": "CIV",
    "childrens": [{
      "id": "1434",
      "pid": "110",
      "path": ",3,110,1434,",
      "level": "3",
      "name": "阿涅比",
      "name_en": "Agnebi",
      "name_pinyin": "aniebi",
      "code": "AG",
      "childrens": []
    }, {
      "id": "1435",
      "pid": "110",
      "path": ",3,110,1435,",
      "level": "3",
      "name": "巴芬",
      "name_en": "Bafing",
      "name_pinyin": "bafen",
      "code": "BF",
      "childrens": []
    }, {
      "id": "1436",
      "pid": "110",
      "path": ",3,110,1436,",
      "level": "3",
      "name": "邦达马河谷",
      "name_en": "Vallee du Bandama",
      "name_pinyin": "bangdamahegu",
      "code": "VB",
      "childrens": []
    }, {
      "id": "1437",
      "pid": "110",
      "path": ",3,110,1437,",
      "level": "3",
      "name": "登盖莱",
      "name_en": "Denguele",
      "name_pinyin": "denggailai",
      "code": "DE",
      "childrens": []
    }, {
      "id": "1438",
      "pid": "110",
      "path": ",3,110,1438,",
      "level": "3",
      "name": "恩济－科莫埃",
      "name_en": "Nzi-Comoe",
      "name_pinyin": "enjikemoai",
      "code": "NC",
      "childrens": []
    }, {
      "id": "1439",
      "pid": "110",
      "path": ",3,110,1439,",
      "level": "3",
      "name": "弗罗马格尔",
      "name_en": "Fromager",
      "name_pinyin": "fuluomageer",
      "code": "FR",
      "childrens": []
    }, {
      "id": "1440",
      "pid": "110",
      "path": ",3,110,1440,",
      "level": "3",
      "name": "湖泊",
      "name_en": "Lacs",
      "name_pinyin": "hubo",
      "code": "LC",
      "childrens": []
    }, {
      "id": "1441",
      "pid": "110",
      "path": ",3,110,1441,",
      "level": "3",
      "name": "马拉韦",
      "name_en": "Marahoue",
      "name_pinyin": "malawei",
      "code": "MR",
      "childrens": []
    }, {
      "id": "1442",
      "pid": "110",
      "path": ",3,110,1442,",
      "level": "3",
      "name": "南邦达马",
      "name_en": "Sud-Bandama",
      "name_pinyin": "nanbangdama",
      "code": "SB",
      "childrens": []
    }, {
      "id": "1443",
      "pid": "110",
      "path": ",3,110,1443,",
      "level": "3",
      "name": "南科莫埃",
      "name_en": "Sud-Comoe",
      "name_pinyin": "nankemoai",
      "code": "SC",
      "childrens": []
    }, {
      "id": "1444",
      "pid": "110",
      "path": ",3,110,1444,",
      "level": "3",
      "name": "萨桑德拉",
      "name_en": "Haut-Sassandra",
      "name_pinyin": "sasangdela",
      "code": "HT",
      "childrens": []
    }, {
      "id": "1445",
      "pid": "110",
      "path": ",3,110,1445,",
      "level": "3",
      "name": "萨瓦纳",
      "name_en": "Savanes",
      "name_pinyin": "sawana",
      "code": "SV",
      "childrens": []
    }, {
      "id": "1446",
      "pid": "110",
      "path": ",3,110,1446,",
      "level": "3",
      "name": "山地",
      "name_en": "Montagnes",
      "name_pinyin": "shandi",
      "code": "DH",
      "childrens": []
    }, {
      "id": "1447",
      "pid": "110",
      "path": ",3,110,1447,",
      "level": "3",
      "name": "沃罗杜古",
      "name_en": "Worodougou",
      "name_pinyin": "woluodugu",
      "code": "WR",
      "childrens": []
    }, {
      "id": "1448",
      "pid": "110",
      "path": ",3,110,1448,",
      "level": "3",
      "name": "下萨桑德拉",
      "name_en": "Bas-Sassandra",
      "name_pinyin": "xiasasangdela",
      "code": "BS",
      "childrens": []
    }, {
      "id": "1449",
      "pid": "110",
      "path": ",3,110,1449,",
      "level": "3",
      "name": "泻湖",
      "name_en": "Lagunes",
      "name_pinyin": "xiehu",
      "code": "LG",
      "childrens": []
    }, {
      "id": "1450",
      "pid": "110",
      "path": ",3,110,1450,",
      "level": "3",
      "name": "赞赞",
      "name_en": "Zanzan",
      "name_pinyin": "zanzan",
      "code": "ZA",
      "childrens": []
    }, {
      "id": "1451",
      "pid": "110",
      "path": ",3,110,1451,",
      "level": "3",
      "name": "中卡瓦利",
      "name_en": "Moyen-Cavally",
      "name_pinyin": "zhongkawali",
      "code": "MV",
      "childrens": []
    }, {
      "id": "1452",
      "pid": "110",
      "path": ",3,110,1452,",
      "level": "3",
      "name": "中科莫埃",
      "name_en": "Moyen-Comoe",
      "name_pinyin": "zhongkemoai",
      "code": "MC",
      "childrens": []
    }]
  }, {
    "id": "113",
    "pid": "3",
    "path": ",3,113,",
    "level": "2",
    "name": "肯尼亚",
    "name_en": "Kenya",
    "name_pinyin": "kenniya",
    "code": "KEN",
    "childrens": [{
      "id": "1474",
      "pid": "113",
      "path": ",3,113,1474,",
      "level": "3",
      "name": "埃尔格约-马拉奎特",
      "name_en": "Elgeyo-Marakwet",
      "name_pinyin": "aiergeyuemalakuite",
      "code": "EMA",
      "childrens": []
    }, {
      "id": "1475",
      "pid": "113",
      "path": ",3,113,1475,",
      "level": "3",
      "name": "巴林戈",
      "name_en": "Baringo",
      "name_pinyin": "balinge",
      "code": "BAR",
      "childrens": []
    }, {
      "id": "1476",
      "pid": "113",
      "path": ",3,113,1476,",
      "level": "3",
      "name": "邦戈马",
      "name_en": "Bungoma",
      "name_pinyin": "banggema",
      "code": "BUN",
      "childrens": []
    }, {
      "id": "1477",
      "pid": "113",
      "path": ",3,113,1477,",
      "level": "3",
      "name": "博美特",
      "name_en": "Bomet",
      "name_pinyin": "bomeite",
      "code": "BOM",
      "childrens": []
    }, {
      "id": "1478",
      "pid": "113",
      "path": ",3,113,1478,",
      "level": "3",
      "name": "布希亚",
      "name_en": "Busia",
      "name_pinyin": "buxiya",
      "code": "BUS",
      "childrens": []
    }, {
      "id": "1479",
      "pid": "113",
      "path": ",3,113,1479,",
      "level": "3",
      "name": "恩布",
      "name_en": "Embu",
      "name_pinyin": "enbu",
      "code": "EMB",
      "childrens": []
    }, {
      "id": "1480",
      "pid": "113",
      "path": ",3,113,1480,",
      "level": "3",
      "name": "霍马湾",
      "name_en": "Homa Bay",
      "name_pinyin": "huomawan",
      "code": "HOB",
      "childrens": []
    }, {
      "id": "1481",
      "pid": "113",
      "path": ",3,113,1481,",
      "level": "3",
      "name": "基安布",
      "name_en": "Kiambu",
      "name_pinyin": "jianbu",
      "code": "KIA",
      "childrens": []
    }, {
      "id": "1482",
      "pid": "113",
      "path": ",3,113,1482,",
      "level": "3",
      "name": "基里菲",
      "name_en": "Kilifi",
      "name_pinyin": "jilifei",
      "code": "KIL",
      "childrens": []
    }, {
      "id": "1483",
      "pid": "113",
      "path": ",3,113,1483,",
      "level": "3",
      "name": "基里尼亚加",
      "name_en": "Kirinyaga",
      "name_pinyin": "jiliniyajia",
      "code": "KIR",
      "childrens": []
    }, {
      "id": "1484",
      "pid": "113",
      "path": ",3,113,1484,",
      "level": "3",
      "name": "基苏木",
      "name_en": "Kisumu",
      "name_pinyin": "jisumu",
      "code": "KIS",
      "childrens": []
    }, {
      "id": "1485",
      "pid": "113",
      "path": ",3,113,1485,",
      "level": "3",
      "name": "基图伊",
      "name_en": "Kitui",
      "name_pinyin": "jituyi",
      "code": "KIT",
      "childrens": []
    }, {
      "id": "1486",
      "pid": "113",
      "path": ",3,113,1486,",
      "level": "3",
      "name": "基西",
      "name_en": "Kisii",
      "name_pinyin": "jixi",
      "code": "KII",
      "childrens": []
    }, {
      "id": "1487",
      "pid": "113",
      "path": ",3,113,1487,",
      "level": "3",
      "name": "加里萨",
      "name_en": "Garissa",
      "name_pinyin": "jialisa",
      "code": "GAS",
      "childrens": []
    }, {
      "id": "1488",
      "pid": "113",
      "path": ",3,113,1488,",
      "level": "3",
      "name": "卡卡梅加",
      "name_en": "Kakamega",
      "name_pinyin": "kakameijia",
      "code": "KAK",
      "childrens": []
    }, {
      "id": "1489",
      "pid": "113",
      "path": ",3,113,1489,",
      "level": "3",
      "name": "卡耶亚多",
      "name_en": "Kajiado",
      "name_pinyin": "kayeyaduo",
      "code": "KAJ",
      "childrens": []
    }, {
      "id": "1490",
      "pid": "113",
      "path": ",3,113,1490,",
      "level": "3",
      "name": "凯里乔",
      "name_en": "Kericho",
      "name_pinyin": "kailiqiao",
      "code": "KEY",
      "childrens": []
    }, {
      "id": "1491",
      "pid": "113",
      "path": ",3,113,1491,",
      "level": "3",
      "name": "夸勒",
      "name_en": "Kwale",
      "name_pinyin": "kuale",
      "code": "KWA",
      "childrens": []
    }, {
      "id": "1492",
      "pid": "113",
      "path": ",3,113,1492,",
      "level": "3",
      "name": "拉木",
      "name_en": "Lamu",
      "name_pinyin": "lamu",
      "code": "LAU",
      "childrens": []
    }, {
      "id": "1493",
      "pid": "113",
      "path": ",3,113,1493,",
      "level": "3",
      "name": "莱基皮亚",
      "name_en": "Laikipia",
      "name_pinyin": "laijipiya",
      "code": "LAI",
      "childrens": []
    }, {
      "id": "1494",
      "pid": "113",
      "path": ",3,113,1494,",
      "level": "3",
      "name": "马查科斯",
      "name_en": "Machakos",
      "name_pinyin": "machakesi",
      "code": "MAC",
      "childrens": []
    }, {
      "id": "1495",
      "pid": "113",
      "path": ",3,113,1495,",
      "level": "3",
      "name": "马瓜尼",
      "name_en": "Makueni",
      "name_pinyin": "maguani",
      "code": "MAK",
      "childrens": []
    }, {
      "id": "1496",
      "pid": "113",
      "path": ",3,113,1496,",
      "level": "3",
      "name": "马萨布布",
      "name_en": "Marsabit",
      "name_pinyin": "masabubu",
      "code": "RBT",
      "childrens": []
    }, {
      "id": "1497",
      "pid": "113",
      "path": ",3,113,1497,",
      "level": "3",
      "name": "曼德拉",
      "name_en": "Mandera",
      "name_pinyin": "mandela",
      "code": "MAN",
      "childrens": []
    }, {
      "id": "1498",
      "pid": "113",
      "path": ",3,113,1498,",
      "level": "3",
      "name": "梅鲁",
      "name_en": "Meru",
      "name_pinyin": "meilu",
      "code": "MER",
      "childrens": []
    }, {
      "id": "1499",
      "pid": "113",
      "path": ",3,113,1499,",
      "level": "3",
      "name": "蒙巴萨",
      "name_en": "Mombasa",
      "name_pinyin": "mengbasa",
      "code": "MOM",
      "childrens": []
    }, {
      "id": "1500",
      "pid": "113",
      "path": ",3,113,1500,",
      "level": "3",
      "name": "米戈利",
      "name_en": "Migori",
      "name_pinyin": "migeli",
      "code": "MIG",
      "childrens": []
    }, {
      "id": "1501",
      "pid": "113",
      "path": ",3,113,1501,",
      "level": "3",
      "name": "穆兰卡",
      "name_en": "Muranga",
      "name_pinyin": "mulanka",
      "code": "MUR",
      "childrens": []
    }, {
      "id": "1502",
      "pid": "113",
      "path": ",3,113,1502,",
      "level": "3",
      "name": "纳库鲁",
      "name_en": "Nakuru",
      "name_pinyin": "nakulu",
      "code": "NUU",
      "childrens": []
    }, {
      "id": "1503",
      "pid": "113",
      "path": ",3,113,1503,",
      "level": "3",
      "name": "纳罗克",
      "name_en": "Narok",
      "name_pinyin": "naluoke",
      "code": "NAR",
      "childrens": []
    }, {
      "id": "1504",
      "pid": "113",
      "path": ",3,113,1504,",
      "level": "3",
      "name": "南迪",
      "name_en": "Nandi",
      "name_pinyin": "nandi",
      "code": "NAN",
      "childrens": []
    }, {
      "id": "1505",
      "pid": "113",
      "path": ",3,113,1505,",
      "level": "3",
      "name": "内罗毕",
      "name_en": "Nairobi",
      "name_pinyin": "neiluobi",
      "code": "NA",
      "childrens": []
    }, {
      "id": "1506",
      "pid": "113",
      "path": ",3,113,1506,",
      "level": "3",
      "name": "尼蒂",
      "name_en": "Nithi",
      "name_pinyin": "nidi",
      "code": "NIT",
      "childrens": []
    }, {
      "id": "1507",
      "pid": "113",
      "path": ",3,113,1507,",
      "level": "3",
      "name": "尼亚米拉",
      "name_en": "Nyamira",
      "name_pinyin": "niyamila",
      "code": "NYM",
      "childrens": []
    }, {
      "id": "1508",
      "pid": "113",
      "path": ",3,113,1508,",
      "level": "3",
      "name": "年达鲁阿",
      "name_en": "Nyandarua",
      "name_pinyin": "niandalua",
      "code": "NYN",
      "childrens": []
    }, {
      "id": "1509",
      "pid": "113",
      "path": ",3,113,1509,",
      "level": "3",
      "name": "涅里",
      "name_en": "Nyeri",
      "name_pinyin": "nieli",
      "code": "NYE",
      "childrens": []
    }, {
      "id": "1510",
      "pid": "113",
      "path": ",3,113,1510,",
      "level": "3",
      "name": "桑布卢",
      "name_en": "Samburu",
      "name_pinyin": "sangbulu",
      "code": "UAS",
      "childrens": []
    }, {
      "id": "1511",
      "pid": "113",
      "path": ",3,113,1511,",
      "level": "3",
      "name": "塔纳河",
      "name_en": "Tana River",
      "name_pinyin": "tanahe",
      "code": "TRI",
      "childrens": []
    }, {
      "id": "1512",
      "pid": "113",
      "path": ",3,113,1512,",
      "level": "3",
      "name": "泰塔塔维塔",
      "name_en": "Taita-Taveta",
      "name_pinyin": "taitataweita",
      "code": "TTA",
      "childrens": []
    }, {
      "id": "1513",
      "pid": "113",
      "path": ",3,113,1513,",
      "level": "3",
      "name": "特兰斯-恩佐亚",
      "name_en": "Trans-Nzoia",
      "name_pinyin": "telansienzuoya",
      "code": "TNZ",
      "childrens": []
    }, {
      "id": "1514",
      "pid": "113",
      "path": ",3,113,1514,",
      "level": "3",
      "name": "图尔卡纳",
      "name_en": "Turkana",
      "name_pinyin": "tuerkana",
      "code": "TUR",
      "childrens": []
    }, {
      "id": "1515",
      "pid": "113",
      "path": ",3,113,1515,",
      "level": "3",
      "name": "瓦吉尔",
      "name_en": "Wajir",
      "name_pinyin": "wajier",
      "code": "WJR",
      "childrens": []
    }, {
      "id": "1516",
      "pid": "113",
      "path": ",3,113,1516,",
      "level": "3",
      "name": "瓦辛基苏",
      "name_en": "Uasin Gishu",
      "name_pinyin": "waxinjisu",
      "code": "UGI",
      "childrens": []
    }, {
      "id": "1517",
      "pid": "113",
      "path": ",3,113,1517,",
      "level": "3",
      "name": "韦希加",
      "name_en": "Vihiga",
      "name_pinyin": "weixijia",
      "code": "VIH",
      "childrens": []
    }, {
      "id": "1518",
      "pid": "113",
      "path": ",3,113,1518,",
      "level": "3",
      "name": "西波克特",
      "name_en": "West Pokot",
      "name_pinyin": "xibokete",
      "code": "WPO",
      "childrens": []
    }, {
      "id": "1519",
      "pid": "113",
      "path": ",3,113,1519,",
      "level": "3",
      "name": "夏亚",
      "name_en": "Siaya",
      "name_pinyin": "xiaya",
      "code": "SIA",
      "childrens": []
    }, {
      "id": "1520",
      "pid": "113",
      "path": ",3,113,1520,",
      "level": "3",
      "name": "伊希约洛",
      "name_en": "Isiolo",
      "name_pinyin": "yixiyueluo",
      "code": "ISI",
      "childrens": []
    }, {
      "id": "1521",
      "pid": "113",
      "path": ",3,113,1521,",
      "level": "3",
      "name": "中央",
      "name_en": "Central",
      "name_pinyin": "zhongyang",
      "code": "CE",
      "childrens": []
    }]
  }, {
    "id": "116",
    "pid": "3",
    "path": ",3,116,",
    "level": "2",
    "name": "莱索托",
    "name_en": "Lesotho",
    "name_pinyin": "laisuotuo",
    "code": "LSO",
    "childrens": [{
      "id": "1548",
      "pid": "116",
      "path": ",3,116,1548,",
      "level": "3",
      "name": "伯里亚",
      "name_en": "Berea",
      "name_pinyin": "boliya",
      "code": "D",
      "childrens": []
    }, {
      "id": "1549",
      "pid": "116",
      "path": ",3,116,1549,",
      "level": "3",
      "name": "布塔布泰",
      "name_en": "Butha-Buthe",
      "name_pinyin": "butabutai",
      "code": "B",
      "childrens": []
    }, {
      "id": "1550",
      "pid": "116",
      "path": ",3,116,1550,",
      "level": "3",
      "name": "古廷",
      "name_en": "Quthing",
      "name_pinyin": "guting",
      "code": "G",
      "childrens": []
    }, {
      "id": "1551",
      "pid": "116",
      "path": ",3,116,1551,",
      "level": "3",
      "name": "加查斯内克",
      "name_en": "Qachas Nek",
      "name_pinyin": "jiachasineike",
      "code": "H",
      "childrens": []
    }, {
      "id": "1552",
      "pid": "116",
      "path": ",3,116,1552,",
      "level": "3",
      "name": "莱里贝",
      "name_en": "Leribe",
      "name_pinyin": "lailibei",
      "code": "C",
      "childrens": []
    }, {
      "id": "1553",
      "pid": "116",
      "path": ",3,116,1553,",
      "level": "3",
      "name": "马费滕",
      "name_en": "Mafeteng",
      "name_pinyin": "mafei",
      "code": "E",
      "childrens": []
    }, {
      "id": "1554",
      "pid": "116",
      "path": ",3,116,1554,",
      "level": "3",
      "name": "马塞卢",
      "name_en": "Maseru",
      "name_pinyin": "masailu",
      "code": "A",
      "childrens": []
    }, {
      "id": "1555",
      "pid": "116",
      "path": ",3,116,1555,",
      "level": "3",
      "name": "莫哈莱斯胡克",
      "name_en": "Mohales Hoek",
      "name_pinyin": "mohalaisihuke",
      "code": "F",
      "childrens": []
    }, {
      "id": "1556",
      "pid": "116",
      "path": ",3,116,1556,",
      "level": "3",
      "name": "莫霍特隆",
      "name_en": "Mokhotlong",
      "name_pinyin": "mohuotelong",
      "code": "J",
      "childrens": []
    }, {
      "id": "1557",
      "pid": "116",
      "path": ",3,116,1557,",
      "level": "3",
      "name": "塔巴采卡",
      "name_en": "Thaba-Tseka",
      "name_pinyin": "tabacaika",
      "code": "K",
      "childrens": []
    }]
  }, {
    "id": "119",
    "pid": "3",
    "path": ",3,119,",
    "level": "2",
    "name": "利比里亚",
    "name_en": "Liberia",
    "name_pinyin": "libiliya",
    "code": "LBR",
    "childrens": [{
      "id": "1581",
      "pid": "119",
      "path": ",3,119,1581,",
      "level": "3",
      "name": "巴波卢",
      "name_en": "Gbarpolu",
      "name_pinyin": "babolu",
      "code": "GBA",
      "childrens": []
    }, {
      "id": "1582",
      "pid": "119",
      "path": ",3,119,1582,",
      "level": "3",
      "name": "邦",
      "name_en": "Bong",
      "name_pinyin": "bang",
      "code": "BG",
      "childrens": []
    }, {
      "id": "1583",
      "pid": "119",
      "path": ",3,119,1583,",
      "level": "3",
      "name": "博波卢",
      "name_en": "Bopolu",
      "name_pinyin": "bobolu",
      "code": "BOP",
      "childrens": []
    }, {
      "id": "1584",
      "pid": "119",
      "path": ",3,119,1584,",
      "level": "3",
      "name": "博米",
      "name_en": "Bomi",
      "name_pinyin": "bomi",
      "code": "BM",
      "childrens": []
    }, {
      "id": "1585",
      "pid": "119",
      "path": ",3,119,1585,",
      "level": "3",
      "name": "大巴萨",
      "name_en": "Grand Bassa",
      "name_pinyin": "dabasa",
      "code": "GB",
      "childrens": []
    }, {
      "id": "1586",
      "pid": "119",
      "path": ",3,119,1586,",
      "level": "3",
      "name": "大吉德",
      "name_en": "Grand Gedeh",
      "name_pinyin": "dajide",
      "code": "GG",
      "childrens": []
    }, {
      "id": "1587",
      "pid": "119",
      "path": ",3,119,1587,",
      "level": "3",
      "name": "大角山",
      "name_en": "Grand Cape Mount",
      "name_pinyin": "dajiaoshan",
      "code": "CM",
      "childrens": []
    }, {
      "id": "1588",
      "pid": "119",
      "path": ",3,119,1588,",
      "level": "3",
      "name": "大克鲁",
      "name_en": "Grand Kru",
      "name_pinyin": "dakelu",
      "code": "GK",
      "childrens": []
    }, {
      "id": "1589",
      "pid": "119",
      "path": ",3,119,1589,",
      "level": "3",
      "name": "菲什敦",
      "name_en": "Fish Town",
      "name_pinyin": "feishidun",
      "code": "FT",
      "childrens": []
    }, {
      "id": "1590",
      "pid": "119",
      "path": ",3,119,1590,",
      "level": "3",
      "name": "吉河",
      "name_en": "River Gee",
      "name_pinyin": "jihe",
      "code": "RG",
      "childrens": []
    }, {
      "id": "1591",
      "pid": "119",
      "path": ",3,119,1591,",
      "level": "3",
      "name": "里弗塞斯",
      "name_en": "River Cess",
      "name_pinyin": "lifusaisi",
      "code": "RI",
      "childrens": []
    }, {
      "id": "1592",
      "pid": "119",
      "path": ",3,119,1592,",
      "level": "3",
      "name": "洛法",
      "name_en": "Lofa",
      "name_pinyin": "luofa",
      "code": "LO",
      "childrens": []
    }, {
      "id": "1593",
      "pid": "119",
      "path": ",3,119,1593,",
      "level": "3",
      "name": "马吉比",
      "name_en": "Margibi",
      "name_pinyin": "majibi",
      "code": "MG",
      "childrens": []
    }, {
      "id": "1594",
      "pid": "119",
      "path": ",3,119,1594,",
      "level": "3",
      "name": "马里兰",
      "name_en": "Maryland",
      "name_pinyin": "malilan",
      "code": "MY",
      "childrens": []
    }, {
      "id": "1595",
      "pid": "119",
      "path": ",3,119,1595,",
      "level": "3",
      "name": "蒙特塞拉多",
      "name_en": "Montserrado",
      "name_pinyin": "mengtesailaduo",
      "code": "MO",
      "childrens": []
    }, {
      "id": "1596",
      "pid": "119",
      "path": ",3,119,1596,",
      "level": "3",
      "name": "宁巴",
      "name_en": "Nimba",
      "name_pinyin": "ningba",
      "code": "NI",
      "childrens": []
    }, {
      "id": "1597",
      "pid": "119",
      "path": ",3,119,1597,",
      "level": "3",
      "name": "锡诺",
      "name_en": "Sinoe",
      "name_pinyin": "xinuo",
      "code": "SI",
      "childrens": []
    }]
  }, {
    "id": "120",
    "pid": "3",
    "path": ",3,120,",
    "level": "2",
    "name": "利比亚",
    "name_en": "Libya",
    "name_pinyin": "libiya",
    "code": "LBY",
    "childrens": []
  }, {
    "id": "125",
    "pid": "3",
    "path": ",3,125,",
    "level": "2",
    "name": "卢旺达",
    "name_en": "Rwanda",
    "name_pinyin": "luwangda",
    "code": "RWA",
    "childrens": [{
      "id": "1612",
      "pid": "125",
      "path": ",3,125,1612,",
      "level": "3",
      "name": "比温巴",
      "name_en": "Byumba",
      "name_pinyin": "biwenba",
      "code": "BY",
      "childrens": []
    }, {
      "id": "1613",
      "pid": "125",
      "path": ",3,125,1613,",
      "level": "3",
      "name": "布塔雷",
      "name_en": "Butare",
      "name_pinyin": "butalei",
      "code": "BU",
      "childrens": []
    }, {
      "id": "1614",
      "pid": "125",
      "path": ",3,125,1614,",
      "level": "3",
      "name": "恩延扎",
      "name_en": "Nyanza",
      "name_pinyin": "enyanzha",
      "code": "NY",
      "childrens": []
    }, {
      "id": "1615",
      "pid": "125",
      "path": ",3,125,1615,",
      "level": "3",
      "name": "基本古",
      "name_en": "Kibungo",
      "name_pinyin": "jibengu",
      "code": "KG",
      "childrens": []
    }, {
      "id": "1616",
      "pid": "125",
      "path": ",3,125,1616,",
      "level": "3",
      "name": "基布耶",
      "name_en": "Kibuye",
      "name_pinyin": "jibuye",
      "code": "KY",
      "childrens": []
    }, {
      "id": "1617",
      "pid": "125",
      "path": ",3,125,1617,",
      "level": "3",
      "name": "基加利-恩加利",
      "name_en": "Kigali-Ngali",
      "name_pinyin": "jijialienjiali",
      "code": "KR",
      "childrens": []
    }, {
      "id": "1618",
      "pid": "125",
      "path": ",3,125,1618,",
      "level": "3",
      "name": "基加利市",
      "name_en": "Kigali-Ville",
      "name_pinyin": "jijialishi",
      "code": "KV",
      "childrens": []
    }, {
      "id": "1619",
      "pid": "125",
      "path": ",3,125,1619,",
      "level": "3",
      "name": "吉孔戈罗",
      "name_en": "Gikongoro",
      "name_pinyin": "jikonggeluo",
      "code": "GK",
      "childrens": []
    }, {
      "id": "1620",
      "pid": "125",
      "path": ",3,125,1620,",
      "level": "3",
      "name": "吉塞尼",
      "name_en": "Gisenyi",
      "name_pinyin": "jisaini",
      "code": "GS",
      "childrens": []
    }, {
      "id": "1621",
      "pid": "125",
      "path": ",3,125,1621,",
      "level": "3",
      "name": "吉塔拉马",
      "name_en": "Gitarama",
      "name_pinyin": "jitalama",
      "code": "GT",
      "childrens": []
    }, {
      "id": "1622",
      "pid": "125",
      "path": ",3,125,1622,",
      "level": "3",
      "name": "卡布加",
      "name_en": "Kabuga",
      "name_pinyin": "kabujia",
      "code": "KA",
      "childrens": []
    }, {
      "id": "1623",
      "pid": "125",
      "path": ",3,125,1623,",
      "level": "3",
      "name": "卢瓦马加纳",
      "name_en": "Rwamagana",
      "name_pinyin": "luwamajiana",
      "code": "RW",
      "childrens": []
    }, {
      "id": "1624",
      "pid": "125",
      "path": ",3,125,1624,",
      "level": "3",
      "name": "鲁汉戈",
      "name_en": "Ruhango",
      "name_pinyin": "luhange",
      "code": "RH",
      "childrens": []
    }, {
      "id": "1625",
      "pid": "125",
      "path": ",3,125,1625,",
      "level": "3",
      "name": "鲁亨盖里",
      "name_en": "Ruhengeri",
      "name_pinyin": "luhenggaili",
      "code": "RU",
      "childrens": []
    }, {
      "id": "1626",
      "pid": "125",
      "path": ",3,125,1626,",
      "level": "3",
      "name": "尚古古",
      "name_en": "Cyangugu",
      "name_pinyin": "shanggugu",
      "code": "CY",
      "childrens": []
    }, {
      "id": "1627",
      "pid": "125",
      "path": ",3,125,1627,",
      "level": "3",
      "name": "乌姆塔拉",
      "name_en": "Umutara",
      "name_pinyin": "wumutala",
      "code": "UM",
      "childrens": []
    }]
  }, {
    "id": "127",
    "pid": "3",
    "path": ",3,127,",
    "level": "2",
    "name": "马达加斯加",
    "name_en": "Madagascar",
    "name_pinyin": "madajiasijia",
    "code": "MDG",
    "childrens": [{
      "id": "1669",
      "pid": "127",
      "path": ",3,127,1669,",
      "level": "3",
      "name": "安齐拉纳纳",
      "name_en": "Antsiranana",
      "name_pinyin": "anqilanana",
      "code": "AS",
      "childrens": []
    }, {
      "id": "1670",
      "pid": "127",
      "path": ",3,127,1670,",
      "level": "3",
      "name": "菲亚纳兰楚阿",
      "name_en": "Fianarantsoa",
      "name_pinyin": "feiyanalanchua",
      "code": "FN",
      "childrens": []
    }, {
      "id": "1671",
      "pid": "127",
      "path": ",3,127,1671,",
      "level": "3",
      "name": "马哈赞加",
      "name_en": "Mahajanga",
      "name_pinyin": "mahazanjia",
      "code": "MJ",
      "childrens": []
    }, {
      "id": "1672",
      "pid": "127",
      "path": ",3,127,1672,",
      "level": "3",
      "name": "塔那那利佛",
      "name_en": "Antananarivo",
      "name_pinyin": "tananalifo",
      "code": "AN",
      "childrens": []
    }, {
      "id": "1673",
      "pid": "127",
      "path": ",3,127,1673,",
      "level": "3",
      "name": "图阿马西拉",
      "name_en": "Toamasina",
      "name_pinyin": "tuamaxila",
      "code": "TM",
      "childrens": []
    }, {
      "id": "1674",
      "pid": "127",
      "path": ",3,127,1674,",
      "level": "3",
      "name": "图利亚拉",
      "name_en": "Toliary",
      "name_pinyin": "tuliyala",
      "code": "TL",
      "childrens": []
    }]
  }, {
    "id": "130",
    "pid": "3",
    "path": ",3,130,",
    "level": "2",
    "name": "马拉维",
    "name_en": "Malawi",
    "name_pinyin": "malawei",
    "code": "MWI",
    "childrens": [{
      "id": "1696",
      "pid": "130",
      "path": ",3,130,1696,",
      "level": "3",
      "name": "北部区",
      "name_en": "Northern",
      "name_pinyin": "beibuqu",
      "code": "N",
      "childrens": []
    }, {
      "id": "1697",
      "pid": "130",
      "path": ",3,130,1697,",
      "level": "3",
      "name": "南部区",
      "name_en": "Southern",
      "name_pinyin": "nanbuqu",
      "code": "S",
      "childrens": []
    }, {
      "id": "1698",
      "pid": "130",
      "path": ",3,130,1698,",
      "level": "3",
      "name": "中央区",
      "name_en": "Central",
      "name_pinyin": "zhongyangqu",
      "code": "C",
      "childrens": []
    }]
  }, {
    "id": "132",
    "pid": "3",
    "path": ",3,132,",
    "level": "2",
    "name": "马里",
    "name_en": "Mali",
    "name_pinyin": "mali",
    "code": "MLI",
    "childrens": [{
      "id": "1699",
      "pid": "132",
      "path": ",3,132,1699,",
      "level": "3",
      "name": "巴马科首都区",
      "name_en": "Bamako",
      "name_pinyin": "bamakeshouduqu",
      "code": "CD",
      "childrens": []
    }, {
      "id": "1700",
      "pid": "132",
      "path": ",3,132,1700,",
      "level": "3",
      "name": "基达尔",
      "name_en": "Kidal",
      "name_pinyin": "jidaer",
      "code": "KD",
      "childrens": []
    }, {
      "id": "1701",
      "pid": "132",
      "path": ",3,132,1701,",
      "level": "3",
      "name": "加奥",
      "name_en": "Gao",
      "name_pinyin": "jiaao",
      "code": "GA",
      "childrens": []
    }, {
      "id": "1702",
      "pid": "132",
      "path": ",3,132,1702,",
      "level": "3",
      "name": "卡伊",
      "name_en": "Kayes",
      "name_pinyin": "kayi",
      "code": "KY",
      "childrens": []
    }, {
      "id": "1703",
      "pid": "132",
      "path": ",3,132,1703,",
      "level": "3",
      "name": "库利科罗",
      "name_en": "Koulikoro",
      "name_pinyin": "kulikeluo",
      "code": "KL",
      "childrens": []
    }, {
      "id": "1704",
      "pid": "132",
      "path": ",3,132,1704,",
      "level": "3",
      "name": "莫普提",
      "name_en": "Mopti",
      "name_pinyin": "moputi",
      "code": "MP",
      "childrens": []
    }, {
      "id": "1705",
      "pid": "132",
      "path": ",3,132,1705,",
      "level": "3",
      "name": "塞古",
      "name_en": "Segou",
      "name_pinyin": "saigu",
      "code": "SG",
      "childrens": []
    }, {
      "id": "1706",
      "pid": "132",
      "path": ",3,132,1706,",
      "level": "3",
      "name": "通布图",
      "name_en": "Tombouctou",
      "name_pinyin": "tongbutu",
      "code": "TB",
      "childrens": []
    }, {
      "id": "1707",
      "pid": "132",
      "path": ",3,132,1707,",
      "level": "3",
      "name": "锡卡索",
      "name_en": "Sikasso",
      "name_pinyin": "xikasuo",
      "code": "SK",
      "childrens": []
    }]
  }, {
    "id": "136",
    "pid": "3",
    "path": ",3,136,",
    "level": "2",
    "name": "马约特岛",
    "name_en": "Mayotte",
    "name_pinyin": "mayuetedao",
    "code": "MYT",
    "childrens": []
  }, {
    "id": "138",
    "pid": "3",
    "path": ",3,138,",
    "level": "2",
    "name": "毛里求斯",
    "name_en": "Mauritius",
    "name_pinyin": "maoliqiusi",
    "code": "MUS",
    "childrens": []
  }, {
    "id": "139",
    "pid": "3",
    "path": ",3,139,",
    "level": "2",
    "name": "毛里塔尼亚",
    "name_en": "Mauritania",
    "name_pinyin": "maolitaniya",
    "code": "MRT",
    "childrens": [{
      "id": "1708",
      "pid": "139",
      "path": ",3,139,1708,",
      "level": "3",
      "name": "阿德拉尔",
      "name_en": "Adrar",
      "name_pinyin": "adelaer",
      "code": "AD",
      "childrens": []
    }, {
      "id": "1709",
      "pid": "139",
      "path": ",3,139,1709,",
      "level": "3",
      "name": "阿萨巴",
      "name_en": "El-Acaba",
      "name_pinyin": "asaba",
      "code": "AS",
      "childrens": []
    }, {
      "id": "1710",
      "pid": "139",
      "path": ",3,139,1710,",
      "level": "3",
      "name": "卜拉克纳",
      "name_en": "Brakna",
      "name_pinyin": "bolakena",
      "code": "BR",
      "childrens": []
    }, {
      "id": "1711",
      "pid": "139",
      "path": ",3,139,1711,",
      "level": "3",
      "name": "东胡德",
      "name_en": "Hodh el-Gharbi",
      "name_pinyin": "donghude",
      "code": "HG",
      "childrens": []
    }, {
      "id": "1712",
      "pid": "139",
      "path": ",3,139,1712,",
      "level": "3",
      "name": "戈尔戈勒",
      "name_en": "Gorgol",
      "name_pinyin": "geergele",
      "code": "GO",
      "childrens": []
    }, {
      "id": "1713",
      "pid": "139",
      "path": ",3,139,1713,",
      "level": "3",
      "name": "吉迪马卡",
      "name_en": "Guidimaka",
      "name_pinyin": "jidimaka",
      "code": "GM",
      "childrens": []
    }, {
      "id": "1714",
      "pid": "139",
      "path": ",3,139,1714,",
      "level": "3",
      "name": "努瓦迪布湾",
      "name_en": "Dakhlet Nouadhibou",
      "name_pinyin": "nuwadibuwan",
      "code": "DN",
      "childrens": []
    }, {
      "id": "1715",
      "pid": "139",
      "path": ",3,139,1715,",
      "level": "3",
      "name": "努瓦克肖特特区",
      "name_en": "Nouakchott",
      "name_pinyin": "nuwakexiaotetequ",
      "code": "NO",
      "childrens": []
    }, {
      "id": "1716",
      "pid": "139",
      "path": ",3,139,1716,",
      "level": "3",
      "name": "塔甘特",
      "name_en": "Tagant",
      "name_pinyin": "tagante",
      "code": "TA",
      "childrens": []
    }, {
      "id": "1717",
      "pid": "139",
      "path": ",3,139,1717,",
      "level": "3",
      "name": "特拉扎",
      "name_en": "Trarza",
      "name_pinyin": "telazha",
      "code": "TR",
      "childrens": []
    }, {
      "id": "1718",
      "pid": "139",
      "path": ",3,139,1718,",
      "level": "3",
      "name": "提里斯-宰穆尔",
      "name_en": "Tiris Zemmour",
      "name_pinyin": "tilisizaimuer",
      "code": "TZ",
      "childrens": []
    }, {
      "id": "1719",
      "pid": "139",
      "path": ",3,139,1719,",
      "level": "3",
      "name": "西胡德",
      "name_en": "Hodh ech-Chargui",
      "name_pinyin": "xihude",
      "code": "HC",
      "childrens": []
    }, {
      "id": "1720",
      "pid": "139",
      "path": ",3,139,1720,",
      "level": "3",
      "name": "因希里",
      "name_en": "Inchiri",
      "name_pinyin": "yinxili",
      "code": "IN",
      "childrens": []
    }]
  }, {
    "id": "150",
    "pid": "3",
    "path": ",3,150,",
    "level": "2",
    "name": "摩洛哥",
    "name_en": "Morocco",
    "name_pinyin": "moluoge",
    "code": "MAR",
    "childrens": [{
      "id": "1802",
      "pid": "150",
      "path": ",3,150,1802,",
      "level": "3",
      "name": "丹吉尔",
      "name_en": "Tangier",
      "name_pinyin": "danjier",
      "code": "TGR",
      "childrens": []
    }, {
      "id": "1803",
      "pid": "150",
      "path": ",3,150,1803,",
      "level": "3",
      "name": "得土安",
      "name_en": "Tetouan",
      "name_pinyin": "detuan",
      "code": "TET",
      "childrens": []
    }, {
      "id": "1804",
      "pid": "150",
      "path": ",3,150,1804,",
      "level": "3",
      "name": "非斯",
      "name_en": "Fes",
      "name_pinyin": "feisi",
      "code": "FES",
      "childrens": []
    }, {
      "id": "1805",
      "pid": "150",
      "path": ",3,150,1805,",
      "level": "3",
      "name": "卡萨布兰卡",
      "name_en": "Casablanca",
      "name_pinyin": "kasabulanka",
      "code": "CBL",
      "childrens": []
    }, {
      "id": "1806",
      "pid": "150",
      "path": ",3,150,1806,",
      "level": "3",
      "name": "拉巴特",
      "name_en": "Rabat",
      "name_pinyin": "labate",
      "code": "RSA",
      "childrens": []
    }, {
      "id": "1807",
      "pid": "150",
      "path": ",3,150,1807,",
      "level": "3",
      "name": "马拉喀什",
      "name_en": "Marrakech",
      "name_pinyin": "malakashi",
      "code": "MRK",
      "childrens": []
    }, {
      "id": "1808",
      "pid": "150",
      "path": ",3,150,1808,",
      "level": "3",
      "name": "梅克内斯",
      "name_en": "Meknes",
      "name_pinyin": "meikeneisi",
      "code": "MKN",
      "childrens": []
    }, {
      "id": "1809",
      "pid": "150",
      "path": ",3,150,1809,",
      "level": "3",
      "name": "乌季达",
      "name_en": "Oujda",
      "name_pinyin": "wujida",
      "code": "OUJ",
      "childrens": []
    }, {
      "id": "1810",
      "pid": "150",
      "path": ",3,150,1810,",
      "level": "3",
      "name": "西撒哈拉",
      "name_en": "Western Sahara",
      "name_pinyin": "xisahala",
      "code": "WSH",
      "childrens": []
    }]
  }, {
    "id": "152",
    "pid": "3",
    "path": ",3,152,",
    "level": "2",
    "name": "莫桑比克",
    "name_en": "Mozambique",
    "name_pinyin": "mosangbike",
    "code": "MOZ",
    "childrens": []
  }, {
    "id": "154",
    "pid": "3",
    "path": ",3,154,",
    "level": "2",
    "name": "纳米比亚",
    "name_en": "Namibia",
    "name_pinyin": "namibiya",
    "code": "NAM",
    "childrens": [{
      "id": "1871",
      "pid": "154",
      "path": ",3,154,1871,",
      "level": "3",
      "name": "埃龙戈",
      "name_en": "Erongo",
      "name_pinyin": "ailongge",
      "code": "ER",
      "childrens": []
    }, {
      "id": "1872",
      "pid": "154",
      "path": ",3,154,1872,",
      "level": "3",
      "name": "奥汉圭纳",
      "name_en": "Ohangwena",
      "name_pinyin": "aohanguina",
      "code": "OW",
      "childrens": []
    }, {
      "id": "1873",
      "pid": "154",
      "path": ",3,154,1873,",
      "level": "3",
      "name": "奥卡万戈",
      "name_en": "Okavango",
      "name_pinyin": "aokawange",
      "code": "KV",
      "childrens": []
    }, {
      "id": "1874",
      "pid": "154",
      "path": ",3,154,1874,",
      "level": "3",
      "name": "奥马赫科",
      "name_en": "Omaheke",
      "name_pinyin": "aomaheke",
      "code": "OK",
      "childrens": []
    }, {
      "id": "1875",
      "pid": "154",
      "path": ",3,154,1875,",
      "level": "3",
      "name": "奥姆沙蒂",
      "name_en": "Omusati",
      "name_pinyin": "aomushadi",
      "code": "OT",
      "childrens": []
    }, {
      "id": "1876",
      "pid": "154",
      "path": ",3,154,1876,",
      "level": "3",
      "name": "奥乔宗蒂约巴",
      "name_en": "Otjozondjupa",
      "name_pinyin": "aoqiaozongdiyueba",
      "code": "OJ",
      "childrens": []
    }, {
      "id": "1877",
      "pid": "154",
      "path": ",3,154,1877,",
      "level": "3",
      "name": "奥沙纳",
      "name_en": "Oshana",
      "name_pinyin": "aoshana",
      "code": "ON",
      "childrens": []
    }, {
      "id": "1878",
      "pid": "154",
      "path": ",3,154,1878,",
      "level": "3",
      "name": "奥希科托",
      "name_en": "Oshikoto",
      "name_pinyin": "aoxiketuo",
      "code": "OO",
      "childrens": []
    }, {
      "id": "1879",
      "pid": "154",
      "path": ",3,154,1879,",
      "level": "3",
      "name": "哈达普",
      "name_en": "Hardap",
      "name_pinyin": "hadapu",
      "code": "HA",
      "childrens": []
    }, {
      "id": "1880",
      "pid": "154",
      "path": ",3,154,1880,",
      "level": "3",
      "name": "霍马斯",
      "name_en": "Khomas",
      "name_pinyin": "huomasi",
      "code": "KH",
      "childrens": []
    }, {
      "id": "1881",
      "pid": "154",
      "path": ",3,154,1881,",
      "level": "3",
      "name": "卡拉斯",
      "name_en": "Karas",
      "name_pinyin": "kalasi",
      "code": "KR",
      "childrens": []
    }, {
      "id": "1882",
      "pid": "154",
      "path": ",3,154,1882,",
      "level": "3",
      "name": "卡普里维",
      "name_en": "Caprivi",
      "name_pinyin": "kapuliwei",
      "code": "CA",
      "childrens": []
    }, {
      "id": "1883",
      "pid": "154",
      "path": ",3,154,1883,",
      "level": "3",
      "name": "库内内",
      "name_en": "Kunene",
      "name_pinyin": "kuneinei",
      "code": "KU",
      "childrens": []
    }]
  }, {
    "id": "155",
    "pid": "3",
    "path": ",3,155,",
    "level": "2",
    "name": "南非",
    "name_en": "South Africa",
    "name_pinyin": "nanfei",
    "code": "ZAF",
    "childrens": [{
      "id": "1884",
      "pid": "155",
      "path": ",3,155,1884,",
      "level": "3",
      "name": "阿平顿",
      "name_en": "Upington",
      "name_pinyin": "apingdun",
      "code": "UTN",
      "childrens": []
    }, {
      "id": "1885",
      "pid": "155",
      "path": ",3,155,1885,",
      "level": "3",
      "name": "艾利弗山",
      "name_en": "Mount Ayliff",
      "name_pinyin": "ailifushan",
      "code": "MAY",
      "childrens": []
    }, {
      "id": "1886",
      "pid": "155",
      "path": ",3,155,1886,",
      "level": "3",
      "name": "彼德马里茨堡",
      "name_en": "Pietermaritzburg",
      "name_pinyin": "bidemalicibao",
      "code": "PZB",
      "childrens": []
    }, {
      "id": "1887",
      "pid": "155",
      "path": ",3,155,1887,",
      "level": "3",
      "name": "彼德斯堡",
      "name_en": "Pietersburg",
      "name_pinyin": "bidesibao",
      "code": "PTG",
      "childrens": []
    }, {
      "id": "1888",
      "pid": "155",
      "path": ",3,155,1888,",
      "level": "3",
      "name": "比勒陀利亚",
      "name_en": "Pretoria",
      "name_pinyin": "biletuoliya",
      "code": "PRY",
      "childrens": []
    }, {
      "id": "1889",
      "pid": "155",
      "path": ",3,155,1889,",
      "level": "3",
      "name": "比索",
      "name_en": "Bisho",
      "name_pinyin": "bisuo",
      "code": "BIY",
      "childrens": []
    }, {
      "id": "1890",
      "pid": "155",
      "path": ",3,155,1890,",
      "level": "3",
      "name": "布雷达斯多普",
      "name_en": "Bredasdorp",
      "name_pinyin": "buleidasiduopu",
      "code": "BDD",
      "childrens": []
    }, {
      "id": "1891",
      "pid": "155",
      "path": ",3,155,1891,",
      "level": "3",
      "name": "布隆方丹",
      "name_en": "Bloemfontein",
      "name_pinyin": "bulongfangdan",
      "code": "BFN",
      "childrens": []
    }, {
      "id": "1892",
      "pid": "155",
      "path": ",3,155,1892,",
      "level": "3",
      "name": "布隆克斯特斯普利特",
      "name_en": "Bronkhorstspruit",
      "name_pinyin": "bulongkesitesipulite",
      "code": "BHT",
      "childrens": []
    }, {
      "id": "1893",
      "pid": "155",
      "path": ",3,155,1893,",
      "level": "3",
      "name": "德阿尔",
      "name_en": "De Aar",
      "name_pinyin": "deaer",
      "code": "DAA",
      "childrens": []
    }, {
      "id": "1894",
      "pid": "155",
      "path": ",3,155,1894,",
      "level": "3",
      "name": "德班",
      "name_en": "Durban",
      "name_pinyin": "deban",
      "code": "DUR",
      "childrens": []
    }, {
      "id": "1895",
      "pid": "155",
      "path": ",3,155,1895,",
      "level": "3",
      "name": "邓迪",
      "name_en": "Dundee",
      "name_pinyin": "dengdi",
      "code": "DUN",
      "childrens": []
    }, {
      "id": "1896",
      "pid": "155",
      "path": ",3,155,1896,",
      "level": "3",
      "name": "东巴克利",
      "name_en": "Barkley East",
      "name_pinyin": "dongbakeli",
      "code": "BAE",
      "childrens": []
    }, {
      "id": "1897",
      "pid": "155",
      "path": ",3,155,1897,",
      "level": "3",
      "name": "东伦敦",
      "name_en": "East London",
      "name_pinyin": "donglundun",
      "code": "ELS",
      "childrens": []
    }, {
      "id": "1898",
      "pid": "155",
      "path": ",3,155,1898,",
      "level": "3",
      "name": "弗雷堡",
      "name_en": "Vryburg",
      "name_pinyin": "fuleibao",
      "code": "VRU",
      "childrens": []
    }, {
      "id": "1899",
      "pid": "155",
      "path": ",3,155,1899,",
      "level": "3",
      "name": "弗里尼欣",
      "name_en": "Vereeniging",
      "name_pinyin": "fulinixin",
      "code": "VGG",
      "childrens": []
    }, {
      "id": "1900",
      "pid": "155",
      "path": ",3,155,1900,",
      "level": "3",
      "name": "格罗布莱斯达尔",
      "name_en": "Groblersdal",
      "name_pinyin": "geluobulaisidaer",
      "code": "GBD",
      "childrens": []
    }, {
      "id": "1901",
      "pid": "155",
      "path": ",3,155,1901,",
      "level": "3",
      "name": "基雅尼",
      "name_en": "Giyani",
      "name_pinyin": "jiyani",
      "code": "GIY",
      "childrens": []
    }, {
      "id": "1902",
      "pid": "155",
      "path": ",3,155,1902,",
      "level": "3",
      "name": "金伯利",
      "name_en": "Kimberley",
      "name_pinyin": "jinboli",
      "code": "KIM",
      "childrens": []
    }, {
      "id": "1903",
      "pid": "155",
      "path": ",3,155,1903,",
      "level": "3",
      "name": "开普敦",
      "name_en": "Cape Town",
      "name_pinyin": "kaipudun",
      "code": "CPT",
      "childrens": []
    }, {
      "id": "1904",
      "pid": "155",
      "path": ",3,155,1904,",
      "level": "3",
      "name": "克莱克斯多普",
      "name_en": "Klerksdorp",
      "name_pinyin": "kelaikesiduopu",
      "code": "KXE",
      "childrens": []
    }, {
      "id": "1905",
      "pid": "155",
      "path": ",3,155,1905,",
      "level": "3",
      "name": "库鲁曼",
      "name_en": "Kuruman",
      "name_pinyin": "kuluman",
      "code": "KMH",
      "childrens": []
    }, {
      "id": "1906",
      "pid": "155",
      "path": ",3,155,1906,",
      "level": "3",
      "name": "昆士敦",
      "name_en": "Queenstown",
      "name_pinyin": "kunshidun",
      "code": "UTW",
      "childrens": []
    }, {
      "id": "1907",
      "pid": "155",
      "path": ",3,155,1907,",
      "level": "3",
      "name": "莱迪史密斯",
      "name_en": "Ladysmith",
      "name_pinyin": "laidishimisi",
      "code": "LAY",
      "childrens": []
    }, {
      "id": "1908",
      "pid": "155",
      "path": ",3,155,1908,",
      "level": "3",
      "name": "兰德方丹",
      "name_en": "Randfontein",
      "name_pinyin": "landefangdan",
      "code": "RFT",
      "childrens": []
    }, {
      "id": "1909",
      "pid": "155",
      "path": ",3,155,1909,",
      "level": "3",
      "name": "理查兹湾",
      "name_en": "Richards Bay",
      "name_pinyin": "lichaziwan",
      "code": "RCB",
      "childrens": []
    }, {
      "id": "1910",
      "pid": "155",
      "path": ",3,155,1910,",
      "level": "3",
      "name": "利斯滕堡",
      "name_en": "Rustenburg",
      "name_pinyin": "lisibao",
      "code": "RSB",
      "childrens": []
    }, {
      "id": "1911",
      "pid": "155",
      "path": ",3,155,1911,",
      "level": "3",
      "name": "米德尔堡",
      "name_en": "Middelburg",
      "name_pinyin": "mideerbao",
      "code": "MDB",
      "childrens": []
    }, {
      "id": "1912",
      "pid": "155",
      "path": ",3,155,1912,",
      "level": "3",
      "name": "姆库泽",
      "name_en": "Mkuze",
      "name_pinyin": "mukuze",
      "code": "MZQ",
      "childrens": []
    }, {
      "id": "1913",
      "pid": "155",
      "path": ",3,155,1913,",
      "level": "3",
      "name": "穆里斯堡",
      "name_en": "Moorreesburg",
      "name_pinyin": "mulisibao",
      "code": "MOO",
      "childrens": []
    }, {
      "id": "1914",
      "pid": "155",
      "path": ",3,155,1914,",
      "level": "3",
      "name": "内尔斯普雷特",
      "name_en": "Nelspruit",
      "name_pinyin": "neiersipuleite",
      "code": "NLP",
      "childrens": []
    }, {
      "id": "1915",
      "pid": "155",
      "path": ",3,155,1915,",
      "level": "3",
      "name": "尼尔斯特隆",
      "name_en": "Nylstroom",
      "name_pinyin": "niersitelong",
      "code": "NYL",
      "childrens": []
    }, {
      "id": "1916",
      "pid": "155",
      "path": ",3,155,1916,",
      "level": "3",
      "name": "纽卡斯尔",
      "name_en": "Newcastle",
      "name_pinyin": "niukasier",
      "code": "NCS",
      "childrens": []
    }, {
      "id": "1917",
      "pid": "155",
      "path": ",3,155,1917,",
      "level": "3",
      "name": "乔治",
      "name_en": "George",
      "name_pinyin": "qiaozhi",
      "code": "GRJ",
      "childrens": []
    }, {
      "id": "1918",
      "pid": "155",
      "path": ",3,155,1918,",
      "level": "3",
      "name": "萨索尔堡",
      "name_en": "Sasolburg",
      "name_pinyin": "sasuoerbao",
      "code": "SAS",
      "childrens": []
    }, {
      "id": "1919",
      "pid": "155",
      "path": ",3,155,1919,",
      "level": "3",
      "name": "瑟孔达",
      "name_en": "Secunda",
      "name_pinyin": "sekongda",
      "code": "ZEC",
      "childrens": []
    }, {
      "id": "1920",
      "pid": "155",
      "path": ",3,155,1920,",
      "level": "3",
      "name": "特克索波",
      "name_en": "Ixopo",
      "name_pinyin": "tekesuobo",
      "code": "IXO",
      "childrens": []
    }, {
      "id": "1921",
      "pid": "155",
      "path": ",3,155,1921,",
      "level": "3",
      "name": "特隆普斯堡",
      "name_en": "Trompsburg",
      "name_pinyin": "telongpusibao",
      "code": "TPB",
      "childrens": []
    }, {
      "id": "1922",
      "pid": "155",
      "path": ",3,155,1922,",
      "level": "3",
      "name": "跳羚",
      "name_en": "Springbok",
      "name_pinyin": "tiaoling",
      "code": "SBU",
      "childrens": []
    }, {
      "id": "1923",
      "pid": "155",
      "path": ",3,155,1923,",
      "level": "3",
      "name": "图拉马哈谢",
      "name_en": "Thulamahashe",
      "name_pinyin": "tulamahaxie",
      "code": "TLH",
      "childrens": []
    }, {
      "id": "1924",
      "pid": "155",
      "path": ",3,155,1924,",
      "level": "3",
      "name": "托霍延杜",
      "name_en": "Thohoyandou",
      "name_pinyin": "tuohuoyandu",
      "code": "THY",
      "childrens": []
    }, {
      "id": "1925",
      "pid": "155",
      "path": ",3,155,1925,",
      "level": "3",
      "name": "韦茨肖克",
      "name_en": "Witsieshoek",
      "name_pinyin": "weicixiaoke",
      "code": "WSH",
      "childrens": []
    }, {
      "id": "1926",
      "pid": "155",
      "path": ",3,155,1926,",
      "level": "3",
      "name": "韦尔科姆",
      "name_en": "Welkom",
      "name_pinyin": "weierkemu",
      "code": "WEL",
      "childrens": []
    }, {
      "id": "1927",
      "pid": "155",
      "path": ",3,155,1927,",
      "level": "3",
      "name": "乌伦迪",
      "name_en": "Ulundi",
      "name_pinyin": "wulundi",
      "code": "ULD",
      "childrens": []
    }, {
      "id": "1928",
      "pid": "155",
      "path": ",3,155,1928,",
      "level": "3",
      "name": "乌姆塔塔",
      "name_en": "Umtata",
      "name_pinyin": "wumutata",
      "code": "UTT",
      "childrens": []
    }, {
      "id": "1929",
      "pid": "155",
      "path": ",3,155,1929,",
      "level": "3",
      "name": "伍斯特",
      "name_en": "Worcester",
      "name_pinyin": "wusite",
      "code": "WOR",
      "childrens": []
    }, {
      "id": "1930",
      "pid": "155",
      "path": ",3,155,1930,",
      "level": "3",
      "name": "西博福特",
      "name_en": "Beaufort West",
      "name_pinyin": "xibofute",
      "code": "BEW",
      "childrens": []
    }, {
      "id": "1931",
      "pid": "155",
      "path": ",3,155,1931,",
      "level": "3",
      "name": "谢普斯通港",
      "name_en": "Port Shepstone",
      "name_pinyin": "xiepusitonggang",
      "code": "PSS",
      "childrens": []
    }, {
      "id": "1932",
      "pid": "155",
      "path": ",3,155,1932,",
      "level": "3",
      "name": "伊丽莎白港",
      "name_en": "Port Elizabeth",
      "name_pinyin": "yilishabaigang",
      "code": "PLZ",
      "childrens": []
    }, {
      "id": "1933",
      "pid": "155",
      "path": ",3,155,1933,",
      "level": "3",
      "name": "约翰内斯堡",
      "name_en": "Johannesburg",
      "name_pinyin": "yuehanneisibao",
      "code": "JNB",
      "childrens": []
    }]
  }, {
    "id": "161",
    "pid": "3",
    "path": ",3,161,",
    "level": "2",
    "name": "尼日尔",
    "name_en": "Niger",
    "name_pinyin": "nirier",
    "code": "NER",
    "childrens": [{
      "id": "1965",
      "pid": "161",
      "path": ",3,161,1965,",
      "level": "3",
      "name": "阿加德兹",
      "name_en": "Agadez",
      "name_pinyin": "ajiadezi",
      "code": "AJY",
      "childrens": []
    }, {
      "id": "1966",
      "pid": "161",
      "path": ",3,161,1966,",
      "level": "3",
      "name": "迪法",
      "name_en": "Diffa",
      "name_pinyin": "difa",
      "code": "DIF",
      "childrens": []
    }, {
      "id": "1967",
      "pid": "161",
      "path": ",3,161,1967,",
      "level": "3",
      "name": "蒂拉贝里",
      "name_en": "Tillaberi",
      "name_pinyin": "dilabeili",
      "code": "TIL",
      "childrens": []
    }, {
      "id": "1968",
      "pid": "161",
      "path": ",3,161,1968,",
      "level": "3",
      "name": "多索",
      "name_en": "Dosso",
      "name_pinyin": "duosuo",
      "code": "DSS",
      "childrens": []
    }, {
      "id": "1969",
      "pid": "161",
      "path": ",3,161,1969,",
      "level": "3",
      "name": "津德尔",
      "name_en": "Zinder",
      "name_pinyin": "jindeer",
      "code": "ZND",
      "childrens": []
    }, {
      "id": "1970",
      "pid": "161",
      "path": ",3,161,1970,",
      "level": "3",
      "name": "马拉迪",
      "name_en": "Maradi",
      "name_pinyin": "maladi",
      "code": "MFQ",
      "childrens": []
    }, {
      "id": "1971",
      "pid": "161",
      "path": ",3,161,1971,",
      "level": "3",
      "name": "尼亚美市",
      "name_en": "Niamey C.U.",
      "name_pinyin": "niyameishi",
      "code": "NIM",
      "childrens": []
    }, {
      "id": "1972",
      "pid": "161",
      "path": ",3,161,1972,",
      "level": "3",
      "name": "塔瓦",
      "name_en": "Tahoua",
      "name_pinyin": "tawa",
      "code": "THZ",
      "childrens": []
    }]
  }, {
    "id": "162",
    "pid": "3",
    "path": ",3,162,",
    "level": "2",
    "name": "尼日利亚",
    "name_en": "Nigeria",
    "name_pinyin": "niriliya",
    "code": "NGA",
    "childrens": [{
      "id": "1973",
      "pid": "162",
      "path": ",3,162,1973,",
      "level": "3",
      "name": "阿比亚",
      "name_en": "Abuja",
      "name_pinyin": "abiya",
      "code": "ABV",
      "childrens": []
    }, {
      "id": "1974",
      "pid": "162",
      "path": ",3,162,1974,",
      "level": "3",
      "name": "奥博莫绍",
      "name_en": "Ogbomosho",
      "name_pinyin": "aobomoshao",
      "code": "OGB",
      "childrens": []
    }, {
      "id": "1975",
      "pid": "162",
      "path": ",3,162,1975,",
      "level": "3",
      "name": "卡诺",
      "name_en": "Kano",
      "name_pinyin": "kanuo",
      "code": "KAN",
      "childrens": []
    }, {
      "id": "1976",
      "pid": "162",
      "path": ",3,162,1976,",
      "level": "3",
      "name": "拉各斯",
      "name_en": "Lagos",
      "name_pinyin": "lagesi",
      "code": "LOS",
      "childrens": []
    }, {
      "id": "1977",
      "pid": "162",
      "path": ",3,162,1977,",
      "level": "3",
      "name": "伊巴丹",
      "name_en": "Ibadan",
      "name_pinyin": "yibadan",
      "code": "IBA",
      "childrens": []
    }]
  }, {
    "id": "176",
    "pid": "3",
    "path": ",3,176,",
    "level": "2",
    "name": "塞拉利昂",
    "name_en": "Sierra Leone",
    "name_pinyin": "sailaliang",
    "code": "SLE",
    "childrens": [{
      "id": "2152",
      "pid": "176",
      "path": ",3,176,2152,",
      "level": "3",
      "name": "北部",
      "name_en": "Northern",
      "name_pinyin": "beibu",
      "code": "N",
      "childrens": []
    }, {
      "id": "2153",
      "pid": "176",
      "path": ",3,176,2153,",
      "level": "3",
      "name": "东部",
      "name_en": "Eastern",
      "name_pinyin": "dongbu",
      "code": "E",
      "childrens": []
    }, {
      "id": "2154",
      "pid": "176",
      "path": ",3,176,2154,",
      "level": "3",
      "name": "南部",
      "name_en": "Southern",
      "name_pinyin": "nanbu",
      "code": "S",
      "childrens": []
    }, {
      "id": "2155",
      "pid": "176",
      "path": ",3,176,2155,",
      "level": "3",
      "name": "西部区",
      "name_en": "Western",
      "name_pinyin": "xibuqu",
      "code": "W",
      "childrens": []
    }]
  }, {
    "id": "177",
    "pid": "3",
    "path": ",3,177,",
    "level": "2",
    "name": "塞内加尔",
    "name_en": "Senegal",
    "name_pinyin": "saineijiaer",
    "code": "SEN",
    "childrens": [{
      "id": "2156",
      "pid": "177",
      "path": ",3,177,2156,",
      "level": "3",
      "name": "达喀尔",
      "name_en": "Dakar",
      "name_pinyin": "dakaer",
      "code": "DA",
      "childrens": []
    }, {
      "id": "2157",
      "pid": "177",
      "path": ",3,177,2157,",
      "level": "3",
      "name": "法蒂克",
      "name_en": "Fatick",
      "name_pinyin": "fadike",
      "code": "FA",
      "childrens": []
    }, {
      "id": "2158",
      "pid": "177",
      "path": ",3,177,2158,",
      "level": "3",
      "name": "济金绍尔",
      "name_en": "Ziguinchor",
      "name_pinyin": "jijinshaoer",
      "code": "ZI",
      "childrens": []
    }, {
      "id": "2159",
      "pid": "177",
      "path": ",3,177,2159,",
      "level": "3",
      "name": "捷斯",
      "name_en": "Thies",
      "name_pinyin": "jiesi",
      "code": "TH",
      "childrens": []
    }, {
      "id": "2160",
      "pid": "177",
      "path": ",3,177,2160,",
      "level": "3",
      "name": "久尔贝勒",
      "name_en": "Diourbel",
      "name_pinyin": "jiuerbeile",
      "code": "DI",
      "childrens": []
    }, {
      "id": "2161",
      "pid": "177",
      "path": ",3,177,2161,",
      "level": "3",
      "name": "考拉克",
      "name_en": "Kaolack",
      "name_pinyin": "kaolake",
      "code": "KA",
      "childrens": []
    }, {
      "id": "2162",
      "pid": "177",
      "path": ",3,177,2162,",
      "level": "3",
      "name": "科尔达",
      "name_en": "Kolda",
      "name_pinyin": "keerda",
      "code": "KO",
      "childrens": []
    }, {
      "id": "2163",
      "pid": "177",
      "path": ",3,177,2163,",
      "level": "3",
      "name": "卢加",
      "name_en": "Louga",
      "name_pinyin": "lujia",
      "code": "LO",
      "childrens": []
    }, {
      "id": "2164",
      "pid": "177",
      "path": ",3,177,2164,",
      "level": "3",
      "name": "马塔姆",
      "name_en": "Matam",
      "name_pinyin": "matamu",
      "code": "MA",
      "childrens": []
    }, {
      "id": "2165",
      "pid": "177",
      "path": ",3,177,2165,",
      "level": "3",
      "name": "圣路易",
      "name_en": "Saint-Louis",
      "name_pinyin": "shengluyi",
      "code": "SL",
      "childrens": []
    }, {
      "id": "2166",
      "pid": "177",
      "path": ",3,177,2166,",
      "level": "3",
      "name": "坦巴昆达",
      "name_en": "Tambacounda",
      "name_pinyin": "tanbakunda",
      "code": "TA",
      "childrens": []
    }]
  }, {
    "id": "179",
    "pid": "3",
    "path": ",3,179,",
    "level": "2",
    "name": "塞舌尔",
    "name_en": "Seychelles",
    "name_pinyin": "saisheer",
    "code": "SYC",
    "childrens": []
  }, {
    "id": "182",
    "pid": "3",
    "path": ",3,182,",
    "level": "2",
    "name": "圣多美和普林西比",
    "name_en": "Sao Tome and Principe",
    "name_pinyin": "shengduomeihepulinxibi",
    "code": "STP",
    "childrens": []
  }, {
    "id": "183",
    "pid": "3",
    "path": ",3,183,",
    "level": "2",
    "name": "圣赫勒拿",
    "name_en": "St.Helena",
    "name_pinyin": "shenghelena",
    "code": "SHN",
    "childrens": []
  }, {
    "id": "193",
    "pid": "3",
    "path": ",3,193,",
    "level": "2",
    "name": "斯威士兰",
    "name_en": "Swaziland",
    "name_pinyin": "siweishilan",
    "code": "SWZ",
    "childrens": []
  }, {
    "id": "194",
    "pid": "3",
    "path": ",3,194,",
    "level": "2",
    "name": "苏丹",
    "name_en": "Sudan",
    "name_pinyin": "sudan",
    "code": "SDN",
    "childrens": [{
      "id": "2240",
      "pid": "194",
      "path": ",3,194,2240,",
      "level": "3",
      "name": "北部",
      "name_en": "Ash-Shamaliyah",
      "name_pinyin": "beibu",
      "code": "ASH",
      "childrens": []
    }, {
      "id": "2241",
      "pid": "194",
      "path": ",3,194,2241,",
      "level": "3",
      "name": "赤道",
      "name_en": "Al-Istiwaiyah",
      "name_pinyin": "chidao",
      "code": "SIS",
      "childrens": []
    }, {
      "id": "2242",
      "pid": "194",
      "path": ",3,194,2242,",
      "level": "3",
      "name": "达尔富尔",
      "name_en": "Darfur",
      "name_pinyin": "daerfuer",
      "code": "SDA",
      "childrens": []
    }, {
      "id": "2243",
      "pid": "194",
      "path": ",3,194,2243,",
      "level": "3",
      "name": "东部",
      "name_en": "Ash-Sharqiyah",
      "name_pinyin": "dongbu",
      "code": "SHA",
      "childrens": []
    }, {
      "id": "2244",
      "pid": "194",
      "path": ",3,194,2244,",
      "level": "3",
      "name": "加扎勒河",
      "name_en": "Bahr al-Ghazal",
      "name_pinyin": "jiazhalehe",
      "code": "SBG",
      "childrens": []
    }, {
      "id": "2245",
      "pid": "194",
      "path": ",3,194,2245,",
      "level": "3",
      "name": "喀土穆",
      "name_en": "Al-Khartum",
      "name_pinyin": "katumu",
      "code": "KRT",
      "childrens": []
    }, {
      "id": "2246",
      "pid": "194",
      "path": ",3,194,2246,",
      "level": "3",
      "name": "科尔多凡",
      "name_en": "Kurdufan",
      "name_pinyin": "keerduofan",
      "code": "GKU",
      "childrens": []
    }, {
      "id": "2247",
      "pid": "194",
      "path": ",3,194,2247,",
      "level": "3",
      "name": "上尼罗",
      "name_en": "Aali an-Nil",
      "name_pinyin": "shangniluo",
      "code": "ANB",
      "childrens": []
    }, {
      "id": "2248",
      "pid": "194",
      "path": ",3,194,2248,",
      "level": "3",
      "name": "中部",
      "name_en": "Al Wasta",
      "name_pinyin": "zhongbu",
      "code": "WDH",
      "childrens": []
    }]
  }, {
    "id": "197",
    "pid": "3",
    "path": ",3,197,",
    "level": "2",
    "name": "索马里",
    "name_en": "Somalia",
    "name_pinyin": "suomali",
    "code": "SOM",
    "childrens": []
  }, {
    "id": "200",
    "pid": "3",
    "path": ",3,200,",
    "level": "2",
    "name": "坦桑尼亚",
    "name_en": "Tanzania",
    "name_pinyin": "tansangniya",
    "code": "TZA",
    "childrens": [{
      "id": "2358",
      "pid": "200",
      "path": ",3,200,2358,",
      "level": "3",
      "name": "阿鲁沙",
      "name_en": "Arusha",
      "name_pinyin": "alusha",
      "code": "AR",
      "childrens": []
    }, {
      "id": "2359",
      "pid": "200",
      "path": ",3,200,2359,",
      "level": "3",
      "name": "奔巴北",
      "name_en": "Kaskazini Pemba",
      "name_pinyin": "benbabei",
      "code": "PN",
      "childrens": []
    }, {
      "id": "2360",
      "pid": "200",
      "path": ",3,200,2360,",
      "level": "3",
      "name": "奔巴南",
      "name_en": "Kusini Pemba",
      "name_pinyin": "benbanan",
      "code": "PS",
      "childrens": []
    }, {
      "id": "2361",
      "pid": "200",
      "path": ",3,200,2361,",
      "level": "3",
      "name": "滨海",
      "name_en": "Pwani",
      "name_pinyin": "binhai",
      "code": "PW",
      "childrens": []
    }, {
      "id": "2362",
      "pid": "200",
      "path": ",3,200,2362,",
      "level": "3",
      "name": "达累斯萨拉姆",
      "name_en": "Dar es Salaam",
      "name_pinyin": "daleisisalamu",
      "code": "DS",
      "childrens": []
    }, {
      "id": "2363",
      "pid": "200",
      "path": ",3,200,2363,",
      "level": "3",
      "name": "多多马",
      "name_en": "Dodoma",
      "name_pinyin": "duoduoma",
      "code": "DO",
      "childrens": []
    }, {
      "id": "2364",
      "pid": "200",
      "path": ",3,200,2364,",
      "level": "3",
      "name": "基戈马",
      "name_en": "Kigoma",
      "name_pinyin": "jigema",
      "code": "KI",
      "childrens": []
    }, {
      "id": "2365",
      "pid": "200",
      "path": ",3,200,2365,",
      "level": "3",
      "name": "卡盖拉",
      "name_en": "Kagera",
      "name_pinyin": "kagaila",
      "code": "KA",
      "childrens": []
    }, {
      "id": "2366",
      "pid": "200",
      "path": ",3,200,2366,",
      "level": "3",
      "name": "林迪",
      "name_en": "Lindi",
      "name_pinyin": "lindi",
      "code": "LN",
      "childrens": []
    }, {
      "id": "2367",
      "pid": "200",
      "path": ",3,200,2367,",
      "level": "3",
      "name": "鲁夸",
      "name_en": "Rukwa",
      "name_pinyin": "lukua",
      "code": "RK",
      "childrens": []
    }, {
      "id": "2368",
      "pid": "200",
      "path": ",3,200,2368,",
      "level": "3",
      "name": "鲁伍马",
      "name_en": "Ruvuma",
      "name_pinyin": "luwuma",
      "code": "RV",
      "childrens": []
    }, {
      "id": "2369",
      "pid": "200",
      "path": ",3,200,2369,",
      "level": "3",
      "name": "马腊",
      "name_en": "Mara",
      "name_pinyin": "mala",
      "code": "MR",
      "childrens": []
    }, {
      "id": "2370",
      "pid": "200",
      "path": ",3,200,2370,",
      "level": "3",
      "name": "曼亚拉",
      "name_en": "Manyara",
      "name_pinyin": "manyala",
      "code": "MY",
      "childrens": []
    }, {
      "id": "2371",
      "pid": "200",
      "path": ",3,200,2371,",
      "level": "3",
      "name": "莫洛戈罗",
      "name_en": "Morogoro",
      "name_pinyin": "moluogeluo",
      "code": "MO",
      "childrens": []
    }, {
      "id": "2372",
      "pid": "200",
      "path": ",3,200,2372,",
      "level": "3",
      "name": "姆贝亚",
      "name_en": "Mbeya",
      "name_pinyin": "mubeiya",
      "code": "MB",
      "childrens": []
    }, {
      "id": "2373",
      "pid": "200",
      "path": ",3,200,2373,",
      "level": "3",
      "name": "姆特瓦拉",
      "name_en": "Mtwara",
      "name_pinyin": "mutewala",
      "code": "MT",
      "childrens": []
    }, {
      "id": "2374",
      "pid": "200",
      "path": ",3,200,2374,",
      "level": "3",
      "name": "姆万扎",
      "name_en": "Mwanza",
      "name_pinyin": "muwanzha",
      "code": "MW",
      "childrens": []
    }, {
      "id": "2375",
      "pid": "200",
      "path": ",3,200,2375,",
      "level": "3",
      "name": "乞力马扎罗",
      "name_en": "Kilimanjaro",
      "name_pinyin": "qilimazhaluo",
      "code": "KJ",
      "childrens": []
    }, {
      "id": "2376",
      "pid": "200",
      "path": ",3,200,2376,",
      "level": "3",
      "name": "桑给巴尔",
      "name_en": "Zanzibar",
      "name_pinyin": "sanggeibaer",
      "code": "ZN",
      "childrens": []
    }, {
      "id": "2377",
      "pid": "200",
      "path": ",3,200,2377,",
      "level": "3",
      "name": "桑给巴尔北",
      "name_en": "Kaskazini Unguja",
      "name_pinyin": "sanggeibaerbei",
      "code": "UN",
      "childrens": []
    }, {
      "id": "2378",
      "pid": "200",
      "path": ",3,200,2378,",
      "level": "3",
      "name": "桑给巴尔南",
      "name_en": "Kusini Unguja",
      "name_pinyin": "sanggeibaernan",
      "code": "US",
      "childrens": []
    }, {
      "id": "2379",
      "pid": "200",
      "path": ",3,200,2379,",
      "level": "3",
      "name": "桑给巴尔市和西",
      "name_en": "Mjini Magharibi",
      "name_pinyin": "sanggeibaershihexi",
      "code": "MM",
      "childrens": []
    }, {
      "id": "2380",
      "pid": "200",
      "path": ",3,200,2380,",
      "level": "3",
      "name": "塔波拉",
      "name_en": "Tabora",
      "name_pinyin": "tabola",
      "code": "TB",
      "childrens": []
    }, {
      "id": "2381",
      "pid": "200",
      "path": ",3,200,2381,",
      "level": "3",
      "name": "坦噶",
      "name_en": "Tanga",
      "name_pinyin": "tanga",
      "code": "TN",
      "childrens": []
    }, {
      "id": "2382",
      "pid": "200",
      "path": ",3,200,2382,",
      "level": "3",
      "name": "辛吉达",
      "name_en": "Singida",
      "name_pinyin": "xinjida",
      "code": "SI",
      "childrens": []
    }, {
      "id": "2383",
      "pid": "200",
      "path": ",3,200,2383,",
      "level": "3",
      "name": "欣延加",
      "name_en": "Shinyanga",
      "name_pinyin": "xinyanjia",
      "code": "SH",
      "childrens": []
    }, {
      "id": "2384",
      "pid": "200",
      "path": ",3,200,2384,",
      "level": "3",
      "name": "伊林加",
      "name_en": "Iringa",
      "name_pinyin": "yilinjia",
      "code": "IR",
      "childrens": []
    }]
  }, {
    "id": "205",
    "pid": "3",
    "path": ",3,205,",
    "level": "2",
    "name": "突尼斯",
    "name_en": "Tunisia",
    "name_pinyin": "tunisi",
    "code": "TUN",
    "childrens": [{
      "id": "2390",
      "pid": "205",
      "path": ",3,205,2390,",
      "level": "3",
      "name": "艾尔亚奈",
      "name_en": "Ariana",
      "name_pinyin": "aieryanai",
      "code": "AR",
      "childrens": []
    }, {
      "id": "2391",
      "pid": "205",
      "path": ",3,205,2391,",
      "level": "3",
      "name": "巴杰",
      "name_en": "Beja",
      "name_pinyin": "bajie",
      "code": "BJ",
      "childrens": []
    }, {
      "id": "2392",
      "pid": "205",
      "path": ",3,205,2392,",
      "level": "3",
      "name": "本阿鲁斯",
      "name_en": "Ben Arous",
      "name_pinyin": "benalusi",
      "code": "BA",
      "childrens": []
    }, {
      "id": "2393",
      "pid": "205",
      "path": ",3,205,2393,",
      "level": "3",
      "name": "比塞大",
      "name_en": "Bizerte",
      "name_pinyin": "bisaida",
      "code": "BI",
      "childrens": []
    }, {
      "id": "2394",
      "pid": "205",
      "path": ",3,205,2394,",
      "level": "3",
      "name": "吉比利",
      "name_en": "Kebili",
      "name_pinyin": "jibili",
      "code": "KB",
      "childrens": []
    }, {
      "id": "2395",
      "pid": "205",
      "path": ",3,205,2395,",
      "level": "3",
      "name": "加贝斯",
      "name_en": "Gabes",
      "name_pinyin": "jiabeisi",
      "code": "GB",
      "childrens": []
    }, {
      "id": "2396",
      "pid": "205",
      "path": ",3,205,2396,",
      "level": "3",
      "name": "加夫萨",
      "name_en": "Gafsa",
      "name_pinyin": "jiafusa",
      "code": "GF",
      "childrens": []
    }, {
      "id": "2397",
      "pid": "205",
      "path": ",3,205,2397,",
      "level": "3",
      "name": "坚杜拜",
      "name_en": "Jendouba",
      "name_pinyin": "jiandubai",
      "code": "JE",
      "childrens": []
    }, {
      "id": "2398",
      "pid": "205",
      "path": ",3,205,2398,",
      "level": "3",
      "name": "卡夫",
      "name_en": "Le Kef",
      "name_pinyin": "kafu",
      "code": "LK",
      "childrens": []
    }, {
      "id": "2399",
      "pid": "205",
      "path": ",3,205,2399,",
      "level": "3",
      "name": "卡塞林",
      "name_en": "Kasserine",
      "name_pinyin": "kasailin",
      "code": "KS",
      "childrens": []
    }, {
      "id": "2400",
      "pid": "205",
      "path": ",3,205,2400,",
      "level": "3",
      "name": "凯鲁万",
      "name_en": "Kairouan",
      "name_pinyin": "kailuwan",
      "code": "KR",
      "childrens": []
    }, {
      "id": "2401",
      "pid": "205",
      "path": ",3,205,2401,",
      "level": "3",
      "name": "马赫迪耶",
      "name_en": "Mahdia",
      "name_pinyin": "mahediye",
      "code": "MH",
      "childrens": []
    }, {
      "id": "2402",
      "pid": "205",
      "path": ",3,205,2402,",
      "level": "3",
      "name": "马努巴",
      "name_en": "Manouba",
      "name_pinyin": "manuba",
      "code": "MN",
      "childrens": []
    }, {
      "id": "2403",
      "pid": "205",
      "path": ",3,205,2403,",
      "level": "3",
      "name": "梅德宁",
      "name_en": "Medenine",
      "name_pinyin": "meidening",
      "code": "ME",
      "childrens": []
    }, {
      "id": "2404",
      "pid": "205",
      "path": ",3,205,2404,",
      "level": "3",
      "name": "莫纳斯提尔",
      "name_en": "Monastir",
      "name_pinyin": "monasitier",
      "code": "MO",
      "childrens": []
    }, {
      "id": "2405",
      "pid": "205",
      "path": ",3,205,2405,",
      "level": "3",
      "name": "纳布勒",
      "name_en": "Nabeul",
      "name_pinyin": "nabule",
      "code": "NA",
      "childrens": []
    }, {
      "id": "2406",
      "pid": "205",
      "path": ",3,205,2406,",
      "level": "3",
      "name": "斯法克斯",
      "name_en": "Sfax",
      "name_pinyin": "sifakesi",
      "code": "SF",
      "childrens": []
    }, {
      "id": "2407",
      "pid": "205",
      "path": ",3,205,2407,",
      "level": "3",
      "name": "苏塞",
      "name_en": "Sousse",
      "name_pinyin": "susai",
      "code": "SO",
      "childrens": []
    }, {
      "id": "2408",
      "pid": "205",
      "path": ",3,205,2408,",
      "level": "3",
      "name": "泰塔温",
      "name_en": "Tataouine",
      "name_pinyin": "taitawen",
      "code": "TA",
      "childrens": []
    }, {
      "id": "2409",
      "pid": "205",
      "path": ",3,205,2409,",
      "level": "3",
      "name": "突尼斯",
      "name_en": "Tunis",
      "name_pinyin": "tunisi",
      "code": "TU",
      "childrens": []
    }, {
      "id": "2410",
      "pid": "205",
      "path": ",3,205,2410,",
      "level": "3",
      "name": "托泽尔",
      "name_en": "Tozeur",
      "name_pinyin": "tuozeer",
      "code": "TO",
      "childrens": []
    }, {
      "id": "2411",
      "pid": "205",
      "path": ",3,205,2411,",
      "level": "3",
      "name": "西迪布济德",
      "name_en": "Sidi Bouzid",
      "name_pinyin": "xidibujide",
      "code": "SD",
      "childrens": []
    }, {
      "id": "2412",
      "pid": "205",
      "path": ",3,205,2412,",
      "level": "3",
      "name": "锡勒亚奈",
      "name_en": "Siliana",
      "name_pinyin": "xileyanai",
      "code": "SL",
      "childrens": []
    }, {
      "id": "2413",
      "pid": "205",
      "path": ",3,205,2413,",
      "level": "3",
      "name": "宰格万",
      "name_en": "Zaghouan",
      "name_pinyin": "zaigewan",
      "code": "ZA",
      "childrens": []
    }]
  }, {
    "id": "217",
    "pid": "3",
    "path": ",3,217,",
    "level": "2",
    "name": "乌干达",
    "name_en": "Uganda",
    "name_pinyin": "wuganda",
    "code": "UGA",
    "childrens": [{
      "id": "2553",
      "pid": "217",
      "path": ",3,217,2553,",
      "level": "3",
      "name": "阿鲁阿",
      "name_en": "Arua",
      "name_pinyin": "alua",
      "code": "ARU",
      "childrens": []
    }, {
      "id": "2554",
      "pid": "217",
      "path": ",3,217,2554,",
      "level": "3",
      "name": "阿帕克",
      "name_en": "Apac",
      "name_pinyin": "apake",
      "code": "APC",
      "childrens": []
    }, {
      "id": "2555",
      "pid": "217",
      "path": ",3,217,2555,",
      "level": "3",
      "name": "阿朱马尼",
      "name_en": "Adjumani",
      "name_pinyin": "azhumani",
      "code": "ADJ",
      "childrens": []
    }, {
      "id": "2556",
      "pid": "217",
      "path": ",3,217,2556,",
      "level": "3",
      "name": "本迪布焦",
      "name_en": "Bundibugyo",
      "name_pinyin": "bendibujiao",
      "code": "BUN",
      "childrens": []
    }, {
      "id": "2557",
      "pid": "217",
      "path": ",3,217,2557,",
      "level": "3",
      "name": "布吉里",
      "name_en": "Bugiri",
      "name_pinyin": "bujili",
      "code": "BUG",
      "childrens": []
    }, {
      "id": "2558",
      "pid": "217",
      "path": ",3,217,2558,",
      "level": "3",
      "name": "布西亚",
      "name_en": "Busia",
      "name_pinyin": "buxiya",
      "code": "BUS",
      "childrens": []
    }, {
      "id": "2559",
      "pid": "217",
      "path": ",3,217,2559,",
      "level": "3",
      "name": "布谢尼",
      "name_en": "Bushenyi",
      "name_pinyin": "buxieni",
      "code": "BSH",
      "childrens": []
    }, {
      "id": "2560",
      "pid": "217",
      "path": ",3,217,2560,",
      "level": "3",
      "name": "恩通加莫",
      "name_en": "Ntungamo",
      "name_pinyin": "entongjiamo",
      "code": "NTU",
      "childrens": []
    }, {
      "id": "2561",
      "pid": "217",
      "path": ",3,217,2561,",
      "level": "3",
      "name": "古卢",
      "name_en": "Gulu",
      "name_pinyin": "gulu",
      "code": "GUL",
      "childrens": []
    }, {
      "id": "2562",
      "pid": "217",
      "path": ",3,217,2562,",
      "level": "3",
      "name": "霍伊马",
      "name_en": "Hoima",
      "name_pinyin": "huoyima",
      "code": "HOI",
      "childrens": []
    }, {
      "id": "2563",
      "pid": "217",
      "path": ",3,217,2563,",
      "level": "3",
      "name": "基巴莱",
      "name_en": "Kibaale",
      "name_pinyin": "jibalai",
      "code": "KBA",
      "childrens": []
    }, {
      "id": "2564",
      "pid": "217",
      "path": ",3,217,2564,",
      "level": "3",
      "name": "基博加",
      "name_en": "Kiboga",
      "name_pinyin": "jibojia",
      "code": "KIB",
      "childrens": []
    }, {
      "id": "2565",
      "pid": "217",
      "path": ",3,217,2565,",
      "level": "3",
      "name": "基恩乔乔",
      "name_en": "Kyenjojo",
      "name_pinyin": "jienqiaoqiao",
      "code": "KYE",
      "childrens": []
    }, {
      "id": "2566",
      "pid": "217",
      "path": ",3,217,2566,",
      "level": "3",
      "name": "基索罗",
      "name_en": "Kisoro",
      "name_pinyin": "jisuoluo",
      "code": "KIS",
      "childrens": []
    }, {
      "id": "2567",
      "pid": "217",
      "path": ",3,217,2567,",
      "level": "3",
      "name": "基特古姆",
      "name_en": "Kitgum",
      "name_pinyin": "jitegumu",
      "code": "KIT",
      "childrens": []
    }, {
      "id": "2568",
      "pid": "217",
      "path": ",3,217,2568,",
      "level": "3",
      "name": "金贾",
      "name_en": "Jinja",
      "name_pinyin": "jinjia",
      "code": "JIN",
      "childrens": []
    }, {
      "id": "2569",
      "pid": "217",
      "path": ",3,217,2569,",
      "level": "3",
      "name": "卡巴莱",
      "name_en": "Kabale",
      "name_pinyin": "kabalai",
      "code": "KBL",
      "childrens": []
    }, {
      "id": "2570",
      "pid": "217",
      "path": ",3,217,2570,",
      "level": "3",
      "name": "卡巴罗莱",
      "name_en": "Kabarole",
      "name_pinyin": "kabaluolai",
      "code": "KAR",
      "childrens": []
    }, {
      "id": "2571",
      "pid": "217",
      "path": ",3,217,2571,",
      "level": "3",
      "name": "卡贝拉马伊多",
      "name_en": "Kaberamaido",
      "name_pinyin": "kabeilamayiduo",
      "code": "KAB",
      "childrens": []
    }, {
      "id": "2572",
      "pid": "217",
      "path": ",3,217,2572,",
      "level": "3",
      "name": "卡兰加拉",
      "name_en": "Kalangala",
      "name_pinyin": "kalanjiala",
      "code": "KAL",
      "childrens": []
    }, {
      "id": "2573",
      "pid": "217",
      "path": ",3,217,2573,",
      "level": "3",
      "name": "卡姆文盖",
      "name_en": "Kamwenge",
      "name_pinyin": "kamuwengai",
      "code": "KAM",
      "childrens": []
    }, {
      "id": "2574",
      "pid": "217",
      "path": ",3,217,2574,",
      "level": "3",
      "name": "卡穆利",
      "name_en": "Kamuli",
      "name_pinyin": "kamuli",
      "code": "KML",
      "childrens": []
    }, {
      "id": "2575",
      "pid": "217",
      "path": ",3,217,2575,",
      "level": "3",
      "name": "卡农古",
      "name_en": "Kanungu",
      "name_pinyin": "kanonggu",
      "code": "KAN",
      "childrens": []
    }, {
      "id": "2576",
      "pid": "217",
      "path": ",3,217,2576,",
      "level": "3",
      "name": "卡普乔鲁瓦",
      "name_en": "Kapchorwa",
      "name_pinyin": "kapuqiaoluwa",
      "code": "KPC",
      "childrens": []
    }, {
      "id": "2577",
      "pid": "217",
      "path": ",3,217,2577,",
      "level": "3",
      "name": "卡塞塞",
      "name_en": "Kasese",
      "name_pinyin": "kasaisai",
      "code": "KAS",
      "childrens": []
    }, {
      "id": "2578",
      "pid": "217",
      "path": ",3,217,2578,",
      "level": "3",
      "name": "卡塔奎",
      "name_en": "Katakwi",
      "name_pinyin": "katakui",
      "code": "KTK",
      "childrens": []
    }, {
      "id": "2579",
      "pid": "217",
      "path": ",3,217,2579,",
      "level": "3",
      "name": "卡永加",
      "name_en": "Kayunga",
      "name_pinyin": "kayongjia",
      "code": "KAY",
      "childrens": []
    }, {
      "id": "2580",
      "pid": "217",
      "path": ",3,217,2580,",
      "level": "3",
      "name": "坎帕拉",
      "name_en": "Kampala",
      "name_pinyin": "kanpala",
      "code": "KMP",
      "childrens": []
    }, {
      "id": "2581",
      "pid": "217",
      "path": ",3,217,2581,",
      "level": "3",
      "name": "科蒂多",
      "name_en": "Kotido",
      "name_pinyin": "kediduo",
      "code": "KOT",
      "childrens": []
    }, {
      "id": "2582",
      "pid": "217",
      "path": ",3,217,2582,",
      "level": "3",
      "name": "库米",
      "name_en": "Kumi",
      "name_pinyin": "kumi",
      "code": "KUM",
      "childrens": []
    }, {
      "id": "2583",
      "pid": "217",
      "path": ",3,217,2583,",
      "level": "3",
      "name": "拉卡伊",
      "name_en": "Rakai",
      "name_pinyin": "lakayi",
      "code": "RAK",
      "childrens": []
    }, {
      "id": "2584",
      "pid": "217",
      "path": ",3,217,2584,",
      "level": "3",
      "name": "利拉",
      "name_en": "Lira",
      "name_pinyin": "lila",
      "code": "LIR",
      "childrens": []
    }, {
      "id": "2585",
      "pid": "217",
      "path": ",3,217,2585,",
      "level": "3",
      "name": "卢韦罗",
      "name_en": "Luwero",
      "name_pinyin": "luweiluo",
      "code": "LUW",
      "childrens": []
    }, {
      "id": "2586",
      "pid": "217",
      "path": ",3,217,2586,",
      "level": "3",
      "name": "鲁昆吉里",
      "name_en": "Rukungiri",
      "name_pinyin": "lukunjili",
      "code": "RUK",
      "childrens": []
    }, {
      "id": "2587",
      "pid": "217",
      "path": ",3,217,2587,",
      "level": "3",
      "name": "马萨卡",
      "name_en": "Masaka",
      "name_pinyin": "masaka",
      "code": "MAS",
      "childrens": []
    }, {
      "id": "2588",
      "pid": "217",
      "path": ",3,217,2588,",
      "level": "3",
      "name": "马辛迪",
      "name_en": "Masindi",
      "name_pinyin": "maxindi",
      "code": "MSN",
      "childrens": []
    }, {
      "id": "2589",
      "pid": "217",
      "path": ",3,217,2589,",
      "level": "3",
      "name": "马尤盖",
      "name_en": "Mayuge",
      "name_pinyin": "mayougai",
      "code": "MAY",
      "childrens": []
    }, {
      "id": "2590",
      "pid": "217",
      "path": ",3,217,2590,",
      "level": "3",
      "name": "莫罗托",
      "name_en": "Moroto",
      "name_pinyin": "moluotuo",
      "code": "MRT",
      "childrens": []
    }, {
      "id": "2591",
      "pid": "217",
      "path": ",3,217,2591,",
      "level": "3",
      "name": "莫约",
      "name_en": "Moyo",
      "name_pinyin": "moyue",
      "code": "MOY",
      "childrens": []
    }, {
      "id": "2592",
      "pid": "217",
      "path": ",3,217,2592,",
      "level": "3",
      "name": "姆巴拉拉",
      "name_en": "Mbarara",
      "name_pinyin": "mubalala",
      "code": "MBR",
      "childrens": []
    }, {
      "id": "2593",
      "pid": "217",
      "path": ",3,217,2593,",
      "level": "3",
      "name": "姆巴莱",
      "name_en": "Mbale",
      "name_pinyin": "mubalai",
      "code": "MBA",
      "childrens": []
    }, {
      "id": "2594",
      "pid": "217",
      "path": ",3,217,2594,",
      "level": "3",
      "name": "姆皮吉",
      "name_en": "Mpigi",
      "name_pinyin": "mupiji",
      "code": "MPI",
      "childrens": []
    }, {
      "id": "2595",
      "pid": "217",
      "path": ",3,217,2595,",
      "level": "3",
      "name": "穆本德",
      "name_en": "Mubende",
      "name_pinyin": "mubende",
      "code": "MUB",
      "childrens": []
    }, {
      "id": "2596",
      "pid": "217",
      "path": ",3,217,2596,",
      "level": "3",
      "name": "穆科诺",
      "name_en": "Mukono",
      "name_pinyin": "mukenuo",
      "code": "MUK",
      "childrens": []
    }, {
      "id": "2597",
      "pid": "217",
      "path": ",3,217,2597,",
      "level": "3",
      "name": "纳卡皮里皮里特",
      "name_en": "Nakapiripirit",
      "name_pinyin": "nakapilipilite",
      "code": "NAK",
      "childrens": []
    }, {
      "id": "2598",
      "pid": "217",
      "path": ",3,217,2598,",
      "level": "3",
      "name": "纳卡松戈拉",
      "name_en": "Nakasongola",
      "name_pinyin": "nakasonggela",
      "code": "NKS",
      "childrens": []
    }, {
      "id": "2599",
      "pid": "217",
      "path": ",3,217,2599,",
      "level": "3",
      "name": "内比",
      "name_en": "Nebbi",
      "name_pinyin": "neibi",
      "code": "NEB",
      "childrens": []
    }, {
      "id": "2600",
      "pid": "217",
      "path": ",3,217,2600,",
      "level": "3",
      "name": "帕德尔",
      "name_en": "Pader",
      "name_pinyin": "padeer",
      "code": "PAD",
      "childrens": []
    }, {
      "id": "2601",
      "pid": "217",
      "path": ",3,217,2601,",
      "level": "3",
      "name": "帕利萨",
      "name_en": "Pallisa",
      "name_pinyin": "palisa",
      "code": "PAL",
      "childrens": []
    }, {
      "id": "2602",
      "pid": "217",
      "path": ",3,217,2602,",
      "level": "3",
      "name": "森巴布莱",
      "name_en": "Sembabule",
      "name_pinyin": "senbabulai",
      "code": "SEM",
      "childrens": []
    }, {
      "id": "2603",
      "pid": "217",
      "path": ",3,217,2603,",
      "level": "3",
      "name": "索罗提",
      "name_en": "Soroti",
      "name_pinyin": "suoluoti",
      "code": "SOR",
      "childrens": []
    }, {
      "id": "2604",
      "pid": "217",
      "path": ",3,217,2604,",
      "level": "3",
      "name": "托罗罗",
      "name_en": "Tororo",
      "name_pinyin": "tuoluoluo",
      "code": "TOR",
      "childrens": []
    }, {
      "id": "2605",
      "pid": "217",
      "path": ",3,217,2605,",
      "level": "3",
      "name": "瓦基索",
      "name_en": "Wakiso",
      "name_pinyin": "wajisuo",
      "code": "WAK",
      "childrens": []
    }, {
      "id": "2606",
      "pid": "217",
      "path": ",3,217,2606,",
      "level": "3",
      "name": "锡龙科",
      "name_en": "Sironko",
      "name_pinyin": "xilongke",
      "code": "SIR",
      "childrens": []
    }, {
      "id": "2607",
      "pid": "217",
      "path": ",3,217,2607,",
      "level": "3",
      "name": "伊甘加",
      "name_en": "Iganga",
      "name_pinyin": "yiganjia",
      "code": "IGA",
      "childrens": []
    }, {
      "id": "2608",
      "pid": "217",
      "path": ",3,217,2608,",
      "level": "3",
      "name": "永贝",
      "name_en": "Yumbe",
      "name_pinyin": "yongbei",
      "code": "YUM",
      "childrens": []
    }]
  }, {
    "id": "238",
    "pid": "3",
    "path": ",3,238,",
    "level": "2",
    "name": "英属印度洋领地",
    "name_en": "British Indian Ocean Territory",
    "name_pinyin": "yingshuyinduyanglingdi",
    "code": "IOT",
    "childrens": []
  }, {
    "id": "241",
    "pid": "3",
    "path": ",3,241,",
    "level": "2",
    "name": "赞比亚",
    "name_en": "Zambia",
    "name_pinyin": "zanbiya",
    "code": "ZMB",
    "childrens": [{
      "id": "2981",
      "pid": "241",
      "path": ",3,241,2981,",
      "level": "3",
      "name": "北方",
      "name_en": "Northern",
      "name_pinyin": "beifang",
      "code": "NO",
      "childrens": []
    }, {
      "id": "2982",
      "pid": "241",
      "path": ",3,241,2982,",
      "level": "3",
      "name": "东方",
      "name_en": "Eastern",
      "name_pinyin": "dongfang",
      "code": "EA",
      "childrens": []
    }, {
      "id": "2983",
      "pid": "241",
      "path": ",3,241,2983,",
      "level": "3",
      "name": "卢阿普拉",
      "name_en": "Luapula",
      "name_pinyin": "luapula",
      "code": "LP",
      "childrens": []
    }, {
      "id": "2984",
      "pid": "241",
      "path": ",3,241,2984,",
      "level": "3",
      "name": "卢萨卡",
      "name_en": "Lusaka",
      "name_pinyin": "lusaka",
      "code": "LK",
      "childrens": []
    }, {
      "id": "2985",
      "pid": "241",
      "path": ",3,241,2985,",
      "level": "3",
      "name": "南方",
      "name_en": "Southern",
      "name_pinyin": "nanfang",
      "code": "SO",
      "childrens": []
    }, {
      "id": "2986",
      "pid": "241",
      "path": ",3,241,2986,",
      "level": "3",
      "name": "铜带",
      "name_en": "Copperbelt",
      "name_pinyin": "tongdai",
      "code": "CB",
      "childrens": []
    }, {
      "id": "2987",
      "pid": "241",
      "path": ",3,241,2987,",
      "level": "3",
      "name": "西北",
      "name_en": "North-Western",
      "name_pinyin": "xibei",
      "code": "NW",
      "childrens": []
    }, {
      "id": "2988",
      "pid": "241",
      "path": ",3,241,2988,",
      "level": "3",
      "name": "西方",
      "name_en": "Western",
      "name_pinyin": "xifang",
      "code": "WE",
      "childrens": []
    }, {
      "id": "2989",
      "pid": "241",
      "path": ",3,241,2989,",
      "level": "3",
      "name": "中央",
      "name_en": "Central",
      "name_pinyin": "zhongyang",
      "code": "CE",
      "childrens": []
    }]
  }, {
    "id": "243",
    "pid": "3",
    "path": ",3,243,",
    "level": "2",
    "name": "乍得",
    "name_en": "Chad",
    "name_pinyin": "zhade",
    "code": "TCD",
    "childrens": []
  }, {
    "id": "244",
    "pid": "3",
    "path": ",3,244,",
    "level": "2",
    "name": "直布罗陀",
    "name_en": "Gibraltar",
    "name_pinyin": "zhibuluotuo",
    "code": "GIB",
    "childrens": []
  }, {
    "id": "246",
    "pid": "3",
    "path": ",3,246,",
    "level": "2",
    "name": "中非共和国",
    "name_en": "Central African Republic",
    "name_pinyin": "zhongfeigongheguo",
    "code": "CAF",
    "childrens": [{
      "id": "3003",
      "pid": "246",
      "path": ",3,246,3003,",
      "level": "3",
      "name": "巴明吉-班戈兰",
      "name_en": "Bamingui-Bangoran",
      "name_pinyin": "bamingjibangelan",
      "code": "BB",
      "childrens": []
    }, {
      "id": "3004",
      "pid": "246",
      "path": ",3,246,3004,",
      "level": "3",
      "name": "班吉直辖市",
      "name_en": "Bangui",
      "name_pinyin": "banjizhixiashi",
      "code": "BGF",
      "childrens": []
    }, {
      "id": "3005",
      "pid": "246",
      "path": ",3,246,3005,",
      "level": "3",
      "name": "宾博",
      "name_en": "Bimbo",
      "name_pinyin": "binbo",
      "code": "BI",
      "childrens": []
    }, {
      "id": "3006",
      "pid": "246",
      "path": ",3,246,3006,",
      "level": "3",
      "name": "凯莫",
      "name_en": "Kemo",
      "name_pinyin": "kaimo",
      "code": "KG",
      "childrens": []
    }, {
      "id": "3007",
      "pid": "246",
      "path": ",3,246,3007,",
      "level": "3",
      "name": "洛巴伊",
      "name_en": "Lobaye",
      "name_pinyin": "luobayi",
      "code": "LB",
      "childrens": []
    }, {
      "id": "3008",
      "pid": "246",
      "path": ",3,246,3008,",
      "level": "3",
      "name": "曼贝雷-卡代",
      "name_en": "Mambere-Kadei",
      "name_pinyin": "manbeileikadai",
      "code": "HS",
      "childrens": []
    }, {
      "id": "3009",
      "pid": "246",
      "path": ",3,246,3009,",
      "level": "3",
      "name": "姆博穆",
      "name_en": "Mbomou",
      "name_pinyin": "mubomu",
      "code": "MB",
      "childrens": []
    }, {
      "id": "3010",
      "pid": "246",
      "path": ",3,246,3010,",
      "level": "3",
      "name": "纳纳-格里比齐",
      "name_en": "Nana-Gribizi",
      "name_pinyin": "nanagelibiqi",
      "code": "KB",
      "childrens": []
    }, {
      "id": "3011",
      "pid": "246",
      "path": ",3,246,3011,",
      "level": "3",
      "name": "纳纳-曼贝雷",
      "name_en": "Nana-Mambere",
      "name_pinyin": "nanamanbeilei",
      "code": "NM",
      "childrens": []
    }, {
      "id": "3012",
      "pid": "246",
      "path": ",3,246,3012,",
      "level": "3",
      "name": "桑加-姆巴埃雷",
      "name_en": "Sangha-Mbaere",
      "name_pinyin": "sangjiamubaailei",
      "code": "SE",
      "childrens": []
    }, {
      "id": "3013",
      "pid": "246",
      "path": ",3,246,3013,",
      "level": "3",
      "name": "上科托",
      "name_en": "Haute-Kotto",
      "name_pinyin": "shangketuo",
      "code": "HK",
      "childrens": []
    }, {
      "id": "3014",
      "pid": "246",
      "path": ",3,246,3014,",
      "level": "3",
      "name": "上姆博穆",
      "name_en": "Haut-Mbomou",
      "name_pinyin": "shangmubomu",
      "code": "HM",
      "childrens": []
    }, {
      "id": "3015",
      "pid": "246",
      "path": ",3,246,3015,",
      "level": "3",
      "name": "瓦卡",
      "name_en": "Ouaka",
      "name_pinyin": "waka",
      "code": "UK",
      "childrens": []
    }, {
      "id": "3016",
      "pid": "246",
      "path": ",3,246,3016,",
      "level": "3",
      "name": "瓦卡加",
      "name_en": "Vakaga",
      "name_pinyin": "wakajia",
      "code": "VK",
      "childrens": []
    }, {
      "id": "3017",
      "pid": "246",
      "path": ",3,246,3017,",
      "level": "3",
      "name": "瓦姆",
      "name_en": "Ouham",
      "name_pinyin": "wamu",
      "code": "AC",
      "childrens": []
    }, {
      "id": "3018",
      "pid": "246",
      "path": ",3,246,3018,",
      "level": "3",
      "name": "瓦姆-彭代",
      "name_en": "Ouham-Pende",
      "name_pinyin": "wamupengdai",
      "code": "OP",
      "childrens": []
    }, {
      "id": "3019",
      "pid": "246",
      "path": ",3,246,3019,",
      "level": "3",
      "name": "翁贝拉-姆波科",
      "name_en": "Ombella-Mpoko",
      "name_pinyin": "wengbeilamuboke",
      "code": "MP",
      "childrens": []
    }, {
      "id": "3020",
      "pid": "246",
      "path": ",3,246,3020,",
      "level": "3",
      "name": "下科托",
      "name_en": "Basse-Kotto",
      "name_pinyin": "xiaketuo",
      "code": "BK",
      "childrens": []
    }]
  }]
}, {
  "id": "4",
  "pid": "0",
  "path": ",4,",
  "level": "1",
  "name": "北美",
  "name_en": "North America",
  "name_pinyin": "beimei",
  "code": null,
  "childrens": [{
    "id": "24",
    "pid": "4",
    "path": ",4,24,",
    "level": "2",
    "name": "安提瓜岛和巴布达",
    "name_en": "Antigua and Barbuda",
    "name_pinyin": "antiguadaohebabuda",
    "code": "ATG",
    "childrens": []
  }, {
    "id": "30",
    "pid": "4",
    "path": ",4,30,",
    "level": "2",
    "name": "巴哈马",
    "name_en": "Bahamas",
    "name_pinyin": "bahama",
    "code": "BHS",
    "childrens": []
  }, {
    "id": "35",
    "pid": "4",
    "path": ",4,35,",
    "level": "2",
    "name": "巴拿马",
    "name_en": "Panama",
    "name_pinyin": "banama",
    "code": "PAN",
    "childrens": []
  }, {
    "id": "38",
    "pid": "4",
    "path": ",4,38,",
    "level": "2",
    "name": "百慕大",
    "name_en": "Bermuda",
    "name_pinyin": "baimuda",
    "code": "BMU",
    "childrens": []
  }, {
    "id": "40",
    "pid": "4",
    "path": ",4,40,",
    "level": "2",
    "name": "北马里亚纳群岛",
    "name_en": "Northern Mariana Islands",
    "name_pinyin": "beimaliyanaqundao",
    "code": "MNP",
    "childrens": []
  }, {
    "id": "44",
    "pid": "4",
    "path": ",4,44,",
    "level": "2",
    "name": "波多黎各",
    "name_en": "Puerto Rico",
    "name_pinyin": "boduolige",
    "code": "PRI",
    "childrens": []
  }, {
    "id": "49",
    "pid": "4",
    "path": ",4,49,",
    "level": "2",
    "name": "伯利兹",
    "name_en": "Belize",
    "name_pinyin": "bolizi",
    "code": "BLZ",
    "childrens": [{
      "id": "799",
      "pid": "49",
      "path": ",4,49,799,",
      "level": "3",
      "name": "伯利兹",
      "name_en": "Belize",
      "name_pinyin": "bolizi",
      "code": "BZ",
      "childrens": []
    }, {
      "id": "800",
      "pid": "49",
      "path": ",4,49,800,",
      "level": "3",
      "name": "橘园",
      "name_en": "Orange Walk",
      "name_pinyin": "yuan",
      "code": "OW",
      "childrens": []
    }, {
      "id": "801",
      "pid": "49",
      "path": ",4,49,801,",
      "level": "3",
      "name": "卡约",
      "name_en": "Cayo",
      "name_pinyin": "kayue",
      "code": "CY",
      "childrens": []
    }, {
      "id": "802",
      "pid": "49",
      "path": ",4,49,802,",
      "level": "3",
      "name": "科罗萨尔",
      "name_en": "Corozal",
      "name_pinyin": "keluosaer",
      "code": "CR",
      "childrens": []
    }, {
      "id": "803",
      "pid": "49",
      "path": ",4,49,803,",
      "level": "3",
      "name": "斯坦港",
      "name_en": "Stann Creek",
      "name_pinyin": "sitangang",
      "code": "SC",
      "childrens": []
    }, {
      "id": "804",
      "pid": "49",
      "path": ",4,49,804,",
      "level": "3",
      "name": "托莱多",
      "name_en": "Toledo",
      "name_pinyin": "tuolaiduo",
      "code": "TO",
      "childrens": []
    }]
  }, {
    "id": "59",
    "pid": "4",
    "path": ",4,59,",
    "level": "2",
    "name": "多米尼加",
    "name_en": "Dominica",
    "name_pinyin": "duominijia",
    "code": "DMA",
    "childrens": []
  }, {
    "id": "60",
    "pid": "4",
    "path": ",4,60,",
    "level": "2",
    "name": "多米尼加共和国",
    "name_en": "Dominican Republic",
    "name_pinyin": "duominijiagongheguo",
    "code": "DOM",
    "childrens": []
  }, {
    "id": "79",
    "pid": "4",
    "path": ",4,79,",
    "level": "2",
    "name": "哥斯达黎加",
    "name_en": "Costa Rica",
    "name_pinyin": "gesidalijia",
    "code": "CRI",
    "childrens": [{
      "id": "1165",
      "pid": "79",
      "path": ",4,79,1165,",
      "level": "3",
      "name": "阿拉胡埃拉",
      "name_en": "Alajuela",
      "name_pinyin": "alahuaila",
      "code": "A",
      "childrens": []
    }, {
      "id": "1166",
      "pid": "79",
      "path": ",4,79,1166,",
      "level": "3",
      "name": "埃雷迪亚",
      "name_en": "Heredia",
      "name_pinyin": "aileidiya",
      "code": "H",
      "childrens": []
    }, {
      "id": "1167",
      "pid": "79",
      "path": ",4,79,1167,",
      "level": "3",
      "name": "瓜纳卡斯特",
      "name_en": "Guanacaste",
      "name_pinyin": "guanakasite",
      "code": "G",
      "childrens": []
    }, {
      "id": "1168",
      "pid": "79",
      "path": ",4,79,1168,",
      "level": "3",
      "name": "卡塔戈",
      "name_en": "Cartago",
      "name_pinyin": "katage",
      "code": "C",
      "childrens": []
    }, {
      "id": "1169",
      "pid": "79",
      "path": ",4,79,1169,",
      "level": "3",
      "name": "利蒙",
      "name_en": "Limon",
      "name_pinyin": "limeng",
      "code": "L",
      "childrens": []
    }, {
      "id": "1170",
      "pid": "79",
      "path": ",4,79,1170,",
      "level": "3",
      "name": "蓬塔雷纳斯",
      "name_en": "Puntarenas",
      "name_pinyin": "pengtaleinasi",
      "code": "P",
      "childrens": []
    }, {
      "id": "1171",
      "pid": "79",
      "path": ",4,79,1171,",
      "level": "3",
      "name": "圣何塞",
      "name_en": "San Jose",
      "name_pinyin": "shenghesai",
      "code": "SJ",
      "childrens": []
    }]
  }, {
    "id": "81",
    "pid": "4",
    "path": ",4,81,",
    "level": "2",
    "name": "格林纳达",
    "name_en": "Grenada",
    "name_pinyin": "gelinnada",
    "code": "GRD",
    "childrens": []
  }, {
    "id": "82",
    "pid": "4",
    "path": ",4,82,",
    "level": "2",
    "name": "格陵兰",
    "name_en": "Greenland",
    "name_pinyin": "gelinglan",
    "code": "GRL",
    "childrens": []
  }, {
    "id": "83",
    "pid": "4",
    "path": ",4,83,",
    "level": "2",
    "name": "古巴",
    "name_en": "Cuba",
    "name_pinyin": "guba",
    "code": "CUB",
    "childrens": [{
      "id": "1172",
      "pid": "83",
      "path": ",4,83,1172,",
      "level": "3",
      "name": "奥尔金",
      "name_en": "Holguin",
      "name_pinyin": "aoerjin",
      "code": "11",
      "childrens": []
    }, {
      "id": "1173",
      "pid": "83",
      "path": ",4,83,1173,",
      "level": "3",
      "name": "比那尔德里奥",
      "name_en": "Pinar del Rio",
      "name_pinyin": "binaerdeliao",
      "code": "1",
      "childrens": []
    }, {
      "id": "1174",
      "pid": "83",
      "path": ",4,83,1174,",
      "level": "3",
      "name": "比亚克拉拉",
      "name_en": "Villa Clara",
      "name_pinyin": "biyakelala",
      "code": "5",
      "childrens": []
    }, {
      "id": "1175",
      "pid": "83",
      "path": ",4,83,1175,",
      "level": "3",
      "name": "格拉玛",
      "name_en": "Granma",
      "name_pinyin": "gelama",
      "code": "12",
      "childrens": []
    }, {
      "id": "1176",
      "pid": "83",
      "path": ",4,83,1176,",
      "level": "3",
      "name": "关塔那摩",
      "name_en": "Guantanamo",
      "name_pinyin": "guantanamo",
      "code": "14",
      "childrens": []
    }, {
      "id": "1177",
      "pid": "83",
      "path": ",4,83,1177,",
      "level": "3",
      "name": "哈瓦那",
      "name_en": "La Habana",
      "name_pinyin": "hawana",
      "code": "2",
      "childrens": []
    }, {
      "id": "1178",
      "pid": "83",
      "path": ",4,83,1178,",
      "level": "3",
      "name": "哈瓦那城",
      "name_en": "Ciudad de la Habana",
      "name_pinyin": "hawanacheng",
      "code": "3",
      "childrens": []
    }, {
      "id": "1179",
      "pid": "83",
      "path": ",4,83,1179,",
      "level": "3",
      "name": "卡马圭",
      "name_en": "Camaguey",
      "name_pinyin": "kamagui",
      "code": "9",
      "childrens": []
    }, {
      "id": "1180",
      "pid": "83",
      "path": ",4,83,1180,",
      "level": "3",
      "name": "拉斯图纳斯",
      "name_en": "Las Tunas",
      "name_pinyin": "lasitunasi",
      "code": "10",
      "childrens": []
    }, {
      "id": "1181",
      "pid": "83",
      "path": ",4,83,1181,",
      "level": "3",
      "name": "马坦萨斯",
      "name_en": "Matanzas",
      "name_pinyin": "matansasi",
      "code": "4",
      "childrens": []
    }, {
      "id": "1182",
      "pid": "83",
      "path": ",4,83,1182,",
      "level": "3",
      "name": "马亚里",
      "name_en": "Mayari",
      "name_pinyin": "mayali",
      "code": "MAY",
      "childrens": []
    }, {
      "id": "1183",
      "pid": "83",
      "path": ",4,83,1183,",
      "level": "3",
      "name": "曼萨尼罗",
      "name_en": "Manzanillo",
      "name_pinyin": "mansaniluo",
      "code": "MZO",
      "childrens": []
    }, {
      "id": "1184",
      "pid": "83",
      "path": ",4,83,1184,",
      "level": "3",
      "name": "青年岛特区",
      "name_en": "Isla de la Juventud",
      "name_pinyin": "qingniandaotequ",
      "code": "99",
      "childrens": []
    }, {
      "id": "1185",
      "pid": "83",
      "path": ",4,83,1185,",
      "level": "3",
      "name": "圣地亚哥",
      "name_en": "Santiago de Cuba",
      "name_pinyin": "shengdiyage",
      "code": "13",
      "childrens": []
    }, {
      "id": "1186",
      "pid": "83",
      "path": ",4,83,1186,",
      "level": "3",
      "name": "圣斯皮里图斯",
      "name_en": "Sancti Spiritus",
      "name_pinyin": "shengsipilitusi",
      "code": "7",
      "childrens": []
    }, {
      "id": "1187",
      "pid": "83",
      "path": ",4,83,1187,",
      "level": "3",
      "name": "西恩富戈斯",
      "name_en": "Cienfuegos",
      "name_pinyin": "xienfugesi",
      "code": "6",
      "childrens": []
    }, {
      "id": "1188",
      "pid": "83",
      "path": ",4,83,1188,",
      "level": "3",
      "name": "谢戈德阿维拉",
      "name_en": "Ciego de Avila",
      "name_pinyin": "xiegedeaweila",
      "code": "8",
      "childrens": []
    }]
  }, {
    "id": "84",
    "pid": "4",
    "path": ",4,84,",
    "level": "2",
    "name": "瓜德罗普",
    "name_en": "Guadeloupe",
    "name_pinyin": "guadeluopu",
    "code": "GLP",
    "childrens": []
  }, {
    "id": "88",
    "pid": "4",
    "path": ",4,88,",
    "level": "2",
    "name": "海地",
    "name_en": "Haiti",
    "name_pinyin": "haidi",
    "code": "HTI",
    "childrens": []
  }, {
    "id": "93",
    "pid": "4",
    "path": ",4,93,",
    "level": "2",
    "name": "洪都拉斯",
    "name_en": "Honduras",
    "name_pinyin": "hongdulasi",
    "code": "HND",
    "childrens": [{
      "id": "1264",
      "pid": "93",
      "path": ",4,93,1264,",
      "level": "3",
      "name": "阿特兰蒂达",
      "name_en": "Atlantida",
      "name_pinyin": "atelandida",
      "code": "AT",
      "childrens": []
    }, {
      "id": "1265",
      "pid": "93",
      "path": ",4,93,1265,",
      "level": "3",
      "name": "埃尔帕拉伊索",
      "name_en": "El Paraiso",
      "name_pinyin": "aierpalayisuo",
      "code": "PA",
      "childrens": []
    }, {
      "id": "1266",
      "pid": "93",
      "path": ",4,93,1266,",
      "level": "3",
      "name": "奥科特佩克",
      "name_en": "Ocotepeque",
      "name_pinyin": "aoketepeike",
      "code": "OC",
      "childrens": []
    }, {
      "id": "1267",
      "pid": "93",
      "path": ",4,93,1267,",
      "level": "3",
      "name": "奥兰乔",
      "name_en": "Olancho",
      "name_pinyin": "aolanqiao",
      "code": "OL",
      "childrens": []
    }, {
      "id": "1268",
      "pid": "93",
      "path": ",4,93,1268,",
      "level": "3",
      "name": "弗朗西斯科-莫拉桑",
      "name_en": "Francisco Morazan",
      "name_pinyin": "fulangxisikemolasang",
      "code": "FM",
      "childrens": []
    }, {
      "id": "1269",
      "pid": "93",
      "path": ",4,93,1269,",
      "level": "3",
      "name": "格拉西亚斯-阿迪奥斯",
      "name_en": "Gracias a Dios",
      "name_pinyin": "gelaxiyasiadiaosi",
      "code": "GD",
      "childrens": []
    }, {
      "id": "1270",
      "pid": "93",
      "path": ",4,93,1270,",
      "level": "3",
      "name": "海湾群岛",
      "name_en": "Islas de la Bahia",
      "name_pinyin": "haiwanqundao",
      "code": "IB",
      "childrens": []
    }, {
      "id": "1271",
      "pid": "93",
      "path": ",4,93,1271,",
      "level": "3",
      "name": "科尔特斯",
      "name_en": "Cortes",
      "name_pinyin": "keertesi",
      "code": "CR",
      "childrens": []
    }, {
      "id": "1272",
      "pid": "93",
      "path": ",4,93,1272,",
      "level": "3",
      "name": "科隆",
      "name_en": "Colon",
      "name_pinyin": "kelong",
      "code": "CL",
      "childrens": []
    }, {
      "id": "1273",
      "pid": "93",
      "path": ",4,93,1273,",
      "level": "3",
      "name": "科马亚瓜",
      "name_en": "Comayagua",
      "name_pinyin": "kemayagua",
      "code": "CM",
      "childrens": []
    }, {
      "id": "1274",
      "pid": "93",
      "path": ",4,93,1274,",
      "level": "3",
      "name": "科潘",
      "name_en": "Copan",
      "name_pinyin": "kepan",
      "code": "CP",
      "childrens": []
    }, {
      "id": "1275",
      "pid": "93",
      "path": ",4,93,1275,",
      "level": "3",
      "name": "拉巴斯",
      "name_en": "La Paz",
      "name_pinyin": "labasi",
      "code": "PZ",
      "childrens": []
    }, {
      "id": "1276",
      "pid": "93",
      "path": ",4,93,1276,",
      "level": "3",
      "name": "伦皮拉",
      "name_en": "Lempira",
      "name_pinyin": "lunpila",
      "code": "LE",
      "childrens": []
    }, {
      "id": "1277",
      "pid": "93",
      "path": ",4,93,1277,",
      "level": "3",
      "name": "乔卢特卡",
      "name_en": "Choluteca",
      "name_pinyin": "qiaoluteka",
      "code": "CH",
      "childrens": []
    }, {
      "id": "1278",
      "pid": "93",
      "path": ",4,93,1278,",
      "level": "3",
      "name": "乔罗马",
      "name_en": "Choloma",
      "name_pinyin": "qiaoluoma",
      "code": "CHO",
      "childrens": []
    }, {
      "id": "1279",
      "pid": "93",
      "path": ",4,93,1279,",
      "level": "3",
      "name": "山谷",
      "name_en": "Valle",
      "name_pinyin": "shangu",
      "code": "VA",
      "childrens": []
    }, {
      "id": "1280",
      "pid": "93",
      "path": ",4,93,1280,",
      "level": "3",
      "name": "圣巴巴拉",
      "name_en": "Santa Barbara",
      "name_pinyin": "shengbabala",
      "code": "SB",
      "childrens": []
    }, {
      "id": "1281",
      "pid": "93",
      "path": ",4,93,1281,",
      "level": "3",
      "name": "因蒂布卡",
      "name_en": "Intibuca",
      "name_pinyin": "yindibuka",
      "code": "IN",
      "childrens": []
    }, {
      "id": "1282",
      "pid": "93",
      "path": ",4,93,1282,",
      "level": "3",
      "name": "约罗",
      "name_en": "Yoro",
      "name_pinyin": "yueluo",
      "code": "YO",
      "childrens": []
    }]
  }, {
    "id": "99",
    "pid": "4",
    "path": ",4,99,",
    "level": "2",
    "name": "加拿大",
    "name_en": "Canada",
    "name_pinyin": "jianada",
    "code": "CAN",
    "childrens": [{
      "id": "1314",
      "pid": "99",
      "path": ",4,99,1314,",
      "level": "3",
      "name": "阿伯茨福",
      "name_en": "Abbotsford",
      "name_pinyin": "abocifu",
      "code": "ABB",
      "childrens": []
    }, {
      "id": "1315",
      "pid": "99",
      "path": ",4,99,1315,",
      "level": "3",
      "name": "埃德蒙顿",
      "name_en": "Edmonton",
      "name_pinyin": "aidemengdun",
      "code": "EDM",
      "childrens": []
    }, {
      "id": "1316",
      "pid": "99",
      "path": ",4,99,1316,",
      "level": "3",
      "name": "奥沙瓦",
      "name_en": "Oshawa",
      "name_pinyin": "aoshawa",
      "code": "OSH",
      "childrens": []
    }, {
      "id": "1317",
      "pid": "99",
      "path": ",4,99,1317,",
      "level": "3",
      "name": "巴里",
      "name_en": "Barrie",
      "name_pinyin": "bali",
      "code": "BAR",
      "childrens": []
    }, {
      "id": "1318",
      "pid": "99",
      "path": ",4,99,1318,",
      "level": "3",
      "name": "布列塔尼角",
      "name_en": "Cape Breton",
      "name_pinyin": "bulietanijiao",
      "code": "CBR",
      "childrens": []
    }, {
      "id": "1319",
      "pid": "99",
      "path": ",4,99,1319,",
      "level": "3",
      "name": "多伦多",
      "name_en": "Toronto",
      "name_pinyin": "duolunduo",
      "code": "TOR",
      "childrens": []
    }, {
      "id": "1320",
      "pid": "99",
      "path": ",4,99,1320,",
      "level": "3",
      "name": "弗雷德里顿",
      "name_en": "Fredericton",
      "name_pinyin": "fuleidelidun",
      "code": "FRE",
      "childrens": []
    }, {
      "id": "1321",
      "pid": "99",
      "path": ",4,99,1321,",
      "level": "3",
      "name": "圭尔夫",
      "name_en": "Guelph",
      "name_pinyin": "guierfu",
      "code": "GLP",
      "childrens": []
    }, {
      "id": "1322",
      "pid": "99",
      "path": ",4,99,1322,",
      "level": "3",
      "name": "哈利法克斯",
      "name_en": "Halifax",
      "name_pinyin": "halifakesi",
      "code": "HAL",
      "childrens": []
    }, {
      "id": "1323",
      "pid": "99",
      "path": ",4,99,1323,",
      "level": "3",
      "name": "哈密尔顿",
      "name_en": "Hamilton",
      "name_pinyin": "hamierdun",
      "code": "HAM",
      "childrens": []
    }, {
      "id": "1324",
      "pid": "99",
      "path": ",4,99,1324,",
      "level": "3",
      "name": "怀特霍斯",
      "name_en": "Whitehorse",
      "name_pinyin": "huaitehuosi",
      "code": "YXY",
      "childrens": []
    }, {
      "id": "1325",
      "pid": "99",
      "path": ",4,99,1325,",
      "level": "3",
      "name": "基劳纳",
      "name_en": "Kelowna",
      "name_pinyin": "jilaona",
      "code": "KWL",
      "childrens": []
    }, {
      "id": "1326",
      "pid": "99",
      "path": ",4,99,1326,",
      "level": "3",
      "name": "基奇纳",
      "name_en": "Brampton",
      "name_pinyin": "jiqina",
      "code": "BRP",
      "childrens": []
    }, {
      "id": "1327",
      "pid": "99",
      "path": ",4,99,1327,",
      "level": "3",
      "name": "金斯敦",
      "name_en": "Kingston",
      "name_pinyin": "jinsidun",
      "code": "KGN",
      "childrens": []
    }, {
      "id": "1328",
      "pid": "99",
      "path": ",4,99,1328,",
      "level": "3",
      "name": "卡里加里",
      "name_en": "Calgary",
      "name_pinyin": "kalijiali",
      "code": "CAL",
      "childrens": []
    }, {
      "id": "1329",
      "pid": "99",
      "path": ",4,99,1329,",
      "level": "3",
      "name": "魁北克",
      "name_en": "Quebec",
      "name_pinyin": "kuibeike",
      "code": "QUE",
      "childrens": []
    }, {
      "id": "1330",
      "pid": "99",
      "path": ",4,99,1330,",
      "level": "3",
      "name": "里贾纳",
      "name_en": "Regina",
      "name_pinyin": "lijiana",
      "code": "REG",
      "childrens": []
    }, {
      "id": "1331",
      "pid": "99",
      "path": ",4,99,1331,",
      "level": "3",
      "name": "伦敦",
      "name_en": "London",
      "name_pinyin": "lundun",
      "code": "LOD",
      "childrens": []
    }, {
      "id": "1332",
      "pid": "99",
      "path": ",4,99,1332,",
      "level": "3",
      "name": "蒙特利尔",
      "name_en": "Montreal",
      "name_pinyin": "mengtelier",
      "code": "MTR",
      "childrens": []
    }, {
      "id": "1333",
      "pid": "99",
      "path": ",4,99,1333,",
      "level": "3",
      "name": "萨德伯里",
      "name_en": "Sudbury",
      "name_pinyin": "sadeboli",
      "code": "SUD",
      "childrens": []
    }, {
      "id": "1334",
      "pid": "99",
      "path": ",4,99,1334,",
      "level": "3",
      "name": "萨斯卡通",
      "name_en": "Saskatoon",
      "name_pinyin": "sasikatong",
      "code": "SAK",
      "childrens": []
    }, {
      "id": "1335",
      "pid": "99",
      "path": ",4,99,1335,",
      "level": "3",
      "name": "三河城",
      "name_en": "Trois-Rivieres",
      "name_pinyin": "sanhecheng",
      "code": "TRR",
      "childrens": []
    }, {
      "id": "1336",
      "pid": "99",
      "path": ",4,99,1336,",
      "level": "3",
      "name": "桑德贝",
      "name_en": "Thunder Bay",
      "name_pinyin": "sangdebei",
      "code": "THU",
      "childrens": []
    }, {
      "id": "1337",
      "pid": "99",
      "path": ",4,99,1337,",
      "level": "3",
      "name": "舍布鲁克",
      "name_en": "Sherbrooke",
      "name_pinyin": "shebuluke",
      "code": "SBE",
      "childrens": []
    }, {
      "id": "1338",
      "pid": "99",
      "path": ",4,99,1338,",
      "level": "3",
      "name": "圣卡塔琳娜",
      "name_en": "St. Catharines",
      "name_pinyin": "shengkatalinna",
      "code": "SCA",
      "childrens": []
    }, {
      "id": "1339",
      "pid": "99",
      "path": ",4,99,1339,",
      "level": "3",
      "name": "圣约翰斯",
      "name_en": "Saint-John's",
      "name_pinyin": "shengyuehansi",
      "code": "SJB",
      "childrens": []
    }, {
      "id": "1340",
      "pid": "99",
      "path": ",4,99,1340,",
      "level": "3",
      "name": "维多利亚",
      "name_en": "Victoria",
      "name_pinyin": "weiduoliya",
      "code": "VIC",
      "childrens": []
    }, {
      "id": "1341",
      "pid": "99",
      "path": ",4,99,1341,",
      "level": "3",
      "name": "温哥华",
      "name_en": "Vancouver",
      "name_pinyin": "wengehua",
      "code": "VAN",
      "childrens": []
    }, {
      "id": "1342",
      "pid": "99",
      "path": ",4,99,1342,",
      "level": "3",
      "name": "温尼伯",
      "name_en": "Winnipeg",
      "name_pinyin": "wennibo",
      "code": "WNP",
      "childrens": []
    }, {
      "id": "1343",
      "pid": "99",
      "path": ",4,99,1343,",
      "level": "3",
      "name": "温莎",
      "name_en": "Windsor",
      "name_pinyin": "wensha",
      "code": "WDR",
      "childrens": []
    }, {
      "id": "1344",
      "pid": "99",
      "path": ",4,99,1344,",
      "level": "3",
      "name": "渥太华",
      "name_en": "Ottawa",
      "name_pinyin": "taihua",
      "code": "OTT",
      "childrens": []
    }, {
      "id": "1345",
      "pid": "99",
      "path": ",4,99,1345,",
      "level": "3",
      "name": "夏洛特敦",
      "name_en": "Charlottetown",
      "name_pinyin": "xialuotedun",
      "code": "CHA",
      "childrens": []
    }, {
      "id": "1346",
      "pid": "99",
      "path": ",4,99,1346,",
      "level": "3",
      "name": "耶洛奈夫",
      "name_en": "Yellowknife",
      "name_pinyin": "yeluonaifu",
      "code": "YZF",
      "childrens": []
    }, {
      "id": "1347",
      "pid": "99",
      "path": ",4,99,1347,",
      "level": "3",
      "name": "伊魁特",
      "name_en": "Iqaluit",
      "name_pinyin": "yikuite",
      "code": "IQL",
      "childrens": []
    }]
  }, {
    "id": "107",
    "pid": "4",
    "path": ",4,107,",
    "level": "2",
    "name": "开曼群岛",
    "name_en": "Cayman Islands",
    "name_pinyin": "kaimanqundao",
    "code": "CYM",
    "childrens": []
  }, {
    "id": "135",
    "pid": "4",
    "path": ",4,135,",
    "level": "2",
    "name": "马提尼克",
    "name_en": "Martinique",
    "name_pinyin": "matinike",
    "code": "MTQ",
    "childrens": []
  }, {
    "id": "140",
    "pid": "4",
    "path": ",4,140,",
    "level": "2",
    "name": "美国",
    "name_en": "United States",
    "name_pinyin": "meiguo",
    "code": "USA",
    "childrens": [{
      "id": "320",
      "pid": "140",
      "path": ",4,140,320,",
      "level": "3",
      "name": "阿肯色",
      "name_en": "Arkansas",
      "name_pinyin": "akense",
      "code": "AR",
      "childrens": [{
        "id": "3824",
        "pid": "320",
        "path": ",4,140,320,3824,",
        "level": "4",
        "name": "费耶特维尔",
        "name_en": "Fayetteville",
        "name_pinyin": "feiyeteweier",
        "code": "FYV",
        "childrens": []
      }, {
        "id": "3825",
        "pid": "320",
        "path": ",4,140,320,3825,",
        "level": "4",
        "name": "史密斯堡",
        "name_en": "Fort Smith",
        "name_pinyin": "shimisibao",
        "code": "FSM",
        "childrens": []
      }, {
        "id": "3826",
        "pid": "320",
        "path": ",4,140,320,3826,",
        "level": "4",
        "name": "小石城",
        "name_en": "Little Rock",
        "name_pinyin": "xiaoshicheng",
        "code": "LIT",
        "childrens": []
      }]
    }, {
      "id": "321",
      "pid": "140",
      "path": ",4,140,321,",
      "level": "3",
      "name": "阿拉巴马",
      "name_en": "Alabama",
      "name_pinyin": "alabama",
      "code": "AL",
      "childrens": [{
        "id": "3827",
        "pid": "321",
        "path": ",4,140,321,3827,",
        "level": "4",
        "name": "伯明罕",
        "name_en": "Birmingham",
        "name_pinyin": "bominghan",
        "code": "BHM",
        "childrens": []
      }, {
        "id": "3828",
        "pid": "321",
        "path": ",4,140,321,3828,",
        "level": "4",
        "name": "蒙哥马利",
        "name_en": "Montgomery",
        "name_pinyin": "menggemali",
        "code": "MGM",
        "childrens": []
      }, {
        "id": "3829",
        "pid": "321",
        "path": ",4,140,321,3829,",
        "level": "4",
        "name": "莫比尔",
        "name_en": "Mobile",
        "name_pinyin": "mobier",
        "code": "MOB",
        "childrens": []
      }]
    }, {
      "id": "322",
      "pid": "140",
      "path": ",4,140,322,",
      "level": "3",
      "name": "阿拉斯加",
      "name_en": "Alaska",
      "name_pinyin": "alasijia",
      "code": "AK",
      "childrens": [{
        "id": "3830",
        "pid": "322",
        "path": ",4,140,322,3830,",
        "level": "4",
        "name": "安克雷奇",
        "name_en": "Anchorage",
        "name_pinyin": "ankeleiqi",
        "code": "ANC",
        "childrens": []
      }, {
        "id": "3831",
        "pid": "322",
        "path": ",4,140,322,3831,",
        "level": "4",
        "name": "费尔班克斯",
        "name_en": "Fairbanks",
        "name_pinyin": "feierbankesi",
        "code": "FAI",
        "childrens": []
      }, {
        "id": "3832",
        "pid": "322",
        "path": ",4,140,322,3832,",
        "level": "4",
        "name": "朱诺",
        "name_en": "Juneau",
        "name_pinyin": "zhunuo",
        "code": "JNU",
        "childrens": []
      }]
    }, {
      "id": "323",
      "pid": "140",
      "path": ",4,140,323,",
      "level": "3",
      "name": "爱达荷",
      "name_en": "Idaho",
      "name_pinyin": "aidahe",
      "code": "ID",
      "childrens": [{
        "id": "3833",
        "pid": "323",
        "path": ",4,140,323,3833,",
        "level": "4",
        "name": "爱达荷福尔斯",
        "name_en": "Idaho Falls",
        "name_pinyin": "aidahefuersi",
        "code": "IDA",
        "childrens": []
      }, {
        "id": "3834",
        "pid": "323",
        "path": ",4,140,323,3834,",
        "level": "4",
        "name": "波卡特洛",
        "name_en": "Pocatello",
        "name_pinyin": "bokateluo",
        "code": "PIH",
        "childrens": []
      }, {
        "id": "3835",
        "pid": "323",
        "path": ",4,140,323,3835,",
        "level": "4",
        "name": "博伊西",
        "name_en": "Boise",
        "name_pinyin": "boyixi",
        "code": "BOI",
        "childrens": []
      }, {
        "id": "3836",
        "pid": "323",
        "path": ",4,140,323,3836,",
        "level": "4",
        "name": "布莱克富特",
        "name_en": "Blackfoot",
        "name_pinyin": "bulaikefute",
        "code": "BLK",
        "childrens": []
      }, {
        "id": "3837",
        "pid": "323",
        "path": ",4,140,323,3837,",
        "level": "4",
        "name": "科达伦",
        "name_en": "Coeur d'Alene",
        "name_pinyin": "kedalun",
        "code": "COE",
        "childrens": []
      }, {
        "id": "3838",
        "pid": "323",
        "path": ",4,140,323,3838,",
        "level": "4",
        "name": "刘易斯顿",
        "name_en": "Lewiston",
        "name_pinyin": "liuyisidun",
        "code": "LWS",
        "childrens": []
      }, {
        "id": "3839",
        "pid": "323",
        "path": ",4,140,323,3839,",
        "level": "4",
        "name": "莫斯科",
        "name_en": "Moscow",
        "name_pinyin": "mosike",
        "code": "MJL",
        "childrens": []
      }, {
        "id": "3840",
        "pid": "323",
        "path": ",4,140,323,3840,",
        "level": "4",
        "name": "墨菲",
        "name_en": "Murphy",
        "name_pinyin": "mofei",
        "code": "ZMU",
        "childrens": []
      }, {
        "id": "3841",
        "pid": "323",
        "path": ",4,140,323,3841,",
        "level": "4",
        "name": "楠帕",
        "name_en": "Nampa",
        "name_pinyin": "pa",
        "code": "NPA",
        "childrens": []
      }, {
        "id": "3842",
        "pid": "323",
        "path": ",4,140,323,3842,",
        "level": "4",
        "name": "岂彻姆",
        "name_en": "Ketchum",
        "name_pinyin": "qichemu",
        "code": "QKM",
        "childrens": []
      }, {
        "id": "3843",
        "pid": "323",
        "path": ",4,140,323,3843,",
        "level": "4",
        "name": "森瓦利",
        "name_en": "Sun Valley",
        "name_pinyin": "senwali",
        "code": "SVY",
        "childrens": []
      }, {
        "id": "3844",
        "pid": "323",
        "path": ",4,140,323,3844,",
        "level": "4",
        "name": "亚美利加瀑布城",
        "name_en": "American Falls",
        "name_pinyin": "yameilijiapubucheng",
        "code": "YAF",
        "childrens": []
      }]
    }, {
      "id": "324",
      "pid": "140",
      "path": ",4,140,324,",
      "level": "3",
      "name": "爱荷华",
      "name_en": "Iowa",
      "name_pinyin": "aihehua",
      "code": "IA",
      "childrens": [{
        "id": "3845",
        "pid": "324",
        "path": ",4,140,324,3845,",
        "level": "4",
        "name": "达文波特",
        "name_en": "Davenport",
        "name_pinyin": "dawenbote",
        "code": "DVN",
        "childrens": []
      }, {
        "id": "3846",
        "pid": "324",
        "path": ",4,140,324,3846,",
        "level": "4",
        "name": "得梅因",
        "name_en": "Des Moines",
        "name_pinyin": "demeiyin",
        "code": "DSM",
        "childrens": []
      }, {
        "id": "3847",
        "pid": "324",
        "path": ",4,140,324,3847,",
        "level": "4",
        "name": "锡达拉皮兹",
        "name_en": "Cedar Rapids",
        "name_pinyin": "xidalapizi",
        "code": "CID",
        "childrens": []
      }]
    }, {
      "id": "325",
      "pid": "140",
      "path": ",4,140,325,",
      "level": "3",
      "name": "北达科他",
      "name_en": "North Dakota",
      "name_pinyin": "beidaketa",
      "code": "ND",
      "childrens": [{
        "id": "3848",
        "pid": "325",
        "path": ",4,140,325,3848,",
        "level": "4",
        "name": "俾斯麦",
        "name_en": "Bismarck",
        "name_pinyin": "simai",
        "code": "BIS",
        "childrens": []
      }, {
        "id": "3849",
        "pid": "325",
        "path": ",4,140,325,3849,",
        "level": "4",
        "name": "大福克斯",
        "name_en": "Grand Forks",
        "name_pinyin": "dafukesi",
        "code": "GFK",
        "childrens": []
      }, {
        "id": "3850",
        "pid": "325",
        "path": ",4,140,325,3850,",
        "level": "4",
        "name": "法戈",
        "name_en": "Fargo",
        "name_pinyin": "fage",
        "code": "FAR",
        "childrens": []
      }, {
        "id": "3851",
        "pid": "325",
        "path": ",4,140,325,3851,",
        "level": "4",
        "name": "迈诺特",
        "name_en": "Minot",
        "name_pinyin": "mainuote",
        "code": "MOT",
        "childrens": []
      }]
    }, {
      "id": "326",
      "pid": "140",
      "path": ",4,140,326,",
      "level": "3",
      "name": "北卡罗来纳",
      "name_en": "North Carolina",
      "name_pinyin": "beikaluolaina",
      "code": "NC",
      "childrens": [{
        "id": "3852",
        "pid": "326",
        "path": ",4,140,326,3852,",
        "level": "4",
        "name": "艾许维尔",
        "name_en": "Asheville",
        "name_pinyin": "aixuweier",
        "code": "AEV",
        "childrens": []
      }, {
        "id": "3853",
        "pid": "326",
        "path": ",4,140,326,3853,",
        "level": "4",
        "name": "杜罕",
        "name_en": "Durham",
        "name_pinyin": "duhan",
        "code": "DHH",
        "childrens": []
      }, {
        "id": "3854",
        "pid": "326",
        "path": ",4,140,326,3854,",
        "level": "4",
        "name": "格林斯伯勒",
        "name_en": "Greensboro",
        "name_pinyin": "gelinsibole",
        "code": "GBO",
        "childrens": []
      }, {
        "id": "3855",
        "pid": "326",
        "path": ",4,140,326,3855,",
        "level": "4",
        "name": "教堂山",
        "name_en": "Chapel Hill",
        "name_pinyin": "jiaotangshan",
        "code": "CHE",
        "childrens": []
      }, {
        "id": "3856",
        "pid": "326",
        "path": ",4,140,326,3856,",
        "level": "4",
        "name": "罗利",
        "name_en": "Raleigh",
        "name_pinyin": "luoli",
        "code": "RAG",
        "childrens": []
      }, {
        "id": "3857",
        "pid": "326",
        "path": ",4,140,326,3857,",
        "level": "4",
        "name": "洛利杜罕都会区",
        "name_en": "Raleigh-Durham",
        "name_pinyin": "luoliduhanduhuiqu",
        "code": "RDU",
        "childrens": []
      }, {
        "id": "3858",
        "pid": "326",
        "path": ",4,140,326,3858,",
        "level": "4",
        "name": "夏洛特",
        "name_en": "Charlotte",
        "name_pinyin": "xialuote",
        "code": "CRQ",
        "childrens": []
      }]
    }, {
      "id": "327",
      "pid": "140",
      "path": ",4,140,327,",
      "level": "3",
      "name": "宾夕法尼亚",
      "name_en": "Pennsylvania",
      "name_pinyin": "binxifaniya",
      "code": "PA",
      "childrens": [{
        "id": "3859",
        "pid": "327",
        "path": ",4,140,327,3859,",
        "level": "4",
        "name": "阿伦敦",
        "name_en": "Allentown",
        "name_pinyin": "alundun",
        "code": "AEW",
        "childrens": []
      }, {
        "id": "3860",
        "pid": "327",
        "path": ",4,140,327,3860,",
        "level": "4",
        "name": "费城",
        "name_en": "Philadephia",
        "name_pinyin": "feicheng",
        "code": "PHL",
        "childrens": []
      }, {
        "id": "3861",
        "pid": "327",
        "path": ",4,140,327,3861,",
        "level": "4",
        "name": "匹兹堡",
        "name_en": "Pittsburgh",
        "name_pinyin": "pizibao",
        "code": "PIT",
        "childrens": []
      }]
    }, {
      "id": "328",
      "pid": "140",
      "path": ",4,140,328,",
      "level": "3",
      "name": "德克萨斯",
      "name_en": "Texas",
      "name_pinyin": "dekesasi",
      "code": "TX",
      "childrens": [{
        "id": "3862",
        "pid": "328",
        "path": ",4,140,328,3862,",
        "level": "4",
        "name": "埃尔帕索",
        "name_en": "El Paso",
        "name_pinyin": "aierpasuo",
        "code": "ELP",
        "childrens": []
      }, {
        "id": "3863",
        "pid": "328",
        "path": ",4,140,328,3863,",
        "level": "4",
        "name": "奥斯汀",
        "name_en": "Austin",
        "name_pinyin": "aositing",
        "code": "AUS",
        "childrens": []
      }, {
        "id": "3864",
        "pid": "328",
        "path": ",4,140,328,3864,",
        "level": "4",
        "name": "达拉斯",
        "name_en": "Dallas",
        "name_pinyin": "dalasi",
        "code": "DAL",
        "childrens": []
      }, {
        "id": "3865",
        "pid": "328",
        "path": ",4,140,328,3865,",
        "level": "4",
        "name": "哥帕斯基斯蒂",
        "name_en": "Corpus Christi",
        "name_pinyin": "gepasijisidi",
        "code": "CRP",
        "childrens": []
      }, {
        "id": "3866",
        "pid": "328",
        "path": ",4,140,328,3866,",
        "level": "4",
        "name": "交维斯顿",
        "name_en": "Galveston",
        "name_pinyin": "jiaoweisidun",
        "code": "GLS",
        "childrens": []
      }, {
        "id": "3867",
        "pid": "328",
        "path": ",4,140,328,3867,",
        "level": "4",
        "name": "拉雷多",
        "name_en": "Laredo",
        "name_pinyin": "laleiduo",
        "code": "LRD",
        "childrens": []
      }, {
        "id": "3868",
        "pid": "328",
        "path": ",4,140,328,3868,",
        "level": "4",
        "name": "麦亚伦",
        "name_en": "McAllen",
        "name_pinyin": "maiyalun",
        "code": "TXC",
        "childrens": []
      }, {
        "id": "3869",
        "pid": "328",
        "path": ",4,140,328,3869,",
        "level": "4",
        "name": "圣安东尼奥",
        "name_en": "San Antonio",
        "name_pinyin": "shengandongniao",
        "code": "SAT",
        "childrens": []
      }, {
        "id": "3870",
        "pid": "328",
        "path": ",4,140,328,3870,",
        "level": "4",
        "name": "休斯敦",
        "name_en": "Houston",
        "name_pinyin": "xiusidun",
        "code": "HOU",
        "childrens": []
      }]
    }, {
      "id": "329",
      "pid": "140",
      "path": ",4,140,329,",
      "level": "3",
      "name": "俄亥俄",
      "name_en": "Ohio",
      "name_pinyin": "ehaie",
      "code": "OH",
      "childrens": [{
        "id": "3871",
        "pid": "329",
        "path": ",4,140,329,3871,",
        "level": "4",
        "name": "代顿",
        "name_en": "Dayton",
        "name_pinyin": "daidun",
        "code": "DYT",
        "childrens": []
      }, {
        "id": "3872",
        "pid": "329",
        "path": ",4,140,329,3872,",
        "level": "4",
        "name": "哥伦布",
        "name_en": "Columbus",
        "name_pinyin": "gelunbu",
        "code": "CZX",
        "childrens": []
      }, {
        "id": "3873",
        "pid": "329",
        "path": ",4,140,329,3873,",
        "level": "4",
        "name": "克利夫兰",
        "name_en": "Cleveland",
        "name_pinyin": "kelifulan",
        "code": "CLE",
        "childrens": []
      }, {
        "id": "3874",
        "pid": "329",
        "path": ",4,140,329,3874,",
        "level": "4",
        "name": "托莱多",
        "name_en": "Toledo",
        "name_pinyin": "tuolaiduo",
        "code": "TOL",
        "childrens": []
      }, {
        "id": "3875",
        "pid": "329",
        "path": ",4,140,329,3875,",
        "level": "4",
        "name": "辛辛那提",
        "name_en": "Cincinnati",
        "name_pinyin": "xinxinnati",
        "code": "CVG",
        "childrens": []
      }]
    }, {
      "id": "330",
      "pid": "140",
      "path": ",4,140,330,",
      "level": "3",
      "name": "俄克拉荷马",
      "name_en": "Oklahoma",
      "name_pinyin": "ekelahema",
      "code": "OK",
      "childrens": [{
        "id": "3876",
        "pid": "330",
        "path": ",4,140,330,3876,",
        "level": "4",
        "name": "俄克拉荷马城",
        "name_en": "Oklahoma City",
        "name_pinyin": "ekelahemacheng",
        "code": "OKC",
        "childrens": []
      }, {
        "id": "3877",
        "pid": "330",
        "path": ",4,140,330,3877,",
        "level": "4",
        "name": "诺曼",
        "name_en": "Norman",
        "name_pinyin": "nuoman",
        "code": "OUN",
        "childrens": []
      }, {
        "id": "3878",
        "pid": "330",
        "path": ",4,140,330,3878,",
        "level": "4",
        "name": "塔尔萨",
        "name_en": "Tulsa",
        "name_pinyin": "taersa",
        "code": "TUL",
        "childrens": []
      }]
    }, {
      "id": "331",
      "pid": "140",
      "path": ",4,140,331,",
      "level": "3",
      "name": "俄勒冈",
      "name_en": "Oregon",
      "name_pinyin": "elegang",
      "code": "OR",
      "childrens": [{
        "id": "3879",
        "pid": "331",
        "path": ",4,140,331,3879,",
        "level": "4",
        "name": "本德",
        "name_en": "Bend",
        "name_pinyin": "bende",
        "code": "BZO",
        "childrens": []
      }, {
        "id": "3880",
        "pid": "331",
        "path": ",4,140,331,3880,",
        "level": "4",
        "name": "波特兰",
        "name_en": "Portland",
        "name_pinyin": "botelan",
        "code": "PDX",
        "childrens": []
      }, {
        "id": "3881",
        "pid": "331",
        "path": ",4,140,331,3881,",
        "level": "4",
        "name": "达尔斯",
        "name_en": "The Dalles",
        "name_pinyin": "daersi",
        "code": "DLS",
        "childrens": []
      }, {
        "id": "3882",
        "pid": "331",
        "path": ",4,140,331,3882,",
        "level": "4",
        "name": "达拉斯",
        "name_en": "Dallas",
        "name_pinyin": "dalasi",
        "code": "DAC",
        "childrens": []
      }, {
        "id": "3883",
        "pid": "331",
        "path": ",4,140,331,3883,",
        "level": "4",
        "name": "蒂拉穆克",
        "name_en": "Tillamook",
        "name_pinyin": "dilamuke",
        "code": "TLM",
        "childrens": []
      }, {
        "id": "3884",
        "pid": "331",
        "path": ",4,140,331,3884,",
        "level": "4",
        "name": "格兰茨帕斯",
        "name_en": "Grant's Pass",
        "name_pinyin": "gelancipasi",
        "code": "XFX",
        "childrens": []
      }, {
        "id": "3885",
        "pid": "331",
        "path": ",4,140,331,3885,",
        "level": "4",
        "name": "胡德里弗",
        "name_en": "Hood River",
        "name_pinyin": "hudelifu",
        "code": "HDX",
        "childrens": []
      }, {
        "id": "3886",
        "pid": "331",
        "path": ",4,140,331,3886,",
        "level": "4",
        "name": "火山口湖",
        "name_en": "Crater Lake",
        "name_pinyin": "huoshankouhu",
        "code": "CTR",
        "childrens": []
      }, {
        "id": "3887",
        "pid": "331",
        "path": ",4,140,331,3887,",
        "level": "4",
        "name": "科瓦利斯",
        "name_en": "Corvallis",
        "name_pinyin": "kewalisi",
        "code": "YCV",
        "childrens": []
      }, {
        "id": "3888",
        "pid": "331",
        "path": ",4,140,331,3888,",
        "level": "4",
        "name": "库斯贝",
        "name_en": "Coos Bay",
        "name_pinyin": "kusibei",
        "code": "COB",
        "childrens": []
      }, {
        "id": "3889",
        "pid": "331",
        "path": ",4,140,331,3889,",
        "level": "4",
        "name": "梅德福",
        "name_en": "Medford",
        "name_pinyin": "meidefu",
        "code": "MFR",
        "childrens": []
      }, {
        "id": "3890",
        "pid": "331",
        "path": ",4,140,331,3890,",
        "level": "4",
        "name": "塞勒姆",
        "name_en": "Salem",
        "name_pinyin": "sailemu",
        "code": "SLE",
        "childrens": []
      }, {
        "id": "3891",
        "pid": "331",
        "path": ",4,140,331,3891,",
        "level": "4",
        "name": "圣海伦斯",
        "name_en": "St Helens",
        "name_pinyin": "shenghailunsi",
        "code": "STH",
        "childrens": []
      }, {
        "id": "3892",
        "pid": "331",
        "path": ",4,140,331,3892,",
        "level": "4",
        "name": "斯普林菲尔德",
        "name_en": "Springfield",
        "name_pinyin": "sipulinfeierde",
        "code": "SPY",
        "childrens": []
      }, {
        "id": "3893",
        "pid": "331",
        "path": ",4,140,331,3893,",
        "level": "4",
        "name": "尤金",
        "name_en": "Eugene",
        "name_pinyin": "youjin",
        "code": "EUG",
        "childrens": []
      }]
    }, {
      "id": "332",
      "pid": "140",
      "path": ",4,140,332,",
      "level": "3",
      "name": "佛罗里达",
      "name_en": "Florida",
      "name_pinyin": "foluolida",
      "code": "FL",
      "childrens": [{
        "id": "3894",
        "pid": "332",
        "path": ",4,140,332,3894,",
        "level": "4",
        "name": "奥兰多",
        "name_en": "Orlando",
        "name_pinyin": "aolanduo",
        "code": "ORL",
        "childrens": []
      }, {
        "id": "3895",
        "pid": "332",
        "path": ",4,140,332,3895,",
        "level": "4",
        "name": "基韦斯特",
        "name_en": "Key West",
        "name_pinyin": "jiweisite",
        "code": "EYW",
        "childrens": []
      }, {
        "id": "3896",
        "pid": "332",
        "path": ",4,140,332,3896,",
        "level": "4",
        "name": "杰克逊维尔",
        "name_en": "Jacksonville",
        "name_pinyin": "jiekexunweier",
        "code": "JAX",
        "childrens": []
      }, {
        "id": "3897",
        "pid": "332",
        "path": ",4,140,332,3897,",
        "level": "4",
        "name": "卡纳维尔角",
        "name_en": "Cape Canaveral",
        "name_pinyin": "kanaweierjiao",
        "code": "CPV",
        "childrens": []
      }, {
        "id": "3898",
        "pid": "332",
        "path": ",4,140,332,3898,",
        "level": "4",
        "name": "罗德岱堡",
        "name_en": "Fort Lauderdale",
        "name_pinyin": "luodebao",
        "code": "FLL",
        "childrens": []
      }, {
        "id": "3899",
        "pid": "332",
        "path": ",4,140,332,3899,",
        "level": "4",
        "name": "迈阿密",
        "name_en": "Miami",
        "name_pinyin": "maiami",
        "code": "MIA",
        "childrens": []
      }, {
        "id": "3900",
        "pid": "332",
        "path": ",4,140,332,3900,",
        "level": "4",
        "name": "圣彼德斯堡市",
        "name_en": "St. Petersburg",
        "name_pinyin": "shengbidesibaoshi",
        "code": "PIE",
        "childrens": []
      }, {
        "id": "3901",
        "pid": "332",
        "path": ",4,140,332,3901,",
        "level": "4",
        "name": "塔拉哈西",
        "name_en": "Tallahassee",
        "name_pinyin": "talahaxi",
        "code": "TLH",
        "childrens": []
      }, {
        "id": "3902",
        "pid": "332",
        "path": ",4,140,332,3902,",
        "level": "4",
        "name": "坦帕",
        "name_en": "Tampa",
        "name_pinyin": "tanpa",
        "code": "TPA",
        "childrens": []
      }]
    }, {
      "id": "333",
      "pid": "140",
      "path": ",4,140,333,",
      "level": "3",
      "name": "佛蒙特",
      "name_en": "Vermont",
      "name_pinyin": "fomengte",
      "code": "VT",
      "childrens": [{
        "id": "3903",
        "pid": "333",
        "path": ",4,140,333,3903,",
        "level": "4",
        "name": "伯灵顿",
        "name_en": "Burlington",
        "name_pinyin": "bolingdun",
        "code": "BTV",
        "childrens": []
      }, {
        "id": "3904",
        "pid": "333",
        "path": ",4,140,333,3904,",
        "level": "4",
        "name": "拉特兰",
        "name_en": "Rutland",
        "name_pinyin": "latelan",
        "code": "RUT",
        "childrens": []
      }, {
        "id": "3905",
        "pid": "333",
        "path": ",4,140,333,3905,",
        "level": "4",
        "name": "南伯灵顿",
        "name_en": "South Burlington",
        "name_pinyin": "nanbolingdun",
        "code": "ZBR",
        "childrens": []
      }]
    }, {
      "id": "334",
      "pid": "140",
      "path": ",4,140,334,",
      "level": "3",
      "name": "哥伦比亚特区",
      "name_en": "District of Columbia",
      "name_pinyin": "gelunbiyatequ",
      "code": "DC",
      "childrens": [{
        "id": "3906",
        "pid": "334",
        "path": ",4,140,334,3906,",
        "level": "4",
        "name": "华盛顿哥伦比亚特区",
        "name_en": "Washington D.C.",
        "name_pinyin": "huashengdungelunbiyatequ",
        "code": "WAS",
        "childrens": []
      }]
    }, {
      "id": "335",
      "pid": "140",
      "path": ",4,140,335,",
      "level": "3",
      "name": "华盛顿",
      "name_en": "Washington",
      "name_pinyin": "huashengdun",
      "code": "WA",
      "childrens": [{
        "id": "3907",
        "pid": "335",
        "path": ",4,140,335,3907,",
        "level": "4",
        "name": "斯波坎",
        "name_en": "Spokane",
        "name_pinyin": "sibokan",
        "code": "GEG",
        "childrens": []
      }, {
        "id": "3908",
        "pid": "335",
        "path": ",4,140,335,3908,",
        "level": "4",
        "name": "塔科马",
        "name_en": "Tacoma",
        "name_pinyin": "takema",
        "code": "TTW",
        "childrens": []
      }, {
        "id": "3909",
        "pid": "335",
        "path": ",4,140,335,3909,",
        "level": "4",
        "name": "西雅图",
        "name_en": "Seattle",
        "name_pinyin": "xiyatu",
        "code": "SEA",
        "childrens": []
      }]
    }, {
      "id": "336",
      "pid": "140",
      "path": ",4,140,336,",
      "level": "3",
      "name": "怀俄明",
      "name_en": "Wyoming",
      "name_pinyin": "huaieming",
      "code": "WY",
      "childrens": [{
        "id": "3910",
        "pid": "336",
        "path": ",4,140,336,3910,",
        "level": "4",
        "name": "埃文斯顿",
        "name_en": "Evanston",
        "name_pinyin": "aiwensidun",
        "code": "EVD",
        "childrens": []
      }, {
        "id": "3911",
        "pid": "336",
        "path": ",4,140,336,3911,",
        "level": "4",
        "name": "卡斯珀",
        "name_en": "Casper",
        "name_pinyin": "kasi",
        "code": "CPR",
        "childrens": []
      }, {
        "id": "3912",
        "pid": "336",
        "path": ",4,140,336,3912,",
        "level": "4",
        "name": "拉勒米",
        "name_en": "Laramie",
        "name_pinyin": "lalemi",
        "code": "LAR",
        "childrens": []
      }, {
        "id": "3913",
        "pid": "336",
        "path": ",4,140,336,3913,",
        "level": "4",
        "name": "罗克斯普林斯",
        "name_en": "Rock Springs",
        "name_pinyin": "luokesipulinsi",
        "code": "RKS",
        "childrens": []
      }, {
        "id": "3914",
        "pid": "336",
        "path": ",4,140,336,3914,",
        "level": "4",
        "name": "夏延",
        "name_en": "Cheyenne",
        "name_pinyin": "xiayan",
        "code": "CYS",
        "childrens": []
      }, {
        "id": "3915",
        "pid": "336",
        "path": ",4,140,336,3915,",
        "level": "4",
        "name": "谢里登",
        "name_en": "Sheridan",
        "name_pinyin": "xielideng",
        "code": "SHR",
        "childrens": []
      }]
    }, {
      "id": "337",
      "pid": "140",
      "path": ",4,140,337,",
      "level": "3",
      "name": "加利福尼亚",
      "name_en": "California",
      "name_pinyin": "jialifuniya",
      "code": "CA",
      "childrens": [{
        "id": "3916",
        "pid": "337",
        "path": ",4,140,337,3916,",
        "level": "4",
        "name": "旧金山",
        "name_en": "San Francisco",
        "name_pinyin": "jiujinshan",
        "code": "SFO",
        "childrens": []
      }, {
        "id": "3917",
        "pid": "337",
        "path": ",4,140,337,3917,",
        "level": "4",
        "name": "洛杉矶",
        "name_en": "Los Angeles",
        "name_pinyin": "luoshan",
        "code": "LAX",
        "childrens": []
      }, {
        "id": "3918",
        "pid": "337",
        "path": ",4,140,337,3918,",
        "level": "4",
        "name": "圣迭戈",
        "name_en": "San Diego",
        "name_pinyin": "shengdiege",
        "code": "SAN",
        "childrens": []
      }, {
        "id": "3919",
        "pid": "337",
        "path": ",4,140,337,3919,",
        "level": "4",
        "name": "圣何塞",
        "name_en": "San Jose",
        "name_pinyin": "shenghesai",
        "code": "SJC",
        "childrens": []
      }]
    }, {
      "id": "338",
      "pid": "140",
      "path": ",4,140,338,",
      "level": "3",
      "name": "堪萨斯",
      "name_en": "Kansas",
      "name_pinyin": "kansasi",
      "code": "KS",
      "childrens": [{
        "id": "3920",
        "pid": "338",
        "path": ",4,140,338,3920,",
        "level": "4",
        "name": "阿比林",
        "name_en": "Abilene",
        "name_pinyin": "abilin",
        "code": "ABZ",
        "childrens": []
      }, {
        "id": "3921",
        "pid": "338",
        "path": ",4,140,338,3921,",
        "level": "4",
        "name": "奥弗兰公园",
        "name_en": "Overland Park",
        "name_pinyin": "aofulangongyuan",
        "code": "OVL",
        "childrens": []
      }, {
        "id": "3922",
        "pid": "338",
        "path": ",4,140,338,3922,",
        "level": "4",
        "name": "哈钦森",
        "name_en": "Hutchinson",
        "name_pinyin": "haqinsen",
        "code": "HCH",
        "childrens": []
      }, {
        "id": "3923",
        "pid": "338",
        "path": ",4,140,338,3923,",
        "level": "4",
        "name": "堪萨斯城",
        "name_en": "Kansas City",
        "name_pinyin": "kansasicheng",
        "code": "KCK",
        "childrens": []
      }, {
        "id": "3924",
        "pid": "338",
        "path": ",4,140,338,3924,",
        "level": "4",
        "name": "莱文沃思",
        "name_en": "Leavenworth",
        "name_pinyin": "laiwenwosi",
        "code": "XIA",
        "childrens": []
      }, {
        "id": "3925",
        "pid": "338",
        "path": ",4,140,338,3925,",
        "level": "4",
        "name": "劳伦斯",
        "name_en": "Lawrence",
        "name_pinyin": "laolunsi",
        "code": "LWC",
        "childrens": []
      }, {
        "id": "3926",
        "pid": "338",
        "path": ",4,140,338,3926,",
        "level": "4",
        "name": "曼哈顿",
        "name_en": "Manhattan",
        "name_pinyin": "manhadun",
        "code": "MHK",
        "childrens": []
      }, {
        "id": "3927",
        "pid": "338",
        "path": ",4,140,338,3927,",
        "level": "4",
        "name": "托皮卡",
        "name_en": "Topeka",
        "name_pinyin": "tuopika",
        "code": "TOP",
        "childrens": []
      }, {
        "id": "3928",
        "pid": "338",
        "path": ",4,140,338,3928,",
        "level": "4",
        "name": "威奇托",
        "name_en": "Wichita",
        "name_pinyin": "weiqituo",
        "code": "ICT",
        "childrens": []
      }]
    }, {
      "id": "339",
      "pid": "140",
      "path": ",4,140,339,",
      "level": "3",
      "name": "康涅狄格",
      "name_en": "Connecticut",
      "name_pinyin": "kangniedige",
      "code": "CT",
      "childrens": [{
        "id": "3929",
        "pid": "339",
        "path": ",4,140,339,3929,",
        "level": "4",
        "name": "布里奇波特",
        "name_en": "Bridgeport",
        "name_pinyin": "buliqibote",
        "code": "BDR",
        "childrens": []
      }, {
        "id": "3930",
        "pid": "339",
        "path": ",4,140,339,3930,",
        "level": "4",
        "name": "达里恩",
        "name_en": "Darien",
        "name_pinyin": "dalien",
        "code": "DAQ",
        "childrens": []
      }, {
        "id": "3931",
        "pid": "339",
        "path": ",4,140,339,3931,",
        "level": "4",
        "name": "格林尼治",
        "name_en": "Greenwich",
        "name_pinyin": "gelinnizhi",
        "code": "GRH",
        "childrens": []
      }, {
        "id": "3932",
        "pid": "339",
        "path": ",4,140,339,3932,",
        "level": "4",
        "name": "哈特福德",
        "name_en": "Hartford",
        "name_pinyin": "hatefude",
        "code": "HFD",
        "childrens": []
      }, {
        "id": "3933",
        "pid": "339",
        "path": ",4,140,339,3933,",
        "level": "4",
        "name": "米德尔顿",
        "name_en": "Middletown",
        "name_pinyin": "mideerdun",
        "code": "XIN",
        "childrens": []
      }, {
        "id": "3934",
        "pid": "339",
        "path": ",4,140,339,3934,",
        "level": "4",
        "name": "纽黑文",
        "name_en": "New Haven",
        "name_pinyin": "niuheiwen",
        "code": "HVN",
        "childrens": []
      }, {
        "id": "3935",
        "pid": "339",
        "path": ",4,140,339,3935,",
        "level": "4",
        "name": "韦斯特波特",
        "name_en": "Westport",
        "name_pinyin": "weisitebote",
        "code": "WPT",
        "childrens": []
      }, {
        "id": "3936",
        "pid": "339",
        "path": ",4,140,339,3936,",
        "level": "4",
        "name": "沃特伯里",
        "name_en": "Waterbury",
        "name_pinyin": "woteboli",
        "code": "WAT",
        "childrens": []
      }, {
        "id": "3937",
        "pid": "339",
        "path": ",4,140,339,3937,",
        "level": "4",
        "name": "新不列颠",
        "name_en": "New Britain",
        "name_pinyin": "xinbuliedian",
        "code": "NWT",
        "childrens": []
      }]
    }, {
      "id": "340",
      "pid": "140",
      "path": ",4,140,340,",
      "level": "3",
      "name": "科罗拉多",
      "name_en": "Colorado",
      "name_pinyin": "keluoladuo",
      "code": "CO",
      "childrens": [{
        "id": "3938",
        "pid": "340",
        "path": ",4,140,340,3938,",
        "level": "4",
        "name": "阿斯彭",
        "name_en": "Aspen",
        "name_pinyin": "asipeng",
        "code": "ASE",
        "childrens": []
      }, {
        "id": "3939",
        "pid": "340",
        "path": ",4,140,340,3939,",
        "level": "4",
        "name": "奥罗拉",
        "name_en": "Aurora",
        "name_pinyin": "aoluola",
        "code": "AUX",
        "childrens": []
      }, {
        "id": "3940",
        "pid": "340",
        "path": ",4,140,340,3940,",
        "level": "4",
        "name": "博尔德",
        "name_en": "Boulder",
        "name_pinyin": "boerde",
        "code": "WBU",
        "childrens": []
      }, {
        "id": "3941",
        "pid": "340",
        "path": ",4,140,340,3941,",
        "level": "4",
        "name": "大章克申",
        "name_en": "Grand Junction",
        "name_pinyin": "dazhangkeshen",
        "code": "GJT",
        "childrens": []
      }, {
        "id": "3942",
        "pid": "340",
        "path": ",4,140,340,3942,",
        "level": "4",
        "name": "丹佛",
        "name_en": "Denver",
        "name_pinyin": "danfo",
        "code": "DEN",
        "childrens": []
      }, {
        "id": "3943",
        "pid": "340",
        "path": ",4,140,340,3943,",
        "level": "4",
        "name": "柯林斯堡",
        "name_en": "Fort Collins",
        "name_pinyin": "kelinsibao",
        "code": "FNL",
        "childrens": []
      }, {
        "id": "3944",
        "pid": "340",
        "path": ",4,140,340,3944,",
        "level": "4",
        "name": "科罗拉多斯普林斯",
        "name_en": "Colorado Springs",
        "name_pinyin": "keluoladuosipulinsi",
        "code": "COS",
        "childrens": []
      }, {
        "id": "3945",
        "pid": "340",
        "path": ",4,140,340,3945,",
        "level": "4",
        "name": "韦尔",
        "name_en": "Vail",
        "name_pinyin": "weier",
        "code": "VAC",
        "childrens": []
      }]
    }, {
      "id": "341",
      "pid": "140",
      "path": ",4,140,341,",
      "level": "3",
      "name": "肯塔基",
      "name_en": "Kentucky",
      "name_pinyin": "kentaji",
      "code": "KY",
      "childrens": [{
        "id": "3946",
        "pid": "341",
        "path": ",4,140,341,3946,",
        "level": "4",
        "name": "列克星敦",
        "name_en": "Lexington",
        "name_pinyin": "liekexingdun",
        "code": "LEX",
        "childrens": []
      }, {
        "id": "3947",
        "pid": "341",
        "path": ",4,140,341,3947,",
        "level": "4",
        "name": "路易斯维尔",
        "name_en": "Louisville",
        "name_pinyin": "luyisiweier",
        "code": "LUI",
        "childrens": []
      }, {
        "id": "3948",
        "pid": "341",
        "path": ",4,140,341,3948,",
        "level": "4",
        "name": "欧文斯伯勒",
        "name_en": "Owensboro",
        "name_pinyin": "ouwensibole",
        "code": "OWB",
        "childrens": []
      }]
    }, {
      "id": "342",
      "pid": "140",
      "path": ",4,140,342,",
      "level": "3",
      "name": "路易斯安那",
      "name_en": "Louisiana",
      "name_pinyin": "luyisianna",
      "code": "LA",
      "childrens": [{
        "id": "3949",
        "pid": "342",
        "path": ",4,140,342,3949,",
        "level": "4",
        "name": "巴吞鲁日",
        "name_en": "Baton Rouge",
        "name_pinyin": "batunluri",
        "code": "BTR",
        "childrens": []
      }, {
        "id": "3950",
        "pid": "342",
        "path": ",4,140,342,3950,",
        "level": "4",
        "name": "什里夫波特",
        "name_en": "Shreveport",
        "name_pinyin": "shilifubote",
        "code": "SHV",
        "childrens": []
      }, {
        "id": "3951",
        "pid": "342",
        "path": ",4,140,342,3951,",
        "level": "4",
        "name": "新奥尔良",
        "name_en": "New Orleans",
        "name_pinyin": "xinaoerliang",
        "code": "MSY",
        "childrens": []
      }]
    }, {
      "id": "343",
      "pid": "140",
      "path": ",4,140,343,",
      "level": "3",
      "name": "罗德岛",
      "name_en": "Rhode Island",
      "name_pinyin": "luodedao",
      "code": "RI",
      "childrens": [{
        "id": "3952",
        "pid": "343",
        "path": ",4,140,343,3952,",
        "level": "4",
        "name": "波塔基特",
        "name_en": "Pawtucket",
        "name_pinyin": "botajite",
        "code": "PAW",
        "childrens": []
      }, {
        "id": "3953",
        "pid": "343",
        "path": ",4,140,343,3953,",
        "level": "4",
        "name": "克兰斯顿",
        "name_en": "Cranston",
        "name_pinyin": "kelansidun",
        "code": "CQH",
        "childrens": []
      }, {
        "id": "3954",
        "pid": "343",
        "path": ",4,140,343,3954,",
        "level": "4",
        "name": "纽波特",
        "name_en": "Newport",
        "name_pinyin": "niubote",
        "code": "NPO",
        "childrens": []
      }, {
        "id": "3955",
        "pid": "343",
        "path": ",4,140,343,3955,",
        "level": "4",
        "name": "普罗维登斯",
        "name_en": "Providence",
        "name_pinyin": "puluoweidengsi",
        "code": "PVD",
        "childrens": []
      }, {
        "id": "3956",
        "pid": "343",
        "path": ",4,140,343,3956,",
        "level": "4",
        "name": "韦斯特利",
        "name_en": "Westerly",
        "name_pinyin": "weisiteli",
        "code": "WST",
        "childrens": []
      }, {
        "id": "3957",
        "pid": "343",
        "path": ",4,140,343,3957,",
        "level": "4",
        "name": "文索基特",
        "name_en": "Woonsocket",
        "name_pinyin": "wensuojite",
        "code": "SFN",
        "childrens": []
      }, {
        "id": "3958",
        "pid": "343",
        "path": ",4,140,343,3958,",
        "level": "4",
        "name": "沃威克",
        "name_en": "Warwick",
        "name_pinyin": "woweike",
        "code": "UZO",
        "childrens": []
      }]
    }, {
      "id": "344",
      "pid": "140",
      "path": ",4,140,344,",
      "level": "3",
      "name": "马里兰",
      "name_en": "Maryland",
      "name_pinyin": "malilan",
      "code": "MD",
      "childrens": [{
        "id": "3959",
        "pid": "344",
        "path": ",4,140,344,3959,",
        "level": "4",
        "name": "巴尔的摩",
        "name_en": "Balitmore",
        "name_pinyin": "baerdemo",
        "code": "BAL",
        "childrens": []
      }, {
        "id": "3960",
        "pid": "344",
        "path": ",4,140,344,3960,",
        "level": "4",
        "name": "盖瑟斯堡",
        "name_en": "Gaithersburg",
        "name_pinyin": "gaisesibao",
        "code": "GAI",
        "childrens": []
      }, {
        "id": "3961",
        "pid": "344",
        "path": ",4,140,344,3961,",
        "level": "4",
        "name": "罗克维尔",
        "name_en": "Rockville",
        "name_pinyin": "luokeweier",
        "code": "RKV",
        "childrens": []
      }]
    }, {
      "id": "345",
      "pid": "140",
      "path": ",4,140,345,",
      "level": "3",
      "name": "马萨诸塞",
      "name_en": "Massachusetts",
      "name_pinyin": "masazhusai",
      "code": "MA",
      "childrens": [{
        "id": "3962",
        "pid": "345",
        "path": ",4,140,345,3962,",
        "level": "4",
        "name": "波士顿",
        "name_en": "Boston",
        "name_pinyin": "boshidun",
        "code": "BZD",
        "childrens": []
      }, {
        "id": "3963",
        "pid": "345",
        "path": ",4,140,345,3963,",
        "level": "4",
        "name": "斯普林菲尔德",
        "name_en": "Springfield",
        "name_pinyin": "sipulinfeierde",
        "code": "SFY",
        "childrens": []
      }, {
        "id": "3964",
        "pid": "345",
        "path": ",4,140,345,3964,",
        "level": "4",
        "name": "伍斯特",
        "name_en": "Worcester",
        "name_pinyin": "wusite",
        "code": "ORH",
        "childrens": []
      }]
    }, {
      "id": "346",
      "pid": "140",
      "path": ",4,140,346,",
      "level": "3",
      "name": "蒙大拿",
      "name_en": "Montana",
      "name_pinyin": "mengdana",
      "code": "MT",
      "childrens": [{
        "id": "3965",
        "pid": "346",
        "path": ",4,140,346,3965,",
        "level": "4",
        "name": "比灵斯",
        "name_en": "Billings",
        "name_pinyin": "bilingsi",
        "code": "BGS",
        "childrens": []
      }, {
        "id": "3966",
        "pid": "346",
        "path": ",4,140,346,3966,",
        "level": "4",
        "name": "大瀑布村",
        "name_en": "Great Falls",
        "name_pinyin": "dapubucun",
        "code": "GTF",
        "childrens": []
      }, {
        "id": "3967",
        "pid": "346",
        "path": ",4,140,346,3967,",
        "level": "4",
        "name": "米苏拉",
        "name_en": "Missoula",
        "name_pinyin": "misula",
        "code": "MSO",
        "childrens": []
      }]
    }, {
      "id": "347",
      "pid": "140",
      "path": ",4,140,347,",
      "level": "3",
      "name": "密苏里",
      "name_en": "Missouri",
      "name_pinyin": "misuli",
      "code": "MO",
      "childrens": [{
        "id": "3968",
        "pid": "347",
        "path": ",4,140,347,3968,",
        "level": "4",
        "name": "哥伦比亚",
        "name_en": "Columbia",
        "name_pinyin": "gelunbiya",
        "code": "COV",
        "childrens": []
      }, {
        "id": "3969",
        "pid": "347",
        "path": ",4,140,347,3969,",
        "level": "4",
        "name": "杰佛逊市",
        "name_en": "Jefferson City",
        "name_pinyin": "jiefoxunshi",
        "code": "JEF",
        "childrens": []
      }, {
        "id": "3970",
        "pid": "347",
        "path": ",4,140,347,3970,",
        "level": "4",
        "name": "堪萨斯城",
        "name_en": "Kansas City",
        "name_pinyin": "kansasicheng",
        "code": "MKC",
        "childrens": []
      }, {
        "id": "3971",
        "pid": "347",
        "path": ",4,140,347,3971,",
        "level": "4",
        "name": "圣路易斯",
        "name_en": "Sanit Louis",
        "name_pinyin": "shengluyisi",
        "code": "STL",
        "childrens": []
      }, {
        "id": "3972",
        "pid": "347",
        "path": ",4,140,347,3972,",
        "level": "4",
        "name": "斯普林菲尔德",
        "name_en": "Springfield",
        "name_pinyin": "sipulinfeierde",
        "code": "SGF",
        "childrens": []
      }]
    }, {
      "id": "348",
      "pid": "140",
      "path": ",4,140,348,",
      "level": "3",
      "name": "密西西比",
      "name_en": "Mississippi",
      "name_pinyin": "mixixibi",
      "code": "MS",
      "childrens": [{
        "id": "3973",
        "pid": "348",
        "path": ",4,140,348,3973,",
        "level": "4",
        "name": "比洛克西",
        "name_en": "Biloxi",
        "name_pinyin": "biluokexi",
        "code": "BIX",
        "childrens": []
      }, {
        "id": "3974",
        "pid": "348",
        "path": ",4,140,348,3974,",
        "level": "4",
        "name": "格尔夫波特",
        "name_en": "Gulfport",
        "name_pinyin": "geerfubote",
        "code": "GPT",
        "childrens": []
      }, {
        "id": "3975",
        "pid": "348",
        "path": ",4,140,348,3975,",
        "level": "4",
        "name": "格林维尔",
        "name_en": "Greenville",
        "name_pinyin": "gelinweier",
        "code": "GLH",
        "childrens": []
      }, {
        "id": "3976",
        "pid": "348",
        "path": ",4,140,348,3976,",
        "level": "4",
        "name": "哈蒂斯堡",
        "name_en": "Hattiesburg",
        "name_pinyin": "hadisibao",
        "code": "HBG",
        "childrens": []
      }, {
        "id": "3977",
        "pid": "348",
        "path": ",4,140,348,3977,",
        "level": "4",
        "name": "杰克逊",
        "name_en": "Jackson",
        "name_pinyin": "jiekexun",
        "code": "JAN",
        "childrens": []
      }, {
        "id": "3978",
        "pid": "348",
        "path": ",4,140,348,3978,",
        "level": "4",
        "name": "默里迪恩",
        "name_en": "Meridian",
        "name_pinyin": "molidien",
        "code": "MEI",
        "childrens": []
      }, {
        "id": "3979",
        "pid": "348",
        "path": ",4,140,348,3979,",
        "level": "4",
        "name": "维克斯堡",
        "name_en": "Vicksburg",
        "name_pinyin": "weikesibao",
        "code": "VKS",
        "childrens": []
      }]
    }, {
      "id": "349",
      "pid": "140",
      "path": ",4,140,349,",
      "level": "3",
      "name": "密歇根",
      "name_en": "Michigan",
      "name_pinyin": "mixiegen",
      "code": "MI",
      "childrens": [{
        "id": "3980",
        "pid": "349",
        "path": ",4,140,349,3980,",
        "level": "4",
        "name": "安娜堡",
        "name_en": "Ann Arbor",
        "name_pinyin": "annabao",
        "code": "ARB",
        "childrens": []
      }, {
        "id": "3981",
        "pid": "349",
        "path": ",4,140,349,3981,",
        "level": "4",
        "name": "巴特尔克里克",
        "name_en": "Battle Creek",
        "name_pinyin": "bateerkelike",
        "code": "BTL",
        "childrens": []
      }, {
        "id": "3982",
        "pid": "349",
        "path": ",4,140,349,3982,",
        "level": "4",
        "name": "贝城",
        "name_en": "Bay City",
        "name_pinyin": "beicheng",
        "code": "BCY",
        "childrens": []
      }, {
        "id": "3983",
        "pid": "349",
        "path": ",4,140,349,3983,",
        "level": "4",
        "name": "大急流城",
        "name_en": "Grand Rapids",
        "name_pinyin": "dajiliucheng",
        "code": "GRR",
        "childrens": []
      }, {
        "id": "3984",
        "pid": "349",
        "path": ",4,140,349,3984,",
        "level": "4",
        "name": "迪尔伯恩",
        "name_en": "Dearborn",
        "name_pinyin": "dierboen",
        "code": "DEO",
        "childrens": []
      }, {
        "id": "3985",
        "pid": "349",
        "path": ",4,140,349,3985,",
        "level": "4",
        "name": "底特律",
        "name_en": "Detroit",
        "name_pinyin": "ditelv",
        "code": "DET",
        "childrens": []
      }, {
        "id": "3986",
        "pid": "349",
        "path": ",4,140,349,3986,",
        "level": "4",
        "name": "弗林特",
        "name_en": "Flint",
        "name_pinyin": "fulinte",
        "code": "FNT",
        "childrens": []
      }, {
        "id": "3987",
        "pid": "349",
        "path": ",4,140,349,3987,",
        "level": "4",
        "name": "怀恩多特",
        "name_en": "Wyandotte",
        "name_pinyin": "huaienduote",
        "code": "WYD",
        "childrens": []
      }, {
        "id": "3988",
        "pid": "349",
        "path": ",4,140,349,3988,",
        "level": "4",
        "name": "卡拉马袓",
        "name_en": "Kalamazoo",
        "name_pinyin": "kalama",
        "code": "AZO",
        "childrens": []
      }, {
        "id": "3989",
        "pid": "349",
        "path": ",4,140,349,3989,",
        "level": "4",
        "name": "兰辛",
        "name_en": "Lansing",
        "name_pinyin": "lanxin",
        "code": "LAN",
        "childrens": []
      }, {
        "id": "3990",
        "pid": "349",
        "path": ",4,140,349,3990,",
        "level": "4",
        "name": "马斯基根",
        "name_en": "Muskegon",
        "name_pinyin": "masijigen",
        "code": "MKG",
        "childrens": []
      }, {
        "id": "3991",
        "pid": "349",
        "path": ",4,140,349,3991,",
        "level": "4",
        "name": "庞菷亚克",
        "name_en": "Pontiac",
        "name_pinyin": "pang",
        "code": "PTK",
        "childrens": []
      }, {
        "id": "3992",
        "pid": "349",
        "path": ",4,140,349,3992,",
        "level": "4",
        "name": "萨吉诺",
        "name_en": "Saginaw",
        "name_pinyin": "sajinuo",
        "code": "SGM",
        "childrens": []
      }, {
        "id": "3993",
        "pid": "349",
        "path": ",4,140,349,3993,",
        "level": "4",
        "name": "苏圣玛丽",
        "name_en": "Sault Ste Marie",
        "name_pinyin": "sushengmali",
        "code": "SSM",
        "childrens": []
      }, {
        "id": "3994",
        "pid": "349",
        "path": ",4,140,349,3994,",
        "level": "4",
        "name": "沃伦",
        "name_en": "Warren",
        "name_pinyin": "wolun",
        "code": "WAM",
        "childrens": []
      }, {
        "id": "3995",
        "pid": "349",
        "path": ",4,140,349,3995,",
        "level": "4",
        "name": "休伦港",
        "name_en": "Port Huron",
        "name_pinyin": "xiulungang",
        "code": "PHN",
        "childrens": []
      }]
    }, {
      "id": "350",
      "pid": "140",
      "path": ",4,140,350,",
      "level": "3",
      "name": "缅因",
      "name_en": "Maine",
      "name_pinyin": "mianyin",
      "code": "ME",
      "childrens": [{
        "id": "3996",
        "pid": "350",
        "path": ",4,140,350,3996,",
        "level": "4",
        "name": "班戈",
        "name_en": "Bangor",
        "name_pinyin": "bange",
        "code": "BNQ",
        "childrens": []
      }, {
        "id": "3997",
        "pid": "350",
        "path": ",4,140,350,3997,",
        "level": "4",
        "name": "波特兰",
        "name_en": "Portland",
        "name_pinyin": "botelan",
        "code": "POL",
        "childrens": []
      }, {
        "id": "3998",
        "pid": "350",
        "path": ",4,140,350,3998,",
        "level": "4",
        "name": "刘易斯顿",
        "name_en": "Lewiston",
        "name_pinyin": "liuyisidun",
        "code": "QLW",
        "childrens": []
      }]
    }, {
      "id": "351",
      "pid": "140",
      "path": ",4,140,351,",
      "level": "3",
      "name": "明尼苏达",
      "name_en": "Minnesota",
      "name_pinyin": "mingnisuda",
      "code": "MN",
      "childrens": [{
        "id": "3999",
        "pid": "351",
        "path": ",4,140,351,3999,",
        "level": "4",
        "name": "罗切斯特",
        "name_en": "Rochester",
        "name_pinyin": "luoqiesite",
        "code": "RST",
        "childrens": []
      }, {
        "id": "4000",
        "pid": "351",
        "path": ",4,140,351,4000,",
        "level": "4",
        "name": "明尼阿波利斯",
        "name_en": "Minneapolis",
        "name_pinyin": "mingniabolisi",
        "code": "MES",
        "childrens": []
      }, {
        "id": "4001",
        "pid": "351",
        "path": ",4,140,351,4001,",
        "level": "4",
        "name": "圣保罗",
        "name_en": "Saint Paul",
        "name_pinyin": "shengbaoluo",
        "code": "STP",
        "childrens": []
      }]
    }, {
      "id": "352",
      "pid": "140",
      "path": ",4,140,352,",
      "level": "3",
      "name": "南达科他",
      "name_en": "South Dakota",
      "name_pinyin": "nandaketa",
      "code": "SD",
      "childrens": [{
        "id": "4002",
        "pid": "352",
        "path": ",4,140,352,4002,",
        "level": "4",
        "name": "阿伯丁",
        "name_en": "Aberdeen",
        "name_pinyin": "aboding",
        "code": "ABK",
        "childrens": []
      }, {
        "id": "4003",
        "pid": "352",
        "path": ",4,140,352,4003,",
        "level": "4",
        "name": "拉皮德城",
        "name_en": "Rapid City",
        "name_pinyin": "lapidecheng",
        "code": "RAP",
        "childrens": []
      }, {
        "id": "4004",
        "pid": "352",
        "path": ",4,140,352,4004,",
        "level": "4",
        "name": "苏福尔斯",
        "name_en": "Sioux Falls",
        "name_pinyin": "sufuersi",
        "code": "FSD",
        "childrens": []
      }]
    }, {
      "id": "353",
      "pid": "140",
      "path": ",4,140,353,",
      "level": "3",
      "name": "南卡罗来纳",
      "name_en": "South Carolina",
      "name_pinyin": "nankaluolaina",
      "code": "SC",
      "childrens": [{
        "id": "4005",
        "pid": "353",
        "path": ",4,140,353,4005,",
        "level": "4",
        "name": "北查尔斯顿",
        "name_en": "North Charleston",
        "name_pinyin": "beichaersidun",
        "code": "NTS",
        "childrens": []
      }, {
        "id": "4006",
        "pid": "353",
        "path": ",4,140,353,4006,",
        "level": "4",
        "name": "查尔斯顿",
        "name_en": "Charleston",
        "name_pinyin": "chaersidun",
        "code": "CHS",
        "childrens": []
      }, {
        "id": "4007",
        "pid": "353",
        "path": ",4,140,353,4007,",
        "level": "4",
        "name": "哥伦比亚",
        "name_en": "Columbia",
        "name_pinyin": "gelunbiya",
        "code": "COV",
        "childrens": []
      }]
    }, {
      "id": "354",
      "pid": "140",
      "path": ",4,140,354,",
      "level": "3",
      "name": "内布拉斯加",
      "name_en": "Nebraska",
      "name_pinyin": "neibulasijia",
      "code": "NE",
      "childrens": [{
        "id": "4008",
        "pid": "354",
        "path": ",4,140,354,4008,",
        "level": "4",
        "name": "奥马哈",
        "name_en": "Omaha",
        "name_pinyin": "aomaha",
        "code": "OMA",
        "childrens": []
      }, {
        "id": "4009",
        "pid": "354",
        "path": ",4,140,354,4009,",
        "level": "4",
        "name": "贝尔维尤",
        "name_en": "Bellevue",
        "name_pinyin": "beierweiyou",
        "code": "XDE",
        "childrens": []
      }, {
        "id": "4010",
        "pid": "354",
        "path": ",4,140,354,4010,",
        "level": "4",
        "name": "林肯",
        "name_en": "Lincoln",
        "name_pinyin": "linken",
        "code": "LNK",
        "childrens": []
      }]
    }, {
      "id": "355",
      "pid": "140",
      "path": ",4,140,355,",
      "level": "3",
      "name": "内华达",
      "name_en": "Nevada",
      "name_pinyin": "neihuada",
      "code": "NV",
      "childrens": [{
        "id": "4011",
        "pid": "355",
        "path": ",4,140,355,4011,",
        "level": "4",
        "name": "埃尔科",
        "name_en": "Elko",
        "name_pinyin": "aierke",
        "code": "EKO",
        "childrens": []
      }, {
        "id": "4012",
        "pid": "355",
        "path": ",4,140,355,4012,",
        "level": "4",
        "name": "北拉斯维加斯",
        "name_en": "North Las Vegas",
        "name_pinyin": "beilasiweijiasi",
        "code": "NVS",
        "childrens": []
      }, {
        "id": "4013",
        "pid": "355",
        "path": ",4,140,355,4013,",
        "level": "4",
        "name": "弗吉尼亚城",
        "name_en": "Virginia City",
        "name_pinyin": "fujiniyacheng",
        "code": "VGI",
        "childrens": []
      }, {
        "id": "4014",
        "pid": "355",
        "path": ",4,140,355,4014,",
        "level": "4",
        "name": "亨德森",
        "name_en": "Henderson",
        "name_pinyin": "hengdesen",
        "code": "HNZ",
        "childrens": []
      }, {
        "id": "4015",
        "pid": "355",
        "path": ",4,140,355,4015,",
        "level": "4",
        "name": "卡森城",
        "name_en": "Carson City",
        "name_pinyin": "kasencheng",
        "code": "CSN",
        "childrens": []
      }, {
        "id": "4016",
        "pid": "355",
        "path": ",4,140,355,4016,",
        "level": "4",
        "name": "拉斯维加斯",
        "name_en": "Las Vegas",
        "name_pinyin": "lasiweijiasi",
        "code": "LAS",
        "childrens": []
      }, {
        "id": "4017",
        "pid": "355",
        "path": ",4,140,355,4017,",
        "level": "4",
        "name": "里诺",
        "name_en": "Reno",
        "name_pinyin": "linuo",
        "code": "RNO",
        "childrens": []
      }, {
        "id": "4018",
        "pid": "355",
        "path": ",4,140,355,4018,",
        "level": "4",
        "name": "斯帕克斯",
        "name_en": "Sparks",
        "name_pinyin": "sipakesi",
        "code": "SPK",
        "childrens": []
      }]
    }, {
      "id": "356",
      "pid": "140",
      "path": ",4,140,356,",
      "level": "3",
      "name": "纽约",
      "name_en": "New York",
      "name_pinyin": "niuyue",
      "code": "NY",
      "childrens": [{
        "id": "4019",
        "pid": "356",
        "path": ",4,140,356,4019,",
        "level": "4",
        "name": "布法罗",
        "name_en": "Buffalo",
        "name_pinyin": "bufaluo",
        "code": "FFO",
        "childrens": []
      }, {
        "id": "4020",
        "pid": "356",
        "path": ",4,140,356,4020,",
        "level": "4",
        "name": "罗切斯特",
        "name_en": "Rochester",
        "name_pinyin": "luoqiesite",
        "code": "ROC",
        "childrens": []
      }, {
        "id": "4021",
        "pid": "356",
        "path": ",4,140,356,4021,",
        "level": "4",
        "name": "纽约市",
        "name_en": "New York",
        "name_pinyin": "niuyueshi",
        "code": "QEE",
        "childrens": []
      }]
    }, {
      "id": "357",
      "pid": "140",
      "path": ",4,140,357,",
      "level": "3",
      "name": "特拉华",
      "name_en": "Delaware",
      "name_pinyin": "telahua",
      "code": "DE",
      "childrens": [{
        "id": "4022",
        "pid": "357",
        "path": ",4,140,357,4022,",
        "level": "4",
        "name": "多佛",
        "name_en": "Dover",
        "name_pinyin": "duofo",
        "code": "DOR",
        "childrens": []
      }, {
        "id": "4023",
        "pid": "357",
        "path": ",4,140,357,4023,",
        "level": "4",
        "name": "纽瓦克",
        "name_en": "Newark",
        "name_pinyin": "niuwake",
        "code": "NWK",
        "childrens": []
      }, {
        "id": "4024",
        "pid": "357",
        "path": ",4,140,357,4024,",
        "level": "4",
        "name": "威明顿",
        "name_en": "Wilmington",
        "name_pinyin": "weimingdun",
        "code": "ILG",
        "childrens": []
      }]
    }, {
      "id": "358",
      "pid": "140",
      "path": ",4,140,358,",
      "level": "3",
      "name": "田纳西",
      "name_en": "Tennessee",
      "name_pinyin": "tiannaxi",
      "code": "TN",
      "childrens": [{
        "id": "4025",
        "pid": "358",
        "path": ",4,140,358,4025,",
        "level": "4",
        "name": "布利斯托",
        "name_en": "Bristol",
        "name_pinyin": "bulisituo",
        "code": "BSJ",
        "childrens": []
      }, {
        "id": "4026",
        "pid": "358",
        "path": ",4,140,358,4026,",
        "level": "4",
        "name": "查塔努加",
        "name_en": "Chattanooga",
        "name_pinyin": "chatanujia",
        "code": "CHA",
        "childrens": []
      }, {
        "id": "4027",
        "pid": "358",
        "path": ",4,140,358,4027,",
        "level": "4",
        "name": "金斯波特",
        "name_en": "Kingsport",
        "name_pinyin": "jinsibote",
        "code": "TRI",
        "childrens": []
      }, {
        "id": "4028",
        "pid": "358",
        "path": ",4,140,358,4028,",
        "level": "4",
        "name": "孟菲斯",
        "name_en": "Memphis",
        "name_pinyin": "mengfeisi",
        "code": "MEM",
        "childrens": []
      }, {
        "id": "4029",
        "pid": "358",
        "path": ",4,140,358,4029,",
        "level": "4",
        "name": "纳什维尔",
        "name_en": "Nashville",
        "name_pinyin": "nashiweier",
        "code": "BNA",
        "childrens": []
      }, {
        "id": "4030",
        "pid": "358",
        "path": ",4,140,358,4030,",
        "level": "4",
        "name": "诺克斯维尔",
        "name_en": "Knoxville",
        "name_pinyin": "nuokesiweier",
        "code": "TYS",
        "childrens": []
      }, {
        "id": "4031",
        "pid": "358",
        "path": ",4,140,358,4031,",
        "level": "4",
        "name": "三城区",
        "name_en": "Tri-City Area",
        "name_pinyin": "sanchengqu",
        "code": "YTC",
        "childrens": []
      }, {
        "id": "4032",
        "pid": "358",
        "path": ",4,140,358,4032,",
        "level": "4",
        "name": "士麦那",
        "name_en": "Smyrna",
        "name_pinyin": "shimaina",
        "code": "MQY",
        "childrens": []
      }, {
        "id": "4033",
        "pid": "358",
        "path": ",4,140,358,4033,",
        "level": "4",
        "name": "斯普林希尔",
        "name_en": "Spring Hill",
        "name_pinyin": "sipulinxier",
        "code": "RGI",
        "childrens": []
      }, {
        "id": "4034",
        "pid": "358",
        "path": ",4,140,358,4034,",
        "level": "4",
        "name": "约翰逊城",
        "name_en": "Johnson City",
        "name_pinyin": "yuehanxuncheng",
        "code": "JCY",
        "childrens": []
      }]
    }, {
      "id": "359",
      "pid": "140",
      "path": ",4,140,359,",
      "level": "3",
      "name": "威斯康星",
      "name_en": "Wisconsin",
      "name_pinyin": "weisikangxing",
      "code": "WI",
      "childrens": [{
        "id": "4035",
        "pid": "359",
        "path": ",4,140,359,4035,",
        "level": "4",
        "name": "阿普尓顿",
        "name_en": "Appleton",
        "name_pinyin": "apu",
        "code": "ATW",
        "childrens": []
      }, {
        "id": "4036",
        "pid": "359",
        "path": ",4,140,359,4036,",
        "level": "4",
        "name": "奥什科什",
        "name_en": "Oshkosh",
        "name_pinyin": "aoshikeshi",
        "code": "OSH",
        "childrens": []
      }, {
        "id": "4037",
        "pid": "359",
        "path": ",4,140,359,4037,",
        "level": "4",
        "name": "格林贝",
        "name_en": "Green Bay",
        "name_pinyin": "gelinbei",
        "code": "GBK",
        "childrens": []
      }, {
        "id": "4038",
        "pid": "359",
        "path": ",4,140,359,4038,",
        "level": "4",
        "name": "基诺沙",
        "name_en": "Kenosha",
        "name_pinyin": "jinuosha",
        "code": "ENW",
        "childrens": []
      }, {
        "id": "4039",
        "pid": "359",
        "path": ",4,140,359,4039,",
        "level": "4",
        "name": "拉克罗斯",
        "name_en": "LaCrosse",
        "name_pinyin": "lakeluosi",
        "code": "LSE",
        "childrens": []
      }, {
        "id": "4040",
        "pid": "359",
        "path": ",4,140,359,4040,",
        "level": "4",
        "name": "拉辛",
        "name_en": "Racine",
        "name_pinyin": "laxin",
        "code": "RAC",
        "childrens": []
      }, {
        "id": "4041",
        "pid": "359",
        "path": ",4,140,359,4041,",
        "level": "4",
        "name": "马尼托沃克",
        "name_en": "Manitowoc",
        "name_pinyin": "manituowoke",
        "code": "MTW",
        "childrens": []
      }, {
        "id": "4042",
        "pid": "359",
        "path": ",4,140,359,4042,",
        "level": "4",
        "name": "迈迪逊",
        "name_en": "Madison",
        "name_pinyin": "maidixun",
        "code": "QMD",
        "childrens": []
      }, {
        "id": "4043",
        "pid": "359",
        "path": ",4,140,359,4043,",
        "level": "4",
        "name": "密尔沃基",
        "name_en": "Milwaukee",
        "name_pinyin": "mierwoji",
        "code": "MKE",
        "childrens": []
      }, {
        "id": "4044",
        "pid": "359",
        "path": ",4,140,359,4044,",
        "level": "4",
        "name": "欧克莱尓",
        "name_en": "Eau Claire",
        "name_pinyin": "oukelai",
        "code": "EAU",
        "childrens": []
      }, {
        "id": "4045",
        "pid": "359",
        "path": ",4,140,359,4045,",
        "level": "4",
        "name": "沃索",
        "name_en": "Wausau",
        "name_pinyin": "wosuo",
        "code": "AUW",
        "childrens": []
      }, {
        "id": "4046",
        "pid": "359",
        "path": ",4,140,359,4046,",
        "level": "4",
        "name": "希博伊根",
        "name_en": "Sheboygan",
        "name_pinyin": "xiboyigen",
        "code": "SBM",
        "childrens": []
      }]
    }, {
      "id": "360",
      "pid": "140",
      "path": ",4,140,360,",
      "level": "3",
      "name": "维吉尼亚",
      "name_en": "Virginia",
      "name_pinyin": "weijiniya",
      "code": "VA",
      "childrens": [{
        "id": "4047",
        "pid": "360",
        "path": ",4,140,360,4047,",
        "level": "4",
        "name": "弗吉尼亚比奇",
        "name_en": "Virginia Beach",
        "name_pinyin": "fujiniyabiqi",
        "code": "VAB",
        "childrens": []
      }, {
        "id": "4048",
        "pid": "360",
        "path": ",4,140,360,4048,",
        "level": "4",
        "name": "诺福克",
        "name_en": "Norfolk",
        "name_pinyin": "nuofuke",
        "code": "ORF",
        "childrens": []
      }, {
        "id": "4049",
        "pid": "360",
        "path": ",4,140,360,4049,",
        "level": "4",
        "name": "切萨皮克",
        "name_en": "Chesapeake",
        "name_pinyin": "qiesapike",
        "code": "HTW",
        "childrens": []
      }]
    }, {
      "id": "361",
      "pid": "140",
      "path": ",4,140,361,",
      "level": "3",
      "name": "西佛吉尼亚",
      "name_en": "West Virginia",
      "name_pinyin": "xifojiniya",
      "code": "WV",
      "childrens": [{
        "id": "4050",
        "pid": "361",
        "path": ",4,140,361,4050,",
        "level": "4",
        "name": "查尔斯顿",
        "name_en": "Charleston",
        "name_pinyin": "chaersidun",
        "code": "CRW",
        "childrens": []
      }, {
        "id": "4051",
        "pid": "361",
        "path": ",4,140,361,4051,",
        "level": "4",
        "name": "亨廷顿",
        "name_en": "Huntington",
        "name_pinyin": "hengtingdun",
        "code": "HNU",
        "childrens": []
      }, {
        "id": "4052",
        "pid": "361",
        "path": ",4,140,361,4052,",
        "level": "4",
        "name": "帕克斯堡",
        "name_en": "Parkersburg",
        "name_pinyin": "pakesibao",
        "code": "PKB",
        "childrens": []
      }]
    }, {
      "id": "362",
      "pid": "140",
      "path": ",4,140,362,",
      "level": "3",
      "name": "夏威夷",
      "name_en": "Hawaii",
      "name_pinyin": "xiaweiyi",
      "code": "HI",
      "childrens": [{
        "id": "4053",
        "pid": "362",
        "path": ",4,140,362,4053,",
        "level": "4",
        "name": "凯卢阿",
        "name_en": "Kailua",
        "name_pinyin": "kailua",
        "code": "KHH",
        "childrens": []
      }, {
        "id": "4054",
        "pid": "362",
        "path": ",4,140,362,4054,",
        "level": "4",
        "name": "檀香山",
        "name_en": "Honolulu",
        "name_pinyin": "tanxiangshan",
        "code": "HNL",
        "childrens": []
      }, {
        "id": "4055",
        "pid": "362",
        "path": ",4,140,362,4055,",
        "level": "4",
        "name": "希洛",
        "name_en": "Hilo",
        "name_pinyin": "xiluo",
        "code": "ITO",
        "childrens": []
      }]
    }, {
      "id": "363",
      "pid": "140",
      "path": ",4,140,363,",
      "level": "3",
      "name": "新罕布什尔",
      "name_en": "New Hampshire",
      "name_pinyin": "xinhanbushier",
      "code": "NH",
      "childrens": [{
        "id": "4056",
        "pid": "363",
        "path": ",4,140,363,4056,",
        "level": "4",
        "name": "康科德",
        "name_en": "Concord",
        "name_pinyin": "kangkede",
        "code": "CON",
        "childrens": []
      }, {
        "id": "4057",
        "pid": "363",
        "path": ",4,140,363,4057,",
        "level": "4",
        "name": "曼彻斯特",
        "name_en": "Manchester",
        "name_pinyin": "manchesite",
        "code": "MHT",
        "childrens": []
      }, {
        "id": "4058",
        "pid": "363",
        "path": ",4,140,363,4058,",
        "level": "4",
        "name": "纳舒厄",
        "name_en": "Nashua",
        "name_pinyin": "nashue",
        "code": "ASH",
        "childrens": []
      }]
    }, {
      "id": "364",
      "pid": "140",
      "path": ",4,140,364,",
      "level": "3",
      "name": "新墨西哥",
      "name_en": "New Mexico",
      "name_pinyin": "xinmoxige",
      "code": "NM",
      "childrens": [{
        "id": "4059",
        "pid": "364",
        "path": ",4,140,364,4059,",
        "level": "4",
        "name": "阿尔伯克基",
        "name_en": "Albuquerque",
        "name_pinyin": "aerbokeji",
        "code": "ABQ",
        "childrens": []
      }, {
        "id": "4060",
        "pid": "364",
        "path": ",4,140,364,4060,",
        "level": "4",
        "name": "拉斯克鲁塞斯",
        "name_en": "Las Cruces",
        "name_pinyin": "lasikelusaisi",
        "code": "LRU",
        "childrens": []
      }, {
        "id": "4061",
        "pid": "364",
        "path": ",4,140,364,4061,",
        "level": "4",
        "name": "罗斯韦尔",
        "name_en": "Roswell",
        "name_pinyin": "luosiweier",
        "code": "ROW",
        "childrens": []
      }, {
        "id": "4062",
        "pid": "364",
        "path": ",4,140,364,4062,",
        "level": "4",
        "name": "圣菲",
        "name_en": "Santa Fe",
        "name_pinyin": "shengfei",
        "code": "SAF",
        "childrens": []
      }]
    }, {
      "id": "365",
      "pid": "140",
      "path": ",4,140,365,",
      "level": "3",
      "name": "新泽西",
      "name_en": "New Jersey",
      "name_pinyin": "xinzexi",
      "code": "NJ",
      "childrens": [{
        "id": "4063",
        "pid": "365",
        "path": ",4,140,365,4063,",
        "level": "4",
        "name": "纽瓦克",
        "name_en": "Newark",
        "name_pinyin": "niuwake",
        "code": "NRK",
        "childrens": []
      }, {
        "id": "4064",
        "pid": "365",
        "path": ",4,140,365,4064,",
        "level": "4",
        "name": "帕特森",
        "name_en": "Paterson",
        "name_pinyin": "patesen",
        "code": "PAT",
        "childrens": []
      }, {
        "id": "4065",
        "pid": "365",
        "path": ",4,140,365,4065,",
        "level": "4",
        "name": "泽西城",
        "name_en": "Jersey City",
        "name_pinyin": "zexicheng",
        "code": "JEC",
        "childrens": []
      }]
    }, {
      "id": "366",
      "pid": "140",
      "path": ",4,140,366,",
      "level": "3",
      "name": "亚利桑那",
      "name_en": "Arizona",
      "name_pinyin": "yalisangna",
      "code": "AZ",
      "childrens": [{
        "id": "4066",
        "pid": "366",
        "path": ",4,140,366,4066,",
        "level": "4",
        "name": "凤凰城",
        "name_en": "Phoenix",
        "name_pinyin": "fenghuangcheng",
        "code": "PHX",
        "childrens": []
      }, {
        "id": "4067",
        "pid": "366",
        "path": ",4,140,366,4067,",
        "level": "4",
        "name": "格兰代尔",
        "name_en": "Glendale",
        "name_pinyin": "gelandaier",
        "code": "GDA",
        "childrens": []
      }, {
        "id": "4068",
        "pid": "366",
        "path": ",4,140,366,4068,",
        "level": "4",
        "name": "梅萨",
        "name_en": "Mesa",
        "name_pinyin": "meisa",
        "code": "MQA",
        "childrens": []
      }, {
        "id": "4069",
        "pid": "366",
        "path": ",4,140,366,4069,",
        "level": "4",
        "name": "史卡兹代尔",
        "name_en": "Scottsdale",
        "name_pinyin": "shikazidaier",
        "code": "STZ",
        "childrens": []
      }, {
        "id": "4070",
        "pid": "366",
        "path": ",4,140,366,4070,",
        "level": "4",
        "name": "坦普",
        "name_en": "Tempe",
        "name_pinyin": "tanpu",
        "code": "TPE",
        "childrens": []
      }, {
        "id": "4071",
        "pid": "366",
        "path": ",4,140,366,4071,",
        "level": "4",
        "name": "图森",
        "name_en": "Tucson",
        "name_pinyin": "tusen",
        "code": "TUC",
        "childrens": []
      }, {
        "id": "4072",
        "pid": "366",
        "path": ",4,140,366,4072,",
        "level": "4",
        "name": "优玛",
        "name_en": "Yuma",
        "name_pinyin": "youma",
        "code": "YUM",
        "childrens": []
      }]
    }, {
      "id": "367",
      "pid": "140",
      "path": ",4,140,367,",
      "level": "3",
      "name": "伊利诺斯",
      "name_en": "Illinois",
      "name_pinyin": "yilinuosi",
      "code": "IL",
      "childrens": [{
        "id": "4073",
        "pid": "367",
        "path": ",4,140,367,4073,",
        "level": "4",
        "name": "奥尔顿",
        "name_en": "Alton",
        "name_pinyin": "aoerdun",
        "code": "ALN",
        "childrens": []
      }, {
        "id": "4074",
        "pid": "367",
        "path": ",4,140,367,4074,",
        "level": "4",
        "name": "奥罗拉",
        "name_en": "Aurora",
        "name_pinyin": "aoluola",
        "code": "AUZ",
        "childrens": []
      }, {
        "id": "4075",
        "pid": "367",
        "path": ",4,140,367,4075,",
        "level": "4",
        "name": "布卢明顿",
        "name_en": "Bloomington",
        "name_pinyin": "bulumingdun",
        "code": "BLO",
        "childrens": []
      }, {
        "id": "4076",
        "pid": "367",
        "path": ",4,140,367,4076,",
        "level": "4",
        "name": "丹维尓",
        "name_en": "Danville",
        "name_pinyin": "danwei",
        "code": "DVI",
        "childrens": []
      }, {
        "id": "4077",
        "pid": "367",
        "path": ",4,140,367,4077,",
        "level": "4",
        "name": "迪卡尔布",
        "name_en": "De Kalb",
        "name_pinyin": "dikaerbu",
        "code": "DEK",
        "childrens": []
      }, {
        "id": "4078",
        "pid": "367",
        "path": ",4,140,367,4078,",
        "level": "4",
        "name": "迪凯持",
        "name_en": "Decatur",
        "name_pinyin": "dikaichi",
        "code": "DEC",
        "childrens": []
      }, {
        "id": "4079",
        "pid": "367",
        "path": ",4,140,367,4079,",
        "level": "4",
        "name": "东圣路易斯",
        "name_en": "East St Louis",
        "name_pinyin": "dongshengluyisi",
        "code": "ESL",
        "childrens": []
      }, {
        "id": "4080",
        "pid": "367",
        "path": ",4,140,367,4080,",
        "level": "4",
        "name": "厄巴纳-香槟",
        "name_en": "Champaign-Urbana",
        "name_pinyin": "ebanaxiang",
        "code": "CMI",
        "childrens": []
      }, {
        "id": "4081",
        "pid": "367",
        "path": ",4,140,367,4081,",
        "level": "4",
        "name": "盖尔斯堡",
        "name_en": "Galesburg",
        "name_pinyin": "gaiersibao",
        "code": "GSU",
        "childrens": []
      }, {
        "id": "4082",
        "pid": "367",
        "path": ",4,140,367,4082,",
        "level": "4",
        "name": "卡本代尔",
        "name_en": "Carbondale",
        "name_pinyin": "kabendaier",
        "code": "MDH",
        "childrens": []
      }, {
        "id": "4083",
        "pid": "367",
        "path": ",4,140,367,4083,",
        "level": "4",
        "name": "罗克艾兰",
        "name_en": "Rock Island",
        "name_pinyin": "luokeailan",
        "code": "RKI",
        "childrens": []
      }, {
        "id": "4084",
        "pid": "367",
        "path": ",4,140,367,4084,",
        "level": "4",
        "name": "罗克福德",
        "name_en": "Rockford",
        "name_pinyin": "luokefude",
        "code": "RFD",
        "childrens": []
      }, {
        "id": "4085",
        "pid": "367",
        "path": ",4,140,367,4085,",
        "level": "4",
        "name": "诺黙尔",
        "name_en": "Normal",
        "name_pinyin": "nuo",
        "code": "NOM",
        "childrens": []
      }, {
        "id": "4086",
        "pid": "367",
        "path": ",4,140,367,4086,",
        "level": "4",
        "name": "皮奥里亚",
        "name_en": "Peoria",
        "name_pinyin": "piaoliya",
        "code": "PLA",
        "childrens": []
      }, {
        "id": "4087",
        "pid": "367",
        "path": ",4,140,367,4087,",
        "level": "4",
        "name": "森特勒利亚",
        "name_en": "Centralia",
        "name_pinyin": "senteleliya",
        "code": "CRA",
        "childrens": []
      }, {
        "id": "4088",
        "pid": "367",
        "path": ",4,140,367,4088,",
        "level": "4",
        "name": "斯普林菲尔德",
        "name_en": "Springfield",
        "name_pinyin": "sipulinfeierde",
        "code": "SPI",
        "childrens": []
      }, {
        "id": "4089",
        "pid": "367",
        "path": ",4,140,367,4089,",
        "level": "4",
        "name": "沃其根",
        "name_en": "Waukegan",
        "name_pinyin": "woqigen",
        "code": "UGN",
        "childrens": []
      }, {
        "id": "4090",
        "pid": "367",
        "path": ",4,140,367,4090,",
        "level": "4",
        "name": "芝加哥",
        "name_en": "Chicago",
        "name_pinyin": "zhijiage",
        "code": "CHI",
        "childrens": []
      }]
    }, {
      "id": "368",
      "pid": "140",
      "path": ",4,140,368,",
      "level": "3",
      "name": "印第安那",
      "name_en": "Indiana",
      "name_pinyin": "yindianna",
      "code": "IN",
      "childrens": [{
        "id": "4091",
        "pid": "368",
        "path": ",4,140,368,4091,",
        "level": "4",
        "name": "埃文斯维尔",
        "name_en": "Evansville",
        "name_pinyin": "aiwensiweier",
        "code": "EVV",
        "childrens": []
      }, {
        "id": "4092",
        "pid": "368",
        "path": ",4,140,368,4092,",
        "level": "4",
        "name": "韦恩堡",
        "name_en": "Fort Wayne",
        "name_pinyin": "weienbao",
        "code": "FWA",
        "childrens": []
      }, {
        "id": "4093",
        "pid": "368",
        "path": ",4,140,368,4093,",
        "level": "4",
        "name": "印第安纳波利斯",
        "name_en": "Indianapolis",
        "name_pinyin": "yindiannabolisi",
        "code": "IND",
        "childrens": []
      }]
    }, {
      "id": "369",
      "pid": "140",
      "path": ",4,140,369,",
      "level": "3",
      "name": "犹他",
      "name_en": "Utah",
      "name_pinyin": "youta",
      "code": "UT",
      "childrens": [{
        "id": "4094",
        "pid": "369",
        "path": ",4,140,369,4094,",
        "level": "4",
        "name": "奥格登",
        "name_en": "Ogden",
        "name_pinyin": "aogedeng",
        "code": "OGD",
        "childrens": []
      }, {
        "id": "4095",
        "pid": "369",
        "path": ",4,140,369,4095,",
        "level": "4",
        "name": "雷登",
        "name_en": "Layton",
        "name_pinyin": "leideng",
        "code": "LTJ",
        "childrens": []
      }, {
        "id": "4096",
        "pid": "369",
        "path": ",4,140,369,4096,",
        "level": "4",
        "name": "欧仁",
        "name_en": "Orem",
        "name_pinyin": "ouren",
        "code": "OEU",
        "childrens": []
      }, {
        "id": "4097",
        "pid": "369",
        "path": ",4,140,369,4097,",
        "level": "4",
        "name": "帕克城",
        "name_en": "Park City",
        "name_pinyin": "pakecheng",
        "code": "PAC",
        "childrens": []
      }, {
        "id": "4098",
        "pid": "369",
        "path": ",4,140,369,4098,",
        "level": "4",
        "name": "普罗沃",
        "name_en": "Provo",
        "name_pinyin": "puluowo",
        "code": "PVU",
        "childrens": []
      }, {
        "id": "4099",
        "pid": "369",
        "path": ",4,140,369,4099,",
        "level": "4",
        "name": "圣乔治",
        "name_en": "St.George",
        "name_pinyin": "shengqiaozhi",
        "code": "SGU",
        "childrens": []
      }, {
        "id": "4100",
        "pid": "369",
        "path": ",4,140,369,4100,",
        "level": "4",
        "name": "西瓦利城",
        "name_en": "West Valley City",
        "name_pinyin": "xiwalicheng",
        "code": "WVC",
        "childrens": []
      }, {
        "id": "4101",
        "pid": "369",
        "path": ",4,140,369,4101,",
        "level": "4",
        "name": "盐湖城",
        "name_en": "Salt Lake City",
        "name_pinyin": "yanhucheng",
        "code": "SLC",
        "childrens": []
      }]
    }, {
      "id": "370",
      "pid": "140",
      "path": ",4,140,370,",
      "level": "3",
      "name": "佐治亚",
      "name_en": "Georgia",
      "name_pinyin": "zuozhiya",
      "code": "GA",
      "childrens": [{
        "id": "4102",
        "pid": "370",
        "path": ",4,140,370,4102,",
        "level": "4",
        "name": "奥古斯塔",
        "name_en": "Augusta",
        "name_pinyin": "aogusita",
        "code": "AUT",
        "childrens": []
      }, {
        "id": "4103",
        "pid": "370",
        "path": ",4,140,370,4103,",
        "level": "4",
        "name": "哥伦布",
        "name_en": "Columbus",
        "name_pinyin": "gelunbu",
        "code": "CZX",
        "childrens": []
      }, {
        "id": "4104",
        "pid": "370",
        "path": ",4,140,370,4104,",
        "level": "4",
        "name": "梅肯",
        "name_en": "Macon",
        "name_pinyin": "meiken",
        "code": "MCN",
        "childrens": []
      }, {
        "id": "4105",
        "pid": "370",
        "path": ",4,140,370,4105,",
        "level": "4",
        "name": "沙瓦纳",
        "name_en": "Savannah",
        "name_pinyin": "shawana",
        "code": "SAV",
        "childrens": []
      }, {
        "id": "4106",
        "pid": "370",
        "path": ",4,140,370,4106,",
        "level": "4",
        "name": "亚特兰大",
        "name_en": "Atlanta",
        "name_pinyin": "yatelanda",
        "code": "TAT",
        "childrens": []
      }]
    }]
  }, {
    "id": "142",
    "pid": "4",
    "path": ",4,142,",
    "level": "2",
    "name": "美属外岛",
    "name_en": "United States Minor Outlying Islands",
    "name_pinyin": "meishuwaidao",
    "code": "UMI",
    "childrens": []
  }, {
    "id": "144",
    "pid": "4",
    "path": ",4,144,",
    "level": "2",
    "name": "蒙特塞拉特",
    "name_en": "Montserrat",
    "name_pinyin": "mengtesailate",
    "code": "MSR",
    "childrens": []
  }, {
    "id": "153",
    "pid": "4",
    "path": ",4,153,",
    "level": "2",
    "name": "墨西哥",
    "name_en": "Mexico",
    "name_pinyin": "moxige",
    "code": "MEX",
    "childrens": [{
      "id": "1811",
      "pid": "153",
      "path": ",4,153,1811,",
      "level": "3",
      "name": "阿瓜斯卡连斯特",
      "name_en": "Aguascalientes",
      "name_pinyin": "aguasikaliansite",
      "code": "AGU",
      "childrens": []
    }, {
      "id": "1812",
      "pid": "153",
      "path": ",4,153,1812,",
      "level": "3",
      "name": "阿卡普尔科",
      "name_en": "Acapulco",
      "name_pinyin": "akapuerke",
      "code": "ACA",
      "childrens": []
    }, {
      "id": "1813",
      "pid": "153",
      "path": ",4,153,1813,",
      "level": "3",
      "name": "埃莫西约",
      "name_en": "Hermosillo",
      "name_pinyin": "aimoxiyue",
      "code": "HMO",
      "childrens": []
    }, {
      "id": "1814",
      "pid": "153",
      "path": ",4,153,1814,",
      "level": "3",
      "name": "埃佩切",
      "name_en": "Campeche",
      "name_pinyin": "aipeiqie",
      "code": "CAM",
      "childrens": []
    }, {
      "id": "1815",
      "pid": "153",
      "path": ",4,153,1815,",
      "level": "3",
      "name": "奥夫雷贡城",
      "name_en": "Obregon",
      "name_pinyin": "aofuleigongcheng",
      "code": "OBR",
      "childrens": []
    }, {
      "id": "1816",
      "pid": "153",
      "path": ",4,153,1816,",
      "level": "3",
      "name": "奥里萨巴",
      "name_en": "Orizaba",
      "name_pinyin": "aolisaba",
      "code": "ORI",
      "childrens": []
    }, {
      "id": "1817",
      "pid": "153",
      "path": ",4,153,1817,",
      "level": "3",
      "name": "巴利城",
      "name_en": "Valles",
      "name_pinyin": "balicheng",
      "code": "VHM",
      "childrens": []
    }, {
      "id": "1818",
      "pid": "153",
      "path": ",4,153,1818,",
      "level": "3",
      "name": "巴亚尔塔港",
      "name_en": "Puerto Vallarta",
      "name_pinyin": "bayaertagang",
      "code": "PVR",
      "childrens": []
    }, {
      "id": "1819",
      "pid": "153",
      "path": ",4,153,1819,",
      "level": "3",
      "name": "比利亚埃尔莫萨",
      "name_en": "Villahermosa",
      "name_pinyin": "biliyaaiermosa",
      "code": "VSA",
      "childrens": []
    }, {
      "id": "1820",
      "pid": "153",
      "path": ",4,153,1820,",
      "level": "3",
      "name": "波萨里卡",
      "name_en": "Poza Rica de Hidalgo",
      "name_pinyin": "bosalika",
      "code": "PRH",
      "childrens": []
    }, {
      "id": "1821",
      "pid": "153",
      "path": ",4,153,1821,",
      "level": "3",
      "name": "蒂华纳",
      "name_en": "Tijuana",
      "name_pinyin": "dihuana",
      "code": "TIJ",
      "childrens": []
    }, {
      "id": "1822",
      "pid": "153",
      "path": ",4,153,1822,",
      "level": "3",
      "name": "杜兰戈",
      "name_en": "Durango",
      "name_pinyin": "dulange",
      "code": "DUR",
      "childrens": []
    }, {
      "id": "1823",
      "pid": "153",
      "path": ",4,153,1823,",
      "level": "3",
      "name": "恩塞纳达",
      "name_en": "Ensenada",
      "name_pinyin": "ensainada",
      "code": "ESE",
      "childrens": []
    }, {
      "id": "1824",
      "pid": "153",
      "path": ",4,153,1824,",
      "level": "3",
      "name": "瓜达拉哈拉",
      "name_en": "Guadalajara",
      "name_pinyin": "guadalahala",
      "code": "GDL",
      "childrens": []
    }, {
      "id": "1825",
      "pid": "153",
      "path": ",4,153,1825,",
      "level": "3",
      "name": "瓜纳华托",
      "name_en": "Guanajuato",
      "name_pinyin": "guanahuatuo",
      "code": "GUA",
      "childrens": []
    }, {
      "id": "1826",
      "pid": "153",
      "path": ",4,153,1826,",
      "level": "3",
      "name": "哈拉帕",
      "name_en": "Jalapa",
      "name_pinyin": "halapa",
      "code": "JAL",
      "childrens": []
    }, {
      "id": "1827",
      "pid": "153",
      "path": ",4,153,1827,",
      "level": "3",
      "name": "华雷斯",
      "name_en": "Juarez",
      "name_pinyin": "hualeisi",
      "code": "JUZ",
      "childrens": []
    }, {
      "id": "1828",
      "pid": "153",
      "path": ",4,153,1828,",
      "level": "3",
      "name": "华雷斯港",
      "name_en": "Benito Juare",
      "name_pinyin": "hualeisigang",
      "code": "BJU",
      "childrens": []
    }, {
      "id": "1829",
      "pid": "153",
      "path": ",4,153,1829,",
      "level": "3",
      "name": "卡门",
      "name_en": "Carmen",
      "name_pinyin": "kamen",
      "code": "CAR",
      "childrens": []
    }, {
      "id": "1830",
      "pid": "153",
      "path": ",4,153,1830,",
      "level": "3",
      "name": "科利马",
      "name_en": "Colima",
      "name_pinyin": "kelima",
      "code": "COL",
      "childrens": []
    }, {
      "id": "1831",
      "pid": "153",
      "path": ",4,153,1831,",
      "level": "3",
      "name": "克雷塔罗",
      "name_en": "Queretaro",
      "name_pinyin": "keleitaluo",
      "code": "QUE",
      "childrens": []
    }, {
      "id": "1832",
      "pid": "153",
      "path": ",4,153,1832,",
      "level": "3",
      "name": "库埃纳瓦卡",
      "name_en": "Cuernavaca",
      "name_pinyin": "kuainawaka",
      "code": "CVC",
      "childrens": []
    }, {
      "id": "1833",
      "pid": "153",
      "path": ",4,153,1833,",
      "level": "3",
      "name": "库利阿坎",
      "name_en": "Culiacan",
      "name_pinyin": "kuliakan",
      "code": "CUL",
      "childrens": []
    }, {
      "id": "1834",
      "pid": "153",
      "path": ",4,153,1834,",
      "level": "3",
      "name": "夸察夸拉克斯",
      "name_en": "Coatzacoalcos",
      "name_pinyin": "kuachakualakesi",
      "code": "COA",
      "childrens": []
    }, {
      "id": "1835",
      "pid": "153",
      "path": ",4,153,1835,",
      "level": "3",
      "name": "拉巴斯",
      "name_en": "La Paz",
      "name_pinyin": "labasi",
      "code": "LAP",
      "childrens": []
    }, {
      "id": "1836",
      "pid": "153",
      "path": ",4,153,1836,",
      "level": "3",
      "name": "莱昂",
      "name_en": "Leon",
      "name_pinyin": "laiang",
      "code": "LEN",
      "childrens": []
    }, {
      "id": "1837",
      "pid": "153",
      "path": ",4,153,1837,",
      "level": "3",
      "name": "雷诺萨",
      "name_en": "Reynosa",
      "name_pinyin": "leinuosa",
      "code": "REX",
      "childrens": []
    }, {
      "id": "1838",
      "pid": "153",
      "path": ",4,153,1838,",
      "level": "3",
      "name": "洛斯莫奇斯",
      "name_en": "Los Mochis",
      "name_pinyin": "luosimoqisi",
      "code": "LMM",
      "childrens": []
    }, {
      "id": "1839",
      "pid": "153",
      "path": ",4,153,1839,",
      "level": "3",
      "name": "马萨特兰",
      "name_en": "Mazatlan",
      "name_pinyin": "masatelan",
      "code": "MZT",
      "childrens": []
    }, {
      "id": "1840",
      "pid": "153",
      "path": ",4,153,1840,",
      "level": "3",
      "name": "马塔莫罗斯",
      "name_en": "Matamoros",
      "name_pinyin": "matamoluosi",
      "code": "MAM",
      "childrens": []
    }, {
      "id": "1841",
      "pid": "153",
      "path": ",4,153,1841,",
      "level": "3",
      "name": "梅里达",
      "name_en": "Merida",
      "name_pinyin": "meilida",
      "code": "MID",
      "childrens": []
    }, {
      "id": "1842",
      "pid": "153",
      "path": ",4,153,1842,",
      "level": "3",
      "name": "蒙克洛瓦",
      "name_en": "Monclova",
      "name_pinyin": "mengkeluowa",
      "code": "LOV",
      "childrens": []
    }, {
      "id": "1843",
      "pid": "153",
      "path": ",4,153,1843,",
      "level": "3",
      "name": "蒙特雷",
      "name_en": "Monterrey",
      "name_pinyin": "mengtelei",
      "code": "MTY",
      "childrens": []
    }, {
      "id": "1844",
      "pid": "153",
      "path": ",4,153,1844,",
      "level": "3",
      "name": "莫雷利亚",
      "name_en": "Morelia",
      "name_pinyin": "moleiliya",
      "code": "MLM",
      "childrens": []
    }, {
      "id": "1845",
      "pid": "153",
      "path": ",4,153,1845,",
      "level": "3",
      "name": "墨西哥城",
      "name_en": "Mexico City",
      "name_pinyin": "moxigecheng",
      "code": "MEX",
      "childrens": []
    }, {
      "id": "1846",
      "pid": "153",
      "path": ",4,153,1846,",
      "level": "3",
      "name": "墨西卡利",
      "name_en": "Mexicali",
      "name_pinyin": "moxikali",
      "code": "MXL",
      "childrens": []
    }, {
      "id": "1847",
      "pid": "153",
      "path": ",4,153,1847,",
      "level": "3",
      "name": "诺加莱斯",
      "name_en": "Nogales",
      "name_pinyin": "nuojialaisi",
      "code": "NOG",
      "childrens": []
    }, {
      "id": "1848",
      "pid": "153",
      "path": ",4,153,1848,",
      "level": "3",
      "name": "帕丘卡",
      "name_en": "Pachuca",
      "name_pinyin": "paqiuka",
      "code": "PAC",
      "childrens": []
    }, {
      "id": "1849",
      "pid": "153",
      "path": ",4,153,1849,",
      "level": "3",
      "name": "普埃布拉",
      "name_en": "Puebla",
      "name_pinyin": "puaibula",
      "code": "PUE",
      "childrens": []
    }, {
      "id": "1850",
      "pid": "153",
      "path": ",4,153,1850,",
      "level": "3",
      "name": "奇尔潘辛戈",
      "name_en": "Chilpancingo",
      "name_pinyin": "qierpanxinge",
      "code": "CHI",
      "childrens": []
    }, {
      "id": "1851",
      "pid": "153",
      "path": ",4,153,1851,",
      "level": "3",
      "name": "奇瓦瓦",
      "name_en": "Chihuahua",
      "name_pinyin": "qiwawa",
      "code": "CHH",
      "childrens": []
    }, {
      "id": "1852",
      "pid": "153",
      "path": ",4,153,1852,",
      "level": "3",
      "name": "切图马尔",
      "name_en": "Cheturnal",
      "name_pinyin": "qietumaer",
      "code": "CTM",
      "childrens": []
    }, {
      "id": "1853",
      "pid": "153",
      "path": ",4,153,1853,",
      "level": "3",
      "name": "萨尔蒂约",
      "name_en": "Saltillo",
      "name_pinyin": "saerdiyue",
      "code": "SLW",
      "childrens": []
    }, {
      "id": "1854",
      "pid": "153",
      "path": ",4,153,1854,",
      "level": "3",
      "name": "萨卡特卡斯",
      "name_en": "Zacatecas",
      "name_pinyin": "sakatekasi",
      "code": "ZAC",
      "childrens": []
    }, {
      "id": "1855",
      "pid": "153",
      "path": ",4,153,1855,",
      "level": "3",
      "name": "塞拉亚",
      "name_en": "Celaya",
      "name_pinyin": "sailaya",
      "code": "CLY",
      "childrens": []
    }, {
      "id": "1856",
      "pid": "153",
      "path": ",4,153,1856,",
      "level": "3",
      "name": "圣路易斯波托亚",
      "name_en": "San Luis Potosi",
      "name_pinyin": "shengluyisibotuoya",
      "code": "SLP",
      "childrens": []
    }, {
      "id": "1857",
      "pid": "153",
      "path": ",4,153,1857,",
      "level": "3",
      "name": "塔帕丘拉",
      "name_en": "Tapachula",
      "name_pinyin": "tapaqiula",
      "code": "TAP",
      "childrens": []
    }, {
      "id": "1858",
      "pid": "153",
      "path": ",4,153,1858,",
      "level": "3",
      "name": "坦皮科",
      "name_en": "Tampico",
      "name_pinyin": "tanpike",
      "code": "TAM",
      "childrens": []
    }, {
      "id": "1859",
      "pid": "153",
      "path": ",4,153,1859,",
      "level": "3",
      "name": "特拉斯卡拉",
      "name_en": "Tlaxcala",
      "name_pinyin": "telasikala",
      "code": "TLA",
      "childrens": []
    }, {
      "id": "1860",
      "pid": "153",
      "path": ",4,153,1860,",
      "level": "3",
      "name": "特皮克",
      "name_en": "Tepic",
      "name_pinyin": "tepike",
      "code": "TPQ",
      "childrens": []
    }, {
      "id": "1861",
      "pid": "153",
      "path": ",4,153,1861,",
      "level": "3",
      "name": "特瓦坎",
      "name_en": "Tehuacan",
      "name_pinyin": "tewakan",
      "code": "TCN",
      "childrens": []
    }, {
      "id": "1862",
      "pid": "153",
      "path": ",4,153,1862,",
      "level": "3",
      "name": "图斯特拉-古铁雷斯",
      "name_en": "Tuxtla Gutierrez",
      "name_pinyin": "tusitelagutieleisi",
      "code": "TGZ",
      "childrens": []
    }, {
      "id": "1863",
      "pid": "153",
      "path": ",4,153,1863,",
      "level": "3",
      "name": "托雷翁",
      "name_en": "Torreon",
      "name_pinyin": "tuoleiweng",
      "code": "TRC",
      "childrens": []
    }, {
      "id": "1864",
      "pid": "153",
      "path": ",4,153,1864,",
      "level": "3",
      "name": "托卢卡",
      "name_en": "Toluca",
      "name_pinyin": "tuoluka",
      "code": "TLC",
      "childrens": []
    }, {
      "id": "1865",
      "pid": "153",
      "path": ",4,153,1865,",
      "level": "3",
      "name": "瓦哈卡",
      "name_en": "Oaxaca",
      "name_pinyin": "wahaka",
      "code": "OAX",
      "childrens": []
    }, {
      "id": "1866",
      "pid": "153",
      "path": ",4,153,1866,",
      "level": "3",
      "name": "维多利亚城",
      "name_en": "Victoria",
      "name_pinyin": "weiduoliyacheng",
      "code": "VIC",
      "childrens": []
    }, {
      "id": "1867",
      "pid": "153",
      "path": ",4,153,1867,",
      "level": "3",
      "name": "韦拉克鲁斯",
      "name_en": "Veracruz",
      "name_pinyin": "weilakelusi",
      "code": "VER",
      "childrens": []
    }, {
      "id": "1868",
      "pid": "153",
      "path": ",4,153,1868,",
      "level": "3",
      "name": "乌鲁阿潘",
      "name_en": "Uruapan",
      "name_pinyin": "wuluapan",
      "code": "UPN",
      "childrens": []
    }, {
      "id": "1869",
      "pid": "153",
      "path": ",4,153,1869,",
      "level": "3",
      "name": "新拉雷多",
      "name_en": "Nuevo Laredo",
      "name_pinyin": "xinlaleiduo",
      "code": "NLE",
      "childrens": []
    }, {
      "id": "1870",
      "pid": "153",
      "path": ",4,153,1870,",
      "level": "3",
      "name": "伊拉普阿托",
      "name_en": "Irapuato",
      "name_pinyin": "yilapuatuo",
      "code": "IRP",
      "childrens": []
    }]
  }, {
    "id": "160",
    "pid": "4",
    "path": ",4,160,",
    "level": "2",
    "name": "尼加拉瓜",
    "name_en": "Nicaragua",
    "name_pinyin": "nijialagua",
    "code": "NIC",
    "childrens": [{
      "id": "1948",
      "pid": "160",
      "path": ",4,160,1948,",
      "level": "3",
      "name": "埃斯特利",
      "name_en": "Esteli",
      "name_pinyin": "aisiteli",
      "code": "ES",
      "childrens": []
    }, {
      "id": "1949",
      "pid": "160",
      "path": ",4,160,1949,",
      "level": "3",
      "name": "北大西洋",
      "name_en": "Atlantico Norte",
      "name_pinyin": "beidaxiyang",
      "code": "AN",
      "childrens": []
    }, {
      "id": "1950",
      "pid": "160",
      "path": ",4,160,1950,",
      "level": "3",
      "name": "博阿科",
      "name_en": "Boaco",
      "name_pinyin": "boake",
      "code": "BO",
      "childrens": []
    }, {
      "id": "1951",
      "pid": "160",
      "path": ",4,160,1951,",
      "level": "3",
      "name": "格拉纳达",
      "name_en": "Granada",
      "name_pinyin": "gelanada",
      "code": "GR",
      "childrens": []
    }, {
      "id": "1952",
      "pid": "160",
      "path": ",4,160,1952,",
      "level": "3",
      "name": "卡拉索",
      "name_en": "Carazo",
      "name_pinyin": "kalasuo",
      "code": "CA",
      "childrens": []
    }, {
      "id": "1953",
      "pid": "160",
      "path": ",4,160,1953,",
      "level": "3",
      "name": "莱昂",
      "name_en": "Leon",
      "name_pinyin": "laiang",
      "code": "LE",
      "childrens": []
    }, {
      "id": "1954",
      "pid": "160",
      "path": ",4,160,1954,",
      "level": "3",
      "name": "里瓦斯",
      "name_en": "Rivas",
      "name_pinyin": "liwasi",
      "code": "RV",
      "childrens": []
    }, {
      "id": "1955",
      "pid": "160",
      "path": ",4,160,1955,",
      "level": "3",
      "name": "马德里斯",
      "name_en": "Madriz",
      "name_pinyin": "madelisi",
      "code": "MD",
      "childrens": []
    }, {
      "id": "1956",
      "pid": "160",
      "path": ",4,160,1956,",
      "level": "3",
      "name": "马那瓜",
      "name_en": "Managua",
      "name_pinyin": "managua",
      "code": "MN",
      "childrens": []
    }, {
      "id": "1957",
      "pid": "160",
      "path": ",4,160,1957,",
      "level": "3",
      "name": "马萨亚",
      "name_en": "Masaya",
      "name_pinyin": "masaya",
      "code": "MS",
      "childrens": []
    }, {
      "id": "1958",
      "pid": "160",
      "path": ",4,160,1958,",
      "level": "3",
      "name": "马塔加尔帕",
      "name_en": "Matagalpa",
      "name_pinyin": "matajiaerpa",
      "code": "MT",
      "childrens": []
    }, {
      "id": "1959",
      "pid": "160",
      "path": ",4,160,1959,",
      "level": "3",
      "name": "南大西洋",
      "name_en": "Atlantico Sur",
      "name_pinyin": "nandaxiyang",
      "code": "AS",
      "childrens": []
    }, {
      "id": "1960",
      "pid": "160",
      "path": ",4,160,1960,",
      "level": "3",
      "name": "奇南德加",
      "name_en": "Chinandega",
      "name_pinyin": "qinandejia",
      "code": "CD",
      "childrens": []
    }, {
      "id": "1961",
      "pid": "160",
      "path": ",4,160,1961,",
      "level": "3",
      "name": "琼塔莱斯",
      "name_en": "Chontales",
      "name_pinyin": "qiongtalaisi",
      "code": "CT",
      "childrens": []
    }, {
      "id": "1962",
      "pid": "160",
      "path": ",4,160,1962,",
      "level": "3",
      "name": "圣胡安河",
      "name_en": "Rio San Juan",
      "name_pinyin": "shenghuanhe",
      "code": "SJ",
      "childrens": []
    }, {
      "id": "1963",
      "pid": "160",
      "path": ",4,160,1963,",
      "level": "3",
      "name": "希诺特加",
      "name_en": "Jinotega",
      "name_pinyin": "xinuotejia",
      "code": "JI",
      "childrens": []
    }, {
      "id": "1964",
      "pid": "160",
      "path": ",4,160,1964,",
      "level": "3",
      "name": "新塞哥维亚",
      "name_en": "Nueva Segovia",
      "name_pinyin": "xinsaigeweiya",
      "code": "NS",
      "childrens": []
    }]
  }, {
    "id": "165",
    "pid": "4",
    "path": ",4,165,",
    "level": "2",
    "name": "诺福克",
    "name_en": "Norfolk Island",
    "name_pinyin": "nuofuke",
    "code": "NFK",
    "childrens": []
  }, {
    "id": "173",
    "pid": "4",
    "path": ",4,173,",
    "level": "2",
    "name": "萨尔瓦多",
    "name_en": "El Salvador",
    "name_pinyin": "saerwaduo",
    "code": "SLV",
    "childrens": [{
      "id": "2121",
      "pid": "173",
      "path": ",4,173,2121,",
      "level": "3",
      "name": "阿波帕",
      "name_en": "Apopa",
      "name_pinyin": "abopa",
      "code": "APO",
      "childrens": []
    }, {
      "id": "2122",
      "pid": "173",
      "path": ",4,173,2122,",
      "level": "3",
      "name": "阿瓦查潘",
      "name_en": "Ahuachapan",
      "name_pinyin": "awachapan",
      "code": "AH",
      "childrens": []
    }, {
      "id": "2123",
      "pid": "173",
      "path": ",4,173,2123,",
      "level": "3",
      "name": "滨海",
      "name_en": "Litoral",
      "name_pinyin": "binhai",
      "code": "LI",
      "childrens": []
    }, {
      "id": "2124",
      "pid": "173",
      "path": ",4,173,2124,",
      "level": "3",
      "name": "查拉特南戈",
      "name_en": "Chalatenango",
      "name_pinyin": "chalatenange",
      "code": "CH",
      "childrens": []
    }, {
      "id": "2125",
      "pid": "173",
      "path": ",4,173,2125,",
      "level": "3",
      "name": "德尔加多",
      "name_en": "Delgado",
      "name_pinyin": "deerjiaduo",
      "code": "DE",
      "childrens": []
    }, {
      "id": "2126",
      "pid": "173",
      "path": ",4,173,2126,",
      "level": "3",
      "name": "基埃-恩特姆",
      "name_en": "Kie-Ntem",
      "name_pinyin": "jiaientemu",
      "code": "KN",
      "childrens": []
    }, {
      "id": "2127",
      "pid": "173",
      "path": ",4,173,2127,",
      "level": "3",
      "name": "卡瓦尼亚斯",
      "name_en": "Cabanas",
      "name_pinyin": "kawaniyasi",
      "code": "CA",
      "childrens": []
    }, {
      "id": "2128",
      "pid": "173",
      "path": ",4,173,2128,",
      "level": "3",
      "name": "库斯卡特兰",
      "name_en": "Cuscatlan",
      "name_pinyin": "kusikatelan",
      "code": "CU",
      "childrens": []
    }, {
      "id": "2129",
      "pid": "173",
      "path": ",4,173,2129,",
      "level": "3",
      "name": "拉巴斯",
      "name_en": "La Paz",
      "name_pinyin": "labasi",
      "code": "PZ",
      "childrens": []
    }, {
      "id": "2130",
      "pid": "173",
      "path": ",4,173,2130,",
      "level": "3",
      "name": "拉利伯塔德",
      "name_en": "La Libertad",
      "name_pinyin": "lalibotade",
      "code": "LB",
      "childrens": []
    }, {
      "id": "2131",
      "pid": "173",
      "path": ",4,173,2131,",
      "level": "3",
      "name": "拉乌尼翁",
      "name_en": "La Union",
      "name_pinyin": "lawuniweng",
      "code": "UN",
      "childrens": []
    }, {
      "id": "2132",
      "pid": "173",
      "path": ",4,173,2132,",
      "level": "3",
      "name": "梅基卡诺斯",
      "name_en": "Mejicanos",
      "name_pinyin": "meijikanuosi",
      "code": "MEJ",
      "childrens": []
    }, {
      "id": "2133",
      "pid": "173",
      "path": ",4,173,2133,",
      "level": "3",
      "name": "莫拉桑",
      "name_en": "Morazan",
      "name_pinyin": "molasang",
      "code": "MO",
      "childrens": []
    }, {
      "id": "2134",
      "pid": "173",
      "path": ",4,173,2134,",
      "level": "3",
      "name": "圣安娜",
      "name_en": "Santa Ana",
      "name_pinyin": "shenganna",
      "code": "SA",
      "childrens": []
    }, {
      "id": "2135",
      "pid": "173",
      "path": ",4,173,2135,",
      "level": "3",
      "name": "圣米格尔",
      "name_en": "San Miguel",
      "name_pinyin": "shengmigeer",
      "code": "SM",
      "childrens": []
    }, {
      "id": "2136",
      "pid": "173",
      "path": ",4,173,2136,",
      "level": "3",
      "name": "圣萨尔瓦多",
      "name_en": "San Salvador",
      "name_pinyin": "shengsaerwaduo",
      "code": "SS",
      "childrens": []
    }, {
      "id": "2137",
      "pid": "173",
      "path": ",4,173,2137,",
      "level": "3",
      "name": "圣维森特",
      "name_en": "San Vicente",
      "name_pinyin": "shengweisente",
      "code": "SV",
      "childrens": []
    }, {
      "id": "2138",
      "pid": "173",
      "path": ",4,173,2138,",
      "level": "3",
      "name": "松索纳特",
      "name_en": "Sonsonate",
      "name_pinyin": "songsuonate",
      "code": "SO",
      "childrens": []
    }, {
      "id": "2139",
      "pid": "173",
      "path": ",4,173,2139,",
      "level": "3",
      "name": "索亚潘戈",
      "name_en": "Soyapango",
      "name_pinyin": "suoyapange",
      "code": "SOY",
      "childrens": []
    }, {
      "id": "2140",
      "pid": "173",
      "path": ",4,173,2140,",
      "level": "3",
      "name": "韦莱-恩萨斯",
      "name_en": "Wele-Nzas",
      "name_pinyin": "weilaiensasi",
      "code": "WN",
      "childrens": []
    }, {
      "id": "2141",
      "pid": "173",
      "path": ",4,173,2141,",
      "level": "3",
      "name": "乌苏卢坦",
      "name_en": "Usulutan",
      "name_pinyin": "wusulutan",
      "code": "US",
      "childrens": []
    }, {
      "id": "2142",
      "pid": "173",
      "path": ",4,173,2142,",
      "level": "3",
      "name": "伊洛潘戈",
      "name_en": "Ilopango",
      "name_pinyin": "yiluopange",
      "code": "IL",
      "childrens": []
    }, {
      "id": "2143",
      "pid": "173",
      "path": ",4,173,2143,",
      "level": "3",
      "name": "中南",
      "name_en": "Centro Sur",
      "name_pinyin": "zhongnan",
      "code": "CS",
      "childrens": []
    }]
  }, {
    "id": "184",
    "pid": "4",
    "path": ",4,184,",
    "level": "2",
    "name": "圣基茨和尼维斯",
    "name_en": "St.Kitts and Nevis",
    "name_pinyin": "shengjiciheniweisi",
    "code": "KNA",
    "childrens": []
  }, {
    "id": "185",
    "pid": "4",
    "path": ",4,185,",
    "level": "2",
    "name": "圣卢西亚",
    "name_en": "St.Lucia",
    "name_pinyin": "shengluxiya",
    "code": "LCA",
    "childrens": []
  }, {
    "id": "187",
    "pid": "4",
    "path": ",4,187,",
    "level": "2",
    "name": "圣皮埃尔和米克隆群岛",
    "name_en": "St.Pierre and Miquelon",
    "name_pinyin": "shengpiaierhemikelongqundao",
    "code": "SPM",
    "childrens": []
  }, {
    "id": "188",
    "pid": "4",
    "path": ",4,188,",
    "level": "2",
    "name": "圣文森特和格林纳丁斯",
    "name_en": "St.Vincent and the Grenadines",
    "name_pinyin": "shengwensentehegelinnadingsi",
    "code": "VCT",
    "childrens": []
  }, {
    "id": "202",
    "pid": "4",
    "path": ",4,202,",
    "level": "2",
    "name": "特克斯和凯克特斯群岛",
    "name_en": "Turks and Caicos Islands",
    "name_pinyin": "tekesihekaiketesiqundao",
    "code": "TCA",
    "childrens": []
  }, {
    "id": "204",
    "pid": "4",
    "path": ",4,204,",
    "level": "2",
    "name": "特立尼达和多巴哥",
    "name_en": "Trinidad and Tobago",
    "name_pinyin": "telinidaheduobage",
    "code": "TTO",
    "childrens": []
  }, {
    "id": "212",
    "pid": "4",
    "path": ",4,212,",
    "level": "2",
    "name": "危地马拉",
    "name_en": "Guatemala",
    "name_pinyin": "weidimala",
    "code": "GTM",
    "childrens": [{
      "id": "2505",
      "pid": "212",
      "path": ",4,212,2505,",
      "level": "3",
      "name": "埃尔普罗格雷索",
      "name_en": "El Progreso",
      "name_pinyin": "aierpuluogeleisuo",
      "code": "PR",
      "childrens": []
    }, {
      "id": "2506",
      "pid": "212",
      "path": ",4,212,2506,",
      "level": "3",
      "name": "埃斯昆特拉",
      "name_en": "Escuintla",
      "name_pinyin": "aisikuntela",
      "code": "ES",
      "childrens": []
    }, {
      "id": "2507",
      "pid": "212",
      "path": ",4,212,2507,",
      "level": "3",
      "name": "哈拉帕",
      "name_en": "Jalapa",
      "name_pinyin": "halapa",
      "code": "JA",
      "childrens": []
    }, {
      "id": "2508",
      "pid": "212",
      "path": ",4,212,2508,",
      "level": "3",
      "name": "胡蒂亚帕",
      "name_en": "Jutiapa",
      "name_pinyin": "hudiyapa",
      "code": "JU",
      "childrens": []
    }, {
      "id": "2509",
      "pid": "212",
      "path": ",4,212,2509,",
      "level": "3",
      "name": "基切",
      "name_en": "Quiche",
      "name_pinyin": "jiqie",
      "code": "QC",
      "childrens": []
    }, {
      "id": "2510",
      "pid": "212",
      "path": ",4,212,2510,",
      "level": "3",
      "name": "克萨尔特南戈",
      "name_en": "Quetzaltenango",
      "name_pinyin": "kesaertenange",
      "code": "QZ",
      "childrens": []
    }, {
      "id": "2511",
      "pid": "212",
      "path": ",4,212,2511,",
      "level": "3",
      "name": "雷塔卢莱乌",
      "name_en": "Retalhuleu",
      "name_pinyin": "leitalulaiwu",
      "code": "RE",
      "childrens": []
    }, {
      "id": "2512",
      "pid": "212",
      "path": ",4,212,2512,",
      "level": "3",
      "name": "米克斯科",
      "name_en": "Mixco",
      "name_pinyin": "mikesike",
      "code": "MIX",
      "childrens": []
    }, {
      "id": "2513",
      "pid": "212",
      "path": ",4,212,2513,",
      "level": "3",
      "name": "佩滕",
      "name_en": "Peten",
      "name_pinyin": "pei",
      "code": "PE",
      "childrens": []
    }, {
      "id": "2514",
      "pid": "212",
      "path": ",4,212,2514,",
      "level": "3",
      "name": "奇基穆拉",
      "name_en": "Chiquimula",
      "name_pinyin": "qijimula",
      "code": "CQ",
      "childrens": []
    }, {
      "id": "2515",
      "pid": "212",
      "path": ",4,212,2515,",
      "level": "3",
      "name": "奇马尔特南戈",
      "name_en": "Chimaltenango",
      "name_pinyin": "qimaertenange",
      "code": "CM",
      "childrens": []
    }, {
      "id": "2516",
      "pid": "212",
      "path": ",4,212,2516,",
      "level": "3",
      "name": "萨卡帕",
      "name_en": "Zacapa",
      "name_pinyin": "sakapa",
      "code": "ZA",
      "childrens": []
    }, {
      "id": "2517",
      "pid": "212",
      "path": ",4,212,2517,",
      "level": "3",
      "name": "萨卡特佩克斯",
      "name_en": "Sacatepequez",
      "name_pinyin": "sakatepeikesi",
      "code": "ST",
      "childrens": []
    }, {
      "id": "2518",
      "pid": "212",
      "path": ",4,212,2518,",
      "level": "3",
      "name": "上韦拉帕斯",
      "name_en": "Alta Verapaz",
      "name_pinyin": "shangweilapasi",
      "code": "AV",
      "childrens": []
    }, {
      "id": "2519",
      "pid": "212",
      "path": ",4,212,2519,",
      "level": "3",
      "name": "圣罗莎",
      "name_en": "Santa Rosa",
      "name_pinyin": "shengluosha",
      "code": "SR",
      "childrens": []
    }, {
      "id": "2520",
      "pid": "212",
      "path": ",4,212,2520,",
      "level": "3",
      "name": "圣马科斯",
      "name_en": "San Marcos",
      "name_pinyin": "shengmakesi",
      "code": "SM",
      "childrens": []
    }, {
      "id": "2521",
      "pid": "212",
      "path": ",4,212,2521,",
      "level": "3",
      "name": "苏奇特佩克斯",
      "name_en": "Suchitepequez",
      "name_pinyin": "suqitepeikesi",
      "code": "SU",
      "childrens": []
    }, {
      "id": "2522",
      "pid": "212",
      "path": ",4,212,2522,",
      "level": "3",
      "name": "索洛拉",
      "name_en": "Solola",
      "name_pinyin": "suoluola",
      "code": "SO",
      "childrens": []
    }, {
      "id": "2523",
      "pid": "212",
      "path": ",4,212,2523,",
      "level": "3",
      "name": "托托尼卡潘",
      "name_en": "Totonicapan",
      "name_pinyin": "tuotuonikapan",
      "code": "TO",
      "childrens": []
    }, {
      "id": "2524",
      "pid": "212",
      "path": ",4,212,2524,",
      "level": "3",
      "name": "危地马拉",
      "name_en": "Guatemala",
      "name_pinyin": "weidimala",
      "code": "GU",
      "childrens": []
    }, {
      "id": "2525",
      "pid": "212",
      "path": ",4,212,2525,",
      "level": "3",
      "name": "韦韦特南戈",
      "name_en": "Huehuetenango",
      "name_pinyin": "weiweitenange",
      "code": "HU",
      "childrens": []
    }, {
      "id": "2526",
      "pid": "212",
      "path": ",4,212,2526,",
      "level": "3",
      "name": "下韦拉帕斯",
      "name_en": "Baja Verapaz",
      "name_pinyin": "xiaweilapasi",
      "code": "BV",
      "childrens": []
    }, {
      "id": "2527",
      "pid": "212",
      "path": ",4,212,2527,",
      "level": "3",
      "name": "新城",
      "name_en": "Villa Nueva",
      "name_pinyin": "xincheng",
      "code": "VIN",
      "childrens": []
    }, {
      "id": "2528",
      "pid": "212",
      "path": ",4,212,2528,",
      "level": "3",
      "name": "伊萨瓦尔",
      "name_en": "Izabal",
      "name_pinyin": "yisawaer",
      "code": "IZ",
      "childrens": []
    }]
  }, {
    "id": "213",
    "pid": "4",
    "path": ",4,213,",
    "level": "2",
    "name": "维尔京群岛，美属",
    "name_en": "Virgin Islands",
    "name_pinyin": "weierjingqundaomeishu",
    "code": "VIR",
    "childrens": []
  }, {
    "id": "214",
    "pid": "4",
    "path": ",4,214,",
    "level": "2",
    "name": "维尔京群岛，英属",
    "name_en": "Virgin Islands,British",
    "name_pinyin": "weierjingqundaoyingshu",
    "code": "VGB",
    "childrens": []
  }, {
    "id": "228",
    "pid": "4",
    "path": ",4,228,",
    "level": "2",
    "name": "牙买加",
    "name_en": "Jamaica",
    "name_pinyin": "yamaijia",
    "code": "JAM",
    "childrens": [{
      "id": "2787",
      "pid": "228",
      "path": ",4,228,2787,",
      "level": "3",
      "name": "波特兰",
      "name_en": "Portland",
      "name_pinyin": "botelan",
      "code": "POR",
      "childrens": []
    }, {
      "id": "2788",
      "pid": "228",
      "path": ",4,228,2788,",
      "level": "3",
      "name": "汉诺威",
      "name_en": "Hanover",
      "name_pinyin": "hannuowei",
      "code": "HAN",
      "childrens": []
    }, {
      "id": "2789",
      "pid": "228",
      "path": ",4,228,2789,",
      "level": "3",
      "name": "金斯敦",
      "name_en": "Kingston",
      "name_pinyin": "jinsidun",
      "code": "KIN",
      "childrens": []
    }, {
      "id": "2790",
      "pid": "228",
      "path": ",4,228,2790,",
      "level": "3",
      "name": "克拉伦登",
      "name_en": "Clarendon",
      "name_pinyin": "kelalundeng",
      "code": "CLA",
      "childrens": []
    }, {
      "id": "2791",
      "pid": "228",
      "path": ",4,228,2791,",
      "level": "3",
      "name": "曼彻斯特",
      "name_en": "Manchester",
      "name_pinyin": "manchesite",
      "code": "MAN",
      "childrens": []
    }, {
      "id": "2792",
      "pid": "228",
      "path": ",4,228,2792,",
      "level": "3",
      "name": "圣安德鲁斯",
      "name_en": "St. Andrews",
      "name_pinyin": "shengandelusi",
      "code": "AND",
      "childrens": []
    }, {
      "id": "2793",
      "pid": "228",
      "path": ",4,228,2793,",
      "level": "3",
      "name": "圣安娜",
      "name_en": "St. Ann",
      "name_pinyin": "shenganna",
      "code": "ANN",
      "childrens": []
    }, {
      "id": "2794",
      "pid": "228",
      "path": ",4,228,2794,",
      "level": "3",
      "name": "圣凯瑟琳",
      "name_en": "St. Catherine",
      "name_pinyin": "shengkaiselin",
      "code": "CAT",
      "childrens": []
    }, {
      "id": "2795",
      "pid": "228",
      "path": ",4,228,2795,",
      "level": "3",
      "name": "圣玛丽",
      "name_en": "St. Mary",
      "name_pinyin": "shengmali",
      "code": "MAR",
      "childrens": []
    }, {
      "id": "2796",
      "pid": "228",
      "path": ",4,228,2796,",
      "level": "3",
      "name": "圣托马斯",
      "name_en": "St. Thomas",
      "name_pinyin": "shengtuomasi",
      "code": "THO",
      "childrens": []
    }, {
      "id": "2797",
      "pid": "228",
      "path": ",4,228,2797,",
      "level": "3",
      "name": "圣伊丽莎白",
      "name_en": "St. Elizabeth",
      "name_pinyin": "shengyilishabai",
      "code": "ELI",
      "childrens": []
    }, {
      "id": "2798",
      "pid": "228",
      "path": ",4,228,2798,",
      "level": "3",
      "name": "圣詹姆斯",
      "name_en": "St. James",
      "name_pinyin": "shengzhanmusi",
      "code": "JAM",
      "childrens": []
    }, {
      "id": "2799",
      "pid": "228",
      "path": ",4,228,2799,",
      "level": "3",
      "name": "特里洛尼",
      "name_en": "Trelawny",
      "name_pinyin": "teliluoni",
      "code": "TRL",
      "childrens": []
    }, {
      "id": "2800",
      "pid": "228",
      "path": ",4,228,2800,",
      "level": "3",
      "name": "西摩兰",
      "name_en": "Westmoreland",
      "name_pinyin": "ximolan",
      "code": "WML",
      "childrens": []
    }]
  }]
}, {
  "id": "5",
  "pid": "0",
  "path": ",5,",
  "level": "1",
  "name": "南美",
  "name_en": "South America",
  "name_pinyin": "nanmei",
  "code": null,
  "childrens": [{
    "id": "11",
    "pid": "5",
    "path": ",5,11,",
    "level": "2",
    "name": "阿根廷",
    "name_en": "Argentina",
    "name_pinyin": "agenting",
    "code": "ARG",
    "childrens": [{
      "id": "439",
      "pid": "11",
      "path": ",5,11,439,",
      "level": "3",
      "name": "巴拉那",
      "name_en": "Parana",
      "name_pinyin": "balana",
      "code": "PRA",
      "childrens": []
    }, {
      "id": "440",
      "pid": "11",
      "path": ",5,11,440,",
      "level": "3",
      "name": "别德马",
      "name_en": "Viedma",
      "name_pinyin": "biedema",
      "code": "VDM",
      "childrens": []
    }, {
      "id": "441",
      "pid": "11",
      "path": ",5,11,441,",
      "level": "3",
      "name": "波萨达斯",
      "name_en": "Posadas",
      "name_pinyin": "bosadasi",
      "code": "PSS",
      "childrens": []
    }, {
      "id": "442",
      "pid": "11",
      "path": ",5,11,442,",
      "level": "3",
      "name": "布兰卡港",
      "name_en": "Bahia Blanca",
      "name_pinyin": "bulankagang",
      "code": "BHI",
      "childrens": []
    }, {
      "id": "443",
      "pid": "11",
      "path": ",5,11,443,",
      "level": "3",
      "name": "布宜诺斯艾利斯",
      "name_en": "Buenos Aires",
      "name_pinyin": "buyinuosiailisi",
      "code": "BUE",
      "childrens": []
    }, {
      "id": "444",
      "pid": "11",
      "path": ",5,11,444,",
      "level": "3",
      "name": "福莫萨",
      "name_en": "Formosa",
      "name_pinyin": "fumosa",
      "code": "FMA",
      "childrens": []
    }, {
      "id": "445",
      "pid": "11",
      "path": ",5,11,445,",
      "level": "3",
      "name": "胡胡伊",
      "name_en": "Jujuy",
      "name_pinyin": "huhuyi",
      "code": "JUJ",
      "childrens": []
    }, {
      "id": "446",
      "pid": "11",
      "path": ",5,11,446,",
      "level": "3",
      "name": "卡塔马卡",
      "name_en": "Catamarca",
      "name_pinyin": "katamaka",
      "code": "CTC",
      "childrens": []
    }, {
      "id": "447",
      "pid": "11",
      "path": ",5,11,447,",
      "level": "3",
      "name": "科尔多瓦",
      "name_en": "Cordoba",
      "name_pinyin": "keerduowa",
      "code": "COR",
      "childrens": []
    }, {
      "id": "448",
      "pid": "11",
      "path": ",5,11,448,",
      "level": "3",
      "name": "科连特斯",
      "name_en": "Corrientes",
      "name_pinyin": "keliantesi",
      "code": "CNQ",
      "childrens": []
    }, {
      "id": "449",
      "pid": "11",
      "path": ",5,11,449,",
      "level": "3",
      "name": "克劳斯城",
      "name_en": "Villa Krause",
      "name_pinyin": "kelaosicheng",
      "code": "VLK",
      "childrens": []
    }, {
      "id": "450",
      "pid": "11",
      "path": ",5,11,450,",
      "level": "3",
      "name": "肯考迪娅",
      "name_en": "Concordia",
      "name_pinyin": "kenkaodi",
      "code": "COC",
      "childrens": []
    }, {
      "id": "451",
      "pid": "11",
      "path": ",5,11,451,",
      "level": "3",
      "name": "拉里奥哈",
      "name_en": "La Rioja",
      "name_pinyin": "laliaoha",
      "code": "IRJ",
      "childrens": []
    }, {
      "id": "452",
      "pid": "11",
      "path": ",5,11,452,",
      "level": "3",
      "name": "拉普拉塔",
      "name_en": "La Plata",
      "name_pinyin": "lapulata",
      "code": "LPG",
      "childrens": []
    }, {
      "id": "453",
      "pid": "11",
      "path": ",5,11,453,",
      "level": "3",
      "name": "雷西斯滕匹亚",
      "name_en": "Resistencia",
      "name_pinyin": "leixisipiya",
      "code": "RES",
      "childrens": []
    }, {
      "id": "454",
      "pid": "11",
      "path": ",5,11,454,",
      "level": "3",
      "name": "里奥加耶戈斯",
      "name_en": "Rio Gallegos",
      "name_pinyin": "liaojiayegesi",
      "code": "RGL",
      "childrens": []
    }, {
      "id": "455",
      "pid": "11",
      "path": ",5,11,455,",
      "level": "3",
      "name": "里奥夸尔托",
      "name_en": "Rio Cuarto",
      "name_pinyin": "liaokuaertuo",
      "code": "RCU",
      "childrens": []
    }, {
      "id": "456",
      "pid": "11",
      "path": ",5,11,456,",
      "level": "3",
      "name": "里瓦达维亚海军准将城",
      "name_en": "Comodoro Rivadavia",
      "name_pinyin": "liwadaweiyahaijunzhunjiangcheng",
      "code": "CRD",
      "childrens": []
    }, {
      "id": "457",
      "pid": "11",
      "path": ",5,11,457,",
      "level": "3",
      "name": "罗萨里奥",
      "name_en": "Rosario",
      "name_pinyin": "luosaliao",
      "code": "ROS",
      "childrens": []
    }, {
      "id": "458",
      "pid": "11",
      "path": ",5,11,458,",
      "level": "3",
      "name": "罗森",
      "name_en": "Rawson",
      "name_pinyin": "luosen",
      "code": "RWO",
      "childrens": []
    }, {
      "id": "459",
      "pid": "11",
      "path": ",5,11,459,",
      "level": "3",
      "name": "马德普拉塔",
      "name_en": "Mar del Plata",
      "name_pinyin": "madepulata",
      "code": "MDQ",
      "childrens": []
    }, {
      "id": "460",
      "pid": "11",
      "path": ",5,11,460,",
      "level": "3",
      "name": "门多萨",
      "name_en": "Mendoza",
      "name_pinyin": "menduosa",
      "code": "MDZ",
      "childrens": []
    }, {
      "id": "461",
      "pid": "11",
      "path": ",5,11,461,",
      "level": "3",
      "name": "内乌肯",
      "name_en": "Neuquen",
      "name_pinyin": "neiwuken",
      "code": "NQN",
      "childrens": []
    }, {
      "id": "462",
      "pid": "11",
      "path": ",5,11,462,",
      "level": "3",
      "name": "萨尔塔",
      "name_en": "Salta",
      "name_pinyin": "saerta",
      "code": "SLA",
      "childrens": []
    }, {
      "id": "463",
      "pid": "11",
      "path": ",5,11,463,",
      "level": "3",
      "name": "圣地亚哥-德尔埃斯特罗",
      "name_en": "Santiago del Estero",
      "name_pinyin": "shengdiyagedeeraisiteluo",
      "code": "SDE",
      "childrens": []
    }, {
      "id": "464",
      "pid": "11",
      "path": ",5,11,464,",
      "level": "3",
      "name": "圣菲",
      "name_en": "Santa Fe",
      "name_pinyin": "shengfei",
      "code": "SFN",
      "childrens": []
    }, {
      "id": "465",
      "pid": "11",
      "path": ",5,11,465,",
      "level": "3",
      "name": "圣胡安",
      "name_en": "San Juan",
      "name_pinyin": "shenghuan",
      "code": "UAQ",
      "childrens": []
    }, {
      "id": "466",
      "pid": "11",
      "path": ",5,11,466,",
      "level": "3",
      "name": "圣拉斐尔",
      "name_en": "San Rafael",
      "name_pinyin": "shenglaer",
      "code": "AFA",
      "childrens": []
    }, {
      "id": "467",
      "pid": "11",
      "path": ",5,11,467,",
      "level": "3",
      "name": "圣路易斯",
      "name_en": "San Luis",
      "name_pinyin": "shengluyisi",
      "code": "LUQ",
      "childrens": []
    }, {
      "id": "468",
      "pid": "11",
      "path": ",5,11,468,",
      "level": "3",
      "name": "圣罗莎",
      "name_en": "Santa Rosa",
      "name_pinyin": "shengluosha",
      "code": "RSA",
      "childrens": []
    }, {
      "id": "469",
      "pid": "11",
      "path": ",5,11,469,",
      "level": "3",
      "name": "圣米格尔-德图库曼",
      "name_en": "San Miguel de Tucuman",
      "name_pinyin": "shengmigeerdetukuman",
      "code": "SMC",
      "childrens": []
    }, {
      "id": "470",
      "pid": "11",
      "path": ",5,11,470,",
      "level": "3",
      "name": "圣尼古拉斯",
      "name_en": "San Nicolas",
      "name_pinyin": "shengnigulasi",
      "code": "SNS",
      "childrens": []
    }, {
      "id": "471",
      "pid": "11",
      "path": ",5,11,471,",
      "level": "3",
      "name": "特雷利乌",
      "name_en": "Trelew",
      "name_pinyin": "teleiliwu",
      "code": "REL",
      "childrens": []
    }, {
      "id": "472",
      "pid": "11",
      "path": ",5,11,472,",
      "level": "3",
      "name": "乌斯怀亚",
      "name_en": "Ushuaia",
      "name_pinyin": "wusihuaiya",
      "code": "USH",
      "childrens": []
    }]
  }, {
    "id": "13",
    "pid": "5",
    "path": ",5,13,",
    "level": "2",
    "name": "阿鲁巴",
    "name_en": "Aruba",
    "name_pinyin": "aluba",
    "code": "ABW",
    "childrens": []
  }, {
    "id": "23",
    "pid": "5",
    "path": ",5,23,",
    "level": "2",
    "name": "安圭拉",
    "name_en": "Anguilla",
    "name_pinyin": "anguila",
    "code": "AIA",
    "childrens": []
  }, {
    "id": "28",
    "pid": "5",
    "path": ",5,28,",
    "level": "2",
    "name": "巴巴多斯岛",
    "name_en": "Barbados",
    "name_pinyin": "babaduosidao",
    "code": "BRB",
    "childrens": []
  }, {
    "id": "32",
    "pid": "5",
    "path": ",5,32,",
    "level": "2",
    "name": "巴拉圭",
    "name_en": "Paraguay",
    "name_pinyin": "balagui",
    "code": "PRY",
    "childrens": [{
      "id": "618",
      "pid": "32",
      "path": ",5,32,618,",
      "level": "3",
      "name": "阿曼拜",
      "name_en": "Amambay",
      "name_pinyin": "amanbai",
      "code": "AM",
      "childrens": []
    }, {
      "id": "619",
      "pid": "32",
      "path": ",5,32,619,",
      "level": "3",
      "name": "阿耶斯总统省",
      "name_en": "Presidente Hayes",
      "name_pinyin": "ayesizongtongsheng",
      "code": "PH",
      "childrens": []
    }, {
      "id": "620",
      "pid": "32",
      "path": ",5,32,620,",
      "level": "3",
      "name": "巴拉瓜里",
      "name_en": "Paraguari",
      "name_pinyin": "balaguali",
      "code": "PA",
      "childrens": []
    }, {
      "id": "621",
      "pid": "32",
      "path": ",5,32,621,",
      "level": "3",
      "name": "博克龙",
      "name_en": "Boqueron",
      "name_pinyin": "bokelong",
      "code": "BO",
      "childrens": []
    }, {
      "id": "622",
      "pid": "32",
      "path": ",5,32,622,",
      "level": "3",
      "name": "瓜伊拉",
      "name_en": "Guaira",
      "name_pinyin": "guayila",
      "code": "GU",
      "childrens": []
    }, {
      "id": "623",
      "pid": "32",
      "path": ",5,32,623,",
      "level": "3",
      "name": "卡瓜苏",
      "name_en": "Caaguazu",
      "name_pinyin": "kaguasu",
      "code": "CG",
      "childrens": []
    }, {
      "id": "624",
      "pid": "32",
      "path": ",5,32,624,",
      "level": "3",
      "name": "卡嫩迪尤",
      "name_en": "Canindeyu",
      "name_pinyin": "kanendiyou",
      "code": "CN",
      "childrens": []
    }, {
      "id": "625",
      "pid": "32",
      "path": ",5,32,625,",
      "level": "3",
      "name": "卡萨帕",
      "name_en": "Caazapa",
      "name_pinyin": "kasapa",
      "code": "CZ",
      "childrens": []
    }, {
      "id": "626",
      "pid": "32",
      "path": ",5,32,626,",
      "level": "3",
      "name": "康塞普西翁",
      "name_en": "Concepcion",
      "name_pinyin": "kangsaipuxiweng",
      "code": "CC",
      "childrens": []
    }, {
      "id": "627",
      "pid": "32",
      "path": ",5,32,627,",
      "level": "3",
      "name": "科迪勒拉",
      "name_en": "Cordillera",
      "name_pinyin": "kedilela",
      "code": "CD",
      "childrens": []
    }, {
      "id": "628",
      "pid": "32",
      "path": ",5,32,628,",
      "level": "3",
      "name": "米西奥内斯",
      "name_en": "Misiones",
      "name_pinyin": "mixiaoneisi",
      "code": "MI",
      "childrens": []
    }, {
      "id": "629",
      "pid": "32",
      "path": ",5,32,629,",
      "level": "3",
      "name": "涅恩布库",
      "name_en": "Neembucu",
      "name_pinyin": "nieenbuku",
      "code": "NE",
      "childrens": []
    }, {
      "id": "630",
      "pid": "32",
      "path": ",5,32,630,",
      "level": "3",
      "name": "上巴拉圭",
      "name_en": "Alto Paraguay",
      "name_pinyin": "shangbalagui",
      "code": "AG",
      "childrens": []
    }, {
      "id": "631",
      "pid": "32",
      "path": ",5,32,631,",
      "level": "3",
      "name": "上巴拉那",
      "name_en": "Alto Parana",
      "name_pinyin": "shangbalana",
      "code": "AN",
      "childrens": []
    }, {
      "id": "632",
      "pid": "32",
      "path": ",5,32,632,",
      "level": "3",
      "name": "圣佩德罗",
      "name_en": "San Pedro",
      "name_pinyin": "shengpeideluo",
      "code": "SP",
      "childrens": []
    }, {
      "id": "633",
      "pid": "32",
      "path": ",5,32,633,",
      "level": "3",
      "name": "亚松森特别区",
      "name_en": "Asuncion",
      "name_pinyin": "yasongsentebiequ",
      "code": "AS",
      "childrens": []
    }, {
      "id": "634",
      "pid": "32",
      "path": ",5,32,634,",
      "level": "3",
      "name": "伊塔普亚",
      "name_en": "Itapua",
      "name_pinyin": "yitapuya",
      "code": "IT",
      "childrens": []
    }, {
      "id": "635",
      "pid": "32",
      "path": ",5,32,635,",
      "level": "3",
      "name": "中央",
      "name_en": "Central",
      "name_pinyin": "zhongyang",
      "code": "CE",
      "childrens": []
    }]
  }, {
    "id": "36",
    "pid": "5",
    "path": ",5,36,",
    "level": "2",
    "name": "巴西",
    "name_en": "Brazil",
    "name_pinyin": "baxi",
    "code": "BRA",
    "childrens": [{
      "id": "647",
      "pid": "36",
      "path": ",5,36,647,",
      "level": "3",
      "name": "阿克里",
      "name_en": "Acre",
      "name_pinyin": "akeli",
      "code": "AC",
      "childrens": []
    }, {
      "id": "648",
      "pid": "36",
      "path": ",5,36,648,",
      "level": "3",
      "name": "阿拉戈斯",
      "name_en": "Alagoas",
      "name_pinyin": "alagesi",
      "code": "AL",
      "childrens": []
    }, {
      "id": "649",
      "pid": "36",
      "path": ",5,36,649,",
      "level": "3",
      "name": "阿马帕",
      "name_en": "Amapa",
      "name_pinyin": "amapa",
      "code": "AP",
      "childrens": []
    }, {
      "id": "650",
      "pid": "36",
      "path": ",5,36,650,",
      "level": "3",
      "name": "巴拉那",
      "name_en": "Parana",
      "name_pinyin": "balana",
      "code": "PR",
      "childrens": []
    }, {
      "id": "651",
      "pid": "36",
      "path": ",5,36,651,",
      "level": "3",
      "name": "巴西利亚",
      "name_en": "Brasilia",
      "name_pinyin": "baxiliya",
      "code": "BSB",
      "childrens": []
    }, {
      "id": "652",
      "pid": "36",
      "path": ",5,36,652,",
      "level": "3",
      "name": "巴伊亚",
      "name_en": "Bahia",
      "name_pinyin": "bayiya",
      "code": "BA",
      "childrens": []
    }, {
      "id": "653",
      "pid": "36",
      "path": ",5,36,653,",
      "level": "3",
      "name": "北里奥格兰德",
      "name_en": "Rio Grande do Norte",
      "name_pinyin": "beiliaogelande",
      "code": "RN",
      "childrens": []
    }, {
      "id": "654",
      "pid": "36",
      "path": ",5,36,654,",
      "level": "3",
      "name": "伯南布哥",
      "name_en": "Pernambuco",
      "name_pinyin": "bonanbuge",
      "code": "PE",
      "childrens": []
    }, {
      "id": "655",
      "pid": "36",
      "path": ",5,36,655,",
      "level": "3",
      "name": "戈亚斯",
      "name_en": "Goias",
      "name_pinyin": "geyasi",
      "code": "GO",
      "childrens": []
    }, {
      "id": "656",
      "pid": "36",
      "path": ",5,36,656,",
      "level": "3",
      "name": "朗多尼亚",
      "name_en": "Rondonia",
      "name_pinyin": "langduoniya",
      "code": "RO",
      "childrens": []
    }, {
      "id": "657",
      "pid": "36",
      "path": ",5,36,657,",
      "level": "3",
      "name": "里约热内卢",
      "name_en": "Rio de Janeiro",
      "name_pinyin": "liyuereneilu",
      "code": "RJ",
      "childrens": []
    }, {
      "id": "658",
      "pid": "36",
      "path": ",5,36,658,",
      "level": "3",
      "name": "罗赖马",
      "name_en": "Roraima",
      "name_pinyin": "luolaima",
      "code": "RR",
      "childrens": []
    }, {
      "id": "659",
      "pid": "36",
      "path": ",5,36,659,",
      "level": "3",
      "name": "马拉尼昂",
      "name_en": "Maranhao",
      "name_pinyin": "malaniang",
      "code": "MA",
      "childrens": []
    }, {
      "id": "660",
      "pid": "36",
      "path": ",5,36,660,",
      "level": "3",
      "name": "马托格罗索",
      "name_en": "Mato Grosso",
      "name_pinyin": "matuogeluosuo",
      "code": "MT",
      "childrens": []
    }, {
      "id": "661",
      "pid": "36",
      "path": ",5,36,661,",
      "level": "3",
      "name": "米纳斯吉拉斯",
      "name_en": "Minas Gerais",
      "name_pinyin": "minasijilasi",
      "code": "MG",
      "childrens": []
    }, {
      "id": "662",
      "pid": "36",
      "path": ",5,36,662,",
      "level": "3",
      "name": "南里奥格兰德",
      "name_en": "Rio Grande do Sul",
      "name_pinyin": "nanliaogelande",
      "code": "RS",
      "childrens": []
    }, {
      "id": "663",
      "pid": "36",
      "path": ",5,36,663,",
      "level": "3",
      "name": "南马托格罗索",
      "name_en": "Mato Grosso do Sul",
      "name_pinyin": "nanmatuogeluosuo",
      "code": "MS",
      "childrens": []
    }, {
      "id": "664",
      "pid": "36",
      "path": ",5,36,664,",
      "level": "3",
      "name": "帕拉",
      "name_en": "Para",
      "name_pinyin": "pala",
      "code": "PA",
      "childrens": []
    }, {
      "id": "665",
      "pid": "36",
      "path": ",5,36,665,",
      "level": "3",
      "name": "帕拉伊巴",
      "name_en": "Paraiba",
      "name_pinyin": "palayiba",
      "code": "PB",
      "childrens": []
    }, {
      "id": "666",
      "pid": "36",
      "path": ",5,36,666,",
      "level": "3",
      "name": "皮奥伊",
      "name_en": "Piaui",
      "name_pinyin": "piaoyi",
      "code": "PI",
      "childrens": []
    }, {
      "id": "667",
      "pid": "36",
      "path": ",5,36,667,",
      "level": "3",
      "name": "塞阿拉",
      "name_en": "Ceara",
      "name_pinyin": "saiala",
      "code": "CE",
      "childrens": []
    }, {
      "id": "668",
      "pid": "36",
      "path": ",5,36,668,",
      "level": "3",
      "name": "塞尔希培",
      "name_en": "Sergipe",
      "name_pinyin": "saierxipei",
      "code": "SE",
      "childrens": []
    }, {
      "id": "669",
      "pid": "36",
      "path": ",5,36,669,",
      "level": "3",
      "name": "圣埃斯皮里图",
      "name_en": "Espirito Santo",
      "name_pinyin": "shengaisipilitu",
      "code": "ES",
      "childrens": []
    }, {
      "id": "670",
      "pid": "36",
      "path": ",5,36,670,",
      "level": "3",
      "name": "圣保罗",
      "name_en": "Sao Paulo",
      "name_pinyin": "shengbaoluo",
      "code": "SP",
      "childrens": []
    }, {
      "id": "671",
      "pid": "36",
      "path": ",5,36,671,",
      "level": "3",
      "name": "圣卡塔琳娜",
      "name_en": "Santa Catarina",
      "name_pinyin": "shengkatalinna",
      "code": "SC",
      "childrens": []
    }, {
      "id": "672",
      "pid": "36",
      "path": ",5,36,672,",
      "level": "3",
      "name": "托坎廷斯",
      "name_en": "Tocantins",
      "name_pinyin": "tuokantingsi",
      "code": "TO",
      "childrens": []
    }, {
      "id": "673",
      "pid": "36",
      "path": ",5,36,673,",
      "level": "3",
      "name": "亚马孙",
      "name_en": "Amazonas",
      "name_pinyin": "yamasun",
      "code": "AM",
      "childrens": []
    }]
  }, {
    "id": "46",
    "pid": "5",
    "path": ",5,46,",
    "level": "2",
    "name": "玻利维亚",
    "name_en": "Bolivia",
    "name_pinyin": "boliweiya",
    "code": "BOL",
    "childrens": [{
      "id": "776",
      "pid": "46",
      "path": ",5,46,776,",
      "level": "3",
      "name": "奥尔托",
      "name_en": "El Alto",
      "name_pinyin": "aoertuo",
      "code": "ALT",
      "childrens": []
    }, {
      "id": "777",
      "pid": "46",
      "path": ",5,46,777,",
      "level": "3",
      "name": "奥鲁罗",
      "name_en": "Oruro",
      "name_pinyin": "aoluluo",
      "code": "ORU",
      "childrens": []
    }, {
      "id": "778",
      "pid": "46",
      "path": ",5,46,778,",
      "level": "3",
      "name": "贝尼",
      "name_en": "El Beni",
      "name_pinyin": "beini",
      "code": "BEN",
      "childrens": []
    }, {
      "id": "779",
      "pid": "46",
      "path": ",5,46,779,",
      "level": "3",
      "name": "波多西",
      "name_en": "Potosi",
      "name_pinyin": "boduoxi",
      "code": "POI",
      "childrens": []
    }, {
      "id": "780",
      "pid": "46",
      "path": ",5,46,780,",
      "level": "3",
      "name": "基拉科洛",
      "name_en": "Quillacollo",
      "name_pinyin": "jilakeluo",
      "code": "QUI",
      "childrens": []
    }, {
      "id": "781",
      "pid": "46",
      "path": ",5,46,781,",
      "level": "3",
      "name": "科恰班巴",
      "name_en": "Cochabamba",
      "name_pinyin": "keqiabanba",
      "code": "CBB",
      "childrens": []
    }, {
      "id": "782",
      "pid": "46",
      "path": ",5,46,782,",
      "level": "3",
      "name": "拉巴斯",
      "name_en": "La Paz",
      "name_pinyin": "labasi",
      "code": "LPB",
      "childrens": []
    }, {
      "id": "783",
      "pid": "46",
      "path": ",5,46,783,",
      "level": "3",
      "name": "潘多",
      "name_en": "Pando",
      "name_pinyin": "panduo",
      "code": "PAN",
      "childrens": []
    }, {
      "id": "784",
      "pid": "46",
      "path": ",5,46,784,",
      "level": "3",
      "name": "丘基萨卡",
      "name_en": "Chuquisaca",
      "name_pinyin": "qiujisaka",
      "code": "CHU",
      "childrens": []
    }, {
      "id": "785",
      "pid": "46",
      "path": ",5,46,785,",
      "level": "3",
      "name": "萨卡巴",
      "name_en": "Sacaba",
      "name_pinyin": "sakaba",
      "code": "SAC",
      "childrens": []
    }, {
      "id": "786",
      "pid": "46",
      "path": ",5,46,786,",
      "level": "3",
      "name": "圣克鲁斯",
      "name_en": "Santa Cruz",
      "name_pinyin": "shengkelusi",
      "code": "SRZ",
      "childrens": []
    }, {
      "id": "787",
      "pid": "46",
      "path": ",5,46,787,",
      "level": "3",
      "name": "塔里哈",
      "name_en": "Tarija",
      "name_pinyin": "taliha",
      "code": "TJA",
      "childrens": []
    }]
  }, {
    "id": "62",
    "pid": "5",
    "path": ",5,62,",
    "level": "2",
    "name": "厄瓜多尔",
    "name_en": "Ecuador",
    "name_pinyin": "eguaduoer",
    "code": "ECU",
    "childrens": [{
      "id": "1038",
      "pid": "62",
      "path": ",5,62,1038,",
      "level": "3",
      "name": "阿苏艾",
      "name_en": "Azuay",
      "name_pinyin": "asuai",
      "code": "A",
      "childrens": []
    }, {
      "id": "1039",
      "pid": "62",
      "path": ",5,62,1039,",
      "level": "3",
      "name": "埃尔奥罗",
      "name_en": "El Oro",
      "name_pinyin": "aieraoluo",
      "code": "O",
      "childrens": []
    }, {
      "id": "1040",
      "pid": "62",
      "path": ",5,62,1040,",
      "level": "3",
      "name": "埃斯梅拉尔达斯",
      "name_en": "Esmeraldas",
      "name_pinyin": "aisimeilaerdasi",
      "code": "E",
      "childrens": []
    }, {
      "id": "1041",
      "pid": "62",
      "path": ",5,62,1041,",
      "level": "3",
      "name": "玻利瓦尔",
      "name_en": "Bolivar",
      "name_pinyin": "boliwaer",
      "code": "B",
      "childrens": []
    }, {
      "id": "1042",
      "pid": "62",
      "path": ",5,62,1042,",
      "level": "3",
      "name": "瓜亚斯",
      "name_en": "Guayas",
      "name_pinyin": "guayasi",
      "code": "G",
      "childrens": []
    }, {
      "id": "1043",
      "pid": "62",
      "path": ",5,62,1043,",
      "level": "3",
      "name": "加拉帕戈斯",
      "name_en": "Galapagos",
      "name_pinyin": "jialapagesi",
      "code": "W",
      "childrens": []
    }, {
      "id": "1044",
      "pid": "62",
      "path": ",5,62,1044,",
      "level": "3",
      "name": "卡尔奇",
      "name_en": "Carchi",
      "name_pinyin": "kaerqi",
      "code": "C",
      "childrens": []
    }, {
      "id": "1045",
      "pid": "62",
      "path": ",5,62,1045,",
      "level": "3",
      "name": "卡尼亚尔",
      "name_en": "Canar",
      "name_pinyin": "kaniyaer",
      "code": "F",
      "childrens": []
    }, {
      "id": "1046",
      "pid": "62",
      "path": ",5,62,1046,",
      "level": "3",
      "name": "科托帕希",
      "name_en": "Cotopaxi",
      "name_pinyin": "ketuopaxi",
      "code": "X",
      "childrens": []
    }, {
      "id": "1047",
      "pid": "62",
      "path": ",5,62,1047,",
      "level": "3",
      "name": "洛哈",
      "name_en": "Loja",
      "name_pinyin": "luoha",
      "code": "L",
      "childrens": []
    }, {
      "id": "1048",
      "pid": "62",
      "path": ",5,62,1048,",
      "level": "3",
      "name": "洛斯里奥斯",
      "name_en": "Los Rios",
      "name_pinyin": "luosiliaosi",
      "code": "R",
      "childrens": []
    }, {
      "id": "1049",
      "pid": "62",
      "path": ",5,62,1049,",
      "level": "3",
      "name": "马纳比",
      "name_en": "Manabi",
      "name_pinyin": "manabi",
      "code": "M",
      "childrens": []
    }, {
      "id": "1050",
      "pid": "62",
      "path": ",5,62,1050,",
      "level": "3",
      "name": "莫罗纳－圣地亚哥",
      "name_en": "Morona-Santiago",
      "name_pinyin": "moluonashengdiyage",
      "code": "S",
      "childrens": []
    }, {
      "id": "1051",
      "pid": "62",
      "path": ",5,62,1051,",
      "level": "3",
      "name": "纳波，奥雷利亚纳",
      "name_en": "Napo, Orellana",
      "name_pinyin": "naboaoleiliyana",
      "code": "D",
      "childrens": []
    }, {
      "id": "1052",
      "pid": "62",
      "path": ",5,62,1052,",
      "level": "3",
      "name": "帕斯塔萨",
      "name_en": "Pastaza",
      "name_pinyin": "pasitasa",
      "code": "Y",
      "childrens": []
    }, {
      "id": "1053",
      "pid": "62",
      "path": ",5,62,1053,",
      "level": "3",
      "name": "皮钦查",
      "name_en": "Pichincha",
      "name_pinyin": "piqincha",
      "code": "P",
      "childrens": []
    }, {
      "id": "1054",
      "pid": "62",
      "path": ",5,62,1054,",
      "level": "3",
      "name": "钦博拉索",
      "name_en": "Chimborazo",
      "name_pinyin": "qinbolasuo",
      "code": "H",
      "childrens": []
    }, {
      "id": "1055",
      "pid": "62",
      "path": ",5,62,1055,",
      "level": "3",
      "name": "萨莫拉－钦奇佩",
      "name_en": "Zamora-Chinchipe",
      "name_pinyin": "samolaqinqipei",
      "code": "Z",
      "childrens": []
    }, {
      "id": "1056",
      "pid": "62",
      "path": ",5,62,1056,",
      "level": "3",
      "name": "苏昆毕奥斯",
      "name_en": "Sucumbios",
      "name_pinyin": "sukunbiaosi",
      "code": "U",
      "childrens": []
    }, {
      "id": "1057",
      "pid": "62",
      "path": ",5,62,1057,",
      "level": "3",
      "name": "通古拉瓦",
      "name_en": "Tungurahua",
      "name_pinyin": "tonggulawa",
      "code": "T",
      "childrens": []
    }, {
      "id": "1058",
      "pid": "62",
      "path": ",5,62,1058,",
      "level": "3",
      "name": "因巴布拉",
      "name_en": "Imbabura",
      "name_pinyin": "yinbabula",
      "code": "I",
      "childrens": []
    }]
  }, {
    "id": "67",
    "pid": "5",
    "path": ",5,67,",
    "level": "2",
    "name": "法属圭亚那",
    "name_en": "French Guiana",
    "name_pinyin": "fashuguiyana",
    "code": "GUF",
    "childrens": []
  }, {
    "id": "78",
    "pid": "5",
    "path": ",5,78,",
    "level": "2",
    "name": "哥伦比亚",
    "name_en": "Colombia",
    "name_pinyin": "gelunbiya",
    "code": "COL",
    "childrens": [{
      "id": "1132",
      "pid": "78",
      "path": ",5,78,1132,",
      "level": "3",
      "name": "阿劳卡",
      "name_en": "Arauca",
      "name_pinyin": "alaoka",
      "code": "ARA",
      "childrens": []
    }, {
      "id": "1133",
      "pid": "78",
      "path": ",5,78,1133,",
      "level": "3",
      "name": "安提奥基亚",
      "name_en": "Antioquia",
      "name_pinyin": "antiaojiya",
      "code": "ANT",
      "childrens": []
    }, {
      "id": "1134",
      "pid": "78",
      "path": ",5,78,1134,",
      "level": "3",
      "name": "北桑坦德",
      "name_en": "Norte de Santander",
      "name_pinyin": "beisangtande",
      "code": "NDS",
      "childrens": []
    }, {
      "id": "1135",
      "pid": "78",
      "path": ",5,78,1135,",
      "level": "3",
      "name": "波哥大首都区",
      "name_en": "Bogota",
      "name_pinyin": "bogedashouduqu",
      "code": "BDC",
      "childrens": []
    }, {
      "id": "1136",
      "pid": "78",
      "path": ",5,78,1136,",
      "level": "3",
      "name": "博利瓦尔",
      "name_en": "Bolivar",
      "name_pinyin": "boliwaer",
      "code": "BOL",
      "childrens": []
    }, {
      "id": "1137",
      "pid": "78",
      "path": ",5,78,1137,",
      "level": "3",
      "name": "博亚卡",
      "name_en": "Boyaca",
      "name_pinyin": "boyaka",
      "code": "BOY",
      "childrens": []
    }, {
      "id": "1138",
      "pid": "78",
      "path": ",5,78,1138,",
      "level": "3",
      "name": "大西洋",
      "name_en": "Atlantico",
      "name_pinyin": "daxiyang",
      "code": "ATL",
      "childrens": []
    }, {
      "id": "1139",
      "pid": "78",
      "path": ",5,78,1139,",
      "level": "3",
      "name": "瓜维亚雷",
      "name_en": "Guaviare",
      "name_pinyin": "guaweiyalei",
      "code": "GVR",
      "childrens": []
    }, {
      "id": "1140",
      "pid": "78",
      "path": ",5,78,1140,",
      "level": "3",
      "name": "瓜希拉",
      "name_en": "La Guajira",
      "name_pinyin": "guaxila",
      "code": "GJR",
      "childrens": []
    }, {
      "id": "1141",
      "pid": "78",
      "path": ",5,78,1141,",
      "level": "3",
      "name": "瓜伊尼亚",
      "name_en": "Guainia",
      "name_pinyin": "guayiniya",
      "code": "GNA",
      "childrens": []
    }, {
      "id": "1142",
      "pid": "78",
      "path": ",5,78,1142,",
      "level": "3",
      "name": "金迪奥",
      "name_en": "Quindio",
      "name_pinyin": "jindiao",
      "code": "QUI",
      "childrens": []
    }, {
      "id": "1143",
      "pid": "78",
      "path": ",5,78,1143,",
      "level": "3",
      "name": "卡尔达斯",
      "name_en": "Caldas",
      "name_pinyin": "kaerdasi",
      "code": "CAL",
      "childrens": []
    }, {
      "id": "1144",
      "pid": "78",
      "path": ",5,78,1144,",
      "level": "3",
      "name": "卡克塔",
      "name_en": "Caqueta",
      "name_pinyin": "kaketa",
      "code": "CAQ",
      "childrens": []
    }, {
      "id": "1145",
      "pid": "78",
      "path": ",5,78,1145,",
      "level": "3",
      "name": "卡萨纳雷",
      "name_en": "Casanare",
      "name_pinyin": "kasanalei",
      "code": "CAS",
      "childrens": []
    }, {
      "id": "1146",
      "pid": "78",
      "path": ",5,78,1146,",
      "level": "3",
      "name": "考卡",
      "name_en": "Cauca",
      "name_pinyin": "kaoka",
      "code": "CAU",
      "childrens": []
    }, {
      "id": "1147",
      "pid": "78",
      "path": ",5,78,1147,",
      "level": "3",
      "name": "考卡山谷",
      "name_en": "Valle del Cauca",
      "name_pinyin": "kaokashangu",
      "code": "VDC",
      "childrens": []
    }, {
      "id": "1148",
      "pid": "78",
      "path": ",5,78,1148,",
      "level": "3",
      "name": "科尔多巴",
      "name_en": "Cordoba",
      "name_pinyin": "keerduoba",
      "code": "COR",
      "childrens": []
    }, {
      "id": "1149",
      "pid": "78",
      "path": ",5,78,1149,",
      "level": "3",
      "name": "昆迪纳马卡",
      "name_en": "Cundinamarca",
      "name_pinyin": "kundinamaka",
      "code": "CAM",
      "childrens": []
    }, {
      "id": "1150",
      "pid": "78",
      "path": ",5,78,1150,",
      "level": "3",
      "name": "利萨拉尔达",
      "name_en": "Risaralda",
      "name_pinyin": "lisalaerda",
      "code": "RIS",
      "childrens": []
    }, {
      "id": "1151",
      "pid": "78",
      "path": ",5,78,1151,",
      "level": "3",
      "name": "马格达雷那",
      "name_en": "Magdalena",
      "name_pinyin": "magedaleina",
      "code": "MAG",
      "childrens": []
    }, {
      "id": "1152",
      "pid": "78",
      "path": ",5,78,1152,",
      "level": "3",
      "name": "梅塔",
      "name_en": "Meta",
      "name_pinyin": "meita",
      "code": "MET",
      "childrens": []
    }, {
      "id": "1153",
      "pid": "78",
      "path": ",5,78,1153,",
      "level": "3",
      "name": "纳里尼奥",
      "name_en": "Narino",
      "name_pinyin": "naliniao",
      "code": "NAR",
      "childrens": []
    }, {
      "id": "1154",
      "pid": "78",
      "path": ",5,78,1154,",
      "level": "3",
      "name": "普图马约",
      "name_en": "Putumayo",
      "name_pinyin": "putumayue",
      "code": "PUT",
      "childrens": []
    }, {
      "id": "1155",
      "pid": "78",
      "path": ",5,78,1155,",
      "level": "3",
      "name": "乔科",
      "name_en": "Choco",
      "name_pinyin": "qiaoke",
      "code": "CHO",
      "childrens": []
    }, {
      "id": "1156",
      "pid": "78",
      "path": ",5,78,1156,",
      "level": "3",
      "name": "塞萨尔",
      "name_en": "Cesar",
      "name_pinyin": "saisaer",
      "code": "CES",
      "childrens": []
    }, {
      "id": "1157",
      "pid": "78",
      "path": ",5,78,1157,",
      "level": "3",
      "name": "桑坦德",
      "name_en": "Santander",
      "name_pinyin": "sangtande",
      "code": "SAN",
      "childrens": []
    }, {
      "id": "1158",
      "pid": "78",
      "path": ",5,78,1158,",
      "level": "3",
      "name": "圣安德烈斯-普罗维登西亚",
      "name_en": "San Andres y Providencia",
      "name_pinyin": "shengandeliesipuluoweidengxiya",
      "code": "SAP",
      "childrens": []
    }, {
      "id": "1159",
      "pid": "78",
      "path": ",5,78,1159,",
      "level": "3",
      "name": "苏克雷",
      "name_en": "Sucre",
      "name_pinyin": "sukelei",
      "code": "SUC",
      "childrens": []
    }, {
      "id": "1160",
      "pid": "78",
      "path": ",5,78,1160,",
      "level": "3",
      "name": "托利马",
      "name_en": "Tolima",
      "name_pinyin": "tuolima",
      "code": "TOL",
      "childrens": []
    }, {
      "id": "1161",
      "pid": "78",
      "path": ",5,78,1161,",
      "level": "3",
      "name": "维查达",
      "name_en": "Vichada",
      "name_pinyin": "weichada",
      "code": "VIC",
      "childrens": []
    }, {
      "id": "1162",
      "pid": "78",
      "path": ",5,78,1162,",
      "level": "3",
      "name": "沃佩斯",
      "name_en": "Vaupes",
      "name_pinyin": "wopeisi",
      "code": "VAU",
      "childrens": []
    }, {
      "id": "1163",
      "pid": "78",
      "path": ",5,78,1163,",
      "level": "3",
      "name": "乌伊拉",
      "name_en": "Huila",
      "name_pinyin": "wuyila",
      "code": "HUI",
      "childrens": []
    }, {
      "id": "1164",
      "pid": "78",
      "path": ",5,78,1164,",
      "level": "3",
      "name": "亚马孙",
      "name_en": "Amazonas",
      "name_pinyin": "yamasun",
      "code": "AMZ",
      "childrens": []
    }]
  }, {
    "id": "86",
    "pid": "5",
    "path": ",5,86,",
    "level": "2",
    "name": "圭亚那",
    "name_en": "Guyana",
    "name_pinyin": "guiyana",
    "code": "GUY",
    "childrens": [{
      "id": "1189",
      "pid": "86",
      "path": ",5,86,1189,",
      "level": "3",
      "name": "埃塞奎博群岛-西德梅拉拉",
      "name_en": "Essequibo Islands-West Demerara",
      "name_pinyin": "aisaikuiboqundaoxidemeilala",
      "code": "EW",
      "childrens": []
    }, {
      "id": "1190",
      "pid": "86",
      "path": ",5,86,1190,",
      "level": "3",
      "name": "巴里马-瓦伊尼",
      "name_en": "Barima-Waini",
      "name_pinyin": "balimawayini",
      "code": "BW",
      "childrens": []
    }, {
      "id": "1191",
      "pid": "86",
      "path": ",5,86,1191,",
      "level": "3",
      "name": "波默伦-苏佩纳姆",
      "name_en": "Pomeroon-Supenaam",
      "name_pinyin": "bomolunsupeinamu",
      "code": "PM",
      "childrens": []
    }, {
      "id": "1192",
      "pid": "86",
      "path": ",5,86,1192,",
      "level": "3",
      "name": "波塔罗-锡帕鲁尼",
      "name_en": "Potaro-Siparuni",
      "name_pinyin": "botaluoxipaluni",
      "code": "PI",
      "childrens": []
    }, {
      "id": "1193",
      "pid": "86",
      "path": ",5,86,1193,",
      "level": "3",
      "name": "德梅拉拉-马海卡",
      "name_en": "Demerara-Mahaica",
      "name_pinyin": "demeilalamahaika",
      "code": "DM",
      "childrens": []
    }, {
      "id": "1194",
      "pid": "86",
      "path": ",5,86,1194,",
      "level": "3",
      "name": "东伯比斯-科兰太因",
      "name_en": "East Berbice-Corentyne",
      "name_pinyin": "dongbobisikelantaiyin",
      "code": "EC",
      "childrens": []
    }, {
      "id": "1195",
      "pid": "86",
      "path": ",5,86,1195,",
      "level": "3",
      "name": "库尤尼-马扎鲁尼",
      "name_en": "Cuyuni-Mazaruni",
      "name_pinyin": "kuyounimazhaluni",
      "code": "CM",
      "childrens": []
    }, {
      "id": "1196",
      "pid": "86",
      "path": ",5,86,1196,",
      "level": "3",
      "name": "马海卡-伯比斯",
      "name_en": "Mahaica-Berbice",
      "name_pinyin": "mahaikabobisi",
      "code": "MB",
      "childrens": []
    }, {
      "id": "1197",
      "pid": "86",
      "path": ",5,86,1197,",
      "level": "3",
      "name": "上德梅拉拉-伯比斯",
      "name_en": "Upper Demerara-Berbice",
      "name_pinyin": "shangdemeilalabobisi",
      "code": "UD",
      "childrens": []
    }, {
      "id": "1198",
      "pid": "86",
      "path": ",5,86,1198,",
      "level": "3",
      "name": "上塔库图-上埃塞奎博",
      "name_en": "Upper Takutu-Upper Essequibo",
      "name_pinyin": "shangtakutushangaisaikuibo",
      "code": "UT",
      "childrens": []
    }]
  }, {
    "id": "91",
    "pid": "5",
    "path": ",5,91,",
    "level": "2",
    "name": "荷属安地列斯",
    "name_en": "Netherlands Antilles",
    "name_pinyin": "heshuandiliesi",
    "code": "ANT",
    "childrens": []
  }, {
    "id": "147",
    "pid": "5",
    "path": ",5,147,",
    "level": "2",
    "name": "秘鲁",
    "name_en": "Peru",
    "name_pinyin": "milu",
    "code": "PER",
    "childrens": [{
      "id": "1759",
      "pid": "147",
      "path": ",5,147,1759,",
      "level": "3",
      "name": "阿雷基帕",
      "name_en": "Arequipa",
      "name_pinyin": "aleijipa",
      "code": "AR",
      "childrens": []
    }, {
      "id": "1760",
      "pid": "147",
      "path": ",5,147,1760,",
      "level": "3",
      "name": "阿普里马克",
      "name_en": "Apurimac",
      "name_pinyin": "apulimake",
      "code": "AP",
      "childrens": []
    }, {
      "id": "1761",
      "pid": "147",
      "path": ",5,147,1761,",
      "level": "3",
      "name": "阿亚库乔",
      "name_en": "Ayacucho",
      "name_pinyin": "ayakuqiao",
      "code": "AY",
      "childrens": []
    }, {
      "id": "1762",
      "pid": "147",
      "path": ",5,147,1762,",
      "level": "3",
      "name": "安卡什",
      "name_en": "Ancash",
      "name_pinyin": "ankashi",
      "code": "AN",
      "childrens": []
    }, {
      "id": "1763",
      "pid": "147",
      "path": ",5,147,1763,",
      "level": "3",
      "name": "胡利亚卡",
      "name_en": "Juliaca",
      "name_pinyin": "huliyaka",
      "code": "JUL",
      "childrens": []
    }, {
      "id": "1764",
      "pid": "147",
      "path": ",5,147,1764,",
      "level": "3",
      "name": "胡宁",
      "name_en": "Junin",
      "name_pinyin": "huning",
      "code": "JU",
      "childrens": []
    }, {
      "id": "1765",
      "pid": "147",
      "path": ",5,147,1765,",
      "level": "3",
      "name": "卡哈马卡",
      "name_en": "Cajamarca",
      "name_pinyin": "kahamaka",
      "code": "CJ",
      "childrens": []
    }, {
      "id": "1766",
      "pid": "147",
      "path": ",5,147,1766,",
      "level": "3",
      "name": "卡亚俄",
      "name_en": "Callao",
      "name_pinyin": "kayae",
      "code": "CL",
      "childrens": []
    }, {
      "id": "1767",
      "pid": "147",
      "path": ",5,147,1767,",
      "level": "3",
      "name": "库斯科",
      "name_en": "Cusco",
      "name_pinyin": "kusike",
      "code": "CU",
      "childrens": []
    }, {
      "id": "1768",
      "pid": "147",
      "path": ",5,147,1768,",
      "level": "3",
      "name": "拉利伯塔德",
      "name_en": "La Libertad",
      "name_pinyin": "lalibotade",
      "code": "LD",
      "childrens": []
    }, {
      "id": "1769",
      "pid": "147",
      "path": ",5,147,1769,",
      "level": "3",
      "name": "兰巴耶克",
      "name_en": "Lambayeque",
      "name_pinyin": "lanbayeke",
      "code": "LY",
      "childrens": []
    }, {
      "id": "1770",
      "pid": "147",
      "path": ",5,147,1770,",
      "level": "3",
      "name": "利马",
      "name_en": "Lima",
      "name_pinyin": "lima",
      "code": "LI",
      "childrens": []
    }, {
      "id": "1771",
      "pid": "147",
      "path": ",5,147,1771,",
      "level": "3",
      "name": "洛雷托",
      "name_en": "Loreto",
      "name_pinyin": "luoleituo",
      "code": "LO",
      "childrens": []
    }, {
      "id": "1772",
      "pid": "147",
      "path": ",5,147,1772,",
      "level": "3",
      "name": "马德雷德迪奥斯",
      "name_en": "Madre de Dios",
      "name_pinyin": "madeleidediaosi",
      "code": "MD",
      "childrens": []
    }, {
      "id": "1773",
      "pid": "147",
      "path": ",5,147,1773,",
      "level": "3",
      "name": "莫克瓜",
      "name_en": "Moquegua",
      "name_pinyin": "mokegua",
      "code": "MO",
      "childrens": []
    }, {
      "id": "1774",
      "pid": "147",
      "path": ",5,147,1774,",
      "level": "3",
      "name": "帕斯科",
      "name_en": "Pasco",
      "name_pinyin": "pasike",
      "code": "PA",
      "childrens": []
    }, {
      "id": "1775",
      "pid": "147",
      "path": ",5,147,1775,",
      "level": "3",
      "name": "皮乌拉",
      "name_en": "Piura",
      "name_pinyin": "piwula",
      "code": "PI",
      "childrens": []
    }, {
      "id": "1776",
      "pid": "147",
      "path": ",5,147,1776,",
      "level": "3",
      "name": "普诺",
      "name_en": "Puno",
      "name_pinyin": "punuo",
      "code": "PU",
      "childrens": []
    }, {
      "id": "1777",
      "pid": "147",
      "path": ",5,147,1777,",
      "level": "3",
      "name": "钦博特",
      "name_en": "Chimbote",
      "name_pinyin": "qinbote",
      "code": "CHM",
      "childrens": []
    }, {
      "id": "1778",
      "pid": "147",
      "path": ",5,147,1778,",
      "level": "3",
      "name": "钦查阿尔塔",
      "name_en": "Chincha Alta",
      "name_pinyin": "qinchaaerta",
      "code": "CHI",
      "childrens": []
    }, {
      "id": "1779",
      "pid": "147",
      "path": ",5,147,1779,",
      "level": "3",
      "name": "圣马丁",
      "name_en": "San Martin",
      "name_pinyin": "shengmading",
      "code": "SM",
      "childrens": []
    }, {
      "id": "1780",
      "pid": "147",
      "path": ",5,147,1780,",
      "level": "3",
      "name": "苏拉纳",
      "name_en": "Sullana",
      "name_pinyin": "sulana",
      "code": "SUL",
      "childrens": []
    }, {
      "id": "1781",
      "pid": "147",
      "path": ",5,147,1781,",
      "level": "3",
      "name": "塔克纳",
      "name_en": "Tacna",
      "name_pinyin": "takena",
      "code": "TA",
      "childrens": []
    }, {
      "id": "1782",
      "pid": "147",
      "path": ",5,147,1782,",
      "level": "3",
      "name": "通贝斯",
      "name_en": "Tumbes",
      "name_pinyin": "tongbeisi",
      "code": "TU",
      "childrens": []
    }, {
      "id": "1783",
      "pid": "147",
      "path": ",5,147,1783,",
      "level": "3",
      "name": "瓦努科",
      "name_en": "Huanuco",
      "name_pinyin": "wanuke",
      "code": "HO",
      "childrens": []
    }, {
      "id": "1784",
      "pid": "147",
      "path": ",5,147,1784,",
      "level": "3",
      "name": "万卡维利卡",
      "name_en": "Huancavelica",
      "name_pinyin": "wankaweilika",
      "code": "HV",
      "childrens": []
    }, {
      "id": "1785",
      "pid": "147",
      "path": ",5,147,1785,",
      "level": "3",
      "name": "乌卡亚利",
      "name_en": "Ucayali",
      "name_pinyin": "wukayali",
      "code": "UC",
      "childrens": []
    }, {
      "id": "1786",
      "pid": "147",
      "path": ",5,147,1786,",
      "level": "3",
      "name": "亚马孙",
      "name_en": "Amazonas",
      "name_pinyin": "yamasun",
      "code": "AM",
      "childrens": []
    }, {
      "id": "1787",
      "pid": "147",
      "path": ",5,147,1787,",
      "level": "3",
      "name": "伊卡",
      "name_en": "Ica",
      "name_pinyin": "yika",
      "code": "IC",
      "childrens": []
    }]
  }, {
    "id": "195",
    "pid": "5",
    "path": ",5,195,",
    "level": "2",
    "name": "苏里南",
    "name_en": "Suriname",
    "name_pinyin": "sulinan",
    "code": "SUR",
    "childrens": [{
      "id": "2249",
      "pid": "195",
      "path": ",5,195,2249,",
      "level": "3",
      "name": "布罗科蓬多",
      "name_en": "Brokopondo",
      "name_pinyin": "buluokepengduo",
      "code": "BR",
      "childrens": []
    }, {
      "id": "2250",
      "pid": "195",
      "path": ",5,195,2250,",
      "level": "3",
      "name": "科罗尼",
      "name_en": "Coronie",
      "name_pinyin": "keluoni",
      "code": "CR",
      "childrens": []
    }, {
      "id": "2251",
      "pid": "195",
      "path": ",5,195,2251,",
      "level": "3",
      "name": "科默韦讷",
      "name_en": "Commewijne",
      "name_pinyin": "kemowei",
      "code": "CM",
      "childrens": []
    }, {
      "id": "2252",
      "pid": "195",
      "path": ",5,195,2252,",
      "level": "3",
      "name": "马罗韦讷",
      "name_en": "Marowijne",
      "name_pinyin": "maluowei",
      "code": "MA",
      "childrens": []
    }, {
      "id": "2253",
      "pid": "195",
      "path": ",5,195,2253,",
      "level": "3",
      "name": "尼克里",
      "name_en": "Nickerie",
      "name_pinyin": "nikeli",
      "code": "NI",
      "childrens": []
    }, {
      "id": "2254",
      "pid": "195",
      "path": ",5,195,2254,",
      "level": "3",
      "name": "帕拉",
      "name_en": "Para",
      "name_pinyin": "pala",
      "code": "PA",
      "childrens": []
    }, {
      "id": "2255",
      "pid": "195",
      "path": ",5,195,2255,",
      "level": "3",
      "name": "帕拉马里博",
      "name_en": "Paramaribo",
      "name_pinyin": "palamalibo",
      "code": "PM",
      "childrens": []
    }, {
      "id": "2256",
      "pid": "195",
      "path": ",5,195,2256,",
      "level": "3",
      "name": "萨拉马卡",
      "name_en": "Saramacca",
      "name_pinyin": "salamaka",
      "code": "SA",
      "childrens": []
    }, {
      "id": "2257",
      "pid": "195",
      "path": ",5,195,2257,",
      "level": "3",
      "name": "瓦尼卡",
      "name_en": "Wanica",
      "name_pinyin": "wanika",
      "code": "WA",
      "childrens": []
    }, {
      "id": "2258",
      "pid": "195",
      "path": ",5,195,2258,",
      "level": "3",
      "name": "西帕里韦尼",
      "name_en": "Sipaliwini",
      "name_pinyin": "xipaliweini",
      "code": "SI",
      "childrens": []
    }]
  }, {
    "id": "203",
    "pid": "5",
    "path": ",5,203,",
    "level": "2",
    "name": "特里斯坦达昆哈",
    "name_en": "Tristan da Cunha",
    "name_pinyin": "telisitandakunha",
    "code": "TAA",
    "childrens": []
  }, {
    "id": "215",
    "pid": "5",
    "path": ",5,215,",
    "level": "2",
    "name": "委内瑞拉",
    "name_en": "Venezuela",
    "name_pinyin": "weineiruila",
    "code": "VEN",
    "childrens": [{
      "id": "2529",
      "pid": "215",
      "path": ",5,215,2529,",
      "level": "3",
      "name": "阿拉瓜",
      "name_en": "Aragua",
      "name_pinyin": "alagua",
      "code": "D",
      "childrens": []
    }, {
      "id": "2530",
      "pid": "215",
      "path": ",5,215,2530,",
      "level": "3",
      "name": "阿马库罗三角洲",
      "name_en": "Delta Amacuro",
      "name_pinyin": "amakuluosanjiaozhou",
      "code": "Y",
      "childrens": []
    }, {
      "id": "2531",
      "pid": "215",
      "path": ",5,215,2531,",
      "level": "3",
      "name": "阿普雷",
      "name_en": "Apure",
      "name_pinyin": "apulei",
      "code": "C",
      "childrens": []
    }, {
      "id": "2532",
      "pid": "215",
      "path": ",5,215,2532,",
      "level": "3",
      "name": "安索阿特吉",
      "name_en": "Anzoategui",
      "name_pinyin": "ansuoateji",
      "code": "B",
      "childrens": []
    }, {
      "id": "2533",
      "pid": "215",
      "path": ",5,215,2533,",
      "level": "3",
      "name": "巴里纳斯",
      "name_en": "Barinas",
      "name_pinyin": "balinasi",
      "code": "E",
      "childrens": []
    }, {
      "id": "2534",
      "pid": "215",
      "path": ",5,215,2534,",
      "level": "3",
      "name": "玻利瓦尔",
      "name_en": "Bolivar",
      "name_pinyin": "boliwaer",
      "code": "F",
      "childrens": []
    }, {
      "id": "2535",
      "pid": "215",
      "path": ",5,215,2535,",
      "level": "3",
      "name": "波图格萨",
      "name_en": "Portuguesa",
      "name_pinyin": "botugesa",
      "code": "P",
      "childrens": []
    }, {
      "id": "2536",
      "pid": "215",
      "path": ",5,215,2536,",
      "level": "3",
      "name": "法尔孔",
      "name_en": "Falcon",
      "name_pinyin": "faerkong",
      "code": "I",
      "childrens": []
    }, {
      "id": "2537",
      "pid": "215",
      "path": ",5,215,2537,",
      "level": "3",
      "name": "瓜里科",
      "name_en": "Guarico",
      "name_pinyin": "gualike",
      "code": "J",
      "childrens": []
    }, {
      "id": "2538",
      "pid": "215",
      "path": ",5,215,2538,",
      "level": "3",
      "name": "加拉加斯",
      "name_en": "Caracas",
      "name_pinyin": "jialajiasi",
      "code": "A",
      "childrens": []
    }, {
      "id": "2539",
      "pid": "215",
      "path": ",5,215,2539,",
      "level": "3",
      "name": "卡拉沃沃",
      "name_en": "Carabobo",
      "name_pinyin": "kalawowo",
      "code": "G",
      "childrens": []
    }, {
      "id": "2540",
      "pid": "215",
      "path": ",5,215,2540,",
      "level": "3",
      "name": "科赫德斯",
      "name_en": "Cojedes",
      "name_pinyin": "kehedesi",
      "code": "H",
      "childrens": []
    }, {
      "id": "2541",
      "pid": "215",
      "path": ",5,215,2541,",
      "level": "3",
      "name": "拉腊",
      "name_en": "Lara",
      "name_pinyin": "lala",
      "code": "K",
      "childrens": []
    }, {
      "id": "2542",
      "pid": "215",
      "path": ",5,215,2542,",
      "level": "3",
      "name": "联邦属地",
      "name_en": "Dependencias Federales",
      "name_pinyin": "lianbangshudi",
      "code": "W",
      "childrens": []
    }, {
      "id": "2543",
      "pid": "215",
      "path": ",5,215,2543,",
      "level": "3",
      "name": "梅里达",
      "name_en": "Merida",
      "name_pinyin": "meilida",
      "code": "L",
      "childrens": []
    }, {
      "id": "2544",
      "pid": "215",
      "path": ",5,215,2544,",
      "level": "3",
      "name": "米兰达",
      "name_en": "Miranda",
      "name_pinyin": "milanda",
      "code": "M",
      "childrens": []
    }, {
      "id": "2545",
      "pid": "215",
      "path": ",5,215,2545,",
      "level": "3",
      "name": "莫纳加斯",
      "name_en": "Monagas",
      "name_pinyin": "monajiasi",
      "code": "N",
      "childrens": []
    }, {
      "id": "2546",
      "pid": "215",
      "path": ",5,215,2546,",
      "level": "3",
      "name": "苏克雷",
      "name_en": "Sucre",
      "name_pinyin": "sukelei",
      "code": "R",
      "childrens": []
    }, {
      "id": "2547",
      "pid": "215",
      "path": ",5,215,2547,",
      "level": "3",
      "name": "苏利亚",
      "name_en": "Zulia",
      "name_pinyin": "suliya",
      "code": "V",
      "childrens": []
    }, {
      "id": "2548",
      "pid": "215",
      "path": ",5,215,2548,",
      "level": "3",
      "name": "塔奇拉",
      "name_en": "Tachira",
      "name_pinyin": "taqila",
      "code": "S",
      "childrens": []
    }, {
      "id": "2549",
      "pid": "215",
      "path": ",5,215,2549,",
      "level": "3",
      "name": "特鲁希略",
      "name_en": "Trujillo",
      "name_pinyin": "teluxilue",
      "code": "T",
      "childrens": []
    }, {
      "id": "2550",
      "pid": "215",
      "path": ",5,215,2550,",
      "level": "3",
      "name": "新埃斯帕塔",
      "name_en": "Estado Nueva Esparta",
      "name_pinyin": "xinaisipata",
      "code": "O",
      "childrens": []
    }, {
      "id": "2551",
      "pid": "215",
      "path": ",5,215,2551,",
      "level": "3",
      "name": "亚拉奎",
      "name_en": "Yaracuy",
      "name_pinyin": "yalakui",
      "code": "U",
      "childrens": []
    }, {
      "id": "2552",
      "pid": "215",
      "path": ",5,215,2552,",
      "level": "3",
      "name": "亚马孙",
      "name_en": "Amazonas",
      "name_pinyin": "yamasun",
      "code": "Z",
      "childrens": []
    }]
  }, {
    "id": "219",
    "pid": "5",
    "path": ",5,219,",
    "level": "2",
    "name": "乌拉圭",
    "name_en": "Uruguay",
    "name_pinyin": "wulagui",
    "code": "URY",
    "childrens": [{
      "id": "2634",
      "pid": "219",
      "path": ",5,219,2634,",
      "level": "3",
      "name": "阿蒂加斯",
      "name_en": "Artigas",
      "name_pinyin": "adijiasi",
      "code": "AR",
      "childrens": []
    }, {
      "id": "2635",
      "pid": "219",
      "path": ",5,219,2635,",
      "level": "3",
      "name": "杜拉斯诺",
      "name_en": "Durazno",
      "name_pinyin": "dulasinuo",
      "code": "DU",
      "childrens": []
    }, {
      "id": "2636",
      "pid": "219",
      "path": ",5,219,2636,",
      "level": "3",
      "name": "佛罗里达",
      "name_en": "Florida",
      "name_pinyin": "foluolida",
      "code": "FA",
      "childrens": []
    }, {
      "id": "2637",
      "pid": "219",
      "path": ",5,219,2637,",
      "level": "3",
      "name": "弗洛雷斯",
      "name_en": "Flores",
      "name_pinyin": "fuluoleisi",
      "code": "FS",
      "childrens": []
    }, {
      "id": "2638",
      "pid": "219",
      "path": ",5,219,2638,",
      "level": "3",
      "name": "卡内洛内斯",
      "name_en": "Canelones",
      "name_pinyin": "kaneiluoneisi",
      "code": "CA",
      "childrens": []
    }, {
      "id": "2639",
      "pid": "219",
      "path": ",5,219,2639,",
      "level": "3",
      "name": "科洛尼亚",
      "name_en": "Colonia",
      "name_pinyin": "keluoniya",
      "code": "CO",
      "childrens": []
    }, {
      "id": "2640",
      "pid": "219",
      "path": ",5,219,2640,",
      "level": "3",
      "name": "拉瓦耶哈",
      "name_en": "Lavalleja",
      "name_pinyin": "lawayeha",
      "code": "LA",
      "childrens": []
    }, {
      "id": "2641",
      "pid": "219",
      "path": ",5,219,2641,",
      "level": "3",
      "name": "里韦拉",
      "name_en": "Rivera",
      "name_pinyin": "liweila",
      "code": "RV",
      "childrens": []
    }, {
      "id": "2642",
      "pid": "219",
      "path": ",5,219,2642,",
      "level": "3",
      "name": "罗恰",
      "name_en": "Rocha",
      "name_pinyin": "luoqia",
      "code": "RO",
      "childrens": []
    }, {
      "id": "2643",
      "pid": "219",
      "path": ",5,219,2643,",
      "level": "3",
      "name": "马尔多纳多",
      "name_en": "Maldonado",
      "name_pinyin": "maerduonaduo",
      "code": "MA",
      "childrens": []
    }, {
      "id": "2644",
      "pid": "219",
      "path": ",5,219,2644,",
      "level": "3",
      "name": "蒙得维的亚",
      "name_en": "Montevideo",
      "name_pinyin": "mengdeweideya",
      "code": "MO",
      "childrens": []
    }, {
      "id": "2645",
      "pid": "219",
      "path": ",5,219,2645,",
      "level": "3",
      "name": "内格罗河",
      "name_en": "Rio Negro",
      "name_pinyin": "neigeluohe",
      "code": "RN",
      "childrens": []
    }, {
      "id": "2646",
      "pid": "219",
      "path": ",5,219,2646,",
      "level": "3",
      "name": "派桑杜",
      "name_en": "Paysandu",
      "name_pinyin": "paisangdu",
      "code": "PA",
      "childrens": []
    }, {
      "id": "2647",
      "pid": "219",
      "path": ",5,219,2647,",
      "level": "3",
      "name": "萨尔托",
      "name_en": "Salto",
      "name_pinyin": "saertuo",
      "code": "SL",
      "childrens": []
    }, {
      "id": "2648",
      "pid": "219",
      "path": ",5,219,2648,",
      "level": "3",
      "name": "塞罗拉尔戈",
      "name_en": "Cerro Largo",
      "name_pinyin": "sailuolaerge",
      "code": "CL",
      "childrens": []
    }, {
      "id": "2649",
      "pid": "219",
      "path": ",5,219,2649,",
      "level": "3",
      "name": "三十三人",
      "name_en": "Treinta y Tres",
      "name_pinyin": "sanshisanren",
      "code": "TT",
      "childrens": []
    }, {
      "id": "2650",
      "pid": "219",
      "path": ",5,219,2650,",
      "level": "3",
      "name": "圣何塞",
      "name_en": "San Jose",
      "name_pinyin": "shenghesai",
      "code": "SJ",
      "childrens": []
    }, {
      "id": "2651",
      "pid": "219",
      "path": ",5,219,2651,",
      "level": "3",
      "name": "索里亚诺",
      "name_en": "Soriano",
      "name_pinyin": "suoliyanuo",
      "code": "SO",
      "childrens": []
    }, {
      "id": "2652",
      "pid": "219",
      "path": ",5,219,2652,",
      "level": "3",
      "name": "塔夸伦博",
      "name_en": "Tacuarembo",
      "name_pinyin": "takualunbo",
      "code": "TAW",
      "childrens": []
    }]
  }, {
    "id": "245",
    "pid": "5",
    "path": ",5,245,",
    "level": "2",
    "name": "智利",
    "name_en": "Chile",
    "name_pinyin": "zhili",
    "code": "CHL",
    "childrens": [{
      "id": "2990",
      "pid": "245",
      "path": ",5,245,2990,",
      "level": "3",
      "name": "阿劳卡尼亚大区",
      "name_en": "Region de la Araucania",
      "name_pinyin": "alaokaniyadaqu",
      "code": "AR",
      "childrens": []
    }, {
      "id": "2991",
      "pid": "245",
      "path": ",5,245,2991,",
      "level": "3",
      "name": "阿塔卡马大区",
      "name_en": "Region de Atacama",
      "name_pinyin": "atakamadaqu",
      "code": "AT",
      "childrens": []
    }, {
      "id": "2992",
      "pid": "245",
      "path": ",5,245,2992,",
      "level": "3",
      "name": "安托法加斯塔大区",
      "name_en": "Region de Antofagasta",
      "name_pinyin": "antuofajiasitadaqu",
      "code": "AN",
      "childrens": []
    }, {
      "id": "2993",
      "pid": "245",
      "path": ",5,245,2993,",
      "level": "3",
      "name": "比奥比奥大区",
      "name_en": "Region del Biobio",
      "name_pinyin": "biaobiaodaqu",
      "code": "BI",
      "childrens": []
    }, {
      "id": "2994",
      "pid": "245",
      "path": ",5,245,2994,",
      "level": "3",
      "name": "复活节岛",
      "name_en": "Libertador",
      "name_pinyin": "fuhuojiedao",
      "code": "LI",
      "childrens": []
    }, {
      "id": "2995",
      "pid": "245",
      "path": ",5,245,2995,",
      "level": "3",
      "name": "湖大区",
      "name_en": "Region de los Lagos",
      "name_pinyin": "hudaqu",
      "code": "LL",
      "childrens": []
    }, {
      "id": "2996",
      "pid": "245",
      "path": ",5,245,2996,",
      "level": "3",
      "name": "科金博大区",
      "name_en": "Region de Coquimbo",
      "name_pinyin": "kejinbodaqu",
      "code": "CO",
      "childrens": []
    }, {
      "id": "2997",
      "pid": "245",
      "path": ",5,245,2997,",
      "level": "3",
      "name": "马乌莱大区",
      "name_en": "Region del Maule",
      "name_pinyin": "mawulaidaqu",
      "code": "ML",
      "childrens": []
    }, {
      "id": "2998",
      "pid": "245",
      "path": ",5,245,2998,",
      "level": "3",
      "name": "麦哲伦-智利南极大区",
      "name_en": "Magallanes y Antartica Chilena",
      "name_pinyin": "maizhelunzhilinanjidaqu",
      "code": "MA",
      "childrens": []
    }, {
      "id": "2999",
      "pid": "245",
      "path": ",5,245,2999,",
      "level": "3",
      "name": "圣地亚哥",
      "name_en": "Metropolitana de Santiago",
      "name_pinyin": "shengdiyage",
      "code": "RM",
      "childrens": []
    }, {
      "id": "3000",
      "pid": "245",
      "path": ",5,245,3000,",
      "level": "3",
      "name": "塔拉帕卡大区",
      "name_en": "Region de Tarapaca",
      "name_pinyin": "talapakadaqu",
      "code": "TA",
      "childrens": []
    }, {
      "id": "3001",
      "pid": "245",
      "path": ",5,245,3001,",
      "level": "3",
      "name": "瓦尔帕莱索大区",
      "name_en": "Region de Valparaiso",
      "name_pinyin": "waerpalaisuodaqu",
      "code": "VS",
      "childrens": []
    }, {
      "id": "3002",
      "pid": "245",
      "path": ",5,245,3002,",
      "level": "3",
      "name": "伊瓦涅斯将军的艾森大区",
      "name_en": "Region de Alsen del General Carlos Ibanez del",
      "name_pinyin": "yiwaniesijiangjundeaisendaqu",
      "code": "AI",
      "childrens": []
    }]
  }]
}, {
  "id": "6",
  "pid": "0",
  "path": ",6,",
  "level": "1",
  "name": "大洋洲",
  "name_en": "Oceania",
  "name_pinyin": "dayangzhou",
  "code": null,
  "childrens": [{
    "id": "25",
    "pid": "6",
    "path": ",6,25,",
    "level": "2",
    "name": "澳大利亚",
    "name_en": "Australia",
    "name_pinyin": "aodaliya",
    "code": "AUS",
    "childrens": [{
      "id": "281",
      "pid": "25",
      "path": ",6,25,281,",
      "level": "3",
      "name": "北部地区",
      "name_en": "Northern Territory",
      "name_pinyin": "beibudiqu",
      "code": "NT",
      "childrens": [{
        "id": "3519",
        "pid": "281",
        "path": ",6,25,281,3519,",
        "level": "4",
        "name": "北帕默斯顿",
        "name_en": "Palmerston",
        "name_pinyin": "beipamosidun",
        "code": "PAL",
        "childrens": []
      }, {
        "id": "3520",
        "pid": "281",
        "path": ",6,25,281,3520,",
        "level": "4",
        "name": "达尔文",
        "name_en": "Darwin",
        "name_pinyin": "daerwen",
        "code": "DRW",
        "childrens": []
      }]
    }, {
      "id": "282",
      "pid": "25",
      "path": ",6,25,282,",
      "level": "3",
      "name": "堪培拉",
      "name_en": "Canberra",
      "name_pinyin": "kanpeila",
      "code": "ACT",
      "childrens": [{
        "id": "3521",
        "pid": "282",
        "path": ",6,25,282,3521,",
        "level": "4",
        "name": "堪培拉",
        "name_en": "Canberra",
        "name_pinyin": "kanpeila",
        "code": "CBR",
        "childrens": []
      }]
    }, {
      "id": "283",
      "pid": "25",
      "path": ",6,25,283,",
      "level": "3",
      "name": "昆士兰",
      "name_en": "Queensland",
      "name_pinyin": "kunshilan",
      "code": "QLD",
      "childrens": [{
        "id": "3522",
        "pid": "283",
        "path": ",6,25,283,3522,",
        "level": "4",
        "name": "布里斯班",
        "name_en": "Brisbane",
        "name_pinyin": "bulisiban",
        "code": "BNE",
        "childrens": []
      }, {
        "id": "3523",
        "pid": "283",
        "path": ",6,25,283,3523,",
        "level": "4",
        "name": "黄金海岸",
        "name_en": "Gold Coast",
        "name_pinyin": "huangjinhaian",
        "code": "OOL",
        "childrens": []
      }, {
        "id": "3524",
        "pid": "283",
        "path": ",6,25,283,3524,",
        "level": "4",
        "name": "凯恩斯",
        "name_en": "Cairns",
        "name_pinyin": "kaiensi",
        "code": "CNS",
        "childrens": []
      }, {
        "id": "3525",
        "pid": "283",
        "path": ",6,25,283,3525,",
        "level": "4",
        "name": "日光海岸",
        "name_en": "Caloundra",
        "name_pinyin": "riguanghaian",
        "code": "CUD",
        "childrens": []
      }, {
        "id": "3526",
        "pid": "283",
        "path": ",6,25,283,3526,",
        "level": "4",
        "name": "汤斯维尔",
        "name_en": "Townsville",
        "name_pinyin": "tangsiweier",
        "code": "TSV",
        "childrens": []
      }, {
        "id": "3527",
        "pid": "283",
        "path": ",6,25,283,3527,",
        "level": "4",
        "name": "图文巴",
        "name_en": "Toowoomba",
        "name_pinyin": "tuwenba",
        "code": "TWB",
        "childrens": []
      }]
    }, {
      "id": "284",
      "pid": "25",
      "path": ",6,25,284,",
      "level": "3",
      "name": "南澳大利亚",
      "name_en": "South Australia",
      "name_pinyin": "nanaodaliya",
      "code": "SA",
      "childrens": [{
        "id": "3528",
        "pid": "284",
        "path": ",6,25,284,3528,",
        "level": "4",
        "name": "阿德莱德",
        "name_en": "Adelaide",
        "name_pinyin": "adelaide",
        "code": "ADL",
        "childrens": []
      }, {
        "id": "3529",
        "pid": "284",
        "path": ",6,25,284,3529,",
        "level": "4",
        "name": "奥古斯塔港",
        "name_en": "Port Augusta",
        "name_pinyin": "aogusitagang",
        "code": "PUG",
        "childrens": []
      }, {
        "id": "3530",
        "pid": "284",
        "path": ",6,25,284,3530,",
        "level": "4",
        "name": "甘比亚山",
        "name_en": "Mount Gambier",
        "name_pinyin": "ganbiyashan",
        "code": "MGB",
        "childrens": []
      }, {
        "id": "3531",
        "pid": "284",
        "path": ",6,25,284,3531,",
        "level": "4",
        "name": "怀阿拉",
        "name_en": "Whyalla",
        "name_pinyin": "huaiala",
        "code": "WAY",
        "childrens": []
      }, {
        "id": "3532",
        "pid": "284",
        "path": ",6,25,284,3532,",
        "level": "4",
        "name": "林肯港",
        "name_en": "Port Lincoln",
        "name_pinyin": "linkengang",
        "code": "PLO",
        "childrens": []
      }, {
        "id": "3533",
        "pid": "284",
        "path": ",6,25,284,3533,",
        "level": "4",
        "name": "默里布里奇",
        "name_en": "Murray Bridge",
        "name_pinyin": "molibuliqi",
        "code": "MYB",
        "childrens": []
      }, {
        "id": "3534",
        "pid": "284",
        "path": ",6,25,284,3534,",
        "level": "4",
        "name": "皮里港",
        "name_en": "Port Pirie",
        "name_pinyin": "piligang",
        "code": "PPI",
        "childrens": []
      }, {
        "id": "3535",
        "pid": "284",
        "path": ",6,25,284,3535,",
        "level": "4",
        "name": "维克托港",
        "name_en": "Victor Harbor",
        "name_pinyin": "weiketuogang",
        "code": "VHA",
        "childrens": []
      }]
    }, {
      "id": "285",
      "pid": "25",
      "path": ",6,25,285,",
      "level": "3",
      "name": "塔斯马尼亚",
      "name_en": "Tasmania",
      "name_pinyin": "tasimaniya",
      "code": "TAS",
      "childrens": [{
        "id": "3536",
        "pid": "285",
        "path": ",6,25,285,3536,",
        "level": "4",
        "name": "伯尼港",
        "name_en": "Burnie",
        "name_pinyin": "bonigang",
        "code": "BWT",
        "childrens": []
      }, {
        "id": "3537",
        "pid": "285",
        "path": ",6,25,285,3537,",
        "level": "4",
        "name": "德文波特",
        "name_en": "Devonport",
        "name_pinyin": "dewenbote",
        "code": "DPO",
        "childrens": []
      }, {
        "id": "3538",
        "pid": "285",
        "path": ",6,25,285,3538,",
        "level": "4",
        "name": "霍巴特",
        "name_en": "Hobart",
        "name_pinyin": "huobate",
        "code": "HBA",
        "childrens": []
      }, {
        "id": "3539",
        "pid": "285",
        "path": ",6,25,285,3539,",
        "level": "4",
        "name": "朗塞斯顿",
        "name_en": "Launceston",
        "name_pinyin": "langsaisidun",
        "code": "LST",
        "childrens": []
      }]
    }, {
      "id": "286",
      "pid": "25",
      "path": ",6,25,286,",
      "level": "3",
      "name": "维多利亚",
      "name_en": "Victoria",
      "name_pinyin": "weiduoliya",
      "code": "VIC",
      "childrens": [{
        "id": "3540",
        "pid": "286",
        "path": ",6,25,286,3540,",
        "level": "4",
        "name": "吉朗",
        "name_en": "Geelong",
        "name_pinyin": "jilang",
        "code": "GEX",
        "childrens": []
      }, {
        "id": "3541",
        "pid": "286",
        "path": ",6,25,286,3541,",
        "level": "4",
        "name": "墨尔本",
        "name_en": "Melbourne",
        "name_pinyin": "moerben",
        "code": "MEL",
        "childrens": []
      }]
    }, {
      "id": "287",
      "pid": "25",
      "path": ",6,25,287,",
      "level": "3",
      "name": "西澳大利亚",
      "name_en": "Western Australia",
      "name_pinyin": "xiaodaliya",
      "code": "WA",
      "childrens": [{
        "id": "3542",
        "pid": "287",
        "path": ",6,25,287,3542,",
        "level": "4",
        "name": "奥尔巴尼",
        "name_en": "Albany",
        "name_pinyin": "aoerbani",
        "code": "ALH",
        "childrens": []
      }, {
        "id": "3543",
        "pid": "287",
        "path": ",6,25,287,3543,",
        "level": "4",
        "name": "班伯里",
        "name_en": "Bunbury",
        "name_pinyin": "banboli",
        "code": "BUY",
        "childrens": []
      }, {
        "id": "3544",
        "pid": "287",
        "path": ",6,25,287,3544,",
        "level": "4",
        "name": "弗里曼特尔港",
        "name_en": "Fremantle",
        "name_pinyin": "fulimanteergang",
        "code": "FRE",
        "childrens": []
      }, {
        "id": "3545",
        "pid": "287",
        "path": ",6,25,287,3545,",
        "level": "4",
        "name": "杰拉尔顿",
        "name_en": "Geraldton",
        "name_pinyin": "jielaerdun",
        "code": "GET",
        "childrens": []
      }, {
        "id": "3546",
        "pid": "287",
        "path": ",6,25,287,3546,",
        "level": "4",
        "name": "卡尔古利",
        "name_en": "Kalgoorlie",
        "name_pinyin": "kaerguli",
        "code": "KGI",
        "childrens": []
      }, {
        "id": "3547",
        "pid": "287",
        "path": ",6,25,287,3547,",
        "level": "4",
        "name": "曼哲拉",
        "name_en": "Mandurah",
        "name_pinyin": "manzhela",
        "code": "MDU",
        "childrens": []
      }, {
        "id": "3548",
        "pid": "287",
        "path": ",6,25,287,3548,",
        "level": "4",
        "name": "珀斯",
        "name_en": "Perth",
        "name_pinyin": "si",
        "code": "PER",
        "childrens": []
      }]
    }, {
      "id": "288",
      "pid": "25",
      "path": ",6,25,288,",
      "level": "3",
      "name": "新南威尔士",
      "name_en": "New South Wales",
      "name_pinyin": "xinnanweiershi",
      "code": "NSW",
      "childrens": [{
        "id": "3549",
        "pid": "288",
        "path": ",6,25,288,3549,",
        "level": "4",
        "name": "纽卡斯尔",
        "name_en": "Newcastle",
        "name_pinyin": "niukasier",
        "code": "NTL",
        "childrens": []
      }, {
        "id": "3550",
        "pid": "288",
        "path": ",6,25,288,3550,",
        "level": "4",
        "name": "伍伦贡",
        "name_en": "Wollongong",
        "name_pinyin": "wulungong",
        "code": "WOL",
        "childrens": []
      }, {
        "id": "3551",
        "pid": "288",
        "path": ",6,25,288,3551,",
        "level": "4",
        "name": "悉尼",
        "name_en": "Sydney",
        "name_pinyin": "xini",
        "code": "HBS",
        "childrens": []
      }]
    }]
  }, {
    "id": "29",
    "pid": "6",
    "path": ",6,29,",
    "level": "2",
    "name": "巴布亚新几内亚",
    "name_en": "Papua New Guinea",
    "name_pinyin": "babuyaxinjineiya",
    "code": "PNG",
    "childrens": [{
      "id": "590",
      "pid": "29",
      "path": ",6,29,590,",
      "level": "3",
      "name": "北部",
      "name_en": "Northern",
      "name_pinyin": "beibu",
      "code": "NO",
      "childrens": []
    }, {
      "id": "591",
      "pid": "29",
      "path": ",6,29,591,",
      "level": "3",
      "name": "布干维尔",
      "name_en": "Bougainville",
      "name_pinyin": "buganweier",
      "code": "BV",
      "childrens": []
    }, {
      "id": "592",
      "pid": "29",
      "path": ",6,29,592,",
      "level": "3",
      "name": "东部高地",
      "name_en": "Eastern Highlands",
      "name_pinyin": "dongbugaodi",
      "code": "EH",
      "childrens": []
    }, {
      "id": "593",
      "pid": "29",
      "path": ",6,29,593,",
      "level": "3",
      "name": "东塞皮克",
      "name_en": "East Sepik",
      "name_pinyin": "dongsaipike",
      "code": "ES",
      "childrens": []
    }, {
      "id": "594",
      "pid": "29",
      "path": ",6,29,594,",
      "level": "3",
      "name": "东新不列颠",
      "name_en": "East New Britain",
      "name_pinyin": "dongxinbuliedian",
      "code": "EB",
      "childrens": []
    }, {
      "id": "595",
      "pid": "29",
      "path": ",6,29,595,",
      "level": "3",
      "name": "恩加",
      "name_en": "Enga",
      "name_pinyin": "enjia",
      "code": "EN",
      "childrens": []
    }, {
      "id": "596",
      "pid": "29",
      "path": ",6,29,596,",
      "level": "3",
      "name": "海湾",
      "name_en": "Gulf",
      "name_pinyin": "haiwan",
      "code": "GU",
      "childrens": []
    }, {
      "id": "597",
      "pid": "29",
      "path": ",6,29,597,",
      "level": "3",
      "name": "马当",
      "name_en": "Madang",
      "name_pinyin": "madang",
      "code": "MD",
      "childrens": []
    }, {
      "id": "598",
      "pid": "29",
      "path": ",6,29,598,",
      "level": "3",
      "name": "马努斯",
      "name_en": "Manus",
      "name_pinyin": "manusi",
      "code": "MN",
      "childrens": []
    }, {
      "id": "599",
      "pid": "29",
      "path": ",6,29,599,",
      "level": "3",
      "name": "米尔恩湾",
      "name_en": "Milne Bay",
      "name_pinyin": "mierenwan",
      "code": "MB",
      "childrens": []
    }, {
      "id": "600",
      "pid": "29",
      "path": ",6,29,600,",
      "level": "3",
      "name": "莫尔兹比港",
      "name_en": "Port Moresby",
      "name_pinyin": "moerzibigang",
      "code": "NC",
      "childrens": []
    }, {
      "id": "601",
      "pid": "29",
      "path": ",6,29,601,",
      "level": "3",
      "name": "莫罗贝",
      "name_en": "Morobe",
      "name_pinyin": "moluobei",
      "code": "MR",
      "childrens": []
    }, {
      "id": "602",
      "pid": "29",
      "path": ",6,29,602,",
      "level": "3",
      "name": "南部高地",
      "name_en": "Southern Highlands",
      "name_pinyin": "nanbugaodi",
      "code": "SH",
      "childrens": []
    }, {
      "id": "603",
      "pid": "29",
      "path": ",6,29,603,",
      "level": "3",
      "name": "钦布",
      "name_en": "Simbu",
      "name_pinyin": "qinbu",
      "code": "SI",
      "childrens": []
    }, {
      "id": "604",
      "pid": "29",
      "path": ",6,29,604,",
      "level": "3",
      "name": "桑道恩",
      "name_en": "Sandaun",
      "name_pinyin": "sangdaoen",
      "code": "SA",
      "childrens": []
    }, {
      "id": "605",
      "pid": "29",
      "path": ",6,29,605,",
      "level": "3",
      "name": "西部",
      "name_en": "Western",
      "name_pinyin": "xibu",
      "code": "WE",
      "childrens": []
    }, {
      "id": "606",
      "pid": "29",
      "path": ",6,29,606,",
      "level": "3",
      "name": "西部高地",
      "name_en": "Western Highlands",
      "name_pinyin": "xibugaodi",
      "code": "WH",
      "childrens": []
    }, {
      "id": "607",
      "pid": "29",
      "path": ",6,29,607,",
      "level": "3",
      "name": "西新不列颠",
      "name_en": "West New Britain",
      "name_pinyin": "xixinbuliedian",
      "code": "WB",
      "childrens": []
    }, {
      "id": "608",
      "pid": "29",
      "path": ",6,29,608,",
      "level": "3",
      "name": "新爱尔兰",
      "name_en": "New Ireland",
      "name_pinyin": "xinaierlan",
      "code": "NI",
      "childrens": []
    }]
  }, {
    "id": "66",
    "pid": "6",
    "path": ",6,66,",
    "level": "2",
    "name": "法属波利尼西亚",
    "name_en": "Frech Polynesia",
    "name_pinyin": "fashubolinixiya",
    "code": "PYF",
    "childrens": []
  }, {
    "id": "71",
    "pid": "6",
    "path": ",6,71,",
    "level": "2",
    "name": "斐济",
    "name_en": "Fiji Islands",
    "name_pinyin": "ji",
    "code": "FJI",
    "childrens": []
  }, {
    "id": "85",
    "pid": "6",
    "path": ",6,85,",
    "level": "2",
    "name": "关岛",
    "name_en": "Guam",
    "name_pinyin": "guandao",
    "code": "GUM",
    "childrens": []
  }, {
    "id": "92",
    "pid": "6",
    "path": ",6,92,",
    "level": "2",
    "name": "赫德和麦克唐纳群岛",
    "name_en": "Heard Island and McDonald Islands",
    "name_pinyin": "hedehemaiketangnaqundao",
    "code": "HMD",
    "childrens": []
  }, {
    "id": "94",
    "pid": "6",
    "path": ",6,94,",
    "level": "2",
    "name": "基里巴斯",
    "name_en": "Kiribati",
    "name_pinyin": "jilibasi",
    "code": "KIR",
    "childrens": [{
      "id": "1283",
      "pid": "94",
      "path": ",6,94,1283,",
      "level": "3",
      "name": "菲尼克斯群岛",
      "name_en": "Phoenix Islands",
      "name_pinyin": "feinikesiqundao",
      "code": "PHO",
      "childrens": []
    }, {
      "id": "1284",
      "pid": "94",
      "path": ",6,94,1284,",
      "level": "3",
      "name": "吉尔伯特群岛",
      "name_en": "Gilberts Islands",
      "name_pinyin": "jierbotequndao",
      "code": "GIL",
      "childrens": []
    }, {
      "id": "1285",
      "pid": "94",
      "path": ",6,94,1285,",
      "level": "3",
      "name": "莱恩群岛",
      "name_en": "Line Islands",
      "name_pinyin": "laienqundao",
      "code": "LIN",
      "childrens": []
    }]
  }, {
    "id": "108",
    "pid": "6",
    "path": ",6,108,",
    "level": "2",
    "name": "科科斯群岛",
    "name_en": "Cocos(Keeling)Islands",
    "name_pinyin": "kekesiqundao",
    "code": "CCK",
    "childrens": []
  }, {
    "id": "114",
    "pid": "6",
    "path": ",6,114,",
    "level": "2",
    "name": "库克群岛",
    "name_en": "Cook Islands",
    "name_pinyin": "kukequndao",
    "code": "COK",
    "childrens": []
  }, {
    "id": "134",
    "pid": "6",
    "path": ",6,134,",
    "level": "2",
    "name": "马绍尔群岛",
    "name_en": "Marshall Islands",
    "name_pinyin": "mashaoerqundao",
    "code": "MHL",
    "childrens": []
  }, {
    "id": "141",
    "pid": "6",
    "path": ",6,141,",
    "level": "2",
    "name": "美属萨摩亚",
    "name_en": "American Samoa",
    "name_pinyin": "meishusamoya",
    "code": "ASM",
    "childrens": [{
      "id": "1721",
      "pid": "141",
      "path": ",6,141,1721,",
      "level": "3",
      "name": "阿纳",
      "name_en": "Aana",
      "name_pinyin": "ana",
      "code": "AAN",
      "childrens": []
    }, {
      "id": "1722",
      "pid": "141",
      "path": ",6,141,1722,",
      "level": "3",
      "name": "阿图阿",
      "name_en": "Atua",
      "name_pinyin": "atua",
      "code": "ATU",
      "childrens": []
    }, {
      "id": "1723",
      "pid": "141",
      "path": ",6,141,1723,",
      "level": "3",
      "name": "艾加伊勒泰",
      "name_en": "Aigaile Tai",
      "name_pinyin": "aijiayiletai",
      "code": "AIT",
      "childrens": []
    }, {
      "id": "1724",
      "pid": "141",
      "path": ",6,141,1724,",
      "level": "3",
      "name": "法塞莱莱阿加",
      "name_en": "Faasaleleaga",
      "name_pinyin": "fasailailaiajia",
      "code": "FAA",
      "childrens": []
    }, {
      "id": "1725",
      "pid": "141",
      "path": ",6,141,1725,",
      "level": "3",
      "name": "加盖福毛加",
      "name_en": "Gagaifomauga",
      "name_pinyin": "jiagaifumaojia",
      "code": "GFG",
      "childrens": []
    }, {
      "id": "1726",
      "pid": "141",
      "path": ",6,141,1726,",
      "level": "3",
      "name": "加加埃毛加",
      "name_en": "Gagaemauga",
      "name_pinyin": "jiajiaaimaojia",
      "code": "GMG",
      "childrens": []
    }, {
      "id": "1727",
      "pid": "141",
      "path": ",6,141,1727,",
      "level": "3",
      "name": "帕劳利",
      "name_en": "Palauli",
      "name_pinyin": "palaoli",
      "code": "PAL",
      "childrens": []
    }, {
      "id": "1728",
      "pid": "141",
      "path": ",6,141,1728,",
      "level": "3",
      "name": "萨图帕伊泰阿",
      "name_en": "Satupaitea",
      "name_pinyin": "satupayitaia",
      "code": "SAT",
      "childrens": []
    }, {
      "id": "1729",
      "pid": "141",
      "path": ",6,141,1729,",
      "level": "3",
      "name": "萨瓦伊岛",
      "name_en": "Savaii",
      "name_pinyin": "sawayidao",
      "code": "SAV",
      "childrens": []
    }, {
      "id": "1730",
      "pid": "141",
      "path": ",6,141,1730,",
      "level": "3",
      "name": "图阿马萨加",
      "name_en": "Tuamasaga",
      "name_pinyin": "tuamasajia",
      "code": "TUA",
      "childrens": []
    }, {
      "id": "1731",
      "pid": "141",
      "path": ",6,141,1731,",
      "level": "3",
      "name": "瓦奥福诺蒂",
      "name_en": "Vaao Fonoti",
      "name_pinyin": "waaofunuodi",
      "code": "VAF",
      "childrens": []
    }, {
      "id": "1732",
      "pid": "141",
      "path": ",6,141,1732,",
      "level": "3",
      "name": "韦西加诺",
      "name_en": "Vaisigano",
      "name_pinyin": "weixijianuo",
      "code": "VAI",
      "childrens": []
    }, {
      "id": "1733",
      "pid": "141",
      "path": ",6,141,1733,",
      "level": "3",
      "name": "乌波卢岛",
      "name_en": "Upolu",
      "name_pinyin": "wuboludao",
      "code": "UPO",
      "childrens": []
    }]
  }, {
    "id": "146",
    "pid": "6",
    "path": ",6,146,",
    "level": "2",
    "name": "密克罗尼西亚",
    "name_en": "Micronesia",
    "name_pinyin": "mikeluonixiya",
    "code": "FSM",
    "childrens": []
  }, {
    "id": "158",
    "pid": "6",
    "path": ",6,158,",
    "level": "2",
    "name": "瑙鲁",
    "name_en": "Nauru",
    "name_pinyin": "lu",
    "code": "NRU",
    "childrens": []
  }, {
    "id": "163",
    "pid": "6",
    "path": ",6,163,",
    "level": "2",
    "name": "纽埃",
    "name_en": "Niue",
    "name_pinyin": "niuai",
    "code": "NIU",
    "childrens": []
  }, {
    "id": "166",
    "pid": "6",
    "path": ",6,166,",
    "level": "2",
    "name": "帕劳群岛",
    "name_en": "Palau",
    "name_pinyin": "palaoqundao",
    "code": "PLW",
    "childrens": []
  }, {
    "id": "167",
    "pid": "6",
    "path": ",6,167,",
    "level": "2",
    "name": "皮特凯恩",
    "name_en": "Pitcairn Islands",
    "name_pinyin": "pitekaien",
    "code": "PCN",
    "childrens": []
  }, {
    "id": "174",
    "pid": "6",
    "path": ",6,174,",
    "level": "2",
    "name": "萨摩亚",
    "name_en": "Samoa",
    "name_pinyin": "samoya",
    "code": "WSM",
    "childrens": []
  }, {
    "id": "181",
    "pid": "6",
    "path": ",6,181,",
    "level": "2",
    "name": "圣诞岛",
    "name_en": "Christmas Island",
    "name_pinyin": "shengdandao",
    "code": "CXR",
    "childrens": []
  }, {
    "id": "196",
    "pid": "6",
    "path": ",6,196,",
    "level": "2",
    "name": "所罗门群岛",
    "name_en": "Solomon Islands",
    "name_pinyin": "suoluomenqundao",
    "code": "SLB",
    "childrens": [{
      "id": "2259",
      "pid": "196",
      "path": ",6,196,2259,",
      "level": "3",
      "name": "瓜达尔卡纳尔",
      "name_en": "Guadalcanal",
      "name_pinyin": "guadaerkanaer",
      "code": "GC",
      "childrens": []
    }, {
      "id": "2260",
      "pid": "196",
      "path": ",6,196,2260,",
      "level": "3",
      "name": "霍尼亚拉",
      "name_en": "Honiara",
      "name_pinyin": "huoniyala",
      "code": "HO",
      "childrens": []
    }, {
      "id": "2261",
      "pid": "196",
      "path": ",6,196,2261,",
      "level": "3",
      "name": "拉纳尔和贝罗纳",
      "name_en": "Rennell and Bellona",
      "name_pinyin": "lanaerhebeiluona",
      "code": "RB",
      "childrens": []
    }, {
      "id": "2262",
      "pid": "196",
      "path": ",6,196,2262,",
      "level": "3",
      "name": "马基拉",
      "name_en": "Makira",
      "name_pinyin": "majila",
      "code": "MK",
      "childrens": []
    }, {
      "id": "2263",
      "pid": "196",
      "path": ",6,196,2263,",
      "level": "3",
      "name": "马莱塔",
      "name_en": "Malaita",
      "name_pinyin": "malaita",
      "code": "ML",
      "childrens": []
    }, {
      "id": "2264",
      "pid": "196",
      "path": ",6,196,2264,",
      "level": "3",
      "name": "乔伊索",
      "name_en": "Choiseul",
      "name_pinyin": "qiaoyisuo",
      "code": "CH",
      "childrens": []
    }, {
      "id": "2265",
      "pid": "196",
      "path": ",6,196,2265,",
      "level": "3",
      "name": "泰莫图",
      "name_en": "Temotu",
      "name_pinyin": "taimotu",
      "code": "TM",
      "childrens": []
    }, {
      "id": "2266",
      "pid": "196",
      "path": ",6,196,2266,",
      "level": "3",
      "name": "西部",
      "name_en": "Western",
      "name_pinyin": "xibu",
      "code": "WE",
      "childrens": []
    }, {
      "id": "2267",
      "pid": "196",
      "path": ",6,196,2267,",
      "level": "3",
      "name": "伊萨贝尔",
      "name_en": "Isabel",
      "name_pinyin": "yisabeier",
      "code": "IS",
      "childrens": []
    }, {
      "id": "2268",
      "pid": "196",
      "path": ",6,196,2268,",
      "level": "3",
      "name": "中部群岛",
      "name_en": "Central Islands",
      "name_pinyin": "zhongbuqundao",
      "code": "CE",
      "childrens": []
    }]
  }, {
    "id": "201",
    "pid": "6",
    "path": ",6,201,",
    "level": "2",
    "name": "汤加",
    "name_en": "Tonga",
    "name_pinyin": "tangjia",
    "code": "TON",
    "childrens": [{
      "id": "2385",
      "pid": "201",
      "path": ",6,201,2385,",
      "level": "3",
      "name": "埃瓦",
      "name_en": "Eua",
      "name_pinyin": "aiwa",
      "code": "E",
      "childrens": []
    }, {
      "id": "2386",
      "pid": "201",
      "path": ",6,201,2386,",
      "level": "3",
      "name": "哈派",
      "name_en": "Haapai",
      "name_pinyin": "hapai",
      "code": "H",
      "childrens": []
    }, {
      "id": "2387",
      "pid": "201",
      "path": ",6,201,2387,",
      "level": "3",
      "name": "纽阿斯",
      "name_en": "Niuas",
      "name_pinyin": "niuasi",
      "code": "N",
      "childrens": []
    }, {
      "id": "2388",
      "pid": "201",
      "path": ",6,201,2388,",
      "level": "3",
      "name": "汤加塔布",
      "name_en": "Tongatapu",
      "name_pinyin": "tangjiatabu",
      "code": "T",
      "childrens": []
    }, {
      "id": "2389",
      "pid": "201",
      "path": ",6,201,2389,",
      "level": "3",
      "name": "瓦瓦乌",
      "name_en": "Vavau",
      "name_pinyin": "wawawu",
      "code": "V",
      "childrens": []
    }]
  }, {
    "id": "206",
    "pid": "6",
    "path": ",6,206,",
    "level": "2",
    "name": "图瓦卢",
    "name_en": "Tuvalu",
    "name_pinyin": "tuwalu",
    "code": "TUV",
    "childrens": []
  }, {
    "id": "209",
    "pid": "6",
    "path": ",6,209,",
    "level": "2",
    "name": "托克劳",
    "name_en": "Tokelau",
    "name_pinyin": "tuokelao",
    "code": "TKL",
    "childrens": []
  }, {
    "id": "210",
    "pid": "6",
    "path": ",6,210,",
    "level": "2",
    "name": "瓦利斯和福图纳",
    "name_en": "Wallis and Futuna",
    "name_pinyin": "walisihefutuna",
    "code": "WLF",
    "childrens": []
  }, {
    "id": "211",
    "pid": "6",
    "path": ",6,211,",
    "level": "2",
    "name": "瓦努阿图",
    "name_en": "Vanuatu",
    "name_pinyin": "wanuatu",
    "code": "VUT",
    "childrens": [{
      "id": "2499",
      "pid": "211",
      "path": ",6,211,2499,",
      "level": "3",
      "name": "马朗帕",
      "name_en": "Malampa",
      "name_pinyin": "malangpa",
      "code": "MA",
      "childrens": []
    }, {
      "id": "2500",
      "pid": "211",
      "path": ",6,211,2500,",
      "level": "3",
      "name": "彭纳马",
      "name_en": "Penama",
      "name_pinyin": "pengnama",
      "code": "PE",
      "childrens": []
    }, {
      "id": "2501",
      "pid": "211",
      "path": ",6,211,2501,",
      "level": "3",
      "name": "桑马",
      "name_en": "Sanma",
      "name_pinyin": "sangma",
      "code": "SA",
      "childrens": []
    }, {
      "id": "2502",
      "pid": "211",
      "path": ",6,211,2502,",
      "level": "3",
      "name": "塔菲阿",
      "name_en": "Tafea",
      "name_pinyin": "tafeia",
      "code": "TA",
      "childrens": []
    }, {
      "id": "2503",
      "pid": "211",
      "path": ",6,211,2503,",
      "level": "3",
      "name": "托尔巴",
      "name_en": "Torba",
      "name_pinyin": "tuoerba",
      "code": "TO",
      "childrens": []
    }, {
      "id": "2504",
      "pid": "211",
      "path": ",6,211,2504,",
      "level": "3",
      "name": "谢法",
      "name_en": "Shefa",
      "name_pinyin": "xiefa",
      "code": "SH",
      "childrens": []
    }]
  }, {
    "id": "224",
    "pid": "6",
    "path": ",6,224,",
    "level": "2",
    "name": "新喀里多尼亚",
    "name_en": "New Caledonia",
    "name_pinyin": "xinkaliduoniya",
    "code": "NCL",
    "childrens": []
  }, {
    "id": "225",
    "pid": "6",
    "path": ",6,225,",
    "level": "2",
    "name": "新西兰",
    "name_en": "New Zealand",
    "name_pinyin": "xinxilan",
    "code": "NZL",
    "childrens": [{
      "id": "2727",
      "pid": "225",
      "path": ",6,225,2727,",
      "level": "3",
      "name": "奥克兰",
      "name_en": "Auckland",
      "name_pinyin": "aokelan",
      "code": "AUK",
      "childrens": []
    }, {
      "id": "2728",
      "pid": "225",
      "path": ",6,225,2728,",
      "level": "3",
      "name": "北岸",
      "name_en": "North Shore",
      "name_pinyin": "beian",
      "code": "NSH",
      "childrens": []
    }, {
      "id": "2729",
      "pid": "225",
      "path": ",6,225,2729,",
      "level": "3",
      "name": "北帕默斯顿",
      "name_en": "Palmerston North",
      "name_pinyin": "beipamosidun",
      "code": "PMR",
      "childrens": []
    }, {
      "id": "2730",
      "pid": "225",
      "path": ",6,225,2730,",
      "level": "3",
      "name": "北远",
      "name_en": "Far North",
      "name_pinyin": "beiyuan",
      "code": "FNR",
      "childrens": []
    }, {
      "id": "2731",
      "pid": "225",
      "path": ",6,225,2731,",
      "level": "3",
      "name": "布莱尼姆",
      "name_en": "Blenheim",
      "name_pinyin": "bulainimu",
      "code": "BHE",
      "childrens": []
    }, {
      "id": "2732",
      "pid": "225",
      "path": ",6,225,2732,",
      "level": "3",
      "name": "达尼丁",
      "name_en": "Dunedin",
      "name_pinyin": "daniding",
      "code": "DUD",
      "childrens": []
    }, {
      "id": "2733",
      "pid": "225",
      "path": ",6,225,2733,",
      "level": "3",
      "name": "格雷茅斯",
      "name_en": "Greymouth",
      "name_pinyin": "geleimaosi",
      "code": "GMN",
      "childrens": []
    }, {
      "id": "2734",
      "pid": "225",
      "path": ",6,225,2734,",
      "level": "3",
      "name": "哈密尔顿",
      "name_en": "Hamilton",
      "name_pinyin": "hamierdun",
      "code": "HLZ",
      "childrens": []
    }, {
      "id": "2735",
      "pid": "225",
      "path": ",6,225,2735,",
      "level": "3",
      "name": "黑斯廷斯",
      "name_en": "Hastings",
      "name_pinyin": "heisitingsi",
      "code": "HAS",
      "childrens": []
    }, {
      "id": "2736",
      "pid": "225",
      "path": ",6,225,2736,",
      "level": "3",
      "name": "怀塔科拉",
      "name_en": "Waitakere",
      "name_pinyin": "huaitakela",
      "code": "WAE",
      "childrens": []
    }, {
      "id": "2737",
      "pid": "225",
      "path": ",6,225,2737,",
      "level": "3",
      "name": "吉斯伯恩",
      "name_en": "Gisborne",
      "name_pinyin": "jisiboen",
      "code": "GIS",
      "childrens": []
    }, {
      "id": "2738",
      "pid": "225",
      "path": ",6,225,2738,",
      "level": "3",
      "name": "凯帕拉",
      "name_en": "Kaipara",
      "name_pinyin": "kaipala",
      "code": "KAI",
      "childrens": []
    }, {
      "id": "2739",
      "pid": "225",
      "path": ",6,225,2739,",
      "level": "3",
      "name": "克赖斯特彻奇",
      "name_en": "Christchurch",
      "name_pinyin": "kelaisitecheqi",
      "code": "CHC",
      "childrens": []
    }, {
      "id": "2740",
      "pid": "225",
      "path": ",6,225,2740,",
      "level": "3",
      "name": "里士满",
      "name_en": "Richmond",
      "name_pinyin": "lishiman",
      "code": "RMD",
      "childrens": []
    }, {
      "id": "2741",
      "pid": "225",
      "path": ",6,225,2741,",
      "level": "3",
      "name": "马努考",
      "name_en": "Manukau",
      "name_pinyin": "manukao",
      "code": "MNK",
      "childrens": []
    }, {
      "id": "2742",
      "pid": "225",
      "path": ",6,225,2742,",
      "level": "3",
      "name": "纳尔逊",
      "name_en": "Nelson",
      "name_pinyin": "naerxun",
      "code": "NSN",
      "childrens": []
    }, {
      "id": "2743",
      "pid": "225",
      "path": ",6,225,2743,",
      "level": "3",
      "name": "内皮尔",
      "name_en": "Napier",
      "name_pinyin": "neipier",
      "code": "NPE",
      "childrens": []
    }, {
      "id": "2744",
      "pid": "225",
      "path": ",6,225,2744,",
      "level": "3",
      "name": "斯特拉特福德",
      "name_en": "Stratford",
      "name_pinyin": "sitelatefude",
      "code": "STR",
      "childrens": []
    }, {
      "id": "2745",
      "pid": "225",
      "path": ",6,225,2745,",
      "level": "3",
      "name": "陶马鲁努伊",
      "name_en": "Taumarunui",
      "name_pinyin": "taomalunuyi",
      "code": "TAU",
      "childrens": []
    }, {
      "id": "2746",
      "pid": "225",
      "path": ",6,225,2746,",
      "level": "3",
      "name": "瓦卡塔尼",
      "name_en": "Whakatane",
      "name_pinyin": "wakatani",
      "code": "WHK",
      "childrens": []
    }, {
      "id": "2747",
      "pid": "225",
      "path": ",6,225,2747,",
      "level": "3",
      "name": "旺阿雷",
      "name_en": "Whangarei",
      "name_pinyin": "wangalei",
      "code": "WRE",
      "childrens": []
    }, {
      "id": "2748",
      "pid": "225",
      "path": ",6,225,2748,",
      "level": "3",
      "name": "旺格努伊",
      "name_en": "Wanganui",
      "name_pinyin": "wanggenuyi",
      "code": "WAG",
      "childrens": []
    }, {
      "id": "2749",
      "pid": "225",
      "path": ",6,225,2749,",
      "level": "3",
      "name": "新普利茅斯",
      "name_en": "New Plymouth",
      "name_pinyin": "xinpulimaosi",
      "code": "NPL",
      "childrens": []
    }, {
      "id": "2750",
      "pid": "225",
      "path": ",6,225,2750,",
      "level": "3",
      "name": "因弗卡吉尔",
      "name_en": "Invercargill",
      "name_pinyin": "yinfukajier",
      "code": "IVC",
      "childrens": []
    }]
  }]
}, {
  "id": "156",
  "pid": "0",
  "path": ",156,",
  "level": "1",
  "name": "南极洲",
  "name_en": "Antarctica",
  "name_pinyin": "nanjizhou",
  "code": "ATA",
  "childrens": [{
    "id": "157",
    "pid": "156",
    "path": ",156,157,",
    "level": "2",
    "name": "南乔治亚和南桑德威奇群岛",
    "name_en": "South Georgia and South Sandwich Islands",
    "name_pinyin": "nanqiaozhiyahenansangdeweiqiqundao",
    "code": "SGS",
    "childrens": []
  }, {
    "id": "169",
    "pid": "156",
    "path": ",156,169,",
    "level": "2",
    "name": "乔治亚",
    "name_en": "Georgia",
    "name_pinyin": "qiaozhiya",
    "code": "GEO",
    "childrens": []
  }]
}]
export default options
