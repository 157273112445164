<template>
    <div class="user">
        <div class="user-basic-infor display_flex bg_fff">
            <div class="user_box bb_e6e flex">
                <div class="userinformation display_flex">
                    <h3 class="felx text_right">{{userData.nickname}}</h3>
                    <el-rate class="flex text_right" v-model="value1"></el-rate>
                </div>
                <div class="contact-way display_flex">
                    <el-tooltip placement="bottom" effect="light">
                      <template #content> 电话：<br />{{userData.username}} </template>
                      <span class="way-box phone"></span>
                    </el-tooltip>
                    <el-tooltip placement="bottom" effect="light">
                      <template #content> 邮箱：<br />{{userData.email}} </template>
                      <span class="way-box email"></span>
                    </el-tooltip>
                    <el-tooltip placement="bottom" effect="light">
                      <template #content> 巴巴里狮平台：<br />已认证 </template>
                      <span class="way-box approve"></span>
                    </el-tooltip>
                    <!-- <div class="lh_30 color_red text_right flex">了解更多会员权益>></div> -->
                </div>
                <p class="pt_5">ip：{{userData.ip}}</p>
            </div>
            <div class="user_box bb_e6e flex">
                <h3 class="h3">{{kefData.title}}</h3>
                <p class="p">联系电话：{{kefData.phone}}</p>
                <p class="p">联系邮箱：{{kefData.email}}</p>
            </div>
            <div class="user_box flex">
                <h3 class="h3">账号余额</h3>
                <div class="display_flex">
                    <p class="p flex">￥{{moneyData.money}}</p>
                    <p class="p flex"><el-button @click="rechargeVisible=true" type="primary" size="mini">充值</el-button></p>
                </div>
            </div>
        </div>
        <!-- <div class="user-sale display_flex">
            <div class="user-l bg_fff padding_20">
                <h3 class="h3">当月销售额 <Warning style="width: 16px; height: 22px;vertical-align: text-top;" /></h3>
                <div class="user-salep">
                    <p class="p">USD：<span style="color: #FF5722;">0</span></p>
                    <p class="p">GBP：<span style="color: #FF5722;">0</span></p>
                </div>
            </div>
            <div class="user-r bg_fff flex padding_20">
                <h3 class="h3">当月销售额 <Warning style="width: 16px; height: 22px;vertical-align: text-top;" /></h3>
                <ul class="display_flex flex_wrap">
                    <li>
                        <router-link to="/">
                            <p class="p">待付款：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>待发货：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>待收货：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>异常订单：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>跟踪号变更：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>待生成订单：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>待上传跟踪号：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>待确认申诉：0 </p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <p>申诉处理中：0 </p>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div> -->
        <div class="user-goods user-sale display_flex">
            <div class="user-data user-l bg_fff padding_20">
                <h3>商品数据</h3>
                <div class="list">
                    <div class="name_list display_flex">
                        <div class="flex color_999">类型</div>
                        <div class="flex color_999">数量</div>
                    </div>
                    <div class="name_list display_flex hand" @click="openPage('/collectionList')">
                        <div class="flex color_666">收藏</div>
                        <div class="flex color_666">{{goodData.fav}}</div>
                    </div>
                    <div class="name_list display_flex hand" @click="openPage('/shoppingCartList')">
                        <div class="flex color_666">购物车</div>
                        <div class="flex color_666">{{goodData.cart}}</div>
                    </div>
                    <div class="name_list display_flex hand" @click="openPage('/myOrderList')">
                        <div class="flex color_666">已下单</div>
                        <div class="flex color_666">{{goodData.order}}</div>
                    </div>
                </div>
            </div>
            <div class="user-data flex user-r bg_fff padding_20">
                <div class="head_goods display_flex">
                    <h3 class="h3">商品销量排行 <Warning style="width: 16px; height: 22px;vertical-align: text-top;" /></h3>
                    <!-- <div class="search_box display_flex">
                        <el-input class="mr_10px" v-model="input" placeholder=""></el-input>
                        <div class="mr_10px"><el-button type="primary" size="mini">搜索</el-button></div>
                        <div><el-button type="primary" size="mini">下载</el-button></div>
                    </div> -->
                </div>
                <div class="table">
                    <el-table :data="topData" style="width: 100%">
                      <el-table-column label="商品">
                          <template #default="scope">
                            <div class="display_flex">
                                <div class="top_img">
                                    <img :src="scope.row.logo" alt="">
                                </div>
                                <div class="title_box">
                                    <div class="title text_two_link">{{scope.row.title}}</div>
                                </div>
                            </div>
                          </template>
                      </el-table-column>
                      <el-table-column label="原价" width="160">
                          <template #default="scope"><div class="text_line">￥{{regFenToYuan(scope.row.marketPrice)}}</div></template>
                      </el-table-column>
                      <el-table-column label="售价" width="160">
                          <template #default="scope"><div class="red_text">￥{{regFenToYuan(scope.row.salePrice)}}</div></template>
                      </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="goods-ranking mb_10px padding_20 bg_fff">
            <h3 class="h3">月订单量趋势图 <Warning style="width: 16px; height: 22px;vertical-align: text-top;" /></h3>
            <Chart v-if="chartData" :chartData="chartData" />
        </div>
        <!-- <div class="goods-ranking mb_10px padding_20 bg_fff">
            <h3 class="h3">申诉排行 <Warning style="width: 16px; height: 22px;vertical-align: text-top;" /></h3>
            <div class="table">
                <el-table :data="tableData" style="width: 100%">
                  <el-table-column prop="date" label="日期" width="180"> </el-table-column>
                  <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
                  <el-table-column prop="address" label="地址"> </el-table-column>
                </el-table>
            </div>
        </div> -->
        <Recharge :rechargeVisible="rechargeVisible" @cloeNo="cloeNo" />
    </div>
</template>
<script>
import mixin from "../../../views/mixin";
import Chart from "../../../components/Common/Chart.vue";
import Recharge from "../../../components/Recharge/index.vue";
export default {
    mixins: [mixin],
    data() {
        return {
            goodData: {}, // 商品信息
            topData: [], // 销售前四
            userData: {}, // 用户信息
            kefData: {}, // 客服信息
            chartData: {}, // 月订单
            moneyData: {}, // 金额
            rechargeVisible: false // 充值弹窗
        }
    },
    components: {
      Chart,
      Recharge
    },
    created() {
        // this.rechargeVisible = true
        // this.getSiteId()
        this.getData()
    },
    methods: {
       getData() {
            this.$requestJSON({
      	      url: 'shop/user/index',
      	      method: 'POST',
              data: {
                  siteId: this.$store.state.siteId,
                token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
              }
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
                   this.goodData = res.data.goods
                   this.topData = res.data.top4
                   this.userData = res.data.user
                   this.kefData = res.data.kef[0]
                   this.chartData = res.data.chart
                   this.moneyData = res.data.money
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
        },
        openPage(pain) {
            this.$router.push({path:pain,query:{siteId: this.$store.state.siteId}})
        } 
    }
}
</script>
<style lang="scss" scoped>
.user {
    width: 100%;
}
.h3 {
    font-size: 16px;
    color: #111;
    line-height: 34px;
    height: 34px;
    margin-bottom: 5px;
}
.user-basic-infor {
    margin-bottom: 10px;
    width: 100%;
    .bb_e6e {
        border-right: 1px solid #e6e6e6;
    }
    .user_box {
        padding: 20px;
        .p {
            font-size: 14px;
            color: #666;
            line-height: 24px;
        }
         .way-box {
             background: url('../../../assets/img/user.png') no-repeat;
             display: inline-block;
             cursor: pointer;
             width: 32px;
             height: 32px;
         }
         .phone {
             background-position: 0 -32px;
         }
         .email {
             background-position: -32px 0;
            //  margin-left: -6px;
         }
         .approve {
             background-position: -64px -32px;
         }
    }
}
.user-sale {
    margin-bottom: 10px;
    .user-l {
        width: 160px;
        margin-right: 10px;
        .p {
            line-height: 34px;
            height: 34px;
        }
    }
    .user-r {
        ul {
            li {
                width: 140px;
                // padding: 0 5px 0 0;
                height: 34px;
                line-height: 34px;
            }
        }
    }
}
.user-goods {
    .list {
        .name_list {
            height: 34px;
            line-height: 34px;
            // color: #999;
        }
    }
    .head_goods {
       .search_box {
           margin-left: auto;
           /deep/.el-input__inner {
               height: 32px !important;
           }
           .el-button--mini {
                min-height: 32px;
                // padding: 2px;
            }
       } 
    }
}
.top_img {
    img {
        width: 60px;
    }
}
.title_box {
    padding-left: 8px;
    line-height: 30px;
}
a p {
    color: #666;
}
</style>