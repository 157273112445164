<template>
    <div class="evaluate_box">
        <div class="title">评价晒单</div>
        <div class="evaluate_data">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="所有订单" name="all"></el-tab-pane>
              <el-tab-pane label="待付款 " name="pending"></el-tab-pane>
              <el-tab-pane label="配货中 " name="distribution"></el-tab-pane>
              <el-tab-pane label="待收货 " name="received"></el-tab-pane>
              <el-tab-pane label="已完成" name="complete"></el-tab-pane>
              <el-tab-pane label="已关闭" name="close"></el-tab-pane>
            </el-tabs>
            <div class="table_box">
                    <div class="table_head bg_f2f display_flex">
                        <div style="width: 35%;" class="">商品</div>
                        <div style="width: 15%;" class="">价格</div>
                        <div style="width: 10%;" class="">数量</div>
                        <div style="width: 15%;" class="">总额</div>
                        <div style="width: 15%;" class="">状态</div>
                        <div style="width: 10%;" class="">操作</div>
                    </div>
                    <div class="complaint-goods">
                      <div class="null_box" v-if="evaluateData.length==0"><el-empty description="您的评价是空的， 赶紧去逛逛吧"><el-button type="primary" @click="openHome">去逛逛</el-button></el-empty></div>
                        <div class="table" v-for="(imte, index) in evaluateData" :key="index">
                            <div class="table_title display_flex">
                                <div class="time flex">
                                    <!-- <el-checkbox v-model="checked1"></el-checkbox> -->
                                    订单号：{{imte.no}} 下单时间：{{imte.createTime}}
                                </div>
                                <div class="name display_flex">
                                    <!-- <div>巴巴里狮平台</div> -->
                                    <div class="icon" @click="getDel(imte.id)"><Delete style="width: 22px;margin-top: 4px;" /></div>
                                </div>
                            </div>
                             <!-- @click="openGoodDetail(imte.id)" -->
                            <div class="table_body display_flex hand" v-for="(good, goodId) in imte.orderDetails" :key="goodId">
                                <div style="width: 35%;" class="b_r_6e6" :class="goodId!=imte.orderDetails.length-1? 'bb_6e6':''">
                                    <div class="list_th">
                                    <div class="good display_flex">
                                        <div class="checkbox_li"><img :src="good.logo" alt=""></div>
                                        <div class="text_box">
                                            <a class="text_hid_link">{{good.title}}</a>
                                            <p>规格：{{good.skuName}}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6" :class="goodId!=imte.orderDetails.length-1? 'bb_6e6':''">
                                    <div class="list" style="color: #FF5722;">￥ {{regFenToYuan(good.price)}}</div>
                                </div>
                                <div style="width: 10%;" class="b_r_6e6" :class="goodId!=imte.orderDetails.length-1? 'bb_6e6':''">
                                    <div class="list" style="color: #999;">{{good.num}}</div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6">
                                    <div v-if="imte.orderDetails.length == 1" class="list" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.totalPrice)}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==0&&goodId==imte.orderDetails.length/2-1" class="list pat_bb" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.totalPrice)}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==1&&goodId==parseInt(imte.orderDetails.length/2)" class="list" style="color: #c90304; font-weight: bold;">{{regFenToYuan(imte.totalPrice)}}</div>
                                </div>
                                <div style="width: 15%;" class="b_r_6e6">
                                    <div v-if="imte.orderDetails.length == 1" class="list" style="color: #FF5722;">{{imte.status == 0? '待付款':imte.status == 1? '配货中':imte.status == 2? '待收货':imte.status == 3? '已完成':imte.status == 4? '已关闭':''}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==0&&goodId==imte.orderDetails.length/2-1" class="list pat_bb" style="color: #FF5722;">{{imte.status == 0? '待付款':imte.status == 1? '配货中':imte.status == 2? '待收货':imte.status == 3? '已完成':imte.status == 4? '已关闭':''}}</div>
                                    <div v-else-if="imte.orderDetails.length%2==1&&goodId==parseInt(imte.orderDetails.length/2)" class="list" style="color: #FF5722;">{{imte.status == 0? '待付款':imte.status == 1? '配货中':imte.status == 2? '待收货':imte.status == 3? '已完成':imte.status == 4? '已关闭':''}}</div>
                                </div>
                                <div style="width: 10%;">
                                    <div v-if="imte.orderDetails.length == 1" style="padding-top: 40px;">
                                      <div @click.stop="getLook(imte.id)" class="list_s red_text hand">查看订单</div>
                                      <div @click.stop="getPay(imte.id)" class="list_s red_text hand" v-if="imte.status == 0">付款</div>
                                      <div @click.stop="getLogistics(imte.deliverNo)" class="list_s red_text hand" v-if="imte.status == 2">物流轨迹</div>
                                    </div>
                                    <div v-else-if="imte.orderDetails.length%2==0&&goodId==imte.orderDetails.length/2" style="margin-top: -30%;">
                                      <div @click.stop="getLook(imte.id)" class="list_s red_text hand">查看订单</div>
                                      <div @click.stop="getPay(imte.id)" class="list_s red_text hand" v-if="imte.status == 0">付款</div>
                                      <div @click.stop="getLogistics(imte.deliverNo)" class="list_s red_text hand" v-if="imte.status == 2">物流轨迹</div>
                                    </div>
                                    <div style="padding-top: 30%;" v-else-if="imte.orderDetails.length%2==1&&goodId==parseInt(imte.orderDetails.length/2)">
                                      <div @click.stop="getLook(imte.id)" class="list_s red_text hand">查看订单</div>
                                      <div @click.stop="getPay(imte.id)" class="list_s red_text hand" v-if="imte.status == 0">付款</div>
                                      <div @click.stop="getLogistics(imte.deliverNo)" class="list_s red_text hand" v-if="imte.status == 2">物流轨迹</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paga" v-if="evaluateData.length>0">
                        <Pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @getData="turnPages" />
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
	import mixin from '../../../views/mixin.js'
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				activeName: 'all',
                evaluateType: '',
                evaluateData: []
			};
		},
		created() {
			// this.getData()
		},
		methods: {
            handleClick(val) {
            this.currentPage = 1
            switch(this.activeName) {
                case 'all':
                  this.evaluateType = ""
                    // this.getData()
                    break
                case 'pending':
                    this.evaluateType = 0
                    // this.getData()
                    break
                case 'distribution':
                    this.evaluateType = 1
                    // this.getData()
                    break
                case 'received':
                    this.evaluateType = 2
                    // this.getData()
                    break
                case 'complete':
                    this.evaluateType = 3
                    // this.getData()
                    break
                case 'complete':
                    this.evaluateType = 4
                    // this.getData()
                    break
            }
        },
            // 获取数据
			getData() {
				this.$requestJSON({
        		  url: 'shop/' + this.$store.state.siteId,
        		  method: 'POST',
				  data: {
					  page: this.currentPage,
                      limit: this.pageSize,
                      siteId: this.$store.state.siteId,
                      token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token'),
                      status: this.evaluateType
				  }
        		}).then(res => {
        		//   this.loading = false
        		   if(res.code === 0) {
        		     console.log(res.data)
                     this.messageData = res.data
                     this.total = res.total
        		   } else {
        		     this.$message.error (res.msg)
        		   }
        		})
			},
            // 返回
            openMessage() {
                this.$router.push({path:'/message',query:{siteId: this.$store.state.siteId}})
            }
		}
	};
</script>
<style lang="scss" scoped>
.evaluate_box {
    background: #fff;
    margin-bottom: 20px;
    width: 100%;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        line-height: 40px;
        padding: 20px 0px 0px 20px;
    }
    .evaluate_data {
        padding: 0px 20px 20px 20px;
    }
    .table_box {
        min-height: 500px;
                .table_head {
                    margin: 10px 0;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    color: #666;
                }
                .table {
                    border: #e6e6e6 1px solid;
                     margin: 10px 0;
                     .table_title {
                         background-color: #f2f2f2;
                         padding: 0px 15px;
                         line-height: 35px;
                         color: #666;
                         font-size: 14px;
                         border-bottom: #e6e6e6 1px solid;
                         .icon {
                             padding-left: 10px;
                         }
                     }
                     .bb_6e6 {
                       border-bottom: #e6e6e6 1px solid;
                     }
                     .b_r_6e6 {
                         border-right: #e6e6e6 1px solid;
                     }
                     .list_th {
                         padding: 9px 15px;
                        .good {
                            width: 100%;
                            img {
                                width: 100px;
                            }
                            .text_box {
                                padding-left: 10px;
                                padding-top: 20px;
                                .color_red {
                                    columns: #CB261C;
                                }
                                a {
                                    color: #CB261C !important;
                                }
                                p {
                                    color: #666;
                                    tab-size: 12px;
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                    .list {
                        text-align: center;
                        line-height: 130px;
                    }
                    .pat_bb {
                       line-height: 0px !important;
                       padding-top: 130px;
                     }
                    .list_s {
                      text-align: center;
                    }
                }
            }
}
</style>
