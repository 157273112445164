<template>
  <el-dialog
    title="充值"
    v-model="rechargeVisible"
    width="60%"
    top="50px"
    :before-close="handleClose"
  >
    <div class="from">
      <el-form ref="form" :model="rechargeForm" :rules="rechargeRules" label-width="120px" class="demo-ruleForm">
        <el-form-item label="汇款银行：" prop="bank">
          <el-select style="width: 100%" v-model="rechargeForm.bank" placeholder="请选择">
            <el-option label="ntglobal" value="ntglobal"></el-option>
            <el-option label="支付宝" value="支付宝"></el-option>
            <el-option label="微信支付" value="微信支付"></el-option>
            <el-option label="工商银行" value="工商银行"></el-option>
            <el-option label="建设银行" value="建设银行"></el-option>
            <el-option label="微信转账" value="微信转账"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="汇款时间" prop="payTime">
           <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="rechargeForm.payTime"
              format="YYYY-MM-DD"
              style="width: 100%"
            ></el-date-picker>
        </el-form-item>
        <el-form-item label="订单号/流水号" prop="orderNo">
          <el-input v-model="rechargeForm.orderNo"></el-input>
        </el-form-item>
        <el-form-item label="汇款金额" prop="money">
          <el-input v-model="rechargeForm.money"></el-input>
        </el-form-item>
       <el-form-item label="备注" prop="remark">
         <el-input v-model="rechargeForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="上传凭证" prop="logo">
          <el-upload
            class="upload-demo"
            action="https://www.babalish.com/api/shop/qiniu/upload"
            :on-success="handleSuccess"
            :file-list="fileList"
            list-type="picture"
            :on-change="HFhandleChangeImg"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm('form')">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import mixin from "../../views/mixin";
import { timestampToTimeAll } from "../../utils/formatTime";
export default {
  mixins: [mixin],
  props: {
    rechargeVisible: Boolean,
  },
  data() {
    return {
        rechargeForm: {
            bank: '', // 银行
            payTime: '', // 汇款时间
            orderNo: '', // 订单号
            money: '', // 汇款金额
            remark: '', // 备注
            logo: '', // 文件上传
            token: ''
        },
        rechargeRules: {
            bank: [
              { required: true, message: '请选择', trigger: 'change' },
            ],
            payTime: [
              { required: true, message: '请选择', trigger: 'change' },
            ],
            orderNo: [
              { required: true, message: '请输入', trigger: 'blur' },
            ],
            money: [
              { required: true, message: '请输入', trigger: 'blur' },
            //   { type: 'number', message: '必须为数字值'}
            ],
            logo: [
              { required: true, message: '请选择', trigger: 'change' },
            ],
        }, // 验证
         fileList: [], // 上传
    };
  },
  mounted() {
    this.rechargeForm.token = window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
  },
  methods: {
      handleSuccess(file, fileList) {
        this.rechargeForm.logo = file.src
      },
      HFhandleChangeImg(file, fileList) {
          this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的csv文件
          console.log(this.fileList)
      },
      // 重置
       resetForm(formName) {
           this.$emit('cloeNo')
        this.$refs[formName].resetFields()
      },
      submitForm(name) {
          this.$refs[name].validate((valid) => {
          if (valid) {
            this.rechargeForm.payTime = timestampToTimeAll(this.rechargeForm.payTime)
            this.$requestJSON({
      	      url: 'shop/userrecharge/save',
      	      method: 'POST',
              data: this.rechargeForm
      	    }).then(res => {
      	    //   this.loading = false
      	       if(res.code === 0) {
      	        this.$message.success (res.msg)
                  this.resetForm(name)
      	       } else {
      	         this.$message.error (res.msg)
      	       }
      	    })
          } else {
            this.$message.error ('请输入！')
            return false
          }
        })
      }
  }
};
</script>
<style lang="scss" scoped>
.from {
  height: 450px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.from::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
// .from::-webkit-scrollbar {
//   /*滚动条整体样式*/
//   width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
//   height: 1px;
//   }
//   .from::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 10px;
//   box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background   : #535353;
//   }
//   .from::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
//   border-radius: 10px;
//   background   : #ededed;
//   }
</style>